import { useRef, useState } from "react";
import Footer from "../Footer";
import Header from "../Header";
import LeftBar from "../LeftBar";

const TestImaging = () => {
  const [imgSrcOne, setImgSrcOne] = useState(`${process.env.PUBLIC_URL}/images/vector.png`);
  const inputFileRef1 = useRef(null);
  const inputFileRef2 = useRef(null);
  const inputFileRef3 = useRef(null);
  return (
    <div className="main-wrapper">
      {/***********************************
                    Header start
                    ************************************/}
      <Header />
      {/***********************************
                    Header end ti-comment-alt
                    ************************************/}
      {/***********************************
                    Sidebar start
                    ************************************/}
      <LeftBar />
      {/***********************************
                    Sidebar end
                    ************************************/}
      {/***********************************
                    Content body start
                    ************************************/}

      {/***********************************
                    Content body end
                    ************************************/}
      <div className="content-body">
        <p className="prescription__text">Patients details</p>
        <div className="prescription__patient__identification">
          <div className="flex__block">
            <label className="recommeded__amount__content">
              NAME: {"JOHN DOE"}
            </label>
            <label className="recommeded__amount__content">
              AGE: {"65 "} YRS
            </label>
          </div>
          <div className="flex__block">
            <label className="recommeded__amount__content">
              Gender: {"MALE"}
            </label>
            <label className="recommeded__amount__content">WEIGHT:</label>
          </div>
        </div>
        <p className="prescription__text">Imaging Results</p>
        <div className="row" style={{ width: 1080 }}>
          <div className="col-md-4 col-sm-12">
            <div
              className="card"
              style={{ width: 347, height: 499, padding: 30 }}
            >
              <p className="recommended__amount__content">PSA</p>
              <div className="image__line" />
              <img
                src={`${process.env.PUBLIC_URL}/images/vector.png`}
                width={119}
                height={132}
                style={{ margin: '0px 10px 5px 70px' }}
              />
              <p
                className="recommended__amount__content"
                style={{ marginTop: 20 }}
              >
                Your file should be uploaded in JPG , PDF, PNG format max size
                150x100{" "}
              </p>
              <input
                type="file"
                ref={inputFileRef1}
                accept='image/png, image/jpeg,.pdf'
                style={{ display: "none" }}
                onChange={(e)=> console.log(e.target.files) }
              />
              <button
                className="image__upload"
                onClick={() => inputFileRef1.current.click()}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/image-upload.png`}
                  className="download__svg"
                  style={{ marginBottom: 5, marginRight: 10 }}
                />
                Upload
              </button>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div
              className="card"
              style={{ width: 347, height: 499, padding: 30 }}
            >
              <p className="recommended__amount__content">
                Prostate Ultrasound
              </p>
              <div className="image__line" />
              <img
                src={`${process.env.PUBLIC_URL}/images/vector.png`}
                width={119}
                height={132}
                style={{ margin: '0px 10px 5px 70px' }}
              />
              <p
                className="recommended__amount__content"
                style={{ marginTop: 20 }}
              >
                Your file should be uploaded in JPG , PDF, PNG format max size
                150x100{" "}
              </p>
              <input
                type="file"
                ref={inputFileRef2}
                accept='image/png, image/jpeg,.pdf'
                style={{ display: "none" }}
                onChange={(e)=> console.log(e.target.files) }
              />
              <button
                className="image__upload"
                onClick={() => inputFileRef2.current.click()}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/image-upload.png`}
                  className="download__svg"
                  style={{ marginBottom: 5, marginRight: 10 }}
                />
                Upload
              </button>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div
              className="card"
              style={{ width: 347, height: 499, padding: 30 }}
            >
              <p className="recommended__amount__content">Cardiac Ultrasound</p>
              <div className="image__line" />
              <img
                src={imgSrcOne}
                width={119}
                height={132}
                style={{ margin: '0px 10px 5px 70px' }}
              />
              <p
                className="recommended__amount__content"
                style={{ marginTop: 20 }}
              >
                Your file should be uploaded in JPG , PDF, PNG format max size
                150x100{" "}
              </p>
              <input
                type="file"
                ref={inputFileRef3}
                accept='image/png, image/jpeg,.pdf'
                style={{ display: "none" }}
                onChange={(e)=>{

                  var file = e.target.files[0];
                  var reader = new FileReader();
                  var url = reader.readAsDataURL(file);

                  reader.onloadend = function (e) {
                      setImgSrcOne({
                          imgSrcOne: [reader.result]
                      })
                    }.bind(imgSrcOne);
                  console.log(reader)
                } }
              />
              <button
                className="image__upload"
                onClick={() => inputFileRef3.current.click()}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/image-upload.png`}
                  className="download__svg"
                  style={{ marginBottom: 5, marginRight: 10 }}
                />
                Upload
              </button>
            </div>
          </div>
        </div>
        <div
          className="flex__container"
          style={{ justifyContent: "flex-end", width: 1080 }}
        >
          <button className="prescription__send__btn" style={{ width: 130 }}>
            Submit
          </button>
        </div>
      </div>

      {/***********************************
                    Footer start
                    ************************************/}
      <Footer />
      {/***********************************
                    Footer end
                    ************************************/}
    </div>
  );
};
export default TestImaging;
