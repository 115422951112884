import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { selectUser } from "../../redux_setup/slices/userSlice";
import { updatePatientData } from "../../redux_setup/slices/patientDataSlice";
import { searchHandler } from "../../constants/Essentials";
import { Modal, Button, Row, Table } from "react-bootstrap";
import Header from "../Header";
import LeftBar from "../LeftBar";
import Footer from "../Footer";
const CarePlanVitals = () => {
    const [chosePatient, setChosepatient] = useState(false);
    const [patients, setPatients] = useState([]);
    const [allPatients, setAllPatients] = useState([]);
    const [showClass, setShowClass] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [modalWasHidden, setModalWasHidden] = useState(false);
    const user = useSelector(selectUser)
    const abortcontroller = new AbortController();
    const signal = abortcontroller.signal;
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const fetchPatients = () => {
      fetch(`${process.env.REACT_APP_MAIN_URL}/api/patient/`, {
        signal: signal,
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + user.token,
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          if (res.error) {
            return console.log("patients fetching did not succeed");
          }
          let iterable =
            user.role === "SUPER_ADMIN" ? res.data.items : res.data;

          let count = 1;
          iterable.forEach((item) => {
            item.count = count;
            count++;
            //->will delete the rest later
          });
          setPatients(iterable);
          setAllPatients(iterable);
          setChosepatient(true);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    useEffect(() => {
        fetchPatients()
    },[])
    useEffect(() => {
    if (searchKeyword.length === 0) {
      return setPatients(allPatients)
    }
    //wait for some seconds then run search handler
    if (searchKeyword.length > 0) {
      setTimeout(() => {
        searchHandler(patients, setPatients, searchKeyword)
      }, 200) //some sort of debounce--whatever
    }
    }, [searchKeyword])
    
    const proceedWithPatient = (patientData) => {
        dispatch(updatePatientData(patientData));
        navigate("/care-plan-vitals");
    }
    

    return (
      <div className='main-wrapper'>
        {/***********************************
                  Header start
                  ************************************/}
        <Header />
        {/***********************************
                  Header end ti-comment-alt
                  ************************************/}
        {/***********************************
                  Sidebar start
                  ************************************/}
        <LeftBar />
        {/***********************************
                  Sidebar end
                  ************************************/}
        {/***********************************
                  Content body start
                  ************************************/}

        {/***********************************
                  Content body end
                  ************************************/}
        <div className='content-body'>
          <Modal
            show={chosePatient}
            onHide={() => {
              setChosepatient(false);
              setModalWasHidden(true);
              setShowClass(false);
            }}
            size='lg'
          >
            <Modal.Header>
              <Modal.Title>Choose a patient to proceed</Modal.Title>
              <span
                className={!showClass ? "close__btn" : "hover"}
                onClick={() => {
                  setChosepatient(false);
                  setModalWasHidden(true);
                  setShowClass(false);
                }}
                onMouseOver={() => setShowClass(!showClass)}
                onMouseOut={() => setShowClass(false)}
              >
                x
              </span>
            </Modal.Header>
            <Modal.Body>
              <div
                className='row flex__container'
                style={{ justifyContent: "flex-end" }}
              >
                <div className='col-lg-4 col-md-6'>
                  <input
                    placeholder='search by name,email or phone'
                    className='form-control'
                    type='text'
                    name=''
                    value={searchKeyword}
                    onChange={(e) => setSearchKeyword(e.target.value)}
                  />
                </div>
                <div className='col-lg-2 col-md-12'>
                  <button
                    type='button'
                    className='submitSearch btn btn-info '
                    onClick={() =>
                      searchHandler(patients, setPatients, searchKeyword)
                    }
                    title='Click here to search patient'
                    style={{
                      width: 110,
                      height: 38,
                      whiteSpace: "nowrap",
                      marginLeft: -20,
                      marginBottom: 10,
                    }}
                  >
                    Find patient
                  </button>
                </div>
              </div>
              <Row>
                <Table bordered hover striped>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Gender</th>
                      <th>Email</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {patients.map((patient) => (
                      <tr key={patient.id}>
                        <td>{patient.name}</td>
                        <td>{patient.gender}</td>
                        <td>{patient.email}</td>
                        <td>
                          <Button
                            size='sm'
                            variant='info'
                            onClick={() => proceedWithPatient(patient)}
                          >
                            Choose
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
            </Modal.Body>
            <div className='footer__close'>
              <Button
                size='sm'
                variant='danger'
                onClick={() => {
                  setChosepatient(false);
                  setModalWasHidden(true);
                  setShowClass(false);
                }}
              >
                Close
              </Button>
            </div>
          </Modal>
        </div>
        {/***********************************
                  Footer start
                  ************************************/}
        <Footer />
        {/***********************************
                  Footer end
                  ************************************/}
      </div>
    );
}
export default CarePlanVitals