import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import Select from 'react-select'

import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { trimDate } from '../constants/Essentials'
import { flexibleCompare, formatDate } from '@fullcalendar/react'

const PatientMedicalDetailsHistoryComponent = ({ dataToProcess }) => {
  toast.configure()
  let navigate = useNavigate()
  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  const user = useSelector(selectUser)

  const [dateOptions, setDateOptions] = useState([])
  const [mainRes, setMainRes] = useState([])
  const [currentRes, setCurrentRes] = useState({})

  //details states
  const [height, setHeight] = useState(0)
  const [weight, setWeight] = useState(0)
  const [bmi, setBmi] = useState('')

  const [foodAllergy, setFoodAllergy] = useState(false)
  const [pollenAllergy, setPollenAllergy] = useState(false)
  const [dustAllergy, setDustAllergy] = useState(false)
  const [petAllergy, setPetAllergy] = useState(false)
  const [latexAllergy, setLatexAllergy] = useState(false)

  const [oftenDrink, setOftenDrink] = useState('')
  const [oftenSmoke, setOftenSmoke] = useState('')
  const [oftenDrugs, setOftenDrugs] = useState('')

  const [medicalCondition, setMedicalCondition] = useState(false)
  const [whichMedicalCondition, setWhichMedicalCondition] = useState('')

  const patient_id =
    user.role !== 'Patient' ? dataToProcess?.patient_id : user.user_id
  const appt_id = dataToProcess?.appt_id

  // fetch profile details
  const fetchDetailsUrl = `${process.env.REACT_APP_MAIN_URL}/api/vital/patient-history?patient_id=${patient_id}`

  useEffect(() => {
    // console.log(user.user_id, user.token)
    fetch(fetchDetailsUrl, {
      method: 'GET',
      signal: signal,
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, 'data')
        if (res.error) {
          return toast.warning('An error occured while fetching data', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          })
        }
        const response = res.data.items
        const dates = []
        response.map((res) => {
          dates.push({
            value: res.createdAt,
            label: `${trimDate(res.createdAt)}`,
          })
        })
        // console.log(response);
        setDateOptions(dates)
        setMainRes(response)
        setCurrentRes(response[0])

        // res.data?.items?.length > 0 && setAppointments(res.data.items)
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  const handleChange = (date) => {
    const res = mainRes.filter((item) => item.createdAt === date)
    setCurrentRes(res[0])
  }

  useEffect(() => {
    const response = currentRes

    setHeight(response?.height)
    setWeight(response?.weight)
    setBmi(response?.bmi)
    setOftenDrink(response?.alcohol_intake)
    setOftenSmoke(response?.smoking)
    setOftenDrugs(response?.drug_use)
    setMedicalCondition(response?.medical_condition)
    setWhichMedicalCondition(response?.which_medical_condition)

    let parsedapplicables = ''
    try {
      parsedapplicables = JSON.parse(response?.allergies)
    } catch {
      parsedapplicables = {}
    }
    // const parsedapplicables = {}
    setFoodAllergy(parsedapplicables.food)
    setPollenAllergy(parsedapplicables.pollen)
    setDustAllergy(parsedapplicables.dust)
    setPetAllergy(parsedapplicables.pet)
    setLatexAllergy(parsedapplicables.latex)
  }, [currentRes])

  return (
    <>
      {mainRes.length ? (
        <div className="row">
          <div className="col-lg-12 col-xxl-12">
            <div className="card ">
              <div className="card-header">
                <h4 className="card-title">Details History</h4>
              </div>
              <div className="card-body text-left">
                <div className="form-group row">
                  <label className="col-md-2">Date Recorded: </label>
                  <div className="col-md-6">
                    <select
                      onChange={(e) => handleChange(e.target.value)}
                      className="form-control"
                    >
                      {dateOptions.map((d) => (
                        <option key={d.value} value={d.value}>
                          {d.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <p>
                      <strong>Height:</strong> {height}
                    </p>
                    <p>
                      <strong>Weight:</strong> {weight}
                    </p>
                    <p>
                      <strong>BMI:</strong> {bmi}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p>
                      <strong>Alcohol:</strong> {oftenDrink}
                    </p>
                    <p>
                      <strong>Smoking:</strong> {oftenSmoke}
                    </p>
                    <p>
                      <strong>Recreational Drugs:</strong> {oftenDrugs}
                    </p>
                  </div>
                  <div className="col-md-12">
                    <p>
                      <strong>Diagonised with medical condition? </strong>{' '}
                      {medicalCondition ? 'Yes' : 'No'}
                    </p>
                    {medicalCondition && (
                      <p>
                        <strong>Which one? </strong>
                        {whichMedicalCondition}
                      </p>
                    )}
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <p>
                        <strong>Allergies</strong>
                      </p>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={foodAllergy}
                          id="food_allergy"
                          disabled
                        />
                        <label className="form-check-label" for="food_allergy">
                          Food
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={pollenAllergy}
                          id="pollen_allergy"
                          disabled
                        />
                        <label
                          className="form-check-label"
                          for="pollen_allergy"
                        >
                          Pollen
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={dustAllergy}
                          disabled
                          id="dust_allergy"
                        />
                        <label className="form-check-label" for="dust_allergy">
                          Dust
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={petAllergy}
                          disabled
                          id="pet_allergy"
                        />
                        <label className="form-check-label" for="pet_allergy">
                          Pet
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={latexAllergy}
                          disabled
                          id="latex_allergy"
                        />
                        <label class="form-check-label" for="latex_allergy">
                          Latex
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="alert alert-info">
          <p>No details history yet</p>
        </div>
      )}
    </>
  )
}

export default PatientMedicalDetailsHistoryComponent
