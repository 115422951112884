const Counter = ({leftPosition,times,data}) =>{
    return(
        <div className="snacks__container">
                <div>
                  <div class='counter__container' style={{position: 'absolute',left:leftPosition*times,bottom:10}}>
                    <div class ='top__circle'>
                      <strong>{data}</strong>
                      <div class ='left-line'></div>
                      <div class="dot"></div>
                      <div class="left__dot"></div>
                      <div class="right__dot"></div>
                    <div class ='right-line'></div>
                  </div>
                </div>
              </div>
                </div>
    )
}
export default Counter;