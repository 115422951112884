import React, { useState, useRef } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from 'react-redux'

import { breakfast_data } from '../../constants/nutrition_data'
import {
  populateBreakfast,
  selectBreakfast,
} from '../../redux_setup/slices/nutritionBasketSlice'
import { ReduxActions } from '../../constants/redux_actions'

const Breakfast = () => {
  const [show, setShow] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [selected, setSelected] = useState()
  const [disabled, setDisabled] = useState(true)
  const [foods, setFoods] = useState([])
  const otherRef = useRef()
  const formRef = useRef()
  const dispatch = useDispatch()
  const nutritionBasket = useSelector(selectBreakfast)

  const addToBasket = (item) => {
    dispatch(populateBreakfast({ item, action_type: ReduxActions.add_to_cart }))
  }

  const minusFromBasket = (item) => {
    dispatch(
      populateBreakfast({
        item,
        action_type: ReduxActions.decrement_from_cart,
      }),
    )
  }

  const handleClose = () => {
    setShow(false)
    console.log('clicked')
  }
  return (
    <>
      <div>
        <p
          className="meal-profile-text"
          style={{ color: '#000', cursor: 'pointer' }}
        >
          Breakfast
        </p>
        <div className="d-flex flex-wrap">
          <label className="mr-3 mt-2 mb-2">Time</label>
          <input
            type="time"
            style={{
              width: 135,
              height: 27,
              marginBottom: 20,
              border: '1px solid #662382',
            }}
          />
        </div>
        <div style={{ width: 200 }}>
          <label>Food</label>
          <Form.Select className="pl-2" onClick={() => setShow(true)}>
            <option onClick={() => setShow(true)}>Foods</option>
          </Form.Select>
        </div>
        <div
          style={{
            // width: 663,
            height: 58,
            backgroundColor: '#E8E8E840',
          }}
          className="mt-3"
        />
      </div>
      <Modal show={show} onHide={handleClose}>
        <div className="card pl-4 pr-4" style={{ overflowX: 'scroll' }}>
          {/* <div className="row">
            <div className="col-10"></div>

            <div className="col-1">
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/images/icons/close-icon.png`}
                  width={26}
                  height={26}
                  alt="close icon"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShow(false)}
                />
              </div>
            </div>
          </div> */}

          <div className="row mt-2">
            {breakfast_data.map((item, index) => (
              <div
                className="col-sm-6 col-12"
                key={index}
                style={{
                  backgroundColor:
                    nutritionBasket.filter((it) => it.title === item.title)[0]
                      ?.count > 0
                      ? '#6623824D'
                      : '',
                  borderRadius: 8,
                  marginTop: 2,
                }}
              >
                <div className="mt-2 mb-2">
                  <div className="d-flex justify-content-between">
                    <Form.Check
                      type="checkbox"
                      id={`breakfast-${item.title}`}
                      label={`${item.title}`}
                      // style={{ whiteSpace: 'nowrap' }}
                      checked={
                        nutritionBasket.filter(
                          (it) => it.title === item.title,
                        )[0]?.count > 0
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          addToBasket(item)
                        }
                      }}
                      className="mt-2"
                      style={{
                        filter: 'grayscale(1)',
                      }}
                    />
                    <div className="d-flex mt-2">
                      <Button
                        variant="default"
                        style={{
                          width: 35,
                          height: 16,
                          padding: 0,
                          fontSize: 10,
                          backgroundColor:
                            nutritionBasket.filter(
                              (it) => it.title === item.title,
                            )[0]?.count > 0
                              ? '#6623824D'
                              : '',
                        }}
                        onClick={() => minusFromBasket(item)}
                      >
                        <i class="fa-sharp fa-solid fa-minus"></i>
                      </Button>
                      <span
                        style={{
                          marginBottom: 10,
                          fontSize: 13,
                        }}
                        className="ml-2 mr-2"
                      >
                        {nutritionBasket.filter(
                          (it) => it.title === item.title,
                        )[0]?.count || 0}
                      </span>
                      <Button
                        variant="default"
                        style={{
                          width: 35,
                          height: 16,
                          padding: 0,
                          fontSize: 10,
                          backgroundColor:
                            nutritionBasket.filter(
                              (it) => it.title === item.title,
                            )[0]?.count > 0
                              ? '#6623824D'
                              : '',
                        }}
                        onClick={() => addToBasket(item)}
                      >
                        <i class="fa-sharp fa-solid fa-plus"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <br />
            <Button
              onClick={() => {
                // console.log(nutritionBasket, 'gggrg')
                setShow(false)
              }}
              style={{ backgroundColor: '#6623824D', borderColor: '#E8E8E840' }}
            >
              Okay
            </Button>

            {/* <div className=" col-md-6 col-sm-12">
              <div style={{ borderRadius: 8 }}>
                {breakfast_data.map((item, index) => (
                  <div
                    key={index}
                    className=" card pl-2 pr-3 mt-2 mb-2 ml-2"
                    style={{
                      maxWidth: 400,
                      marginLeft: 150,
                      backgroundColor:
                        isSelected && selected === item.title
                          ? '#6623824D'
                          : '',
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <Form.Check
                        type="checkbox"
                        id={`breakfast-${item.title}`}
                        label={`${item.title}`}
                        style={{ whiteSpace: 'nowrap' }}
                        className="mt-2"
                        onChange={(e) => {
                          setIsSelected(e.target.checked)
                          setSelected(item.title)
                        }}
                      />
                      <div className="d-flex mt-2">
                        <Button
                          variant="default"
                          style={{
                            width: 35,
                            height: 16,
                            padding: 0,
                            fontSize: 10,
                            backgroundColor:
                              isSelected && selected === item.title
                                ? '#6623824D'
                                : '',
                          }}
                        >
                          <i class="fa-sharp fa-solid fa-minus"></i>
                        </Button>
                        <span style={{ marginBottom: 10, fontSize: 13 }}>
                          1
                        </span>
                        <Button
                          variant="default"
                          style={{
                            width: 35,
                            height: 16,
                            padding: 0,
                            fontSize: 10,
                            backgroundColor:
                              isSelected && selected === item.title
                                ? '#6623824D'
                                : '',
                          }}
                        >
                          <i class="fa-sharp fa-solid fa-plus"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="card" style={{ maxHeight: 100, padding: 10 }}>
                <Form.Check
                  type="checkbox"
                  id="other"
                  label="Other"
                  style={{ whiteSpace: 'nowrap', marginBottom: 10 }}
                  className="mt-2"
                  onChange={(e) => setDisabled(!e.target.checked)}
                />
                <form
                  ref={formRef}
                  className="d-flex flex-wrap justify-content-between"
                >
                  <input
                    type="text"
                    placeholder="Add ather foods not in the list"
                    style={{ width: 250, borderRadius: 5, paddingLeft: 10 }}
                    disabled={disabled}
                    ref={otherRef}
                  />
                  <Button
                    className="mt-2"
                    onClick={() => {
                      if (otherRef.current.value != '') {
                        dispatch(
                          updateBasketFood([
                            ...foodInBasket,
                            otherRef.current.value,
                          ]),
                        )
                        formRef.current.reset()
                      }
                    }}
                  >
                    Add
                  </Button>
                </form>
              </div>
            </div> */}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Breakfast
