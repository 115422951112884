import React from 'react'
import { Link } from 'react-router-dom'
import { SignInSchema } from '../constants/ValidationSchema'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'

const StaffLogin = () => {
  toast.configure()
  let navigate = useNavigate()
  const initialValues = {
    email: '',
    password: '',
  }

  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal

  const url = `${process.env.REACT_APP_MAIN_URL}/api/auth/user-login`

  const handleLogin = (values, resetForm) => {
    const dataToSend = {
      username: values.email,

      password: values.password,
    }

    // send request
    fetch(url, {
      signal: signal,
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',

        // "Access-Control-Request-Headers": "*",
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }
        resetForm()
        localStorage.setItem('temp_token', res.token)
        // console.log(res);
        res.data.role === 'RECEPTIONIST'
          ? navigate('/admin-dashboard')
          : navigate('/dashboard')
        window.location.reload(false)
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('A server error occured', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
    //after getting token value, store it to local storage
    // localStorage.setItem("temp_token", "jwt");
  }

  return (
    <div className="bgLockScreen">
      <div className="form-body">
        <div className="row">
          <div className="img-holder">
            <div className="bg" />

            <h3
              className="bigScreenOnly text-left mr-5"
              style={{ marginTop: '55vh' }}
            >
              Welcome to <span>better</span> healthcare management
            </h3>
            <div className="info-holder"></div>
          </div>
          <div className="form-holder">
            <div className="form-content">
              <div className="form-items">
                <div className="bgCover">
                  <div className="mt-4 page-links text-center">
                    <Link
                      to="/login"
                      className="active"
                      title="Click here to Login as Staff"
                    >
                      Staff Login
                    </Link>
                  </div>

                  <Formik
                    initialValues={initialValues}
                    validationSchema={SignInSchema}
                    onSubmit={(values, { resetForm }) => {
                      handleLogin(values, resetForm)
                    }}
                  >
                    {(formik) => {
                      const { errors, touched, isValid, dirty } = formik

                      return (
                        <Form className="row">
                          <div className="col-md-12 my-2">
                            <Field name="email">
                              {(props) => {
                                return (
                                  <div className="form-group">
                                    <label
                                      className="mb-1 text-white"
                                      htmlFor="email"
                                    >
                                      <strong>
                                        Email or Phone{' '}
                                        <ErrorMessage
                                          name="email"
                                          component="span"
                                          className="help-block warning errorMessage"
                                        />
                                      </strong>
                                    </label>
                                    <input
                                      type="text"
                                      id="email"
                                      // placeholder="Email or phone.."
                                      title="Type your Email or Phone number"
                                      value={props.field.value}
                                      onChange={props.field.onChange}
                                      onBlur={props.field.onBlur}
                                      className={
                                        errors.email && touched.email
                                          ? 'form-control input-error warning'
                                          : 'form-control'
                                      }
                                    />
                                  </div>
                                )
                              }}
                            </Field>
                          </div>

                          <div className="col-md-12 my-2">
                            <Field name="password">
                              {(props) => {
                                return (
                                  <div className="form-group">
                                    <label
                                      className="mb-1 text-white"
                                      htmlFor="password"
                                    >
                                      <strong>
                                        Password
                                        <ErrorMessage
                                          name="password"
                                          component="span"
                                          className="help-block text-danger errorMessage"
                                        />
                                      </strong>
                                    </label>
                                    <input
                                      type="password"
                                      id="password"
                                      title="Type your Password"
                                      // placeholder="Enter password.."
                                      autoComplete="password"
                                      value={props.field.value}
                                      onChange={props.field.onChange}
                                      onBlur={props.field.onBlur}
                                      className={
                                        errors.password && touched.password
                                          ? 'form-control input-error text-danger'
                                          : 'form-control'
                                      }
                                    />
                                  </div>
                                )
                              }}
                            </Field>
                          </div>

                          <div className="col-md-3 text-center">
                            <button
                              disabled={!(dirty && isValid)}
                              type="submit"
                              className="btn btn-light text-primary btn-blocke"
                              title="Click here to Login"
                            >
                              Login
                            </button>
                          </div>
                          <div className="col-md-12"></div>
                          <div className=" col-md-4 text-center my-4">
                            <p className="text-white">
                              <Link
                                to="login"
                                className="text-white"
                                title="Click here to Login as Patient"
                              >
                                Patient login
                              </Link>
                            </p>
                          </div>

                          <div className=" col-md-5 text-center mt-4">
                            <p className="text-white">
                              <Link
                                to="/forgot-password"
                                className="text-white"
                                title="Click here to reset password"
                              >
                                Forgot Password?
                              </Link>
                            </p>
                          </div>
                        </Form>
                      )
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StaffLogin
