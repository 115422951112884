import { useState } from "react";
import Switch from "react-switch";
import { Form } from "react-bootstrap";
const switchColorCompute = (level) => {
  let color = "white";
  let handle = "white";
  switch (level) {
    case "High":
      color = "#ff80ad";
      handle = "#ff005c";
      break;

    case "Medium":
      color = "#fff9e5";
      handle = "#ffc000";
      break;

    case "Low":
      color = "#e6f7fb";
      handle = "#07b0d7";
      break;

    default:
      color = "white";
      handle = "#ff005c";
      break;
  }
  return { color, handle };
};
const SexualFunction = () => {
  const [switched, setSwitched] = useState({id: null, checked: false})
  return (
    <div className='card pl-2 pr-2 mt-3'>
      <p className='meal-profile-text mt-3' style={{ color: "#000" }}>
        Sexual Function
      </p>
      <label>Are you sexualy active?</label>
      <div className='d-flex' style={{ gap: 50 }}>
        <Form.Check id='sex-yes' label='Yes' type='checkbox' />
        <Form.Check id='sex-no' label='No' type='checkbox' />
      </div>
      <div className='d-flex flex-wrap mt-3' style={{ gap: 30 }}>
        <div>
          <label>Erectyle Disfunction?</label>
          <div className='d-flex' style={{ gap: 50 }}>
            <Form.Check id='erectyle-yes' label='Yes' type='checkbox' />
            <Form.Check id='erectyle-no' label='No' type='checkbox' />
          </div>
        </div>
        <div>
          <label>Nitro Oxide drugs?</label>
          <div className='d-flex' style={{ gap: 50 }}>
            <Form.Check id='drug-yes' label='Yes' type='checkbox' />
            <Form.Check id='drug-no' label='No' type='checkbox' />
          </div>
          <input
            type='text'
            placeholder='Drug name'
            className='pl-2'
            style={{ height: 30 }}
          />
        </div>
      </div>
      <div className='d-flex flex-wrap mt-3 mb-3' style={{ gap: 20 }}>
        {["Low", "Medium", "High"].map((level, index) => (
          <div className='d-flex flex-wrap'>
            <label style={{ marginTop: 5, fontWeight: 700 }}>{level}</label>
            <div className={`switch${index + 1}`} key={index}>
              <Switch
                key={index}
                onChange={(e) => setSwitched({ id: index, checked: e })}
                value={switched.checked}
                checked={switched.id === index ? switched.checked : false}
                uncheckedIcon={false}
                checkedIcon={false}
                onHandleColor={switchColorCompute(level).handle}
                offHandleColor={switchColorCompute(level).handle}
                onColor={switchColorCompute(level).color}
                offColor={switchColorCompute(level).color}
                width={80}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SexualFunction;
