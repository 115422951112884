import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'

const ForgotPassword = () => {
  let navigate = useNavigate()
  toast.configure()
  const agreedCounter = 20
  const [showcollect, setShowCollect] = useState('username')
  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  const [counter, setCounter] = useState(agreedCounter)
  const [startCounter, setStartCounter] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [email, setEmail] = useState('')
  const [seePassword, setSeePassword] = useState(false)
  const [canProceed, setCanProceed] = useState(false)
  const [userResetResponse, setUserResetResponse] = useState({})
  const url = `${process.env.REACT_APP_MAIN_URL}/api/auth/forgot-password`

  const disabledStyle = {
    backgroundColor: '#ddc3c3',
    color: '#aeb4b9',
    cursor: 'wait',
  }

  useEffect(() => {
    if (counter === 0) {
      setStartCounter(false)
      setCanProceed(true)
    }
    if (startCounter === true && counter > 0) {
      const timer = setInterval(() => {
        const c = counter - 1
        setCounter(c)
        // console.log('counter decreasing' + counter + c)
      }, 1000)

      return function clr() {
        clearInterval(timer)
      }
    }
  }, [startCounter, counter])

  // function to send code
  const sendEmail = () => {
    const dataToSend = {
      username: email,
    }
    setCounter(agreedCounter)
    setStartCounter(true)
    setCanProceed(false)

    // send request
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // "Access-Control-Request-Headers": "*",
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          })
        }

        // setShowCollect('code')
        return toast.success(
          "Check your phone or email for the code. If you can't see the code, resend the link after the timer ends",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          },
        )
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('A server error occured', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        })
      })
    //after getting token value, store it to local storage
    // localStorage.setItem("temp_token", "jwt");
  }

  // function to verifyCode
  const verifyCode = () => {
    const dataToSend = {
      code,
    }
    const url = `${process.env.REACT_APP_MAIN_URL}/api/auth/reset-password-code-checker`

    // send request
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // "Access-Control-Request-Headers": "*",
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          })
        }
        // console.log(res);
        setUserResetResponse({
          userId: res.data.userId,
          userRole: res.data.userRole,
        })
        setShowCollect('passwords')
        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        })
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('A server error occured', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        })
      })
    //after getting token value, store it to local storage
    // localStorage.setItem("temp_token", "jwt");
  }

  //function to reset passwords
  const resetPasswords = () => {
    const dataToSend = {
      password: password,
      password_confirmation: confirmPassword,
    }
    const url = `${process.env.REACT_APP_MAIN_URL}/api/auth/reset-password?userId=${userResetResponse.userId}&role=${userResetResponse.userRole}`

    // send request
    fetch(url, {
      method: 'PATCH',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // "Access-Control-Request-Headers": "*",
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          })
        }
        navigate('/login')

        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        })
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('A server error occured', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        })
      })
    //after getting token value, store it to local storage
    // localStorage.setItem("temp_token", "jwt");
  }
  return (
    <div className="bgLockScreen">
      <div className="form-body">
        <div className="row">
          <div className="img-holder">
            <div className="bg" />
            <h3
              className="bigScreenOnly text-left mr-5"
              style={{ marginTop: '55vh' }}
            >
              Welcome to <span>better</span> healthcare management
            </h3>
            <div className="info-holder"></div>
          </div>
          <div className="form-holder">
            <div className="form-content">
              <div className="form-items">
                <div className="website-logo">
                  <a href="#">
                    <div className="logo">
                      <img
                        className="logo-size"
                        src="images/logo2.png"
                        alt=""
                      />
                    </div>
                  </a>
                </div>
                <div className={showcollect === 'username' ? 'show' : 'hide'}>
                  <h3>Password reset</h3>
                  <p>
                    To reset your password, enter the email or phone number you
                    used to sign up for HealthCenta
                  </p>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email or phone"
                      required
                    />

                    <div className="form-button d-flex flex-row justify-content-between">
                      <button
                        disabled={!email || startCounter}
                        style={startCounter ? disabledStyle : undefined}
                        onClick={sendEmail}
                        className="ibtn btn-forget"
                      >
                        {startCounter && (
                          <span className="text-green">
                            {' '}
                            ({counter}) <i className="fa fa-spinner fa-spin" />{' '}
                          </span>
                        )}
                        Send reset link
                      </button>
                      {canProceed && (
                        <button
                          disabled={!email}
                          onClick={() => setShowCollect('code')}
                          className="ibtn btn-forget"
                        >
                          Proceed
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {/* collect code */}
                <div className={showcollect === 'code' ? 'show' : 'hide'}>
                  <h3>Enter verification code</h3>
                  <p>
                    Check your email or phone and enter the verification code
                    sent below
                  </p>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      placeholder="Verification Code"
                      required
                    />
                    <div className="form-button full-width">
                      <button
                        disabled={!code}
                        onClick={verifyCode}
                        className="ibtn btn-forget"
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                </div>
                {/* collect passwords */}
                <div className={showcollect === 'passwords' ? 'show' : 'hide'}>
                  <h3>Set new password</h3>

                  <div>
                    <div className="input-wrapper">
                      <input
                        type={seePassword ? 'text' : 'password'}
                        className="form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        requiredtype="text"
                      />
                      <i
                        className={`view fa ${
                          seePassword ? 'fa-eye-slash' : 'fa-eye'
                        }`}
                        onClick={() => setSeePassword(!seePassword)}
                      />
                    </div>
                    <br />
                    <div className="input-wrapper">
                      <input
                        type={seePassword ? 'text' : 'password'}
                        className="form-control"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm Password"
                        required
                      />
                      <i
                        className={`view fa ${
                          seePassword ? 'fa-eye-slash' : 'fa-eye'
                        }`}
                        onClick={() => setSeePassword(!seePassword)}
                      />
                    </div>

                    <div className="form-button full-width">
                      <button
                        disabled={!password || password !== confirmPassword}
                        onClick={resetPasswords}
                        className="ibtn btn-forget"
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
