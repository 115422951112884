import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LeftBar from "../components/LeftBar";
import DataTable from "react-data-table-component";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { selectUser } from "../redux_setup/slices/userSlice";
import Preloader from "../components/Preloader";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Add_HC_Schema } from "../constants/ValidationSchema";

const columns = [
  {
    name: "No",
    selector: (row) => row.count,
    sortable: true,
  },
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row.email,
  },
  {
    name: "Status",
    selector: (row) => (
      <span
        className={row.active === "Active" ? "text-success" : "text-danger"}
      >
        {row.active}
      </span>
    ),
  },
  {
    name: "Phone",
    selector: (row) => row.phone,
  },
  {
    name: "Address",
    selector: (row) => row.address,
  },
];

const HeathCenters = () => {
  toast.configure();
  const [editShow, setEditShow] = useState(false); //edit modal
  const [addShow, setAddShow] = useState(false); //add modal
  const [dataToEdit, setDataToEdit] = useState({});
  const [loading, setLoading] = useState(true);
  const [healthCenters, setHealthCenters] = useState([]);
  const ExpandedComponent = ({ data }) => {
    return (
      <div className="expanded-div py-5 px-5">
        <Button
          variant="success"
          onClick={() => {
            setEditShow(!editShow);
            setDataToEdit(data);
          }}
        >
          <i className="fa fa-pencil"></i> Edit
        </Button>
        <Button
          variant="danger"
          className="ml-5"
          onClick={() => deleteCenter(data.id)}
        >
          <i className="fa fa-trash"></i> Delete
        </Button>
      </div>
    );
  };

  //   Initial values for add
  const Add_HC_initialValues = {
    email: "",
    name: "",
    phone: "",
    address: "",
  };

  // initial values for edit
  //   Initial values for add
  const Edit_HC_initialValues = {
    email: dataToEdit?.email,
    name: dataToEdit?.name,
    phone: dataToEdit?.phone,
    address: dataToEdit?.address,
    active: dataToEdit?.active,
  };

  const abortcontroller = new AbortController();
  const signal = abortcontroller.signal;
  const user = useSelector(selectUser);
  const centersUrl = `${process.env.REACT_APP_MAIN_URL}/api/health-center/`;

  useEffect(() => {
    fetch(centersUrl, {
      signal: signal,
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + user.token,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.error) {
          return toast.warning("An error occured while fetching centers", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          });
        }
        let count = 1;
        res.data.items.forEach((item) => {
          item.count = count;
          item.active = item.active === true ? "Active" : "Inactive";
          count++;
          //->will delete the rest later
        });
        // console.log(res.data);

        res.data?.items?.length > 0 && setHealthCenters(res.data.items);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return toast.warning("An error occured while reaching the server", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        });
      });
    return function cleanup() {
      abortcontroller.abort();
    };
  }, []);

  const addHC = (values, resetForm) => {
    const url = `${process.env.REACT_APP_MAIN_URL}/api/health-center/register`;
    const dataToSend = {
      email: values.email,
      name: values.name,
      phone: values.phone,
      address: values.address,
    };
    // send request
    fetch(url, {
      signal: signal,
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        Authorization: "Bearer " + user.token,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          });
        }
        // console.log(JSON.stringify(dataToSend));
        resetForm();
        setAddShow(!addShow);
        //adjust state_
        console.log(res);
        const newCentersData = [...healthCenters];
        dataToSend.active = "Active";
        newCentersData.unshift(dataToSend);
        let count = 1;
        newCentersData.forEach((item) => {
          item.count = count;
          //   item.active = item.active === true ? "Active" : "Inactive";
          count++;
        });
        setHealthCenters(newCentersData);

        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        });
      })
      .catch((err) => {
        console.log(err);
        return toast.warning("An error occured while reaching the server", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        });
      });
  };

  const editHC = (values) => {
    const url = `${process.env.REACT_APP_MAIN_URL}/api/health-center/${dataToEdit?.id}/update`;
    const dataToSend = {
      email: values.email,
      name: values.name,
      phone: values.phone,
      address: values.address,
      status: values.status,
    };
    // send request
    fetch(url, {
      signal: signal,
      method: "PUT",
      headers: new Headers({
        Accept: "application/json",
        Authorization: "Bearer " + user.token,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          });
        }
        setEditShow(!editShow);
        //adjust state_

        // const newCentersData = [...healthCenters];
        // dataToSend.active = "Active";
        // newCentersData.unshift(dataToSend);
        // let count = 1;
        // newCentersData.forEach((item) => {
        //   item.count = count;
        //   //   item.active = item.active === true ? "Active" : "Inactive";
        //   count++;
        // });
        // setHealthCenters(newCentersData);
        dataToSend.id = dataToEdit.id;

        const newCentersData = [...healthCenters];

        const index = healthCenters.findIndex(
          (item) => item.id === dataToEdit.id
        );
        newCentersData.splice(index, 1, dataToSend);
        let count = 1;
        newCentersData.forEach((item) => {
          item.count = count;
          count++;
        });
        setHealthCenters(newCentersData);

        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        });
      })
      .catch((err) => {
        console.log(err);
        return toast.warning("An error occured while reaching the server", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        });
      });
  };
  const deleteCenter = (id) => {
    const url = `${process.env.REACT_APP_MAIN_URL}/api/health-center/${id}/delete`;
    if (window.confirm("Are you sure you want to delete this cemter?")) {
      // send request
      fetch(url, {
        signal: signal,
        method: "DELETE",
        headers: new Headers({
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
          "Content-Type": "application/json",
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            return toast.warning(res.error, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: false
            });
          }

          //adjust state
          const newCentersData = healthCenters.filter((item) => item.id !== id);

          setHealthCenters(newCentersData);

          return toast.success(res.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          });
        })
        .catch((err) => {
          console.log(err);
          return toast.warning("An error occured while reaching the server", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          });
        });
    }
  };
  return (
    <div id="main-wrapper">
      {/***********************************
    Nav header start
************************************/}
      {/* <NavHeader/> */}
      {/***********************************
    Nav header end
************************************/}

      {/***********************************
    Header start
************************************/}
      <Header />
      {/***********************************
    Header end ti-comment-alt
************************************/}
      {/***********************************
    Sidebar start
************************************/}
      <LeftBar />
      {/***********************************
    Sidebar end
************************************/}
      {/***********************************
    Content body start
************************************/}
      <div className="content-body">
        {loading ? (
          <Preloader />
        ) : (
          <Row>
            <Col md={12}>
              <Button
                variant="info"
                className="ml-3"
                onClick={() => setAddShow(!addShow)}
              >
                <i className="fa fa-plus"></i> Add
              </Button>
            </Col>
         



            <Col md={12}>
              <DataTable
                columns={columns}
                data={healthCenters}
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                pagination
              />
            </Col>
          </Row>
        )}
      </div>
      {/***********************************
    Content body end
************************************/}

      {/***********************************
    Footer start
************************************/}
      <Footer />
      {/***********************************
    Footer end
************************************/}

      {/***********************************
    Edit Modal start
************************************/}
      <Modal
        size="lg"
        show={editShow}
        onHide={() => setEditShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {dataToEdit?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={Edit_HC_initialValues}
            validationSchema={Add_HC_Schema}
            onSubmit={(values) => {
              editHC(values);
            }}
            enableReinitialize
          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <Form className="row">
                  <div className="col-md-6">
                    <Field name="name">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1" htmlFor="name">
                              <strong>Center Name</strong>
                            </label>
                            <input
                              type="text"
                              id="name"
                              placeholder="Enter name.."
                              autoComplete="name"
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.name && touched.name
                                  ? "form-control input-error text-danger"
                                  : "form-control"
                              }
                            />
                            <ErrorMessage
                              name="name"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>

                  <div className="col-md-6">
                    <Field name="email">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1" htmlFor="email">
                              <strong>Email</strong>
                            </label>
                            <input
                              type="email"
                              id="email"
                              placeholder="Enter email.."
                              autoComplete="email"
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.email && touched.email
                                  ? "form-control input-error text-danger"
                                  : "form-control"
                              }
                            />
                            <ErrorMessage
                              name="email"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>

                  <div className="col-md-6">
                    <Field name="phone">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1" htmlFor="phone">
                              <strong>Phone</strong>
                            </label>
                            <input
                              type="number"
                              id="phone"
                              placeholder="Enter phone.."
                              autoComplete="phone"
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.phone && touched.phone
                                  ? "form-control input-error text-danger"
                                  : "form-control"
                              }
                            />
                            <ErrorMessage
                              name="phone"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>

                  <div className="col-md-6">
                    <Field name="address">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1" htmlFor="address">
                              <strong>Address</strong>
                            </label>
                            <textarea
                              id="address"
                              placeholder="Enter address.."
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.address && touched.address
                                  ? "form-control input-error text-danger"
                                  : "form-control"
                              }
                            ></textarea>
                            <ErrorMessage
                              name="address"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>

                  <div className="col-md-6">
                    <Field name="active">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1 " htmlFor="active">
                              <strong>Status</strong>
                            </label>
                            <select
                              id="active"
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.active && touched.active
                                  ? "form-control input-error text-danger"
                                  : "form-control"
                              }
                            >
                              <option value="true" className="text-success">
                                Active
                              </option>
                              <option value="false" className="text-danger">
                                Inactive
                              </option>
                            </select>
                            <ErrorMessage
                              name="active"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>

                  <div className=" col-md-8 text-center mt-4">
                    <Button
                      disabled={!(dirty && isValid)}
                      type="submit"
                      className="btn-block"
                      variant="warning"
                    >
                      Update
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
      {/***********************************
    Edit Modal end
************************************/}
      {/***********************************
    Add Modal start
************************************/}
      <Modal
        size="lg"
        show={addShow}
        onHide={() => setAddShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add a health center
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={Add_HC_initialValues}
            validationSchema={Add_HC_Schema}
            onSubmit={(values, { resetForm }) => {
              addHC(values, resetForm);
            }}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <Form className="row">
                  <div className="col-md-6">
                    <Field name="name">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1" htmlFor="name">
                              <strong>Center Name</strong>
                            </label>
                            <input
                              type="text"
                              id="name"
                              placeholder="Enter name.."
                              autoComplete="name"
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.name && touched.name
                                  ? "form-control input-error text-danger"
                                  : "form-control"
                              }
                            />
                            <ErrorMessage
                              name="name"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>

                  <div className="col-md-6">
                    <Field name="email">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1" htmlFor="email">
                              <strong>Email</strong>
                            </label>
                            <input
                              type="email"
                              id="email"
                              placeholder="Enter email.."
                              autoComplete="email"
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.email && touched.email
                                  ? "form-control input-error text-danger"
                                  : "form-control"
                              }
                            />
                            <ErrorMessage
                              name="email"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>

                  <div className="col-md-6">
                    <Field name="phone">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1" htmlFor="phone">
                              <strong>Phone</strong>
                            </label>
                            <input
                              type="text"
                              id="phone"
                              placeholder="Enter phone.."
                              autoComplete="phone"
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.phone && touched.phone
                                  ? "form-control input-error text-danger"
                                  : "form-control"
                              }
                            />
                            <ErrorMessage
                              name="phone"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>

                  <div className="col-md-6">
                    <Field name="address">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1" htmlFor="address">
                              <strong>Address</strong>
                            </label>
                            <textarea
                              id="address"
                              placeholder="Enter address.."
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.address && touched.address
                                  ? "form-control input-error text-danger"
                                  : "form-control"
                              }
                            ></textarea>
                            <ErrorMessage
                              name="address"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>

                  {/* <div className="col-md-6">
                    <Field name="gender">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1 text-white" htmlFor="gender">
                              <strong>Gender</strong>
                            </label>
                            <select
                              id="gender"
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.gender && touched.gender
                                  ? "form-control input-error text-danger"
                                  : "form-control"
                              }
                            >
                              <option value="">Select</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                            <ErrorMessage
                              name="gender"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div> */}

                  <div className=" col-md-8 text-center mt-4">
                    <Button
                      disabled={!(dirty && isValid)}
                      type="submit"
                      className="btn-block"
                      variant="primary"
                    >
                      Add
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
      {/***********************************
    Add Modal end
************************************/}
    </div>
  );
};

export default HeathCenters;
