import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {
  patientLogin,
  selectPatientData,
} from '../../redux_setup/slices/patientDataSlice'
import { trimDate } from '../../constants/Essentials'

const SignUpCarePlam = () => {
  const [phoneState, setPhoneState] = useState('')
  let navigate = useNavigate()
  let { patientId } = useParams()
  console.log('Entering', patientId)
  toast.configure()
  const dispatch = useDispatch()
  const patientData = useSelector(selectPatientData)
  const [dataToValidate, setDataToValidate] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    birth_date: '',
  })
  const id = atob(patientId)
  console.log(btoa('9ff504d8-e578-4ee0-8b99-cd32661930b1'))
  console.log('id is', id)

  useEffect(() => {
    const fetchPatientUrl = `${process.env.REACT_APP_MAIN_URL}/api/patient/${id}/careplan`

    fetch(fetchPatientUrl, {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        dispatch(
          patientLogin({
            id: res?.data?.id,
            firstName: res.data?.name?.split(' ')[0],
            lastName: res.data?.name?.split(' ')[
              res.data?.name?.split(' ')?.length - 1
            ],
            phone: res?.data?.phone,
            birth_date: trimDate(res?.data?.birth_date, 'date_only'),
            email: res?.data?.email,
            gender: res?.data?.gender,
            nextkin: res?.data?.next_kin,
            address: res?.data?.address,
          }),
        )
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  const validate = (data) => {
    let obj = {
      msg: '',
      isValidated: true,
    }
    if (typeof data === 'undefined' && typeof patientData === 'undefined') {
      return toast.warning('Fill the required fields to continue', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      })
    }
    if (data.firstName === '' && patientData.firstName === '') {
      obj.msg = 'Firstname field is required'
      obj.isValidated = false
    }
    if (data.lastName === '' && patientData.lastName === '') {
      obj.msg = 'Lastname field is required'
      obj.isValidated = false
    }
    if (data.phone === '' && patientData.phone === '') {
      obj.msg = 'Phone number field is required'
      obj.isValidated = false
    }
    if (data.birth_date === '' && patientData.birth_date === '') {
      obj.msg = 'Birth date field is required'
      obj.isValidated = false
    }
    return obj
  }
  const handleSubmit = () => {
    const valid = validate(dataToValidate)
    if (!valid.isValidated) {
      return toast.warning(valid.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      })
    } else {
      navigate('/care-plan-signup-next-page')
    }
  }

  return (
    <div>
      <div
        className="signup-container"
        style={{
          background:
            'linear-gradient(180deg, rgba(69, 183, 87, 0.6) 0%, rgba(47, 165, 219, 0.6) 100%)',
        }}
      >
        <div style={{ marginLeft: '0' }} className="content-body">
          <div className="card card pt-5 pb-3 signup-card">
            <div className="d-flex justify-content-center align-items-center">
              <p
                style={{
                  fontSize: 24,
                  fontWeight: 400,
                  fontFamily: 'Lato',
                  color: '#19226D',
                  marginBottom: -20,
                }}
              >
                Welcome To
              </p>
            </div>
            <div className="text-center" style={{ width: '100%' }}>
              <div className="filipelogos" style={{ marginBottom: -20 }}></div>
              <p
                style={{
                  fontSize: 24,
                  fontWeight: 400,
                  fontFamily: 'Lato',
                  color: '#19226D',
                }}
              >
                Patients Care Plan
              </p>
            </div>
            <div className="ml-5">
              <p
                style={{
                  fontSize: 20,
                  fontWeight: 700,
                  fontFamily: 'Lato',
                  color: '#000',
                }}
              >
                Confirm your details to continue
              </p>
              <div className="row">
                <div
                  className="col-md-4 col-lg-4 col-sm-12"
                  style={{ marginRight: -20 }}
                >
                  <label>
                    First Name <span style={{ color: 'red' }}>*</span>
                  </label>
                  <Form.Control
                    type="text"
                    placeholder="Enter first name"
                    spellcheck="true"
                    required
                    value={patientData?.firstName}
                    onChange={(e) => {
                      dispatch(
                        patientLogin({
                          ...patientData,
                          firstName: e.target.value,
                        }),
                      )
                      setDataToValidate({
                        ...dataToValidate,
                        firstName: e.target.value,
                      })
                    }}
                  />
                </div>
                <div
                  className="col-md-4 col-lg-4 col-sm-12"
                  style={{ marginRight: -20 }}
                >
                  <label>Middle Name</label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Enter middle name"
                    spellcheck="true"
                    value={patientData?.middleName}
                    onChange={(e) =>
                      dispatch(
                        patientLogin({
                          ...patientData,
                          middleName: e.target.value,
                        }),
                      )
                    }
                  />
                </div>
                <div className="col-md-4 col-lg-4 col-sm-12">
                  <label>
                    Last Name <span style={{ color: 'red' }}>*</span>
                  </label>
                  <Form.Control
                    type="text"
                    required
                    spellcheck="true"
                    placeholder="Enter last name"
                    value={patientData?.lastName}
                    onChange={(e) => {
                      dispatch(
                        patientLogin({
                          ...patientData,
                          lastName: e.target.value,
                        }),
                      )
                      setDataToValidate({
                        ...dataToValidate,
                        lastName: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div
                  className="col-md-4 col-lg-4 col-sm-12"
                  style={{ marginRight: -20 }}
                >
                  <label>Email </label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Enter email"
                    spellcheck="true"
                    name="email"
                    value={patientData?.email}
                    onChange={(e) =>
                      dispatch(
                        patientLogin({
                          ...patientData,
                          email: e.target.value,
                        }),
                      )
                    }
                  />
                </div>
                <div
                  className="col-md-4 col-lg-4 col-sm-12"
                  style={{ marginRight: -20 }}
                >
                  <label>
                    Date of birth <span style={{ color: 'red' }}>*</span>
                  </label>
                  <Form.Control
                    type="date"
                    required
                    value={patientData?.birth_date}
                    onChange={(e) => {
                      dispatch(
                        patientLogin({
                          ...patientData,
                          birth_date: e.target.value,
                        }),
                      )
                      setDataToValidate({
                        ...dataToValidate,
                        birth_date: e.target.value,
                      })
                    }}
                  />
                </div>
                <div className="col-md-4 col-lg-4 col-sm-12">
                  <label>
                    Phone number <span style={{ color: 'red' }}>*</span>
                  </label>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={patientData?.phone}
                    onBlur={() =>
                      dispatch(
                        patientLogin({
                          ...patientData,
                          phone: phoneState,
                        }),
                      )
                    }
                    onChange={setPhoneState}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between flex-wrap">
                <Button
                  className="mt-4"
                  variant="success"
                  style={{ width: 171, height: 40, fontWeight: 700 }}
                  onClick={handleSubmit}
                >
                  Next <i className="fas fa-arrow-right ml-2" />
                </Button>
                <div className="d-flex flex-wrap mt-3 mr-5" style={{ gap: 20 }}>
                  <p
                    style={{
                      fontSize: 14,
                      fontFamily: 'Lato',
                      fontWeight: 400,
                      color: '#52575C',
                    }}
                  >
                    Already registered?
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      fontFamily: 'Lato',
                      fontWeight: 400,
                      color: '#0193D9',
                      borderBottom: '1px solid #0193D9',
                      cursor: 'pointer',
                    }}
                  >
                    <Link to="/login">Login</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/***********************************
            Footer start
        ************************************/}
      {/***********************************
            Footer end
        ************************************/}
    </div>
  )
}

export default SignUpCarePlam
