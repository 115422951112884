import { useState } from 'react'
import Header from '../../Header'
import Footer from '../../Footer'
import LeftBar from '../../LeftBar'
import { Button, Form } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
const MentalHealth = () => {
  toast.configure()
  const navigate = useNavigate()
  const [mood, setMood] = useState(0)
  const toaster = () => {
    navigate('/careplan-dashboard')
    return toast.success('Saved successfully', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 15000,
    })
  }
  return (
    <div className='main-wrapper'>
      {/***********************************
                  Header start
                  ************************************/}
      <Header />
      {/***********************************
                  Header end ti-comment-alt
                  ************************************/}
      {/***********************************
                  Sidebar start
                  ************************************/}
      <LeftBar />
      {/***********************************
                  Sidebar end
                  ************************************/}
      {/***********************************
                  Content body start
                  ************************************/}

      {/***********************************
                  Content body end
                  ************************************/}
      <div className='content-body'>
        <p
          className='mt-3 mb-3'
          style={{
            fontSize: 20,
            fontFamily: "Lato",
            fontWeight: 700,
            color: "#000",
          }}
        >
          Mental Health
        </p>
        <div className='row'>
          <div className='col-md-8 col-lg-8 col-sm-12'>
            <div className='card pl-4 pb-3' style={{ height: 108 }}>
              <p className='meal-profile-text mt-3' style={{ color: "#000" }}>
                Daily Stress Level
              </p>
              <span
                style={{
                  fontWeight: 400,
                  fontFamily: "Lato",
                  fontSize: 14,
                  color: "#52575C",
                }}
                className='mt-2'
              >
                How is your mood today?
              </span>
            </div>
            <div className='card' style={{ height: 132 }}>
              <div className='d-flex justify-content-around'>
                <div className='mt-4'>
                  <span>😄</span>
                  <p
                    style={{
                      fontWeight: 700,
                      fontSize: 14,
                      fontFamily: "Lato",
                      color: "#8F9397",
                    }}
                    className='mt-2'
                  >
                    Smiley face - Low
                    <br /> Stress (0-13)
                  </p>
                </div>
                <div className='mt-4'>
                  <span>🙂</span>
                  <p
                    style={{
                      fontWeight: 700,
                      fontSize: 14,
                      fontFamily: "Lato",
                      color: "#8F9397",
                    }}
                    className='mt-2'
                  >
                    Neutral face - Moderate
                    <br /> Stress (14-26)
                  </p>
                </div>
                <div className='mt-4'>
                  <span>😒</span>
                  <p
                    style={{
                      fontWeight: 700,
                      fontSize: 14,
                      fontFamily: "Lato",
                      color: "#8F9397",
                    }}
                    className='mt-2'
                  >
                    Frowny face - High
                    <br /> Stress (27-40)
                  </p>
                </div>
              </div>
            </div>
            <div className='card pt-3 pl-3 pr-3' style={{ height: 232 }}>
              <span
                style={{
                  fontWeight: 400,
                  fontFamily: "Lato",
                  fontSize: 14,
                  color: "#52575C",
                }}
                className='mb-4'
              >
                How is your mood today?
              </span>
              <div
                className='pt-3 mt-5'
                style={{ width: "100%", position: "relative" }}
              >
                <span
                  style={{
                    position: "absolute",
                    left: `${mood * (18.7 / 795) * 100}%`,
                    bottom: 30,
                    fontSize: 40,
                  }}
                >
                  {mood < 14 ? "😄" : mood < 27 ? "🙂" : "😒"}
                </span>
                <Form.Range
                  step={1}
                  min={0}
                  max={40}
                  value={mood}
                  style={{ width: "100%" }}
                  onChange={(e) => setMood(e.target.value)}
                />
                <span
                  style={{
                    position: "absolute",
                    fontSize: 10,
                    left: "1%",
                    bottom: -20,
                  }}
                >
                  0
                </span>
                <span
                  style={{
                    position: "absolute",
                    fontSize: 10,
                    left: "25%",
                    bottom: -20,
                  }}
                >
                  10
                </span>
                <span
                  style={{
                    position: "absolute",
                    fontSize: 10,
                    left: "50%",
                    bottom: -20,
                  }}
                >
                  20
                </span>
                <span
                  style={{
                    position: "absolute",
                    fontSize: 10,
                    left: "75%",
                    bottom: -20,
                  }}
                >
                  30
                </span>
                <span
                  style={{
                    position: "absolute",
                    fontSize: 10,
                    left: "98%",
                    bottom: -20,
                  }}
                >
                  40
                </span>
              </div>
            </div>
          </div>
          <div className='col-md-4 col-lg-4 col-sm-12' style={{ height: 263 }}>
            <div className='card pt-4 pl-2'>
              <p
                style={{
                  fontSize: 24,
                  fontWeight: 700,
                  fontFamily: "Lato",
                  color: "#662382",
                }}
                className='ml-4'
              >
                Week Average Emoji
              </p>
              <div
                className='pl-2 pr-2 ml-3 mr-3 mb-3'
                style={{
                  border: "1px #662382 solid",
                  borderRadius: 8,
                  height: 156,
                }}
              >
                <div className='mt-4' style={{ textAlign: "center" }}>
                  <span style={{ fontSize: 40 }}>
                    {mood < 14 ? "😄" : mood < 27 ? "🙂" : "😒"}
                  </span>
                </div>
                <Button
                  style={{
                    backgroundColor: "#FF005C3D",
                    border: "1px #FF005C3D solid",
                    width: "100%",
                  }}
                >
                  <span
                    style={{ color: "#662382", fontWeight: 700, fontSize: 16 }}
                  >
                    {mood < 14 ? "Low" : mood < 27 ? "Medium" : "High"}
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex' style={{ gap: 10 }}>
          <Link to='/new-habit-breaker-care-plan' style={{ width: "20%" }}>
            <Button variant='success' style={{ width: "100%" }}>
              Back
            </Button>
          </Link>
          <Button variant='success' style={{ width: "20%" }} onClick={toaster}>
            Done
          </Button>
        </div>
      </div>
      {/***********************************
                  Footer start
                  ************************************/}
      <Footer />
      {/***********************************
                  Footer end
                  ************************************/}
    </div>
  );
}

export default MentalHealth
