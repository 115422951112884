import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const VitalProfile = ({
  temperature,
  heart_rate,
  blood_pressure,
  weight,
  submitHandler,
}) => {
  return (
    <div className="card pl-2 pt-2 pr-2 pb-2">
      <p
        className="mt-3 mb-3"
        style={{
          fontSize: 24,
          fontFamily: 'Lato',
          fontWeight: 700,
          color: '#662382',
        }}
      >
        Vitals Profile
      </p>
      <div
        className="d-flex justify-content-between pt-1 pl-1 pr-1 pb-1"
        style={{
          borderRadius: 5,
          border: '1px solid #662382',
          padding: 10,
          height: 48,
        }}
      >
        <span
          style={{
            fontSize: 16,
            fontFamily: 'Lato',
            fontWeight: 700,
            color: '#662382',
            marginTop: 10,
          }}
        >
          Blood Pressure
        </span>
        <span
          style={{
            fontSize: 30,
            fontFamily: 'Lato',
            color: '#662382',
            marginRight: 10,
            marginLeft: 30,
          }}
        >
          |
        </span>
        <Button
          style={{
            backgroundColor: '#97CE5157',
            border: '1px solid #97CE5157',
            marginLeft: 30,
          }}
        >
          {String(blood_pressure).toUpperCase()}
        </Button>
      </div>
      <div
        className="d-flex justify-content-between pt-1 pl-1 pr-1 pb-1"
        style={{
          borderRadius: 5,
          border: '1px solid #662382',
          marginTop: 10,
          height: 48,
        }}
      >
        <span
          style={{
            fontSize: 16,
            fontFamily: 'Lato',
            fontWeight: 700,
            color: '#662382',
            marginTop: 10,
          }}
        >
          Heart Rate
        </span>
        <span
          style={{
            fontSize: 30,
            fontFamily: 'Lato',
            color: '#662382',
            marginRight: 10,
            marginLeft: 30,
          }}
        >
          |
        </span>
        <Button
          style={{
            backgroundColor: '#FF005C3D',
            border: '1px solid #FF005C3D',
            marginLeft: 30,
          }}
        >
          {String(heart_rate).toUpperCase()}
        </Button>
      </div>
      <div
        className="d-flex justify-content-between pt-1 pl-1 pr-1 pb-1"
        style={{
          borderRadius: 5,
          border: '1px solid #662382',
          marginTop: 10,
          height: 48,
        }}
      >
        <span
          style={{
            fontSize: 16,
            fontFamily: 'Lato',
            fontWeight: 700,
            color: '#662382',
            marginTop: 10,
          }}
        >
          Weight
        </span>
        <span
          style={{
            fontSize: 30,
            fontFamily: 'Lato',
            color: '#662382',
            marginRight: 10,
          }}
        >
          |
        </span>
        <Button
          style={{
            backgroundColor: '#D60A5199',
            border: '1px solid #D60A5199',
            marginLeft: 30,
          }}
        >
          {String(weight).toUpperCase()}
        </Button>
      </div>
      <div
        className="d-flex justify-content-between pt-1 pl-1 pr-1 pb-1"
        style={{
          borderRadius: 5,
          border: '1px solid #662382',
          marginTop: 10,
          height: 48,
        }}
      >
        <span
          style={{
            fontSize: 16,
            fontFamily: 'Lato',
            color: '#662382',
            marginTop: 10,
          }}
        >
          Body Temperature
        </span>
        <span
          style={{
            fontSize: 30,
            fontFamily: 'Lato',
            color: '#662382',
            marginRight: 10,
            marginLeft: -10,
          }}
        >
          |
        </span>
        <Button
          style={{
            backgroundColor: '#D60A5199',
            border: '1px solid #D60A5199',
            marginLeft: 30,
          }}
        >
          {String(temperature).toUpperCase()}
        </Button>
      </div>
      <div className="d-flex flex-row justify-content-between" style={{gap: 10}}>
        <Link to="/careplan-dashboard" style={{width: '50%'}}>
          <Button
            className="mt-2"
            variant="primary"
            style={{ width: '100%' }}
            // onClick={submitHandler}
          >
            Back
          </Button>
        </Link>
        <Link to="/new-nutrition" style={{width: '50%'}}>
          <Button
            className="mt-2"
            variant="success"
            style={{ width: '100%' }}
            // onClick={submitHandler}
          >
            Next
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default VitalProfile
