import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const HabitBreaker = ({ data }) => {
  return (
    <div className='card pl-2 pr-2 pb-5'>
      <p className='meal-profile-text' style={{ color: "#000", fontSize: 20 }}>
        Habit Breaker Care Plan
      </p>
      <div
        className='card pl-4 pr-4 careplan-title'
        style={{
          background: "rgba(171, 179, 253, 0.25)",
          height: 35,
          width: "85%",
        }}
      >
        <p
          className='meal-profile-text pr-2'
          style={{
            color: "#000",
            fontWeight: 700,
            fontSize: 20,
            fontFamily: "Lato",
          }}
        >
          Suggested Outcome: {data?.habit_suggested_outcome}
        </p>
      </div>
      <div className='row'>
        <div className='col-lg-4 col-md-4 col-6'>
          <div
            className='card pl-2 pt-2 pr-2'
            style={{ background: "rgba(208, 224, 255, 0.5)" }}
          >
            <div className='card pl-2 pt-2' style={{ background: "#0F56B333" }}>
              <p className='mobo-card-text'>Daily Drinking Level</p>
              <span className='show-on-mobile'>(Sticks/day)</span>
            </div>
            <div
              className='d-flex flex-wrap justify-content-between'
              style={{ marginTop: -20 }}
            >
              <div>
                <small
                  style={{
                    fontSize: 10,
                    fontWeight: 400,
                    fontFamily: "Lato",
                    color: "#0D5CA4",
                  }}
                  className='ml-3 mt-2'
                >
                  Current
                </small>
                <h4 className='ml-3 mt-2 d-flex'>
                  0
                  {/* <small
                        className='dont-show-on-mobile mt-2 ml-2'
                        style={{ fontSize: 14 }}
                      >
                        Sticks/day
                      </small> */}
                </h4>
              </div>
              <div>
                <small
                  style={{
                    fontSize: 10,
                    fontWeight: 400,
                    fontFamily: "Lato",
                    color: "#0D5CA4",
                  }}
                  className='ml-3 mt-2'
                >
                  Target
                </small>
                <h4 className='ml-3 mt-2 d-flex'>
                  {data?.habit_target_drinking}
                  {/* <small
                      className='dont-show-on-mobile mt-2 ml-2'
                      style={{ fontSize: 14 }}
                    >
                      Sticks/day
                    </small> */}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-4 col-md-4 col-6'>
          <div>
            <div
              className='card pl-2 pt-2 pr-2'
              style={{ background: "rgba(208, 224, 255, 0.5)" }}
            >
              <div
                className='card pl-2 pt-2'
                style={{ background: "#0F56B333" }}
              >
                <p className='mobo-card-text'>Daily Smoking Level</p>
                <span className='show-on-mobile'>(Sticks/day)</span>
              </div>
              <div
                className='d-flex flex-wrap justify-content-between'
                style={{ marginTop: -20 }}
              >
                <div>
                  <small
                    style={{
                      fontSize: 10,
                      fontWeight: 400,
                      fontFamily: "Lato",
                      color: "#0D5CA4",
                    }}
                    className='ml-3 mt-2'
                  >
                    Current
                  </small>
                  <h4 className='ml-3 mt-2 d-flex'>
                    24
                    <small
                      className='dont-show-on-mobile mt-2 ml-2'
                      style={{ fontSize: 14 }}
                    >
                      Sticks/day
                    </small>
                  </h4>
                </div>
                <div>
                  <small
                    style={{
                      fontSize: 10,
                      fontWeight: 400,
                      fontFamily: "Lato",
                      color: "#0D5CA4",
                    }}
                    className='ml-3 mt-2'
                  >
                    Target
                  </small>
                  <h4 className='ml-3 mt-2 d-flex'>
                    {data?.habit_target_smoking}
                    {/* <small
                      className='dont-show-on-mobile mt-2 ml-2'
                      style={{ fontSize: 14 }}
                    >
                      Sticks/day
                    </small> */}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-4 col-md-4 col-12'>
          <div>
            <div
              className='card pl-2 pt-2 pr-2'
              style={{ background: "rgba(208, 224, 255, 0.5)" }}
            >
              <div
                className='card pl-2 pt-2'
                style={{ background: "#0F56B333" }}
              >
                <p className='mobo-card-text'>Daily Smoking Level</p>
                <span className='show-on-mobile'>(Sticks/day)</span>
              </div>
              <div
                className='d-flex flex-wrap justify-content-between'
                style={{ marginTop: -20 }}
              >
                <div>
                  <small
                    style={{
                      fontSize: 10,
                      fontWeight: 400,
                      fontFamily: "Lato",
                      color: "#0D5CA4",
                    }}
                    className='ml-3 mt-2'
                  >
                    Current
                  </small>
                  <h4 className='ml-3 mt-2 d-flex'>
                    24
                    <small
                      className='dont-show-on-mobile mt-2 ml-2'
                      style={{ fontSize: 14 }}
                    >
                      Sticks/day
                    </small>
                  </h4>
                </div>
                <div>
                  <small
                    style={{
                      fontSize: 10,
                      fontWeight: 400,
                      fontFamily: "Lato",
                      color: "#0D5CA4",
                    }}
                    className='ml-3 mt-2'
                  >
                    Target
                  </small>
                  <h4 className='ml-3 mt-2 d-flex'>
                    {data?.habit_target_substance}
                    {/* <small
                      className='dont-show-on-mobile mt-2 ml-2'
                      style={{ fontSize: 14 }}
                    >
                      Sticks/day
                    </small> */}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button
          style={{
            backgroundColor: "#11195A",
            width: "97%",
            height: "15%",
          }}
          className='mb-2'
        >
          Update Habit breaker Profile
        </Button>
      </div>
    </div>
  );
}

export default HabitBreaker
