import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectBreakfast,
  selectDinner,
  selectLunch,
  selectSnacks,
  selectSummary,
  updateSummary,
} from '../../redux_setup/slices/nutritionBasketSlice'
function Mealprofile() {
  //pull from redux
  const breakfast = useSelector(selectBreakfast)
  const lunch = useSelector(selectLunch)
  const dinner = useSelector(selectDinner)
  const snacks = useSelector(selectSnacks)
  const summary = useSelector(selectSummary)
  const dispatch = useDispatch()
  const combined = [...breakfast, ...lunch, ...dinner, ...snacks]
  const calculate = () => {
    let calories = 0
    let sodium = 0
    let fats = 0
    //combine arrays

    console.log(combined)
    combined.map((brk) => {
      calories += brk.calories * brk.count
      sodium += brk.sodium * brk.count
      fats += brk.fats * brk.count
    })
    const item = {
      calories: Number(calories).toFixed(2),
      fats: Number(fats).toFixed(2),
      sodium: Number(sodium).toFixed(2),
    }
    dispatch(updateSummary(item))

    console.log(calories, fats, sodium)
  }
  useEffect(() => {
    calculate()

    // return () => {
    //   second
    // }
  }, [breakfast, lunch, dinner, snacks])

  return (
    <>
      <p
        className="mt-3 mb-3"
        style={{
          fontSize: 24,
          fontFamily: 'Lato',
          fontWeight: 700,
          color: '#662382',
        }}
      >
        Meal Profile
      </p>
      <div
        className="d-flex"
        style={{
          borderRadius: 5,
          border: '1px solid #662382',
          padding: 10,
        }}
      >
        <span
          style={{
            fontSize: 16,
            fontFamily: 'Lato',
            fontWeight: 700,
            color: '#662382',
            marginTop: 10,
          }}
        >
          Total Calorie Intake |
        </span>
        <span
          style={{
            fontSize: 30,
            fontFamily: 'Lato',
            fontWeight: 700,
            color: '#662382',
            marginRight: 10,
          }}
        >
          {summary.calories}
        </span>
        <span style={{ color: '#662382', marginTop: 10 }}>kcal</span>
        {/* <Button
          style={{
            backgroundColor: "#97CE5157",
            border: "1px solid #97CE5157",
            marginLeft: 30,
          }}
        >
          OPTIMAL
        </Button> */}
      </div>
      <div
        className="d-flex"
        style={{
          borderRadius: 5,
          border: '1px solid #662382',
          padding: 10,
          marginTop: 10,
        }}
      >
        <span
          style={{
            fontSize: 16,
            fontFamily: 'Lato',
            fontWeight: 700,
            color: '#662382',
            marginTop: 10,
          }}
        >
          Total Sodium Intake |
        </span>
        <span
          style={{
            fontSize: 30,
            fontFamily: 'Lato',
            fontWeight: 700,
            color: '#662382',
            marginRight: 10,
          }}
        >
          {summary.sodium}
        </span>
        <span style={{ color: '#662382', marginTop: 10 }}>kcal</span>
        {/* <Button
          style={{
            backgroundColor: "#FF005C3D",
            border: "1px solid #FF005C3D",
            marginLeft: 30,
          }}
        >
          LOW
        </Button> */}
      </div>
      <div
        className="d-flex"
        style={{
          borderRadius: 5,
          border: '1px solid #662382',
          padding: 10,
          marginTop: 10,
        }}
      >
        <span
          style={{
            fontSize: 16,
            fontFamily: 'Lato',
            fontWeight: 700,
            color: '#662382',
            marginTop: 10,
          }}
        >
          Total Fats Intake |
        </span>
        <span
          style={{
            fontSize: 30,
            fontFamily: 'Lato',
            fontWeight: 700,
            color: '#662382',
            marginRight: 10,
          }}
        >
          {summary.fats}
        </span>
        <span style={{ color: '#662382', marginTop: 10 }}>kcal</span>
        {/* <Button
          style={{
            backgroundColor: "#D60A5199",
            border: "1px solid #D60A5199",
            marginLeft: 30,
          }}
        >
          HIGH
        </Button> */}
      </div>
    </>
  )
}

export default Mealprofile
