import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../redux_setup/slices/userSlice'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Card, Form } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import { deselect, extract_text } from '../../constants/Essentials'
import Bubble from './Bubble'
import Counter from './Counter'

const table_data = [
  {
    drug: 'Cigarette',
    qty: 1,
    concentration: '20mg Nicotine',
  },
  {
    drug: 'Vape',
    qty: '20ml tank with 20mg',
    concentration: '40mg Nicotine',
  },
  {
    drug: 'Regular Beer',
    qty: '12 Ounces',
    concentration: '5% Alcohol',
  },
  {
    drug: 'Light Beer',
    qty: '13 Ounces',
    concentration: '4.2% Alcohol',
  },
  {
    drug: 'Wine',
    qty: '5 Ounces',
    concentration: '12% Alcohol',
  },
  {
    drug: 'Spirits',
    qty: '1.5 Ounces',
    concentration: '40% Alcohol',
  },
]
const MentalCarePlanComponent = ({ patientDetails }) => {
  const [habitSelected, setHabitSelected] = useState([])
  const [alcoholSelected, setAlcoholSelected] = useState([])
  const [otherSubstanceSelected, setOtherSubstanceSelected] = useState([])
  const [dataToSend, setDataToSend] = useState({
    patient_id: patientDetails?.patient_id,
    smoking: { number: 0 },
    alcohol: { percentage: 0 },
    other: {},
  })
  const user = useSelector(selectUser)
  toast.configure()
  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  const handleSubmit = () => {
    console.log('done')
  }
  return (
    <div className="margin__container">
      <h2 className="nutrition__title">Mental health</h2>
      <Card>
        <div className="row ">
          <div className="col-md-7 col-sm-12 ">
            <div className="card">
              <div className="card-body text-left">
                <p className="subtitle">Smoking</p>
                <p className="habit__subtitle">Substance</p>

                <Form>
                  {['checkbox'].map((type) => (
                    <div key={`default-${type}`} className="mb-3">
                      <Form.Check
                        type={type}
                        id={`default-${type}`}
                        label={`Cigarette`}
                        onChange={(e) => {
                          const checked = extract_text(
                            e.target.nextElementSibling.innerText,
                          )
                          if (e.target.checked) {
                            setHabitSelected([...habitSelected, checked])
                            dataToSend.smoking[checked] = e.target.checked
                            setDataToSend({
                              ...dataToSend,
                              smoking: {
                                ...dataToSend.smoking,
                              },
                            })
                            console.log(
                              'smoking data to send =>',
                              dataToSend.smoking,
                            )
                          } else {
                            setHabitSelected(deselect(habitSelected, checked))
                            dataToSend.smoking[checked] = false
                            console.log(
                              'updated smoking data=>',
                              dataToSend.smoking,
                            )
                          }
                        }}
                      />
                      <Form.Check
                        type={type}
                        id={`default-${type}-1`}
                        label={`Vape`}
                        onChange={(e) => {
                          const checked = extract_text(
                            e.target.nextElementSibling.innerText,
                          )
                          if (e.target.checked) {
                            setHabitSelected([...habitSelected, checked])
                            dataToSend.smoking[checked] = e.target.checked
                            setDataToSend({
                              ...dataToSend,
                              smoking: {
                                ...dataToSend.smoking,
                              },
                            })
                          } else {
                            setHabitSelected(deselect(habitSelected, checked))
                            dataToSend.smoking[checked] = false
                          }
                        }}
                      />
                    </div>
                  ))}
                </Form>

                <label className="habit__small__text">Number</label>
                <Counter
                  leftPosition={dataToSend.smoking?.number}
                  times={13.5}
                  data={dataToSend.smoking?.number}
                />
                <div>
                  <Form.Range
                    min={0}
                    max={20}
                    value={dataToSend.smoking?.number}
                    style={{ width: 301 }}
                    onChange={(e) => {
                      setDataToSend({
                        ...dataToSend,
                        smoking: {
                          ...dataToSend.smoking,
                          number: e.target.value,
                        },
                      })
                    }}
                  />
                </div>

                <label className="habit__small__text" style={{ marginTop: 30 }}>
                  Time of Day
                </label>
                <Form>
                  {['checkbox'].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        inline
                        label="Morning"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                        onChange={(e) => {
                          const checked = extract_text(
                            e.target.nextElementSibling.innerText,
                          )
                          if (e.target.checked) {
                            setHabitSelected([...habitSelected, checked])
                          } else {
                            setHabitSelected(deselect(habitSelected, checked))
                          }
                        }}
                      />
                      <Form.Check
                        inline
                        label="Afternoon"
                        name="group1"
                        type={type}
                        id={`inline-${type}-2`}
                        onChange={(e) => {
                          const checked = extract_text(
                            e.target.nextElementSibling.innerText,
                          )
                          if (e.target.checked) {
                            setHabitSelected([...habitSelected, checked])
                          } else {
                            setHabitSelected(deselect(habitSelected, checked))
                          }
                        }}
                      />
                      <Form.Check
                        inline
                        label="Evening"
                        name="group1"
                        type={type}
                        id={`inline-${type}-3`}
                        onChange={(e) => {
                          const checked = extract_text(
                            e.target.nextElementSibling.innerText,
                          )
                          if (e.target.checked) {
                            setHabitSelected([...habitSelected, checked])
                          } else {
                            setHabitSelected(deselect(habitSelected, checked))
                          }
                        }}
                      />
                      <Form.Check
                        inline
                        label="Night"
                        name="group1"
                        type={type}
                        id={`inline-${type}-4`}
                        onChange={(e) => {
                          const checked = extract_text(
                            e.target.nextElementSibling.innerText,
                          )
                          if (e.target.checked) {
                            setHabitSelected([...habitSelected, checked])
                          } else {
                            setHabitSelected(deselect(habitSelected, checked))
                          }
                        }}
                      />
                    </div>
                  ))}
                </Form>
              </div>
            </div>
          </div>
          <div className="col-md-5 col-sm-12">
            <h5 style={{ margin: 15 }}>
              Drug quantity, concentration & intake specifications
            </h5>
            <div>
              <div className="card">
                <div className="table-responsive table-full-width">
                  <Table striped borderless hover>
                    <thead>
                      <tr>
                        <th>Drug</th>
                        <th>Quantity</th>
                        <th>Contentration/delivery</th>
                      </tr>
                    </thead>
                    <tbody>
                      {table_data.map((data, index) => (
                        <tr key={index}>
                          <td>{data?.drug}</td>
                          <td>{data?.qty}</td>
                          <td>{data?.concentration}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="col-md-7 col-sm-12 ">
            <div className="card" style={{ overflowX: 'scroll' }}>
              <div className="card-body">
                <Bubble
                  selected={habitSelected}
                  isGrey={true}
                  width={75}
                  height={75}
                  right={140}
                />
              </div>
            </div>
          </div>
          <div className="col-md-5 col-sm-12">
            <div
              className="card"
              style={{
                backgroundColor: 'rgba(226, 237, 217, 1)',
                paddingLeft: 15,
              }}
            >
              <p className="bubble__side__title mgn">Smoking profile</p>
              <div className="flex__container" style={{ gap: 0 }}>
                <div className="flex1" style={{ backgroundColor: '#FF005C3D' }}>
                  <label className="low">Substance profile</label>
                </div>
                <div className="flex2">
                  <strong className="large__text">
                    {dataToSend.smoking.number}
                  </strong>
                  <small className="profile__small__text">
                    Cigarettes per day
                  </small>
                </div>
                <div className="flex3" style={{ backgroundColor: '#FF005C80' }}>
                  <p className="low">
                    {dataToSend.smoking?.number < 6
                      ? 'Low'
                      : dataToSend.smoking?.number < 8
                      ? 'Normal'
                      : 'High'}
                  </p>
                </div>
              </div>
              {/* Nicotine profile */}
              {/* <p className="bubble__side__title mgn">Nicotine Profile</p>
              <div className="flex__container" style={{ gap: 0 }}>
                <div className="flex1" style={{ backgroundColor: '#FF005C3D' }}>
                  <label className="low">Concentration mg/ml</label>
                </div>
                <div className="flex2">
                  <strong className="large__text">80</strong>
                  <small className="profile__small__text">mg/dl</small>
                </div>
                <div className="flex3" style={{ backgroundColor: '#FF005C80' }}>
                  <p className="low">High</p>
                </div>
              </div>
              <p className="bubble__side__title">Nicotine Profile</p>
              <div class="single__button">
                <strong className="large__text">High</strong>
              </div> */}
              {/* Nicotine profile end */}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-7 col-sm-12 ">
            <div className="card">
              <div className="card-body text-left">
                <p className="subtitle">Alcohol</p>
                <p
                  className="habit__subtitle"
                  style={{ marginTop: -10, marginBottom: 20 }}
                >
                  Substance
                </p>

                <div
                  className="flex__container"
                  style={{ flexWrap: 'wrap', gap: -20 }}
                >
                  {[
                    'Gin',
                    'Whisky',
                    'Brandy',
                    'Wine',
                    'Regular Beer',
                    'Light Beer',
                    'Spirits',
                  ].map((label, index) => (
                    <div key={index}>
                      <Form.Check
                        inline
                        label={label}
                        name="group1"
                        type="checkbox"
                        id={label}
                        onChange={(e) => {
                          const checked = extract_text(
                            e.target.nextElementSibling.innerText,
                          )
                          if (e.target.checked) {
                            setAlcoholSelected([...alcoholSelected, checked])
                            dataToSend.alcohol[checked] = e.target.checked
                            setDataToSend({
                              ...dataToSend,
                              alcohol: {
                                ...dataToSend.alcohol,
                              },
                            })
                            console.log(
                              'alcohol data to send =>',
                              dataToSend.alcohol,
                            )
                          } else {
                            setAlcoholSelected(
                              deselect(alcoholSelected, checked),
                            )
                            dataToSend.alcohol[checked] = false
                            console.log(
                              'upadted alcohol data =>',
                              dataToSend.alcohol,
                            )
                          }
                        }}
                      />
                    </div>
                  ))}
                </div>

                <label className="habit__small__text">Alcohol percentage</label>
                <Counter
                  leftPosition={dataToSend.alcohol?.percentage}
                  times={2.7}
                  data={dataToSend.alcohol?.percentage}
                />
                <div>
                  <Form.Range
                    min={0}
                    max={100}
                    value={dataToSend.alcohol?.percentage}
                    style={{ width: 301 }}
                    onChange={(e) => {
                      setDataToSend({
                        ...dataToSend,
                        alcohol: {
                          ...dataToSend.alcohol,
                          percentage: e.target.value,
                        },
                      })
                    }}
                  />
                </div>
                <label className="habit__small__text" style={{ marginTop: 30 }}>
                  Time of Day
                </label>
                <Form>
                  {['checkbox'].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        inline
                        label="Morning"
                        name="group1"
                        type={type}
                        id={`inline-${type}-65`}
                        onChange={(e) => {
                          const checked = extract_text(
                            e.target.nextElementSibling.innerText,
                          )
                          if (e.target.checked) {
                            setAlcoholSelected([...alcoholSelected, checked])
                          } else {
                            setAlcoholSelected(
                              deselect(alcoholSelected, checked),
                            )
                          }
                        }}
                      />
                      <Form.Check
                        inline
                        label="Afternoon"
                        name="group1"
                        type={type}
                        id={`inline-${type}-12`}
                        onChange={(e) => {
                          const checked = extract_text(
                            e.target.nextElementSibling.innerText,
                          )
                          if (e.target.checked) {
                            setAlcoholSelected([...alcoholSelected, checked])
                          } else {
                            setAlcoholSelected(
                              deselect(alcoholSelected, checked),
                            )
                          }
                        }}
                      />
                      <Form.Check
                        inline
                        label="Evening"
                        name="group1"
                        type={type}
                        id={`inline-${type}-43`}
                        onChange={(e) => {
                          const checked = extract_text(
                            e.target.nextElementSibling.innerText,
                          )
                          if (e.target.checked) {
                            setAlcoholSelected([...alcoholSelected, checked])
                          } else {
                            setAlcoholSelected(
                              deselect(alcoholSelected, checked),
                            )
                          }
                        }}
                      />
                      <Form.Check
                        inline
                        label="Night"
                        name="group1"
                        type={type}
                        id={`inline-${type}-34`}
                        onChange={(e) => {
                          const checked = extract_text(
                            e.target.nextElementSibling.innerText,
                          )
                          if (e.target.checked) {
                            setAlcoholSelected([...alcoholSelected, checked])
                          } else {
                            setAlcoholSelected(
                              deselect(alcoholSelected, checked),
                            )
                          }
                        }}
                      />
                    </div>
                  ))}
                </Form>
              </div>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="col-md-7 col-sm-12 ">
            <div className="card">
              <div className="card-body">
                <Bubble
                  selected={alcoholSelected}
                  isGrey={true}
                  width={75}
                  height={75}
                  right={140}
                />
              </div>
            </div>
          </div>
          <div className="col-md-5 col-sm-12">
            <div
              className="card"
              style={{
                backgroundColor: 'rgba(226, 237, 217, 1)',
                paddingLeft: 15,
              }}
            >
              <p className="bubble__side__title mgn">Alcohol profile</p>
              <div className="flex__container" style={{ gap: 0 }}>
                <div className="flex1" style={{ backgroundColor: '#FF005C3D' }}>
                  <label className="low">Alcohol percentage</label>
                </div>
                <div className="flex2">
                  <strong className="large__text">{`${dataToSend.alcohol.percentage}%`}</strong>
                  <small className="profile__small__text">Alcohol</small>
                </div>
                <div className="flex3" style={{ backgroundColor: '#FF005C80' }}>
                  <p className="low">
                    {dataToSend.alcohol.percentage < 21
                      ? 'Low'
                      : dataToSend.alcohol.percentage < 41
                      ? 'Medium'
                      : 'High'}
                  </p>
                </div>
              </div>
              <p className="bubble__side__title mgn">Addiction profile</p>
              <div class="single__button">
                <strong className="large__text">High</strong>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-7 col-sm-12 ">
            <div className="card">
              <div className="card-body text-left">
                <p className="subtitle">Other sustances</p>
                <p
                  className="habit__subtitle"
                  style={{ marginTop: -10, marginBottom: 20 }}
                >
                  Substance
                </p>

                <Form>
                  {['checkbox'].map((type) => (
                    <div key={`inline-${type}`} className="mb-3 row">
                      <div className="col-6">
                        <Form.Check
                          inline
                          label="Smoking"
                          name="group1"
                          type={type}
                          id={`inline-${type}-16`}
                          onChange={(e) => {
                            const checked = extract_text(
                              e.target.nextElementSibling.innerText,
                            )
                            if (e.target.checked) {
                              setOtherSubstanceSelected([
                                ...otherSubstanceSelected,
                                checked,
                              ])
                              dataToSend.other[checked] = e.target.checked
                              setDataToSend({
                                ...dataToSend,
                                other: {
                                  ...dataToSend.other,
                                },
                              })
                            } else {
                              setOtherSubstanceSelected(
                                deselect(otherSubstanceSelected, checked),
                              )
                              dataToSend.other[checked] = false
                            }
                          }}
                        />
                        <div className="radio__button__holder">
                          <Form>
                            {['radio'].map((type) => (
                              <div key={`default-${type}`} className="mb-3">
                                <Form.Check
                                  type={type}
                                  id={`skr-${type}`}
                                  label={`Cigarette`}
                                />
                              </div>
                            ))}
                          </Form>
                        </div>
                      </div>
                      <div className="col-6">
                        <Form.Check
                          inline
                          label="Sniffing"
                          name="group1"
                          type={type}
                          id={`inline-${type}-17`}
                          onChange={(e) => {
                            const checked = extract_text(
                              e.target.nextElementSibling.innerText,
                            )
                            if (e.target.checked) {
                              setOtherSubstanceSelected([
                                ...otherSubstanceSelected,
                                checked,
                              ])
                              dataToSend.other[checked] = e.target.checked
                              setDataToSend({
                                ...dataToSend,
                                other: {
                                  ...dataToSend.other,
                                },
                              })
                            } else {
                              setOtherSubstanceSelected(
                                deselect(otherSubstanceSelected, checked),
                              )
                              dataToSend.other[checked] = false
                            }
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <Form.Check
                          inline
                          label="Chewing"
                          name="group1"
                          type={type}
                          id={`inline-${type}-18`}
                          onChange={(e) => {
                            const checked = extract_text(
                              e.target.nextElementSibling.innerText,
                            )
                            if (e.target.checked) {
                              setOtherSubstanceSelected([
                                ...otherSubstanceSelected,
                                checked,
                              ])
                              dataToSend.other[checked] = e.target.checked
                              setDataToSend({
                                ...dataToSend,
                                other: {
                                  ...dataToSend.other,
                                },
                              })
                            } else {
                              setOtherSubstanceSelected(
                                deselect(otherSubstanceSelected, checked),
                              )
                              dataToSend.other[checked] = false
                            }
                          }}
                        />
                        <div className="radio__button__holder">
                          <Form>
                            {['radio'].map((type) => (
                              <div key={`default-${type}`} className="mb-3">
                                <Form.Check
                                  type={type}
                                  id={`skr-${type}`}
                                  label={`Miraa`}
                                />
                              </div>
                            ))}
                          </Form>
                        </div>
                      </div>
                      <div className="col-6">
                        <Form.Check
                          inline
                          label="Injecting"
                          name="group1"
                          type={type}
                          id={`inline-${type}-19`}
                          onChange={(e) => {
                            const checked = extract_text(
                              e.target.nextElementSibling.innerText,
                            )
                            if (e.target.checked) {
                              setOtherSubstanceSelected([
                                ...otherSubstanceSelected,
                                checked,
                              ])
                              dataToSend.other[checked] = e.target.checked
                              setDataToSend({
                                ...dataToSend,
                                other: {
                                  ...dataToSend.other,
                                },
                              })
                            } else {
                              setOtherSubstanceSelected(
                                deselect(otherSubstanceSelected, checked),
                              )
                              dataToSend.other[checked] = false
                            }
                          }}
                        />
                        <div className="radio__button__holder">
                          <Form>
                            {['radio'].map((type) => (
                              <div key={`default-${type}`} className="mb-3">
                                <Form.Check
                                  type={type}
                                  id={`skr-${type}`}
                                  label={`Heroine`}
                                />
                              </div>
                            ))}
                          </Form>
                        </div>
                      </div>
                      <div className="col-6">
                        <Form.Check
                          inline
                          label="Drinking"
                          name="group1"
                          type={type}
                          id={`inline-${type}-20`}
                          onChange={(e) => {
                            const checked = extract_text(
                              e.target.nextElementSibling.innerText,
                            )
                            if (e.target.checked) {
                              setOtherSubstanceSelected([
                                ...otherSubstanceSelected,
                                checked,
                              ])
                              dataToSend.other[checked] = e.target.checked
                              setDataToSend({
                                ...dataToSend,
                                other: {
                                  ...dataToSend.other,
                                },
                              })
                            } else {
                              setOtherSubstanceSelected(
                                deselect(otherSubstanceSelected, checked),
                              )
                              dataToSend.other[checked] = false
                            }
                          }}
                        />
                        <div className="radio__button__holder">
                          <Form>
                            {['radio'].map((type) => (
                              <div key={`default-${type}`} className="mb-3">
                                <Form.Check
                                  type={type}
                                  id={`skr-${type}`}
                                  ii
                                  label={`Alcoholg`}
                                />
                                <Form.Check
                                  type={type}
                                  id={`skr-${type}`}
                                  label={`Codaine`}
                                />
                              </div>
                            ))}
                          </Form>
                        </div>
                      </div>
                    </div>
                  ))}
                </Form>

                <label className="habit__small__text">Time of day</label>
                <Form>
                  {['checkbox'].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        inline
                        label="Morning"
                        name="group1"
                        type={type}
                        id={`inline-${type}-15`}
                        onChange={(e) => {
                          const checked = extract_text(
                            e.target.nextElementSibling.innerText,
                          )
                          if (e.target.checked) {
                            setOtherSubstanceSelected([
                              ...otherSubstanceSelected,
                              checked,
                            ])
                          } else {
                            setOtherSubstanceSelected(
                              deselect(otherSubstanceSelected, checked),
                            )
                          }
                        }}
                      />
                      <Form.Check
                        inline
                        label="Afternoon"
                        name="group1"
                        type={type}
                        id={`substance-${type}-12`}
                        onChange={(e) => {
                          const checked = extract_text(
                            e.target.nextElementSibling.innerText,
                          )
                          if (e.target.checked) {
                            setOtherSubstanceSelected([
                              ...otherSubstanceSelected,
                              checked,
                            ])
                          } else {
                            setOtherSubstanceSelected(
                              deselect(otherSubstanceSelected, checked),
                            )
                          }
                        }}
                      />
                      <Form.Check
                        inline
                        label="Evening"
                        name="group1"
                        type={type}
                        id={`inline-${type}-13`}
                        onChange={(e) => {
                          const checked = extract_text(
                            e.target.nextElementSibling.innerText,
                          )
                          if (e.target.checked) {
                            setOtherSubstanceSelected([
                              ...otherSubstanceSelected,
                              checked,
                            ])
                          } else {
                            setOtherSubstanceSelected(
                              deselect(otherSubstanceSelected, checked),
                            )
                          }
                        }}
                      />
                      <Form.Check
                        inline
                        label="Night"
                        name="group1"
                        type={type}
                        id={`inline-${type}-14`}
                        onChange={(e) => {
                          const checked = extract_text(
                            e.target.nextElementSibling.innerText,
                          )
                          if (e.target.checked) {
                            setOtherSubstanceSelected([
                              ...otherSubstanceSelected,
                              checked,
                            ])
                          } else {
                            setOtherSubstanceSelected(
                              deselect(otherSubstanceSelected, checked),
                            )
                          }
                        }}
                      />
                    </div>
                  ))}
                </Form>
              </div>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="col-md-7 col-sm-12 ">
            <div className="card">
              <div className="card-body">
                <Bubble
                  selected={otherSubstanceSelected}
                  isGrey={true}
                  width={75}
                  height={75}
                  right={140}
                />
              </div>
            </div>
          </div>
          <div className="col-md-5 col-sm-12">
            <div
              className="card"
              style={{
                backgroundColor: 'rgba(226, 237, 217, 1)',
                paddingLeft: 15,
              }}
            >
              <p className="bubble__side__title mgn">Substance abuse profile</p>
              <div className="flex__container" style={{ gap: 0 }}>
                <div className="flex1" style={{ backgroundColor: '#FF005C3D' }}>
                  <label className="low">Number of times of abuse a day</label>
                </div>
                <div className="flex2">
                  <strong className="large__text">4</strong>
                  <small className="profile__small__text">Times a day</small>
                </div>
                <div className="flex3" style={{ backgroundColor: '#FF005C80' }}>
                  <p className="low">High</p>
                </div>
              </div>
              <p className="bubble__side__title mgn">Addiction profile</p>
              <div class="single__button">
                <strong className="large__text">High</strong>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <div className="submit__notes">
        <button
          className="submit__btn"
          style={{ marginRight: 10, marginTop: 15, width: 130 }}
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  )
}

export default MentalCarePlanComponent
