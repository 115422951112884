import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import LeftBar from '../../components/LeftBar'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Vitals from '../../components/vitals/Vitals'
import DietNutrition from '../../components/vitals/diet/DietNutrition'
import ActivityExercise from '../../components/vitals/exercise/ActivityExercise'
import HabitBreaker from '../../components/vitals/habit-breaker/HabitBreaker'
import { selectUser } from '../../redux_setup/slices/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import MentalHealth from '../../components/vitals/mental-health/MentalHealth'
import { populateVitals } from '../../redux_setup/slices/hypertensionSlice'
import { ReduxActions } from '../../constants/redux_actions'
const CarePlanDashboard = () => {
  const [careplanData, setCareplanData] = useState([])
  const [mainRes, setMainRes] = useState([])
  const [currentRes, setCurrentRes] = useState({})
  const [height, setHeight] = useState(0)
  const [weight, setWeight] = useState(0)
  const [bmi, setBmi] = useState('')
  const [recentVitalsData, setRecentVitalsData] = useState({})
  const user = useSelector(selectUser)
  const dispatch = useDispatch()
  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  useEffect(() => {
    fetch(
      `https://specialconsult.healthcenta.com/api/goals/general/${user?.user_id}`,
      {
        signal: signal,
        method: 'GET',
        headers: new Headers({
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res.data[0])
        setCareplanData(res.data[0])
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  //fectch height and weight and BMI
  const fetchDetailsUrl = `${process.env.REACT_APP_MAIN_URL}/api/vital/patient-history?patient_id=${user?.user_id}`

  useEffect(() => {
    // console.log(user.user_id, user.token)
    fetch(fetchDetailsUrl, {
      method: 'GET',
      signal: signal,
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, 'data')
        if (res.error) {
          return console.log(res.error)
        }
        const response = res.data.items

        // console.log(response);
        setMainRes(response)
        const resp = response[0]
        setHeight(resp?.height)
        setWeight(resp?.weight)
        setBmi(resp?.bmi)

        // res.data?.items?.length > 0 && setAppointments(res.data.items)
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  //fetch vitals
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_MAIN_URL_HYPERTENSION}/api/patient/vitals/overall/${user?.user_id}`,
      {
        method: 'GET',
        headers: new Headers({
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return console.log(res.error)
        }
        // console.log(res)
        if (res?.data) {
          setRecentVitalsData(res?.data[0])
          const rec = res?.data[0]
          dispatch(
            populateVitals({
              data: {
                vitals_readings: {
                  blood_pressure: rec?.bp,
                  blood_pressure_level: '',
                  heart_rate: rec?.heart_rate,
                  heart_rate_level: '',
                  respiration_rate: '0',
                  respiration_rate_level: '',
                  weight: rec?.weight,
                  weight_level: '',
                },
                stethoscopic_readings: {
                  systolic_murmurs: '0',
                  systolic_murmurs_level: '',
                  diastolic_murmurs: '0',
                  diastolic_murmurs_level: '',
                  temperature: rec?.temp,
                  temperature_level: '',
                },
                risk_level: '',
                notes: '',
              },
              action_type: ReduxActions.add_initial_data_from_server,
            }),
          )
        }

        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  return (
    <div className="main-wrapper">
      {/***********************************
                  Header start
                  ************************************/}
      <Header />
      {/***********************************
                  Header end ti-comment-alt
                  ************************************/}
      {/***********************************
                  Sidebar start
                  ************************************/}
      <LeftBar />
      {/***********************************
                  Sidebar end
                  ************************************/}
      {/***********************************
                  Content body start
                  ************************************/}

      {/***********************************
                  Content body end
                  ************************************/}
      <div className="content-body">
        <div className="row mr-2">
          <div className="col-lg-3 col-md-3 col-6">
            <div>
              <div
                className=" card pl-2 pt-3 pr-2 mobo-card"
                style={{ height: 120 }}
              >
                <div
                  className="card pt-1 padding-card ml-3 mr-3"
                  style={{
                    height: 35,
                    backgroundColor: '#6623823D',
                  }}
                >
                  <p className="mobo-text ml-3" style={{ fontWeight: 700 }}>
                    Diagnosis
                  </p>
                  <p className="mobo-text" style={{ marginLeft: '1%' }}>
                    {careplanData?.diagnosis}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-6">
            <div>
              <div
                className=" card pl-2 pt-3 pr-2 mobo-card"
                style={{ height: 120 }}
              >
                <div
                  className="card pt-1 padding-card ml-3 mr-3"
                  style={{
                    height: 35,
                    backgroundColor: '#07B0D73D',
                  }}
                >
                  <p className="mobo-text ml-3" style={{ fontWeight: 700 }}>
                    Risk Factors
                  </p>
                  <p className="mobo-text" style={{ marginLeft: '1%' }}>
                    {careplanData?.risk_factors}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-6">
            <div>
              <div
                className=" card pl-2 pt-3 pr-2 mobo-card"
                style={{ height: 120 }}
              >
                <div
                  className="card pt-1 pl-3 mobo ml-3 mr-3"
                  style={{
                    height: 35,
                    backgroundColor: '#ABB3FD80',
                  }}
                >
                  <p
                    className="mobo-text"
                    style={{ fontWeight: 700, marginLeft: '1%' }}
                  >
                    Estimated Weight
                  </p>
                  <p className="ml-4 mobo-text">{weight}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-6">
            <div>
              <div
                className=" card pl-2 pt-3 pr-2 mobo-card"
                style={{ height: 120 }}
              >
                <div
                  className="card pt-1 pl-3 mobo ml-3 mr-3"
                  style={{
                    height: 35,
                    backgroundColor: '#0F56B33D',
                  }}
                >
                  <p
                    className="mobo-text"
                    style={{ fontWeight: 700, marginLeft: '1%' }}
                  >
                    Estimated BMI
                  </p>
                  <p className="ml-4 mobo-text">{bmi}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card row pl-3 pt-3 ml-1 mr-1" style={{ width: '100%' }}>
          <p
            className="meal-profile-text"
            style={{ color: '#000', fontSize: 20 }}
          >
            Vitals
          </p>
          <div
            className="card pl-4 pr-4 careplan-title"
            style={{
              backgroundColor: '#6623823D',
              whiteSpace: 'wrap',
            }}
          >
            <p
              className="meal-profile-text pr-2"
              style={{ color: '#000', whiteSpace: 'normal' }}
            >
              Suggested Outcome: {careplanData?.vitals_suggested_outcome}
            </p>
          </div>
          <div className="d-flex flex-wrap" style={{ gap: 50 }}>
            <div className="vitals-container">
              <Vitals data={careplanData} recent={recentVitalsData} />
            </div>
          </div>
        </div>
        {/* Diet & Nutrition */}
        {user?.invitedHyperTension?.includes('Nutrition & Diet Care Plan') && (
          <DietNutrition data={careplanData} />
        )}

        {/* Activity & Exercise */}
        {user?.invitedHyperTension?.includes('Activity Care Plan') && (
          <ActivityExercise data={careplanData} />
        )}

        {/* Habit Breaker */}
        {user?.invitedHyperTension?.includes('Habit Breaker Care Plan') && (
          <HabitBreaker data={careplanData} />
        )}

        {/* Mental health */}
        {user?.invitedHyperTension?.includes('Mental Care Plan') && (
          <MentalHealth data={careplanData} />
        )}
      </div>
      {/***********************************
                  Footer start
                  ************************************/}
      <Footer />
      {/***********************************
                  Footer end
                  ************************************/}
    </div>
  )
}

export default CarePlanDashboard
