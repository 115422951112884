import * as Yup from "yup";

// functions
function calculateAge(birthday) {
    // birthday is a date
    var ageDifMs = Date.now() - birthday;
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

// Sign In Schema
const SignInSchema = Yup.object().shape({
    email: Yup.string().required("Required"),

    password: Yup.string().required("Required"),
});

const PatientSignupSchema = Yup.object().shape({
    email: Yup.string().email().optional(),
    firstName: Yup.string().required("Required"),
    middleName: Yup.string().optional(),
    lastName: Yup.string().required("Required"),
    gender: Yup.string().required("Required"),
    birth_date: Yup.date()
        .required("Required")
        .test("birth_date", "Only 18 and above", function(value) {
            return calculateAge(new Date(value)) >= 18;
        }),
    password: Yup.string()
        .required("Required")
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Password is weak"
        ),
    passwordConfirmation: Yup.string()
        .required("Passwords must match")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const PatientAdddedSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    middleName: Yup.string().optional(),
    lastName: Yup.string().required("Required"),
    gender: Yup.string().required("Required"),
    birth_date: Yup.date()
        .required("Required")
        .test("birth_date", "Only 18 and above", function(value) {
            return calculateAge(new Date(value)) >= 18;
        }),
    
});

const Add_HC_Schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email().required("Email is required"),
    phone: Yup.string().optional(),

    address: Yup.string().optional(),
});
const Add_User_Schema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email().required("Required"),
    gender: Yup.string().required("Required"),
    password: Yup.string().optional(),
    role: Yup.string().required("Required"),
    is_admin: Yup.string().optional(),
    // health_center_id: Yup.string().required("Health Center is required"),
});

const PatientProfileSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    phone: Yup.string().optional(),
    password: Yup.string()
        .optional()
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
            "Password is weak"
        ),
});

const AddSpecialitySchema = Yup.object().shape({
    name: Yup.string().required("speciality name is required"),
});

const AddRoomSchema = Yup.object().shape({
    name: Yup.string().required("room name is required"),
});

const EditPriceSchema = Yup.object().shape({
    price: Yup.number().required("price is required"),
});

export {
    SignInSchema,
    PatientSignupSchema,
    PatientAdddedSchema,
    Add_HC_Schema,
    Add_User_Schema,
    PatientProfileSchema,
    AddSpecialitySchema,
    AddRoomSchema,
    EditPriceSchema,
};