import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import { formatDate } from '@fullcalendar/react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'
import { humanize, trimString } from '../constants/Essentials'
import { Badge, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import HoverComponent from './HoverComponent'
import Swal from 'sweetalert2'

const PatientAppointments = (props) => {
  // console.log({ status: props.appt.status, id: props.appt.id })
  // console.log(props.appt)
  const user = useSelector(selectUser)
  toast.configure()
  let navigate = useNavigate()

  const cancelAppointment = (id) => {
    Swal.fire({
      title: 'Are you sure you want to cancel this appointment?',
      // text: 'You will be redirected to the login page !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: 'gray',
      confirmButtonText: 'Yes, Proceed!',
      cancelButtonText: 'Close',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(id)
        const cancelUrl = `${process.env.REACT_APP_MAIN_URL}/api/appointment/change-status/${id}`
        fetch(cancelUrl, {
          method: 'PATCH',
          headers: new Headers({
            Authorization: 'Bearer ' + user.token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({ status: 'Cancel' }),
        })
          .then((res) => res.json())
          .then((res) => {
            props.alterAfterCancel && props.alterAfterCancel(id)
            return toast.success(
              'Appointment has been cancelled successfully',
              {
                position: toast.POSITION.TOP_CENTER,
                position: false
              },
            )
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })
  }

  const assessPatient = (appt_id, patient_id) => {
    // check for userrole
    const dataToSend = { appt_id, patient_id }
    if (user.role === 'DOCTOR') {
      //navigate
      navigate('/doctor-assessment', { state: dataToSend })
      // alert(appt_id + " " + patient_id);
    } else {
      return toast.warning('Next step is for doctors only', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false
      })
    }
  }
  const dataToProceed = {
    appt_id: props.appt?.id,
    patient_id: props.appt?.patient_id,
    doctor_id: props.appt?.doctor_id,
    health_center: { name: props.appt?.Health_center?.name },
    doctor: { name: props.appt?.doctorId?.name },
    time_slot: props.appt?.time_slot,
    date: props.appt?.date,
    reason_for_visit: props.appt?.reason_for_visit,
    send_reminder: props.appt?.send_reminder,
    email: props.appt?.email,
    patient_name: props?.patient_name,
    price: parseInt(props?.appt?.price),
  }

  return (
    <tr
      className={`text-left ${
        props.appt.status === 'Cancel' && 'cancelled-appt'
      }`}
    >
      <td>
        {props.appt?.status !== 'Done' && props.appt.status !== 'Cancel' && (
          <span
            role="button"
            onClick={() => cancelAppointment(props?.appt?.id)}
          >
            <i
              className="fa fa-times-circle text-danger mr-1"
              title="Cancel Appointment"
            ></i>
          </span>
        )}{' '}
        {props.docname}
      </td>
      <td>
        {props.date}{' '}
        {/* {props.appt?.status === 'Done' && (
          <Badge bg="success" text="white">
            <small>Completed</small>
          </Badge>
        )} */}
      </td>
      <td>
        {props.time}
        {props.appt?.status === 'Done' && (
          <i className="fa fa-check-circle text-success" title="Completed"></i>
        )}
      </td>
      {/* <td>{props.appt?.reason_for_visit}</td> */}
      <td>
        <HoverComponent
          textToDisplay={props.appt?.reason_for_visit}
          placement="right"
        >
          <b className="cursor-pointer text-info">
            {trimString(props.appt?.reason_for_visit, 13)}
          </b>
        </HoverComponent>
      </td>
      <td>{humanize(props.appt?.payment_type)}</td>
      {props.appt?.status !== 'Cancel' ? (
        <>
          <td>
            {props?.isPayed === true ? (
              <Badge pill bg="light" style={{color: '#22bb33'}}>
                Paid
              </Badge>
            ) : user.role === 'RECEPTIONIST' ? (
              <Link
                to="/payments"
                state={dataToProceed}
                className="btn btn-dark btn-sm"
                title="Click to receive Payment"
              >
                Receive Payment
              </Link>
            ) : (
              <span className="text-warning">Unpaid</span>
            )}
          </td>
          <td>
            {props.appt?.status === 'Done' && (
              <Link
                to="/book-follow-up"
                title="Click to book follow up"
                state={{
                  app_id: props?.id,
                  patient_id: props?.patient_id,
                  doctor_id: props?.doctor_id,
                  health_center: props?.health_center,
                }}
                className="btn btn-info btn-sm"
              >
                <small>Book follow up</small>
              </Link>
            )}
          </td>
          {user.role === 'DOCTOR' && (
            <td>
              <Link
                to="/transfer-appt"
                state={{
                  app_id: props?.id,
                  patient_id: props?.patient_id,
                  doctor_id: props?.doctor_id,
                  health_center: props?.health_center,
                }}
                title="Click to initiate transfer"
                className="btn btn-warning btn-sm"
              >
                Transfer
              </Link>
            </td>
          )}
        </>
      ) : (
        <>
          <td></td>
          <td></td>
          <td></td>
        </>
      )}

      {/* <a href="appintment-details" style={{ padding: '0 15px' }}><i style={{ color: '#A0A4A8' }} class="fas fa-pencil-alt"></i></a> */}
    </tr>
  )
}

export default PatientAppointments
