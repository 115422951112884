import React, {useState} from "react";
const GraphButtons = ({ nutritionType,clickHandler }) => {
    const [active, setActive] = useState(
        {
            calorieIntake:{ id: 0, isActive: false },
            fatIntake:{ id: 0, isActive: false },
            sodiumIntake:{ id: 0, isActive: false },
            waterIntake:{ id: 0, isActive: false },
        }
        );
        const nutritionTypes = () =>{
        switch(nutritionType){
            case 'calorie intake':
                return 'calorieIntake'
            case 'fat intake':
                return 'fatIntake'    
            case 'sodium intake':
                return 'sodiumIntake'
            case 'water intake':
                return 'waterIntake'    
            default:
                return ''  
        }
        }
    return (
        <div className="flex__container">
                {["Today", "Week", "Month", "Quarter", "Year"].map(
                  (el, index) => (
                    <div key={index}>
                      <button
                        className={
                          index === active[nutritionTypes()]['id']
                            ? "graph__btn graph__btn__active"
                            : "graph__btn"
                        }
                        onClick={() => {
                            const type = nutritionTypes()
                            active[type]['isActive'] = true
                            active[type]['id'] = index;
                          setActive(
                            {
                            ...active,
                            type
                            }
                          );
                          clickHandler(el);
                        }}
                      >
                        {el}
                      </button>
                    </div>
                  )
                )}
              </div>
    )
}
export default GraphButtons;