import React, { useState, useRef } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Counter from '../hypertension/Counter'
import { FoodChart } from './FoodChart'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateBasketFood,
  selectBasketFood,
} from '../../redux_setup/slices/basketFoodSlice'
import { snacks_data } from '../../constants/nutrition_data'
import Mealprofile from './Mealprofile'
import {
  populateSnacks,
  selectSnacks,
} from '../../redux_setup/slices/nutritionBasketSlice'
import { ReduxActions } from '../../constants/redux_actions'

const Snacks = () => {
  const [show, setShow] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [selected, setSelected] = useState()
  const [disabled, setDisabled] = useState(true)
  const [snacks, setSnacks] = useState(0)
  const otherRef = useRef()
  const formRef = useRef()
  const dispatch = useDispatch()
  const foodInBasket = useSelector(selectBasketFood)
  const handleClose = () => setShow(false)
  const nutritionBasket = useSelector(selectSnacks)

  const addToBasket = (item) => {
    dispatch(populateSnacks({ item, action_type: ReduxActions.add_to_cart }))
  }

  const minusFromBasket = (item) => {
    dispatch(
      populateSnacks({
        item,
        action_type: ReduxActions.decrement_from_cart,
      }),
    )
  }
  return (
    <>
      <div>
        <p
          className="meal-profile-text"
          style={{ color: '#000', cursor: 'pointer' }}
        >
          Snacks
        </p>
        <div className="d-flex flex-wrap">
          <label className="mr-3 mt-2 mb-2">Time</label>
          <input
            type="time"
            style={{
              width: 135,
              height: 27,
              marginBottom: 20,
              border: '1px solid #662382',
            }}
          />
        </div>
        <div style={{ width: 200 }}>
          <label>Food</label>
          <Form.Select className="pl-2" onClick={() => setShow(true)}>
            <option>Foods</option>
          </Form.Select>
        </div>
        <div
          style={{
            height: 58,
            backgroundColor: '#E8E8E840',
          }}
          className="mt-3"
        />
      </div>
      <Modal show={show} onHide={handleClose}>
        <div className="card pl-4 pr-4">
          {/* <div className="row">
            <div className="col-10"></div>

            <div className="col-1">
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/images/icons/close-icon.png`}
                  width={26}
                  height={26}
                  alt="close icon"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShow(false)}
                />
              </div>
            </div>
          </div> */}
          {/* <div className="row mt-2">
            <div
              className=" col-md-8 col-lg-8 col-sm-12 d-flex flex-wrap rounded"
              style={{ backgroundColor: '#fff', padding: 20, borderRadius: 8 }}
            >
              <div className="col-md-12">
                <p className="meal-profile-text" style={{ color: '#000' }}>
                  Snacks
                </p>
                <Counter leftPosition={snacks} times={27} data={snacks} /> 
                <div>
                  <Form.Range
                    min={0}
                    max={10}
                    value={snacks}
                    style={{
                      width: 301,
                      marginRight: 10,
                      marginBottom: 30,
                      color: 'red',
                    }}
                    onChange={(e) => setSnacks(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div> */}
          <div className="row mt-2">
            {snacks_data.map((item, index) => (
              <div
                key={index}
                className="col-sm-6 col-12"
                style={{
                  backgroundColor:
                    nutritionBasket.filter((it) => it.title === item.title)[0]
                      ?.count > 0
                      ? '#6623824D'
                      : '',
                  borderRadius: 8,
                }}
              >
                <div className="mt-2 mb-2">
                  <div className="d-flex justify-content-between">
                    <Form.Check
                      type="checkbox"
                      id={`dinner-${item.title}`}
                      label={`${item.title}`}
                      checked={
                        nutritionBasket.filter(
                          (it) => it.title === item.title,
                        )[0]?.count > 0
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          addToBasket(item)
                        }
                      }}
                      className="mt-2"
                      style={{
                        filter: 'grayscale(1)',
                      }}
                    />
                    <div className="d-flex mt-2">
                      <Button
                        variant="default"
                        style={{
                          width: 35,
                          height: 16,
                          padding: 0,
                          fontSize: 10,
                          backgroundColor:
                            nutritionBasket.filter(
                              (it) => it.title === item.title,
                            )[0]?.count > 0
                              ? '#6623824D'
                              : '',
                        }}
                        onClick={() => minusFromBasket(item)}
                      >
                        <i class="fa-sharp fa-solid fa-minus"></i>
                      </Button>
                      <span style={{ marginBottom: 10, fontSize: 13 }}>
                        {nutritionBasket.filter(
                          (it) => it.title === item.title,
                        )[0]?.count || 0}
                      </span>
                      <Button
                        variant="default"
                        style={{
                          width: 35,
                          height: 16,
                          padding: 0,
                          fontSize: 10,
                          backgroundColor:
                            nutritionBasket.filter(
                              (it) => it.title === item.title,
                            )[0]?.count > 0
                              ? '#6623824D'
                              : '',
                        }}
                        onClick={() => addToBasket(item)}
                      >
                        <i class="fa-sharp fa-solid fa-plus"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <Button
              onClick={() => {
                // console.log(nutritionBasket, 'gggrg')
                setShow(false)
              }}
              style={{ backgroundColor: '#6623824D', borderColor: '#E8E8E840' }}
            >
              Okay
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default Snacks
