import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectStep, updateStep } from '../../redux_setup/slices/stepSlice'
import { humanize } from '../../constants/Essentials'

const HypertensionMenu = () => {
  const dispatch = useDispatch()
  const step = useSelector(selectStep)
  const pageSteps = [
    'vitals',
    'family_history',
    'underlying_conditions',
    'LifestyleReview',
    'labs',
    'summary',
  ]
  return (
    <div style={{ display: 'inline-flex', marginLeft: '20px' }}>
      {pageSteps.map((page, index) => (
        <React.Fragment key={index}>
          <p
            className={`Dancing ${step === page && 'selectedtab'}`}
            onClick={() => dispatch(updateStep(page))}
          >
            {humanize(page)}
          </p>
          <div className={`wynd ${step === page && 'selectedtab'}`} />
          {step === page && <span className="dot"></span>}
        </React.Fragment>
      ))}
    </div>
  )
}
export default HypertensionMenu
