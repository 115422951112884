import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import HypertensionMenu from './HypertensionMenu'
import { useDispatch, useSelector } from 'react-redux'
import {
  populateLabs,
  populateSummary,
  selectLabs,
  selectSummary,
} from '../../redux_setup/slices/hypertensionSlice'
import { ReduxActions } from '../../constants/redux_actions'
import { defaultLabData, labLevels } from '../../constants/hypertension_data'
import {
  backgroundColor,
  calculateAge,
  humanize,
  machinize,
  trimString,
} from '../../constants/Essentials'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { selectUser } from '../../redux_setup/slices/userSlice'
import { updateStep } from '../../redux_setup/slices/stepSlice'
import { selectTests, updateTests } from '../../redux_setup/slices/testSlice'
import { Collapse } from 'react-bootstrap'
import { fetchLabs } from '../../constants/FetchFunctions'
import { SummarypageBonesLabs } from '../bonesloader/SummarypageBones'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

const Labs = ({ patientDetails }) => {
  const dispatch = useDispatch()
  const labs = useSelector(selectLabs)
  const user = useSelector(selectUser)
  const testsArray = useSelector(selectTests)
  const summaryState = useSelector(selectSummary)
  const [serverLoadedTests, setServerLoadedTests] = useState(false)
  const [notFetched, setNotFetched] = useState(false)
  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  let navigate = useNavigate()
  toast.configure()

  //   const setcholesterol = (e, subcategory, category) => {
  //     console.log(e, subcategory, category)
  //     //get redux store variable labs

  //     //get the category
  //     const labsClone = labs
  //     const cat = labs[category]
  //     console.log(cat[subcategory])

  //     // if (e.target.checked) {
  //     //   //it was checked, activate the card
  //     // }
  //   }

  const setTextBox = (val, category) => {
    dispatch(
      populateLabs({
        val,
        category,
        action_type: ReduxActions.add_property,
      }),
    )
  }

  const submitHandler = () => {
    //are we gonna post or patch?

    let dataToSend

    if (labs?.id) {
      dataToSend = { ...labs }
      delete dataToSend.created_at
      delete dataToSend.updated_at
      delete dataToSend.patient_id
    } else {
      dataToSend = { ...labs, patient_id: patientDetails.patient_id }
    }

    fetch(`${process.env.REACT_APP_MAIN_URL_HYPERTENSION}/api/labs`, {
      signal: signal,
      method: labs?.id ? 'PATCH' : 'POST', //if you can find an id in labs, then update
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }
        dispatch(updateStep('summary'))
        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        })

        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error has occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
  }
  const changeTestState = (name) => {
    dispatch(
      updateTests({
        val: name,
        action_type: ReduxActions.expand_test_option,
      }),
    )
  }
  const adjustSelectedChildren = (motherObject, child) => {
    dispatch(
      updateTests({
        motherObject,
        child,
        action_type: ReduxActions.change_child_option,
      }),
    )
  }

  useEffect(() => {
    // console.log(patientDetails)
    fetch(
      `${process.env.REACT_APP_MAIN_URL_HYPERTENSION}/api/labs/${patientDetails.patient_id}`,
      {
        signal: signal,
        method: 'GET',
        headers: new Headers({
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return console.log('Labs fetching did not succeed')
        }
        if (res.data.length <= 0) {
          //reset redux statepiece

          dispatch(
            populateLabs({
              action_type: ReduxActions.reset_to_default_value,
            }),
          )
        } else {
          dispatch(
            populateLabs({
              val: res.data[0],
              action_type: ReduxActions.add_initial_data_from_server,
            }),
          )
        }

        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err)
      })

    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  useEffect(() => {
    //get labs
    fetchLabs(
      process.env.REACT_APP_MAIN_URL_HYPERTENSION,
      patientDetails.patient_id,
      signal,
      user.token,
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          console.log('an error occured')
          setNotFetched(true)
        }
        dispatch(
          populateSummary({
            data: res.data[0],
            property: 'lab_tests',
            action_type: ReduxActions.add_property,
          }),
        )
      })
      .catch((err) => {
        console.log(err)
        setNotFetched(true)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  const saveTests = () => {
    const toSend = {
      appointment_id: undefined,
      patient_id: patientDetails.patient_id,
      tests_to_be_done: {
        tests: testsArray,
        date: moment().format('YYYY-MM-DD'),
        timeRequested: moment().format('h:mm:ss a'),
        doctor: user?.userName,
      },
    }

    return navigate('/requested-test', {
      state: {
        patient_id: patientDetails.patient_id,
        appt_id: undefined,
        name: patientDetails.name,
        gender: patientDetails.gender?.toUpperCase(),
        age: calculateAge(patientDetails.dob),
        phone: patientDetails.phone,
        email: patientDetails.email,
        tests: testsArray,
        timeRequested: toSend.tests_to_be_done.timeRequested,
      },
    })

    // return console.log(dataToSend);
    const url = `${process.env.REACT_APP_MAIN_URL}/api/general_consultation/labtest`
    // send to payment page here
    // return navigate("/payments", { state: dataToSend });
    // send request
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(toSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }

        toast.success('Lab tests request saved successfully', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })

        return navigate('/requested-test', {
          state: {
            patient_id: patientDetails.patient_id,
            appt_id: undefined,
            name: patientDetails.name,
            gender: patientDetails.gender?.toUpperCase(),
            age: calculateAge(patientDetails.dob),
            phone: patientDetails.phone,
            email: patientDetails.email,
            tests: testsArray,
            timeRequested: toSend.tests_to_be_done.timeRequested,
          },
        })
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
  }
  const NoData = () => (
    <div className="alert alert-warning">No data was found </div>
  )

  return (
    <div className="margin__container">
      <h6 className="margin__left vital__subtopic">Lab</h6>
      <HypertensionMenu />
      <div className="risk__card">
        <label className="vital__subtopic">Tests</label>

        <div className="row border-top">
          {testsArray.map((item, index) => (
            <div className="col-md-6 col-sm-12" key={index}>
              <Form.Check
                label={item.name}
                name={machinize(item.name)}
                id={`${machinize(item.name)}-${index}`}
                key={`${machinize(item.name)}-${index}`}
                onChange={(e) => {
                  changeTestState(item.name)
                }}
                checked={item.expanded}
                aria-controls="example-collapse-text"
                aria-expanded={item.expanded}
              />
              {item.children?.length > 0 && item.expanded ? (
                <Collapse in={item.expanded} style={{ marginLeft: 40 }}>
                  <div id={`child${machinize(item.name)}-${index}`}>
                    {item.children.map((child, index) => (
                      <Form.Check
                        label={child}
                        key={`${item.name}-${child}-${index}`}
                        id={`${item.name}-${child}-${index}`}
                        onChange={() => adjustSelectedChildren(item, child)}
                        checked={item.selectedChildren.includes(child)}
                      />
                    ))}
                  </div>
                </Collapse>
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
        <div className="row">
          {/* <Link
                          to="/requested-test"
                          state={{
                            patient_id: patient?.patient_id,
                            appt_id: patient?.appt_id,
                            name: patient?.patient_name,
                            gender: patient?.patient_gender?.toUpperCase(),
                            age: patient?.patient_birth_date,
                            phone: patient?.patient_phone,
                            email: patient?.patient_email,
                          }}
                        > */}
          <button
            className="btn btn-primary btn-sm ml-3 mt-5"
            onClick={saveTests}
          >
            {serverLoadedTests ? 'Update Tests' : 'Save Tests'}
          </button>
          {/* </Link> */}
        </div>
      </div>
      <div className="risk__card">
        <label className="vital__subtopic">Previous Lab results</label>
        {summaryState.lab_tests?.patient_id === patientDetails.patient_id ? (
          <div className="habits__container">
            <h6 className="vital__subtopic">Heart function</h6>
            <hr />
            <Row>
              {Object.entries(summaryState.lab_tests.heart_function).map(
                (test, index) => (
                  <>
                    {parseInt(test[1].value) > 0 && (
                      <Col md={2} className="mr-3 mt-2 mb-3" key={index}>
                        <span>{humanize(trimString(test[0], 16))}</span>
                        <div className="flex__container__summary">
                          <button className="lab__btn__white">
                            <p>
                              {test[1].value}
                              <small>mg/dL</small>
                            </p>
                          </button>

                          {test[1].value > 0 && (
                            <span
                              style={{
                                backgroundColor: backgroundColor(test[1].level),
                                border: `1px solid ${backgroundColor(
                                  test[1].level,
                                )}`,
                              }}
                            >
                              {test[1].level}
                            </span>
                          )}
                        </div>
                      </Col>
                    )}
                  </>
                ),
              )}
            </Row>
            <h6 className="vital__subtopic mt-3">Liver function</h6>
            <hr />
            <Row>
              {Object.entries(summaryState.lab_tests.liver_function).map(
                (test, index) => (
                  <>
                    {parseInt(test[1].value) > 0 && (
                      <Col md={2} className="mr-3 mt-2 mb-3" key={index}>
                        <span>{humanize(trimString(test[0], 16))}</span>
                        <div className="flex__container__summary">
                          <button className="lab__btn__white">
                            <p>
                              {test[1].value}
                              <small>u/L</small>
                            </p>
                          </button>
                          {test[1].value > 0 && (
                            <span
                              style={{
                                backgroundColor: backgroundColor(test[1].level),
                                border: `1px solid ${backgroundColor(
                                  test[1].level,
                                )}`,
                              }}
                            >
                              {test[1].level}
                            </span>
                          )}
                        </div>
                      </Col>
                    )}
                  </>
                ),
              )}
            </Row>
            <h6 className="vital__subtopic mt-3">Kidney function</h6>
            <hr />
            <Row>
              {Object.entries(summaryState.lab_tests.kidney_function).map(
                (test, index) => (
                  <>
                    {parseInt(test[1].value) > 0 && (
                      <Col md={3} className="mr-3 mt-2 mb-3" key={index}>
                        <span>{humanize(trimString(test[0], 26))}</span>
                        <div className="flex__container__summary">
                          <button className="lab__btn__white">
                            <p>
                              {test[1].value}
                              <small>mEq/L</small>
                            </p>
                          </button>
                          {parseInt(test[1].value) > 0 && (
                            <span
                              style={{
                                backgroundColor: backgroundColor(test[1].level),
                                border: `1px solid ${backgroundColor(
                                  test[1].level,
                                )}`,
                              }}
                            >
                              {test[1].level}
                            </span>
                          )}
                        </div>
                      </Col>
                    )}
                  </>
                ),
              )}
            </Row>
          </div>
        ) : (
          <>{notFetched ? <NoData /> : <SummarypageBonesLabs />}</>
        )}
      </div>
    </div>
  )
}
export default Labs
