import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import LeftBar from '../components/LeftBar'
import 'react-toastify/dist/ReactToastify.css'
import PatientComponent from '../components/PatientComponent'
import DataTable from 'react-data-table-component'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import { useNavigate } from 'react-router-dom'
import { Button, Col, Modal } from 'react-bootstrap'
import { titleSentenceCase, trimDate, searchHandler } from '../constants/Essentials'
import { read, utils } from 'xlsx'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const PatientsProfile = () => {
  toast.configure()
  let navigate = useNavigate()
  const user = useSelector(selectUser)
  const [patients, setPatients] = useState([])
  const [allPatients, setAllPatients] = useState([])
  const [pending, setPending] = useState(true)

  //state for searching
  const [searchKeyword, setSearchKeyword] = useState('')

  const [patientsFromExcel, setPatientsFromExcel] = useState([])
  const [status, setStatus] = useState('Upload')
  const [erroed, setErroed] = useState(0)
  const [successful, setSuccessful] = useState(0)
  const [addShow, setAddShow] = useState(false) //add modal
  const [reloadPatients, setReloadPatients] = useState(false)

  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  const ExpandedComponent = ({ data }) => {
    return (
      <div className="expanded-div py-5 px-5">
        <p>Name: {data.name}</p>
        <p>Email: {data.email}</p>
        <p>Adress: {data.address}</p>
        <p>Phone: {data.phone}</p>
      </div>
    )
  }

  const columns = [
    {
      name: 'No',

      selector: (row) => row.count,
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Gender',
      selector: (row) => row.gender,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: 'Phone',
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row) => (
        <span
          role="button"
          className="text-info hover-overlay"
          onClick={() => viewMore(row)}
        >
          <i className="fa fa-eye" />
          Manage
        </span>
      ),
    },
  ]

  const patientsUrl = `${process.env.REACT_APP_MAIN_URL}/api/patient/`
  useEffect(() => {
    fetch(patientsUrl, {
      signal: signal,
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setPending(false)
        console.log(res)
        if (res.error) {
          return console.log('patients fetching did not succeed')
        }
        let iterable = user.role === 'SUPER_ADMIN' ? res.data.items : res.data

        let count = 1
        iterable.forEach((item) => {
          item.count = count
          item.name = titleSentenceCase(item.name)
          item.gender = String(item.gender).toUpperCase()
          count++
          //->will delete the rest later
        })
        setPatients(iterable)
        setAllPatients(iterable)
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [reloadPatients])

  const viewMore = (row) => {
    navigate('/patient-profile', { state: row })
  }
  useEffect(() => {
    if (searchKeyword.length === 0) {
      return setPatients(allPatients)
    }
    //wait for some seconds then run search handler
    if (searchKeyword.length > 0) {
      setTimeout(() => {
        searchHandler(allPatients,setPatients, searchKeyword)
      }, 200) //some sort of debounce--whatever
    }
  }, [searchKeyword])


  const readUploadFile = (e) => {
    e.preventDefault()
    if (e.target.files) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = e.target.result
        const workbook = read(data, { type: 'array' })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const json = utils.sheet_to_json(worksheet, {
          raw: false,
        })
        //new array
        const newArr = []
        //cycle thru every obj of this array

        if (json.length > 0) {
          json.map((item) => {
            const tempItem = {}
            tempItem.address = item['Address']
            tempItem.email = item['E-Mail']
            tempItem.phone = item['Phone No']
            tempItem.gender = item['Gender'].toLowerCase()
            tempItem.firstName = item['First Name']
            tempItem.middleName = item['Other Name']
            tempItem.lastName = item['Surname']
            tempItem.birth_date = trimDate(item['Birth Date'], 'date_only')
            tempItem.avatar_mimetype = item['Patient No']
            tempItem.password = item['Patient No'] + '@Healthcenta'
            tempItem.is_verified = 1

            newArr.push(tempItem)
          })
        }

        setPatientsFromExcel(newArr)
      }
      reader.readAsArrayBuffer(e.target.files[0])
    }
  }
  const url = `${process.env.REACT_APP_MAIN_URL}/api/auth/signup`

  const uploadPatients = () => {
    //set status of uploading
    setStatus('Uploading data...')
    console.log(patientsFromExcel)
    patientsFromExcel.map((patient) => {
      return fetch(url, {
        signal: signal,
        method: 'POST',
        headers: new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(patient),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            return setErroed(erroed + 1)
          }

          return setSuccessful(successful + 1)
        })
        .catch((err) => {
          console.log(err)
          return setErroed(erroed + 1)
        })
    })
    setTimeout(() => {
      setStatus('Upload')
      setPatientsFromExcel([])
      // setReloadPatients(!reloadPatients)
      return toast.info('Data Transfer completed. Reload to see changes.', {
        position: toast.POSITION.TOP_CENTER,
      })
    }, 100)
  }


  return (
    <div id="main-wrapper">
      {/***********************************
Header start
************************************/}
      <Header />
      {/***********************************
Header end ti-comment-alt
************************************/}
      {/***********************************
Sidebar start
************************************/}
      <LeftBar />
      {/***********************************
Sidebar end
************************************/}
      {/***********************************
Content body start
************************************/}

      {/***********************************
            Sidebar end
        ************************************/}
      {/***********************************
            Content body start
        ************************************/}

      <div className="content-body">
        <div className="card">
          <div className="card-header py-2">
            <h3>Manage patients</h3>
          </div>
        </div>
        <div className="card">
          <div className="topSearch">
            <Col md={6}>
              <Button
                variant="success"
                className="ml-3 mb-3"
                title="Click here to Add user"
                onClick={() => setAddShow(!addShow)}
              >
                <i className="fa fa-download"></i> Import patients
              </Button>
            </Col>
            <div className="row flex__container" style={{justifyContent: 'flex-end'}}>
              <div className="col-lg-4 col-md-6">
                <input
                  placeholder="search by name,email or phone"
                  className="form-control"
                  type="text"
                  name=""
                  value={searchKeyword}
                  onChange={(e) => setSearchKeyword(e.target.value)}
                />
              </div>
              <div className="col-lg-2 col-md-12">
                <button
                  type="button"
                  className="submitSearch btn btn-info "
                  onClick={()=>searchHandler(allPatients,setPatients, searchKeyword)}
                  title="Click here to search patient"
                >
                  Find Patient
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <DataTable
              columns={columns}
              data={patients}
              progressPending={pending}
              pagination
              expandableRows
              expandableRowsComponent={ExpandedComponent}
            />
          </div>
        </div>

        <Footer />
      </div>
      {/***********************************
    Add Modal start
************************************/}
      <Modal
        size="lg"
        show={addShow}
        onHide={() => setAddShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Import patients data
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Upload an excel file containing patients data</p>
          <div className="form-group">
            <input
              type="file"
              placeholder="Excel file only"
              className="form-control"
              accept=".xlsx, .xls, .csv"
              onChange={readUploadFile}
            />
          </div>
          <div className="form-group">
            <button
              className="btn btn-primary btn-sm"
              disabled={patientsFromExcel.length === 0}
              onClick={uploadPatients}
            >
              {status}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {/***********************************
    Add Modal end
************************************/}
    </div>
  )
}

export default PatientsProfile

// <>
// {(() => {
//   Add array and Lenth here
//   var thisList = [];
//   for (let i = 0; i < 20; i++) {

//     thisList.push(
//       <div className=" review-table">
//         <div className="media">
//           <img className="mr-3 img-fluid" width={110} src="https://i.ibb.co/D8PMZFx/profile.jpg" alt="DexignZone" />
//           <div className="media-body">
//             <h3 className="fs-20 text-black font-w600 mb-3"><a href="email-read.html" className="text-black">Glee Smiley</a>
//             </h3>
//             <div>
//               <p className="mb-1 fs-14">Email:  <h4 className="text-primary2">email@gmail.com</h4></p>

//             </div>
//             <span className="fs-15">Sunday, 24 July 2020   04:55 PM</span>
//           </div>
//           <div className="media-footer d-flex align-self-center">
//             <div className="disease mr-5">
//               <p className="mb-1 fs-14">Disease</p>
//               <h4 className="text-primary2">Diabetes</h4>
//             </div>
//             <div className="edit ml-auto">
//               <a href="javascript:void(0);" className="btn btn-outline-danger">DELETE</a>
//               {/* Add I Here */}
//               <a href="/Patientdetails" className="btn btn-outline-primary ml-2">Details</a>
//             </div>
//           </div>
//         </div>
//       </div>
//     );

//   }

//   return thisList;
// })()}
// </>
