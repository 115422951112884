import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import { formatDate } from '@fullcalendar/react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'
import { humanize } from '../constants/Essentials'

const PatientFollowUps = (props) => {
  const user = useSelector(selectUser)
  toast.configure()
  let navigate = useNavigate()

  const confirmFollowUp = (appt_id) => {
    const confirmUrl = `${process.env.REACT_APP_MAIN_URL}/api/follow-up/confirm?appointment_id=${appt_id}`
    // return toast.info('In Progress', {
    //   position: toast.POSITION.TOP_CENTER,
    // })
    fetch(confirmUrl, {
      method: 'PATCH',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        return toast.success(res?.message, {
          position: toast.POSITION.TOP_CENTER,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <tr className="text-left">
      <td>{humanize(props.appt?.doctorId?.name)}</td>
      <td>{formatDate(props?.appt?.date, { locale: 'es' })}</td>
      <td>{props.appt?.time_slot}</td>
      <td>{props.appt?.reason_for_visit}</td>
      {/* <td>{props.appt?.requestedBy}</td> */}
      <td>
        <button
          onClick={() => confirmFollowUp(props.appt.id)}
          className="btn btn-success btn__confirm__mobo"
        >
          {' '}
          Confirm
        </button>
      </td>
    </tr>
  )
}

export default PatientFollowUps
