import React, { useState } from 'react'
import { toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'

const VerifyRegister = () => {
  let navigate = useNavigate()
  toast.configure()
  const [verifyCode, setVerifyCode] = useState('')
  const url = `${process.env.REACT_APP_MAIN_URL}/api/auth/verify`

  const verifyFunction = () => {
    const dataToSend = {
      code: verifyCode,
    }

    // send request
    fetch(url, {
      method: 'PATCH',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // "Access-Control-Request-Headers": "*",
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          })
        }
        navigate('/login')
        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        })
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('A server error occured', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        })
      })
    //after getting token value, store it to local storage
    // localStorage.setItem("temp_token", "jwt");
  }
  return (
    <div className="bgLockScreen">
      <div className="form-body">
        <div className="row">
          <div className="img-holder">
            <div className="bg" />
            <h3
              className="bigScreenOnly text-left mr-5"
              style={{ marginTop: '55vh' }}
            >
              Welcome to <span>Better</span> Healthcare Management
            </h3>
            <div className="info-holder"></div>
          </div>
          <div className="form-holder">
            <div className="form-content">
              <div className="form-items">
                <div className="website-logo">
                  <a href="#">
                    <div className="logo">
                      <img
                        className="logo-size"
                        src="images/logo2.png"
                        alt=""
                      />
                    </div>
                  </a>
                </div>
                <h3>Verify Your Account</h3>
                <p>Check your email or sms and enter verification code below</p>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    value={verifyCode}
                    onChange={(e) => setVerifyCode(e.target.value)}
                    placeholder="Verification code"
                    title="Type Verification code"
                    required
                  />
                  <div className="form-button full-width">
                    <button
                      disabled={!verifyCode}
                      onClick={verifyFunction}
                      className="ibtn btn-forget"
                      title="Click here to verify account"

                    >
                      Verify
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyRegister
