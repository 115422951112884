import Footer from '../../Footer'
import LeftBar from '../../LeftBar'
import Header from '../../Header'
import { Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
const NewActivityCarePlan = () => {
  return (
    <div className="main-wrapper">
      {/***********************************
                  Header start
                  ************************************/}
      <Header />
      {/***********************************
                  Header end ti-comment-alt
                  ************************************/}
      {/***********************************
                  Sidebar start
                  ************************************/}
      <LeftBar />
      {/***********************************
                  Sidebar end
                  ************************************/}
      {/***********************************
                  Content body start
                  ************************************/}

      {/***********************************
                  Content body end
                  ************************************/}
      <div className="content-body">
        <p
          className="mt-3 mb-3"
          style={{
            fontSize: 20,
            fontFamily: 'Lato',
            fontWeight: 700,
            color: '#000',
          }}
        >
          Activity & Exercises
        </p>
        <div className="row">
          <div
            className=" col-md-8 col-lg-8 col-sm-12 d-flex flex-wrap rounded"
            style={{ backgroundColor: '#fff', padding: 20, borderRadius: 8 }}
          >
            <div className="col-md-12">
              {['Cardio', 'Strength'].map((exercise) => (
                <div>
                  <p
                    className="meal-profile-text mt-3"
                    style={{ color: '#000' }}
                  >
                    {exercise}
                  </p>
                  <div className="d-flex flex-wrap">
                    <label className="mr-3 mt-2">Time</label>
                    <input
                      className="mt-2"
                      type="time"
                      style={{
                        width: 135,
                        height: 27,
                        marginBottom: 20,
                        border: '1px solid #662382',
                      }}
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-lg-6">
                      <label>Frequency</label>
                      <Form.Select
                        aria-label="Default select example"
                        className="mr-2 pl-2"
                      >
                        <option>frequency</option>
                        <option>Daily</option>
                        <option>Monthly</option>
                      </Form.Select>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <label>Duration</label>
                      <Form.Select
                        aria-label="Default select example"
                        className="mr-2 pl-2"
                      >
                        <option>duration</option>
                        <option>less than 1 hr</option>
                        <option>1-5 hr</option>
                        <option>over 5 hrs</option>
                      </Form.Select>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-sm-12 card">
            <p
              className="mt-3 mb-3"
              style={{
                fontSize: 24,
                fontFamily: 'Lato',
                fontWeight: 700,
                color: '#662382',
              }}
            >
              Activity Profile
            </p>
            <div className="card">
              <div
                className="d-flex justify-content-between pl-2 pt-2 pr-2 pb-2"
                style={{ border: '1px solid #662382', borderRadius: 8 }}
              >
                <span
                  style={{
                    fontSize: 16,
                    fontFamily: 'Lato',
                    fontWeight: 700,
                    color: '#662382',
                    marginTop: 10,
                  }}
                >
                  Weekly Cardio
                </span>
                <div
                  style={{
                    backgroundColor: '#662382',
                    height: 30,
                    width: 2,
                    marginTop: 5,
                  }}
                />
                <Button
                  style={{
                    backgroundColor: '#97CE5157',
                    border: '1px solid #97CE5157',
                    marginLeft: 30,
                    width: 126,
                  }}
                >
                  OPTIMAL
                </Button>
              </div>
              <div
                className="d-flex justify-content-between pl-2 pt-2 pr-2 pb-2 mt-2"
                style={{ border: '1px solid #662382', borderRadius: 8 }}
              >
                <span
                  style={{
                    fontSize: 16,
                    fontFamily: 'Lato',
                    fontWeight: 700,
                    color: '#662382',
                    marginTop: 10,
                  }}
                >
                  Weekly Strength
                </span>
                <div
                  style={{
                    backgroundColor: '#662382',
                    height: 30,
                    width: 2,
                    marginTop: 5,
                  }}
                />
                <Button
                  style={{
                    backgroundColor: '#FF005C3D',
                    border: '1px solid #FF005C3D',
                    marginLeft: 30,
                    width: 126,
                  }}
                >
                  Low
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-start mt-2 ml-2"
          style={{ gap: 20 }}
        >
          <div>
            <Link to="/new-nutrition">
              <Button variant="success">Back</Button>
            </Link>
          </div>
          <Link to="/new-habit-breaker-care-plan">
            <Button variant="success">Next</Button>
          </Link>
        </div>
      </div>

      {/***********************************
                  Footer start
                  ************************************/}
      <Footer />
      {/***********************************
                  Footer end
                  ************************************/}
    </div>
  )
}

export default NewActivityCarePlan
