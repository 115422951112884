import { useRef,useEffect } from "react";
import { Button, Form } from "react-bootstrap";

const CodeValidation = () => {
  const inputOne = useRef()
  const inputTwo = useRef()
  const inputThree = useRef()
  const inputFour = useRef();
  useEffect(() => {
    inputOne.current.focus()
  },[])
  return (
    <div>
      <div
        style={{
          background:
            "linear-gradient(180deg, rgba(69, 183, 87, 0.6) 0%, rgba(47, 165, 219, 0.6) 100%)",
          height: "100vh",
        }}
      >
        <div style={{ marginLeft: "0" }} className='content-body'>
          <div>
            <div className='text-center' style={{ width: "100%" }}>
              <div className='filipelogos' style={{ marginBottom: -20 }}></div>
              <p
                style={{
                  fontSize: 24,
                  fontWeight: 800,
                  fontFamily: "Lato",
                  color: "#19226D",
                }}
              >
                Verify Your Account
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 400,
                  fontFamily: "Lato",
                }}
              >
                A verification code has been sent via email and text to the
                information you provided on
                <br /> the previous screen. Please enter the verification code
                below.
              </p>
              <div
                className='d-flex flex-wrap justify-content-center mt-4 mb-3'
                style={{ gap: 30 }}
              >
                <Form.Control
                  type='text'
                  style={{
                    background: "rgba(255, 255, 255, 0.35)",
                    border: "1px solid #FFF",
                    width: 130,
                  }}
                  ref={inputOne}
                  onChange={(e) => {
                    if (e.target.value) {
                      inputTwo.current.focus();
                    }
                  }}
                />
                <Form.Control
                  type='text'
                  style={{
                    background: "rgba(255, 255, 255, 0.35)",
                    border: "1px solid #FFF",
                    width: 130,
                  }}
                  ref={inputTwo}
                  onChange={(e) => {
                    if (e.target.value) {
                      inputThree.current.focus();
                    }
                  }}
                />
                <Form.Control
                  type='text'
                  style={{
                    background: "rgba(255, 255, 255, 0.35)",
                    border: "1px solid #FFF",
                    width: 130,
                  }}
                  ref={inputThree}
                  onChange={(e) => {
                    if (e.target.value) {
                      inputFour.current.focus();
                    }
                  }}
                />
                <Form.Control
                  type='text'
                  style={{
                    background: "rgba(255, 255, 255, 0.35)",
                    border: "1px solid #FFF",
                    width: 130,
                  }}
                  ref={inputFour}
                  onChange={(e) => {
                    if (e.target.value) {
                      console.log("last digit of code");
                    }
                  }}
                />
              </div>
              <i className='mt-5' sytle={{ fontFamily: 'Lato', fontSize: 14, fontWeight: 500, textAlign: 'center', cursor: 'pointer' }}>Resend code?</i>
              <div className='d-flex justify-content-center mt-3'>
                <Button style={{width: '40%',color: '#FFF'}} variant='success'>Verify</Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/***********************************
            Footer start
        ************************************/}
      {/***********************************
            Footer end
        ************************************/}
    </div>
  );
};

export default CodeValidation;
