export const defaultLabData = {
  heart_function: {
    cholesterol: {
      value: 0,
      level: '',
      bg: '#edf7e1',
      units: 'mg/dl',
      min: 100,
      max: 600,
      step: 1,
    },
    ldl: {
      value: 0,
      level: '',
      bg: '#dee9ff',
      units: 'mg/dl',
      min: 0,
      max: 100,
      step: 1,
    },
    hdl: {
      value: 0,
      level: '',
      bg: '#ebe1f3',
      units: 'mg/dl',
      min: 0,
      max: 100,
      step: 1,
    },
    total_cholesterol: {
      value: 0,
      level: '',
      bg: '#b4e588',
      units: 'mg/dl',
      min: 0,
      max: 100,
      step: 1,
    },
    triglycerides: {
      value: 0,
      level: '',
      bg: '#97d2ed',
      units: 'mg/dl',
      min: 0,
      max: 100,
      step: 1,
    },
    vldl_levels: {
      value: 0,
      level: '',
      bg: '#b391c0',
      units: 'mg/dl',
      min: 0,
      max: 100,
      step: 1,
    },
  },
  liver_function: {
    alt: {
      value: 0,
      level: '',
      bg: '#edf7e1',
      units: 'u/l',
      min: 0,
      max: 100,
      step: 1,
    },
    ast: {
      value: 0,
      level: '',
      bg: '#dee9ff',
      units: 'u/l',
      min: 0,
      max: 100,
      step: 1,
    },
    alp: {
      value: 0,
      level: '',
      bg: '#ebe1f3',
      units: 'u/l',
      min: 0,
      max: 100,
      step: 1,
    },
    albumin: {
      value: 0,
      level: '',
      bg: '#b4e588',
      units: 'g/dl',
      min: 0,
      max: 100,
      step: 1,
    },
    total_protein: {
      value: 0,
      level: '',
      bg: '#97d2ed',
      units: 'g/dl',
      min: 0,
      max: 100,
      step: 1,
    },
    bilirubin: {
      value: 0,
      level: '',
      bg: '#b391c0',
      units: 'mg/dl',
      min: 0,
      max: 100,
      step: 1,
    },
    ggt: {
      value: 0,
      level: '',
      bg: '#edf7e1',
      units: 'u/l',
      min: 0,
      max: 100,
      step: 1,
    },
    ld: {
      value: 0,
      level: '',
      bg: '#dee9ff',
      units: 'u/l',
      min: 0,
      max: 100,
      step: 1,
    },
    pt: {
      value: 0,
      level: '',
      bg: '#ebe1f3',
      units: 'seconds',
      min: 0,
      max: 100,
      step: 1,
    },
  },
  kidney_function: {
    egfr: {
      value: 0,
      level: '',
      bg: '#edf7e1',
      units: 'mg/dl',
      min: 0,
      max: 100,
      step: 1,
    },
    serum_creatinine: {
      value: 0,
      level: '',
      bg: '#dee9ff',
      units: 'mg/dl',
      min: 0,
      max: 100,
      step: 1,
    },
    creatinine_clearance: {
      value: 0,
      level: '',
      bg: '#ebe1f3',
      units: 'ml/min/BSA',
      min: 0,
      max: 100,
      step: 1,
    },
    uacr: {
      value: 0,
      level: '',
      bg: '#b4e588',
      units: 'mg/g',
      min: 0,
      max: 100,
      step: 1,
    },
    bun: {
      value: 0,
      level: '',
      bg: '#97d2ed',
      units: 'mg/dl',
      min: 0,
      max: 100,
      step: 1,
    },
  },
  sodium_level_test: {
    urinalysis: {
      value: 0,
      level: '',
      bg: '#edf7e1',
      units: 'mEq/l',
      min: 0,
      max: 100,
      step: 1,
    },
    blood_sodium: {
      value: 0,
      level: '',
      bg: '#dee9ff',
      units: 'mEq/l',
      min: 0,
      max: 100,
      step: 1,
    },
    potassium: {
      value: 0,
      level: '',
      bg: '#ebe1f3',
      units: 'mmol/l',
      min: 0,
      max: 100,
      step: 1,
    },
  },
  notes: '',
}
export const labLevels = [
  'Very High',
  'High',
  'Borderline High',
  'Optimal',
  'Near Optimal',
  'Low',
  'Very Low',
]
export const defaultSummaryData = {
  vitals: [],
  underlying_conditions: [],
  family_history: [],
  lifestyle_review: [],
  lab_tests: [],
}

export const defaultVitalsData = {
  vitals_readings: {
    blood_pressure: '0',
    blood_pressure_level: '',
    heart_rate: '0',
    heart_rate_level: '',
    respiration_rate: '0',
    respiration_rate_level: '',
    weight: '0',
    weight_level: '',
  },
  stethoscopic_readings: {
    systolic_murmurs: '0',
    systolic_murmurs_level: '',
    diastolic_murmurs: '0',
    diastolic_murmurs_level: '',
    temperature: '0',
    temperature_level: '',
  },
  risk_level: '',
  notes: '',
}

export const defaultfamilyHistoryData = {
  condition_obj: [
    {
      condition: 'Hypertension',
      cases: [
        {
          relation: '',
          relation_side: '',
          age: '',
          condition_type: '',
          condition_stage: '',
          diagnosis_date: '',
        },
      ],
    },
    {
      condition: 'Diabetes',
      cases: [
        {
          relation: '',
          relation_side: '',
          age: '',
          condition_type: '',
          condition_stage: '',
          diagnosis_date: '',
        },
      ],
    },
    {
      condition: 'Obesity',
      cases: [
        {
          relation: '',
          relation_side: '',
          age: '',
          condition_type: '',
          condition_stage: '',
          diagnosis_date: '',
        },
      ],
    },
    {
      condition: 'Metabolic Syndrome',
      cases: [
        {
          relation: '',
          relation_side: '',
          age: '',
          condition_type: '',
          condition_stage: '',
          diagnosis_date: '',
        },
      ],
    },
    {
      condition: 'Stroke',
      cases: [
        {
          relation: '',
          relation_side: '',
          age: '',
          condition_type: '',
          condition_stage: '',
          diagnosis_date: '',
        },
      ],
    },
    {
      condition: 'Heart Disease',
      cases: [
        {
          relation: '',
          relation_side: '',
          age: '',
          condition_type: '',
          condition_stage: '',
          diagnosis_date: '',
        },
      ],
    },
  ],
}

export const defaultUnderlyingConditions = {
  condition_obj: [
    {
      condition: 'Hypertension',
      cases: [
        {
          condition_type: '',
          condition_stage: '',
          diagnosis_date: '',
        },
      ],
    },
    {
      condition: 'Diabetes',
      cases: [
        {
          condition_type: '',
          condition_stage: '',
          diagnosis_date: '',
        },
      ],
    },
    {
      condition: 'Obesity',
      cases: [
        {
          condition_type: '',
          condition_stage: '',
          diagnosis_date: '',
        },
      ],
    },
    {
      condition: 'Metabolic Syndrome',
      cases: [
        {
          condition_type: '',
          condition_stage: '',
          diagnosis_date: '',
        },
      ],
    },
    {
      condition: 'Stroke',
      cases: [
        {
          condition_type: '',
          condition_stage: '',
          diagnosis_date: '',
        },
      ],
    },
    {
      condition: 'Heart Disease',
      cases: [
        {
          condition_type: '',
          condition_stage: '',
          diagnosis_date: '',
        },
      ],
    },
  ],
  prescription_obj: {
    obesity: false,
    contraceptive_pills: false,
    steroids: false,
    anti_inflammatory: false,
    anti_depressants: false,
    herbal_remedies: false,
    serotonin_inhibitor: false,
  },
  drug_allergies: '',
}

export const defaultLifeStyleReviewData = {
  activity_level_activities: {
    Walking: false,
    Jogging: false,
    Running: false,
    Yoga: false,
    Dancing: false,
    Cycling: false,
    Swimming: false,
    Hiking: false,
    Skipping: false,
    Gym: false,
    Other: false,
  },
  activity_mins: 15,
  activity_days: 1,
  activity_level: 'Medium',
  activity_notes: '',
  energy_level: 1,
  fatigue_level: 1,
  nutrition_calories_intake: '0',
  nutrition_meals: {
    breakfast: false,
    lunch: false,
    dinner: false,
    other: 'other',
  },
  nutrition_meals_frequency: '0',
  nutrition_meal_type: '0',
  nutrition_appetite: 'Medium',
  nutrition_notes: '',
  habits_risk_level: 'Medium',
  habits_alcohol_pints_day: 0,
  habits_alcohol_glasses_day: 0,
  habits_alcohol_totes_day: 0,
  habits_cigarrettes_day: 0,
  habits_recreational_drugs_freq: 0,
  habits_recreational_drugs_notes: '',
  habits_notes: '',
  sexual_function: {
    normal: false,
    erectile_disfunction: false,
    pregnancy: false,
  },
  sexual_function_nitrooxide: 0,
  sexual_function_nitrooxide_notes: '',
  sexual_function_notes: '',
  mood_anxiety: 0,
  mood_depression: 0,
  mood_irrational_fears: 0,
  mood_sleep_problems: '',
  mood_risk_level: 'Medium',
  mood_notes: '',
  se_hobbies_text: '',
  se_career: '',
  se_social_activities: 0,
  se_social_isolation: 0,
  se_care_support: '',
  se_financial_legal_concern: 0,
  se_abuse: 0,
  se_home_comfort: 0,
  se_local_services_access: '',
  se_formal_support: 0,
  se_notes: '',
  se_risk_level: 'Medium',
}
