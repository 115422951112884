import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const size = 4
const tempStyles = {
  val: {
    top: '21%',
  },
  unit: {
    top: '65%',
  },
  level: {
    transform: 'rotate(90deg)',
  },
}

export const SummarypageBonesTop = () => {
  return (
    <div>
      <Row className="p-2">
        <Col sm={4}>
          <div className="patient__details">
            <h6 className="vital__subtopic">Patient Details</h6>
            <div>
              <Skeleton count={8} />
            </div>
          </div>
        </Col>
        <Col col={8} className="mt-2">
          <div className="vitals__summary">
            <Row>
              <Col sm={size} style={{ paddingLeft: 20 }}>
                <div className="vital__cardloading">
                  <Skeleton height={90} />
                </div>
              </Col>
              <Col sm={size}>
                <div className="vital__cardloading">
                  <Skeleton height={90} />
                </div>
              </Col>
              <Col sm={size}>
                <div className="vital__cardloading">
                  <Skeleton height={90} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={size} style={{ paddingLeft: 20 }}>
                <div className="vital__cardloading">
                  <Skeleton height={90} />
                </div>
              </Col>
              <Col sm={size}>
                <div className="vital__cardloading">
                  <Skeleton height={90} />
                </div>
              </Col>
              <Col sm={size}>
                <div className="vital__cardloading">
                  <Skeleton height={90} />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export const SummarypageBonesComorbidities = () => {
  return (
    <Row>
      <Col sm={6}>
        <div className="underlying__condition__summary">
          <Skeleton count={2} width="60%" />
          <Skeleton count={5} width="40%" />
        </div>
      </Col>
      <Col sm={6}>
        <div className="underlying__condition__summary">
          <Skeleton count={2} width="60%" />
          <Skeleton count={5} width="40%" />
        </div>
      </Col>
    </Row>
  )
}

export const SummarypageBonesLabs = () => {
  return (
    <div className="habits__container">
      <Skeleton width="30%" />
      <hr />
      <Row className="p-3">
        <Col md={2} className="mr-3 mt-2 mb-3">
          <Skeleton width="40%" style={{ marginLeft: -18 }} />
          <div className="row mt-2">
            <Skeleton
              width="20%"
              height={30}
              className="col-sm-4"
              style={tempStyles.val}
            />
            <Skeleton
              width="10%"
              height="10%"
              className="col-sm-2"
              style={tempStyles.unit}
            />
            <Skeleton
              className="col-sm-6"
              height={50}
              style={tempStyles.level}
            />
          </div>
        </Col>
        <Col md={2} className="mr-3 mt-2 mb-3">
          <Skeleton width="40%" style={{ marginLeft: -18 }} />
          <div className="row mt-2">
            <Skeleton
              width="20%"
              height={30}
              className="col-sm-4"
              style={tempStyles.val}
            />
            <Skeleton
              width="10%"
              height="10%"
              className="col-sm-2"
              style={tempStyles.unit}
            />
            <Skeleton
              className="col-sm-6"
              height={50}
              style={tempStyles.level}
            />
          </div>
        </Col>
        <Col md={2} className="mr-3 mt-2 mb-3">
          <Skeleton width="40%" style={{ marginLeft: -18 }} />
          <div className="row mt-2">
            <Skeleton
              width="20%"
              height={30}
              className="col-sm-4"
              style={tempStyles.val}
            />
            <Skeleton
              width="10%"
              height="10%"
              className="col-sm-2"
              style={tempStyles.unit}
            />
            <Skeleton
              className="col-sm-6"
              height={50}
              style={tempStyles.level}
            />
          </div>
        </Col>
        <Col md={2} className="mr-3 mt-2 mb-3">
          <Skeleton width="40%" style={{ marginLeft: -18 }} />
          <div className="row mt-2">
            <Skeleton
              width="20%"
              height={30}
              className="col-sm-4"
              style={tempStyles.val}
            />
            <Skeleton
              width="10%"
              height="10%"
              className="col-sm-2"
              style={tempStyles.unit}
            />
            <Skeleton
              className="col-sm-6"
              height={50}
              style={tempStyles.level}
            />
          </div>
        </Col>
        <Col md={2} className="mr-3 mt-2 mb-3">
          <Skeleton width="40%" style={{ marginLeft: -18 }} />
          <div className="row mt-2">
            <Skeleton
              width="20%"
              height={30}
              className="col-sm-4"
              style={tempStyles.val}
            />
            <Skeleton
              width="10%"
              height="10%"
              className="col-sm-2"
              style={tempStyles.unit}
            />
            <Skeleton
              className="col-sm-6"
              height={50}
              style={tempStyles.level}
            />
          </div>
        </Col>
        <Col md={2} className="mr-3 mt-2 mb-3">
          <Skeleton width="40%" style={{ marginLeft: -18 }} />
          <div className="row mt-2">
            <Skeleton
              width="20%"
              height={30}
              className="col-sm-4"
              style={tempStyles.val}
            />
            <Skeleton
              width="10%"
              height="10%"
              className="col-sm-2"
              style={tempStyles.unit}
            />
            <Skeleton
              className="col-sm-6"
              height={50}
              style={tempStyles.level}
            />
          </div>
        </Col>
      </Row>
      <Skeleton width="30%" />
      <hr />
      <Row className="p-3">
        <Col md={2} className="mr-3 mt-2 mb-3">
          <Skeleton width="40%" style={{ marginLeft: -18 }} />
          <div className="row mt-2">
            <Skeleton
              width="20%"
              height={30}
              className="col-sm-4"
              style={tempStyles.val}
            />
            <Skeleton
              width="10%"
              height="10%"
              className="col-sm-2"
              style={tempStyles.unit}
            />
            <Skeleton
              className="col-sm-6"
              height={50}
              style={tempStyles.level}
            />
          </div>
        </Col>
        <Col md={2} className="mr-3 mt-2 mb-3">
          <Skeleton width="40%" style={{ marginLeft: -18 }} />
          <div className="row mt-2">
            <Skeleton
              width="20%"
              height={30}
              className="col-sm-4"
              style={tempStyles.val}
            />
            <Skeleton
              width="10%"
              height="10%"
              className="col-sm-2"
              style={tempStyles.unit}
            />
            <Skeleton
              className="col-sm-6"
              height={50}
              style={tempStyles.level}
            />
          </div>
        </Col>
        <Col md={2} className="mr-3 mt-2 mb-3">
          <Skeleton width="40%" style={{ marginLeft: -18 }} />
          <div className="row mt-2">
            <Skeleton
              width="20%"
              height={30}
              className="col-sm-4"
              style={tempStyles.val}
            />
            <Skeleton
              width="10%"
              height="10%"
              className="col-sm-2"
              style={tempStyles.unit}
            />
            <Skeleton
              className="col-sm-6"
              height={50}
              style={tempStyles.level}
            />
          </div>
        </Col>
        <Col md={2} className="mr-3 mt-2 mb-3">
          <Skeleton width="40%" style={{ marginLeft: -18 }} />
          <div className="row mt-2">
            <Skeleton
              width="20%"
              height={30}
              className="col-sm-4"
              style={tempStyles.val}
            />
            <Skeleton
              width="10%"
              height="10%"
              className="col-sm-2"
              style={tempStyles.unit}
            />
            <Skeleton
              className="col-sm-6"
              height={50}
              style={tempStyles.level}
            />
          </div>
        </Col>
        <Col md={2} className="mr-3 mt-2 mb-3">
          <Skeleton width="40%" style={{ marginLeft: -18 }} />
          <div className="row mt-2">
            <Skeleton
              width="20%"
              height={30}
              className="col-sm-4"
              style={tempStyles.val}
            />
            <Skeleton
              width="10%"
              height="10%"
              className="col-sm-2"
              style={tempStyles.unit}
            />
            <Skeleton
              className="col-sm-6"
              height={50}
              style={tempStyles.level}
            />
          </div>
        </Col>
        <Col md={2} className="mr-3 mt-2 mb-3">
          <Skeleton width="40%" style={{ marginLeft: -18 }} />
          <div className="row mt-2">
            <Skeleton
              width="20%"
              height={30}
              className="col-sm-4"
              style={tempStyles.val}
            />
            <Skeleton
              width="10%"
              height="10%"
              className="col-sm-2"
              style={tempStyles.unit}
            />
            <Skeleton
              className="col-sm-6"
              height={50}
              style={tempStyles.level}
            />
          </div>
        </Col>
      </Row>
      <Skeleton width="30%" />
      <hr />
      <Row className="p-3">
        <Col md={2} className="mr-3 mt-2 mb-3">
          <Skeleton width="40%" style={{ marginLeft: -18 }} />
          <div className="row mt-2">
            <Skeleton
              width="20%"
              height={30}
              className="col-sm-4"
              style={tempStyles.val}
            />
            <Skeleton
              width="10%"
              height="10%"
              className="col-sm-2"
              style={tempStyles.unit}
            />
            <Skeleton
              className="col-sm-6"
              height={50}
              style={tempStyles.level}
            />
          </div>
        </Col>
        <Col md={2} className="mr-3 mt-2 mb-3">
          <Skeleton width="40%" style={{ marginLeft: -18 }} />
          <div className="row mt-2">
            <Skeleton
              width="20%"
              height={30}
              className="col-sm-4"
              style={tempStyles.val}
            />
            <Skeleton
              width="10%"
              height="10%"
              className="col-sm-2"
              style={tempStyles.unit}
            />
            <Skeleton
              className="col-sm-6"
              height={50}
              style={tempStyles.level}
            />
          </div>
        </Col>
        <Col md={2} className="mr-3 mt-2 mb-3">
          <Skeleton width="40%" style={{ marginLeft: -18 }} />
          <div className="row mt-2">
            <Skeleton
              width="20%"
              height={30}
              className="col-sm-4"
              style={tempStyles.val}
            />
            <Skeleton
              width="10%"
              height="10%"
              className="col-sm-2"
              style={tempStyles.unit}
            />
            <Skeleton
              className="col-sm-6"
              height={50}
              style={tempStyles.level}
            />
          </div>
        </Col>
        <Col md={2} className="mr-3 mt-2 mb-3">
          <Skeleton width="40%" style={{ marginLeft: -18 }} />
          <div className="row mt-2">
            <Skeleton
              width="20%"
              height={30}
              className="col-sm-4"
              style={tempStyles.val}
            />
            <Skeleton
              width="10%"
              height="10%"
              className="col-sm-2"
              style={tempStyles.unit}
            />
            <Skeleton
              className="col-sm-6"
              height={50}
              style={tempStyles.level}
            />
          </div>
        </Col>
        <Col md={2} className="mr-3 mt-2 mb-3">
          <Skeleton width="40%" style={{ marginLeft: -18 }} />
          <div className="row mt-2">
            <Skeleton
              width="20%"
              height={30}
              className="col-sm-4"
              style={tempStyles.val}
            />
            <Skeleton
              width="10%"
              height="10%"
              className="col-sm-2"
              style={tempStyles.unit}
            />
            <Skeleton
              className="col-sm-6"
              height={50}
              style={tempStyles.level}
            />
          </div>
        </Col>
        <Col md={2} className="mr-3 mt-2 mb-3">
          <Skeleton width="40%" style={{ marginLeft: -18 }} />
          <div className="row mt-2">
            <Skeleton
              width="20%"
              height={30}
              className="col-sm-4"
              style={tempStyles.val}
            />
            <Skeleton
              width="10%"
              height="10%"
              className="col-sm-2"
              style={tempStyles.unit}
            />
            <Skeleton
              className="col-sm-6"
              height={50}
              style={tempStyles.level}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}
