import React, { useState, useRef,useEffect } from "react";
import { Button, Form, FormControl, Modal } from "react-bootstrap";
const NutritionAndDiet = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [meals, setMeals] = useState([]);
  return (
    <div className='row ml-1'>
      <div
        className=' col-md-8 col-lg-8 col-sm-12 d-flex flex-wrap rounded'
        style={{ backgroundColor: "#fff", padding: 20, borderRadius: 8 }}
      >
        <div className='col-md-12'>
          <p className='meal-profile-text mt-3' style={{ color: "#000" }}>
            Diet and Nutrition
          </p>
          <label>How many meals do you have in day?</label>
          <div className='d-flex justify-content-between flex-wrap mr-3 meals'>
            {["Breakfast", "Lunch", "Dinner", "Snacks"].map((food, index) => (
              <Form.Check
                key={index}
                label={food}
                id={food}
                type='checkbox'
                className={isChecked ? "checked" : ""}
                onChange={(e) => {
                  setIsChecked(e.target.checked);
                  if (e.target.checked) {
                    setMeals([...meals, food]);
                  } else {
                    const index = meals.indexOf(food);
                    meals.splice(index, 1);
                    setMeals([...meals]);
                  }
                }}
              />
            ))}
          </div>
          {meals?.map((meal, index) => (
            <div key={index}>
              <label className='mt-3' style={{ color: "#000" }}>
                {meal}
              </label>
              <div className='d-flex flex-wrap' style={{ gap: 50 }}>
                <div>
                  <label>Frequency</label>
                  <Form.Select className='pl-2'>
                    <option>4 Days per week</option>
                    <option>2 Days per week</option>
                    <option>5 Days per week</option>
                  </Form.Select>
                </div>
                <div style={{ width: "60%" }}>
                  <label>Meal Type</label>
                  <FormControl
                    as='textarea'
                    rows='3'
                    placeholder='Type something here...'
                    style={{ backgroundColor: "#eee" }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='col-md-4 col-lg-4 col-sm-12 card'>
        {meals.map((meal, index) => (
          <div key={index}>
            <p
              className='mt-3 mb-3'
              style={{
                fontSize: 24,
                fontFamily: "Lato",
                fontWeight: 700,
                color: "#662382",
              }}
            >
              {meal}
            </p>
            <div className='card'>
              <div
                className='d-flex justify-content-between mt-2 pl-2 pt-2 pr-2 pb-2'
                style={{ border: "1px solid #662382", borderRadius: 8 }}
              >
                <span
                  style={{
                    fontSize: 16,
                    fontFamily: "Lato",
                    fontWeight: 700,
                    color: "#662382",
                    marginTop: 10,
                  }}
                >
                  Carbohydrate Coverage
                </span>
                <div
                  style={{
                    backgroundColor: "#662382",
                    height: 30,
                    width: 2,
                    marginTop: 5,
                  }}
                />
                <Button
                  style={{
                    backgroundColor: "#97CE513D",
                    border: "1px solid #97CE513D",
                    marginLeft: 30,
                    width: 126,
                  }}
                >
                  Yes
                </Button>
              </div>
              <div
                className='d-flex justify-content-between mt-2 pl-2 pt-2 pr-2 pb-2'
                style={{ border: "1px solid #662382", borderRadius: 8 }}
              >
                <span
                  style={{
                    fontSize: 16,
                    fontFamily: "Lato",
                    fontWeight: 700,
                    color: "#662382",
                    marginTop: 10,
                  }}
                >
                  Protein Coverage
                </span>
                <div
                  style={{
                    backgroundColor: "#662382",
                    height: 30,
                    width: 2,
                    marginTop: 5,
                    marginLeft: 40,
                  }}
                />
                <Button
                  style={{
                    backgroundColor: "#97CE513D",
                    border: "1px solid #97CE513D",
                    marginLeft: 30,
                    width: 126,
                  }}
                >
                  Yes
                </Button>
              </div>
              <div
                className='d-flex justify-content-between mt-2 pl-2 pt-2 pr-2 pb-2'
                style={{ border: "1px solid #662382", borderRadius: 8 }}
              >
                <span
                  style={{
                    fontSize: 16,
                    fontFamily: "Lato",
                    fontWeight: 700,
                    color: "#662382",
                    marginTop: 10,
                  }}
                >
                  Fats Coverage
                </span>
                <div
                  style={{
                    backgroundColor: "#662382",
                    height: 30,
                    width: 2,
                    marginTop: 5,
                    marginLeft: 55,
                  }}
                />
                <Button
                  style={{
                    backgroundColor: "#97CE513D",
                    border: "1px solid #97CE513D",
                    marginLeft: 30,
                    width: 126,
                  }}
                >
                  Yes
                </Button>
              </div>
              <div
                className='d-flex justify-content-between pl-2 pt-2 pr-2 pb-2 mt-2'
                style={{ border: "1px solid #662382", borderRadius: 8 }}
              >
                <span
                  style={{
                    fontSize: 16,
                    fontFamily: "Lato",
                    fontWeight: 700,
                    color: "#662382",
                    marginTop: 10,
                  }}
                >
                  Est Calorie Intake
                </span>
                <div
                  style={{
                    backgroundColor: "#662382",
                    height: 30,
                    width: 2,
                    marginTop: 5,
                    marginLeft: 25,
                  }}
                />
                <Button
                  style={{
                    backgroundColor: "#FF005C3D",
                    border: "1px solid #FF005C3D",
                    marginLeft: 30,
                    width: 126,
                  }}
                >
                  High
                </Button>
              </div>
            </div>
          </div>
        ))}
        {meals.length ? (
          <Button className='mt-2 mb-2' variant='success'>
            Update Activity Profile
          </Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default NutritionAndDiet;
