import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import LeftBar from '../components/LeftBar'
import MainApp from '../components/calendar/MainApp'
import Preloader from '../components/Preloader'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'

const Calendar = () => {
  const [loading, setLoading] = useState(false)

  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  const user = useSelector(selectUser)
  const health_center = user.hc_id
  const [doctor, setDoctor] = useState([])
  const [selectedDoctor, setSelectedDoctor] = useState('')
  const [appointments, setAppointments] = useState([])
  const [appointmentsToday, setAppointmentsToday] = useState([])

  useEffect(() => {
    const fetchUsersUrl = `${process.env.REACT_APP_MAIN_URL}/api/user/health-center/?role=doctor&health_center_id=${health_center}`
    fetch(fetchUsersUrl, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        res.data.items.forEach((item) => {
          item.value = item.id
          item.label = item.name
          // now I no longer need them
          delete item.id
          delete item.name
          //->will delete the rest later
        })
        // console.log(res.data);

        res.data?.items?.length > 0 && setDoctor(res.data.items)
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  const fetchAppointmentsUrl =
    user.role === 'Patient'
      ? `${process.env.REACT_APP_MAIN_URL}/api/appointment/patient/${user.user_id}?size=100`
      : user.role === 'DOCTOR'
      ? `${process.env.REACT_APP_MAIN_URL}/api/doctor/appointments`
      : `${process.env.REACT_APP_MAIN_URL}/api/user/appointments-admin?size=100`
  useEffect(() => {
    fetch(fetchAppointmentsUrl, {
      method: 'GET',
      signal: signal,
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        res.data.items.forEach((item) => {
          // item.appointment_id = item.id;
          // item.id = count;
          item.title =
            user.role === 'Patient' ? item?.doctorId?.name : item?.patient?.name
          const dt = new Date(item.date)
          // item.value = item.id;
          // item.start = ;
          // const slot = "T"+item.time_slot.substring(0, str.length - 2)
          item.start = String(
            dt.toISOString().replace(/T.*$/, '') + 'T' + item.time_slot,
          )
          // item.start = "2022-02-01T16:38";

          //->will delete the rest later
        })

        // set appointments today
        console.log(res.data?.items[0]?.date)
        const appointmentsT = res?.data?.items.filter(
          (item) =>
            new Date(item.date).toISOString().replace(/T.*$/, '') ===
            new Date().toISOString().replace(/T.*$/, ''),
        )
        console.log(appointmentsT)
        setAppointmentsToday(appointmentsT)
        // console.log(res.data);

        res.data?.items?.length > 0 && setAppointments(res.data.items)
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [selectedDoctor])
  return (
    <div id="main-wrapper">
      {/***********************************
            Nav header start
        ************************************/}
      {/* <NavHeader/> */}
      {/***********************************
            Nav header end
        ************************************/}

      {/***********************************
            Header start
        ************************************/}
      <Header />
      {/***********************************
            Header end ti-comment-alt
        ************************************/}
      {/***********************************
            Sidebar start
        ************************************/}
      <LeftBar />
      {/***********************************
            Sidebar end
        ************************************/}
      {/***********************************
            Content body start
        ************************************/}
      <div className="content-body">
        {/* {user.role !== 'Patient' && (
          <Row className="mb-4">
            <Col md={4}>
              <Select
                options={doctor}
                id="doctor"
                onChange={(selectedOption) =>
                  setSelectedDoctor(selectedOption.value)
                }
                placeholder="Select Doctor"
              />
            </Col>
          </Row>
        )} */}
        {/* row */}
        <MainApp appointments={appointments} today={appointmentsToday} />
      </div>
      {/***********************************
            Content body end
        ************************************/}

      {/***********************************
            Footer start
        ************************************/}
      <Footer />
      {/***********************************
            Footer end
        ************************************/}
    </div>
  )
}

export default Calendar
