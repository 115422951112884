import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import LeftBar from '../components/LeftBar'
import { useLocation } from 'react-router-dom'
import PatientSymptomsComponent from '../components/PatientSymptoms'
import PatientConsultationHistoryComponent from '../components/PatientConsultationHistory'
// import "../foreigncss/Profile.css";

const PatientConsultationHistory = () => {
  const location = useLocation()
  return (
    <div id="main-wrapper">
      {/***********************************
        Nav header start
    ************************************/}
      {/* <NavHeader/> */}
      {/***********************************
        Nav header end
    ************************************/}

      {/***********************************
        Header start
    ************************************/}
      <Header />
      {/***********************************
        Header end ti-comment-alt
    ************************************/}
      {/***********************************
        Sidebar start
    ************************************/}
      <LeftBar />
      {/***********************************
        Sidebar end
    ************************************/}
      {/***********************************
        Content body start
    ************************************/}
      <div className="content-body">
        <div className="container-fluid pt-0">
          <PatientConsultationHistoryComponent />
        </div>
      </div>
      {/***********************************
        Content body end
    ************************************/}

      {/***********************************
        Footer start
    ************************************/}
      <Footer />
      {/***********************************
        Footer end
    ************************************/}
    </div>
  )
}

export default PatientConsultationHistory
