import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import HypertensionMenu from './HypertensionMenu'

import {
  populateVitals,
  selectVitals,
} from '../../redux_setup/slices/hypertensionSlice'
import { ReduxActions } from '../../constants/redux_actions'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { selectUser } from '../../redux_setup/slices/userSlice'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { updateStep } from '../../redux_setup/slices/stepSlice'

const Vitals = ({ patientDetails, hideMenu }) => {
  const vitalsState = useSelector(selectVitals)
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const [disabled, setDisabled] = useState(true)
  toast.configure()

  const [toggle, setToggle] = useState(true)
  const [toggleHeart, setToggleHeart] = useState(true)
  const [toggleRespiration, setToggleRespiration] = useState(true)
  const [toggleTemperature, setToggleTemperature] = useState(true)
  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal

  const [currentModal, setCurrentModal] = useState('')
  const [previousReading, setPreviousReading] = useState([
    {
      no: 1,
      reading: '140/70',
      date: '15/08/2022 08:35 AM',
    },
    {
      no: 2,
      reading: '140/76',
      date: '12/08/2022 09:35 AM',
    },
    {
      no: 3,
      reading: '140/67',
      date: '11/08/2022 12:15 PM',
    },
  ])

  const directPropertyChange = (data, main_property) => {
    dispatch(
      populateVitals({
        data: data,
        main_property,
        action_type: ReduxActions.add_property,
      }),
    )
  }
  const selectBoxChangeHandler = (data) => {
    dispatch(
      populateVitals({
        data: data,
        main_property: currentModal?.category,
        sub_property: currentModal?.subcategory,
        action_type: ReduxActions.add_property_in_property,
      }),
    )
  }

  const textBoxChangeHandler = (data, category, subcategory) => {
    dispatch(
      populateVitals({
        data: data,
        main_property: category,
        sub_property: subcategory,
        action_type: ReduxActions.add_property_in_property,
      }),
    )

    //change the labels accordingly
    const level = levelChanger(data, subcategory)
    if (level) {
      //update redux
      dispatch(
        populateVitals({
          data: level,
          main_property: category,
          sub_property: subcategory + '_level',
          action_type: ReduxActions.add_property_in_property,
        }),
      )
    }
  }

  const levelChanger = (value, subcategory) => {
    switch (subcategory) {
      case 'blood_pressure':
        const mm = value.split('/')[0]
        const hg = value.split('/')[1]
        if (parseInt(mm) < 90) {
          return 'Low'
        } else if (parseInt(mm) >= 90 && parseInt(mm) <= 120) {
          return 'Normal'
        } else if (parseInt(mm) > 120) {
          return 'High'
        } else if (parseInt(hg) < 60) {
          return 'Low'
        } else if (parseInt(hg) >= 60 && parseInt(hg) <= 80) {
          return 'Normal'
        } else {
          return 'High'
        }
      case 'heart_rate':
        if (parseInt(value) < 60) {
          return 'Low'
        } else if (parseInt(value) >= 60 && parseInt(value) <= 100) {
          return 'Normal'
        } else {
          return 'High'
        }

      case 'respiration_rate':
        if (parseInt(value) < 12) {
          return 'Low'
        } else if (parseInt(value) >= 12 && parseInt(value) <= 16) {
          return 'Normal'
        } else {
          return 'High'
        }

      case 'temperature':
        if (parseInt(value) < 36.1) {
          return 'Low'
        } else if (parseInt(value) >= 36.1 && parseInt(value) <= 37.5) {
          return 'Normal'
        } else {
          return 'High'
        }
      default:
        return ''
    }
  }
  

  const handleSubmit = () => {
    //are we gonna post or patch?
    let dataToSend

    if (vitalsState?.id) {
      dataToSend = { ...vitalsState }
      delete dataToSend.created_at
      delete dataToSend.updated_at
      delete dataToSend.patient_id
    } else {
      dataToSend = { ...vitalsState, patient_id: patientDetails.patient_id }
    }

    fetch(`${process.env.REACT_APP_MAIN_URL_HYPERTENSION}/api/vitals`, {
      signal: signal,
      method: vitalsState?.id ? 'PATCH' : 'POST', //if you can find an id in vitalsState, then update
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }
        dispatch(updateStep('family_history'))
        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        })

        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error has occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
  }

  const handleCareSubmit = () => {
    const dataToSend = {
      patient_id: patientDetails?.patient_id,
      blood_pressure: vitalsState.vitals_readings.blood_pressure,
      heart_rate: vitalsState.vitals_readings.heart_rate,
      respiration_rate: vitalsState.vitals_readings.respiration_rate,
      temperature: vitalsState.stethoscopic_readings.temperature,
    }

    fetch(
      `${process.env.REACT_APP_MAIN_URL_HYPERTENSION}/api/careplans/hypertensionv1/vitals/`,
      {
        method: 'POST',
        headers: new Headers({
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(dataToSend),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }
        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        })

        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error has occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
  }

  useEffect(() => {
    // console.log(patientDetails)

    if (!hideMenu) {
      fetch(
        `${process.env.REACT_APP_MAIN_URL_HYPERTENSION}/api/vitals/${patientDetails.patient_id}`,
        {
          signal: signal,
          method: 'GET',
          headers: new Headers({
            Authorization: 'Bearer ' + user.token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }),
        },
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            return console.log('vitals fetching did not succeed')
          }
          if (res.data.length <= 0) {
            //reset redux statepiece

            dispatch(
              populateVitals({
                action_type: ReduxActions.reset_to_default_value,
              }),
            )
          } else {
            dispatch(
              populateVitals({
                data: res.data[0],
                action_type: ReduxActions.add_initial_data_from_server,
              }),
            )
          }

          // console.log(res.data);
        })
        .catch((err) => {
          console.log(err)
        })
    }

    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  const size = hideMenu ? 6 : 2.3
  return (
    <>
      <div className='margin__container'>
        {/* Vitals */}
        {!hideMenu && <h2 className='margin__left vital__subtopic'>Vitals</h2>}

        {!hideMenu && <HypertensionMenu />}
        <div
          className='vital__card'
          style={{ marginLeft: 2, marginTop: 10, width: "100%" }}
        >
          <h2 className='margin__left vital__subtopic'>Vitals</h2>
          <Row>
            <Col sm={8}>
              <Row>
                <Col sm={size} style={{ paddingLeft: 20 }}>
                  <div className='vital__card1'>
                    <h6>Blood Pressure</h6>
                    {toggle ? (
                      <h5
                        style={{ marginTop: 25 }}
                        onClick={() => setToggle(!toggle)}
                      >
                        {vitalsState.vitals_readings.blood_pressure}
                        <small>
                          <strong>&nbsp;mm/Hg</strong>
                        </small>
                      </h5>
                    ) : (
                      <input
                        style={{ width: 180, marginBottom: 3 }}
                        type='text'
                          onKeyUp={(e) => {
                          const currentValue = e.target.value.replace("/", "");
                          const newValue = currentValue
                            .replace(/\D/g, "")
                            .replace(/(\d{3})(?=\d)/g, "$1/") // Add forward slash after every 3 digits
                            .substring(0, 7); // Limit to 7 characters (3 digits + 1 slash + 3 digits)
                            textBoxChangeHandler(
                              newValue,
                              "vitals_readings",
                              "blood_pressure"
                            );
                        }}
                        value={vitalsState.vitals_readings.blood_pressure}
                        onBlur={() => setToggle(!toggle)}
                        maxLength='7'
                        onChange={(e) =>
                          textBoxChangeHandler(
                            e.target.value,
                            "vitals_readings",
                            "blood_pressure"
                          )
                        }
                      />
                    )}
                    <button
                      className='vital__btn1'
                      onClick={() => {
                        setCurrentModal({
                          category: "vitals_readings",
                          subcategory: "blood_pressure_level",
                        });
                      }}
                    >
                      {vitalsState.vitals_readings.blood_pressure_level}
                    </button>
                  </div>
                </Col>
                <Col sm={size}>
                  <div className='vital__card2'>
                    <h6>Heart Rate</h6>
                    {toggleHeart ? (
                      <h5
                        style={{ marginTop: 25 }}
                        onClick={() => setToggleHeart(!toggleHeart)}
                      >
                        {vitalsState.vitals_readings.heart_rate} Beats/min
                      </h5>
                    ) : (
                      <input
                        type='text'
                        style={{ width: 180, marginBottom: 3 }}
                        value={vitalsState.vitals_readings.heart_rate}
                        maxlength='3'
                        onBlur={() => setToggleHeart(!toggleHeart)}
                        onChange={(e) =>
                          textBoxChangeHandler(
                            e.target.value,
                            "vitals_readings",
                            "heart_rate"
                          )
                        }
                      />
                    )}
                    <button
                      className='vital__btn2'
                      onClick={() => {
                        setCurrentModal({
                          category: "vitals_readings",
                          subcategory: "heart_rate_level",
                        });
                      }}
                    >
                      {vitalsState.vitals_readings.heart_rate_level}
                    </button>
                  </div>
                </Col>
                {!hideMenu && (
                  <Col sm={size}>
                    <div className='vital__card3'>
                      <h6>Respiration rate </h6>
                      {toggleRespiration ? (
                        <h5
                          style={{ marginTop: 25 }}
                          onClick={() =>
                            setToggleRespiration(!toggleRespiration)
                          }
                        >
                          {vitalsState.vitals_readings.respiration_rate}{" "}
                          Breaths/min
                        </h5>
                      ) : (
                        <input
                          type='text'
                          style={{ width: 180, marginBottom: 3 }}
                          value={vitalsState.vitals_readings.respiration_rate}
                          onBlur={() =>
                            setToggleRespiration(!toggleRespiration)
                          }
                          maxlength='3'
                          onChange={(e) =>
                            textBoxChangeHandler(
                              e.target.value,
                              "vitals_readings",
                              "respiration_rate"
                            )
                          }
                        />
                      )}
                      <button
                        className='vital__btn3'
                        onClick={() => {
                          setCurrentModal({
                            category: "vitals_readings",
                            subcategory: "respiration_rate_level",
                          });
                        }}
                      >
                        {vitalsState.vitals_readings.respiration_rate_level}
                      </button>
                    </div>
                  </Col>
                )}
              </Row>
              {/* Readings */}

              {!hideMenu && (
                <h2
                  className='margin__left vital__subtopic'
                  style={{ margin: 15 }}
                >
                  Stethoscopic Reading
                </h2>
              )}

              <Row>
                {hideMenu ? (
                  <Col sm={size}>
                    <div className='vital__card3'>
                      <h6>Respiration Rate </h6>
                      {toggleRespiration ? (
                        <h5
                          style={{ marginTop: 25 }}
                          onClick={() =>
                            setToggleRespiration(!toggleRespiration)
                          }
                        >
                          {vitalsState.vitals_readings.respiration_rate}{" "}
                          Breaths/min
                        </h5>
                      ) : (
                        <input
                          type='text'
                          style={{ width: 180, marginBottom: 3 }}
                          value={vitalsState.vitals_readings.respiration_rate}
                          onBlur={() =>
                            setToggleRespiration(!toggleRespiration)
                          }
                          maxlength='3'
                          onChange={(e) =>
                            textBoxChangeHandler(
                              e.target.value,
                              "vitals_readings",
                              "respiration_rate"
                            )
                          }
                        />
                      )}
                      <button
                        className='vital__btn3'
                        onClick={() => {
                          setCurrentModal({
                            category: "vitals_readings",
                            subcategory: "respiration_rate_level",
                          });
                        }}
                      >
                        {vitalsState.vitals_readings.respiration_rate_level}
                      </button>
                    </div>
                  </Col>
                ) : (
                  <>
                    <Col sm={size} style={{ paddingLeft: 20 }}>
                      <div className='reading__card1'>
                        <h6>Systolic murmurs</h6>
                        <Form.Label>
                          Grade{" "}
                          {vitalsState.stethoscopic_readings.systolic_murmurs}
                        </Form.Label>
                        <Form.Range
                          min={1}
                          max={4}
                          value={
                            vitalsState.stethoscopic_readings.systolic_murmurs
                          }
                          style={{ width: 180, marginRight: 10 }}
                          onChange={(e) =>
                            textBoxChangeHandler(
                              e.target.value,
                              "stethoscopic_readings",
                              "systolic_murmurs"
                            )
                          }
                          disabled={disabled}
                        />
                        <button
                          className='reading__btn'
                          onClick={() => {
                            setCurrentModal({
                              category: "stethoscopic_readings",
                              subcategory: "systolic_murmurs_level",
                            });
                          }}
                        >
                          {
                            vitalsState.stethoscopic_readings
                              .systolic_murmurs_level
                          }
                        </button>
                      </div>
                    </Col>
                    <Col sm={size}>
                      <div className='reading__card2'>
                        <h6>Diastolic murmurs</h6>
                        <Form.Label>
                          Grade{" "}
                          {vitalsState.stethoscopic_readings.diastolic_murmurs}
                        </Form.Label>
                        <Form.Range
                          min={1}
                          max={6}
                          value={
                            vitalsState.stethoscopic_readings.diastolic_murmurs
                          }
                          style={{ width: 180, marginRight: 10 }}
                          onChange={(e) =>
                            textBoxChangeHandler(
                              e.target.value,
                              "stethoscopic_readings",
                              "diastolic_murmurs"
                            )
                          }
                        />
                        <button
                          className='reading__btn'
                          onClick={() => {
                            setCurrentModal({
                              category: "stethoscopic_readings",
                              subcategory: "diastolic_murmurs_level",
                            });
                          }}
                        >
                          {
                            vitalsState.stethoscopic_readings
                              .diastolic_murmurs_level
                          }
                        </button>
                      </div>
                    </Col>
                  </>
                )}

                <Col sm={size}>
                  <div className='reading__card3'>
                    <h6>Body temperature</h6>
                    {toggleTemperature ? (
                      <h4
                        style={{ marginTop: 20 }}
                        onClick={() => setToggleTemperature(!toggleTemperature)}
                      >
                        {vitalsState.stethoscopic_readings.temperature}
                        <span
                          style={{
                            fontSize: 17,
                            marginTop: -20,
                            marginLeft: 5,
                            fontWeight: 700,
                          }}
                        >
                          &#8451;
                        </span>
                      </h4>
                    ) : (
                      <input
                        type='text'
                        style={{ width: 180, marginBottom: 3 }}
                        value={vitalsState.stethoscopic_readings.temperature}
                        onBlur={() => setToggleTemperature(!toggleTemperature)}
                        maxlength='3'
                        onChange={(e) =>
                          textBoxChangeHandler(
                            e.target.value,
                            "stethoscopic_readings",
                            "temperature"
                          )
                        }
                      />
                    )}
                    <button
                      className='reading__btn'
                      style={{ marginTop: 9 }}
                      onClick={() => {
                        setCurrentModal({
                          category: "stethoscopic_readings",
                          subcategory: "temperature_level",
                        });
                      }}
                    >
                      {vitalsState.stethoscopic_readings.temperature_level}
                    </button>
                  </div>
                </Col>
              </Row>
            </Col>
            {/* <Col sm={4}>
              <Table>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Reading</th>
                    <th>Date and Time</th>
                  </tr>
                </thead>
                <tbody>
                  {previousReading.map((reading) => (
                    <tr key={reading.no}>
                      <td>{reading.no}</td>
                      <td>{reading.reading}</td>
                      <td>{reading.date}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col> */}
          </Row>
        </div>
      </div>
      {!hideMenu ? (
        <>
          <div className='risk__card'>
            <h5 className='vital__subtopic'>Risk level</h5>
            <Form.Group
              className='mb-3'
              controlId='exampleForm.ControlTextarea1'
              onChange={(e) => {
                directPropertyChange(e.target.value, "risk_level");
              }}
            >
              <Form.Check
                inline
                label='High'
                name='group1'
                type='radio'
                value='high'
                checked={vitalsState.risk_level == "high"}
                id='risk-level-1'
              />
              <Form.Check
                inline
                label='Medium'
                checked={vitalsState.risk_level == "medium"}
                name='group1'
                type='radio'
                value='medium'
                id='risk-level-2'
              />
              <Form.Check
                inline
                label='Low'
                name='group1'
                checked={vitalsState.risk_level == "low"}
                type='radio'
                value='low'
                id='risk-level-3'
              />
            </Form.Group>
          </div>
          <div className='risk__card'>
            <h6 className='vital__subtopic'>Notes</h6>
            <Form>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlTextarea1'
              >
                <Form.Control
                  as='textarea'
                  rows={3}
                  value={vitalsState.notes}
                  onChange={(e) =>
                    directPropertyChange(e.target.value, "notes")
                  }
                />
              </Form.Group>
            </Form>
            <div className='submit__notes'>
              <button className='submit__btn' onClick={handleSubmit}>
                Save
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className='risk__card'>
          <div className='submit__notes'>
            <button className='submit__btn' onClick={handleCareSubmit}>
              Save
            </button>
          </div>
        </div>
      )}

      <Modal
        show={currentModal !== ""}
        onHide={() => setCurrentModal("")}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <select
            placeholder='Select Diagnosis'
            className='form-control form-control-sm'
            onChange={(e) => {
              selectBoxChangeHandler(e.target.value);
            }}
          >
            <option disabled selected>
              --select--
            </option>
            <option>Normal</option>
            <option>High</option>
            <option>Barely audible</option>
            <option>Audible but soft</option>
          </select>
          <div className='submit__notes' style={{ margin: 10 }}>
            <button className='submit__btn' onClick={() => setCurrentModal("")}>
              Select
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Vitals
