import { useState } from "react";
import Switch from "react-switch";
import { Form } from "react-bootstrap";
const switchColorCompute = (level) => {
  let color = "white";
  let handle = "white";
  switch (level) {
    case "High":
      color = "#ff80ad";
      handle = "#ff005c";
      break;

    case "Medium":
      color = "#fff9e5";
      handle = "#ffc000";
      break;

    case "Low":
      color = "#e6f7fb";
      handle = "#07b0d7";
      break;

    default:
      color = "white";
      handle = "#ff005c";
      break;
  }
  return { color, handle };
};

const Appetite = () => {
  const [switched, setSwitched] = useState({
    id: null,
    checked: false
  })
  return (
    <div
      className='card pl-2 pr-2 mt-3 pt-2'
      style={{ height: 180, overflow: "auto" }}
    >
      <label>What is your usual appetite?</label>
      <div className='d-flex flex-wrap justify-content-between'>
        {["Morning", "Mid Morning", "Afternoon", "Evening", "Night"].map(
          (time, index) => (
            <div key={index}>
              <label
                style={{
                  fontFamily: "Lato",
                  fontWeight: 400,
                  fontSize: 18,
                  color: "#616161",
                }}
              >
                {time}
              </label>
              <Form.Select className='pl-2'>
                <option>Low</option>
                <option>Medium</option>
                <option>High</option>
              </Form.Select>
            </div>
          )
        )}
      </div>
      <div className='d-flex flex-wrap mt-3' style={{ gap: 20 }}>
        {["Low", "Medium", "High"].map((level, index) => (
          <div className='d-flex flex-wrap'>
            <label style={{ marginTop: 5, fontWeight: 700 }}>{level}</label>
            <div className={`switch${index + 1}`} key={index}>
              <Switch
                key={index}
                onChange = {e => setSwitched({id: index, checked: e})}
                value={switched.checked}
                checked={switched.id  === index ? switched.checked : false}
                uncheckedIcon={false}
                checkedIcon={false}
                onHandleColor={switchColorCompute(level).handle}
                offHandleColor={switchColorCompute(level).handle}
                onColor={switchColorCompute(level).color}
                offColor={switchColorCompute(level).color}
                width={80}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Appetite;
