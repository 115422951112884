import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { titleSentenceCase,searchHandler } from '../../constants/Essentials'
import { selectUser } from '../../redux_setup/slices/userSlice'
import Footer from '../Footer'
import Header from '../Header'
import LeftBar from '../LeftBar'

const abortcontroller = new AbortController()
const signal = abortcontroller.signal
const HypertensivePatient = () => {
  const user = useSelector(selectUser)
  const [active, setActive] = useState({ id: null, isActive: false })
  const [show, setShow] = useState(false)
  const [patients, setPatients] = useState([])
  const [allPatients, setAllPatients] = useState([])
  const [patientName, setPatientName] = useState('')
  const [noDataFound, setNoDataFound] = useState(true)
  let navigate = useNavigate()

  const patientsUrl = `${process.env.REACT_APP_MAIN_URL}/api/patient/`
  useEffect(() => {
    fetch(patientsUrl, {
      signal: signal,
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        const iterable = res.data.filter((res) => res.avatar == 1)
        console.log(iterable)

        setPatients(iterable)
        setAllPatients(iterable)
        setNoDataFound(false)
      })
      .catch((err) => {
        console.log(err)
      })
    // return function cleanup() {
    //   abortcontroller.abort()
    // }
  }, [])

  //useEffcet to reset state when search input is cleared and search as uiser types
  useEffect(() => {
    if (patientName.length === 0) {
      return setPatients(allPatients)
    }
    //wait for some seconds then run search handler
    if (patientName.length > 0) {
      setTimeout(() => {
        searchHandler(allPatients,setPatients, patientName)
      }, 200) //some sort of debounce--whatever
    }
  }, [patientName])
  
  return (
    <div className="main-wrapper">
      {/***********************************
        Header start
        ************************************/}
      <Header />
      {/***********************************
        Header end ti-comment-alt
        ************************************/}
      {/***********************************
        Sidebar start
        ************************************/}
      <LeftBar />
      {/***********************************
        Sidebar end
        ************************************/}
      {/***********************************
        Content body start
        ************************************/}

      {/***********************************
        Content body end
        ************************************/}
      <div className="content-body">
        {/* Header title */}
        <div className="row">
          <div className="col-md-3 col-sm-12">
            <p className="header__title">All patients</p>
          </div>
          <div className="col-md-2 col-sm-12">
            <p className="header__title">Completed (1)</p>
          </div>
          <div className="col-md-2 col-sm-12">
            <p className="header__title">Viewed (1)</p>
          </div>
          <div className="col-md-2 col-sm-12">
            <p className="header__title">Closed</p>
          </div>
          <div className="col-md-3 col-sm-12">
            <div
              className="flex__container"
              style={{ gap: 40, marginLeft: 70 }}
            >
              <label className="header__title">
                <i className="fa fa-arrow-up-short-wide" /> &nbsp; Sort
              </label>
              <label className="header__title">
                <i className="fa fa-filter" aria-hidden="true" /> &nbsp;Filter
              </label>
            </div>
          </div>
        </div>
        {/* Header title end */}
        {/* Search section */}
        <section id="search">
          <div
            className="flex__container"
            style={{ justifyContent: 'flex-end', gap: 20 }}
          >
            <input
              type="text"
              placeholder="Search Patient"
              className="search__input"
              onChange={(e) => setPatientName(e.target.value)}
            />
            <button className="search__btn" onClick={() => searchHandler(allPatients,setPatients, patientName)}>
              Search
            </button>
          </div>
          {noDataFound ? (
            <h5>Loading patients...</h5>
          ) : (
            <>
              {patients.map((patient, index) => (
                <div
                  className="row search__item__container"
                  style={{ marginLeft: 0 }}
                  key={patient.id}
                  onClick={() =>
                    navigate('/careplan-graph', { state: patient })
                  }
                >
                  <div className="col-md-3 col-sm-12">
                    <div className="flex__container">
                      <img
                        src={process.env.PUBLIC_URL + '/images/pdf-icon.png'}
                        width={32}
                        height={41}
                      />
                      <div className="flex__block">
                        <label>{titleSentenceCase(patient?.name)}.pdf</label>
                        <button className="pdf__download__btn">Download</button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12">
                    <div className="flex__block">
                      <label className="item__title">{patient?.phone}</label>
                      <label className="item__title">{patient?.email}</label>
                      <label className="item__title">
                        <strong>Sent:</strong>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12" style={{backgroundColor: '#FFF'}}>
                    <div className="flex__container" style={{ gap: 20 }}>
                      <img
                        width={44}
                        height={44}
                        src={`${process.env.PUBLIC_URL}/images/avatar/noProfile.jpg`}
                      />
                      <label className="title__gray" style={{ marginTop: 15 }}>
                        {titleSentenceCase(patient?.name)}
                      </label>
                      {/* <div className="status__btn">
                    <div className="disc" />
                    <label className="tittle__gray">Not viewed</label>
                  </div> */}
                      
                      {/* {show && active.isActive && active.id === index ? (
                        <div>
                          <button
                            className="option__details"
                            onClick={() =>
                              navigate('/careplan-graph', { state: patient })
                            }
                            style={{backgroundColor: 'red',color:'#000'}}
                          >
                            Details
                          </button>
                        </div>
                      ) : (
                        <></>
                      )} */}
                    </div>
                    <div className='flex__container' style={{justifyContent: 'flex-end',marginTop: -50}}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/msg-icon.jpg`}
                          width={22}
                          height={24}
                          alt="Message icon"
                          style={{ marginTop: 15, marginLeft: 20 }}
                        />
                        <div>
                          <i
                            className="fa-solid fa-ellipsis three__dots"
                            onClick={() => {
                              setActive({
                                id: index,
                                isActive: true,
                              })
                              setShow(true)
                            }}
                          />
                        </div>
                      </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </section>
      </div>

      {/***********************************
        Footer start
        ************************************/}
      <Footer />
      {/***********************************
        Footer end
        ************************************/}
    </div>
  )
}
export default HypertensivePatient
