import React, {useEffect,useState} from 'react'
const Bubble = ({
  selected = "",
  color = "#D9D9D999",
  width = "",
  height = "",
  right = "",
  isGrey = false,
  subtitle = "",
}) => {
    const [isMobile,setIsMobile] = useState(false)
    useEffect(()=>{
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }
    },[window.screen.width])
    
  return (
    <div className="bubble__container">
      <div className="bubble__big">{selected[0]}</div>
      <div
        className="bubble__bacon"
        style={{ backgroundColor: isGrey ? color : "" }}
      >
        {selected[1]}
        {/* {isGrey ? selected[1]?.} */}
      </div>
      <div
        className="bubble__yam"
        style={{ backgroundColor: isGrey ? color : "" }}
      >
        {selected[2]}
        <small>{subtitle}</small>
      </div>
      <div
        className="bubble__coffe"
        style={{ backgroundColor: isGrey ? color : "" }}
      >
        {selected[3]}
        <small>{subtitle}</small>
      </div>
      <div className="bubble__pancake">
        {selected[4]}
        <small>{subtitle}</small>
      </div>
      <div
        className="bubble__grey__big"
        style={{ backgroundColor: isGrey ? color : "" }}
      >
        {selected[5]}
        <small>{subtitle}</small>
      </div>
      <div
        className="bubble__grey__medium"
        style={{ backgroundColor: isGrey ? color : "" }}
      ></div>
      <div
        className="bubble__grey__small1"
        style={{ backgroundColor: isGrey ? color : "" }}
      ></div>
      <div
        className="bubble__grey__small2"
        style={{ backgroundColor: isGrey ? color : "" }}
      ></div>
      <div
        className="bubble__grey__small3"
        style={{ backgroundColor: isGrey ? color : "" }}
      ></div>
      <div
        className="bubble__grey__xsmall1"
        style={{ backgroundColor: isGrey ? color : "" }}
      ></div>
      <div
        className="bubble__grey__xsmall2"
        style={{
          backgroundColor: isGrey ? color : "",
          width:isMobile  && isGrey ? '60px':width,
          borderRadius: isMobile && isGrey ? '50%':'50%',
          top: isMobile && isGrey ? '20%': '',
          height: isMobile && isGrey ? '60px': height,
          right:isMobile && isGrey ? '-5%':right,
        }}
      ></div>
    </div>
  );
};
export default Bubble;
