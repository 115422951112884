import { Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Vitals = ({ data, recent }) => {
  return (
    <>
      <div className="mt-3">
        <div className="row">
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <div className="d-flex" style={{ gap: '3%', width: '100%' }}>
              <div
                className="card pl-2 pt-2 pr-2"
                style={{ backgroundColor: '#6623823D', width: '48%' }}
              >
                <div
                  className="card pl-2 pt-2"
                  style={{ background: 'rgba(102, 35, 130, 0.24)' }}
                >
                  <div className="d-flex">
                    <img
                      className="mt-2 mr-2"
                      src={`${process.env.PUBLIC_URL}/images/icons/unlock.png`}
                      style={{ width: 22, height: 16 }}
                    />
                    <p className="mb-text mb-3">Blood Pressure</p>
                  </div>
                  <span
                    className="show-on-mobile ml-4 mb-2"
                    style={{ fontSize: 12 }}
                  >
                    &nbsp;(mm/Hg)
                  </span>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ marginTop: -20 }}
                >
                  <div>
                    <small
                      style={{
                        fontSize: 10,
                        fontWeight: 400,
                        fontFamily: 'Lato',
                        color: '#0D5CA4',
                      }}
                      className="ml-3 mt-2"
                    >
                      Current
                    </small>
                    <br />
                    <small className="ml-3 mt-2">
                      {recent?.bp}
                      <small
                        className="dont-show-on-mobile"
                        style={{ fontSize: 12 }}
                      >
                        mm/Hg
                      </small>
                    </small>
                  </div>
                  <div style={{ whiteSpace: 'nowrap' }}>
                    <small
                      style={{
                        fontSize: 10,
                        fontWeight: 400,
                        fontFamily: 'Lato',
                        color: '#0D5CA4',
                      }}
                      className="ml-3 mt-2"
                    >
                      Target
                    </small>
                    <br />
                    <small className="ml-3 mt-2">
                      {data?.vitals_target_bp || 0}
                      <small
                        className="dont-show-on-mobile"
                        style={{ fontSize: 12 }}
                      >
                        mm/Hg
                      </small>
                    </small>
                  </div>
                </div>
              </div>
              <div
                className="card pl-2 pt-2 pr-2"
                style={{ width: '48%', background: 'rgba(7, 176, 215, 0.24)' }}
              >
                <div
                  className="card pl-2 pt-2"
                  style={{ background: 'rgba(7, 176, 215, 0.24)' }}
                >
                  <div className="d-flex">
                    <img
                      className="mt-2 mr-1"
                      src={`${process.env.PUBLIC_URL}/images/icons/heart-icon.png`}
                      style={{ width: 22, height: 16 }}
                    />
                    <p className="mb-text mb-3">Heart Rate</p>
                  </div>
                  <br />
                  <span
                    className="show-on-mobile ml-4 mb-2"
                    style={{ fontSize: 12 }}
                  >
                    &nbsp;(Beats/min)
                  </span>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ marginTop: -20 }}
                >
                  <div>
                    <small
                      style={{
                        fontSize: 10,
                        fontWeight: 400,
                        fontFamily: 'Lato',
                        color: '#0D5CA4',
                      }}
                      className="ml-3 mt-2"
                    >
                      Current
                    </small>
                    <br />
                    <small className="ml-3 mt-2">
                      {recent?.heart_rate}
                      <small
                        className="dont-show-on-mobile"
                        style={{ fontSize: 12 }}
                      >
                        Beats/min
                      </small>
                    </small>
                  </div>
                  <div>
                    <small
                      style={{
                        fontSize: 10,
                        fontWeight: 400,
                        fontFamily: 'Lato',
                        color: '#0D5CA4',
                      }}
                      className="ml-3 mt-2"
                    >
                      Target
                    </small>
                    <br />
                    <small className="ml-3 mt-2">
                      {data?.vitals_target_heartrate || 0}
                      <small
                        className="dont-show-on-mobile"
                        style={{ fontSize: 12 }}
                      >
                        Beats/min
                      </small>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Link to="/vitals-care-plan" state={{ recent: recent }}>
          <Button
            className="mb-3"
            style={{ backgroundColor: '#11195A', width: '93%' }}
          >
            Update Vitals Care Plan
          </Button>
        </Link>
      </div>
    </>
  )
}

export default Vitals
