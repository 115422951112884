import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import Select from 'react-select'

import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { trimDate } from '../constants/Essentials'
import { formatDate } from '@fullcalendar/react'

const PatientConsultationHistoryComponent = ({ dataToProcess }) => {
  toast.configure()
  let navigate = useNavigate()
  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  const user = useSelector(selectUser)
  const [currentTab, setcurrentTab] = useState('physical_assessment')

  // inputs states
  const [height, setHeight] = useState('')
  const [weight, setWeight] = useState('')
  let bmi = weight / Math.pow(height / 100, 2)

  const [dateOptions, setDateOptions] = useState([])
  const [mainRes, setMainRes] = useState([])
  const [currentRes, setCurrentRes] = useState({})

  //doctor assessment states
  const [assessmentCaseInformation, setAssessmentCaseInformation] = useState('')
  const [assessmentTests, setAssessmentTests] = useState('')
  const [assessmentDiagnosis, setAssessmentDiagnosis] = useState('')
  const [assessmentPrescription, setAssessmentPrescription] = useState('')
  const [additionalNotes, setAdditionalNotes] = useState('')
  // tests states
  // Complete Blood Count state
  const [CBC_WBC, setCBC_WBC] = useState(false)
  // Renal function state
  const [RF_Urine, setRF_Urine] = useState(false)
  // Stool Analysis state
  const [stoolAnalysis, setStoolAnalysis] = useState(false)
  // blood sugar states
  const [bloodSugar, setBloodSugar] = useState(false)
  // COVID-19 test states
  const [covidTest, setCovidTest] = useState(false)
  // Serology states
  const [serology, setSerology] = useState(false)
  // HIV test states
  const [hivTest, setHivTest] = useState(false)
  // Malaria test states
  const [malariaTest, setMalariaTest] = useState(false)
  // Glucose metabolism states
  const [glucoseMetabolism, setGlucoseMetabolism] = useState(false)
  // Liver function test states
  const [liverFunctionTest, setLiverFunctionTest] = useState(false)
  // Hormonal profile state_save
  const [hormonalProfile, setHormonalProfile] = useState(false)

  const patient_id =
    user.role !== 'Patient' ? dataToProcess?.patient_id : user.user_id
  const appt_id = dataToProcess?.appt_id

  const consultationUrl = `${process.env.REACT_APP_MAIN_URL}/api/general_consultation?patient_id=${patient_id}`

  useEffect(() => {
    fetch(consultationUrl, {
      signal: signal,
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning('An error occured while fetching history', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }
        // return console.log(res, 'umojka', user.user_id)
        const response = res.data.items
        const dates = []
        response.map((res) => {
          dates.push({
            value: res.createdAt,
            label: `${trimDate(res.createdAt)}`,
          })
        })
        // console.log(response);
        setDateOptions(dates)
        setMainRes(response)
        setCurrentRes(response[0])
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  const handleChange = (date) => {
    const res = mainRes.filter((item) => item.createdAt === date)
    setCurrentRes(res[0])
  }

  useEffect(() => {
    const response = currentRes

    //setting state_save
    setAssessmentCaseInformation(response?.case_info)
    //parse assessment tests
    // let tests_to_be_done = JSON.parse(response?.tests_to_be_done) || {}

    let tests_to_be_done = ''
    try {
      tests_to_be_done = JSON.parse(response?.tests_to_be_done)
    } catch {
      tests_to_be_done = {}
    }
    setCBC_WBC(tests_to_be_done?.cbc_wbc || false)
    setRF_Urine(tests_to_be_done?.rf_urine || false)
    setStoolAnalysis(tests_to_be_done?.stool_analysis || false)
    setBloodSugar(tests_to_be_done?.blood_Sugar || false)
    setCovidTest(tests_to_be_done?.covid_Test || false)
    setSerology(tests_to_be_done?.serology || false)
    setHivTest(tests_to_be_done?.hiv_test || false)
    setMalariaTest(tests_to_be_done?.malaria_test || false)
    setGlucoseMetabolism(tests_to_be_done?.glucose_metabolism || false)
    setLiverFunctionTest(tests_to_be_done?.liver_function_Test || false)
    setHormonalProfile(tests_to_be_done?.hormonal_profile || false)

    setAssessmentDiagnosis(response?.diagnosis)
    setAssessmentPrescription(response?.prescription)
    setAdditionalNotes(response?.extra_notes)
  }, [currentRes])

  return (
    <>
      {mainRes.length ? (
        <div className="row">
          <div className="col-lg-12 col-xxl-12">
            <div className="card ">
              <div className="card-header">
                <h4 className="card-title">Consultation History</h4>
              </div>
              <div className="card-body text-left">
                <div className="form-group row">
                  <label className="col-md-3">Date: </label>
                  <div className="col-md-6">
                    <select
                      onChange={(e) => handleChange(e.target.value)}
                      className="form-control"
                    >
                      {dateOptions.map((d) => (
                        <option key={d.value} value={d.value}>
                          {d.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8">
                    {/* <p>
                        <strong>Case Information:</strong>{' '}
                        {assessmentCaseInformation}
                      </p> */}
                    <div className="form-group">
                      <label className="text-label">Tests</label>
                      <div className="row border-top">
                        <div className="col-md-6">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={CBC_WBC}
                              disabled
                              id="blood_count"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="blood_count"
                            >
                              Blood Count Test
                            </label>
                          </div>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={RF_Urine}
                              disabled
                              id="renal_function"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="renal_function"
                            >
                              Renal Function Test
                            </label>
                          </div>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={stoolAnalysis}
                              disabled
                              id="stool_analysis"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="stool_analysis"
                            >
                              Stool Analysis
                            </label>
                          </div>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={bloodSugar}
                              disabled
                              id="blood_sugar"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="blood_sugar"
                            >
                              Blood Sugar
                            </label>
                          </div>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={covidTest}
                              disabled
                              id="covid_test"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="covid_test"
                            >
                              Covid
                            </label>
                          </div>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={serology}
                              disabled
                              id="serology"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="serology"
                            >
                              Serology Test
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={hivTest}
                              disabled
                              id="hiv_test"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="hiv_test"
                            >
                              HIV Test
                            </label>
                          </div>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={malariaTest}
                              disabled
                              id="malaria_test"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="malaria_test"
                            >
                              Malaria Test
                            </label>
                          </div>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={glucoseMetabolism}
                              disabled
                              id="glucose"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="glucose"
                            >
                              Glucose Metabolism Test
                            </label>
                          </div>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={liverFunctionTest}
                              disabled
                              id="liver_function_test"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="liver_function_test"
                            >
                              Malaria Test
                            </label>
                          </div>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={hormonalProfile}
                              disabled
                              id="hormonal_profile"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="hormonal_profile"
                            >
                              Hormonal Profile
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p>
                      <strong>Diagnosis:</strong>
                      {assessmentDiagnosis}
                    </p>
                    <p>
                      <strong>Prescription:</strong> {assessmentPrescription}
                    </p>
                    <p>
                      <strong>Additional Notes:</strong> {additionalNotes}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="alert alert-info">
          <p>No consultation history yet</p>
        </div>
      )}
    </>
  )
}

export default PatientConsultationHistoryComponent
