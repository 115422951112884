import { useState } from "react";
import Header from "../../components/Header";
import LeftBar from "../../components/LeftBar";
import Footer from "../../components/Footer";
import { Button, Form,Modal } from "react-bootstrap";

const ExternalRxDetails = () => {
  const [show, setShow] = useState(false)
  const [plusChecked, setPlusChecked] = useState(false);
  const [highChecked, setHighChecked] = useState(false);
  const [lowChecked, setLowChecked] = useState(false);
  const handleClose = () => setShow(false)
  return (
    <div id='main-wrapper'>
      {/***********************************
Header start
************************************/}
      <Header />
      {/***********************************
Header end ti-comment-alt
************************************/}
      {/***********************************
Sidebar start
************************************/}
      <LeftBar />
      {/***********************************
Sidebar end
************************************/}
      {/***********************************
Content body start
************************************/}

      {/***********************************
      Sidebar end
  ************************************/}
      {/***********************************
      Content body start
  ************************************/}

      <div className='content-body'>
        <p
          className='mt-2 mb-3'
          style={{ fontSize: 20, fontFamily: "Lato", fontWeight: 700 }}
        >
          External Rx Details
        </p>
        <div className='card pt-2 pl-3 pb-3 pr-3'>
          <p
            className='mt-2 mb-4'
            style={{ fontSize: 16, fontFamily: "Lato", fontWeight: 700 }}
          >
            Patient Details
          </p>
          <div className='row'>
            <div className='col-md-4 col-lg-4 col-sm-12'>
              <div className='d-flex mb-2' style={{ gap: 50 }}>
                <label>
                  Name<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Control
                  type='text'
                  style={{ borderRadius: 0, width: 240 }}
                />
              </div>
            </div>
            <div className='col-md-4 col-lg-4 col-sm-12'>
              <div className='d-flex mb-2' style={{ gap: 40 }}>
                <label>
                  Sex<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Select
                  type='text'
                  style={{
                    borderRadius: 0,
                    border: "1px #96bfcf solid",
                    width: 240,
                    height: 35,
                    backgroundColor: "#FFF",
                  }}
                  className='pl-2 ml-4'
                >
                  <option>Select sex</option>
                  <option>Male</option>
                  <option>Female</option>
                </Form.Select>
              </div>
            </div>
            <div className='col-md-4 col-lg-4 col-sm-12'>
              <div className='d-flex mb-2' style={{ gap: 0 }}>
                <label>
                  Diagnosis<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Control
                  className='ml-4'
                  type='text'
                  style={{ borderRadius: 0, width: 240 }}
                />
              </div>
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-md-4 col-lg-4 col-sm-12'>
              <div className='d-flex mb-2' style={{ gap: 45 }}>
                <label>
                  Age<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Control
                  className='ml-3'
                  type='text'
                  style={{ borderRadius: 0, width: 240 }}
                />
              </div>
            </div>
            <div className='col-md-4 col-lg-4 col-sm-12'>
              <div className='d-flex mb-2' style={{ gap: 40 }}>
                <label>
                  Weight<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Control
                  type='text'
                  style={{ borderRadius: 0, width: 240 }}
                  className='pl-2'
                />
              </div>
            </div>
            <div className='col-md-4 col-lg-4 col-sm-12'>
              <div className='d-flex mt-2' style={{ gap: 23 }}>
                <label>
                  Underlying
                  <br /> Condition<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Control
                  type='text'
                  style={{ borderRadius: 0, width: 240 }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='card pt-4 pl-2 pr-2 pb-3'>
          <p
            className='mt-2 mb-5'
            style={{ fontSize: 20, fontFamily: "Lato", fontWeight: 700 }}
          >
            Rx #123445
          </p>
          <div className='table-responsive card-table'>
            <table
              id='example5'
              className='display dataTablesCard table-responsive-xl'
            >
              <thead>
                <tr>
                  <th style={{ backgroundColor: "#E8E8E8" }}>DRUG CLASS</th>
                  <th style={{ backgroundColor: "#E8E8E8" }}>DRUG NAME</th>
                  <th style={{ backgroundColor: "#E8E8E8" }}>DOSAGE</th>
                  <th style={{ backgroundColor: "#E8E8E8" }}>
                    ROUTE &nbsp; &nbsp;
                  </th>
                  <th style={{ backgroundColor: "#E8E8E8" }}>FREQUENCY</th>
                  <th style={{ backgroundColor: "#E8E8E8" }}>
                    AMT TO BE DISPENSED
                    <br /> BY PHARMACY
                  </th>
                  <th style={{ backgroundColor: "#E8E8E8" }}>
                    &nbsp; &nbsp;NO. OF REFILLS
                  </th>
                  <th style={{ backgroundColor: "#E8E8E8" }}>Action</th>
                </tr>
              </thead>

              <tbody>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td className='d-flex mt-2' style={{ gap: 15 }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icons/edit-icon.png`}
                    width={13}
                    height={13}
                    style={{ cursor: "pointer" }}
                  />
                  <i
                    className='fa fa-minus'
                    style={{ color: "#D60A51", cursor: "pointer" }}
                  />
                </td>
              </tbody>
            </table>
          </div>
          <div
            className='d-flex justify-content-end mr-5'
            style={{ gap: 10, cursor: "pointer" }}
          >
            <p
              className='mt-2 mb-5'
              style={{
                fontSize: 24,
                fontFamily: "Lato",
                fontWeight: 800,
                color: "#2FA5DB",
              }}
              onClick={() => setShow(true)}
            >
              Add Medication
            </p>
            <i
              className='fa fa-plus mt-4'
              style={{ color: "#2FA5DB", fontWeight: 800 }}
              onClick={() => setShow(true)}
            />
          </div>
        </div>
        <div className='card pt-2 pl-3 pb-3 pr-3'>
          <p
            className='mt-2 mb-4'
            style={{
              fontSize: 16,
              fontFamily: "Lato",
              fontWeight: 700,
              color: "#000",
            }}
          >
            Clinic Details
          </p>
          <div className='row mt-3'>
            <div className='col-md-4 col-lg-4 col-sm-12'>
              <div className='d-flex mb-2' style={{ gap: 30 }}>
                <label>
                  Name<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Control
                  className='ml-5'
                  type='text'
                  style={{ borderRadius: 0, width: 251 }}
                />
              </div>
            </div>
            <div className='col-md-4 col-lg-4 col-sm-12'>
              <div className='d-flex mb-2' style={{ gap: 30 }}>
                <label>
                  phone Number<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Control
                  type='text'
                  style={{ borderRadius: 0, width: 251 }}
                  className='pl-2'
                />
              </div>
            </div>
            <div className='col-md-4 col-lg-4 col-sm-12'>
              <div className='d-flex' style={{ gap: 30 }}>
                <label>
                  Email Address<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Control
                  type='text'
                  style={{ borderRadius: 0, width: 251 }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='card pt-2 pl-3 pb-3 pr-3'>
          <p
            className='mt-2 mb-4'
            style={{
              fontSize: 16,
              fontFamily: "Lato",
              fontWeight: 700,
              color: "#000",
            }}
          >
            Doctor's Details
          </p>
          <div className='row mt-3'>
            <div className='col-md-4 col-lg-4 col-sm-12'>
              <div className='d-flex mb-2' style={{ gap: 30 }}>
                <label>
                  Name<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Control
                  className='ml-5'
                  type='text'
                  style={{ borderRadius: 0, width: 251 }}
                />
              </div>
            </div>
            <div className='col-md-4 col-lg-4 col-sm-12'>
              <div className='d-flex mb-2' style={{ gap: 30 }}>
                <label>
                  phone Number<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Control
                  type='text'
                  style={{ borderRadius: 0, width: 251 }}
                  className='pl-2'
                />
              </div>
            </div>
            <div className='col-md-4 col-lg-4 col-sm-12'>
              <div className='d-flex' style={{ gap: 30 }}>
                <label>
                  Email Address<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Control
                  type='text'
                  style={{ borderRadius: 0, width: 251 }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex mt-4'>
          <Form.Check
            id='confirm'
            label=' I confirm that the Clinic & Doctor details provided above are valid'
          />
          <span style={{ color: "red" }}>*</span>
        </div>
        <div className='d-flex justify-content-end mr-5 mt-4'>
          <Button style={{ backgroundColor: "#19226D" }}>
            Create External Rx
          </Button>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        size='xl'
      >
        <Modal.Body className='pt-5 pl-5 pr-5'>
          <div>
            <div className='d-flex flex-wrap' style={{ gap: 40 }}>
              <div>
                <label
                  style={{
                    fontSize: 16,
                    fontFamily: "Lato",
                    fontWeight: 400,
                    color: "#52575C",
                  }}
                >
                  Drug Class
                </label>
                <Form.Select className='pl-2'>
                  <option>Class one</option>
                  <option>Class two</option>
                </Form.Select>
              </div>
              <div>
                <label
                  style={{
                    fontSize: 16,
                    fontFamily: "Lato",
                    fontWeight: 400,
                    color: "#52575C",
                  }}
                >
                  Drug Class
                </label>
                <Form.Select className='pl-2'>
                  <option>Drug one</option>
                  <option>Drug two</option>
                </Form.Select>
              </div>
            </div>
            <label
              style={{
                fontSize: 16,
                fontFamily: "Lato",
                fontWeight: 400,
                color: "#52575C",
              }}
              className='mt-3'
            >
              Dosage Form
            </label>
            <div className='d-flex flex-wrap' style={{ gap: 10 }}>
              <Form.Check id='capsules' label='Capsules' />
              <Form.Check id='tablets' label='Tablets' />
              <Form.Check id='suspension' label='Suspension' />
              <Form.Check id='suppository' label='Suppository' />
            </div>
            <label
              style={{
                fontSize: 16,
                fontFamily: "Lato",
                fontWeight: 400,
                color: "#52575C",
              }}
              className='mt-3'
            >
              Drug strength (Number/weight or volume or other)
            </label>
            <div
              className='d-flex flex-wrap'
              style={{ gap: 15, width: "100%" }}
            >
              <div className='d-flex' style={{ gap: 15 }}>
                <label>Strength</label>
                <Form.Select className='pl-2' style={{ width: 149 }}>
                  <option>50mg</option>
                  <option>100mg</option>
                  <option>200mg</option>
                </Form.Select>
              </div>
              <div className='d-flex' style={{ gap: 15 }}>
                <label>Weight</label>
                <Form.Select className='pl-2' style={{ width: 149 }}>
                  <option>ml</option>
                  <option>cl</option>
                </Form.Select>
              </div>
              <div className='d-flex' style={{ gap: 15 }}>
                <label>Volume</label>
                <Form.Select className='pl-2' style={{ width: 149 }}>
                  <option>drops</option>
                </Form.Select>
              </div>
            </div>
            <div
              className='d-flex flex-wrap mt-3 mobile-width'
              style={{ gap: 10 }}
            >
              <div>
                <label>Amount to be dispensed by pharmacy</label>
                <Form.Control type='number' placeholder='Input amount' />
              </div>
              <div>
                <label>Enter number of refills</label>
                <Form.Control
                  type='number'
                  placeholder='Input number of refills'
                  className='mobile-width'
                />
              </div>
            </div>
            <label
              style={{
                fontSize: 16,
                fontFamily: "Lato",
                fontWeight: 400,
                color: "#52575C",
              }}
              className='mt-3'
            >
              Direction for use
            </label>
            <textarea
              className='form-control mt-3'
              placeholder='Input text'
              id='exampleFormControlTextarea1'
              rows={4}
            />
          </div>
          <div className='row mt-3'>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "Lato",
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Reason For Error
              </p>
              <div className='d-flex flex-wrap' style={{ gap: 50 }}>
                <Form.Check label='Drug Detail' id='details' />
                <div>
                  <Form.Check
                    label='Plus'
                    id='plus'
                    onChange={(e) => {
                      if (e.target.checked) {
                        setPlusChecked(true);
                      } else {
                        setPlusChecked(false);
                      }
                    }}
                  />
                  {plusChecked ? (
                    <div className='ml-4'>
                      <Form.Check
                        label='High'
                        id='high'
                        onChange={(e) => {
                          if (e.target.checked) {
                            setHighChecked(true);
                          } else {
                            setHighChecked(false);
                          }
                        }}
                      />
                      {/* {highChecked ? (
                        <div className='ml-4'>
                          <Form.Check id='diagnosis' label='For Diagnosis' />
                          <Form.Check
                            id='underlying-condition'
                            label='For Underlying Condition'
                          />
                        </div>
                      ) : (
                        <></>
                      )} */}
                      <Form.Check
                        label='Low'
                        id='low'
                        onChange={(e) => {
                          if (e.target.checked) {
                            setLowChecked(true);
                          } else {
                            setLowChecked(false);
                          }
                        }}
                      />
                      {/* {lowChecked ? (
                        <div className='ml-4'>
                          <Form.Check
                            id='diagnosis-low'
                            label='For Diagnosis'
                          />
                          <Form.Check
                            id='underlying-condition-low'
                            label='For Underlying Condition'
                          />
                        </div>
                      ) : (
                        <></>
                      )}*/}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <label
                style={{
                  fontSize: 16,
                  fontFamily: "Lato",
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Other Details
              </label>
              <textarea
                className='form-control mt-3'
                placeholder='Input text'
                id='other-details'
                rows={4}
              />
            </div>
          </div>
          <div className='d-flex justify-content-end mt-3'>
            <Button
              style={{
                backgroundColor: "#19226D",
                border: "1px #19226D solid",
              }}
              onClick={() => setShow(false)}
            >
              Update Rx
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </div>
  );
};

export default ExternalRxDetails;
