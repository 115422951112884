import React, { useEffect, useState } from 'react'
import { Col, Row, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchFamilyHistory,
  fetchLabs,
  fetchLifeStyleReview,
  fetchUnderlyingConditions,
  fetchVitals,
} from '../../constants/FetchFunctions'
import { selectUser } from '../../redux_setup/slices/userSlice'
import {
  SummarypageBonesTop,
  SummarypageBonesComorbidities,
  SummarypageBonesLabs,
} from '../bonesloader/SummarypageBones'
import HypertensionMenu from './HypertensionMenu'
import {
  populateSummary,
  selectSummary,
} from '../../redux_setup/slices/hypertensionSlice'
import { ReduxActions } from '../../constants/redux_actions'
import {
  humanize,
  backgroundColor,
  calculateAge,
  trimString,
} from '../../constants/Essentials'
import { formatDate } from '@fullcalendar/react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Link } from 'react-router-dom'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const NoData = () => (
  <div className="alert alert-warning">No data was found </div>
)
const Summary = ({ patientDetails, hideMenu }) => {
  const user = useSelector(selectUser)
  const summaryState = useSelector(selectSummary)
  const dispatch = useDispatch()
  toast.configure()
  const [notFetched, setNotFetched] = useState({
    vitals: 0,
    underlying_conditions: 0,
    family_history: 0,
    lifestyle_review: 0,
    lab_tests: 0,
  })
  const [dataCollected, setDataCollected] = useState('yes')
  const [emailorphone, setEmailorphone] = useState('')
  const [selectedCarePlans, setSelectedCarePlans] = useState([])

  const populateCareplans = (plan, checked) => {
    const careplans = [...selectedCarePlans]
    const index = careplans.indexOf(plan)
    if (checked) {
      //add
      careplans.push(plan)
    } else {
      //remove
      careplans.splice(index, 1)
    }

    setSelectedCarePlans(careplans)
  }

  const invitePatient = () => {
    //patch request to patient as invited to careplan
    fetch(
      `${process.env.REACT_APP_MAIN_URL}/api/patient/${patientDetails.patient_id}/update`,
      {
        method: 'PUT',
        headers: new Headers({
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({ avatar: selectedCarePlans.toString() }),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        return toast.success('Patient will be invited to the care plan', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
      .catch((e) => console.log(e))

    fetch(
      `${process.env.REACT_APP_MAIN_URL_HYPERTENSION}/api/goals/activity/invite`,
      {
        signal: signal,
        method: 'POST', //if you can find an id in vitalsState, then update
        headers: new Headers({
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          phone: emailorphone,
          patientname: patientDetails.name,
          doctorname: user?.userName,
          patient_id: patientDetails.patient_id,
        }),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const patient = patientDetails.patient_id

  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal

  // fetch summary data and store it in redux
  useEffect(() => {
    fetchVitals(
      process.env.REACT_APP_MAIN_URL_HYPERTENSION,
      patientDetails.patient_id,
      signal,
      user.token,
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          const tempObj = { ...notFetched }
          tempObj.vitals = 1
          setNotFetched(tempObj)
          return setDataCollected('no')
        }
        if (!res.data?.length) {
          return setDataCollected('no')
        }
        dispatch(
          populateSummary({
            data: res.data[0],
            property: 'vitals',
            action_type: ReduxActions.add_property,
          }),
        )
        //get underlying conditions
        fetchUnderlyingConditions(
          process.env.REACT_APP_MAIN_URL_HYPERTENSION,
          patientDetails.patient_id,
          signal,
          user.token,
        )
          .then((res) => res.json())
          .then((res) => {
            if (res.error) {
              const tempObj = { ...notFetched }
              tempObj.underlying_conditions = 1
              setNotFetched(tempObj)
            }
            dispatch(
              populateSummary({
                data: res.data[0],
                property: 'underlying_conditions',
                action_type: ReduxActions.add_property,
              }),
            )

            //get family history
            fetchFamilyHistory(
              process.env.REACT_APP_MAIN_URL_HYPERTENSION,
              patientDetails.patient_id,
              signal,
              user.token,
            )
              .then((res) => res.json())
              .then((res) => {
                if (res.error) {
                  const tempObj = { ...notFetched }
                  tempObj.family_history = 1
                  setNotFetched(tempObj)
                }
                dispatch(
                  populateSummary({
                    data: res.data[0],
                    property: 'family_history',
                    action_type: ReduxActions.add_property,
                  }),
                )

                //get lifestylereview
                fetchLifeStyleReview(
                  process.env.REACT_APP_MAIN_URL_HYPERTENSION,
                  patientDetails.patient_id,
                  signal,
                  user.token,
                )
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.error) {
                      const tempObj = { ...notFetched }
                      tempObj.lifestyle_review = 1
                      setNotFetched(tempObj)
                    }
                    dispatch(
                      populateSummary({
                        data: res.data[0],
                        property: 'lifestyle_review',
                        action_type: ReduxActions.add_property,
                      }),
                    )

                    //get labs
                    fetchLabs(
                      process.env.REACT_APP_MAIN_URL_HYPERTENSION,
                      patientDetails.patient_id,
                      signal,
                      user.token,
                    )
                      .then((res) => res.json())
                      .then((res) => {
                        if (res.error) {
                          const tempObj = { ...notFetched }
                          tempObj.lab_tests = 1
                          setNotFetched(tempObj)
                        }
                        dispatch(
                          populateSummary({
                            data: res.data[0],
                            property: 'lab_tests',
                            action_type: ReduxActions.add_property,
                          }),
                        )
                      })
                      .catch((err) => {
                        console.log(err)
                        const tempObj = { ...notFetched }
                        tempObj.lab_tests = 1
                        setNotFetched(tempObj)
                      })
                  })
                  .catch((err) => {
                    console.log(err)
                    const tempObj = { ...notFetched }
                    tempObj.lifestyle_review = 1
                    setNotFetched(tempObj)
                  })
              })
              .catch((err) => {
                console.log(err)
                const tempObj = { ...notFetched }
                tempObj.family_history = 1
                setNotFetched(tempObj)
              })
          })
          .catch((err) => {
            console.log(err)
            const tempObj = { ...notFetched }
            tempObj.underlying_conditions = 1
            setNotFetched(tempObj)
          })
      })
      .catch((err) => {
        console.log(err)
        const tempObj = { ...notFetched }
        tempObj.vitals = 1
        setNotFetched(tempObj)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  const size = 4
  return (
    <div className="margin__container">
      <h6 className="margin__left vital__subtopic">Summary</h6>
      {dataCollected === 'yes' ? (
        <>
          {!hideMenu && <HypertensionMenu />}
          <div className="card">
            {summaryState.vitals?.patient_id === patient ? ( // later check against patient id in question
              <Row className="p-2">
                <Col sm={4}>
                  <div className="patient__details">
                    <h6 className="vital__subtopic">Patient Details</h6>
                    <div>
                      <div>
                        <span>Name: &nbsp;&nbsp;{patientDetails?.name}</span>
                      </div>
                      <div>
                        <span>
                          Date of Birth:{' '}
                          {formatDate(patientDetails?.dob, { locale: 'es' })}
                        </span>
                      </div>
                      <div>
                        <span>
                          Age: {calculateAge(patientDetails?.dob)} YRS
                        </span>
                      </div>
                      <div>
                        <span>Weight: </span>
                      </div>
                      <div>
                        <span>Height:</span>
                      </div>
                      <div>
                        <span>BMI: </span>
                      </div>
                      <div>
                        <span>Gender: {patientDetails?.gender}</span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col col={8} className="p-2">
                  <div className="vitals__summary">
                    <Row>
                      <Col sm={size} style={{ paddingLeft: 20 }}>
                        <div className="vital__card1">
                          <h6>Blood pressure</h6>
                          <h5>
                            {
                              summaryState.vitals?.vitals_readings
                                ?.blood_pressure
                            }
                            <small>
                              <strong>&nbsp;mm/Hg</strong>
                            </small>
                          </h5>
                          <button className="vital__btn1">
                            {
                              summaryState.vitals?.vitals_readings
                                ?.blood_pressure_level
                            }
                          </button>
                        </div>
                      </Col>
                      <Col sm={size}>
                        <div className="vital__card2">
                          <h6>Heart rate</h6>
                          <h5>
                            {summaryState.vitals?.vitals_readings?.heart_rate}
                            <small>
                              <strong>&nbsp;Beats/min</strong>
                            </small>
                          </h5>
                          <button className="vital__btn2">
                            {
                              summaryState.vitals?.vitals_readings
                                ?.heart_rate_level
                            }
                          </button>
                        </div>
                      </Col>
                      <Col sm={size}>
                        <div className="vital__card3">
                          <h6>Respiration rate </h6>
                          <h5>
                            {
                              summaryState.vitals?.vitals_readings
                                ?.respiration_rate
                            }
                            <small>
                              <strong>&nbsp;Breaths /min</strong>
                            </small>
                          </h5>
                          <button className="vital__btn3">
                            {
                              summaryState.vitals?.vitals_readings
                                ?.respiration_rate_level
                            }
                          </button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={size} style={{ paddingLeft: 20 }}>
                        <div className="reading__card1">
                          <h6>Systolic murmurs</h6>
                          <Form.Label>
                            Grade{' '}
                            {
                              summaryState.vitals?.stethoscopic_readings
                                ?.systolic_murmurs
                            }
                          </Form.Label>
                          <Form.Range
                            style={{ width: 180, marginRight: 10 }}
                            min={1}
                            max={4}
                            value={
                              summaryState.vitals?.stethoscopic_readings
                                ?.systolic_murmurs
                            }
                            disabled
                          />
                          <button className="reading__btn">
                            {
                              summaryState.vitals?.stethoscopic_readings
                                ?.systolic_murmurs_level
                            }
                          </button>
                        </div>
                      </Col>
                      <Col sm={size}>
                        <div className="reading__card2">
                          <h6>Diastolic mursmurs</h6>
                          <Form.Label>
                            Grade{' '}
                            {
                              summaryState.vitals?.stethoscopic_readings
                                ?.diastolic_murmurs
                            }
                          </Form.Label>
                          <Form.Range
                            style={{ width: 180, marginRight: 10 }}
                            min={1}
                            max={6}
                            value={
                              summaryState.vitals?.stethoscopic_readings
                                ?.diastolic_murmurs
                            }
                            disabled
                          />
                          <button className="reading__btn">
                            {
                              summaryState.vitals?.stethoscopic_readings
                                ?.diastolic_murmurs_level
                            }
                          </button>
                        </div>
                      </Col>
                      <Col sm={size}>
                        <div className="reading__card3">
                          <h6>Body temperature</h6>
                          <h2>
                            {
                              summaryState.vitals?.stethoscopic_readings
                                ?.temperature
                            }
                            <span
                              style={{
                                fontSize: 17,
                                marginTop: -20,
                                marginLeft: 5,
                                fontWeight: 700,
                              }}
                            >
                              &#8451;
                            </span>
                          </h2>
                          <button className="reading__btn">
                            {
                              summaryState.vitals?.stethoscopic_readings
                                ?.temperature_level
                            }
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            ) : (
              <>
                {notFetched.vitals > 0 ? <NoData /> : <SummarypageBonesTop />}
              </>
            )}
          </div>
          <div className="underlying__summary__container">
            <h4>Comorbidities</h4>
            {summaryState.family_history?.patient_id === patient ? (
              <Row>
                <Col sm={6}>
                  <div className="underlying__condition__summary">
                    <h6 className="vital__subtopic">Underlying conditions</h6>
                    <h6>Risk factor: HIGH</h6>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea67"
                    >
                      {summaryState.underlying_conditions?.condition_obj.map(
                        (obj, index) => {
                          // console.log()
                          return (
                            <Form.Check
                              key={index}
                              type="radio"
                              checked={obj.cases[0].diagnosis_date !== ''}
                              label={obj.condition}
                              style={{ marginLeft: 15 }}
                              disabled
                            />
                          )
                        },
                      )}
                    </Form.Group>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="underlying__condition__summary">
                    <h6 className="vital__subtopic">Family history</h6>
                    <h6>Risk factor: HIGH</h6>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      {summaryState.family_history?.condition_obj.map(
                        (obj, index) => (
                          <Form.Check
                            key={index}
                            type="radio"
                            checked={obj.cases[0].relation !== ''}
                            label={obj.condition}
                            style={{ marginLeft: 15 }}
                            disabled
                          />
                        ),
                      )}
                    </Form.Group>
                  </div>
                </Col>
              </Row>
            ) : (
              <>
                {notFetched.family_history > 0 ? (
                  <NoData />
                ) : (
                  <SummarypageBonesComorbidities />
                )}
              </>
            )}
            {/*  */}
          </div>
          {/* Lab Tests Summary */}
          <div className="underlying__summary__container">
            <h4>Lifestyle review</h4>
            {summaryState.lifestyle_review?.patient_id === patient ? (
              <Row>
                <Col sm={6}>
                  <div className="underlying__condition__summary">
                    <h6 className="vital__subtopic">Activities</h6>
                    <h6>
                      Activity level:{' '}
                      {summaryState.lifestyle_review?.activity_level}
                    </h6>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea676"
                    >
                      {Object.entries(
                        summaryState.lifestyle_review
                          ?.activity_level_activities,
                      ).map((obj, index) => {
                        return (
                          <Form.Check
                            key={index}
                            type="radio"
                            label={obj[0]}
                            style={{ marginLeft: 15 }}
                            disabled
                          />
                        )
                      })}
                    </Form.Group>
                  </div>
                </Col>
                <Col sm={6}>
                  <Row className="underlying__condition__summary">
                    <Col sm={6}>
                      <h6 className="vital__subtopic">Mood</h6>
                      <h6>
                        Risk factor:{' '}
                        {summaryState.lifestyle_review?.mood_risk_level}
                      </h6>
                      <Form.Group className="mb-3" controlId="moods">
                        {summaryState.lifestyle_review?.mood_depression && (
                          <Form.Check
                            type="radio"
                            label={'Depressed'}
                            style={{ marginLeft: 15 }}
                            disabled
                          />
                        )}
                        {summaryState.lifestyle_review?.mood_sleep_problems && (
                          <Form.Check
                            type="radio"
                            label={'Sleep Problems'}
                            style={{ marginLeft: 15 }}
                            disabled
                          />
                        )}
                        {summaryState.lifestyle_review?.mood_anxiety && (
                          <Form.Check
                            type="radio"
                            label={'Anxious'}
                            style={{ marginLeft: 15 }}
                            disabled
                          />
                        )}
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <h6 className="vital__subtopic">Social factors</h6>
                      <h6>
                        Risk factor:{' '}
                        {summaryState.lifestyle_review?.se_risk_level}
                      </h6>
                      <Form.Group
                        className="mb-3"
                        controlId="socialfactors_summary"
                      >
                        <Form.Check
                          type="radio"
                          label={
                            summaryState.lifestyle_review?.se_hobbies_text
                              ? 'Hobbies Present'
                              : 'No hobbies'
                          }
                          style={{ marginLeft: 15 }}
                          disabled
                        />
                        <Form.Check
                          type="radio"
                          label={
                            summaryState.lifestyle_review
                              ?.se_financial_legal_concern
                              ? 'Financial concerns'
                              : 'No Financial Concern'
                          }
                          style={{ marginLeft: 15 }}
                          disabled
                        />
                        <Form.Check
                          type="radio"
                          label={
                            summaryState.lifestyle_review?.se_formal_support
                              ? 'Formal Support'
                              : 'No formal Support'
                          }
                          style={{ marginLeft: 15 }}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12} className="underlying__condition__summary p-3 m-3">
                  <h6 className="vital__subtopic">Habits</h6>
                  <p>Alcohol Intake</p>
                  <Row style={{ marginTop: 20 }}>
                    <Col md={4}>
                      <div className="relative">
                        <div className="dashboard1 ">
                          <div className="flex__container">
                            <h4>
                              {
                                summaryState.lifestyle_review
                                  ?.habits_alcohol_glasses_day
                              }
                            </h4>
                            {/* <small style={{ marginLeft: -10, marginTop: 10 }}>
                          ml
                        </small> */}
                          </div>
                          <span style={{ fontSize: 12 }}>
                            Total glasses Intake/per day
                          </span>
                          <div className="midle__edge"></div>
                        </div>
                        <div className="edge"></div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="relative">
                        <div className="dashboard2 ">
                          <div className="flex__container">
                            <h4>
                              {
                                summaryState.lifestyle_review
                                  ?.habits_alcohol_totes_day
                              }
                            </h4>
                            {/* <small style={{ marginLeft: -10, marginTop: 10 }}>
                          ml
                        </small> */}
                          </div>
                          <span style={{ fontSize: 12 }}>
                            Total totes intake/per day
                          </span>
                          <div className="midle__edge"></div>
                        </div>
                        <div className="edge"></div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="relative">
                        <div className="dashboard3 ">
                          <div className="flex__container">
                            <h4>
                              {
                                summaryState.lifestyle_review
                                  ?.habits_alcohol_pints_day
                              }
                            </h4>
                            {/* <small style={{ marginLeft: -10, marginTop: 10 }}>
                          ml
                        </small> */}
                          </div>
                          <span style={{ fontSize: 12 }}>
                            Total pints intake/per day
                          </span>
                          <div className="midle__edge"></div>
                        </div>
                        <div className="edge"></div>
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-5">Smoking</p>
                  <Row style={{ marginTop: 20 }}>
                    <Col md={4}>
                      <div className="relative">
                        <div className="dashboard1 ">
                          <div className="flex__container">
                            <h4>
                              {
                                summaryState.lifestyle_review
                                  ?.habits_cigarrettes_day
                              }
                            </h4>
                            {/* <small style={{ marginLeft: -10, marginTop: 10 }}>
                          ml
                        </small> */}
                          </div>
                          <span style={{ fontSize: 12 }}>
                            Cigarretes/per day
                          </span>
                          <div className="midle__edge"></div>
                        </div>
                        <div className="edge"></div>
                      </div>
                    </Col>
                  </Row>

                  <p className="mt-5">Recreational drugs</p>
                  <Row style={{ marginTop: 20 }}>
                    <Col md={4}>
                      <p>
                        Checked:{' '}
                        {summaryState.lifestyle_review
                          ?.habits_recreational_drugs_notes
                          ? 'YES'
                          : 'NO'}
                      </p>
                    </Col>
                    <Col md={4}>
                      <p>
                        Deduction:{' '}
                        {summaryState.lifestyle_review?.habits_risk_level}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <>
                {notFetched.lifestyle_review > 0 ? (
                  <NoData />
                ) : (
                  <SummarypageBonesComorbidities />
                )}
              </>
            )}
            {/*  */}
          </div>

          <div className="underlying__summary__container">
            {/* <h4>Lab tests</h4> */}
            {summaryState.lab_tests?.patient_id === patient ? (
              <div className="habits__container">
                <h6 className="vital__subtopic">Heart function</h6>
                <hr />
                <Row>
                  {Object.entries(summaryState.lab_tests.heart_function).map(
                    (test, index) => (
                      <Col md={2} className="mr-3 mt-2 mb-3" key={index}>
                        <span>{humanize(trimString(test[0], 16))}</span>
                        <div className="flex__container__summary">
                          <button className="lab__btn__white">
                            <p>
                              {test[1].value}
                              <small>mg/dL</small>
                            </p>
                          </button>

                          {test[1].value > 0 && (
                            <span
                              style={{
                                backgroundColor: backgroundColor(test[1].level),
                                border: `1px solid ${backgroundColor(
                                  test[1].level,
                                )}`,
                              }}
                            >
                              {test[1].level}
                            </span>
                          )}
                        </div>
                      </Col>
                    ),
                  )}
                </Row>
                <h6 className="vital__subtopic mt-3">Liver function</h6>
                <hr />
                <Row>
                  {Object.entries(summaryState.lab_tests.liver_function).map(
                    (test, index) => (
                      <Col md={2} className="mr-3 mt-2 mb-3" key={index}>
                        <span>{humanize(trimString(test[0], 16))}</span>
                        <div className="flex__container__summary">
                          <button className="lab__btn__white">
                            <p>
                              {test[1].value}
                              <small>u/L</small>
                            </p>
                          </button>
                          {test[1].value > 0 && (
                            <span
                              style={{
                                backgroundColor: backgroundColor(test[1].level),
                                border: `1px solid ${backgroundColor(
                                  test[1].level,
                                )}`,
                              }}
                            >
                              {test[1].level}
                            </span>
                          )}
                        </div>
                      </Col>
                    ),
                  )}
                </Row>
                <h6 className="vital__subtopic mt-3">Kidney function</h6>
                <hr />
                <Row>
                  {Object.entries(summaryState.lab_tests.kidney_function).map(
                    (test, index) => (
                      <Col md={2} className="mr-3 mt-2 mb-3" key={index}>
                        <span>{humanize(trimString(test[0], 16))}</span>
                        <div className="flex__container__summary">
                          <button className="lab__btn__white">
                            <p>
                              {test[1].value}
                              <small>mEq/L</small>
                            </p>
                          </button>
                          {test[1].value > 0 && (
                            <span
                              style={{
                                backgroundColor: backgroundColor(test[1].level),
                                border: `1px solid ${backgroundColor(
                                  test[1].level,
                                )}`,
                              }}
                            >
                              {test[1].level}
                            </span>
                          )}
                        </div>
                      </Col>
                    ),
                  )}
                </Row>
              </div>
            ) : (
              <></>
            )}
          </div>

          {(user.role === 'DOCTOR' || user.role === 'NURSE') && (
            <>
              <div className="final__diagnosis">
                <h6 className="vital__subtopic">Final Diagnosis</h6>
                <div className="row">
                  <div className="col-sm-8">
                    <select
                      placeholder="Select Diagnosis"
                      className="form-control form-control-sm mb-2"
                    >
                      <option>Normal</option>
                      <option>Hypertension</option>
                      <option>Stage 1 Hypertension</option>
                      <option>Stage 2 Hypertension</option>
                    </select>
                  </div>

                  <div className="col-sm-4">
                    <Link
                      to="/doctor-prescription"
                      state={{
                        patientDetails,
                      }}
                      className="btn btn-primary"
                    >
                      Prescription
                    </Link>
                  </div>
                </div>
              </div>
              <div className="submit__notes"></div>
              <div className="care__plan__container">
                <div className="flex__container">
                  <div className="care__plan__left">
                    <h6 className="vital__subtopic">Care plan</h6>
                    <div className="flex__container">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.gereh"
                      >
                        <Form.Check
                          type="checkbox"
                          label="Nutrition & Diet Care Plan"
                          name="obesity"
                          style={{ marginLeft: 15 }}
                          onChange={(e) =>
                            populateCareplans(
                              'Nutrition & Diet Care Plan',
                              e.target.checked,
                            )
                          }
                        />
                      </Form.Group>
                    </div>
                    <div className="flex__container">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.gerehrr"
                      >
                        <Form.Check
                          type="checkbox"
                          label="Habit Breaker Care Plan"
                          name="obesity"
                          style={{ marginLeft: 15 }}
                          onChange={(e) =>
                            populateCareplans(
                              'Habit Care Plan',
                              e.target.checked,
                            )
                          }
                        />
                      </Form.Group>
                    </div>

                    <div className="flex__container">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.activity"
                      >
                        <Form.Check
                          type="checkbox"
                          label="Activity Care Plan"
                          name="obesity"
                          style={{ marginLeft: 15 }}
                          onChange={(e) =>
                            populateCareplans(
                              'Activity Care Plan',
                              e.target.checked,
                            )
                          }
                        />
                      </Form.Group>
                    </div>
                    <div className="flex__container">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.mental"
                      >
                        <Form.Check
                          type="checkbox"
                          label="Mental Care Plan"
                          name="obesity"
                          style={{ marginLeft: 15 }}
                          onChange={(e) =>
                            populateCareplans(
                              'Mental Care Plan',
                              e.target.checked,
                            )
                          }
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="care__plan__right">
                    <strong>Enter phone number to invite</strong>

                    <PhoneInput
                      placeholder="Enter phone number"
                      value={emailorphone}
                      onChange={setEmailorphone}
                    />
                    <button
                      className="care__plan__btn"
                      onClick={invitePatient}
                      disabled={!emailorphone}
                    >
                      Send Invite
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="alert alert-info">
          <p>There is no data associated with this profile</p>
        </div>
      )}
    </div>
  )
}
export default Summary
