import React, { useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import LeftBar from '../components/LeftBar'

import { Link } from 'react-router-dom'
import { PatientAdddedSchema } from '../constants/ValidationSchema'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useSelector } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'

const PatientAdd = () => {
  const [phoneState, setPhoneState] = useState('')
  let navigate = useNavigate()
  const user = useSelector(selectUser)
  toast.configure()
  const initialValues = {
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    birth_date: '',
  }

  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal

  const url = `${process.env.REACT_APP_MAIN_URL}/api/patient/`

  const signupLogic = (values, resetForm) => {
    if (phoneState === '' || phoneState.length < 8) {
      return alert('Error: Phone is invalid')
    }
    const dataToSend = {
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      phone: phoneState,
      gender: values.gender,
      birth_date: values.birth_date,
    }

    // send request
    console.log(url, dataToSend, user.token)
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)

        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }
        // dataToSend.id = res.data.id;
        // dataToSend.name = `${dataToSend.firstName} ${dataToSend.lastName}`;
        // resetForm();
        // navigate("/patient-profile", { state: dataToSend });
        navigate('/book-appointment', {
          state: {
            patient_id: res.data.id, //replace with res.id
            patient_name: res.data.name, //replace with data to send .fulname
          },
        })
        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        })
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
  }
  return (
    <div id="main-wrapper">
      {/***********************************
Header start
************************************/}
      <Header />
      {/***********************************
Header end ti-comment-alt
************************************/}
      {/***********************************
Sidebar start
************************************/}
      <LeftBar />
      {/***********************************
Sidebar end
************************************/}
      {/***********************************
Content body start
************************************/}

      {/***********************************
            Sidebar end
        ************************************/}
      {/***********************************
            Content body start
        ************************************/}

      <div className="content-body">
        <div className="card">
          <div className="card-header py-2">
            <h3>Add Patient</h3>
          </div>
        </div>

        <div className="card">
          <div className="card-body text-left">
            <div className="form-contente" style={{ color: 'black' }}>
              <div className="register-widthe ">
                <Formik
                  initialValues={initialValues}
                  validationSchema={PatientAdddedSchema}
                  onSubmit={(values, { resetForm }) => {
                    signupLogic(values, resetForm)
                  }}
                >
                  {(formik) => {
                    const { errors, touched, isValid, dirty } = formik
                    return (
                      <Form className="row">
                        <div className="col-md-4">
                          <Field name="firstName">
                            {(props) => {
                              return (
                                <div className="form-group">
                                  <label className="mb-1 " htmlFor="firstName">
                                    <strong>
                                      First name
                                      <span className="text-danger">*</span>
                                      <ErrorMessage
                                        name="firstName"
                                        component="span"
                                        className="help-block warning errorMessage"
                                      />
                                    </strong>
                                  </label>
                                  <input
                                    type="text"
                                    id="firstName"
                                    autoComplete="firstName"
                                    value={props.field.value}
                                    onChange={props.field.onChange}
                                    onBlur={props.field.onBlur}
                                    className={
                                      errors.firstName && touched.firstName
                                        ? 'form-control input-error warning'
                                        : 'form-control'
                                    }
                                  />
                                </div>
                              )
                            }}
                          </Field>
                        </div>
                        <div className="col-md-4">
                          <Field name="middleName">
                            {(props) => {
                              return (
                                <div className="form-group">
                                  <label className="mb-1 " htmlFor="middleName">
                                    <strong>
                                      Middle name
                                      <ErrorMessage
                                        name="middleName"
                                        component="span"
                                        className="help-block warning errorMessage"
                                      />
                                    </strong>
                                  </label>
                                  <input
                                    type="text"
                                    id="middleName"
                                    autoComplete="middleName"
                                    value={props.field.value}
                                    onChange={props.field.onChange}
                                    onBlur={props.field.onBlur}
                                    className={
                                      errors.middleName && touched.middleName
                                        ? 'form-control input-error warning'
                                        : 'form-control'
                                    }
                                  />
                                </div>
                              )
                            }}
                          </Field>
                        </div>
                        <div className="col-md-4">
                          <Field name="lastName">
                            {(props) => {
                              return (
                                <div className="form-group">
                                  <label className="mb-1 " htmlFor="lastName">
                                    <strong>
                                      Last name
                                      <span className="text-danger">*</span>
                                      <ErrorMessage
                                        name="lastName"
                                        component="span"
                                        className="help-block warning errorMessage"
                                      />
                                    </strong>
                                  </label>
                                  <input
                                    type="text"
                                    id="lastName"
                                    // placeholder="Enter lastName.."
                                    autoComplete="lastName"
                                    value={props.field.value}
                                    onChange={props.field.onChange}
                                    onBlur={props.field.onBlur}
                                    className={
                                      errors.lastName && touched.lastName
                                        ? 'form-control input-error warning'
                                        : 'form-control'
                                    }
                                  />
                                </div>
                              )
                            }}
                          </Field>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="mb-1 " htmlFor="phone">
                              <strong>
                                Phone number
                                <span className="text-danger">*</span>
                                <ErrorMessage
                                  name="phone"
                                  component="span"
                                  className="help-block warning errorMessage"
                                />
                              </strong>
                            </label>
                            {/* <input
                                      type="text"
                                      id="phone"
                                      // placeholder="Enter phone.."
                                      autoComplete="phone"
                                      value={props.field.value}
                                      onChange={props.field.onChange}
                                      onBlur={props.field.onBlur}
                                      className={
                                        errors.phone && touched.phone
                                          ? "form-control input-error warning"
                                          : "form-control"
                                      }
                                    /> */}

                            <PhoneInput
                              inputStyle={{ color: 'black', width: '100%' }}
                              id="phone"
                              country={'ke'}
                              value={phoneState}
                              onChange={(e) => setPhoneState(e)}
                              inputClass={
                                errors.phone && touched.phone
                                  ? 'form-control input-error warning'
                                  : 'form-control'
                              }
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <Field name="gender">
                            {(props) => {
                              return (
                                <div className="form-group">
                                  <label className="mb-1 " htmlFor="gender">
                                    <strong>
                                      Gender
                                      <span className="text-danger">*</span>
                                      <ErrorMessage
                                        name="gender"
                                        component="span"
                                        className="help-block errorMessage warning"
                                      />
                                    </strong>
                                  </label>
                                  <select
                                    id="gender"
                                    value={props.field.value}
                                    onChange={props.field.onChange}
                                    onBlur={props.field.onBlur}
                                    className={
                                      errors.gender && touched.gender
                                        ? 'form-control input-error warning'
                                        : 'form-control'
                                    }
                                  >
                                    <option value="">Select</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                  </select>
                                </div>
                              )
                            }}
                          </Field>
                        </div>

                        <div className="col-md-4">
                          <Field name="birth_date">
                            {(props) => {
                              return (
                                <div className="form-group">
                                  <label className="mb-1 " htmlFor="birth_date">
                                    <strong>
                                      Date of birth
                                      <span className="text-danger">*</span>
                                      <ErrorMessage
                                        name="birth_date"
                                        component="span"
                                        className="help-block errorMessage warning"
                                      />
                                    </strong>
                                  </label>
                                  <input
                                    type="date"
                                    id="birth_date"
                                    // placeholder="Enter birth_date.."
                                    autoComplete="birth_date"
                                    value={props.field.value}
                                    onChange={props.field.onChange}
                                    onBlur={props.field.onBlur}
                                    className={
                                      errors.birth_date && touched.birth_date
                                        ? 'form-control input-error warning'
                                        : 'form-control'
                                    }
                                  />
                                </div>
                              )
                            }}
                          </Field>
                        </div>

                        <div className="col-md-12 text-center mt-4">
                          <button
                            disabled={!(dirty && isValid)}
                            type="submit"
                            className="btn btn-info"
                            title="Click here to Add Patient"
                          >
                            Add
                          </button>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  )
}

export default PatientAdd

// <>
// {(() => {
//   Add array and Lenth here
//   var thisList = [];
//   for (let i = 0; i < 20; i++) {

//     thisList.push(
//       <div className=" review-table">
//         <div className="media">
//           <img className="mr-3 img-fluid" width={110} src="https://i.ibb.co/D8PMZFx/profile.jpg" alt="DexignZone" />
//           <div className="media-body">
//             <h3 className="fs-20 text-black font-w600 mb-3"><a href="email-read.html" className="text-black">Glee Smiley</a>
//             </h3>
//             <div>
//               <p className="mb-1 fs-14">Email:  <h4 className="text-primary2">email@gmail.com</h4></p>

//             </div>
//             <span className="fs-15">Sunday, 24 July 2020   04:55 PM</span>
//           </div>
//           <div className="media-footer d-flex align-self-center">
//             <div className="disease mr-5">
//               <p className="mb-1 fs-14">Disease</p>
//               <h4 className="text-primary2">Diabetes</h4>
//             </div>
//             <div className="edit ml-auto">
//               <a href="javascript:void(0);" className="btn btn-outline-danger">DELETE</a>
//               {/* Add I Here */}
//               <a href="/Patientdetails" className="btn btn-outline-primary ml-2">Details</a>
//             </div>
//           </div>
//         </div>
//       </div>
//     );

//   }

//   return thisList;
// })()}
// </>
