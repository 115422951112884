import './App.css'
import Calendar from './views/Calendar'
import Dashboard from './views/Dashboard'
import AdminDashboard from './views/AdminDashboard'
import Profile from './views/Profile'
import { Routes, Route, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser, login, logout } from './redux_setup/slices/userSlice'
import Login from './views/Login'
import StaffLogin from './views/StaffLogin'
import Register from './views/Register'
import ForgotPassword from './views/ForgotPassword'
import { useEffect, useLayoutEffect, useState } from 'react'
import Booking from './views/Booking'
import jwt_decode from 'jwt-decode'
import HeathCenters from './views/HeathCenters'
import Specialities from './views/Specialities'
import ManageRooms from './views/ManageRooms'
import ManagePricing from './views/ManagePricing'
import ManageUsers from './views/ManageUsers'
import ImportPatients from './views/ImportPatients'
import CreateSlots from './views/CreateSlots'
import PatientManage from './views/Patientmanage'
import PatientProfile from './views/PatientProfile'
import PatientAdd from './views/PatientAdd'
import PatientDetails from './views/PatientDetails'
import Preloader from './components/Preloader'
import VerifyRegister from './views/VerifyRegister'
import AppointmentsActivity from './views/AppointmentsActivity'
import AppointmentPayments from './views/AppointmentPayments'
import PatientProfileComplete from './views/PatientProfileComplete'
import BookFollowUp from './views/BookFollowUp'
import TransferAppt from './views/TransferAppt'
import PatientMedicalDetails from './views/PatientMedicalDetails'
import PatientHypertensionTriage from './views/PatientHypertensionTriage'
import PatientTriageHistory from './views/PatientTriageHistory'
import AssignToNurse from './views/AssignToNurse'
import DoctorAssessment from './views/DoctorAssessment'
import DoctorAssessmentHistory from './views/DoctorAssessmentHistory'
import Ledger from './views/Ledger'
import InsuranceInvoice from './views/InsuranceInvoice'
import PatientSymptoms from './views/PatientSymptoms'
import PatientConsultationHistory from './views/PatientConsultationHistory'
import InsurancePrint from './views/InsurancePrint'
import InstructNurse from './views/InstructNurse'
import PatientDetailsHistory from './views/PatientDetailsHistory'
import TerminateAccount from './views/TerminatingAccount'

import HypertensionContainer from './views/HypertensionContainer'
import ChronicCarePage from './views/HyperTension/HyperTension'
// Care Plan Imports >>>>>>>>
//care-plan-sign-up
import SignUpCarePlam from './views/CarePlan/SignUp.jsx'
import VitalsCarePlan from './views/CarePlan/VitalsCarePlan'
import NutritionCarePlan from './views/CarePlan/NutritionCarePlan'
import CarePlanSummary from './views/CarePlan/CarePlanSummary'
import ActivityCarePlan from './views/CarePlan/ActivityCarePlan'
import HabitCarePlan from './views/CarePlan/HabitCarePlan'
import MentalCarePlan from './views/CarePlan/MentalCarePlan'
import CarePlanHome from './views/CarePlan/CarePlanHome'
import GraphContainer from './components/hypertension/graphs/GraphContainer'
import HypertensivePatient from './components/hypertension/HypertensivePatient'
import NewInvoice from './components/NewInvoice'
import OutputInvoice from './components/OutputInvoice'
import DoctorPrescription from './components/DoctorPrescripton'
import LabTest from './components/labs/LabTest'
import LabTestRequested from './components/labs/LabTestRequested'
import TestImaging from './components/labs/TestImaging'
import PrescriptionPrintView from './components/labs/PrescriptionPrintView'
import PaymentHistory from './components/PaymentHistory'
import LabResults from './components/labs/LabResults'
import CheckInvoice from './components/invoice/CheckInvoice'
import Nutrition from './components/new-nutrition'
import Lunch from './components/new-nutrition/Lunch'
import Dinner from './components/new-nutrition/Dinner'
import Snacks from './components/new-nutrition/Snacks'
import OilUsed from './components/new-nutrition/OilUsed'
import FoodBasketSummary from './components/new-nutrition/FoodBasketSummary'
import CareplanDashboard from './views/CarePlan/CarePlanDashboard'
import VitalCarePlan from './views/CarePlan/VitalsCarePlan'
import DietAndNutritionRecording from './components/vitals/diet/DietAndNutritionRecording'
import DietBasketSummary from './components/vitals/diet/DietBasketSummary'
import NewActivityCarePlan from './components/vitals/exercise/NewActivityCarePlan'
import NewHabitBreakerCarePlan from './components/vitals/habit-breaker/NewHabitBreakerCarePlan'
import NewLifeStyleReview from './components/hypertension/lifestyle'
import BloodPressure from './components/careplan-vitals/blood-pressure'
import NutritionWeightLoss from './components/careplan-vitals/nutrition'
import ActivityLevel from './components/careplan-vitals/ActivityLevel'
import Habits from './components/careplan-vitals/habit-breaker'
import CarePlanVitals from './components/careplan-vitals'
import PasswordSetup from './views/CarePlan/PasswordSetup'
import SuccessfulPage from './views/CarePlan/SuccessfulPage'
import TermsOfServices from './views/CarePlan/TermsOfServices'
import SignupNextPage from './views/CarePlan/SignupNextPage'
import MentalHealth from './components/careplan-vitals/mental-health/MentalHealth'
import CodeValidation from './views/pharmacy/CodeValidation'
import PharmacistDashboard from './views/pharmacy/PharmacistDashboard'
import PharmacyPatientDetails from './views/pharmacy/PrescriptionSummary'
import PrescriptionSummary from './views/pharmacy/PrescriptionSummary'
import PharmacyDispenseButton from './views/pharmacy/PrescriptionSummary'
import DispensationSummary from './views/pharmacy/DispensationSummary'
import ExternalRxDetails from './views/pharmacy/ExternalRxDetails'
import ExternalDispensationSummary from './views/pharmacy/ExternalDispensationSummary'
import PrescriptionDispensation from './views/pharmacy/PrescriptionDispensation'
function App() {
  const [checking, setChecking] = useState(true)
  const [token, setToken] = useState(null)
  const user = useSelector(selectUser)
  const dispatch = useDispatch()
  useEffect(() => {
    const tempToken = localStorage.getItem('temp_token')
    setToken(tempToken)
    if (tempToken && tempToken !== 'null' && tempToken !== 'undefined') {
      //check if it is valid--by decoding the jwt
      const decodedToken = jwt_decode(tempToken)
      // console.log(decodedToken)
      var dateNow = new Date()

      if (decodedToken.exp * 1000 < dateNow.getTime()) {
        //has expired
        localStorage.setItem('temp_token', 'null')
        dispatch(logout())
        setChecking(false)
      } else {
        dispatch(
          login({
            userName: decodedToken.name,

            role: decodedToken.role || 'Patient',

            isAdmin: decodedToken.is_admin || false,
            gender: decodedToken.gender,
            phone: decodedToken.phone,
            user_id: decodedToken.id,
            token: tempToken,
            email: decodedToken.email,
            hc_id: decodedToken.health_center_id,
            health_center_name: decodedToken?.health_center_name,
            birth_date: decodedToken?.birth_date,
            invitedHyperTension: decodedToken?.avatar
              ? decodedToken?.avatar?.split(',')
              : [],
          }),
        )
        setChecking(false)
        // if (decodedToken?.avatar === true) {
        //   Navigate('/careplan-dashboard')
        // }
      }
    } else {
      dispatch(logout())
      setChecking(false)
    }
  }, [])

  const Wrapper = ({ children }) => {
    const location = useLocation()
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0)
    }, [location.pathname])
    return children
  }

  return (
    <Wrapper>
      {checking ? (
        <Preloader />
      ) : (
        <div>
          {user ? (
            <Routes>
              <Route path="/*" element={<Dashboard />} />
              <Route path="calendar" element={<Calendar />} />
              <Route path="profile" element={<Profile />} />
              <Route path="book-appointment" element={<Booking />} />
              <Route
                path="appointment-activity"
                element={<AppointmentsActivity />}
              />
              <Route path="patient-add" element={<PatientAdd />} />
              <Route path="patient-profile" element={<PatientProfile />} />
              <Route path="payments" element={<AppointmentPayments />} />
              <Route
                path="patient-complete-profile"
                element={<PatientProfileComplete />}
              />
              <Route path="book-follow-up" element={<BookFollowUp />} />
              <Route path="transfer-appt" element={<TransferAppt />} />
              <Route
                path="patient-medical-details"
                element={<PatientMedicalDetails />}
              />
              <Route path="patient-symptoms" element={<PatientSymptoms />} />
              <Route
                path="hypertension-triage"
                element={<PatientHypertensionTriage />}
              />
              <Route path="triage-history" element={<PatientTriageHistory />} />
              <Route
                path="hypertension-history"
                element={<PatientTriageHistory />}
              />
              <Route
                path="consultation-history"
                element={<PatientConsultationHistory />}
              />
              <Route
                path="details-history"
                element={<PatientDetailsHistory />}
              />
              <Route
                path="doctor-assessment-history"
                element={<DoctorAssessmentHistory />}
              />
              <Route path="insurance-invoice" element={<InsuranceInvoice />} />
              <Route path="insurance-print" element={<InsurancePrint />} />
              <Route path="assign-to-nurse" element={<AssignToNurse />} />
              <Route path="payment-history" element={<PaymentHistory />} />
              <Route path="lab-result" element={<LabResults />} />
              {/* careplans start */}
              <Route path="care-plan-summary" element={<CarePlanSummary />} />
              <Route
                path="nutrition-care-plan"
                element={<NutritionCarePlan />}
              />
              <Route path="vitals-care-plan" element={<VitalsCarePlan />} />
              <Route path="activity-care-plan" element={<ActivityCarePlan />} />
              <Route path="habit-care-plan" element={<HabitCarePlan />} />
              <Route path="mental-care-plan" element={<MentalCarePlan />} />
              <Route path="new-nutrition" element={<Nutrition />} />
              <Route path="new-nutrition-lunch" element={<Lunch />} />
              <Route path="new-nutrition-dinner" element={<Dinner />} />
              <Route path="new-nutrition-snacks" element={<Snacks />} />
              <Route path="new-nutrition-oil-used" element={<OilUsed />} />
              <Route
                path="careplan-dashboard"
                element={<CareplanDashboard />}
              />
              <Route path="vitals" element={<VitalsCarePlan />} />
              <Route
                path="diet-care-plan"
                element={<DietAndNutritionRecording />}
              />
              <Route
                path="diet-basket-summary"
                element={<DietBasketSummary />}
              />
              <Route
                path="new-nutrition-food-summary"
                element={<FoodBasketSummary />}
              />
              <Route
                path="new-activity-care-plan"
                element={<NewActivityCarePlan />}
              />
              <Route
                path="new-habit-breaker-care-plan"
                element={<NewHabitBreakerCarePlan />}
              />
              <Route
                path="new-life-style-review"
                element={<NewLifeStyleReview />}
              />
              <Route path="mental-health-careplan" element={<MentalHealth />} />
              {/* careplans end */}
              {user.role === 'DOCTOR' && (
                <>
                  <Route path="instruct-nurse" element={<InstructNurse />} />
                  <Route
                    path="doctor-assessment"
                    element={<DoctorAssessment />}
                  />
                  <Route
                    path="hypertensive-patients"
                    element={<HypertensivePatient />}
                  />
                  <Route path="careplan-graph" element={<GraphContainer />} />
                  <Route path="chronic-care" element={<ChronicCarePage />} />
                  <Route
                    path="hypertension"
                    element={<HypertensionContainer />}
                  />

                  {/* Care Plan Routes                                                                     >>>>>>>>>>>*/}

                  <Route path="care-plan-home" element={<CarePlanHome />} />
                  <Route
                    path="doctor-prescription"
                    element={<DoctorPrescription />}
                  />
                  <Route path="lab-test" element={<LabTest />} />
                  <Route path="requested-test" element={<LabTestRequested />} />
                  <Route path="test-image" element={<TestImaging />} />
                  <Route
                    path="prescription-print-view"
                    element={<PrescriptionPrintView />}
                  />
                  <Route path="care-plan-vitals" element={<BloodPressure />} />
                  <Route
                    path="careplan-outcomes"
                    element={<CarePlanVitals />}
                  />
                  <Route
                    path="careplan-nutrition"
                    element={<NutritionWeightLoss />}
                  />
                  <Route
                    path="careplan-activity-level"
                    element={<ActivityLevel />}
                  />
                  <Route path="careplan-habit-breaker" element={<Habits />} />
                </>
              )}
              <>
                {user.role === 'SUPER_ADMIN' && (
                  <>
                    <Route path="health-centers" element={<HeathCenters />} />
                    <Route path="specialities" element={<Specialities />} />
                    <Route path="patient-manage" element={<PatientManage />} />
                    <Route path="patientdetails" element={<PatientDetails />} />
                  </>
                )}
              </>
              <>
                {user.isAdmin === true && (
                  <>
                    <Route path="rooms" element={<ManageRooms />} />
                    <Route path="ledger" element={<Ledger />} />
                    <Route
                      path="admin-dashboard"
                      element={<AdminDashboard />}
                    />
                    <Route path="pricing" element={<ManagePricing />} />
                    <Route path="create-slots" element={<CreateSlots />} />
                    <Route
                      path="terminate-account"
                      element={<TerminateAccount />}
                    />
                    <Route path="invoice" element={<NewInvoice />} />
                    <Route path="check-invoice" element={<CheckInvoice />} />
                    <Route path="invoice-two" element={<OutputInvoice />} />
                  </>
                )}
              </>
              <>
                {(user.role === 'SUPER_ADMIN' || user.isAdmin) === true && (
                  <>
                    <Route path="myCalendar" element={<myCalendar_ />} />

                    <Route path="manage-users" element={<ManageUsers />} />
                    <Route
                      path="import-patients"
                      element={<ImportPatients />}
                    />
                    <Route path="patient-manage" element={<PatientManage />} />
                  </>
                )}
              </>
              <>
                <Route path="patient-manage" element={<PatientManage />} />
                <Route path="patientprofile" element={<PatientDetails />} />
              </>
              <>
                {(user.role === 'NURSE' || user.isAdmin) === true && (
                  <>
                    <Route path="patient-manage" element={<PatientManage />} />
                    <Route path="check-code" element={<CodeValidation />} />
                  </>
                )}
              </>
              <>
                {(user.role === 'PHARMACIST' || user.isAdmin) === true && (
                  <>
                    <Route path="patient-manage" element={<PatientManage />} />
                    <Route path="patient-manage" element={<PatientManage />} />
                    <Route
                      path="pharmacist-dashboard"
                      element={<PharmacistDashboard />}
                    />
                    <Route
                      path="pharmacist-prescription-order-summary"
                      element={<PrescriptionSummary />}
                    />
                    <Route
                      path="pharmacist-prescription-dispensation"
                      element={<PrescriptionDispensation />}
                    />
                    <Route
                      path="dispensation-summary"
                      element={<DispensationSummary />}
                    />
                    <Route
                      path="external-rx-details"
                      element={<ExternalRxDetails />}
                    />
                    <Route
                      path="external-dispensation-summary"
                      element={<ExternalDispensationSummary />}
                    />
                  </>
                )}
              </>
            </Routes>
          ) : (
            // <Routes>
            //   <Route path="/*" element={<Dashboard />} />
            //   <Route path="calendar" element={<Calendar />} />
            //   <Route path="profile" element={<Profile />} />
            // </Routes>
            <Routes>
              <Route path="/*" element={<Login />} />

              <Route
                path="care-plan-signup/:patientId"
                element={<SignUpCarePlam />}
              />
              <Route
                path="care-plan-signup-next-page"
                element={<SignupNextPage />}
              />
              <Route path="password-setup" element={<PasswordSetup />} />
              <Route path="password-successful" element={<SuccessfulPage />} />
              <Route path="terms-of-service" element={<TermsOfServices />} />

              <Route path="login" element={<Login />} />
              <Route path="staff-login" element={<StaffLogin />} />
              <Route path="register" element={<Register />} />
              <Route path="verify-account" element={<VerifyRegister />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
            </Routes>
          )}
        </div>
      )}
    </Wrapper>
  )
}

export default App
