import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectInvoice,
  updateTotalCalculation,
  updateInvoiceIdentification,
  selectPharmacy,
} from '../redux_setup/slices/invoiceSlice'
import { Link, useLocation } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'
import LeftBar from './LeftBar'
import InvoiceHeader from './invoice/InvoiceHeader'
import InvoiceItem from './invoice/InvoiceItem'
import { selectUser } from '../redux_setup/slices/userSlice'
import {
  addInvoiceLabTest,
  adjustInvoiceConsultation,
  adjustInvoiceLabTestItem,
  adjustInvoicePharmacy,
  adjustSections,
  selectInsuranceInvoice,
} from '../redux_setup/slices/insuranceInvoiceSlice'
import { toast } from 'react-toastify'
import { Table } from 'react-bootstrap'
import { ReduxActions } from '../constants/redux_actions'
import { findTotalsInArrayOfObjects } from '../constants/Essentials'

const NewInvoice = () => {
  toast.configure()

  const invoice = useSelector(selectInvoice)
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const insuranceInvoice = useSelector(selectInsuranceInvoice)
  const location = useLocation()
  const [drugName, setDrugName] = useState('')
  const [drugForm, setDrugForm] = useState('')

  const [identification, setIdentification] = useState({})

  const submitHandler = () => {
    // return console.log('insurance invoice could be ', insuranceInvoice)
    // dispatch(updateInvoice([...invoice,{...invoiceObject}]))
    dispatch(updateInvoiceIdentification(identification))

    //code to send to server
    const dataToSend = {
      receptionist_id: user.isAdmin && user.user_id,
      appointment_id: location.state.appt_id,
      payment_type: 'insurance', //cash or mpesa
      amount: findTotalsInArrayOfObjects(insuranceInvoice, 'totalfield'),
    }
    const invoiceObj = {
      invoice: insuranceInvoice,
    }
    const finalObj = { invoiceObj, ...dataToSend }
    console.log(finalObj)

    const url = `${process.env.REACT_APP_MAIN_URL}/api/appointment/process-payment`
    // send to payment page here
    // return navigate("/payments", { state: dataToSend });
    // send request
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(finalObj),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }

        toast.success('Invoice saved successfully', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })

    //end server sending code
  }

  //
  const invoiceNo = (e) =>
    setIdentification({ ...identification, invoice_no: e.target.value })
  const issueDate = (e) =>
    setIdentification({ ...identification, date: e.target.value })
  const fileNo = (e) =>
    setIdentification({ ...identification, file_no: e.target.value })
  const patientNo = (e) =>
    setIdentification({ ...identification, patient_no: e.target.value })
  const insurance = (e) =>
    setIdentification({
      ...identification,
      insurance_provider: e.target.value,
    })
  const insuranceNo = (e) =>
    setIdentification({
      ...identification,
      insurance_no: e.target.value,
    })
  const scheme = (e) =>
    setIdentification({ ...identification, scheme: e.target.value })
  const principalName = (e) =>
    setIdentification({ ...identification, principal_name: e.target.value })

  //new functions

  const addSection = (section) => {
    console.log(section)
    if (section !== '') {
      dispatch(
        adjustSections({
          action_type: ReduxActions.add_invoice_section,
          val: section,
        }),
      )
    }
  }

  const removeSection = (section) => {
    console.log(section)
    if (section !== '') {
      dispatch(
        adjustSections({
          action_type: ReduxActions.remove_invoice_section,
          val: section,
        }),
      )
    }
  }

  const addlabTest = (labTest) => {
    if (labTest !== '') {
      dispatch(
        addInvoiceLabTest({
          action_type: ReduxActions.add_invoice_labtest,
          val: labTest,
        }),
      )
    }
  }

  const editLabTestItem = (val, field, testname) => {
    dispatch(
      adjustInvoiceLabTestItem({
        action_type: ReduxActions.edit_invoice_labtest_item,
        val,
        field,
        testname,
      }),
    )
  }

  const removeLabTestItem = (testname) => {
    dispatch(
      adjustInvoiceLabTestItem({
        action_type: ReduxActions.delete_invoice_labtest_item,

        testname,
      }),
    )
  }

  const editInvoiceMainField = (val, fieldToEdit, fieldValue) => {
    dispatch(
      adjustSections({
        action_type: ReduxActions.edit_invoice_section,
        val,
        fieldToEdit,
        fieldValue,
      }),
    )
  }

  const addDrugToList = () => {
    console.log('Adding', drugName, drugForm)
    if (drugName !== '' && drugForm !== '') {
      dispatch(
        adjustInvoicePharmacy({
          action_type: ReduxActions.add_invoice_pharmacy,
          slug: drugName + ' ' + drugForm,
        }),
      )
    }
    setDrugForm('')
    setDrugName('')
  }
  const removeDrug = (slug) => {
    dispatch(
      adjustInvoicePharmacy({
        action_type: ReduxActions.delete_invoice_drug,

        slug,
      }),
    )
  }

  const editDrug = (val, field, slug) => {
    dispatch(
      adjustInvoicePharmacy({
        action_type: ReduxActions.edit_invoice_drug,
        val,
        field,
        slug,
      }),
    )
  }
  const editConsultationItem = (val, field) => {
    dispatch(
      adjustInvoiceConsultation({
        action_type: ReduxActions.change_consultation_field,
        val,
        field,
      }),
    )
  }

  const calculatePrice = (typ) => {
    if (typ === 'New Appointment') {
      editConsultationItem(4000, 'pricefield')
    } else if (typ === 'Follow up Appointment') {
      editConsultationItem(2000, 'pricefield')
    }
  }
  return (
    <div className="main-wrapper">
      {/***********************************
            Header start
            ************************************/}
      <Header />
      {/***********************************
            Header end ti-comment-alt
            ************************************/}
      {/***********************************
            Sidebar start
            ************************************/}
      <LeftBar />
      {/***********************************
            Sidebar end
            ************************************/}
      {/***********************************
            Content body start
            ************************************/}

      {/***********************************
            Content body end
            ************************************/}
      <div className="content-body">
        <InvoiceHeader
          invoiceNo={invoiceNo}
          date={issueDate}
          insurance={insurance}
          fileNo={fileNo}
          insuranceNo={insuranceNo}
          scheme={scheme}
          patientNo={patientNo}
          principalNo={principalName}
        />
        <Table>
          {insuranceInvoice.map((elem, index) => {
            if (elem.selected && elem.service === 'Consultation') {
              return (
                <>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Service</th>
                      <th>Type</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Discount</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1.</td>
                      <td>{elem.service}</td>
                      <td>
                        <select
                          className="form-control"
                          onChange={(e) => calculatePrice(e.target.value)}
                        >
                          <option value="">Select</option>
                          {elem.options.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <input
                          type="number"
                          value={elem?.pricefield}
                          className="form-control"
                          readOnly
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={elem?.quantityfield}
                          className="form-control"
                          readOnly
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={elem?.discountfield}
                          className="form-control"
                          onChange={(e) =>
                            editConsultationItem(
                              e.target.value,
                              'discountfield',
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={elem?.amountfield}
                          className="form-control"
                          readOnly
                        />
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Subtotal</td>
                      <td>
                        <input
                          type="text"
                          value={elem?.subtotalfield || 0}
                          className="form-control"
                          readOnly
                        />
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Tax (%)</td>
                      <td>
                        <input
                          type="number"
                          value={elem?.taxfield}
                          onChange={(e) =>
                            editInvoiceMainField(
                              'Consultation',
                              'taxfield',
                              e.target.value,
                            )
                          }
                          max={100}
                          min={0}
                          className="form-control"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Total</td>
                      <td>
                        <input
                          type="text"
                          value={elem?.totalfield || 0}
                          className="form-control"
                          readOnly
                        />
                      </td>
                    </tr>
                  </tbody>
                </>
              )
            }
            if (elem.selected && elem.service === 'Labs') {
              return (
                <>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Service</th>
                      <th>Test</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Discount</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>2.</td>
                      <td>{elem.service}</td>
                      <td>
                        <select
                          className="form-control"
                          onChange={(e) => addlabTest(e.target.value)}
                        >
                          <option value="">Select Test</option>
                          {elem.options.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {
                      //repeat rows in chosen tests here
                      elem.chosenTests.map((test, index) => (
                        <tr>
                          <td></td>
                          <td>
                            <i
                              className="fa-sharp fa-solid fa-minus-circle text-danger"
                              onClick={() => removeLabTestItem(test?.name)}
                            ></i>
                          </td>
                          <td>{test?.name}</td>
                          <td>
                            <input
                              type="text"
                              value={test?.price}
                              onChange={(e) =>
                                editLabTestItem(
                                  e.target.value,
                                  'price',
                                  test?.name,
                                )
                              }
                              className="form-control"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={test?.quantity}
                              onChange={(e) =>
                                editLabTestItem(
                                  e.target.value,
                                  'quantity',
                                  test?.name,
                                )
                              }
                              className="form-control"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              value={test?.discount}
                              className="form-control"
                              onChange={(e) =>
                                editLabTestItem(
                                  e.target.value,
                                  'discount',
                                  test?.name,
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={test.amount || 0}
                              className="form-control"
                              readOnly
                            />
                          </td>
                        </tr>
                      ))
                    }

                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Subtotal</td>
                      <td>
                        <input
                          type="text"
                          value={elem?.subtotalfield || 0}
                          className="form-control"
                          readOnly
                        />
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Tax (%)</td>
                      <td>
                        <input
                          type="number"
                          value={elem?.taxfield}
                          onChange={(e) =>
                            editInvoiceMainField(
                              'Labs',
                              'taxfield',
                              e.target.value,
                            )
                          }
                          max={100}
                          min={0}
                          className="form-control"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <button
                          className="btn btn-danger"
                          onClick={() => removeSection('Labs')}
                        >
                          <i class="fa-sharp fa-solid fa-trash"></i> Remove
                          Section
                        </button>
                      </td>
                      <td></td>
                      <td></td>
                      <td>Total</td>
                      <td>
                        <input
                          type="text"
                          value={elem?.totalfield || 0}
                          className="form-control"
                          readOnly
                        />
                      </td>
                    </tr>
                  </tbody>
                </>
              )
            }
            //pharmacy
            if (elem.selected && elem.service === 'Pharmacy') {
              return (
                <>
                  <thead style={{ width: '80%' }}>
                    <tr>
                      <th>No.</th>
                      <th>Service</th>
                      <th>Drug</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Discount</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>3.</td>
                      <td>{elem.service}</td>
                      <td>
                        <select
                          className="form-control"
                          value={drugName}
                          onChange={(e) => setDrugName(e.target.value)}
                        >
                          <option value="">Select Drug</option>
                          {elem.drugs.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <select
                          className="form-control"
                          value={drugForm}
                          onChange={(e) => setDrugForm(e.target.value)}
                        >
                          <option value="">Select Drug Form</option>

                          {elem.forms.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={addDrugToList}
                        >
                          Add
                        </button>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    {
                      //repeat rows in chosen tests here
                      elem.chosenDrugs.map((drug, index) => (
                        <tr>
                          <td></td>
                          <td>
                            <i
                              className="fa-sharp fa-solid fa-minus-circle text-danger"
                              onClick={() => removeDrug(drug?.slug)}
                            ></i>
                          </td>
                          <td>{drug?.slug}</td>
                          <td>
                            <input
                              type="text"
                              value={drug?.price}
                              onChange={(e) =>
                                editDrug(e.target.value, 'price', drug?.slug)
                              }
                              className="form-control"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={drug?.quantity}
                              onChange={(e) =>
                                editDrug(e.target.value, 'quantity', drug?.slug)
                              }
                              className="form-control"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              value={drug?.discount}
                              className="form-control"
                              onChange={(e) =>
                                editDrug(e.target.value, 'discount', drug?.slug)
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={drug.amount || 0}
                              className="form-control"
                              readOnly
                            />
                          </td>
                        </tr>
                      ))
                    }
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Subtotal</td>
                      <td>
                        <input
                          type="text"
                          value={elem?.subtotalfield || 0}
                          className="form-control"
                          readOnly
                        />
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Tax (%)</td>
                      <td>
                        <input
                          type="number"
                          value={elem?.taxfield}
                          onChange={(e) =>
                            editInvoiceMainField(
                              'Pharmacy',
                              'taxfield',
                              e.target.value,
                            )
                          }
                          max={100}
                          min={0}
                          className="form-control"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <button
                          className="btn btn-danger"
                          onClick={() => removeSection('Pharmacy')}
                        >
                          <i class="fa-sharp fa-solid fa-trash"></i> Remove
                          Section
                        </button>
                      </td>
                      <td></td>
                      <td></td>
                      <td>Total</td>
                      <td>
                        <input
                          type="text"
                          value={elem?.totalfield || 0}
                          className="form-control"
                          readOnly
                        />
                      </td>
                    </tr>
                  </tbody>
                </>
              )
            }
          })}
        </Table>
        <div className="form-group row">
          <label class="col-sm-2 control-label" htmlFor="sections">
            <i class="fa-sharp fa-solid fa-plus"></i> Add Section
          </label>
        </div>
        <div className="row mb-3">
          <div className="col-sm-4">
            <select
              id="sections"
              className="form-control"
              onChange={(e) => addSection(e.target.value)}
            >
              <option value="">Select section</option>
              <option value="Labs">Labs</option>
              <option value="Pharmacy">Pharmacy</option>
            </select>
          </div>
        </div>

        <div
          className="flex__container"
          style={{
            justifyContent: 'space-between',
            backgroundColor: '#FFF',
            width: '100%',
          }}
        >
          <div
            className="flex__block"
            style={{ justifyContent: 'space-between' }}
          >
            <span
              className="invoice__label"
              style={{ marginTop: 70, marginLeft: 30 }}
            >
              If you have any query about any information
              <br /> provided on this invoice please contact us on
              <br /> the details provided.
            </span>
          </div>
          <div>
            <div
              className="invoice__item"
              style={{ justifyContent: 'flex-end' }}
            >
              <div
                className="flex__container"
                style={{ marginRight: 30, gap: 80 }}
              >
                <span className="invoice__label" style={{ color: '#25282B' }}>
                  {`Grand Total`}
                </span>
                <label className="short__label">
                  {findTotalsInArrayOfObjects(insuranceInvoice, 'totalfield')}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div
          className="flex__container"
          style={{ backgroundColor: '#FFF', gap: 40, width: '100%' }}
        >
          <div
            className="flex__container"
            style={{ marginBottom: 50, marginTop: 50 }}
          >
            <span style={{ marginLeft: 30, whiteSpace: 'nowrap' }}>
              Approving Doctor’s
              <br /> Signature:
            </span>
            <div
              style={{
                width: 246,
                height: 1,
                backgroundColor: '#E8E8E8',
                marginTop: 40,
              }}
            />
          </div>
          <div className="flex__container" style={{ marginTop: 50 }}>
            <span style={{ whiteSpace: 'nowrap' }}>
              Approving Doctor’s
              <br /> Signature:
            </span>
            <div
              style={{
                width: 322,
                height: 1,
                backgroundColor: '#E8E8E8',
                marginTop: 40,
              }}
            />
          </div>
        </div>
      </div>
      <div
        className="flex__container"
        style={{ justifyContent: 'center', marginTop: -120 }}
      >
        <Link to="/invoice-two">
          <button className="invoice__btn" onClick={submitHandler}>
            Generate Invoice
          </button>
        </Link>
      </div>

      {/***********************************
            Footer start
            ************************************/}
      <Footer />
      {/***********************************
            Footer end
            ************************************/}
    </div>
  )
}

export default NewInvoice
