import { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import Header from '../Header'
import Footer from '../Footer'
import LeftBar from '../LeftBar'
import { FoodChart } from './FoodChart'
import { useSelector } from 'react-redux'
import { selectBasketFood } from '../../redux_setup/slices/basketFoodSlice'
import Mealprofile from './Mealprofile'
import { Link, useNavigate } from 'react-router-dom'
import Breakfast from './Breakfast'
import Lunch from './Lunch'
import Dinner from './Dinner'
import Snacks from './Snacks'
import OilUsed from './OilUsed'
import {
  selectBreakfast,
  selectDinner,
  selectLunch,
  selectSnacks,
  selectSummary,
  updateSummary,
} from '../../redux_setup/slices/nutritionBasketSlice'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { selectUser } from '../../redux_setup/slices/userSlice'

const Nutrition = () => {
  const [loading, setLoading] = useState(false)
  toast.configure()
  const foodInBasket = useSelector(selectBasketFood)
  const breakfast = useSelector(selectBreakfast)
  const lunch = useSelector(selectLunch)
  const dinner = useSelector(selectDinner)
  const snacks = useSelector(selectSnacks)
  const summary = useSelector(selectSummary)
  const user = useSelector(selectUser)
  const navigate = useNavigate()
  const combined = [...breakfast, ...lunch, ...dinner, ...snacks]
  const filteredcombined = combined.filter((fd) => fd?.count > 0)
  const submitHandler = () => {
    setLoading(true)
    const dataToSend = {
      patient_id: user?.user_id,
      calories: summary.calories,
      sodium: summary.sodium,
      fats: summary.fats,
    }
    fetch(
      `${process.env.REACT_APP_MAIN_URL_HYPERTENSION}/api/patient/nutrition`,
      {
        method: 'POST',
        headers: new Headers({
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(dataToSend),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false)
        navigate('/new-activity-care-plan')

        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }
        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })

        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error has occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
  }
  return (
    <div className='main-wrapper'>
      {/***********************************
                  Header start
                  ************************************/}
      <Header />
      {/***********************************
                  Header end ti-comment-alt
                  ************************************/}
      {/***********************************
                  Sidebar start
                  ************************************/}
      <LeftBar />
      {/***********************************
                  Sidebar end
                  ************************************/}
      {/***********************************
                  Content body start
                  ************************************/}

      {/***********************************
                  Content body end
                  ************************************/}
      <div className='content-body'>
        <div className='d-flex' style={{ width: 300 }}>
          {/* <Form.Select
            aria-label='Default select example'
            className='mr-2 pl-2'
          >
            <option>Open this select menu</option>
          </Form.Select> */}
          <Button
            className='mr-2'
            style={{
              backgroundColor: "#2FA5DB33",
              border: "1px solid #2FA5DB33",
              fontWeight: 700,
            }}
          >
            Today
          </Button>
          <div
            className=' circle'
            style={{
              border: "1px solid #242E75",
              width: 50,
              height: 32,
              paddingLeft: 10,
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/meal.png`}
              alt='meal icon'
              width={7.6}
              height={17}
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/meal-1.png`}
              alt='meal icon'
            />
            <div
              className='circle'
              style={{
                position: "absolute",
                right: -5,
                top: -12,
                backgroundColor: "#662382",
                color: "#FFF",
                paddingBottom: 7,
                paddingLeft: 4,
                fontSize: 10,
                width: foodInBasket.length > 9 ? 20 : "",
                height: foodInBasket.length > 9 ? 20 : "",
                paddingTop: foodInBasket.length > 9 ? 2 : "",
              }}
            >
              {filteredcombined.length}
            </div>
          </div>
        </div>
        <p
          className='mt-3 mb-3'
          style={{
            fontSize: 20,
            fontFamily: "Lato",
            fontWeight: 700,
            color: "#000",
          }}
        >
          Meals
        </p>
        <div className='row'>
          <div
            className=' col-md-8 col-lg-8 col-sm-12'
            style={{ backgroundColor: "#fff", padding: 20, borderRadius: 8 }}
          >
            <div className='col-md-12'>
              <Breakfast />
              <Lunch />
              <Dinner />
              <Snacks />
              {/* <OilUsed /> */}
            </div>
          </div>
          <div className='col-md-4 col-lg-4 col-sm-12 card'>
            <Mealprofile />
            <FoodChart />
            {/* <Link to="/new-nutrition"> */}
          </div>
        </div>
        {/* </Link> */}
        <div className='d-flex' style={{ gap: 20 }}>
          <Link to='/vitals-care-plan'>
            <Button
              className='mt-2'
              variant='success'
              // onClick={submitHandler}
            >
              Back
            </Button>
          </Link>
          <Button className='mt-2' variant='success' onClick={submitHandler}>
            {loading ? (
              <Spinner animation='border' variant='primary' />
            ) : (
              "Next"
            )}
          </Button>
        </div>
      </div>
      {/***********************************
                  Footer start
                  ************************************/}
      <Footer />
      {/***********************************
                  Footer end
                  ************************************/}
    </div>
  );
}

export default Nutrition
