import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux'
import { selectTests, updateTests } from '../../redux_setup/slices/testSlice'
import { Form } from 'react-bootstrap'
import Collapse from 'react-bootstrap/Collapse'
import { Link } from 'react-router-dom'
import Footer from '../Footer'
import Header from '../Header'
import LeftBar from '../LeftBar'
import { machinize } from '../../constants/Essentials'
import { ReduxActions } from '../../constants/redux_actions'

const LabTest = () => {
  const dispatch = useDispatch()
  const componentRef = useRef()
  const testsArray = useSelector(selectTests)

  const changeTestState = (name) => {
    dispatch(
      updateTests({
        val: name,
        action_type: ReduxActions.expand_test_option,
      }),
    )
  }
  const adjustSelectedChildren = (motherObject, child) => {
    dispatch(
      updateTests({
        motherObject,
        child,
        action_type: ReduxActions.change_child_option,
      }),
    )
  }
  return (
    <div className="main-wrapper">
      {/***********************************
                    Header start
                    ************************************/}
      <Header />
      {/***********************************
                    Header end ti-comment-alt
                    ************************************/}
      {/***********************************
                    Sidebar start
                    ************************************/}
      <LeftBar />
      {/***********************************
                    Sidebar end
                    ************************************/}
      {/***********************************
                    Content body start
                    ************************************/}

      {/***********************************
                    Content body end
                    ************************************/}
      <div className="content-body" ref={componentRef}>
        <p className="prescription__text">Additional Information- John Doe</p>
        <div className="card lab__test__info">
          <div className="row">
            <div className="col-md-7 col-sm-12">
              <p className="recommended__amount__content">
                What are your current Symptoms?
              </p>
              <textarea
                className="lab__textarea"
                placeholder="provide some information"
              />
            </div>
            <div className="col-md-5 col-sm-12">
              <p className="recommended__amount__content">
                When did the symptoms start?
              </p>
              <input type="date" className="lab__date" />
            </div>
          </div>
          <Form.Check
            style={{ marginTop: 20 }}
            label="Have you tried self treatment? (Including use of Over The Counter medication)"
          />
          <Form.Check label="Start Triage" />
          <button className="prescription__send__btn not__printed" style={{ marginTop: 20 }}>
            Done{' '}
          </button>
          <div
            className="flex__container"
            style={{ flexWrap: 'wrap', gap: 30 }}
          >
            <p
              className="prescription__text"
              style={{ color: '#2FA5DB', fontWeight: 400, cursor: 'pointer' }}
            >
              View Patient Details History
            </p>
            <p
              className="prescription__text"
              style={{ color: '#2FA5DB', fontWeight: 400, cursor: 'pointer' }}
            >
              View Patient Consultation History
            </p>
          </div>
        </div>
        <p className="prescription__text">Assess Patient</p>
        <div className="card lab__test__info" style={{ height: 'auto' }}>
          <p className="recommended__amount__content">Case Information</p>
          <div className="flex__container" style={{ gap: 20 }}>
            {['Acute', 'Chronic', 'Pre-existing', 'Injury'].map(
              (item, index) => (
                <Form.Check
                  key={index}
                  label={item}
                  type="radio"
                  name="case"
                  id={item}
                />
              ),
            )}
          </div>
          <p className="recommended__amount__content" style={{ marginTop: 30 }}>
            Tests
          </p>
          <div className="row">
            {testsArray.map((item, index) => (
              <div className="col-md-6 col-sm-12" key={index}>
                <Form.Check
                  label={item.name}
                  name={machinize(item.name)}
                  id={`${machinize(item.name)}-${index}`}
                  key={`${machinize(item.name)}-${index}`}
                  onChange={(e) => {
                    changeTestState(item.name)
                    // setOpen(!open)
                    // setShow({
                    //   id: index,
                    //   id_text: item,
                    // })
                    // setStatus({ id: null })
                  }}
                  aria-controls="example-collapse-text"
                  aria-expanded={item.expanded}
                />
                {item.children?.length > 0 && item.expanded ? (
                  <Collapse in={item.expanded} style={{ marginLeft: 40 }}>
                    <div id={`child${machinize(item.name)}-${index}`}>
                      {item.children.map((child, index) => (
                        <Form.Check
                          label={child}
                          key={`${item.name}-${child}-${index}`}
                          id={`${item.name}-${child}-${index}`}
                          onChange={() => adjustSelectedChildren(item, child)}
                        />
                      ))}
                    </div>
                  </Collapse>
                ) : (
                  <></>
                )}
              </div>
            ))}
          </div>

          <div
            className="flex__container"
            style={{ justifyContent: 'flex-end', marginTop: 30 }}
          >
            
            <ReactToPrint
            trigger={() => <button
              className="prescription__send__btn not__printed"
              style={{ width: 100, background: '#45B757' }}
            >
              Print{' '}
            </button>}
            content={() => componentRef.current}
            documentTitle='Lab Test'
          />
            <Link to="/requested-test">
              <button
                className="prescription__send__btn not__printed"
                style={{ width: 130 }}
                onClick={() => {
                  // dispatch(updateTest([...tests]))
                  // dispatch(updateMainTest(mainTest))
                  // console.log(testsArray.filter((test) => test.expanded === true))
                }}
              >
                Email
              </button>
            </Link>
          </div>
        </div>
        <div className="card lab__test__info" style={{ height: 'auto' }}>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <p className="recommended__amount__content">Diagnosis</p>
              <textarea
                className="lab__textarea"
                placeholder="provide some information"
                style={{ width: 508 }}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <p className="recommended__amount__content">Additional Notes</p>
              <textarea
                className="lab__textarea"
                placeholder="provide some information"
                style={{ width: 508 }}
              />
            </div>
            <div
              className="flex__container"
              style={{ margin: '30px 20px 40px 20px' }}
            >
              <button
                className="prescription__send__btn not__printed"
                style={{ width: 167, background: '#52575C' }}
              >
                Back to dashboard{' '}
              </button>
              <button
                className="prescription__send__btn not__printed"
                style={{ width: 130 }}
              >
                prescription{' '}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/***********************************
                    Footer start
                    ************************************/}
      <Footer />
      {/***********************************
                    Footer end
                    ************************************/}
    </div>
  )
}
export default LabTest
