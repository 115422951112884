import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import LeftBar from '../components/LeftBar'
import Nav from 'react-bootstrap/Nav'
// import HypertensionPage from './hypertension/ChronicCareManagement'
import FamilyHistory from '../components/hypertension/FamilyHistory'
import Vitals from '../components/hypertension/Vitals'
import Labs from '../components/hypertension/Labs'
import Summary from '../components/hypertension/Summary'
import LifestyleReview from '../components/hypertension/LifestyleReview'
import UnderlyingConditions from '../components/hypertension/UnderlyingConditions'
import { useSelector } from 'react-redux'
import { selectStep } from '../redux_setup/slices/stepSlice'
import { useLocation } from 'react-router-dom'
import { Button, Modal, Row, Table } from 'react-bootstrap'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { selectUser } from '../redux_setup/slices/userSlice'
import { searchHandler } from '../constants/Essentials'
const HypertensionContainer = () => {
  const step = useSelector(selectStep)
  const location = useLocation()
  const [chosePatient, setChosepatient] = useState(false)
  const [proceedGreenLight, setProceedGreenLight] = useState(false)
  const [patients, setPatients] = useState([])
  const [detailsToDrill, setDetailsToDrill] = useState({})
  const [modalWasHidden, setModalWasHidden] = useState(false)
  const [showClass, setShowClass] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState('')
  const [allPatients, setAllPatients] = useState([])
  const user = useSelector(selectUser)
  toast.configure()

  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal

  const proceedWithPatient = (patient) => {
    setDetailsToDrill({
      name: patient.name,
      patient_id: patient.id,
      dob: patient.birth_date,
      gender: patient.gender,
      //extras
      email: patient.email,
      phone: patient.phone,
    })
    setProceedGreenLight(true)
    setChosepatient(false)
  }
  const fetchPatients = () => {
    fetch(`${process.env.REACT_APP_MAIN_URL}/api/patient/`, {
      signal: signal,
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        if (res.error) {
          return console.log('patients fetching did not succeed')
        }
        let iterable = user.role === 'SUPER_ADMIN' ? res.data.items : res.data

        let count = 1
        iterable.forEach((item) => {
          item.count = count
          count++
          //->will delete the rest later
        })
        setPatients(iterable)
        setAllPatients(iterable)
        setChosepatient(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (!location.state) {
      //show a modal to choose a patient
      fetchPatients()
    } else {
      setProceedGreenLight(true)
      setDetailsToDrill(location.state)
      console.log('location object =>', location)
    }
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
    // return () => {
    //   second
    // }
  }, [step])

  const switchComponent = (steps) => {
    switch (steps) {
      case 'vitals':
        return <Vitals patientDetails={detailsToDrill} />
      case 'family_history':
        return <FamilyHistory patientDetails={detailsToDrill} />
      case 'underlying_conditions':
        return <UnderlyingConditions patientDetails={detailsToDrill} />
      case 'LifestyleReview':
        return <LifestyleReview patientDetails={detailsToDrill} />
      case 'labs':
        return <Labs patientDetails={detailsToDrill} />
      case 'summary':
        return <Summary patientDetails={detailsToDrill} />
      default:
        return <></>
    }
  }
  useEffect(() => {
    if (searchKeyword.length === 0) {
      return setPatients(allPatients)
    }
    //wait for some seconds then run search handler
    if (searchKeyword.length > 0) {
      setTimeout(() => {
        searchHandler(patients, setPatients, searchKeyword)
      }, 200) //some sort of debounce--whatever
    }
  }, [searchKeyword])
  return (
    <div id="main-wrapper">
      {/***********************************
Header start
************************************/}
      <Header />
      {/***********************************
Header end ti-comment-alt
************************************/}
      {/***********************************
Sidebar start
************************************/}
      <LeftBar />
      {/***********************************
Sidebar end
************************************/}
      {/***********************************
Content body start
************************************/}

      {/***********************************
Content body end
************************************/}
      <div className="content-body">
        {proceedGreenLight ? (
          <div>{switchComponent(step)}</div>
        ) : (
          <div>
            {' '}
            Checking info... <i className="fa fa-spin fa-spinner " />
            {modalWasHidden && (
              <Button
                onClick={() => {
                  setChosepatient(true)
                  setModalWasHidden(false)
                }}
              >
                Show patients again
              </Button>
            )}
          </div>
        )}
      </div>

      {/***********************************
Footer start
************************************/}
      <Footer />
      {/***********************************
Footer end
************************************/}
      <Modal
        show={chosePatient}
        onHide={() => {
          setChosepatient(false)
          setModalWasHidden(true)
          setShowClass(false)
        }}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Choose a patient to proceed</Modal.Title>
          <span
            className={!showClass ? 'close__btn' : 'hover'}
            onClick={() => {
              setChosepatient(false)
              setModalWasHidden(true)
              setShowClass(false)
            }}
            onMouseOver={() => setShowClass(!showClass)}
            onMouseOut={() => setShowClass(false)}
          >
            x
          </span>
        </Modal.Header>
        <Modal.Body>
          <div
            className="row flex__container"
            style={{ justifyContent: 'flex-end' }}
          >
            <div className="col-lg-4 col-md-6">
              <input
                placeholder="search by name,email or phone"
                className="form-control"
                type="text"
                name=""
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
            </div>
            <div className="col-lg-2 col-md-12">
              <button
                type="button"
                className="submitSearch btn btn-info "
                onClick={() =>
                  searchHandler(patients, setPatients, searchKeyword)
                }
                title="Click here to search patient"
                style={{
                  width: 110,
                  height: 38,
                  whiteSpace: 'nowrap',
                  marginLeft: -20,
                  marginBottom: 10,
                }}
              >
                Find patient
              </button>
            </div>
          </div>
          <Row>
            <Table bordered hover striped>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Gender</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {patients.map((patient) => (
                  <tr key={patient.id}>
                    <td>{patient.name}</td>
                    <td>{patient.gender}</td>
                    <td>{patient.email}</td>
                    <td>
                      <Button
                        size="sm"
                        variant="info"
                        onClick={() => proceedWithPatient(patient)}
                      >
                        Choose
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        </Modal.Body>
        <div className="footer__close">
          <Button
            size="sm"
            variant="danger"
            onClick={() => {
              setChosepatient(false)
              setModalWasHidden(true)
              setShowClass(false)
            }}
          >
            Close
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default HypertensionContainer
