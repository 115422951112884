import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import LeftBar from '../components/LeftBar'
import DataTable from 'react-data-table-component'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import Preloader from '../components/Preloader'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import {
  AddSpecialitySchema,
  Add_HC_Schema,
} from '../constants/ValidationSchema'

const columns = [
  {
    name: 'No',
    selector: (row) => row.count,
    sortable: true,
  },
  {
    name: 'Name',
    selector: (row) => row.name,
    sortable: true,
  },
]

const Specialities = () => {
  toast.configure()
  const [editShow, setEditShow] = useState(false) //edit modal
  const [addShow, setAddShow] = useState(false) //add modal
  const [dataToEdit, setDataToEdit] = useState({})
  const [loading, setLoading] = useState(true)
  const [specialities, setSpecialities] = useState([])
  const ExpandedComponent = ({ data }) => {
    return (
      <div className="expanded-div py-5 px-5">
        <Button
          variant="success"
          onClick={() => {
            setEditShow(!editShow)
            setDataToEdit(data)
          }}
        >
          <i className="fa fa-pencil"></i> Edit
        </Button>
        <Button
          variant="danger"
          className="ml-5"
          onClick={() => deleteSpecialty(data.id)}
        >
          <i className="fa fa-trash"></i> Delete
        </Button>
      </div>
    )
  }

  //   Initial values for add
  const Add_speciality_initial_values = {
    name: '',
  }

  // initial values for edit
  //   Initial values for add
  const Edit_speciality_initialValues = {
    name: dataToEdit?.name,
  }

  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  const user = useSelector(selectUser)
  const specialitiesUrl = `${process.env.REACT_APP_MAIN_URL}/api/specialty/?size=30`

  useEffect(() => {
    fetch(specialitiesUrl, {
      signal: signal,
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false)
        if (res.error) {
          return toast.warning('An error occured while fetching specialities', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          })
        }
        let count = 1
        res.data.items.forEach((item) => {
          item.count = count
          count++
          //->will delete the rest later
        })

        setSpecialities(res.data.items)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        })
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  const addHC = (values, resetForm) => {
    const url = `${process.env.REACT_APP_MAIN_URL}/api/specialty/register`
    const dataToSend = {
      data: [
        {
          name: values.name,
        },
      ],
    }
    // send request
    fetch(url, {
      signal: signal,
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          })
        }
        // console.log(JSON.stringify(dataToSend));
        resetForm()
        setAddShow(!addShow)
        //adjust state_

        const newSpecialitiesData = [...specialities]
        dataToSend.data[0].id = res?.data[0].id
        newSpecialitiesData.unshift(dataToSend.data[0])
        let count = 1
        newSpecialitiesData.forEach((item) => {
          item.count = count
          //   item.active = item.active === true ? "Active" : "Inactive";
          count++
        })
        setSpecialities(newSpecialitiesData)

        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        })
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        })
      })
  }

  const editHC = (values) => {
    const url = `${process.env.REACT_APP_MAIN_URL}/api/specialty/${dataToEdit?.id}/update`
    const dataToSend = {
      name: values.name,
    }
    // send request
    fetch(url, {
      signal: signal,
      method: 'PUT',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          })
        }
        setEditShow(!editShow)
        //adjust state
        dataToSend.id = dataToEdit.id

        const newSpecialitiesData = [...specialities]

        const index = specialities.findIndex(
          (item) => item.id === dataToEdit.id,
        )
        newSpecialitiesData.splice(index, 1, dataToSend)
        let count = 1
        newSpecialitiesData.forEach((item) => {
          item.count = count
          count++
        })
        setSpecialities(newSpecialitiesData)

        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        })
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        })
      })
  }

  const deleteSpecialty = (id) => {
    const url = `${process.env.REACT_APP_MAIN_URL}/api/specialty/${id}/delete`
    if (window.confirm('Are you sure you want to delete this specialty?')) {
      // send request
      fetch(url, {
        signal: signal,
        method: 'DELETE',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: 'Bearer ' + user.token,
          'Content-Type': 'application/json',
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            return toast.warning(res.error, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: false
            })
          }

          //adjust state
          const newSpecialitiesData = specialities.filter(
            (item) => item.id !== id,
          )

          setSpecialities(newSpecialitiesData)

          return toast.success(res.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          })
        })
        .catch((err) => {
          console.log(err)
          return toast.warning('An error occured while reaching the server', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          })
        })
    }
  }
  return (
    <div id="main-wrapper">
      {/***********************************
    Nav header start
************************************/}
      {/* <NavHeader/> */}
      {/***********************************
    Nav header end
************************************/}

      {/***********************************
    Header start
************************************/}
      <Header />
      {/***********************************
    Header end ti-comment-alt
************************************/}
      {/***********************************
    Sidebar start
************************************/}
      <LeftBar />
      {/***********************************
    Sidebar end
************************************/}
      {/***********************************
    Content body start
************************************/}
      <div className="content-body">
        {loading ? (
          <Preloader />
        ) : (
          <Row>
            <Col md={12}>
              <Button
                variant="info"
                className="ml-3"
                onClick={() => setAddShow(!addShow)}
              >
                <i className="fa fa-plus"></i> Add
              </Button>
            </Col>
            <Col md={12}>
              <DataTable
                columns={columns}
                data={specialities}
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                pagination
              />
            </Col>
          </Row>
        )}
      </div>
      {/***********************************
    Content body end
************************************/}

      {/***********************************
    Footer start
************************************/}
      <Footer />
      {/***********************************
    Footer end
************************************/}

      {/***********************************
    Edit Modal start
************************************/}
      <Modal
        size="lg"
        show={editShow}
        onHide={() => setEditShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {dataToEdit?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={Edit_speciality_initialValues}
            validationSchema={AddSpecialitySchema}
            onSubmit={(values) => {
              editHC(values)
            }}
            enableReinitialize
          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik
              return (
                <Form className="row">
                  <div className="col-md-6">
                    <Field name="name">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1" htmlFor="name">
                              <strong>Speciality Name</strong>
                            </label>
                            <input
                              type="text"
                              id="name"
                              placeholder="Enter name.."
                              autoComplete="name"
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.name && touched.name
                                  ? 'form-control input-error text-danger'
                                  : 'form-control'
                              }
                            />
                            <ErrorMessage
                              name="name"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        )
                      }}
                    </Field>
                  </div>

                  <div className=" col-md-8 text-center mt-4">
                    <Button
                      disabled={!(dirty && isValid)}
                      type="submit"
                      className="btn-block"
                      variant="warning"
                    >
                      Update
                    </Button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
      {/***********************************
    Edit Modal end
************************************/}
      {/***********************************
    Add Modal start
************************************/}
      <Modal
        size="lg"
        show={addShow}
        onHide={() => setAddShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add speciality
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={Add_speciality_initial_values}
            validationSchema={AddSpecialitySchema}
            onSubmit={(values, { resetForm }) => {
              addHC(values, resetForm)
            }}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik
              return (
                <Form className="row">
                  <div className="col-md-6">
                    <Field name="name">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1" htmlFor="name">
                              <strong>Speciality Name</strong>
                            </label>
                            <input
                              type="text"
                              id="name"
                              placeholder="Enter name.."
                              autoComplete="name"
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.name && touched.name
                                  ? 'form-control input-error text-danger'
                                  : 'form-control'
                              }
                            />
                            <ErrorMessage
                              name="name"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        )
                      }}
                    </Field>
                  </div>

                  <div className=" col-md-8 text-center mt-4">
                    <Button
                      disabled={!(dirty && isValid)}
                      type="submit"
                      className="btn-block"
                      variant="primary"
                    >
                      Add
                    </Button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
      {/***********************************
    Add Modal end
************************************/}
    </div>
  )
}

export default Specialities
