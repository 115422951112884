import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import LeftBar from '../components/LeftBar'
import Patient from '../components/Patient'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'
// import "../foreigncss/Profile.css";

const PatientProfile = () => {
  const location = useLocation()
  let navigate = useNavigate()
  toast.configure()
  const user = useSelector(selectUser)
  // console.log(location)
  const [patients, setPatients] = useState()

  const fetchPatient = (patient_id) => {
    const patientUrl = `${process.env.REACT_APP_MAIN_URL}/api/patient/${patient_id}`
    fetch(patientUrl, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        if (res.error) {
          return toast.warning(
            'An error occured while fetching patient details',
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: false
            },
          )
        }

        setPatients(res.data)
      })
      .catch((err) => {
        console.log(err, user.token)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        })
      })
  }
  useEffect(() => {
    if (location.state?.fromEvent) {
      fetchPatient(location.state?.fromEvent)
    }
    return () => {
      const p = { ...patients }
      console.log(location.state?.fromEvent)
    }
  }, [])

  return (
    <div id="main-wrapper">
      {/***********************************
        Nav header start
    ************************************/}
      {/* <NavHeader/> */}
      {/***********************************
        Nav header end
    ************************************/}

      {/***********************************
        Header start
    ************************************/}
      <Header />
      {/***********************************
        Header end ti-comment-alt
    ************************************/}
      {/***********************************
        Sidebar start
    ************************************/}
      <LeftBar />
      {/***********************************
        Sidebar end
    ************************************/}
      {/***********************************
        Content body start
    ************************************/}
      {location.state?.fromEvent ? (
        <Patient notPatient={patients} fromId={location?.state?.fromEvent} />
      ) : (
        <Patient notPatient={location.state} />
      )}

      {/***********************************
        Content body end
    ************************************/}

      {/***********************************
        Footer start
    ************************************/}
      <Footer />
      {/***********************************
        Footer end
    ************************************/}
    </div>
  )
}

export default PatientProfile
