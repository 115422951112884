import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import MainApp from './calendar/MainApp'
import AppointmentActivity from './AppointmentActivity'
import { Table } from 'react-bootstrap'
import PatientAppointments from './PatientAppointments.jsx'
import { formatDate } from '@fullcalendar/react'
import { forceUpdate } from '../constants/Essentials'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { PatientAdddedSchema } from '../constants/ValidationSchema'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useLocation } from 'react-router-dom'

const PatientCompleteProfile = ({ notPatient }) => {
  const location = useLocation()
  const profile = location.state
  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  const user = useSelector(selectUser)
  const [appointments, setAppointments] = useState([])
  const [appointmentsToday, setAppointmentsToday] = useState([])
  const [phoneState, setPhoneState] = useState('')
  const fullName =
    user.role !== 'Patient' ? `${notPatient?.name}` : user.userName

  const patient_id =
    user.role !== 'Patient' ? `${notPatient?.id}` : user.user_id

  const initialValues = {
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    birth_date: '',
    country: '',
    city: '',
    postcode: '',
    county: '',
    address: '',
    contact_method: '',
    next_of_kin: '',
    relationship_kin: '',
    contact_kin: '',
    permission_kin: '',
    marital_status: '',
    living_arrangement: '',
    insurance_provider: '',
    policy_number: '',
    group_number: '',
  }

  // fetch profile details
  const fetchDetailsUrl = `${process.env.REACT_APP_MAIN_URL}/api/patient/${patient_id}`
  useEffect(() => {
    fetch(fetchDetailsUrl, {
      method: 'GET',
      signal: signal,
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, 'somdala')
        // res.data.items.length > 0 &&
        //   res.data.items.forEach((item) => {
        //     item.title =
        //       user.role === "Patient" ? item?.user?.name : item?.patient?.name;
        //     const dt = new Date(item.date);
        //     // item.value = item.id;
        //     // item.start = ;
        //     // const slot = "T"+item.time_slot.substring(0, str.length - 2)
        //     item.start = String(
        //       dt.toISOString().replace(/T.*$/, "") + "T" + item.time_slot
        //     );
        //     // item.start = "2022-02-01T16:38";

        //     //->will delete the rest later
        //   });

        // res.data?.items?.length > 0 && setAppointments(res.data.items);
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])
  return (
    <div className="content-body">
      {/* row */}

      {/* <div className="container-fluid">
        <div className="form-head page-titles d-flex mb-md-4">
          <div className="mr-auto">
            <h2 className="text-black font-w600">Doctor</h2>
          </div>
        </div>
      </div> */}
      <div className="container-fluid pt-0">
        <div className="row">
          <div className="col-lg-8 col-xxl-12">
            <div className="card py-3 px-3">
              <Formik
                initialValues={initialValues}
                validationSchema={PatientAdddedSchema}
                onSubmit={(values, { resetForm }) => {
                  console.log(values, resetForm)
                }}
                enableReinitialize
              >
                {(formik) => {
                  const { errors, touched, isValid, dirty } = formik
                  return (
                    <Form className="row">
                      <div className="col-md-12">
                        <h4>Personal Information</h4>
                      </div>
                      <div className="col-md-4">
                        <Field name="firstName">
                          {(props) => {
                            return (
                              <div className="form-group">
                                <label className="mb-1 " htmlFor="firstName">
                                  <strong>
                                    First Name
                                    <ErrorMessage
                                      name="firstName"
                                      component="span"
                                      className="help-block warning errorMessage"
                                    />
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  id="firstName"
                                  autoComplete="firstName"
                                  value={props.field.value}
                                  onChange={props.field.onChange}
                                  onBlur={props.field.onBlur}
                                  className={
                                    errors.firstName && touched.firstName
                                      ? 'form-control input-error warning'
                                      : 'form-control'
                                  }
                                />
                              </div>
                            )
                          }}
                        </Field>
                      </div>
                      <div className="col-md-4">
                        <Field name="middleName">
                          {(props) => {
                            return (
                              <div className="form-group">
                                <label className="mb-1 " htmlFor="middleName">
                                  <strong>
                                    Middle Name
                                    <ErrorMessage
                                      name="middleName"
                                      component="span"
                                      className="help-block warning errorMessage"
                                    />
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  id="middleName"
                                  autoComplete="middleName"
                                  value={props.field.value}
                                  onChange={props.field.onChange}
                                  onBlur={props.field.onBlur}
                                  className={
                                    errors.middleName && touched.middleName
                                      ? 'form-control input-error warning'
                                      : 'form-control'
                                  }
                                />
                              </div>
                            )
                          }}
                        </Field>
                      </div>
                      <div className="col-md-4">
                        <Field name="lastName">
                          {(props) => {
                            return (
                              <div className="form-group">
                                <label className="mb-1 " htmlFor="lastName">
                                  <strong>
                                    Last Name
                                    <ErrorMessage
                                      name="lastName"
                                      component="span"
                                      className="help-block warning errorMessage"
                                    />
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  id="lastName"
                                  // placeholder="Enter lastName.."
                                  autoComplete="lastName"
                                  value={props.field.value}
                                  onChange={props.field.onChange}
                                  onBlur={props.field.onBlur}
                                  className={
                                    errors.lastName && touched.lastName
                                      ? 'form-control input-error warning'
                                      : 'form-control'
                                  }
                                />
                              </div>
                            )
                          }}
                        </Field>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="mb-1 " htmlFor="phone">
                            <strong>
                              Phone{' '}
                              <ErrorMessage
                                name="phone"
                                component="span"
                                className="help-block warning errorMessage"
                              />
                            </strong>
                          </label>

                          <PhoneInput
                            inputStyle={{ color: 'black', width: '100%' }}
                            id="phone"
                            country={'ke'}
                            value={phoneState}
                            onChange={(e) => setPhoneState(e)}
                            inputClass={
                              errors.phone && touched.phone
                                ? 'form-control input-error warning'
                                : 'form-control'
                            }
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <Field name="gender">
                          {(props) => {
                            return (
                              <div className="form-group">
                                <label className="mb-1 " htmlFor="gender">
                                  <strong>
                                    Gender{' '}
                                    <ErrorMessage
                                      name="gender"
                                      component="span"
                                      className="help-block errorMessage warning"
                                    />
                                  </strong>
                                </label>
                                <select
                                  id="gender"
                                  value={props.field.value}
                                  onChange={props.field.onChange}
                                  onBlur={props.field.onBlur}
                                  className={
                                    errors.gender && touched.gender
                                      ? 'form-control input-error warning'
                                      : 'form-control'
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="male">Male</option>
                                  <option value="female">Female</option>
                                  <option value="other">Other</option>
                                </select>
                              </div>
                            )
                          }}
                        </Field>
                      </div>

                      <div className="col-md-4">
                        <Field name="birth_date">
                          {(props) => {
                            return (
                              <div className="form-group">
                                <label className="mb-1 " htmlFor="birth_date">
                                  <strong>
                                    Date of Birth
                                    <ErrorMessage
                                      name="birth_date"
                                      component="span"
                                      className="help-block errorMessage warning"
                                    />
                                  </strong>
                                </label>
                                <input
                                  type="date"
                                  id="birth_date"
                                  // placeholder="Enter birth_date.."
                                  autoComplete="birth_date"
                                  value={props.field.value}
                                  onChange={props.field.onChange}
                                  onBlur={props.field.onBlur}
                                  className={
                                    errors.birth_date && touched.birth_date
                                      ? 'form-control input-error warning'
                                      : 'form-control'
                                  }
                                />
                              </div>
                            )
                          }}
                        </Field>
                      </div>

                      <div className="col-md-6">
                        <Field name="country">
                          {(props) => {
                            return (
                              <div className="form-group">
                                <label className="mb-1 " htmlFor="country">
                                  <strong>
                                    Country
                                    <ErrorMessage
                                      name="country"
                                      component="span"
                                      className="help-block errorMessage warning"
                                    />
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  id="country"
                                  // placeholder="Enter country.."
                                  autoComplete="country"
                                  value={props.field.value}
                                  onChange={props.field.onChange}
                                  onBlur={props.field.onBlur}
                                  className={
                                    errors.country && touched.country
                                      ? 'form-control input-error warning'
                                      : 'form-control'
                                  }
                                />
                              </div>
                            )
                          }}
                        </Field>
                      </div>

                      <div className="col-md-6">
                        <Field name="city">
                          {(props) => {
                            return (
                              <div className="form-group">
                                <label className="mb-1 " htmlFor="city">
                                  <strong>
                                    City
                                    <ErrorMessage
                                      name="city"
                                      component="span"
                                      className="help-block errorMessage warning"
                                    />
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  id="city"
                                  // placeholder="Enter city.."
                                  autoComplete="city"
                                  value={props.field.value}
                                  onChange={props.field.onChange}
                                  onBlur={props.field.onBlur}
                                  className={
                                    errors.city && touched.city
                                      ? 'form-control input-error warning'
                                      : 'form-control'
                                  }
                                />
                              </div>
                            )
                          }}
                        </Field>
                      </div>

                      <div className="col-md-6">
                        <Field name="postcode">
                          {(props) => {
                            return (
                              <div className="form-group">
                                <label className="mb-1 " htmlFor="postcode">
                                  <strong>
                                    Post Code
                                    <ErrorMessage
                                      name="postcode"
                                      component="span"
                                      className="help-block errorMessage warning"
                                    />
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  id="postcode"
                                  // placeholder="Enter postcode.."
                                  autoComplete="postcode"
                                  value={props.field.value}
                                  onChange={props.field.onChange}
                                  onBlur={props.field.onBlur}
                                  className={
                                    errors.postcode && touched.postcode
                                      ? 'form-control input-error warning'
                                      : 'form-control'
                                  }
                                />
                              </div>
                            )
                          }}
                        </Field>
                      </div>

                      <div className="col-md-6">
                        <Field name="county">
                          {(props) => {
                            return (
                              <div className="form-group">
                                <label className="mb-1 " htmlFor="county">
                                  <strong>
                                    County
                                    <ErrorMessage
                                      name="county"
                                      component="span"
                                      className="help-block errorMessage warning"
                                    />
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  id="county"
                                  // placeholder="Enter county.."
                                  autoComplete="county"
                                  value={props.field.value}
                                  onChange={props.field.onChange}
                                  onBlur={props.field.onBlur}
                                  className={
                                    errors.county && touched.county
                                      ? 'form-control input-error warning'
                                      : 'form-control'
                                  }
                                />
                              </div>
                            )
                          }}
                        </Field>
                      </div>

                      <div className="col-md-12">
                        <Field name="address">
                          {(props) => {
                            return (
                              <div className="form-group">
                                <label className="mb-1 " htmlFor="address">
                                  <strong>
                                    Address
                                    <ErrorMessage
                                      name="address"
                                      component="span"
                                      className="help-block errorMessage warning"
                                    />
                                  </strong>
                                </label>

                                <textarea
                                  className={
                                    errors.address && touched.address
                                      ? 'form-control input-error warning'
                                      : 'form-control'
                                  }
                                  id="address"
                                  // placeholder="Enter address.."
                                  autoComplete="address"
                                  value={props.field.value}
                                  onChange={props.field.onChange}
                                  onBlur={props.field.onBlur}
                                  rows="4"
                                ></textarea>
                              </div>
                            )
                          }}
                        </Field>
                      </div>

                      <div className="col-md-12">
                        <Field name="contact_method">
                          {(props) => {
                            return (
                              <div className="form-group">
                                <label
                                  className="mb-1 "
                                  htmlFor="contact_method"
                                >
                                  <strong>
                                    Preferred Contact Method
                                    <ErrorMessage
                                      name="contact_method"
                                      component="span"
                                      className="help-block errorMessage warning"
                                    />
                                  </strong>
                                </label>

                                <div className="form-group mb-0">
                                  <label className="radio-inline mr-3">
                                    <input
                                      type="radio"
                                      name="optradio"
                                      value="sms"
                                    />{' '}
                                    SMS
                                  </label>
                                  <label className="radio-inline mr-3">
                                    <input
                                      type="radio"
                                      name="optradio"
                                      value="email"
                                    />{' '}
                                    Email
                                  </label>
                                </div>
                              </div>
                            )
                          }}
                        </Field>
                      </div>

                      <div className="col-md-12">
                        <h4>Emergency Contact</h4>
                      </div>

                      <div className="col-md-6">
                        <Field name="next_of_kin">
                          {(props) => {
                            return (
                              <div className="form-group">
                                <label className="mb-1 " htmlFor="next_of_kin">
                                  <strong>
                                    Next of Kin
                                    <ErrorMessage
                                      name="next_of_kin"
                                      component="span"
                                      className="help-block errorMessage warning"
                                    />
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  id="next_of_kin"
                                  // placeholder="Enter next_of_kin.."
                                  autoComplete="next_of_kin"
                                  value={props.field.value}
                                  onChange={props.field.onChange}
                                  onBlur={props.field.onBlur}
                                  className={
                                    errors.next_of_kin && touched.next_of_kin
                                      ? 'form-control input-error warning'
                                      : 'form-control'
                                  }
                                />
                              </div>
                            )
                          }}
                        </Field>
                      </div>

                      <div className="col-md-6">
                        <Field name="relationship_kin">
                          {(props) => {
                            return (
                              <div className="form-group">
                                <label
                                  className="mb-1 "
                                  htmlFor="relationship_kin"
                                >
                                  <strong>
                                    Relationship
                                    <ErrorMessage
                                      name="relationship_kin"
                                      component="span"
                                      className="help-block errorMessage warning"
                                    />
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  id="relationship_kin"
                                  // placeholder="Enter relationship_kin.."
                                  autoComplete="relationship_kin"
                                  value={props.field.value}
                                  onChange={props.field.onChange}
                                  onBlur={props.field.onBlur}
                                  className={
                                    errors.relationship_kin &&
                                    touched.relationship_kin
                                      ? 'form-control input-error warning'
                                      : 'form-control'
                                  }
                                />
                              </div>
                            )
                          }}
                        </Field>
                      </div>

                      <div className="col-md-6">
                        <Field name="contact_kin">
                          {(props) => {
                            return (
                              <div className="form-group">
                                <label className="mb-1 " htmlFor="contact_kin">
                                  <strong>
                                    Contact Number
                                    <ErrorMessage
                                      name="contact_kin"
                                      component="span"
                                      className="help-block errorMessage warning"
                                    />
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  id="contact_kin"
                                  // placeholder="Enter contact_kin.."
                                  autoComplete="contact_kin"
                                  value={props.field.value}
                                  onChange={props.field.onChange}
                                  onBlur={props.field.onBlur}
                                  className={
                                    errors.contact_kin && touched.contact_kin
                                      ? 'form-control input-error warning'
                                      : 'form-control'
                                  }
                                />
                              </div>
                            )
                          }}
                        </Field>
                      </div>

                      <div className="col-md-12">
                        <Field name="permission_kin">
                          {(props) => {
                            return (
                              <div className="form-group">
                                <label
                                  className="mb-1 "
                                  htmlFor="permission_kin"
                                >
                                  <strong>
                                    Do you grant permission for your personal
                                    health information to be shared with your
                                    emergency contact. if required for
                                    treatment?*
                                    <ErrorMessage
                                      name="permission_kin"
                                      component="span"
                                      className="help-block errorMessage warning"
                                    />
                                  </strong>
                                </label>

                                <div className="form-group mb-0">
                                  <label className="radio-inline mr-3">
                                    <input
                                      type="radio"
                                      name="permissions"
                                      value="yes"
                                    />{' '}
                                    Yes
                                  </label>
                                  <label className="radio-inline mr-3">
                                    <input
                                      type="radio"
                                      name="permissions"
                                      value="no"
                                    />{' '}
                                    No
                                  </label>
                                </div>
                              </div>
                            )
                          }}
                        </Field>
                      </div>

                      <div className="col-md-12">
                        <h4>General Information</h4>
                      </div>

                      <div className="col-md-6">
                        <Field name="marital_status">
                          {(props) => {
                            return (
                              <div className="form-group">
                                <label
                                  className="mb-1 "
                                  htmlFor="marital_status"
                                >
                                  <strong>
                                    Marital Status
                                    <ErrorMessage
                                      name="marital_status"
                                      component="span"
                                      className="help-block errorMessage warning"
                                    />
                                  </strong>
                                </label>

                                <select
                                  id="marital_status"
                                  // placeholder="Enter marital_status.."
                                  autoComplete="marital_status"
                                  value={props.field.value}
                                  onChange={props.field.onChange}
                                  onBlur={props.field.onBlur}
                                  className={
                                    errors.marital_status &&
                                    touched.marital_status
                                      ? 'form-control input-error warning'
                                      : 'form-control'
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="single">Single</option>
                                  <option value="single">Married</option>
                                  <option value="divorced">Divorced</option>
                                </select>
                              </div>
                            )
                          }}
                        </Field>
                      </div>

                      <div className="col-md-6">
                        <Field name="living_arrangement">
                          {(props) => {
                            return (
                              <div className="form-group">
                                <label
                                  className="mb-1 "
                                  htmlFor="living_arrangement"
                                >
                                  <strong>
                                    Living Arrangement
                                    <ErrorMessage
                                      name="living_arrangement"
                                      component="span"
                                      className="help-block errorMessage warning"
                                    />
                                  </strong>
                                </label>
                                <select
                                  id="living_arrangement"
                                  autoComplete="living_arrangement"
                                  value={props.field.value}
                                  onChange={props.field.onChange}
                                  onBlur={props.field.onBlur}
                                  className={
                                    errors.living_arrangement &&
                                    touched.living_arrangement
                                      ? 'form-control input-error warning'
                                      : 'form-control'
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="group homes">
                                    Group Homes
                                  </option>
                                  <option value="foster home">
                                    Foster Home
                                  </option>
                                </select>
                              </div>
                            )
                          }}
                        </Field>
                      </div>

                      <div className="col-md-4">
                        <Field name="insurance_provider">
                          {(props) => {
                            return (
                              <div className="form-group">
                                <label
                                  className="mb-1 "
                                  htmlFor="insurance_provider"
                                >
                                  <strong>
                                    Primary Insurance Provider
                                    <ErrorMessage
                                      name="insurance_provider"
                                      component="span"
                                      className="help-block errorMessage warning"
                                    />
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  id="insurance_provider"
                                  // placeholder="Enter insurance_provider.."
                                  autoComplete="insurance_provider"
                                  value={props.field.value}
                                  onChange={props.field.onChange}
                                  onBlur={props.field.onBlur}
                                  className={
                                    errors.insurance_provider &&
                                    touched.insurance_provider
                                      ? 'form-control input-error warning'
                                      : 'form-control'
                                  }
                                />
                              </div>
                            )
                          }}
                        </Field>
                      </div>

                      <div className="col-md-4">
                        <Field name="policy_number">
                          {(props) => {
                            return (
                              <div className="form-group">
                                <label
                                  className="mb-1 "
                                  htmlFor="policy_number"
                                >
                                  <strong>
                                    Policy Number
                                    <ErrorMessage
                                      name="policy_number"
                                      component="span"
                                      className="help-block errorMessage warning"
                                    />
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  id="policy_number"
                                  // placeholder="Enter policy_number.."
                                  autoComplete="policy_number"
                                  value={props.field.value}
                                  onChange={props.field.onChange}
                                  onBlur={props.field.onBlur}
                                  className={
                                    errors.policy_number &&
                                    touched.policy_number
                                      ? 'form-control input-error warning'
                                      : 'form-control'
                                  }
                                />
                              </div>
                            )
                          }}
                        </Field>
                      </div>

                      <div className="col-md-4">
                        <Field name="group_number">
                          {(props) => {
                            return (
                              <div className="form-group">
                                <label className="mb-1 " htmlFor="group_number">
                                  <strong>
                                    Group Number
                                    <ErrorMessage
                                      name="group_number"
                                      component="span"
                                      className="help-block errorMessage warning"
                                    />
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  id="group_number"
                                  // placeholder="Enter group_number.."
                                  autoComplete="group_number"
                                  value={props.field.value}
                                  onChange={props.field.onChange}
                                  onBlur={props.field.onBlur}
                                  className={
                                    errors.group_number && touched.group_number
                                      ? 'form-control input-error warning'
                                      : 'form-control'
                                  }
                                />
                              </div>
                            )
                          }}
                        </Field>
                      </div>

                      <div className="col-md-12 text-center mt-4">
                        <button
                          disabled={!(dirty && isValid)}
                          type="submit"
                          className="btn btn-info"
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>

          <div className="col-lg-4 pl-2">
            <div className="text-center">
              <div className="card noPadBottom">
                <div className="profileCap">
                  <img
                    src="./images/avatar/noProfile.jpg"
                    style={{ borderRadius: '100px' }}
                    width={138}
                    alt=""
                  />

                  <h5 className="profileName">{profile.patient_name}</h5>
                </div>
              </div>

              <div className="col-lg-12">
                <div
                  style={{ paddingTop: '8px', marginBottom: '0.8rem' }}
                  className="row"
                >
                  <div
                    style={{ paddingLeft: '0px', paddingRight: '4px' }}
                    className="col-lg-6"
                  >
                    <div className="card">
                      <div className="someData">
                        <h4 className="detailHead">Gender</h4>
                        <p style={{ color: '#333' }}>
                          {profile.patient_gender}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ paddingRight: '0px', paddingLeft: '4px' }}
                    className=" col-lg-6"
                  >
                    <div className="card">
                      <div className="someData">
                        <h4 className="detailHead">Date of Birth</h4>
                        <p style={{ color: '#333' }}>
                          {formatDate(profile.patient_dob, { locale: 'es' })}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card naModhe">
              <div className="card-body">
                <div className="row sideDetails">
                  <p className="qwerty">
                    <i className="fa-solid fa-phone fa-lg"></i>
                    {profile.patient_phone}
                  </p>

                  <p className="qwerty">
                    <i className="fa-solid fa-envelope fa-lg"></i>
                    {profile.patient_email}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <a href="/ManagePatients">
          <button className="btn btn-light btl-lg shadow"> 
          Manage Patients
          </button> </a> */}

      {/* <MainApp appointments={appointments} today={appointmentsToday} /> */}
      {/* <AppointmentActivity /> */}
    </div>
  )
}

export default PatientCompleteProfile
