import React, { useRef, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { todayDate } from '../constants/Essentials'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../print.css'
// import Pdf from "react-to-pdf";
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

// import { useReactToPrint } from "react-to-print";

const InsuranceInvoice = () => {
  // const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  const inputRef = useRef(null)

  const printDocument = () => {
    html2canvas(inputRef.current).then((canvas) => {
      const imgData = canvas.toDataURL('image/png')
      const pdf = new jsPDF()
      pdf.addImage(imgData, 'JPEG', 0, 0)
      pdf.save('receipt.pdf')
    })
  }

  toast.configure()
  const location = useLocation()
  let navigate = useNavigate()
  const user = useSelector(selectUser)

  const [editShow, setEditShow] = useState(false)

  //states for insurance invoice
  const [description2, setDescription2] = useState('')
  const [price2, setPrice2] = useState('')
  const [quantity2, setQuantity2] = useState('')
  const [amount2, setAmount2] = useState('')

  const [description3, setDescription3] = useState('')
  const [price3, setPrice3] = useState('')
  const [quantity3, setQuantity3] = useState('')
  const [amount3, setAmount3] = useState('')

  const [description4, setDescription4] = useState('')
  const [price4, setPrice4] = useState('')
  const [quantity4, setQuantity4] = useState('')
  const [amount4, setAmount4] = useState('')

  const [description5, setDescription5] = useState('')
  const [price5, setPrice5] = useState('')
  const [quantity5, setQuantity5] = useState('')
  const [amount5, setAmount5] = useState('')

  const [dynamicObj, setDynamicObj] = useState('')

  // function to add parsed int values of amount states
  const addAmount = () => {
    return (
      parseInt(amount2 || 0) +
      parseInt(amount3 || 0) +
      parseInt(amount4 || 0) +
      parseInt(amount5 || 0) +
      parseInt(location.state?.price || 0)
      // parseInt('')
    )
  }

  const compileData = () => {
    const billTo = {
      insurance_company: location.state?.selectedCompany,
      scheme: location.state?.selectedScheme,
      reference_number: location.state?.referenceNumber,
      insurance_number: location.state?.insurance_number,
      insurance_exp_date: location.state?.insurance_exp_date,
      patient_name: location.state?.patient_name,
    }
    const defaultObj = {
      appointment_consultation: 'Appointment Consultation',
      price: location.state?.price,
      quantity: 1,
      amount: location.state?.price,
    }

    const dataToSend = {
      receptionist_id: user.isAdmin && user.user_id,
      appointment_id: location.state.appt_id,
      payment_type: 'insurance', //cash or mpesa
      amount: addAmount(),
    }

    const dynamicObj = {}
    if (description2) {
      dynamicObj['first_row'] = {
        description: description2,
        price: price2,
        quantity: quantity2,
        amount: amount2,
      }
    }
    if (description3) {
      dynamicObj['second_row'] = {
        description: description3,
        price: price3,
        quantity: quantity3,
        amount: amount3,
      }
    }
    if (description4) {
      dynamicObj['third_row'] = {
        description: description4,
        price: price4,
        quantity: quantity4,
        amount: amount4,
      }
    }
    if (description5) {
      dynamicObj['fourth_row'] = {
        description: description5,
        price: price5,
        quantity: quantity5,
        amount: amount5,
      }
    }

    // combine the objects into one
    const finalObj = { defaultObj, dynamicObj, billTo, ...dataToSend }
    console.log(finalObj)
    return finalObj
  }

  const saveInvoice = () => {
    const dataToSend = compileData()
    console.log(dataToSend)
    // return console.log(dataToSend);
    const url = `${process.env.REACT_APP_MAIN_URL}/api/appointment/process-payment`
    // send to payment page here
    // return navigate("/payments", { state: dataToSend });
    // send request
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }
        // window.print()
        console.log(res, dataToSend, user.token)

        // const dataToProceed = {
        //   appt_id: location.state.appt_id,
        //   patient_id: location.state.patient_id,
        // }
        // navigate('/assign-to-nurse', { state: dataToProceed })
        navigate('/admin-dashboard')
        return toast.success('Invoice saved successfully', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
  }
  return (
    <div>
      <Row className="ml-5 mt-5" id="divToPrint" ref={inputRef}>
        <Col md={7} className="printable card">
          <div className="card-body">
            <Row>
              <Col md="6">
                <Row>
                  <Col className="InvoiceText" md="12">
                    INVOICE
                  </Col>
                  <Col md="12">
                    <Table bordered>
                      <thead className="frstTable">
                        <tr className="frstTable">
                          <th>DATE</th>
                          <th>INVOICE NO</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{todayDate()}</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <Row className="text-right">
                  <Col md="12">Demo clinic</Col>
                  <Col md="12">Address</Col>
                  <Col md="12">Nairobi, Kenya</Col>
                  <Col md="12">Phone: (000) 000-0000</Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-3">
              <Table bordered className="text-left">
                <thead>
                  <tr>
                    <th>Bill to</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Insurance company</td>
                    <td>{location.state?.selectedCompany}</td>
                  </tr>
                  <tr>
                    <td>Scheme</td>
                    <td> {location.state?.selectedScheme}</td>
                  </tr>
                  <tr>
                    <td>Reference number</td>
                    <td>{location.state?.referenceNumber}</td>
                  </tr>
                  <tr>
                    <td>Patient</td>
                    <td>{location.state?.patient_name}</td>
                  </tr>
                  <tr>
                    <td>Insurance number</td>
                    <td>{location.state?.insurance_number}</td>
                  </tr>
                  <tr>
                    <td>Expiry</td>
                    <td>{location.state?.insurance_exp_date}</td>
                  </tr>
                </tbody>
              </Table>
            </Row>
            <Row className="mt-3">
              <Table bordered>
                <thead className="frstTable">
                  <tr>
                    <th>No.</th>
                    <th>Description</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1. </td>
                    <td>Appointment consultation</td>
                    <td>{location.state?.price}</td>
                    <td>1</td>
                    <td>{location.state?.price}</td>
                  </tr>
                  <tr>
                    <td>2. </td>
                    <td>
                      {editShow ? (
                        <input
                          type="text"
                          value={description2}
                          onChange={(e) => setDescription2(e.target.value)}
                          className="form-control"
                        />
                      ) : (
                        description2
                      )}
                    </td>
                    <td>
                      {editShow ? (
                        <input
                          type="text"
                          value={price2}
                          onChange={(e) => setPrice2(e.target.value)}
                          className="form-control"
                        />
                      ) : (
                        price2
                      )}
                    </td>
                    <td>
                      {editShow ? (
                        <input
                          type="text"
                          className="form-control"
                          value={quantity2}
                          onChange={(e) => setQuantity2(e.target.value)}
                        />
                      ) : (
                        quantity2
                      )}
                    </td>
                    <td>
                      {editShow ? (
                        <input
                          type="text"
                          className="form-control"
                          value={amount2}
                          onChange={(e) => setAmount2(e.target.value)}
                        />
                      ) : (
                        amount2
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>3. </td>
                    <td>
                      {editShow ? (
                        <input
                          type="text"
                          value={description3}
                          onChange={(e) => setDescription3(e.target.value)}
                          className="form-control"
                        />
                      ) : (
                        description3
                      )}
                    </td>
                    <td>
                      {editShow ? (
                        <input
                          type="text"
                          value={price3}
                          onChange={(e) => setPrice3(e.target.value)}
                          className="form-control"
                        />
                      ) : (
                        price3
                      )}
                    </td>
                    <td>
                      {editShow ? (
                        <input
                          type="text"
                          className="form-control"
                          value={quantity3}
                          onChange={(e) => setQuantity3(e.target.value)}
                        />
                      ) : (
                        quantity3
                      )}
                    </td>
                    <td>
                      {editShow ? (
                        <input
                          type="text"
                          className="form-control"
                          value={amount3}
                          onChange={(e) => setAmount3(e.target.value)}
                        />
                      ) : (
                        amount3
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>4. </td>
                    <td>
                      {editShow ? (
                        <input
                          type="text"
                          value={description4}
                          onChange={(e) => setDescription4(e.target.value)}
                          className="form-control"
                        />
                      ) : (
                        description4
                      )}
                    </td>
                    <td>
                      {editShow ? (
                        <input
                          type="text"
                          value={price4}
                          onChange={(e) => setPrice4(e.target.value)}
                          className="form-control"
                        />
                      ) : (
                        price4
                      )}
                    </td>
                    <td>
                      {editShow ? (
                        <input
                          type="text"
                          className="form-control"
                          value={quantity4}
                          onChange={(e) => setQuantity4(e.target.value)}
                        />
                      ) : (
                        quantity4
                      )}
                    </td>
                    <td>
                      {editShow ? (
                        <input
                          type="text"
                          className="form-control"
                          value={amount4}
                          onChange={(e) => setAmount4(e.target.value)}
                        />
                      ) : (
                        amount4
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>5. </td>
                    <td>
                      {editShow ? (
                        <input
                          type="text"
                          value={description5}
                          onChange={(e) => setDescription5(e.target.value)}
                          className="form-control"
                        />
                      ) : (
                        description5
                      )}
                    </td>
                    <td>
                      {editShow ? (
                        <input
                          type="text"
                          value={price5}
                          onChange={(e) => setPrice5(e.target.value)}
                          className="form-control"
                        />
                      ) : (
                        price5
                      )}
                    </td>
                    <td>
                      {editShow ? (
                        <input
                          type="text"
                          className="form-control"
                          value={quantity5}
                          onChange={(e) => setQuantity5(e.target.value)}
                        />
                      ) : (
                        quantity5
                      )}
                    </td>
                    <td>
                      {editShow ? (
                        <input
                          type="text"
                          className="form-control"
                          value={amount5}
                          onChange={(e) => setAmount5(e.target.value)}
                        />
                      ) : (
                        amount5
                      )}
                    </td>
                  </tr>

                  <tr>
                    <th>Tax </th>
                    <th></th>
                    <th>Discount: </th>
                    <th>Subtotal:</th>
                    <th></th>
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th className="GreenGoblin">Final total:</th>
                    <th className="GreenGoblin">{'Ksh ' + addAmount()}</th>
                  </tr>
                </tbody>
              </Table>
              <div className="mt-2 m-auto ">
                <p className="bottomText">
                  If you have any query about this invoice please contat us
                </p>
                <p className="mt-5">____________________________________</p>
                <p>Signature and seal</p>
              </div>
            </Row>
          </div>
        </Col>
        <Col
          className="not-printable text-center ml-3"
          style={{ marginTop: '300px', position: 'fixed', left: '900px' }}
        >
          <div className="row">
            {!editShow && (
              <>
                <button
                  className="btn btn-dark"
                  onClick={() => navigate(-1)}
                  title="Go back to payments"
                >
                  Go Back
                </button>

                <button
                  className="btn btn-success ml-3"
                  // onClick={printDocument}
                  // onClick={compileData}
                  //
                  onClick={saveInvoice}
                  title="Click here to print"
                >
                  Save Invoice
                </button>
              </>
            )}
          </div>
          <div className="row mt-3">
            <button
              className="btn btn-primary"
              onClick={() => setEditShow(!editShow)}
              title="Add fields to the invoice"
            >
              {!editShow ? 'Add More Fields' : 'Complete Edit Mode'}
            </button>
          </div>

          {/* <Pdf targetRef={ref} filename="invoice.pdf">
        {({ toPdf }) => <button onClick={toPdf} className="btn btn-primary ml-3">Generate Pdf</button>}
      </Pdf> */}
        </Col>
      </Row>
    </div>
  )
}

export default InsuranceInvoice
