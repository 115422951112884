import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import Select from 'react-select'

import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { machinize, trimDate, validate } from '../constants/Essentials'
import { formatDate } from '@fullcalendar/react'
import PatientSymptomsComponent from './PatientSymptoms'
import Swal from 'sweetalert2'
import { Collapse, Form } from 'react-bootstrap'
import {
  selectTests,
  setTestsFromSever,
  updateTests,
} from '../redux_setup/slices/testSlice'
import { ReduxActions } from '../constants/redux_actions'
import moment from 'moment/moment'

const DoctorsAssessmentComponent = () => {
  toast.configure()
  let navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  const user = useSelector(selectUser)
  const testsArray = useSelector(selectTests)

  const [currentTab, setcurrentTab] = useState('physical_assessment')

  // doctor assessment states
  const [assessmentCaseInformation, setAssessmentCaseInformation] = useState('')
  const [assessmentTests, setAssessmentTests] = useState('')
  const [assessmentDiagnosis, setAssessmentDiagnosis] = useState('')
  const [assessmentPrescription, setAssessmentPrescription] = useState('')
  const [additionalNotes, setAdditionalNotes] = useState('')
  const [consultationId, setConsultationId] = useState('')
  const [alreadyPrefilled, setAlreadyPrefilled] = useState(false)
  const [serverLoadedTests, setServerLoadedTests] = useState(false)
  const patient = location.state

  const consultationUrl = `${process.env.REACT_APP_MAIN_URL}/api/general_consultation/appointment?appointment_id=${patient.appt_id}`

  useEffect(() => {
    fetch(consultationUrl, {
      signal: signal,
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        if (res.error) {
          return toast.warning('An error occured while fetching history', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }
        const response = res.data
        // setCurrentRes(response[0])
        if (response) {
          setAlreadyPrefilled(true)
          setAssessmentCaseInformation(response?.case_info)
          // setAssessmentTests(response?.tests_to_be_done)
          let tests_to_be_done = JSON.parse(response?.tests_to_be_done) || {
            tests: [],
          }
          //prescription
          let prescription = JSON.parse(response?.prescription) || {
            rx: [],
          }

          //dispatch to redux the tests to be done
          dispatch(setTestsFromSever(tests_to_be_done?.tests))
          console.log(tests_to_be_done?.tests)
          if (tests_to_be_done?.tests?.length > 0) {
            setServerLoadedTests(true)
          }
          // console.log()

          setAssessmentDiagnosis(response?.diagnosis)
          setAssessmentPrescription(prescription?.rx)
          setAdditionalNotes(response?.extra_notes)
          setConsultationId(response?.id)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  const changeTestState = (name) => {
    dispatch(
      updateTests({
        val: name,
        action_type: ReduxActions.expand_test_option,
      }),
    )
  }
  const adjustSelectedChildren = (motherObject, child) => {
    dispatch(
      updateTests({
        motherObject,
        child,
        action_type: ReduxActions.change_child_option,
      }),
    )
  }
  //listen to chronic case information
  const listenToChronicChange = () => {
    Swal.fire({
      title: 'You selected a chronic case information. ',
      text: 'Are you sure you want to continue to chronic care management?',
      showCancelButton: true,
      icon: 'info',
      width: '600',
      confirmButtonText: 'Proceed',
      cancelButtonText: 'Undo Selection',
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log('Booked Follow Up!', '', 'success')

        navigate('/hypertension', {
          state: {
            patient_id: patient.patient_id,
            name: patient.patient_name,
            dob: patient.patient_birth_date,
            gender: patient.patient_gender,
          },
        })
      } else {
        setAssessmentCaseInformation('')
      }
    })
  }
  //save tests
  const saveTests = () => {
    const toSend = {
      appointment_id: patient?.appt_id,
      patient_id: patient?.patient_id,
      tests_to_be_done: {
        tests: testsArray,
        date: moment().format('YYYY-MM-DD'),
        timeRequested: moment().format('h:mm:ss a'),
        doctor: user?.userName,
      },
    }
    // return console.log(dataToSend);
    const url = `${process.env.REACT_APP_MAIN_URL}/api/general_consultation/labtest`
    // send to payment page here
    // return navigate("/payments", { state: dataToSend });
    // send request
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(toSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }

        toast.success('Lab tests request saved successfully', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })

        return navigate('/requested-test', {
          state: {
            patient_id: patient?.patient_id,
            appt_id: patient?.appt_id,
            name: patient?.patient_name,
            gender: patient?.patient_gender?.toUpperCase(),
            age: patient?.patient_birth_date,
            phone: patient?.patient_phone,
            email: patient?.patient_email,
            tests: testsArray,
            timeRequested: toSend.tests_to_be_done.timeRequested,
          },
        })
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
  }

  // handle doctor assesment button
  const submitAssessment = () => {
    const submitUrl = alreadyPrefilled
      ? `${process.env.REACT_APP_MAIN_URL}/api/general_consultation/${consultationId}`
      : `${process.env.REACT_APP_MAIN_URL}/api/general_consultation`
    const dataToSend = {
      appointment_id: patient.appt_id,
      patient_id: patient.patient_id,
      case_info: assessmentCaseInformation,

      diagnosis: assessmentDiagnosis,
    }

    const validation = validate(dataToSend)

    console.log(validate(dataToSend), dataToSend)
    if (validation.isValidated) {
      dataToSend.extra_notes = additionalNotes
      // send request
      fetch(submitUrl, {
        method: alreadyPrefilled ? 'PUT' : 'POST',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: 'Bearer ' + user.token,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(dataToSend),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            return toast.warning(res.error, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 15000,
            })
          }
          setAlreadyPrefilled(true)

          // navigate('/')

          // return toast.success(
          //   res.message + ' You have completed consultation with this patient',
          //   {
          //     position: toast.POSITION.TOP_CENTER,
          //   },
          // )

          Swal.fire({
            title: 'Diagnosis saved successfully',
            showCancelButton: true,
            icon: 'success',
            width: '600',
            confirmButtonText: 'Go to prescription',
            cancelButtonText: 'Back To Dashboard',
          }).then((result) => {
            if (result.isConfirmed) {
              // console.log('Booked Follow Up!', '', 'success')
              navigate('/doctor-prescription', {
                state: {
                  patientDetails: {
                    name: patient?.patient_name,
                    appt_id: patient?.appt_id,
                    gender: patient?.patient_gender?.toUpperCase(),
                    age: patient?.patient_birth_date,
                    phone: patient?.patient_phone,
                    email: patient?.patient_email,
                  },
                  rx: assessmentPrescription,
                },
              })

              // navigate('/book-follow-up', {
              //   state: {
              //     app_id: patient.appt_id,
              //     patient_id: patient.patient_id,
              //     doctor_id: user.user_id,
              //     health_center: user.hc_id,
              //   },
              // })
            } else if (result.isDenied) {
              // navigate('/instruct-nurse', {
              //   state: {
              //     appt_id: patient.appt_id,
              //   },
              // })
            } else {
              console.log('cancelled')
              navigate('/')
            }
          })
        })
        .catch((err) => {
          console.log(err, dataToSend, consultationId)
          return toast.warning('An error occured while reaching the server', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        })
    } else {
      toast.warning(validation.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 10000,
      })
    }
  }

  useEffect(() => {
    if (assessmentCaseInformation === 'chronic') {
      listenToChronicChange()
    }
  }, [assessmentCaseInformation])

  return (
    <div className="content-body">
      {/* row */}
      <PatientSymptomsComponent dataToProcess={patient} />

      <div className="container-fluid pt-0">
        <div className="row">
          <div className="col-lg-12 col-xxl-12">
            <div className="card ">
              <div className="card-header">
                <h4 className="card-title">Assess patient</h4>
              </div>
              <div className="card-body text-left">
                <div className="cardess ">
                  <div className="card-body text-left">
                    <div className="form-group mb-2">
                      <label className="text-label">Case information</label>
                      <div className="row pl-3 border-top">
                        <label className="radio-inline mr-3">
                          <input
                            type="radio"
                            name="optradio"
                            value="acute"
                            checked={assessmentCaseInformation === 'acute'}
                            onChange={(e) =>
                              setAssessmentCaseInformation(e.target.value)
                            }
                            className="mr-1"
                          />
                          Acute
                        </label>

                        <label className="radio-inline mr-3">
                          <input
                            type="radio"
                            name="optradio"
                            value="chronic"
                            checked={assessmentCaseInformation === 'chronic'}
                            onChange={(e) =>
                              setAssessmentCaseInformation(e.target.value)
                            }
                            className="mr-1"
                          />
                          Chronic
                        </label>
                        <label className="radio-inline mr-3">
                          <input
                            type="radio"
                            name="optradio"
                            value="pre-existing"
                            checked={
                              assessmentCaseInformation === 'pre-existing'
                            }
                            onChange={(e) =>
                              setAssessmentCaseInformation(e.target.value)
                            }
                            className="mr-1"
                          />
                          Pre-existing
                        </label>
                        <label className="radio-inline mr-3">
                          <input
                            type="radio"
                            name="optradio"
                            value="injury"
                            checked={assessmentCaseInformation === 'injury'}
                            onChange={(e) =>
                              setAssessmentCaseInformation(e.target.value)
                            }
                            className="mr-1"
                          />
                          Injury
                        </label>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="text-label">Tests</label>

                      <div className="row border-top">
                        {testsArray.map((item, index) => (
                          <div className="col-md-6 col-sm-12" key={index}>
                            <Form.Check
                              label={item.name}
                              name={machinize(item.name)}
                              id={`${machinize(item.name)}-${index}`}
                              key={`${machinize(item.name)}-${index}`}
                              onChange={(e) => {
                                changeTestState(item.name)
                              }}
                              checked={item.expanded}
                              aria-controls="example-collapse-text"
                              aria-expanded={item.expanded}
                            />
                            {item.children?.length > 0 && item.expanded ? (
                              <Collapse
                                in={item.expanded}
                                style={{ marginLeft: 40 }}
                              >
                                <div
                                  id={`child${machinize(item.name)}-${index}`}
                                >
                                  {item.children.map((child, index) => (
                                    <Form.Check
                                      label={child}
                                      key={`${item.name}-${child}-${index}`}
                                      id={`${item.name}-${child}-${index}`}
                                      onChange={() =>
                                        adjustSelectedChildren(item, child)
                                      }
                                      checked={item.selectedChildren.includes(
                                        child,
                                      )}
                                    />
                                  ))}
                                </div>
                              </Collapse>
                            ) : (
                              <></>
                            )}
                          </div>
                        ))}
                      </div>
                      <div className="row">
                        {/* <Link
                          to="/requested-test"
                          state={{
                            patient_id: patient?.patient_id,
                            appt_id: patient?.appt_id,
                            name: patient?.patient_name,
                            gender: patient?.patient_gender?.toUpperCase(),
                            age: patient?.patient_birth_date,
                            phone: patient?.patient_phone,
                            email: patient?.patient_email,
                          }}
                        > */}
                        <button
                          className="btn btn-primary btn-sm ml-3 mt-5"
                          onClick={saveTests}
                        >
                          {serverLoadedTests ? 'Update Tests' : 'Save Tests'}
                        </button>
                        {/* </Link> */}
                      </div>
                    </div>

                    {/* end tets */}
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label className="text-label" htmlFor="diagnosis">
                          Diagnosis
                        </label>
                        <textarea
                          class="form-control"
                          id="diagnosis"
                          rows="3"
                          value={assessmentDiagnosis}
                          onChange={(e) =>
                            setAssessmentDiagnosis(e.target.value)
                          }
                        ></textarea>
                      </div>

                      <div className="form-group col-md-6">
                        <label className="text-label" htmlFor="prescription">
                          Additional notes
                        </label>
                        <textarea
                          class="form-control"
                          id="prescription"
                          rows="3"
                          value={additionalNotes}
                          onChange={(e) => setAdditionalNotes(e.target.value)}
                        ></textarea>
                      </div>
                    </div>

                    <div className="form-group">
                      {/* <button
                        className="btn btn-dark mr-2"
                        onClick={() => navigate('/')}
                      >
                        Back to dashboard
                      </button> */}
                      <button
                        className="btn btn-success mr-2"
                        onClick={submitAssessment}
                      >
                        Save Diagnosis
                      </button>
                      {/* <button
                        className="btn btn-primary"
                        onClick={() => {
                          console.log(patient)
                          navigate('/doctor-prescription', {
                            state: {
                              patientDetails: {
                                name: patient?.patient_name,
                                appt_id: patient?.appt_id,
                                gender: patient?.patient_gender?.toUpperCase(),
                                age: patient?.patient_birth_date,
                                phone: patient?.patient_phone,
                                email: patient?.patient_email,
                              },
                              rx: assessmentPrescription,
                            },
                          })
                        }}
                      >
                        {assessmentPrescription?.length < 1
                          ? 'Write Prescription'
                          : 'Update Prescription'}
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <a href="/ManagePatients">
          <button className="btn btn-light btl-lg shadow"> 
          Manage Patients
          </button> </a> */}

      {/* <MainApp appointments={appointments} today={appointmentsToday} /> */}
      {/* <AppointmentActivity /> */}
    </div>
  )
}

export default DoctorsAssessmentComponent
