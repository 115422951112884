import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SemiCircleProgressBar from 'react-progressbar-semicircle'
import HypertensionMenu from './HypertensionMenu'
import Form from 'react-bootstrap/Form'
import Switch from 'react-switch'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useDispatch, useSelector } from 'react-redux'
import {
  populateLifeStyleReview,
  selectLifestyleReview,
} from '../../redux_setup/slices/hypertensionSlice'
import { ReduxActions } from '../../constants/redux_actions'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { selectUser } from '../../redux_setup/slices/userSlice'
import { Row } from 'react-bootstrap'
import { humanize } from '../../constants/Essentials'
import { updateStep } from '../../redux_setup/slices/stepSlice'
import Counter from './Counter'

const activities = [
  'Walking',
  'Running',
  'Jogging',
  'Yoga',
  'Dancing',
  'Cycling',
  'Swimming',
  'Hiking',
  'Skipping',
  'Gym',
  'Other',
]
const switchColorCompute = (level) => {
  let color = 'white'
  let handle = 'white'
  switch (level) {
    case 'High':
      color = '#ff80ad'
      handle = '#ff005c'
      break

    case 'Medium':
      color = '#fff9e5'
      handle = '#ffc000'
      break

    case 'Low':
      color = '#e6f7fb'
      handle = '#07b0d7'
      break

    default:
      color = 'white'
      handle = '#ff005c'
      break
  }
  return { color, handle }
}

const LifestyleReview = ({ patientDetails }) => {
  const dispatch = useDispatch()
  const lifestylereviewstate = useSelector(selectLifestyleReview)
  const user = useSelector(selectUser)
  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  toast.configure()

  const [mealsChecked, setMealsChecked] = useState('Medium')
  const [appetiteChecked, setAppetiteChecked] = useState('Medium')
  const [riskLevelChecked, setRiskLevelChecked] = useState('Medium')

  const [activeMin, setActiveMin] = useState({ id: null, status: false })
  const [activeDays, setActiveDays] = useState({ id: null, status: false })

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)

  useEffect(() => {
    console.log(lifestylereviewstate)
  }, [])

  const changeSingleProperty = (data, property) => {
    dispatch(
      populateLifeStyleReview({
        data,
        property,
        action_type: ReduxActions.add_property,
      }),
    )
  }

  const changeInnerProperty = (data, property, inner_property) => {
    dispatch(
      populateLifeStyleReview({
        data,
        inner_property,
        property,
        action_type: ReduxActions.add_property_in_property,
      }),
    )
  }

  const handleSubmit = () => {
    // console.log(JSON.stringify(lifestylereviewstate))
    //are we gonna post or patch?
    let dataToSend

    if (lifestylereviewstate?.id) {
      dataToSend = { ...lifestylereviewstate }
      delete dataToSend.created_at
      delete dataToSend.updated_at
      delete dataToSend.patient_id
    } else {
      dataToSend = {
        ...lifestylereviewstate,
        patient_id: patientDetails.patient_id,
      }
    }

    // return console.log(
    //   lifestylereviewstate,
    //   dataToSend,
    //   JSON.stringify(dataToSend),
    // )

    fetch(
      `${process.env.REACT_APP_MAIN_URL_HYPERTENSION}/api/lifestyle_review`,
      {
        method: lifestylereviewstate?.id ? 'PATCH' : 'POST', //if you can find an id in lifestylereviewstate, then update
        headers: new Headers({
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(dataToSend),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          })
        }
        dispatch(updateStep('labs'))
        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        })

        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error has occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        })
      })
  }
  useEffect(() => {
    console.log(patientDetails)
    fetch(
      `${process.env.REACT_APP_MAIN_URL_HYPERTENSION}/api/lifestyle_review/${patientDetails.patient_id}`,
      {
        signal: signal,
        method: 'GET',
        headers: new Headers({
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return console.log('lifestyle review fetching did not succeed')
        }
        if (res.data.length <= 0) {
          //reset redux statepiece
          dispatch(
            populateLifeStyleReview({
              action_type: ReduxActions.reset_to_default_value,
            }),
          )
        } else {
          dispatch(
            populateLifeStyleReview({
              data: res.data[0],
              action_type: ReduxActions.add_initial_data_from_server,
            }),
          )
        }

        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err)
      })

    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  return (
    <div className="margin__container">
      <h6 className="margin__left vital__subtopic">Lifestyle review</h6>
      <HypertensionMenu />
      <div>
        {/* First Segment */}
        <div className="card">
          <div className="card-body text-left">
            <h4>Activity Notes and level</h4>
            <textarea
              className="form-control"
              placeholder="Input Notes"
              id="exampleFormControlTextarea1"
              rows={6}
              value={lifestylereviewstate.activity_notes}
              onChange={(e) =>
                changeSingleProperty(e.target.value, 'activity_notes')
              }
            />
            <div className="LowerLimit">
              <select
                class="form-control form-control-sm"
                value={lifestylereviewstate.activity_level}
                onChange={(e) =>
                  changeSingleProperty(e.target.value, 'activity_level')
                }
              >
                <option value="N/A">Choose</option>
                <option value="Sedentary">Sedentary</option>
                <option value="Moderate">Moderate</option>
                <option value="Active">Active</option>
              </select>
            </div>
          </div>
        </div>

        {/* Third Segment */}
        <div className="card">
          <div className="card-body text-left">
            <h4>Activities</h4>
            <p>Exercise Type</p>

            <Row className="mb-3">
              {activities.map((activity, index) => (
                <div className="col-2 exception" key={index}>
                  <Form.Check
                    label={activity}
                    type="checkbox"
                    checked={
                      lifestylereviewstate.activity_level_activities[activity]
                    }
                    onChange={(e) =>
                      changeInnerProperty(
                        e.target.checked,
                        'activity_level_activities',
                        activity,
                      )
                    }
                    id={`inline-checkbox-${index}`}
                  />
                </div>
              ))}
            </Row>

            <div>
              <div className="flex__container" style={{ gap: 325 }}>
                <p>
                  <strong style={{ color: '#191919' }}>Min/Day</strong>
                </p>
                <p>
                  <strong style={{ color: '#191919' }}>Days/Week</strong>
                </p>
              </div>
              <div className="flex__container" style={{ gap: 150 }}>
                <div className="flex__container" style={{ gap: 0 }}>
                  {[0, 15, 30, 45, 60].map((el, index) => (
                    <div
                      className="flex__container"
                      style={{ gap: 0 }}
                      key={index}
                    >
                      <div
                        className="circle"
                        style={{
                          backgroundColor:
                            activeMin.id === index && activeMin.status
                              ? '#662382'
                              : '',
                        }}
                        onClick={() =>
                          setActiveMin({ id: index, status: true })
                        }
                      >
                        <small>{index === 0 ? index : el + 'min'}</small>
                      </div>
                      {el < 60 ? (
                        <div
                          className="line"
                          style={{
                            backgroundColor:
                              activeMin.id === index && activeMin.status
                                ? '#662382'
                                : '',
                          }}
                        ></div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </div>
                <div className="flex__container" style={{ gap: 0 }}>
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((el, index) => (
                    <div
                      className="flex__container"
                      style={{ gap: 0 }}
                      key={index}
                    >
                      <div
                        className="circle"
                        style={{
                          backgroundColor:
                            activeDays.id === index && activeDays.status
                              ? '#45b757'
                              : '',
                        }}
                        onClick={() =>
                          setActiveDays({ id: index, status: true })
                        }
                      >
                        <small>
                          {index === 0
                            ? index
                            : index === 1
                            ? index + 'day'
                            : index + 'days'}
                        </small>
                      </div>
                      {el < 8 ? (
                        <div
                          className="line"
                          style={{
                            backgroundColor:
                              activeDays.id === index && activeDays.status
                                ? '#45b757'
                                : '',
                          }}
                        ></div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Fourth Segment */}

        <div className="card">
          <div className="card-body text-left">
            <div className="row">
              <div className="col-6">
                <h3>Energy Level</h3>
                <div className="row">
                </div>
                <Counter 
                leftPosition={lifestylereviewstate.energy_level}
                times={27}
                data={lifestylereviewstate.energy_level}
                />
                <div>
                <Form.Range
                  value={lifestylereviewstate.energy_level}
                  onChange={(e) =>
                    changeSingleProperty(e.target.value, 'energy_level')
                  }
                  min={1}
                  max={10}
                />
                </div>
              </div>
              <div className="col-6">
                <h3>Fatigue Level</h3>
                <div className="row">
                  {/* <div className="col-sm-4 form-groupt">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      style={{ marginBottom: 3 }}
                      value={lifestylereviewstate.fatigue_level}
                      onChange={(e) =>
                        changeSingleProperty(e.target.value, 'fatigue_level')
                      }
                      min={1}
                      max={10}
                    />
                  </div>
                </div>
                <div> */}
                <Counter
                leftPosition={lifestylereviewstate.fatigue_level}
                times={27}
                data={lifestylereviewstate.fatigue_level}
                />
                  <Form.Range
                    value={lifestylereviewstate.fatigue_level}
                    onChange={(e) =>
                      changeSingleProperty(e.target.value, 'fatigue_level')
                    }
                    min={1}
                    max={10}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Fifth Segment */}

        <div className="card">
          <div className="card-body text-left">
            <h3>Nutrition</h3>
            <div>
              <textarea
                className="form-control"
                placeholder="Input Notes"
                id="exampleFormControlTextarea1"
                rows={6}
                value={lifestylereviewstate.nutrition_notes}
                onChange={(e) =>
                  changeSingleProperty(e.target.value, 'nutrition_notes')
                }
              />

              <p className="needspace">Estimated calorie intake</p>
              <div className="flex__container" style={{ gap: 20 }}>
                {['Low', 'Medium', 'High'].map((level, index) => (
                  <div className="flex__container">
                    <label style={{ marginTop: 5, fontWeight: 700 }}>
                      {level}
                    </label>
                    <div className={`switch${index + 1}`} key={index}>
                      <Switch
                        key={index}
                        onChange={(e) => {
                          changeSingleProperty(
                            level,
                            'nutrition_calories_intake',
                          )
                          // console.log(e)
                          setMealsChecked(level)
                        }}
                        checked={mealsChecked === level}
                        value={level}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onHandleColor={switchColorCompute(level).handle}
                        offHandleColor={switchColorCompute(level).handle}
                        onColor={switchColorCompute(level).color}
                        offColor={switchColorCompute(level).color}
                        width={80}
                      />
                      {mealsChecked === level ? (
                        <span
                          className="span__switch1"
                          //   onClick={() => setMealSwitch1(false)}
                        >
                          {level}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <h3 className="needspace">Meals</h3>
              <Row className="mb-3">
                {['Breakfast', 'Lunch', 'Dinner'].map((meal, index) => (
                  <div className="col-2 exception" key={index}>
                    <Form.Check
                      label={meal}
                      type="checkbox"
                      checked={lifestylereviewstate.nutrition_meals[meal]}
                      onChange={(e) =>
                        changeInnerProperty(
                          e.target.checked,
                          'nutrition_meals',
                          meal,
                        )
                      }
                      id={`inline-meals-${index}`}
                    />
                  </div>
                ))}
              </Row>

              <div className="row">
                <div className="col-4">
                  <h5>Meal Frequency</h5>
                  <p>Daily</p>
                  {['radio'].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        inline
                        label="Yes"
                        onChange={(e) =>
                          changeSingleProperty(
                            'daily',
                            'nutrition_meals_frequency',
                          )
                        }
                        name="mealfqncy"
                        type={type}
                        id={`inline-${type}-1`}
                        checked={
                          lifestylereviewstate.nutrition_meals_frequency ===
                          'daily'
                        }
                      />
                      <Form.Check
                        inline
                        label="No"
                        onChange={(e) =>
                          changeSingleProperty(
                            e.target.value,
                            'nutrition_meals_frequency',
                          )
                        }
                        name="mealfqncy"
                        type={type}
                        id={`inline-${type}-2`}
                        checked={
                          lifestylereviewstate.nutrition_meals_frequency !==
                          'daily'
                        }
                      />
                    </div>
                  ))}
                </div>
                <div className="col-4">
                  <h5>Meal type</h5>
                  <textarea
                    className="form-control"
                    placeholder="Input Notes"
                    id="meal_type"
                    rows={4}
                    value={lifestylereviewstate.nutrition_meal_type}
                    onChange={(e) =>
                      changeSingleProperty(
                        e.target.value,
                        'nutrition_meal_type',
                      )
                    }
                  />
                </div>
                <div className="col-4">
                  <h5>Appetite</h5>

                  <div className="flex__container" style={{ gap: 10 }}>
                    {['Low', 'Medium', 'High'].map((level, index) => (
                      <div className="flex__container">
                        <label style={{ marginTop: 5, fontWeight: 700 }}>
                          {level}
                        </label>
                        <div className={`switch${index + 1}`} key={index}>
                          <Switch
                            key={index}
                            onChange={(e) => {
                              changeSingleProperty(level, 'nutrition_appetite')
                              // console.log(e)
                              setAppetiteChecked(level)
                            }}
                            checked={appetiteChecked === level}
                            value={level}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onHandleColor={switchColorCompute(level).handle}
                            offHandleColor={switchColorCompute(level).handle}
                            onColor={switchColorCompute(level).color}
                            offColor={switchColorCompute(level).color}
                            width={75}
                          />
                          {appetiteChecked === level ? (
                            <span
                              className="span__switch1"
                              //   onClick={() => setMealSwitch1(false)}
                            >
                              {level}
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 6th Segment */}

        <div className="card">
          <div className="card-body text-left">
            <div>
              <h3>Habits</h3>
              <textarea
                className="form-control"
                placeholder="Input Notes"
                id="exampleFormControlTextarea1"
                rows={4}
              />
            </div>
            <div>
              <h5 className="margin__top">Risk Level</h5>
              <div className="flex__container margin__top" style={{ gap: 20 }}>
                {['High', 'Medium', 'Low'].map((level, index) => (
                  <div className="flex__container">
                    <label style={{ marginTop: 5, fontWeight: 700 }}>
                      {level}
                    </label>
                    <div className={`switch${index + 1}`} key={index}>
                      <Switch
                        key={index}
                        onChange={(e) => {
                          changeSingleProperty(level, 'habits_risk_level')
                          // console.log(e)
                          setRiskLevelChecked(level)
                        }}
                        checked={riskLevelChecked === level}
                        value={level}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onHandleColor={switchColorCompute(level).handle}
                        offHandleColor={switchColorCompute(level).handle}
                        onColor={switchColorCompute(level).color}
                        offColor={switchColorCompute(level).color}
                        width={80}
                      />
                      {riskLevelChecked === level ? (
                        <span
                          className="span__switch1"
                          //   onClick={() => setMealSwitch1(false)}
                        >
                          {level}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <p style={{ fontWeight: '500', marginTop: 30, marginBottom: 30 }}>
                Alcohol Intake
              </p>
              {/* Semi circles */}
              <div className="semi__circle">
                <div className="flex__container" style={{ gap: 70 }}>
                  <SemiCircleProgressBar
                    percentage={lifestylereviewstate.habits_alcohol_pints_day}
                    strokeWidth={20}
                    stroke="#c2e297"
                  />
                  <span className="span span__first__semi__circle ml-0 mb-3">
                    <Row>
                      <div className="col-sm-1 ">
                        <input
                          type="number"
                          value={lifestylereviewstate.habits_alcohol_pints_day}
                          onChange={(e) =>
                            changeSingleProperty(
                              e.target.value,
                              'habits_alcohol_pints_day',
                            )
                          }
                          className="form-control"
                        />
                      </div>
                    </Row>
                  </span>
                  <small className="small1__first__semi__circle">
                    Pints/day
                  </small>
                  <small className="small2__first__semi__circle">
                    1 pint = 300ml
                  </small>
                  <SemiCircleProgressBar
                    percentage={lifestylereviewstate.habits_alcohol_glasses_day}
                    strokeWidth={20}
                    stroke="#7dc4e4"
                  />
                  <span className="span span__second__semi__circle mb-3">
                    <Row>
                      <div className="col-sm-1">
                        <input
                          type="number"
                          value={
                            lifestylereviewstate.habits_alcohol_glasses_day
                          }
                          onChange={(e) =>
                            changeSingleProperty(
                              e.target.value,
                              'habits_alcohol_glasses_day',
                            )
                          }
                          className="form-control"
                        />
                      </div>
                    </Row>
                  </span>
                  <small className="small1__second__semi__circle">
                    Wine glasses/day
                  </small>
                  <small className="small2__second__semi__circle">
                    1 wine glass = 120ml
                  </small>
                  <SemiCircleProgressBar
                    percentage={lifestylereviewstate.habits_alcohol_totes_day}
                    strokeWidth={20}
                    stroke="#c8b4d0"
                  />
                  <span className="span span__third__semi__circle mb-3">
                    <Row>
                      <div className="col-sm-1">
                        <input
                          type="number"
                          value={lifestylereviewstate.habits_alcohol_totes_day}
                          onChange={(e) =>
                            changeSingleProperty(
                              e.target.value,
                              'habits_alcohol_totes_day',
                            )
                          }
                          className="form-control"
                        />
                      </div>
                    </Row>
                  </span>
                  <small className="small1__third__semi__circle">
                    Totes/ day
                  </small>
                  <small className="small2__third__semi__circle">
                    1tote = 30ml
                  </small>
                </div>
              </div>
            </div>

            <div>
              <p style={{ fontWeight: '500', marginTop: 30, marginBottom: 30 }}>
                Smoking
              </p>
              <div className="bar">
                <SemiCircleProgressBar
                  percentage={lifestylereviewstate.habits_cigarrettes_day}
                  strokeWidth={20}
                  stroke="#7dc4e4"
                />
                <span className="span span__fourth__semi__circle mb-3">
                  <Row>
                    <div className="col-sm-5">
                      <input
                        type="number"
                        value={lifestylereviewstate.habits_cigarrettes_day}
                        onChange={(e) =>
                          changeSingleProperty(
                            e.target.value,
                            'habits_cigarrettes_day',
                          )
                        }
                        className="form-control"
                      />
                    </div>
                  </Row>
                </span>
                <small className="small__fourth__semi__circle">
                  cigarette per day
                </small>
              </div>
            </div>

            <div>
              <p style={{ fontWeight: '500', marginTop: 30, marginBottom: 30 }}>
                Recreational drugs
              </p>
              {/* {['radio'].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    inline
                    label="Yes"
                    name="drugs"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                  <Form.Check
                    inline
                    label="No"
                    name="drugs"
                    type={type}
                    id={`inline-${type}-2`}
                  />
                </div>
              ))} */}

              <h6>Frequency</h6>
              <div className="needspace LowerLimit">
                <select
                  class="form-control form-control-sm"
                  value={lifestylereviewstate.habits_recreational_drugs_freq}
                  onChange={(e) =>
                    changeSingleProperty(
                      e.target.value,
                      'habits_recreational_drugs_freq',
                    )
                  }
                >
                  <option value="0">Choose</option>
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                </select>
              </div>
            </div>
            <textarea
              className="form-control"
              placeholder="Recreational drugs Notes"
              id="exampleFormControlTextarea1"
              rows={4}
              value={lifestylereviewstate.habits_recreational_drugs_notes}
              onChange={(e) =>
                changeSingleProperty(
                  e.target.value,
                  'habits_recreational_drugs_notes',
                )
              }
            />
          </div>
        </div>

        {/* 7th Segment */}

        <div className="card">
          <div className="card-body text-left">
            <h3>Sexual Function</h3>
            <Row className="mb-3">
              {['Normal', 'erectile_disfunction', 'pregnancy'].map(
                (func, index) => (
                  <div className="col-3 exception" key={index}>
                    <Form.Check
                      inline
                      label={humanize(func)}
                      type="checkbox"
                      checked={lifestylereviewstate.sexual_function[func]}
                      onChange={(e) =>
                        changeInnerProperty(
                          e.target.checked,
                          'sexual_function',
                          func,
                        )
                      }
                      id={`inline-sexualfunction-${index}`}
                    />
                  </div>
                ),
              )}
            </Row>
            <div className="row">
              <div className="col-6">
                <textarea
                  className="form-control"
                  placeholder="Input Notes"
                  id="exampleFormControlTextarea1"
                  rows={8}
                  value={lifestylereviewstate.sexual_function_notes}
                  onChange={(e) =>
                    changeSingleProperty(
                      e.target.value,
                      'sexual_function_notes',
                    )
                  }
                />
              </div>
              <div className="col-6">
                <h5>Nitro-Oxide drugs</h5>
                {['radio'].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="Yes"
                      name="Oxdrug"
                      onChange={(e) =>
                        changeSingleProperty(1, 'sexual_function_nitrooxide')
                      }
                      type={type}
                      id={`inline-${type}-28`}
                      checked={
                        lifestylereviewstate.sexual_function_nitrooxide === 1
                      }
                    />
                    <Form.Check
                      inline
                      label="No"
                      name="Oxdrug"
                      onChange={(e) =>
                        changeSingleProperty(0, 'sexual_function_nitrooxide')
                      }
                      type={type}
                      id={`inline-${type}-28`}
                      checked={
                        lifestylereviewstate.sexual_function_nitrooxide === 0
                      }
                    />
                  </div>
                ))}
                <textarea
                  className="form-control"
                  placeholder="Input Notes"
                  id="exampleFormControlTextarea1"
                  rows={5}
                  value={lifestylereviewstate.sexual_function_nitrooxide_notes}
                  onChange={(e) =>
                    changeSingleProperty(
                      e.target.value,
                      'sexual_function_nitrooxide_notes',
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {/* 8TH */}

        <div className="card">
          <div className="card-body text-left">
            <h3>Mood</h3>
            <textarea
              className="form-control"
              placeholder="Input Notes"
              id="exampleFormControlTextarea1"
              rows={5}
              value={lifestylereviewstate.mood_notes}
              onChange={(e) =>
                changeSingleProperty(e.target.value, 'mood_notes')
              }
            />

            <div className="needspace">
              <h5 className="margin__top">Risk level</h5>
              <div className="needspace LowerLimit">
                <select
                  class="form-control form-control-sm"
                  value={lifestylereviewstate.mood_risk_level}
                  onChange={(e) =>
                    changeSingleProperty(e.target.value, 'mood_risk_level')
                  }
                >
                  <option value="">Choose</option>
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <strong className="margin__top">Anxiety</strong>
                {['radio'].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="Yes"
                      name="Anxiety"
                      type={type}
                      id={`inline-${type}-28`}
                      onChange={(e) => changeSingleProperty(1, 'mood_anxiety')}
                      checked={lifestylereviewstate.mood_anxiety === 1}
                    />
                    <Form.Check
                      inline
                      label="No"
                      name="Anxiety"
                      type={type}
                      id={`inline-${type}-281`}
                      className="margin__top"
                      onChange={(e) => changeSingleProperty(0, 'mood_anxiety')}
                      checked={lifestylereviewstate.mood_anxiety === 0}
                    />
                  </div>
                ))}
              </div>
              <div className="col-3">
                <strong className="margin__top">Depression</strong>
                {['radio'].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="Yes"
                      name="Depression"
                      type={type}
                      id={`inline-${type}-29`}
                      onChange={(e) =>
                        changeSingleProperty(1, 'mood_depression')
                      }
                      checked={lifestylereviewstate.mood_depression === 1}
                    />
                    <Form.Check
                      inline
                      label="No"
                      name="Depression"
                      type={type}
                      id={`inline-${type}-30`}
                      className="margin__top"
                      onChange={(e) =>
                        changeSingleProperty(0, 'mood_depression')
                      }
                      checked={lifestylereviewstate.mood_depression === 0}
                    />
                  </div>
                ))}
              </div>
              <div className="col-3">
                <strong className="margin__top">Irrational Fears</strong>
                {['radio'].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="Yes"
                      name="IrrationalFears"
                      type={type}
                      id={`inline-${type}-31`}
                      onChange={(e) =>
                        changeSingleProperty(1, 'mood_irrational_fears')
                      }
                      checked={lifestylereviewstate.mood_irrational_fears === 1}
                    />
                    <Form.Check
                      inline
                      label="No"
                      name="IrrationalFears"
                      type={type}
                      id={`inline-${type}-32`}
                      className="margin__top"
                      onChange={(e) =>
                        changeSingleProperty(0, 'mood_irrational_fears')
                      }
                      checked={lifestylereviewstate.mood_irrational_fears === 0}
                    />
                  </div>
                ))}
              </div>
              <div className="col-3">
                <strong className="margin__top">Sleep problems</strong>

                <div className="mb-3">
                  <textarea
                    className="form-control"
                    placeholder="If yes, input types of sleep problems"
                    id="exampleFormControlTextarea1"
                    rows={3}
                    value={lifestylereviewstate.mood_sleep_problems}
                    onChange={(e) =>
                      changeSingleProperty(
                        e.target.value,
                        'mood_sleep_problems',
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Another Segment */}
        <div className="card">
          <div className="card-body text-left">
            <h3> Social & Environment</h3>
            <textarea
              className="form-control"
              placeholder="Input Notes"
              id="exampleFormControlTextarea1"
              rows={5}
              value={lifestylereviewstate.se_notes}
              onChange={(e) => changeSingleProperty(e.target.value, 'se_notes')}
            />

            <div className="needspace">
              <h5>Risk Level</h5>
              <div className="needspace LowerLimit">
                <select
                  class="form-control form-control-sm"
                  value={lifestylereviewstate.se_risk_level}
                  onChange={(e) =>
                    changeSingleProperty(e.target.value, 'se_risk_level')
                  }
                >
                  <option value="">Select</option>
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </select>
                <h5 className="margin__top">Hobbies & interests</h5>

                <div className="mb-3">
                  <textarea
                    className="form-control margin__top"
                    placeholder="List hobbies"
                    id="hoobie_texte"
                    rows={3}
                    value={lifestylereviewstate.se_hobbies_text}
                    onChange={(e) =>
                      changeSingleProperty(e.target.value, 'se_hobbies_text')
                    }
                  />
                </div>
                <h5 className="margin__top">Career/ job</h5>
                <select
                  class="form-control form-control-sm"
                  style={{ marginBottom: 20 }}
                  value={lifestylereviewstate.se_career}
                  onChange={(e) =>
                    changeSingleProperty(e.target.value, 'se_career')
                  }
                >
                  <option value="">Select</option>
                  <option>Office job</option>
                  <option>Casual work</option>
                  <option>Work from home</option>
                </select>
                <strong className="margin__top">Social activities</strong>
                <br />
                <Form.Check
                  inline
                  label="Yes"
                  name="socialActivities"
                  id="social_activities_1"
                  type="radio"
                  className="margin__top"
                  onChange={(e) =>
                    changeSingleProperty(1, 'se_social_activities')
                  }
                  checked={lifestylereviewstate.se_social_activities === 1}
                />
                <Form.Check
                  inline
                  label="No"
                  name="socialActivities"
                  type="radio"
                  id="social_activities_2"
                  style={{ marginBottom: 20 }}
                  onChange={(e) =>
                    changeSingleProperty(0, 'se_social_activities')
                  }
                  checked={lifestylereviewstate.se_social_activities === 0}
                />
                <br />
                <strong className="margin__top">Care & support system</strong>
                <br />
                <textarea
                  className="form-control margin__top"
                  placeholder="Input text"
                  id="localserviceaccess"
                  rows={3}
                  value={lifestylereviewstate.se_care_support}
                  onChange={(e) =>
                    changeSingleProperty(e.target.value, 'se_care_support')
                  }
                />

                <br />
                <strong className="margin__top">
                  Financial/ legal concerns
                </strong>
                <br />
                <Form.Check
                  inline
                  label="Yes"
                  name="legalConcerns"
                  type="radio"
                  id="legal_concern_yes"
                  className="margin__top"
                  onChange={(e) =>
                    changeSingleProperty(1, 'se_financial_legal_concern')
                  }
                  checked={
                    lifestylereviewstate.se_financial_legal_concern === 1
                  }
                />
                <Form.Check
                  inline
                  label="No"
                  name="legalConcerns"
                  type="radio"
                  id="legal_concern_no"
                  style={{ marginBottom: 20 }}
                  onChange={(e) =>
                    changeSingleProperty(0, 'se_financial_legal_concern')
                  }
                  checked={
                    lifestylereviewstate.se_financial_legal_concern === 0
                  }
                />
                <br />
                <strong className="margin__top">Abuse</strong>
                <br />
                <Form.Check
                  inline
                  label="Yes"
                  name="abuse"
                  type="radio"
                  id="se_abise_yes"
                  className="margin__top"
                  onChange={(e) => changeSingleProperty(1, 'se_abuse')}
                  checked={lifestylereviewstate.se_abuse === 1}
                />
                <Form.Check
                  inline
                  label="No"
                  name="abuse"
                  type="radio"
                  id="se_abuse_no"
                  style={{ marginBottom: 20 }}
                  onChange={(e) => changeSingleProperty(0, 'se_abuse')}
                  checked={lifestylereviewstate.se_abuse === 0}
                />
                <br />
                <strong className="margin__top">Home comfort & safety</strong>
                <br />
                <Form.Check
                  inline
                  label="Yes"
                  name="safety"
                  type="radio"
                  id="se_home_comfort_1"
                  className="margin__top"
                  onChange={(e) => changeSingleProperty(1, 'se_home_comfort')}
                  checked={lifestylereviewstate.se_home_comfort === 1}
                />
                <Form.Check
                  inline
                  label="No"
                  name="safety"
                  type="radio"
                  id="se_home_comfort_0"
                  style={{ marginBottom: 20 }}
                  onChange={(e) => changeSingleProperty(0, 'se_home_comfort')}
                  checked={lifestylereviewstate.se_home_comfort === 0}
                />
                <br />
                <strong className="margin__top">Access Local Services</strong>
                <br />

                <textarea
                  className="form-control margin__top"
                  placeholder="Input text"
                  id="localserviceaccess"
                  rows={3}
                  value={lifestylereviewstate.se_local_services_access}
                  onChange={(e) =>
                    changeSingleProperty(
                      e.target.value,
                      'se_local_services_access',
                    )
                  }
                />
                <br />
                <strong className="margin__top">
                  Eligibility for formal support
                </strong>
                <br />
                <Form.Check
                  inline
                  label="Yes"
                  name="CareSupport"
                  id="caresupport_1"
                  type="radio"
                  className="margin__top"
                  onChange={(e) => changeSingleProperty(1, 'se_formal_support')}
                  checked={lifestylereviewstate.se_formal_support === 1}
                />
                <Form.Check
                  inline
                  label="No"
                  name="CareSupport"
                  type="radio"
                  id="caresupport2"
                  style={{ marginBottom: 20 }}
                  onChange={(e) => changeSingleProperty(0, 'se_formal_support')}
                  checked={lifestylereviewstate.se_formal_support === 0}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ textAlign: 'right' }}>
          <button className="btn btn-success hypemit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
      {/* Modal */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enter percentage number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control type="number" placeholder="Enter percentage" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default LifestyleReview
