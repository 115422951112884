import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const MentalHealth = ({ data }) => {
  return (
    <div className="card pl-2 pr-2 pb-5">
      <p className="meal-profile-text" style={{ color: '#000', fontSize: 20 }}>
        Mental Health Care Plan
      </p>
      <div
        className="card pl-4 pr-4 careplan-title"
        style={{
          background: 'rgba(171, 179, 253, 0.25)',
          height: '100%',
          width: '85%',
        }}
      >
        <p
          className="meal-profile-text pr-2"
          style={{
            color: '#000',
            fontWeight: 700,
            fontSize: 20,
            fontFamily: 'Lato',
            whiteSpace: 'normal',
          }}
        >
          Suggested Outcome: {data?.mental_target_desc}
        </p>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-sm-12">
          {/* <div className="d-flex flex-warp" style={{ gap: 20 }}>
            <div
              className="card pl-2 pt-2 pr-2"
              style={{ backgroundColor: '#6623823D', width: '50%' }}
            >
              <div
                className="card pl-2 pt-2"
                style={{
                  width: '100%',
                  background: 'rgba(102, 35, 130, 0.24)',
                }}
              >
                <p className="mobo-card-text">Daily Smoking Level</p>
                <span className="show-on-mobile">sticks/day</span>
              </div>
              <div
                className="d-flex flex-wrap justify-content-between"
                style={{ marginTop: -20 }}
              >
                <div>
                  <small
                    style={{
                      fontSize: 10,
                      fontWeight: 400,
                      fontFamily: 'Lato',
                      color: '#0D5CA4',
                    }}
                    className="ml-3 mt-2"
                  >
                    Current
                  </small>
                  <h4 className="ml-3 mt-2">
                    24{' '}
                    <small
                      className="dont-show-on-mobile"
                      style={{ fontSize: 14 }}
                    >
                      sticks/day
                    </small>
                  </h4>
                </div>
                <div>
                  <small
                    style={{
                      fontSize: 10,
                      fontWeight: 400,
                      fontFamily: 'Lato',
                      color: '#0D5CA4',
                    }}
                    className="ml-3 mt-2"
                  >
                    Target
                  </small>
                  <h4 className="ml-3 mt-2">
                    0{' '}
                    <small
                      className="dont-show-on-mobile"
                      style={{ fontSize: 14 }}
                    >
                      sticks/day
                    </small>
                  </h4>
                </div>
              </div>
            </div>
            <div
              className="card pl-2 pt-2 pr-2"
              style={{ background: 'rgba(7, 176, 215, 0.24)', width: '50%' }}
            >
              <div
                className="card pl-2 pt-2"
                style={{ width: '100%', backgroundColor: '#07B0D73D' }}
              >
                <p className="mobo-card-text">Daily Drinking Level</p>
                <span className="show-on-mobile">Alcohol/day</span>
              </div>
              <div
                className="d-flex flex-wrap justify-content-between"
                style={{ marginTop: -20 }}
              >
                <div>
                  <small
                    style={{
                      fontSize: 10,
                      fontWeight: 400,
                      fontFamily: 'Lato',
                      color: '#0D5CA4',
                    }}
                    className="ml-3 mt-2"
                  >
                    Current
                  </small>
                  <h4 className="ml-3 mt-2">
                    76{' '}
                    <small
                      className="dont-show-on-mobile"
                      style={{ fontSize: 14 }}
                    >
                      Alcohol/day
                    </small>
                  </h4>
                </div>
                <div>
                  <small
                    style={{
                      fontSize: 10,
                      fontWeight: 400,
                      fontFamily: 'Lato',
                      color: '#0D5CA4',
                    }}
                    className="ml-3 mt-2"
                  >
                    Target
                  </small>
                  <h4 className="ml-3 mt-2">
                    0{' '}
                    <small
                      className="dont-show-on-mobile"
                      style={{ fontSize: 14 }}
                    >
                      Alcohol/day
                    </small>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div
            className="mb-3 mr-5 mt-1 hr"
            style={{
              height: 2,
              backgroundColor: '#818181',
              marginTop: -10,
              width: '97%',
            }}
          /> */}
          <div className="d-flex flex-warp" style={{ gap: 20 }}>
            <div
              className="card pl-2 pt-2 pr-2"
              style={{ background: 'rgba(208, 224, 255, 0.5)' }}
            >
              <div
                className="card pl-2 pt-2"
                style={{ width: 282, background: '#6623823D' }}
              >
                <p className="mobo-card-text">Daily Stress Level</p>
                <span className="show-on-mobile">(Daily)</span>
              </div>
              <div
                className="d-flex flex-wrap justify-content-between"
                style={{ marginTop: -20 }}
              >
                <div>
                  <small
                    style={{
                      fontSize: 10,
                      fontWeight: 400,
                      fontFamily: 'Lato',
                      color: '#0D5CA4',
                    }}
                    className="ml-3 mt-2"
                  >
                    Current
                  </small>
                  <h4 className="ml-3 mt-2 d-flex" style={{ gap: 15 }}>
                    0
                    <small className="mt-1" style={{ fontSize: 14 }}>
                      <span>
                        {data?.mental_target_mood < 14
                          ? '😄'
                          : data?.mental_target_mood < 27
                          ? '🙂'
                          : '😒'}
                      </span>
                    </small>
                  </h4>
                </div>
                <div>
                  <small
                    style={{
                      fontSize: 10,
                      fontWeight: 400,
                      fontFamily: 'Lato',
                      color: '#0D5CA4',
                    }}
                    className="ml-3 mt-2"
                  >
                    Target
                  </small>
                  <h4 className="ml-3 mt-2 d-flex" style={{ gap: 15 }}>
                    {data?.mental_target_mood || 0}
                    <small className="mt-1" style={{ fontSize: 14 }}>
                      <span>
                        {data?.mental_target_mood < 14
                          ? '😄'
                          : data?.mental_target_mood < 27
                          ? '🙂'
                          : '😒'}
                      </span>
                    </small>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <Link to="/mental-health-careplan">
            <Button
              style={{
                backgroundColor: '#11195A',
                width: '97%',
                height: '20%',
              }}
              className="mb-2"
            >
              Update Mental Health Care Plan
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default MentalHealth
