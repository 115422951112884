import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import { selectReceptionistFilters } from '../redux_setup/slices/receptionistSlice'
import { tConv24, titleSentenceCase, todayDate } from '../constants/Essentials'
import { formatDate } from '@fullcalendar/react'
import { Link, useNavigate } from 'react-router-dom'

const NurseAssignment = () => {
  let navigate = useNavigate()
  const abortcontroller = new AbortController()
  const user = useSelector(selectUser)
  const filters = useSelector(selectReceptionistFilters)
  const [patientsAssigned, setPatientsAssigned] = useState([])

  useEffect(() => {
    const today = todayDate()
    const url = `${process.env.REACT_APP_MAIN_URL}/api/appointment/nurse-assigned?nurse_id=${user.user_id}`
    fetch(url, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, 'resa')
        setPatientsAssigned(res.data.items)
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  return (
    <div className="card roundediv">
      <div className="card-header">Patients assigned to me</div>
      <div className="card-body">
        <Table variant="hover">
          <thead>
            <tr style={{ textAlign: 'left' }}>
              <th>Patient</th>
              <th>Doctor</th>
              <th>Date</th>
              <th>Time</th>
              <th>Triage</th>
            </tr>
          </thead>
          <tbody>
            {/* {patientsAssigned ? ( */}
            {/* {1 == 2 ? ( */}
            {patientsAssigned.length > 0 ? (
              patientsAssigned.map((patient, index) => {
                const dataToProceed = {}

                return (
                  <tr className="text-left" key={index}>
                    <td>{titleSentenceCase(patient?.patient?.name)}</td>
                    <td>{titleSentenceCase(patient?.doctorId?.name)}</td>
                    <td>{patient?.updatedAt}</td>
                    <td>{tConv24(patient?.time_slot)}</td>
                    <td>
                      {patient?.symptom?.triage_started === 2 && (
                        <>
                          <i
                            className="fa fa-check-circle text-success"
                            title="Completed"
                          ></i>{' '}
                          Completed
                        </>
                      )}

                      {patient?.symptom?.triage_started !== 2 && (
                        <button
                          className={`btn btn-sm ${
                            patient?.symptom?.triage_started === 0 ||
                            !patient?.symptom?.triage_started
                              ? 'btn-dark'
                              : 'btn-info'
                          }`}
                          title="Click here to add or confirm symptoms"
                          onClick={() =>
                            navigate('/patient-symptoms', {
                              state: {
                                patient_id: patient?.patient_id,
                                appt_id: patient?.id,
                              },
                            })
                          }
                        >
                          {patient?.symptom?.triage_started === 1
                            ? 'Back To Triage'
                            : 'Go To Triage'}
                        </button>
                      )}
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <p>No patients assigned today</p>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default NurseAssignment
