import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Form, Button } from 'react-bootstrap'
import { selectPatientData } from '../../redux_setup/slices/patientDataSlice'
// import { backgroundImage } from 'html2canvas/dist/types/css/property-descriptors/background-image'

const PasswordSetup = () => {
  const patient = useSelector(selectPatientData)
  const [dataToSend, setDataToSend] = useState(patient)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPasswordRules, setShowPasswordRules] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)

  toast.configure()
  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  const navigate = useNavigate()
  const validate = () => {
    let obj = { message: '', isValidated: true }
    if (dataToSend.password === '') {
      obj.message = 'new Password is needed to signup'
      obj.isValidated = false
    }
    if (confirmPassword === '') {
      obj.message = 'Confirm your password'
      obj.isValidated = false
    }
    return obj
  }
  const url = `${process.env.REACT_APP_MAIN_URL}/api/patient/${patient?.id}/update`
  const handleSubmit = () => {
    const valid = validate()
    if (!valid.isValidated) {
      return toast.warning(valid.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      })
    }
    // if(dataToSend.password === '')

    const { password } = dataToSend
    if (password !== confirmPassword) {
      return toast.warning('Password do not match', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      })
    }
    setIsDisabled(true)
    // send request
    fetch(url, {
      signal: signal,
      method: 'PUT',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ password: password, is_verified: 1 }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          setIsDisabled(false)
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: true,
          })
        }
        navigate('/password-successful')
        setIsDisabled(false)
        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true,
        })
      })
      .catch((err) => {
        setIsDisabled(false)
        console.log(err)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true,
        })
      })
  }
  return (
    <div>
      <div
        className="signup-container"
        style={{
          background:
            'linear-gradient(180deg, rgba(69, 183, 87, 0.6) 0%, rgba(47, 165, 219, 0.6) 100%)',
        }}
      >
        <div style={{ marginLeft: '0' }} className="content-body">
          <div
            // style={{ width: "50%", marginTop: "10%", margin: "auto" }}
            className="card card pt-5 pb-3 signup-card"
          >
            <div className="d-flex justify-content-center align-items-center">
              <p
                style={{
                  fontSize: 24,
                  fontWeight: 400,
                  fontFamily: 'Lato',
                  color: '#19226D',
                  marginBottom: -20,
                }}
              >
                Welcome To
              </p>
            </div>
            <div className="text-center" style={{ width: '100%' }}>
              <div className="filipelogos" style={{ marginBottom: -20 }}></div>
              <p
                style={{
                  fontSize: 24,
                  fontWeight: 400,
                  fontFamily: 'Lato',
                  color: '#19226D',
                }}
              >
                Patients Care Plan
              </p>
            </div>
            <div style={{ marginLeft: '20%', marginRight: '20%' }}>
              <p
                style={{
                  fontSize: 20,
                  fontWeight: 700,
                  fontFamily: 'Lato',
                  color: '#000',
                }}
              >
                password Setup
              </p>
              <div className="row">
                <div
                  className="col-md-6 col-lg-6 col-sm-12"
                  style={{ marginRight: -20 }}
                >
                  <label>Password</label>
                  <Form.Control
                    type="password"
                    placeholder="type password"
                    onKeyUp={() => setShowPasswordRules(true)}
                    onBlur={() => setShowPasswordRules(false)}
                    onChange={(e) =>
                      setDataToSend({ ...dataToSend, password: e.target.value })
                    }
                  />
                  {showPasswordRules ? (
                    <span style={{ color: 'red', fontSize: 13 }}>
                      Password should be atleast:
                      <br />
                      8 characters long
                      <br />
                      Contains capital letter <br />
                      Contains special character
                      <br />
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
                <div
                  className="col-md-6 col-lg-6 col-sm-12"
                  style={{ marginRight: -20 }}
                >
                  <label>Confirm Password</label>
                  <Form.Control
                    type="password"
                    placeholder="Re-enter password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </div>
              {/* <Link to='/password-successful'> */}
              <div className="d-flex">
                <Link to="/care-plan-signup-next-page">
                  <Button
                    className="mt-4"
                    variant="success"
                    style={{ width: 171, height: 40 }}
                  >
                    <i className="fas fa-arrow-left mr-2" />
                    Back
                  </Button>
                </Link>
                <Button
                  className="mt-4 ml-2"
                  variant="success"
                  style={{ width: 171, height: 40, whiteSpace: 'nowrap' }}
                  onClick={handleSubmit}
                  disabled={isDisabled}
                >
                  Sign me up
                </Button>
              </div>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>

      {/***********************************
            Footer start
        ************************************/}
      {/***********************************
            Footer end
        ************************************/}
    </div>
  )
}

export default PasswordSetup
