import { useState } from "react";
import Footer from "../../Footer";
import LeftBar from "../../LeftBar";
import Header from "../../Header";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Counter from "../hypertension/Counter";
const NewHabitBreakerCarePlan = () => {
  const [habits, setHabits] = useState([])
  return (
    <div className='main-wrapper'>
      {/***********************************
                  Header start
                  ************************************/}
      <Header />
      {/***********************************
                  Header end ti-comment-alt
                  ************************************/}
      {/***********************************
                  Sidebar start
                  ************************************/}
      <LeftBar />
      {/***********************************
                  Sidebar end
                  ************************************/}
      {/***********************************
                  Content body start
                  ************************************/}

      {/***********************************
                  Content body end
                  ************************************/}
      <div className='content-body'>
        <p
          className='mt-3 mb-3'
          style={{
            fontSize: 20,
            fontFamily: "Lato",
            fontWeight: 700,
            color: "#000",
          }}
        >
          Habit Breaker Care Plan
        </p>
        <div className='row ml-1'>
          <div
            className=' col-md-7 col-lg-7 col-sm-12 d-flex flex-wrap rounded mr-3'
            style={{ backgroundColor: "#fff", padding: 20, borderRadius: 8 }}
          >
            <div className='col-md-12'>
              <p className='meal-profile-text mt-3' style={{ color: "#000" }}>
                Habit Type
              </p>
              <div className='d-flex flex-wrap justify-content-between'>
                {["Drinking", "Smoking", "Substances"].map((habit) => (
                  <div>
                    <Form.Check
                      label={habit}
                      id={`habit-${habit}`}
                      type='checkbox'
                      onChange={(e) => {
                        if (e.target.checked) {
                          setHabits([...habits, habit]);
                        } else {
                          const index = habits.indexOf(habit);
                          habits.splice(index, 1);
                          setHabits([...habits]);
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            {habits?.map((habit) => (
              <div className='row'>
                <div className='col-md-3 col-lg-3 col-sm-6 col-xs-6'>
                  {habit === "Drinking" ? (
                    <>
                      <label>Alcohol Type</label>
                      <Form.Select className='pl-2'>
                        <option>select</option>
                        <option>Beer/Lager</option>
                        <option>Wine</option>
                        <option>Spirits</option>
                      </Form.Select>
                    </>
                  ) : habit === "Smoking" ? (
                    <>
                      <label>Smoking Type</label>
                      <Form.Select className='pl-2'>
                        <option>select</option>
                        <option>Cigarette</option>
                        <option>Vape</option>
                      </Form.Select>
                    </>
                  ) : (
                    <>
                      <label>Substance Type</label>
                      <Form.Select className='pl-2'>
                        <option>select</option>
                        <option>Cannabis</option>
                        <option>Cocaine</option>
                        <option>Heroine</option>
                        <option>Crystal Meth</option>
                        <option>Codeine</option>
                        <option>Morphine</option>
                        <option>Phentanyle</option>
                        <option>Other</option>
                      </Form.Select>
                    </>
                  )}
                </div>
                <div className='col-md-3 col-lg-3 col-sm-6 col-xs-6'>
                  <label>Frequency</label>
                  <Form.Control type='number' placeholder='Enter frequency' />
                </div>
                <div className='col-md-3 col-lg-3 col-sm-6 col-xs-6'>
                  <label>Quantity</label>
                  <Form.Control type='number' placeholder='Enter quantity' />
                </div>
                <div className='col-md-3 col-lg-3 col-sm-6 col-xs-6'>
                  {habit === "Drinking" ? (
                    <>
                      <label>&nbsp;</label>
                      <Form.Select className='pl-2'>
                        <option>Select</option>
                        <option>Bottles(300ml)</option>
                        <option>Glasses(120ml)</option>
                        <option>Totes(25ml)</option>
                      </Form.Select>
                    </>
                  ) : habit === "Smoking" ? (
                    <>
                      <label>&nbsp;</label>
                      <Form.Select className='pl-2'>
                        <option>Select</option>
                        <option>Sticks</option>
                        <option>Puffs</option>
                      </Form.Select>
                    </>
                  ) : (
                    <>
                      <label>&nbsp;</label>
                      <Form.Select className='pl-2'>
                        <option>Select</option>
                        <option>Cannabis</option>
                        <option>Heroine</option>
                      </Form.Select>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className='col-md-4 col-lg-4 col-sm-12 card'>
            <p
              className='mt-3 mb-3'
              style={{
                fontSize: 24,
                fontFamily: "Lato",
                fontWeight: 700,
                color: "#662382",
              }}
            >
              Alcohol Profile
            </p>
            <div className='card'>
              <div
                className='d-flex justify-content-between pl-2 pt-2 pr-2 pb-2'
                style={{ border: "1px solid #662382", borderRadius: 8 }}
              >
                <span
                  style={{
                    fontSize: 16,
                    fontFamily: "Lato",
                    fontWeight: 700,
                    color: "#662382",
                    marginTop: 10,
                  }}
                >
                  Alcohol Content
                </span>
                <div
                  style={{
                    backgroundColor: "#662382",
                    height: 30,
                    width: 2,
                    marginTop: 5,
                  }}
                />
                <Button
                  style={{
                    backgroundColor: "#97CE5157",
                    border: "1px solid #97CE5157",
                    marginLeft: 30,
                    width: 126,
                  }}
                >
                  OPTIMAL
                </Button>
              </div>
              <p
                className='mt-3 mb-3'
                style={{
                  fontSize: 24,
                  fontFamily: "Lato",
                  fontWeight: 700,
                  color: "#662382",
                }}
              >
                Nicotine Profile
              </p>
              <div
                className='d-flex justify-content-between pl-2 pt-2 pr-2 pb-2 mt-2'
                style={{ border: "1px solid #662382", borderRadius: 8 }}
              >
                <span
                  style={{
                    fontSize: 16,
                    fontFamily: "Lato",
                    fontWeight: 700,
                    color: "#662382",
                    marginTop: 10,
                  }}
                >
                  Nicotine Content
                </span>
                <div
                  style={{
                    backgroundColor: "#662382",
                    height: 30,
                    width: 2,
                    marginTop: 5,
                  }}
                />
                <Button
                  style={{
                    backgroundColor: "#FF005C3D",
                    border: "1px solid #FF005C3D",
                    marginLeft: 30,
                    width: 126,
                  }}
                >
                  High
                </Button>
              </div>
              <p
                className='mt-3 mb-3'
                style={{
                  fontSize: 24,
                  fontFamily: "Lato",
                  fontWeight: 700,
                  color: "#662382",
                }}
              >
                Other Substances Profile
              </p>
              <div
                className='d-flex justify-content-between pl-2 pt-2 pr-2 pb-2 mt-2'
                style={{ border: "1px solid #662382", borderRadius: 8 }}
              >
                <span
                  style={{
                    fontSize: 16,
                    fontFamily: "Lato",
                    fontWeight: 700,
                    color: "#662382",
                    marginTop: 10,
                  }}
                >
                  Substances Intake
                </span>
                <div
                  style={{
                    backgroundColor: "#662382",
                    height: 30,
                    width: 2,
                    marginTop: 5,
                  }}
                />
                <Button
                  style={{
                    backgroundColor: "#FF005C3D",
                    border: "1px solid #FF005C3D",
                    marginLeft: 30,
                    width: 126,
                  }}
                >
                  Low
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div
          className='d-flex justify-content-start mt-2 ml-2'
          style={{ gap: 20 }}
        >
          <Link to='/new-activity-care-plan'>
            <Button variant='success'>Back</Button>
          </Link>
          <Link to='/mental-health-careplan'>
            <Button variant='success'>Next</Button>
          </Link>
        </div>
      </div>

      {/***********************************
                  Footer start
                  ************************************/}
      <Footer />
      {/***********************************
                  Footer end
                  ************************************/}
    </div>
  );
};

export default NewHabitBreakerCarePlan;
