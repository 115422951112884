import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import DashboardCards from '../components/DashboardCards'
import AppointmentActivity from './AppointmentActivity'
import NurseAssignment from './NurseAssignments'
import DoctorsOnCall from './DoctorsOnCall'
import NurseInstructions from './NurseInstructions'

const Nurse_ = () => {
  // useEffect(() => {
  //   document.getElementById("menu").metisMenu();
  // }, []);
  const user = useSelector(selectUser)
  return (
    <div className="content-body">
      {/* row */}

      <DashboardCards
        bookedAppointments="26"
        upcAppointments="16"
        activePatients="788"
        newPatients="11"
      />
      <NurseAssignment />
      <NurseInstructions />
      {/* <DoctorsOnCall /> */}
    </div>
  )
}

export default Nurse_
