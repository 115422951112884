import React, { useState, useRef } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateBasketFood,
  selectBasketFood,
} from '../../redux_setup/slices/basketFoodSlice'
import { oil_data } from '../../constants/nutrition_data'

const OilUsed = () => {
  const [show, setShow] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [selected, setSelected] = useState()
  const [disabled, setDisabled] = useState(true)
  const otherRef = useRef()
  const formRef = useRef()
  const dispatch = useDispatch()
  const foodInBasket = useSelector(selectBasketFood)

  const handleClose = () => setShow(false)
  return (
    <>
      <div>
        <p
          className="meal-profile-text"
          style={{ color: '#000', cursor: 'pointer' }}
        >
          Oil used
        </p>
        <div className="d-flex flex-wrap">
          <label className="mr-3 mt-2 mb-2">Time</label>
          <input
            type="time"
            style={{
              width: 135,
              height: 27,
              marginBottom: 20,
              border: '1px solid #662382',
            }}
          />
        </div>
        <div style={{ width: 200 }}>
          <label>Food</label>
          <Form.Select className="pl-2">
            <option onClick={() => setShow(true)}>Foods</option>
          </Form.Select>
        </div>
        <div
          style={{
            // width: 663,
            height: 58,
            backgroundColor: '#E8E8E840',
          }}
          className="mt-3"
        />
      </div>
      <Modal show={show} onHide={handleClose}>
        <div className="card pl-4 pr-4">
          <div style={{ position: 'absolute', top: 2, right: 2 }}>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/close-icon.png`}
              width={26}
              height={26}
              alt="close icon"
              style={{ cursor: 'pointer' }}
              onClick={() => setShow(false)}
            />
          </div>
          <div className="row mt-5">
            <div className=" col-md-6 col-sm-12">
              <div style={{ borderRadius: 8 }}>
                {oil_data[0].map((item, index) => (
                  <div
                    key={index}
                    className=" card pl-2 pr-3 mt-2 mb-2 ml-2"
                    style={{
                      maxWidth: 400,
                      marginLeft: 150,
                      backgroundColor:
                        isSelected && selected === item.title
                          ? '#6623824D'
                          : '',
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <Form.Check
                        type="checkbox"
                        id={`dinner-${item.title}`}
                        label={`${item.title}`}
                        style={{ whiteSpace: 'nowrap' }}
                        className="mt-2"
                        onChange={(e) => {
                          setIsSelected(e.target.checked)
                          setSelected(item.title)
                        }}
                      />
                      <div className="d-flex mt-2">
                        <Button
                          variant="default"
                          style={{
                            width: 35,
                            height: 16,
                            padding: 0,
                            fontSize: 10,
                            backgroundColor:
                              isSelected && selected === item.title
                                ? '#6623824D'
                                : '',
                          }}
                        >
                          <i class="fa-sharp fa-solid fa-minus"></i>
                        </Button>
                        <span style={{ marginBottom: 10, fontSize: 13 }}>
                          1
                        </span>
                        <Button
                          variant="default"
                          style={{
                            width: 35,
                            height: 16,
                            padding: 0,
                            fontSize: 10,
                            backgroundColor:
                              isSelected && selected === item.title
                                ? '#6623824D'
                                : '',
                          }}
                        >
                          <i class="fa-sharp fa-solid fa-plus"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className=" col-md-6 col-sm-12">
              <div className="meal-mobo" style={{ borderRadius: 8 }}>
                <div
                  className="card pl-2 pr-3 mt-2 mb-2 ml-2"
                  style={{ maxHeight: 150, padding: 8 }}
                >
                  <div className="d-flex justify-content-between">
                    <Form.Check
                      type="checkbox"
                      id="other"
                      label="Other"
                      style={{ whiteSpace: 'nowrap', marginBottom: 10 }}
                      className="mt-2"
                      onChange={(e) => setDisabled(!e.target.checked)}
                    />
                    <div className="d-flex mt-2">
                      <Button
                        variant="default"
                        style={{
                          width: 35,
                          height: 16,
                          padding: 0,
                          fontSize: 10,
                          backgroundColor:
                            isSelected && selected === 'fruit'
                              ? '#6623824D'
                              : '',
                        }}
                      >
                        <i class="fa-sharp fa-solid fa-minus"></i>
                      </Button>
                      <span style={{ marginBottom: 10, fontSize: 13 }}>1</span>
                      <Button
                        variant="default"
                        style={{
                          width: 35,
                          height: 16,
                          padding: 0,
                          fontSize: 10,
                          backgroundColor:
                            isSelected && selected === 'fruit'
                              ? '#6623824D'
                              : '',
                        }}
                      >
                        <i class="fa-sharp fa-solid fa-plus"></i>
                      </Button>
                    </div>
                  </div>
                  <form
                    ref={formRef}
                    className="d-flex flex-wrap justify-content-between"
                  >
                    <input
                      type="text"
                      placeholder="Add ather foods not in the list"
                      style={{ width: 240, borderRadius: 5, paddingLeft: 10 }}
                      disabled={disabled}
                      ref={otherRef}
                    />
                    <Button
                      className="mt-2"
                      onClick={() => {
                        if (otherRef.current.value != '') {
                          dispatch(
                            updateBasketFood([
                              ...foodInBasket,
                              otherRef.current.value,
                            ]),
                          )
                          formRef.current.reset()
                        }
                      }}
                    >
                      Add
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default OilUsed
