import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import Select from 'react-select'

import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const hypertensionstage = [
  { value: 'Stage 1', label: 'Stage 1' },
  { value: 'Stage 2', label: 'Stage 2' },
]

const PatientHyperTensionComponent = () => {
  toast.configure()
  let navigate = useNavigate()
  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  const user = useSelector(selectUser)
  const [currentTab, setcurrentTab] = useState('physical_assessment')

  // inputs states
  const [height, setHeight] = useState('')
  const [weight, setWeight] = useState('')
  let bmi = weight / Math.pow(height / 100, 2)
  const [hypertensionStagev, setHypertensionStageV] = useState('')
  const [pastMedicalHistory, setPastMedicalHistory] = useState('')
  const [currentMedication, setCurrentMedication] = useState('')
  const [bpReadingHistory, setBpReadingHistory] = useState('')
  const [chiefComplaint, setChiefComplaint] = useState('')
  const [physicalExamination, setPhysicalExamination] = useState('')
  const [laboratoryEvaluation, setLaboratoryEvaluation] = useState([])
  const [abnormal, setAbnormal] = useState('')
  const [extraNotes, setExtraNotes] = useState('')

  const [knownAllergies, setKnownAllergies] = useState('')
  const [terminalIllness, setTerminalIllness] = useState('')
  const [foodAllergy, setFoodAllergy] = useState(false)
  const [pollenAllergy, setPollenAllergy] = useState(false)
  const [dustAllergy, setDustAllergy] = useState(false)
  const [petAllergy, setPetAllergy] = useState(false)
  const [latexAllergy, setLatexAllergy] = useState(false)
  const [whichterminalIllness, setWhichTerminalIllness] = useState('')
  const [medicineAllergy, setMedicineAllergy] = useState('')

  const [alcoholHabit, setAlcoholHabit] = useState(false)
  const [smokingHabit, setSmokingHabit] = useState(false)
  const [drugHabit, setDrugHabit] = useState(false)
  const [currentSymptoms, setCurrentSymptoms] = useState('')
  const [illnessStart, setIllnessStart] = useState('')
  const [triedSelfTreatment, setTriedSelfTreatment] = useState('')
  const [whatTried, setWhatTried] = useState('')
  const [oftenDrink, setOftenDrink] = useState('')
  const [oftenSmoke, setOftenSmoke] = useState('')
  const [oftenDrugs, setOftenDrugs] = useState('')
  const [bottlesDay, setBottlesDay] = useState('')
  const [packsDay, setPacksDay] = useState('')
  // const []
  //valid invalid states
  const [physical_assessmentvalid, setPhysical_assessmentvalid] = useState(
    false,
  )
  const [medical_historyvalid, setMedical_historyvalid] = useState(false)
  const [treatment_historyvalid, setTreatment_historyvalid] = useState(false)

  const [patients, setPatients] = useState([])
  const [selectedPatient, setSelectedPatient] = useState({})

  //changing tabs
  useEffect(() => {
    if (!isNaN(bmi) && bmi > 0 && selectedPatient) {
      setPhysical_assessmentvalid(true)
    } else {
      setPhysical_assessmentvalid(false)
    }

    return () => {
      console.log('completed')
    }
  }, [height, weight])

  //fetch patients if nurse
  // patients
  const patientsUrl = `${process.env.REACT_APP_MAIN_URL}/api/patient/`
  useEffect(() => {
    if (user.role === 'NURSE') {
      fetch(patientsUrl, {
        signal: signal,
        method: 'GET',
        headers: new Headers({
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            return console.log('patients fetching did not succeed')
          }
          let iterable = user.role === 'SUPER_ADMIN' ? res.data.items : res.data

          iterable.forEach((item) => {
            item.value = item.id
            item.label = item.name
            // now I no longer need them
            delete item.id
            delete item.name
            //->will delete the rest later
          })
          // console.log(res.data);

          iterable?.length > 0 && setPatients(iterable)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  const handleCheckbox = (checked, value) => {
    const tempArray = [...laboratoryEvaluation]
    checked ? tempArray.push(value) : tempArray.pop()
    const uniqueTemp2 = [...new Set(tempArray)]
    setLaboratoryEvaluation(uniqueTemp2)
  }

  const handleTabs = (action) => {
    if (action === 'prev') {
      //get current state for currentTab
      if (currentTab === 'medical_history') {
        setcurrentTab('physical_assessment')
      } else if (currentTab === 'treatment_history') {
        setcurrentTab('medical_history')
      } else {
        return false
      }
    } else if (action === 'next') {
      if (currentTab === 'medical_history') {
        setMedical_historyvalid(true)
        setcurrentTab('treatment_history')
      } else if (
        currentTab === 'physical_assessment' &&
        physical_assessmentvalid
      ) {
        setcurrentTab('medical_history')
      } else if (currentTab === 'treatment_history') {
        submitData()
      }
    }
  }
  const submitData = () => {
    const url = `${process.env.REACT_APP_MAIN_URL}/api/hypertension`
    const dataToSend2 = {
      patient_id:
        user.role === 'Patient' ? user.user_id : selectedPatient?.value,
      height: height,
      weight: weight,
      bmi: bmi,
      hypertension_stage: hypertensionStagev,
      past_medical_history: pastMedicalHistory,
      current_medications: currentMedication,
      bp_reading_history: bpReadingHistory,
      the_chief_complaint: [chiefComplaint],
      physical_examination: physicalExamination,
      laboratory_evaluation: laboratoryEvaluation,
      abnormal_comment: abnormal,
      staff_note: extraNotes,
    }
    // const dataToSend = {
    //   patient_id: user.user_id,
    //   height: height,
    //   weight: weight,
    //   bmi: bmi,
    //   current_symptoms: currentSymptoms,
    //   illness_start: illnessStart,
    //   self_treatment: triedSelfTreatment,
    //   which_self_treatment: whatTried,

    //   known_allergies: knownAllergies,
    //   where_applicable: {
    //     food: foodAllergy,
    //     pollen: pollenAllergy,
    //     dust: dustAllergy,
    //     pet: petAllergy,
    //     latex: latexAllergy,
    //   },
    //   know_medicine_allergies: medicineAllergy,
    //   terminal_illness: terminalIllness,
    //   which_terminal_illness: whichterminalIllness,
    //   habits: {
    //     alcohol: alcoholHabit,
    //     smoking: smokingHabit,
    //     drugs: drugHabit,
    //   },
    //   often_drink: oftenDrink,
    //   bottles_a_day: bottlesDay,
    //   often_smoke: oftenSmoke,
    //   packs_a_day: packsDay,
    //   often_drugs: oftenDrugs,
    // }
    // return console.log(dataToSend2, url, user.token)

    // return navigate("/book-appointment", {
    //   state: {
    //     patient_id: "5642282b-bf52-4be9-a4e1-c3bc12e68620", //replace with res.id
    //     patient_name: "Zari Hindra Nomakanji", //replace with data to send .fulname
    //   },
    // });
    // send request
    fetch(url, {
      signal: signal,
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend2),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)

        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          })
        }

        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        })
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
        })
      })
  }

  return (
    <div className="content-body">
      {/* row */}

      {/* <div className="container-fluid">
        <div className="form-head page-titles d-flex mb-md-4">
          <div className="mr-auto">
            <h2 className="text-black font-w600">Doctor</h2>
          </div>
        </div>
      </div> */}
      <div className="container-fluid pt-0">
        <div className="row">
          <div className="col-lg-12 col-xxl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Hypertension Triage</h4>
              </div>
              <div className="card-body">
                <div
                  id="smartwizard"
                  className="form-wizard order-create sw sw-theme-default sw-justified"
                >
                  <ul className="nav nav-wizard">
                    <li>
                      <a
                        className={`nav-link ${
                          currentTab === 'physical_assessment'
                            ? 'active'
                            : 'inactive'
                        } ${physical_assessmentvalid && 'done active'}`}
                      >
                        <span>1</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className={`nav-link ${
                          currentTab === 'medical_history'
                            ? 'active'
                            : 'inactive'
                        } ${medical_historyvalid && 'done active'}`}
                      >
                        <span>2</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className={`nav-link ${
                          currentTab === 'treatment_history'
                            ? 'active'
                            : 'inactive'
                        } ${treatment_historyvalid && 'done active'}`}
                      >
                        <span>3</span>
                      </a>
                    </li>
                  </ul>
                  <div
                    className="tab-content text-left"
                    style={{ overflowY: 'scroll' }}
                  >
                    <div
                      id="physical_assessment"
                      className={`tab-pane ${
                        currentTab === 'physical_assessment'
                          ? 'blockeddisplay'
                          : 'hide'
                      }`}
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="form-group col-lg-12">
                          <h4>Physical Assessment</h4>
                        </div>
                        {user.role === 'NURSE' && (
                          <div className="col-lg-6 mb-2">
                            <div className="form-group">
                              <label className="text-label">
                                Select Patient
                              </label>
                              <Select
                                options={patients}
                                id="slot"
                                placeholder="select Patient"
                                onChange={(selectedOption) =>
                                  setSelectedPatient(selectedOption)
                                }
                              />
                            </div>
                          </div>
                        )}
                        <div className="col-lg-6 mb-2">
                          <div className="form-group">
                            <label className="text-label">Height In cm*</label>
                            <input
                              type="number"
                              name="firstName"
                              className="form-control"
                              title="Type your Height"
                              placeholder=""
                              value={height}
                              onChange={(e) => setHeight(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <div className="form-group">
                            <label className="text-label">Weight In Kg*</label>
                            <input
                              type="number"
                              name="lastName"
                              title="Type your Weight"
                              className="form-control"
                              placeholder=""
                              value={weight}
                              onChange={(e) => setWeight(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 mb-2">
                          <div className="form-group">
                            <label className="text-label">BMI</label>
                            <input
                              type="number"
                              className="form-control"
                              title="Body mass index value derived from the mass and height "
                              id="inputGroupPrepend2"
                              aria-describedby="inputGroupPrepend2"
                              value={Math.round(bmi * 100) / 100}
                              readOnly
                            />
                          </div>
                        </div>
                        {/* <div className="col-lg-12 mb-2">
                    <div className="form-group">
                      <label className="text-label">Phone Number*</label>
                      <input type="text" name="phoneNumber" className="form-control" placeholder="(+1)408-657-9007" required />
                    </div>
                  </div> */}
                        {/* <div className="col-lg-12 mb-3">
                    <div className="form-group">
                      <label className="text-label">Where are you from*</label>
                      <input type="text" name="place" className="form-control" required />
                    </div>
                  </div> */}
                      </div>
                    </div>
                    <div
                      id="medical_history"
                      className={`tab-pane ${
                        currentTab === 'medical_history'
                          ? 'blockeddisplay'
                          : 'hide'
                      }`}
                      role="tabpanel"
                    >
                      <div className="row">
                        {/* <div className="form-group col-lg-12">
                    <h4>Medical History</h4>
                    <div class="row col-lg-12"style={{marginLeft:"3px"}}>
                        <h4>Examination and Lab Test</h4>
                      </div>
                  </div> */}

                        <div class="container">
                          <div class="row justify-content-center">
                            <div class="col-12  col-lg-12 col-xl-12">
                              <div class="row">
                                <div className="form-group col-lg-12">
                                  <h4 style={{ marginLeft: '13px' }}>
                                    Medical History
                                  </h4>
                                </div>
                              </div>

                              <div class="row align-items-center mt-9">
                                <div class="col">
                                  <div className="form-group">
                                    <div class="col-9">
                                      <div className="form-group">
                                        <label
                                          class="form-check-label"
                                          for="flexCheckDefault"
                                        >
                                          Hypertension stage
                                        </label>
                                        <Select
                                          options={hypertensionstage}
                                          onChange={(e) =>
                                            setHypertensionStageV(e.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row align-items-center mt-9">
                                <div class="col">
                                  <div className="form-group">
                                    <div class="col-9">
                                      <label for="medical_history">
                                        Past Medical History
                                      </label>
                                      <textarea
                                        class="form-control"
                                        id="medical_history"
                                        rows="3"
                                        value={pastMedicalHistory}
                                        onChange={(e) =>
                                          setPastMedicalHistory(e.target.value)
                                        }
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row align-items-center mt-9">
                                <div class="col">
                                  <div className="form-group">
                                    <div class="col-9">
                                      <label for="current_medication">
                                        Current medication(s)
                                      </label>
                                      <textarea
                                        class="form-control"
                                        id="current_medication"
                                        rows="3"
                                        value={currentMedication}
                                        onChange={(e) =>
                                          setCurrentMedication(e.target.value)
                                        }
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="row align-items-center mt-9"
                                style={{ marginLeft: '4px' }}
                              >
                                <div class="col">
                                  <div className="form-group">
                                    <label className="text-label">
                                      The chief complaint
                                    </label>
                                    <div class="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="chiefc"
                                        value="Headache,sweating and palpitations(pheochromocytoma)"
                                        onChange={(e) =>
                                          setChiefComplaint(e.target.value)
                                        }
                                        id="ca"
                                      />
                                      <label class="form-check-label" for="ca">
                                        Headache, sweating and
                                        palpitations(pheochromocytoma)
                                      </label>
                                    </div>
                                    <div class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="radio"
                                        name="chiefc"
                                        value="Loud snoring, obesity, gasping and
                                        daytime sleepiness(sleep apnea)"
                                        onChange={(e) =>
                                          setChiefComplaint(e.target.value)
                                        }
                                        id="cb"
                                      />
                                      <label class="form-check-label" for="cb">
                                        Loud snoring, obesity, gasping and
                                        daytime sleepiness(sleep apnea)
                                      </label>
                                    </div>
                                    <div class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="radio"
                                        name="chiefc"
                                        value="Mild Chest Pain"
                                        onChange={(e) =>
                                          setChiefComplaint(e.target.value)
                                        }
                                        id="cc"
                                      />
                                      <label class="form-check-label" for="cc">
                                        Mild Chest Pain
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="treatment_history"
                      className={`tab-pane ${
                        currentTab === 'treatment_history'
                          ? 'blockeddisplay'
                          : 'hide'
                      }`}
                      role="tabpanel"
                    >
                      <div class="row col-lg-12" style={{ marginLeft: '3px' }}>
                        <h4>Examination and Lab Test</h4>
                      </div>

                      <div class="mb-3">
                        <div class="row align-items-center mt-9">
                          <div class="col">
                            <div className="form-group">
                              <div class="col-9">
                                <label for="physical_exam">
                                  Physical examination
                                </label>
                                <textarea
                                  class="form-control"
                                  id="physical_exam"
                                  rows="3"
                                  value={physicalExamination}
                                  onChange={(e) =>
                                    setPhysicalExamination(e.target.value)
                                  }
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row align-items-center mt-9">
                        <div class="col">
                          <div className="form-group">
                            <div class="col-9">
                              <label for="bpreading">BP reading history</label>
                              <textarea
                                class="form-control"
                                id="bpreading"
                                rows="3"
                                value={bpReadingHistory}
                                onChange={(e) =>
                                  setBpReadingHistory(e.target.value)
                                }
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="row align-items-center mt-9"
                        style={{ marginLeft: '4px' }}
                      >
                        <div class="col">
                          <div className="form-group">
                            <label className="text-label">
                              Laboratory evaluation
                            </label>
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value="Blood Pressure Tests"
                                onChange={(e) => {
                                  handleCheckbox(
                                    e.target.checked,
                                    e.target.value,
                                  )
                                }}
                                id="l1"
                              />
                              <label class="form-check-label" for="l1">
                                Blood Pressure Tests
                              </label>
                            </div>
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value="Blood Tests"
                                onChange={(e) => {
                                  handleCheckbox(
                                    e.target.checked,
                                    e.target.value,
                                  )
                                }}
                                id="l2"
                              />
                              <label class="form-check-label" for="l2">
                                Blood Tests
                              </label>
                            </div>
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value="Urine Tests"
                                onChange={(e) => {
                                  handleCheckbox(
                                    e.target.checked,
                                    e.target.value,
                                  )
                                }}
                                id="l3"
                              />
                              <label class="form-check-label" for="l3">
                                Urine Tests
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center mt-9">
                        <div class="col">
                          <div className="form-group">
                            <div class="col-9">
                              <label for="comment_abnormal">
                                Comment if abnormal
                              </label>
                              <textarea
                                class="form-control"
                                id="comment_abnormal"
                                rows="3"
                                value={abnormal}
                                onChange={(e) => setAbnormal(e.target.value)}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      {(user.role === 'NURSE' || user.role === 'DOCTOR') && (
                        <div class="row align-items-center mt-9">
                          <div class="col">
                            <div className="form-group">
                              <div class="col-9">
                                <label for="extra_notes">
                                  Additional Notes
                                </label>
                                <textarea
                                  class="form-control"
                                  id="extra_notes"
                                  rows="3"
                                  value={extraNotes}
                                  onChange={(e) =>
                                    setExtraNotes(e.target.value)
                                  }
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="toolbar toolbar-bottom"
                    role="toolbar"
                    style={{ textAlign: 'right' }}
                  >
                    <button
                      onClick={() => handleTabs('prev')}
                      className={`btn btn-success ${
                        currentTab === 'physical_assessment' && 'disabled'
                      }`}
                      type="button"
                    >
                      Previous
                    </button>
                    <button
                      onClick={() => handleTabs('next')}
                      className={`btn btn-primary`}
                      type="button"
                    >
                      {currentTab === 'treatment_history' ? 'Submit' : 'Next'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <a href="/ManagePatients">
          <button className="btn btn-light btl-lg shadow"> 
          Manage Patients
          </button> </a> */}

      {/* <MainApp appointments={appointments} today={appointmentsToday} /> */}
      {/* <AppointmentActivity /> */}
    </div>
  )
}

export default PatientHyperTensionComponent
