import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import { humanize } from '../constants/Essentials'
import PatientComponent from './PatientComponent'
import DashboardCards from './DashboardCards'
import { Table } from 'react-bootstrap'
import AppointmentActivity from './AppointmentActivity'
//dummy to be replaced`

const Admin_ = () => {
  const user = useSelector(selectUser)
  const abortcontroller = new AbortController()
  const [dashboardItems, setDashboardItems] = useState([])
  const signal = abortcontroller.signal
  // fetch appointments
  const fetchAppointmentsUrl = `${process.env.REACT_APP_MAIN_URL}/api/user/appointments-admin?size=100`

  useEffect(() => {
    fetch(fetchAppointmentsUrl, {
      method: 'GET',
      signal: signal,
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.data.items)
        res.data.items.forEach((item) => {
          // item.appointment_id = item.id;
          // item.id = count;
          item.title =
            user.role === 'Patient' ? item?.doctorId?.name : item?.patient?.name
          const dt = new Date(item.date)
          // item.value = item.id;
          // item.start = ;
          // const slot = "T"+item.time_slot.substring(0, str.length - 2)
          item.start = String(
            dt.toISOString().replace(/T.*$/, '') + 'T' + item.time_slot,
          )
          // item.start = "2022-02-01T16:38";

          //->will delete the rest later
        })

        // set appointments today
        const appointmentsT = res?.data?.items.filter(
          (item) =>
            new Date(item.date).toISOString().replace(/T.*$/, '') ===
            new Date().toISOString().replace(/T.*$/, ''),
        )
        console.log(appointmentsT)
        //  setAppointmentsToday(appointmentsT);

        //  res.data?.items?.length > 0 && setAppointments(res.data.items);
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  // fetch dashboard items
  useEffect(() => {
    if (user.role === 'RECEPTIONIST') {
      fetch(
        `${process.env.REACT_APP_MAIN_URL}/api/dashboard/dashboard-receptionist`,
        {
          method: 'GET',
          headers: new Headers({
            Authorization: 'Bearer ' + user.token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }),
        },
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res.data)
          setDashboardItems(res.data)
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      fetch(`${process.env.REACT_APP_MAIN_URL}/api/dashboard/dashboard`, {
        method: 'GET',
        headers: new Headers({
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res.data)
          setDashboardItems(res.data)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])
  return (
    <div className="content-body">
      {/* row */}

      {/* <div className="form-head d-flex align-items-center mb-sm-4 mb-3">
        <div className="mr-auto">
          <h2 className="text-black font-w600">{humanize(user.role)}</h2>
        </div>
      </div> */}

      <DashboardCards
        bookedAppointments={dashboardItems[0]?.total}
        upcAppointments={dashboardItems[1]?.total}
        activePatients={dashboardItems[2]?.total}
        newPatients={dashboardItems[3]?.total}
      />

      {/* expert_doctors_area_end */}

      <AppointmentActivity />
    </div>
  )
}

export default Admin_
