 const IntakeCalculation = ({title,calories}) => {
    return(
            
            <div className='bubble__right__side__container'>
                <p className='bubble__side__title'>{title}</p>
                <div className='flex__container' style={{gap:0}}>
                        <div className='flex1'>
                            <label>Total Calorie intake</label>
                        </div>
                        <div className='flex2'>
                            <strong>{calories?.calories.toFixed(1)}</strong>
                            <span>kcal</span>
                        </div>
                        <div className='flex3'>
                            <p className='low'>LOW</p>
                        </div>
                </div>
                <div className='flex__container' style={{gap:0}}>
                        <div className='flex1'>
                            <label>Total sodium intake</label>
                        </div>
                        <div className='flex2'>
                            <strong>{calories?.sodium.toFixed(1)}</strong>
                            <span>kcal</span>
                        </div>
                        <div className='flex3'>
                            <p className='low'>LOW</p>
                        </div>
                </div>
                <div className='flex__container' style={{gap:0}}>
                        <div className='flex1'>
                            <label>Total fat intake</label>
                        </div>
                        <div className='flex2'>
                            <strong>{calories?.fat.toFixed(1)}</strong>
                            <small>kcal</small>
                        </div>
                        <div className='flex3'>
                            <p className='low'>LOW</p>
                        </div>
                </div>
            </div>
    )
}
export default IntakeCalculation