import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import GraphButtons from './GraphButtons'
import { Line } from 'react-chartjs-2'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { data_options } from '../../../constants/Graph_data'
import { selectUser } from '../../../redux_setup/slices/userSlice'
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from 'chart.js'
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement)

const calorie_intake = {
  labels: ['Morning', '', '', 'Noon', '', 'Evening', ''],
  datasets: [
    {
      data: [7, 4, 2, 6, 8, 3],
      backgroundColor: 'transparent',
      borderColor: '#D60A51',
      pointBorderColor: 'transparent',
      pointBorderWidth: 4,
    },
  ],
}

const GraphVital = ({ patientDetails }) => {
  const [labels, setLabels] = useState(null)
  const [data, setData] = useState(null)
  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  const user = useSelector(selectUser)
  const url = `api/careplans/hypertensionv1/nutrition/analysis?patient=9ff504d8-e578-4ee0-8b99-cd32661930b1&timeframe=month`
  toast.configure()
  useEffect(() => {
    fetch(`${process.env.REACT_APP_MAIN_URL_HYPERTENSION}/${url}`, {
      signal: signal,
      method: 'GET', //if you can find an id in labs, then update
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }

        console.log('Graph data =>', res.data)
      })
      .catch((err) => {
        console.log(err)
        // return toast.warning('An error has occured while reaching the server', {
        //   position: toast.POSITION.TOP_CENTER,
        //   autoClose:15000,
        // })
      })
  }, [])
  return (
    <>
      <div
        className="graph__container"
        style={{ backgroundColor: 'transparent' }}
      >
        <div className="row" style={{ marginLeft: -100 }}>
          <div className="col-md-6 col-sm-12">
            <div className="flex__container">
              <p className="vital__tile">Vital Tile</p>
              <div className="graph__card">
                <svg
                  width="63"
                  height="26"
                  viewBox="0 0 63 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 26V10.3755L5.60289 18.645L11.8586 14.0553L17.1566 8.95548L22.5442 6.43643L27.8222 8.95548L33.8206 14.6354L39.481 8.95548L45.2619 14.0553L50.9223 3.80797C50.9223 3.80797 52.9538 5.69498 53.7891 7.26675C53.9586 7.58562 62.0776 -1.1916 62.7162 0.137052C63.3548 1.4657 62.7162 26 62.7162 26H0Z"
                    fill="url(#paint0_linear_3030_16938)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_3030_16938"
                      x1="-25.6449"
                      y1="-18.7048"
                      x2="-25.6449"
                      y2="28.4624"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#D60A51" stop-opacity="0.6" />
                      <stop offset="1" stop-color="white" />
                    </linearGradient>
                  </defs>
                </svg>
                <div className="flex__container" style={{ marginTop: 10 }}>
                  <small className="graph__card__text">Latest</small>
                  <small className="graph__card__text">Average</small>
                  <small className="graph__card__text">Diagnosis</small>
                </div>
                <div className="flex__container" style={{ marginTop: 10 }}>
                  <small className="graph__card__subtext">120/129</small>
                  <small className="graph__card__subtext">120/123</small>
                  <small
                    className="graph__card__subtext"
                    style={{ marginLeft: 10 }}
                  >
                    121/125
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="graph__patient__identification__card">
              <p
                className="graph__card__text"
                style={{ color: '#52575C', fontSize: 16 }}
              >
                Name: {patientDetails?.name}
              </p>
              <p
                className="graph__card__text"
                style={{ color: '#52575C', fontSize: 16 }}
              >
                Diagnosis: Hypertension stage 1 Vitals
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="btn_component__container" style={{ marginTop: -230 }}>
        <GraphButtons nutritionType={'calorie intake'} />
      </div>
      <div className="graph__container">
        <Line data={calorie_intake} options={data_options} />
      </div>
    </>
  )
}
export default GraphVital
