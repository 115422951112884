import { useState } from "react";
import Switch from "react-switch";
import { Form } from "react-bootstrap";
import Counter from "../Counter";
const switchColorCompute = (level) => {
  let color = "white";
  let handle = "white";
  switch (level) {
    case "High":
      color = "#ff80ad";
      handle = "#ff005c";
      break;

    case "Medium":
      color = "#fff9e5";
      handle = "#ffc000";
      break;

    case "Low":
      color = "#e6f7fb";
      handle = "#07b0d7";
      break;

    default:
      color = "white";
      handle = "#ff005c";
      break;
  }
  return { color, handle };
};

const MentalHealth = () => {
  const [range, setRange] = useState(0);
  const [switched, setSwitched] = useState({id: null, checked:false})
  return (
    <div className='card pl-2 pr-2 mt-3'>
      <p className='meal-profile-text mt-3' style={{ color: "#000" }}>
        Mental Health
      </p>
      <label>Number</label>
      <Counter leftPosition={range} times={27} data={range} />
      <div>
        <Form.Range
          min={0}
          max={10}
          value={range}
          style={{
            width: 301,
            marginRight: 10,
            marginBottom: 30,
            color: "red",
          }}
          onChange={(e) => setRange(e.target.value)}
        />
      </div>
      <label>Do you suffer from anxiety?</label>
      <div className='d-flex' style={{ gap: 100 }}>
        <Form.Check id='anxiety-yes' label='Yes' type='checkbox' />
        <Form.Check id='anxiety-no' label='No' type='checkbox' />
      </div>
      <label className='mt-2'>Frequency</label>
      <Form.Select
        type='text'
        placeholder='Enter your alcohol habit'
        className='pl-2'
        style={{ width: 215 }}
      >
        <option>Weekly</option>
        <option>Monthly</option>
      </Form.Select>
      <label className='mt-2'>Have you ever suffered Depression?</label>
      <div className='d-flex' style={{ gap: 100 }}>
        <Form.Check id='depression-yes' label='Yes' type='checkbox' />
        <Form.Check id='depression-no' label='No' type='checkbox' />
      </div>
      <label className='mt-2'>Ongoing?</label>
      <div className='d-flex' style={{ gap: 100 }}>
        <Form.Check id='depression-yes' label='Yes' type='checkbox' />
        <Form.Check id='depression-no' label='No' type='checkbox' />
      </div>
      <div className='d-flex flex-wrap mt-3 mb-3' style={{ gap: 20 }}>
        {["Low", "Medium", "High"].map((level, index) => (
          <div className='d-flex flex-wrap'>
            <label style={{ marginTop: 5, fontWeight: 700 }}>{level}</label>
            <div className={`switch${index + 1}`} key={index}>
              <Switch
                key={index}
                onChange={(e) => setSwitched({ id: index, checked: e })}
                value={switched.checked}
                checked={switched.id === index ? switched.checked : false}
                uncheckedIcon={false}
                checkedIcon={false}
                onHandleColor={switchColorCompute(level).handle}
                offHandleColor={switchColorCompute(level).handle}
                onColor={switchColorCompute(level).color}
                offColor={switchColorCompute(level).color}
                width={80}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MentalHealth;
