import React,{ useState } from 'react'
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LeftBar from '../../components/LeftBar';
import moment from 'moment';
import { Button, Form, Nav } from 'react-bootstrap'
const data = [
  {
    name: "Patient One",
    doctor: "Doctor One",
    rx: "Acetaminophen 650mg tabs",
    clinic: "Internal",
    type: "New",
    date: moment().format("DD/MM/YYYY"),
    status: "On Hold",
  },
  {
    name: "Patient Two",
    doctor: "Doctor Two",
    rx: "Acetaminophen 650mg tabs",
    clinic: "External",
    type: "New",
    date: moment().format("DD/MM/YYYY"),
    status: "Dispenced",
  },
];
const PharmacistDashboard = () => {
    const [clicked, setClicked] = useState({
        btnOne: true,
        btnTwo: false,
        btnThree: false
    })
    const today = moment().format('DD/MM/YYYY')
  return (
    <div id='main-wrapper'>
      {/***********************************
Header start
************************************/}
      <Header />
      {/***********************************
Header end ti-comment-alt
************************************/}
      {/***********************************
Sidebar start
************************************/}
      <LeftBar />
      {/***********************************
Sidebar end
************************************/}
      {/***********************************
Content body start
************************************/}

      {/***********************************
      Sidebar end
  ************************************/}
      {/***********************************
      Content body start
  ************************************/}

      <div className='content-body'>
        <div className='row pl-2'>
          <div
            className='col-md-3 col-lg-3 col-sm-4 pl-3 pt-2 mr-4 mb-3'
            style={{
              background:
                "linear-gradient(180deg, rgba(69, 183, 87, 0.6) 0%, rgba(47, 165, 219, 0.6) 100%)",
              height: 84,
            }}
          >
            <p
              style={{
                fontSize: 16,
                fontWeight: 700,
                fontFamily: "Lato",
                color: "#FFF",
              }}
            >
              On the Queue Today
            </p>
            <div className='d-flex justify-content-between'>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  fontFamily: "Lato",
                  color: "#FFF",
                }}
              >
                On the Queue Today
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  fontFamily: "Lato",
                  color: "#FFF",
                }}
              >
                {today}
              </p>
            </div>
          </div>
          <div
            className='col-md-3 col-lg-3 col-sm-4 pl-3 pt-2 mb-3 mr-4'
            style={{ background: "rgba(102, 35, 130, 0.1)", height: 84 }}
          >
            <p
              style={{
                fontSize: 16,
                fontWeight: 700,
                fontFamily: "Lato",
                color: "#19226D",
              }}
            >
              Total New Prescriptions
            </p>
            <p
              style={{
                fontSize: 16,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#52575C",
              }}
            >
              10
            </p>
          </div>
          <div
            className='col-md-3 col-lg-3 col-sm-3 pl-3 pt-2 mr-4'
            style={{ background: "rgba(69, 183, 87, 0.24)", height: 84 }}
          >
            <p
              style={{
                fontSize: 16,
                fontWeight: 700,
                fontFamily: "Lato",
                color: "#19226D",
              }}
            >
              Total Dispenced
            </p>
            <p
              style={{
                fontSize: 16,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#52575C",
              }}
            >
              5
            </p>
          </div>
        </div>
        <div className='row pl-2 mt-3'>
          <div
            className='col-md-3 col-lg-3 col-sm-4 pl-3 pt-2 mr-4 mb-3'
            style={{
              background: "rgba(255, 192, 0, 0.24)",
              height: 84,
            }}
          >
            <p
              style={{
                fontSize: 16,
                fontWeight: 700,
                fontFamily: "Lato",
                color: "#19226D",
              }}
            >
              Total Pending
            </p>
            <p
              style={{
                fontSize: 16,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#52575C",
              }}
            >
              10
            </p>
          </div>
          <div
            className='col-md-3 col-lg-3 col-sm-4 pl-3 pt-2 mb-3 mr-4'
            style={{ background: "rgba(29, 78, 216, 0.24)", height: 84 }}
          >
            <p
              style={{
                fontSize: 16,
                fontWeight: 700,
                fontFamily: "Lato",
                color: "#19226D",
              }}
            >
              Total Refill
            </p>
            <p
              style={{
                fontSize: 16,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#52575C",
              }}
            >
              10
            </p>
          </div>
          <div
            className='col-md-3 col-lg-3 col-sm-3 pl-3 pt-2 mr-4'
            style={{ background: "rgba(0, 151, 220, 0.24)", height: 84 }}
          >
            <p
              style={{
                fontSize: 16,
                fontWeight: 700,
                fontFamily: "Lato",
                color: "#19226D",
              }}
            >
              Total On Hold
            </p>
            <p
              style={{
                fontSize: 16,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#52575C",
              }}
            >
              10
            </p>
          </div>
        </div>
        <div className='d-flex justify-content-between flex-wrap'>
          <Nav variant='pills' defaultActiveKey='/home'>
            <Nav.Item>
              <Nav.Link href='#' style={{ position: "relative" }}>
                <Button
                  variant={clicked.btnOne ? "primary" : "default"}
                  onClick={() =>
                    setClicked({
                      ...clicked,
                      btnOne: true,
                      btnTwo: false,
                      btnThree: false,
                    })
                  }
                >
                  Orders
                </Button>
                <div
                  style={{
                    position: "absolute",
                    width: 25,
                    height: 25,
                    backgroundColor: "#FF6760",
                    color: "#FFF",
                    borderRadius: "50%",
                    padding: 5,
                    fontSize: 10,
                    top: 10,
                    right: 5,
                  }}
                >
                  12
                </div>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='link-1' style={{ position: "relative" }}>
                <Button
                  variant={clicked.btnTwo ? "primary" : "default"}
                  onClick={() =>
                    setClicked({
                      ...clicked,
                      btnTwo: true,
                      btnOne: false,
                      btnThree: false,
                    })
                  }
                >
                  General Pharma
                </Button>
                <div
                  style={{
                    position: "absolute",
                    width: 25,
                    height: 25,
                    backgroundColor: "#FF6760",
                    color: "#FFF",
                    borderRadius: "50%",
                    padding: 5,
                    fontSize: 10,
                    top: 10,
                    right: 5,
                  }}
                >
                  10
                </div>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='disabled' style={{ position: "relative" }}>
                <Button
                  variant={clicked.btnThree ? "primary" : "default"}
                  onClick={() =>
                    setClicked({
                      ...clicked,
                      btnThree: true,
                      btnOne: false,
                      btnTwo: false,
                    })
                  }
                >
                  Pharma Chronic
                </Button>
                <div
                  style={{
                    position: "absolute",
                    width: 25,
                    height: 25,
                    backgroundColor: "#FF6760",
                    color: "#FFF",
                    borderRadius: "50%",
                    padding: 5,
                    fontSize: 10,
                    top: 10,
                    right: 5,
                  }}
                >
                  10
                </div>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <p className='mr-5' style={{ color: "#2FA5DB", cursor: "pointer" }}>
            Create Prescription{" "}
            <i
              style={{ color: "#2FA5DB" }}
              className='fa-sharp fa-solid fa-plus'
            ></i>
          </p>
        </div>
        <div
          className='d-flex justify-content-between mb-3 mt-3'
          style={{ position: "relative",width: '95%' }}
        >
          <div>
            <Form.Control
              type='text'
              placeholder='Search Rx'
              className='pres-card pl-4'
            />
            <i
              className='fa fa-search'
              aria-hidden='true'
              style={{ position: "absolute", left: 5, top: 10 }}
            ></i>
          </div>
          <i className='fa fa-filter' aria-hidden='true' style={{cursor: 'pointer'}} />
        </div>
        <div className='table-responsive card-table'>
          <table
            id='example5'
            className='display dataTablesCard table-responsive-xl'
          >
            <thead>
              <tr style={{ height: 56, color: "#FFF" }}>
                <th style={{ backgroundColor: "#19226D" }}>Patient</th>
                <th style={{ backgroundColor: "#19226D" }}>
                  Prescribing
                  <br /> Doctor
                </th>
                <th style={{ backgroundColor: "#19226D" }}>RX Details</th>
                <th style={{ backgroundColor: "#19226D" }}>
                  Prescribing
                  <br /> Clinic
                </th>
                <th style={{ backgroundColor: "#19226D" }}>
                  Prescription
                  <br /> Type
                </th>
                <th style={{ backgroundColor: "#19226D" }}>
                  Prescription
                  <br /> Date
                </th>
                <th style={{ backgroundColor: "#19226D" }}>
                  Dispensing
                  <br /> Status
                </th>
                <th style={{ backgroundColor: "#19226D" }}>Action</th>
              </tr>
            </thead>

            <tbody>
              <>
                {data?.map((data) => (
                  <tr className='mt-2 mb-2'>
                    <td>{data?.name}</td>
                    <td>{data?.doctor}</td>
                    <td>{data?.rx}</td>
                    <td>{data?.clinic}</td>
                    <td>{data?.type}</td>
                    <td>{data?.date}</td>
                    <td>
                      <Button
                        className='mt-2 mb-2'
                        style={{
                          background: "rgba(0, 151, 220, 0.24)",
                          border: "1px rgba(0, 151, 220, 0.24) solid",
                          fontWeight: 800,
                        }}
                      >
                        {data?.status}
                      </Button>
                    </td>
                    <td>
                      <Button
                        style={{
                          backgroundColor: "#19226D",
                          border: "1px #19226D solid",
                        }}
                      >
                        Update
                      </Button>
                    </td>
                  </tr>
                ))}
              </>
            </tbody>
          </table>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default PharmacistDashboard