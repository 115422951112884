import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {
  patientLogin,
  selectPatientData,
} from '../../redux_setup/slices/patientDataSlice'

const SignupNextPage = () => {
  const [termsAgree, setTermsAgree] = useState(false)
  const patient = useSelector(selectPatientData)
  const [dataToSend, setDataToSend] = useState(patient)
  const [phoneState, setPhoneState] = useState('')
  let navigate = useNavigate()
  toast.configure()
  const dispatch = useDispatch()
  const validate = (data) => {
    let obj = {
      msg: '',
      isValidated: true,
    }
    if (data.gender === '' && patient.gender === '') {
      obj.msg = 'Gender field is required'
      obj.isValidated = false
    }
    return obj
  }
  const handleSubmit = () => {
    const isValidated = validate(dataToSend)
    if (!isValidated.isValidated) {
      return toast.warning(isValidated.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      })
    } else {
      navigate('/password-setup')
    }
  }
  return (
    <div>
      <div
        className="signup-container"
        style={{
          background:
            'linear-gradient(180deg, rgba(69, 183, 87, 0.6) 0%, rgba(47, 165, 219, 0.6) 100%)',
        }}
      >
        <div style={{ marginLeft: '0' }} className="content-body">
          <div className="card card pt-5 pb-3 signup-card">
            <div className="d-flex justify-content-center align-items-center">
              <p
                style={{
                  fontSize: 24,
                  fontWeight: 400,
                  fontFamily: 'Lato',
                  color: '#19226D',
                  marginBottom: -20,
                }}
              >
                Welcome To
              </p>
            </div>
            <div className="text-center" style={{ width: '100%' }}>
              <div className="filipelogos" style={{ marginBottom: -20 }}></div>
              <p
                style={{
                  fontSize: 24,
                  fontWeight: 400,
                  fontFamily: 'Lato',
                  color: '#19226D',
                }}
              >
                Patients Care Plan
              </p>
            </div>
            <div className="ml-5">
              <p
                style={{
                  fontSize: 20,
                  fontWeight: 700,
                  fontFamily: 'Lato',
                  color: '#000',
                }}
              >
                Finish setting up
              </p>
              <fom onSubmit={handleSubmit}>
                <div className="row">
                  <div
                    className="col-md-4 col-lg-4 col-sm-12"
                    style={{ marginRight: -20 }}
                  >
                    <label>Address</label>
                    <Form.Control
                      type="text"
                      placeholder="Enter address"
                      spellcheck="true"
                      required
                      value={patient?.address}
                      onChange={(e) => {
                        setDataToSend({
                          ...dataToSend,
                          address: e.target.value,
                        })
                        dispatch(
                          patientLogin({ ...patient, address: e.target.value }),
                        )
                      }}
                    />
                  </div>
                  <div
                    className="col-md-4 col-lg-4 col-sm-12"
                    style={{ marginRight: -20 }}
                  >
                    <label>
                      Gender <span style={{ color: 'red' }}>*</span>
                    </label>
                    <Form.Select
                      style={{
                        backgroundColor: 'transparent',
                        paddingLeft: 10,
                        height: 35,
                        border: '1px solid #96bfcf',
                        borderRadius: 5,
                      }}
                      value={patient?.gender}
                      onChange={(e) => {
                        setDataToSend({
                          ...dataToSend,
                          gender: e.target.value,
                        })
                        dispatch(
                          patientLogin({ ...patient, gender: e.target.value }),
                        )
                      }}
                    >
                      <option>Select gender</option>
                      <option>male</option>
                      <option>female</option>
                    </Form.Select>
                  </div>
                  <div className="col-md-4 col-lg-4 col-sm-12">
                    <label>Ethnicity</label>
                    <Form.Select
                      style={{
                        backgroundColor: 'transparent',
                        paddingLeft: 10,
                        height: 35,
                        border: '1px solid #96bfcf',
                        borderRadius: 5,
                      }}
                    >
                      <option>Select</option>
                      <option>Black</option>
                      <option>White</option>
                      <option>Black American</option>
                    </Form.Select>
                  </div>
                </div>
                <div className="row mt-2">
                  <div
                    className="col-md-4 col-lg-4 col-sm-12"
                    style={{ marginRight: -20 }}
                  >
                    <label>Next of kin details </label>
                    <Form.Control
                      type="text"
                      required
                      placeholder="Enter name"
                      spellcheck="true"
                      value={patient?.nextkin?.name}
                      onChange={(e) => {
                        const nextkin = { ...patient?.nextkin }
                        nextkin.name = e.target.value

                        dispatch(
                          patientLogin({
                            ...patient,
                            nextkin: nextkin,
                          }),
                        )
                      }}
                    />
                  </div>
                  <div
                    className="col-md-4 col-lg-4 col-sm-12"
                    style={{ marginRight: -20 }}
                  >
                    <label>
                      {/* Next of kin details{" "} */}
                      <span style={{ color: 'red' }}>&nbsp;</span>{' '}
                    </label>
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={patient?.nextkin?.phone}
                      // onBlur={() =>
                      //   setDataToSend({ ...dataToSend, phone: phoneState })
                      // }
                      onBlur={(e) => {
                        const nextkin = { ...patient?.nextkin }
                        nextkin.phone = e.target.value

                        dispatch(
                          patientLogin({
                            ...patient,
                            nextkin: nextkin,
                          }),
                        )
                      }}
                      onChange={setPhoneState}
                      style={{ height: 40 }}
                    />
                  </div>
                  <div className="col-md-4 col-lg-4 col-sm-12">
                    <label>
                      {/* Next of kin details{" "} */}
                      <span style={{ color: 'red' }}>&nbsp;</span>{' '}
                    </label>
                    <Form.Select
                      style={{
                        backgroundColor: 'transparent',
                        paddingLeft: 10,
                        height: 35,
                        border: '1px solid #96bfcf',
                        borderRadius: 5,
                      }}
                      onChange={(e) => {
                        const nextkin = { ...patient?.nextkin }
                        nextkin.relationship = e.target.value

                        dispatch(
                          patientLogin({
                            ...patient,
                            nextkin: nextkin,
                          }),
                        )
                      }}
                    >
                      <option>Relationship</option>
                      <option>Parent</option>
                      <option>Spouse</option>
                      <option>Brother</option>
                      <option>Sister</option>
                    </Form.Select>
                  </div>
                </div>
                <div className="row mt-2">
                  <div
                    className="col-md-4 col-lg-4 col-sm-12"
                    style={{ marginRight: -20 }}
                  >
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      spellcheck="true"
                      required
                    />
                  </div>
                  <div
                    className="col-md-8 col-lg-8 col-sm-12"
                    style={{ marginRight: -20 }}
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter address"
                      spellcheck="true"
                      required
                    />
                  </div>
                </div>
                <Link to="/terms-of-service" target="_blank">
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      fontFamily: 'Lato',
                      color: '#0193D9',
                      marginTop: '8%',
                      marginBottom: '5%',
                      cursor: 'pointer',
                    }}
                  >
                    Terms & Conditions
                  </p>
                </Link>
                <Form.Check
                  label="I agree to the above terms of service."
                  id="terms"
                  onChange={(e) => setTermsAgree(e.target.checked)}
                />
                <div className="d-flex " style={{ gap: 20 }}>
                  <Link to="/care-plan-signup">
                    <Button
                      className="mt-4"
                      variant="success"
                      style={{ width: 171, height: 40, fontWeight: 700 }}
                    >
                      <i className="fas fa-arrow-left mr-2" />
                      Back
                    </Button>
                  </Link>
                  <Button
                    className="mt-4"
                    variant="success"
                    style={{ width: 171, height: 40, fontWeight: 700 }}
                    onClick={handleSubmit}
                    disabled={!termsAgree}
                  >
                    Continue
                  </Button>
                </div>
              </fom>
            </div>
          </div>
        </div>
      </div>

      {/***********************************
            Footer start
        ************************************/}
      {/***********************************
            Footer end
        ************************************/}
    </div>
  )
}

export default SignupNextPage
