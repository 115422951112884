import React from 'react'
import { Button, Form } from 'react-bootstrap';

const Vitals = () => {
  return (
    <>
      <div className='d-flex flex-wrap mb-2' style={{ gap: 10 }}>
        <div
          className='card pl-2 pt-3 pb-2'
          style={{ width: 150, backgroundColor: "#FF005C26" }}
        >
          <div className='d-flex flex-wrap' style={{ gap: 6 }}>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/unlock.png`}
              width={14}
              height={14}
              alt='unlock'
              className='mt-2'
            />
            <p>Blood Pressure</p>
          </div>
          <div className='d-flex flex-wrap' style={{ position: "relative" }}>
            <span
              style={{
                fontSize: 20,
                fontFamily: "Lato",
                fontWeight: 700,
                color: "#52575C",
              }}
            >
              120/129
            </span>
            <span
              style={{
                fontSize: 10,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
                position: "absolute",
                bottom: 0,
                right: 30,
              }}
            >
              mm/Hg
            </span>
          </div>
          <Button
            className='mt-2'
            style={{
              width: 132,
              height: 22,
              backgroundColor: "#52575C26",
              fontSize: 13,
              padding: 0,
              color: "#52575C",
            }}
          >
            High
          </Button>
        </div>
        <div
          className='card pl-2 pt-3 pb-2'
          style={{ width: 150, backgroundColor: "#07B0D726" }}
        >
          <div className='d-flex flex-wrap' style={{ gap: 6 }}>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/heart-icon.png`}
              width={14}
              height={14}
              alt='unlock'
              className='mt-2'
            />
            <p>Heart Rate</p>
          </div>
          <div className='d-flex flex-wrap' style={{ gap: 5 }}>
            <span
              style={{
                fontSize: 20,
                fontFamily: "Lato",
                fontWeight: 700,
                color: "#52575C",
              }}
            >
              105
            </span>
            <span
              style={{
                fontSize: 10,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
              className='mt-3'
            >
              Beats/min
            </span>
          </div>
          <Button
            className='mt-2'
            style={{
              width: 132,
              height: 22,
              backgroundColor: "#52575C26",
              fontSize: 13,
              padding: 0,
              color: "#52575C",
            }}
          >
            High
          </Button>
        </div>
        <div
          className='card pl-2 pt-3 pb-2'
          style={{ width: 165, backgroundColor: "#FFC00026" }}
        >
          <div className='d-flex flex-wrap' style={{ gap: 6 }}>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/lungs.png`}
              width={14}
              height={14}
              alt='unlock'
              className='mt-2'
            />
            <p style={{ whiteSpace: "nowrap" }}>Respiration Rate</p>
          </div>
          <div className='d-flex flex-wrap' style={{ gap: 5 }}>
            <span
              style={{
                fontSize: 20,
                fontFamily: "Lato",
                fontWeight: 700,
                color: "#52575C",
              }}
            >
              11
            </span>
            <span
              style={{
                fontSize: 10,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
              className='mt-3'
            >
              Beats/min
            </span>
          </div>
          <Button
            className='mt-2'
            style={{
              width: 132,
              height: 22,
              backgroundColor: "#52575C26",
              fontSize: 13,
              padding: 0,
              color: "#52575C",
            }}
          >
            High
          </Button>
        </div>
      </div>
      <div className='d-flex flex-wrap mb-2' style={{ gap: 10 }}>
        <div
          className='card pl-2 pt-3 pb-2'
          style={{ width: 150, backgroundColor: "#97CE5126" }}
        >
          <p>Blood Pressure</p>
          <span
            className='mb-2'
            style={{ fontSize: 10, color: "#45B757", fontWeight: 600 }}
          >
            Grade 1
          </span>
          <Form.Range
            min={0}
            max={10}
            // value={snacks}
            style={{
              width: 127,
              marginRight: 10,
            }}
          />
          <Button
            className='mt-2'
            style={{
              width: 132,
              height: 22,
              backgroundColor: "#52575C26",
              fontSize: 13,
              padding: 0,
              color: "#52575C",
            }}
          >
            Barely Audible
          </Button>
        </div>
        <div
          className='card pl-2 pt-3 pb-2'
          style={{ width: 150, backgroundColor: "#66238226" }}
        >
          <p>Diastolic Murmurs</p>
          <span
            className='mb-2'
            style={{ fontSize: 10, color: "#45B757", fontWeight: 600 }}
          >
            Grade 2
          </span>
          <Form.Range
            min={0}
            max={10}
            // value={snacks}
            style={{
              width: 127,
              marginRight: 10,
            }}
          />
          <Button
            className='mt-2'
            style={{
              width: 132,
              height: 22,
              backgroundColor: "#52575C26",
              fontSize: 13,
              padding: 0,
              color: "#52575C",
            }}
          >
            Audible But Soft
          </Button>
        </div>
        <div
          className='card pl-2 pt-3 pb-2'
          style={{ width: 150, height: 142, backgroundColor: "#52575C26" }}
        >
          <p style={{ fontSize: 14 }}>Body Temperature</p>
          <div className='d-flex flex-wrap' style={{ position: "relative" }}>
            <span
              style={{
                fontSize: 20,
                fontFamily: "Lato",
                fontWeight: 700,
                color: "#52575C",
              }}
            >
              39
            </span>
            <span
              style={{
                fontSize: 10,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
                position: "absolute",
                bottom: 0,
                left: 25,
              }}
            >
              &#8451;
            </span>
          </div>
          <Button
            className='mt-4'
            style={{
              width: 132,
              height: 22,
              backgroundColor: "#52575C26",
              fontSize: 13,
              padding: 0,
              color: "#52575C",
            }}
          >
            Audible But Soft
          </Button>
        </div>
      </div>
    </>
  );
}

export default Vitals;