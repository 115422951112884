import Header from "../../components/Header";
import LeftBar from "../../components/LeftBar";
import Footer from "../../components/Footer";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
const data = [
  {
    drugClass: "Analgesics and antipyretics",
    drugName: "Paracetamol   (500mg) Tablets",
    dosage: "1 or 2 tablets ",
    route: "Oral",
    frequency: "every 4-6 hours",
    amount: 1,
    refill: 1,
  },
  {
    drugClass: "Penicillins, Amino",
    drugName: "Amoxicillin  (500mg) Capsules",
    dosage: "1 tablet After Meals",
    route: "Oral",
    frequency: "every 8 or 12 hours",
    amount: 1,
    refill: 1,
  },
  {
    drugClass: "Reflux suppressant",
    drugName: "Gaviscon Syrup",
    dosage: "10ml after meals",
    route: "Oral",
    frequency: "every 4-6 hours",
    amount: 1,
    refill: 0,
  },
];
const DispensationSummary = () => {
  return (
    <div id='main-wrapper'>
      {/***********************************
Header start
************************************/}
      <Header />
      {/***********************************
Header end ti-comment-alt
************************************/}
      {/***********************************
Sidebar start
************************************/}
      <LeftBar />
      {/***********************************
Sidebar end
************************************/}
      {/***********************************
Content body start
************************************/}

      {/***********************************
      Sidebar end
  ************************************/}
      {/***********************************
      Content body start
  ************************************/}

      <div className='content-body'>
        <div className='card pt-5 pl-4'>
          <p
            style={{
              fontSize: 20,
              fontWeight: 700,
              color: "#45B757",
              fontFamily: "Lato",
            }}
            className='mt-4 mb-5'
          >
            Rx #123445
          </p>
          <div
            className='mb-3'
            style={{ width: "95%", height: 2, backgroundColor: "#E8E8E8" }}
          />
          {/* Doctor identification Section */}
          <div className='d-flex flex-wrap justify-content-between mt-5'>
            <div className='d-flex' style={{ gap: 30 }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/clinicklogo.png`}
                width={139}
                height={53}
                alt='clinic logo'
              />
              <div className='flex__block'>
                <span className='header__title' style={{ color: "#25282B" }}>
                  Street Name, Suit 00, State,
                  <br />
                  Zip Code, Country
                </span>
                <span className='header__title'>
                  <span style={{ color: "#45B757" }}>t:&nbsp;&nbsp;</span>+123
                  00 456 7890
                </span>
                <span className='header__title'>
                  <span style={{ color: "#45B757" }}>e:&nbsp;&nbsp;</span>
                  email@doctorschamber.com
                </span>
              </div>
            </div>
            <div className='d-flex mr-5' style={{ gap: 30 }}>
              <div>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: "Lato",
                    color: "#2FA5DB",
                  }}
                >
                  DOCTOR DETAILS
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 400,
                    fontFamily: "Lato",
                    color: "#000",
                  }}
                >
                  NAME: DR. FRED WANJALA
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 400,
                    fontFamily: "Lato",
                    color: "#000",
                  }}
                >
                  REG: 1234567890
                </p>
                <img
                  src={`${process.env.PUBLIC_URL}/images/doctor-signature.png`}
                  width={124}
                  height={74}
                />
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 400,
                    fontFamily: "Lato",
                    color: "#000",
                  }}
                >
                  {moment().format("MM/DD/YYYY")}
                </p>
              </div>
              <Button
                className='mt-5'
                style={{
                  background: "rgba(110, 227, 11, 0.24)",
                  border: "1px rgba(110, 227, 11, 0.24) solid",
                  width: "94px",
                  height: 40,
                  color: "#000",
                }}
              >
                Available
              </Button>
            </div>
          </div>
          {/* patient details section */}
          <div className='d-flex flex-wrap justify-content-between'>
            <div>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: 20,
                  fontFamily: "Lato",
                  color: "#2FA5DB",
                }}
              >
                PATIENT’S DETAILS
              </p>
              <div>
                <div className='d-flex' style={{ gap: 30 }}>
                  <p style={{ fontSize: 16, fontWeight: 700 }}>Name</p>
                  <p style={{ fontSize: 14, fontWeight: 400 }}>Patient One</p>
                </div>
                <div className='d-flex' style={{ gap: 50 }}>
                  <p style={{ fontSize: 16, fontWeight: 700 }}>Age</p>
                  <p style={{ fontSize: 14, fontWeight: 400 }}>50</p>
                </div>
                <div className='d-flex' style={{ gap: 50 }}>
                  <p style={{ fontSize: 16, fontWeight: 700 }}>Sex</p>
                  <p style={{ fontSize: 14, fontWeight: 400 }}>Female</p>
                </div>
                <div className='d-flex' style={{ gap: 20 }}>
                  <p style={{ fontSize: 16, fontWeight: 700 }}>Weight</p>
                  <p style={{ fontSize: 14, fontWeight: 400 }}>68kgs</p>
                </div>
              </div>
            </div>
            <div className='mt-5'>
              <div className='d-flex' style={{ gap: 30 }}>
                <p style={{ fontSize: 16, fontWeight: 700 }}>BMI</p>
                <p style={{ fontSize: 14, fontWeight: 400 }}>20.29</p>
              </div>
              <div className='d-flex' style={{ gap: 30 }}>
                <p style={{ fontSize: 16, fontWeight: 700 }}>Diagnosis</p>
                <p style={{ fontSize: 14, fontWeight: 400 }}>Type 2 Diabetes</p>
              </div>
              <div className='d-flex' style={{ gap: 30 }}>
                <p style={{ fontSize: 16, fontWeight: 700 }}>
                  Underlying Conditions
                </p>
                <p style={{ fontSize: 14, fontWeight: 400 }}>Hypertension</p>
              </div>
              <div className='d-flex' style={{ gap: 30 }}>
                <p style={{ fontSize: 16, fontWeight: 700 }}>
                  Last Prescription
                </p>
                <p style={{ fontSize: 14, fontWeight: 400 }}>
                  Amoxil 500mg Tabs
                </p>
              </div>
            </div>
            <div className='mt-5 mr-5'>
              <div className='d-flex' style={{ gap: 30 }}>
                <p style={{ fontSize: 16, fontWeight: 700 }}>
                  Current Medication
                </p>
                <p style={{ fontSize: 14, fontWeight: 400 }}>None</p>
              </div>
              <div className='d-flex' style={{ gap: 30 }}>
                <p style={{ fontSize: 16, fontWeight: 700 }}>
                  No. of Prescribes Refills
                </p>
                <p style={{ fontSize: 14, fontWeight: 400 }}>1</p>
              </div>
              <div className='d-flex' style={{ gap: 30 }}>
                <p style={{ fontSize: 16, fontWeight: 700 }}>
                  Prev. No. of Refills
                </p>
                <p style={{ fontSize: 14, fontWeight: 400 }}>5</p>
              </div>
              <div className='d-flex' style={{ gap: 30 }}>
                <p style={{ fontSize: 16, fontWeight: 700 }}>
                  Pending No. of Refills
                </p>
                <p style={{ fontSize: 14, fontWeight: 400 }}>None</p>
              </div>
            </div>
          </div>
          <div
            className='mb-3'
            style={{ width: "95%", height: 2, backgroundColor: "#E8E8E8" }}
          />
          <div className='d-flex' style={{ gap: 30 }}>
            <p style={{ fontSize: 16, fontWeight: 700 }}>Phone number</p>
            <p style={{ fontSize: 14, fontWeight: 400 }}>+254 712 345 678</p>
          </div>
          <div className='d-flex' style={{ gap: 30 }}>
            <p style={{ fontSize: 16, fontWeight: 700 }}>Email address</p>
            <p style={{ fontSize: 14, fontWeight: 400 }}>
              patientone@gmail.com
            </p>
          </div>
        </div>
        <p
          className='mt-2 mb-3'
          style={{ fontSize: 20, fontFamily: "Lato", fontWeight: 700 }}
        >
          RX Details
        </p>
        <div className='card pt-3 pl-3'>
          <div className='table-responsive card-table'>
            <table
              id='example5'
              className='display dataTablesCard table-responsive-xl'
            >
              <thead>
                <tr>
                  <th>DRUG CLASS</th>
                  <th>DRUG NAME</th>
                  <th>DOSAGE</th>
                  <th>ROUTE &nbsp; &nbsp;</th>
                  <th>FREQUENCY</th>
                  <th>
                    AMT TO BE DISPENSED
                    <br /> BY PHARMACY
                  </th>
                  <th>&nbsp; &nbsp;NO. OF REFILLS</th>
                </tr>
              </thead>

              <tbody>
                <>
                  {data?.map((data) => (
                    <tr className='mt-2 mb-2'>
                      <td>{data?.drugClass}</td>
                      <td>{data?.drugName}</td>
                      <td>{data?.dosage}</td>
                      <td>{data?.route}</td>
                      <td>{data?.frequency}</td>
                      <td>{data?.amount}</td>
                      <td>{data?.refill}</td>
                    </tr>
                  ))}
                </>
              </tbody>
            </table>
          </div>
          <p
            className='mt-4 mb-3'
            style={{ fontSize: 16, fontFamily: "Lato", fontWeight: 700 }}
          >
            Additional Directions of use
          </p>
          <p
            className='mt-2 mb-3'
            style={{
              fontSize: 14,
              fontFamily: "Lato",
              fontWeight: 400,
              color: "#52575C",
            }}
          >
            This is additional information about this medication to this
            patient. Please take it seriously.
          </p>
          <div className='d-flex justify-content-end'>
            <Button
              className='mr-5 mt-5 mb-5'
              style={{ backgroundColor: "#19226D", width: 166, height: 40 }}
            >
              Edit Rx
            </Button>
          </div>
        </div>
        <div className='table-responsive card-table'>
          <table
            id='example5'
            className='display dataTablesCard table-responsive-xl'
          >
            <thead>
              <tr>
                <th style={{ backgroundColor: "#FFFF" }}>
                  <p
                    className='mt-4 mb-3'
                    style={{
                      fontSize: 20,
                      fontFamily: "Lato",
                      fontWeight: 700,
                      color: "#25282B",
                    }}
                  >
                    Inventory Status
                  </p>
                </th>
                <th style={{ backgroundColor: "#FFFF" }}>
                  <p
                    className='mt-4 mb-3'
                    style={{
                      fontSize: 20,
                      fontFamily: "Lato",
                      fontWeight: 700,
                      color: "#25282B",
                    }}
                  >
                    Billing Details
                  </p>
                </th>
                <th style={{ backgroundColor: "#FFFF" }}>
                  <p
                    className='mt-4 mb-3'
                    style={{
                      fontSize: 20,
                      fontFamily: "Lato",
                      fontWeight: 700,
                      color: "#25282B",
                    }}
                  >
                    Factors to Note
                  </p>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <div className='d-flex' style={{ gap: 30 }}>
                    <p style={{ fontSize: 16, fontWeight: 700 }}>Pack Size</p>
                    <p style={{ fontSize: 14, fontWeight: 400 }}>Doctor One</p>
                  </div>
                  <div className='d-flex' style={{ gap: 30 }}>
                    <p style={{ fontSize: 16, fontWeight: 700 }}>
                      Available Quantity
                    </p>
                    <p style={{ fontSize: 14, fontWeight: 400 }}>2 Pkts</p>
                  </div>
                  <div className='d-flex' style={{ gap: 30 }}>
                    <p style={{ fontSize: 16, fontWeight: 700 }}>Drug Tier</p>
                    <p style={{ fontSize: 14, fontWeight: 400 }}>Best</p>
                  </div>
                </td>
                <td>
                  <div className='d-flex' style={{ gap: 20 }}>
                    <p style={{ fontSize: 16, fontWeight: 700 }}>
                      Patient Payment Method
                    </p>
                    <p style={{ fontSize: 14, fontWeight: 400 }}>Cash</p>
                  </div>
                  <div className='d-flex' style={{ gap: 20 }}>
                    <p style={{ fontSize: 16, fontWeight: 700 }}>
                      Drug Billing Type
                    </p>
                    <p style={{ fontSize: 14, fontWeight: 400 }}>Cash</p>
                  </div>
                </td>
                <td>
                  <span
                    style={{
                      fontFamily: "Lato",
                      fontSize: 16,
                      fontWeight: 700,
                    }}
                  >
                    Current Medications & Possible Drug
                    <br /> Interactions with Prescribed Drug
                  </span>
                  <p
                    className='mt-3'
                    style={{
                      fontFamily: "Lato",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    State the lethal interaction
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='card pl-4 pt-4 pb-5 mt-3'>
          <p
            className='mt-2 mb-3'
            style={{
              fontSize: 16,
              fontFamily: "Lato",
              fontWeight: 700,
              color: "#000",
            }}
          >
            Submit amended Rx and share with:
          </p>
          <div style={{ width: 250 }}>
            <div className='d-flex justify-content-between'>
              <Form.Check
                id='doctor'
                label='Doctor'
                type='radio'
                name='submit'
              />
              <Form.Check
                id='patient'
                label='Patient'
                type='radio'
                name='submit'
              />
            </div>
            <Button className='mt-3' style={{ backgroundColor: "#19226D" }}>
              Submit
            </Button>
          </div>
        </div>
        <div className='d-flex justify-content-end' style={{ gap: 30 }}>
          <Button style={{ backgroundColor: "#19226D" }}>Back to Queue</Button>
          <Link to='/pharmacist-dashboard'>
            <Button style={{ backgroundColor: "#19226D" }}>
              Back to Dashboard
            </Button>
          </Link>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default DispensationSummary;
