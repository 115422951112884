import { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../../Header";
import Footer from "../../Footer";
import LeftBar from "../../LeftBar";
import Vitals from "../blood-pressure";
import PatientDetails from "../PatientDetails";
import Invite from "../Invite";
import { Button, Form } from "react-bootstrap";
import WeightLoss from "./WeightLoss";
import Dietary from "./Dietary";
import { toast } from "react-toastify";
import { selectUser } from "../../../redux_setup/slices/userSlice";
import { selectPatientData } from "../../../redux_setup/slices/patientDataSlice";
import "react-toastify/dist/ReactToastify.css";
const NutritionWeightLoss = () => {
  const [showWeightLoss, setShowWeightLoss] = useState(false);
  const [showDietary, setShowDietary] = useState(false);
  const [weightLossData, setWeightLossData] = useState({});
  const [dietaryData, setDietaryData] = useState({});
  toast.configure();
  const user = useSelector(selectUser);
  const patient = useSelector(selectPatientData);
  // Receive data from child  component
  const receiveData = (data) => setWeightLossData({ ...weightLossData, data });
  const receiveDietaryData = (data) => setDietaryData({ ...dietaryData, data });
  let dataToSend = {
    patient_id: patient.id,
    doctor_id: user.user_id,
  };
  dataToSend = { ...dataToSend, ...weightLossData.data, ...dietaryData.data };
  const handleSubmit = () => {
    fetch(
      `${process.env.REACT_APP_MAIN_URL_HYPERTENSION}/api/goals/nutrition`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + user.token,
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(dataToSend),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        // navigate("/careplan-weight-loss");
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
          });
        }
        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        });
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        return toast.warning("An error has occured while reaching the server", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        });
      });
  };
  return (
    <div className='main-wrapper'>
      {/***********************************
                  Header start
                  ************************************/}
      <Header />
      {/***********************************
                  Header end ti-comment-alt
                  ************************************/}
      {/***********************************
                  Sidebar start
                  ************************************/}
      <LeftBar />
      {/***********************************
                  Sidebar end
                  ************************************/}
      {/***********************************
                  Content body start
                  ************************************/}

      {/***********************************
                  Content body end
                  ************************************/}
      <div className='content-body'>
        <p
          className='mt-3 mb-3'
          style={{
            fontSize: 20,
            fontFamily: "Lato",
            fontWeight: 700,
            color: "#000",
          }}
        >
          Patient Details
        </p>
        <div className='row'>
          <div className='col-md-7 col-lg-7 col-sm-12'>
            <PatientDetails />
            <p
              className='mt-3 mb-3'
              style={{
                fontSize: 20,
                fontFamily: "Lato",
                fontWeight: 700,
                color: "#000",
              }}
            >
              Nutrition & Diet Health Outcomes
            </p>
            <div className='card pt-4 pl-3' style={{ width: 654, height: 84 }}>
              <div className='d-flex' style={{ gap: 100 }}>
                <div className='d-flex ml-4' style={{ gap: 10 }}>
                  <Form.Check
                    id='weight loss'
                    className='mt-1'
                    onChange={(e) => setShowWeightLoss(e.target.checked)}
                  />
                  <label
                    htmlFor='weight loss'
                    style={{
                      fontFamily: "Lato",
                      fontSize: 18,
                      fontWeight: 400,
                      color: "#616161",
                    }}
                  >
                    Weight Loss
                  </label>
                </div>
                <div className='d-flex ml-4' style={{ gap: 10 }}>
                  <Form.Check id='weight maintenance' className='mt-1' />
                  <label
                    htmlFor='weight maintenance'
                    style={{
                      fontFamily: "Lato",
                      fontSize: 18,
                      fontWeight: 400,
                      color: "#616161",
                    }}
                  >
                    Weight maintenance
                  </label>
                </div>
                <div className='d-flex ml-4' style={{ gap: 10 }}>
                  <Form.Check
                    id='dietary'
                    className='mt-1'
                    onChange={(e) => setShowDietary(e.target.checked)}
                  />
                  <label
                    htmlFor='dietary'
                    style={{
                      fontFamily: "Lato",
                      fontSize: 18,
                      fontWeight: 400,
                      color: "#616161",
                    }}
                  >
                    Dietary
                  </label>
                </div>
              </div>
            </div>
            {showWeightLoss ? (
              <WeightLoss sendDataToParent={receiveData} />
            ) : (
              <></>
            )}
            {showDietary ? (
              <Dietary sendDataToParent={receiveDietaryData} />
            ) : (
              <></>
            )}
            <Invite />
          </div>
          <div className='col-md-5 col-lg-5 col-sm-12'>
            <Vitals />
          </div>
        </div>
        <div className='d-flex justify-content-end' style={{ gap: 15 }}>
          <Link to='/care-plan-vitals'>
            <Button variant='outline-primary'>
              <i class='fa fa-arrow-left mr-3' />
              Back
            </Button>
          </Link>
          {/* <Link to='/careplan-activity-level'> */}
          <Button variant='primary' onClick={handleSubmit}>
            Next
            {/* <i class='fa fa-arrow-right ml-3' /> */}
          </Button>
          {/* </Link> */}
        </div>
      </div>
      {/***********************************
                  Footer start
                  ************************************/}
      <Footer />
      {/***********************************
                  Footer end
                  ************************************/}
    </div>
  );
};
export default NutritionWeightLoss;
