import Footer from "../../Footer";
import LeftBar from "../../LeftBar";
import Header from "../../Header";
import NutritionAndDiet from "./NutritionAndDiet";
import AcitvityAndExercise from "./AcitvityAndExercise";
import Notes from "./Notes";
import Habits from "./Habits";
import Smoking from "./Smoking";
import Substances from "./Substances";
import SexualFunction from "./SexualFunction";
import MentalHealth from "./MentalHealth";
import EnergyLevel from "./EnergyLevel";
import SocialSupport from "./SocialSupport";
import Appetite from "./Appetite";
import { Button } from "react-bootstrap";
const NewLifeStyleReview = () => {
  return (
    <div className='main-wrapper'>
      {/***********************************
                  Header start
                  ************************************/}
      <Header />
      {/***********************************
                  Header end ti-comment-alt
                  ************************************/}
      {/***********************************
                  Sidebar start
                  ************************************/}
      <LeftBar />
      {/***********************************
                  Sidebar end
                  ************************************/}
      {/***********************************
                  Content body start
                  ************************************/}

      {/***********************************
                  Content body end
                  ************************************/}
      <div className='content-body'>
        <p
          className='mt-3 mb-3'
          style={{
            fontSize: 20,
            fontFamily: "Lato",
            fontWeight: 700,
            color: "#000",
          }}
        >
          Lifestyle Review
        </p>
        <NutritionAndDiet />
        <Notes />
        <Appetite />
        <Notes />
        <AcitvityAndExercise />
        <Notes />
        <EnergyLevel />
        <Notes />
        {/* Habits */}
        <Habits />
        <Notes />
        <Smoking />
        <Notes />
        <Substances />
        <Notes />
        {/* Sexual Function */}
        <SexualFunction />
        <Notes />
        {/* Mental Health */}
        <MentalHealth />
        <Notes />
        <SocialSupport />
        <Notes />
        <div className='d-flex justify-content-end'>
          <Button style={{ width: 216 }} variant='success'>
            Submit
          </Button>
        </div>
      </div>

      {/***********************************
                  Footer start
                  ************************************/}
      <Footer />
      {/***********************************
                  Footer end
                  ************************************/}
    </div>
  );
};

export default NewLifeStyleReview;
