import { useState } from "react";
import Header from "../../components/Header";
import LeftBar from "../../components/LeftBar";
import Footer from "../../components/Footer";
import { Button, Form } from "react-bootstrap";
import moment from "moment";
const data = [
  {
    drugClass: "Analgesics and antipyretics",
    drugName: "Paracetamol   (500mg) Tablets",
    dosage: "1 or 2 tablets ",
    route: "Oral",
    frequency: "every 4-6 hours",
    amount: 1,
    refill: 1,
  },
  {
    drugClass: "Penicillins, Amino",
    drugName: "Amoxicillin  (500mg) Capsules",
    dosage: "1 tablet After Meals",
    route: "Oral",
    frequency: "every 8 or 12 hours",
    amount: 1,
    refill: 1,
  },
  {
    drugClass: "Reflux suppressant",
    drugName: "Gaviscon Syrup",
    dosage: "10ml after meals",
    route: "Oral",
    frequency: "every 4-6 hours",
    amount: 1,
    refill: 0,
  },
];
const ExternalDispensationSummary = () => {
    const [sendEmail, setSendEmail] = useState(false)
    const [printPdf, setPrintPdf] = useState(false)
    const [share, setShare] = useState(false)
  return (
    <div id='main-wrapper'>
      {/***********************************
Header start
************************************/}
      <Header />
      {/***********************************
Header end ti-comment-alt
************************************/}
      {/***********************************
Sidebar start
************************************/}
      <LeftBar />
      {/***********************************
Sidebar end
************************************/}
      {/***********************************
Content body start
************************************/}

      {/***********************************
      Sidebar end
  ************************************/}
      {/***********************************
      Content body start
  ************************************/}

      <div className='content-body'>
        <p
          className='mt-2 mb-3'
          style={{ fontSize: 20, fontFamily: "Lato", fontWeight: 700 }}
        >
          External Dispensation Summary
        </p>
        <div className='card pl-2 pt-5 pb-3'>
          <div className='d-flex flex-wrap justify-content-between mr-4'>
            <p
              className='mt-2 mb-3'
              style={{
                fontSize: 20,
                fontFamily: "Lato",
                fontWeight: 700,
                color: "#45B757",
              }}
            >
              Rx #789123
            </p>
            <div>
              <span
                style={{
                  fontSize: 16,
                  fontFamily: "Lato",
                  fontWeight: 700,
                  color: "#2FA5DB",
                }}
              >
                {moment().format("MM/DD/YYYY")}
                <br />
                {moment().format("hh:mm:ss A")}
              </span>
            </div>
          </div>
          <div
            className='mb-3'
            style={{ width: "95%", height: 2, backgroundColor: "#E8E8E8" }}
          />
          <p
            className='mt-2 mb-3'
            style={{
              fontSize: 20,
              fontFamily: "Lato",
              fontWeight: 700,
              color: "#45B757",
            }}
          >
            Patient Details
          </p>
          <div className='d-flex flex-wrap justify-content-between mr-4 mb-5'>
            <div>
              <div className='d-flex' style={{ gap: 30 }}>
                <p style={{ fontSize: 16, fontWeight: 700 }}>Name</p>
                <p style={{ fontSize: 14, fontWeight: 400 }}>Patient One</p>
              </div>
              <div className='d-flex' style={{ gap: 50 }}>
                <p style={{ fontSize: 16, fontWeight: 700 }}>Age</p>
                <p style={{ fontSize: 14, fontWeight: 400 }}>70Yrs</p>
              </div>
            </div>
            <div>
              <div className='d-flex' style={{ gap: 30 }}>
                <p style={{ fontSize: 16, fontWeight: 700 }}>Sex</p>
                <p style={{ fontSize: 14, fontWeight: 400 }}>Male</p>
              </div>
              <div className='d-flex' style={{ gap: 50 }}>
                <p style={{ fontSize: 16, fontWeight: 700 }}>Weight</p>
                <p style={{ fontSize: 14, fontWeight: 400 }}>104kgs</p>
              </div>
            </div>
            <div>
              <div className='d-flex' style={{ gap: 30 }}>
                <p style={{ fontSize: 16, fontWeight: 700 }}>Diagnosis</p>
                <p style={{ fontSize: 14, fontWeight: 400 }}>Diabetes</p>
              </div>
              <div className='d-flex' style={{ gap: 50 }}>
                <p style={{ fontSize: 16, fontWeight: 700 }}>
                  Underlying Condition
                </p>
                <p style={{ fontSize: 14, fontWeight: 400 }}>Disease</p>
              </div>
            </div>
          </div>
          <div
            className='mb-5'
            style={{ width: "95%", height: 2, backgroundColor: "#E8E8E8" }}
          />
          <div className='table-responsive card-table'>
            <table
              id='example5'
              className='display dataTablesCard table-responsive-xl'
            >
              <thead>
                <tr>
                  <th>DRUG CLASS</th>
                  <th>DRUG NAME</th>
                  <th>DOSAGE</th>
                  <th>ROUTE &nbsp; &nbsp;</th>
                  <th>FREQUENCY</th>
                  <th>
                    AMT TO BE DISPENSED
                    <br /> BY PHARMACY
                  </th>
                  <th>&nbsp; &nbsp;NO. OF REFILLS</th>
                </tr>
              </thead>

              <tbody>
                <>
                  {data?.map((data) => (
                    <tr className='mt-2 mb-2'>
                      <td>{data?.drugClass}</td>
                      <td>{data?.drugName}</td>
                      <td>{data?.dosage}</td>
                      <td>{data?.route}</td>
                      <td>{data?.frequency}</td>
                      <td>{data?.amount}</td>
                      <td>{data?.refill}</td>
                    </tr>
                  ))}
                </>
              </tbody>
            </table>
          </div>
          <p
            className='mt-2 mb-3'
            style={{
              fontSize: 16,
              fontFamily: "Lato",
              fontWeight: 700,
              color: "#000",
            }}
          >
            Additional Directions of use
          </p>
          <p
            className='mt-2 mb-3'
            style={{
              fontSize: 14,
              fontFamily: "Lato",
              fontWeight: 400,
              color: "#52575C",
            }}
          >
            This is additional information about this medication to this
            patient. Please take it seriously.
          </p>
          <div className='d-flex justify-content-between flex-wrap mr-5'>
            <div>
              <p
                className='mt-2 mb-3'
                style={{
                  fontSize: 20,
                  fontFamily: "Lato",
                  fontWeight: 700,
                  color: "#2FA5DB",
                }}
              >
                Clinic Details
              </p>
              <div>
                <div className='d-flex' style={{ gap: 30 }}>
                  <p style={{ fontSize: 16, fontWeight: 700 }}>Pack Size</p>
                  <p style={{ fontSize: 14, fontWeight: 400 }}>20</p>
                </div>
                <div className='d-flex' style={{ gap: 50 }}>
                  <p style={{ fontSize: 16, fontWeight: 700 }}>
                    Available Quantity
                  </p>
                  <p style={{ fontSize: 14, fontWeight: 400 }}>70</p>
                </div>
                <div className='d-flex' style={{ gap: 50 }}>
                  <p style={{ fontSize: 16, fontWeight: 700 }}>Drug Tier</p>
                  <p style={{ fontSize: 14, fontWeight: 400 }}>Best</p>
                </div>
              </div>
            </div>
            <div>
              <p
                className='mt-2 mb-3'
                style={{
                  fontSize: 20,
                  fontFamily: "Lato",
                  fontWeight: 700,
                  color: "#2FA5DB",
                }}
              >
                Doctor Details
              </p>
              <div>
                <div className='d-flex' style={{ gap: 30 }}>
                  <p style={{ fontSize: 16, fontWeight: 700 }}>
                    Patient Payment Method
                  </p>
                  <p style={{ fontSize: 14, fontWeight: 400 }}>Cash</p>
                </div>
                <div className='d-flex' style={{ gap: 50 }}>
                  <p style={{ fontSize: 16, fontWeight: 700 }}>
                    Drug Billing Type
                  </p>
                  <p style={{ fontSize: 14, fontWeight: 400 }}>Cash</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className='mb-5'
            style={{ width: "95%", height: 2, backgroundColor: "#E8E8E8" }}
          />
          <div className='table-responsive card-table'>
            <table
              id='example5'
              className='display dataTablesCard table-responsive-xl'
            >
              <thead>
                <tr>
                  <th style={{ backgroundColor: "#FFFF" }}>
                    <p
                      className='mt-4 mb-3'
                      style={{
                        fontSize: 20,
                        fontFamily: "Lato",
                        fontWeight: 700,
                        color: "#2FA5DB",
                      }}
                    >
                      Inventory Status
                    </p>
                  </th>
                  <th style={{ backgroundColor: "#FFFF" }}>
                    <p
                      className='mt-4 mb-3'
                      style={{
                        fontSize: 20,
                        fontFamily: "Lato",
                        fontWeight: 700,
                        color: "#2FA5DB",
                      }}
                    >
                      Billing Details
                    </p>
                  </th>
                  <th style={{ backgroundColor: "#FFFF" }}>
                    <p
                      className='mt-4 mb-3'
                      style={{
                        fontSize: 20,
                        fontFamily: "Lato",
                        fontWeight: 700,
                        color: "#2FA5DB",
                      }}
                    >
                      Factors to Note
                    </p>
                  </th>
                </tr>
                <div
                  className='mb-5'
                  style={{
                    width: "300%",
                    height: 2,
                    backgroundColor: "#E8E8E8",
                  }}
                />
              </thead>

              <tbody>
                <tr>
                  <td>
                    <div className='d-flex' style={{ gap: 30 }}>
                      <p style={{ fontSize: 16, fontWeight: 700 }}>Pack Size</p>
                      <p style={{ fontSize: 14, fontWeight: 400 }}>40</p>
                    </div>
                    <div className='d-flex' style={{ gap: 30 }}>
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        Available Quantity
                      </p>
                      <p style={{ fontSize: 14, fontWeight: 400 }}>2 Pkts</p>
                    </div>
                    <div className='d-flex' style={{ gap: 30 }}>
                      <p style={{ fontSize: 16, fontWeight: 700 }}>Drug Tier</p>
                      <p style={{ fontSize: 14, fontWeight: 400 }}>Best</p>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex' style={{ gap: 20 }}>
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        Patient Payment Method
                      </p>
                      <p style={{ fontSize: 14, fontWeight: 400 }}>Cash</p>
                    </div>
                    <div className='d-flex' style={{ gap: 20 }}>
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        Drug Billing Type
                      </p>
                      <p style={{ fontSize: 14, fontWeight: 400 }}>Cash</p>
                    </div>
                  </td>
                  <td>
                    <span
                      style={{
                        fontFamily: "Lato",
                        fontSize: 16,
                        fontWeight: 700,
                      }}
                    >
                      Current Medications & Possible Drug
                      <br /> Interactions with Prescribed Drug
                    </span>
                    <p
                      className='mt-3'
                      style={{
                        fontFamily: "Lato",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      State the lethal interaction
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className='mb-5'
            style={{ width: "90%", height: 2, backgroundColor: "#E8E8E8" }}
          />
        </div>
        <div className='card pt-4 pl-3 pb-3'>
          <Form.Check
            id='check'
            label='I confirm that I have issued this prescription to Patient One'
            onChange={(e) => {
              if (e.target.checked) {
                setShare(true);
              } else {
                setShare(false);
              }
            }}
          />
          {share ? (
            <>
              <p
                className='mt-3'
                style={{
                  fontFamily: "Lato",
                  fontSize: 16,
                  fontWeight: 700,
                }}
              >
                Share dispensation Summary with Patient & Doctor via :
              </p>
              <div className='d-flex flex-wrap' style={{ gap: 50 }}>
                <Form.Check
                  type='radio'
                  id='email'
                  label='Email'
                  name='choose'
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSendEmail(true);
                      setPrintPdf(false);
                    } else {
                      setSendEmail(false);
                    }
                  }}
                />
                <Form.Check
                  type='radio'
                  id='pdf'
                  label='Print PDF'
                  name='choose'
                  onChange={(e) => {
                    if (e.target.checked) {
                      setPrintPdf(true);
                      setSendEmail(false);
                    } else {
                      setPrintPdf(false);
                    }
                  }}
                />
              </div>
            </>
          ) : (
            <></>
          )}
          {sendEmail ? (
            <>
              <div className='d-flex flex-wrap mt-3' style={{ gap: 30 }}>
                <div className='d-flex' style={{ gap: 10 }}>
                  <Form.Control type='email' placeholder='Enter Doctor Email' />
                  <Button variant='success'>Send</Button>
                </div>
                <div className='d-flex' style={{ gap: 10 }}>
                  <Form.Control
                    type='email'
                    placeholder='Enter Patient Email'
                  />
                  <Button variant='success'>Send</Button>
                </div>
              </div>
              <div>
                <Button
                  className='mt-5'
                  style={{ backgroundColor: "#19226D", width: "15%" }}
                >
                  Submit
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}
          {printPdf ? (
            <div>
              <Button
                className='mt-5'
                style={{ backgroundColor: "#19226D", width: "15%" }}
              >
                Print
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ExternalDispensationSummary;
