import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Footer from '../Footer'
import Header from '../Header'
import LeftBar from '../LeftBar'
import Form from 'react-bootstrap/Form'
import IntakeCalculation from './IntakeCalculation'
import { deselect, extract_text, validate } from '../../constants/Essentials'
import {
  breakfast_data,
  lunch_data,
  snacks_data,
  foodAlergies_data,
  oil_data,
} from '../../constants/nutrition_data'
import { selectUser } from '../../redux_setup/slices/userSlice'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Counter from './Counter'
import { Theme } from '@fullcalendar/core'

const Nutrition = ({ patientDetails }) => {
  const [breakfastSelected, setBreakfastSelected] = useState([])
  const [lunchSelected, setLunchSelected] = useState([])
  const [dinnerSelected, setDinnerSelected] = useState([])
  const [snacksSelected, setSnacksSelected] = useState([])
  const [foodAlergiesSelected, setFoodAlergiesSelected] = useState([])
  const user = useSelector(selectUser)
  toast.configure()
  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  const [calorieData, setCalorieData] = useState([])
  const [disabled, setDisabled] = useState({
    breakfast: {
      fruit: true,
      other: true,
    },
    lunch: {
      fruit: true,
      other: true,
    },
    dinner: {
      fruit: true,
      other: true,
    },
    snacks: {
      fruit: true,
      other: true,
    },
    oil: {
      other: true,
    },
    food_alergies: {
      other: true,
    },
  })
  const [calories, setCalories] = useState({
    breakfast_profile: {
      calories: 0,
      sodium: 0,
      fat: 0,
    },
    lunch_profile: {
      calories: 0,
      sodium: 0,
      fat: 0,
    },
    dinner_profile: {
      calories: 0,
      sodium: 0,
      fat: 0,
    },
    snacks_profile: {
      calories: 0,
      sodium: 0,
      fat: 0,
    },
  })
  const [dataToSend, setDataToSend] = useState({
    patient_id: patientDetails.patient_id,
    breakfast: {},
    breakfast_time: '',
    lunch: {},
    lunch_time: '',
    dinner: {},
    dinner_time: '',
    snacks_a_day: 0,
    snacks: {},
    oils: {},
    food_allergies: {},
    sodium_chloride_intake: {
      value: 1,
      level: 'Low',
    },
    oil_intake: {
      value: 1,
      level: 'Low',
    },
    water_intake: {
      value: 1,
      level: 'Low',
    },
  })

  const handleSubmit = () => {
    const { breakfast_profile, lunch_profile, dinner_profile } = calories
    console.log({
      ...dataToSend,
      breakfast_profile,
      lunch_profile,
      dinner_profile,
    })
    const validation = validate(dataToSend)
    if (validation.isValidated) {
      fetch(
        `${process.env.REACT_APP_MAIN_URL_HYPERTENSION}/api/careplans/hypertensionv1/nutrition`,
        {
          signal: signal,
          method: 'POST', //if you can find an id in vitalsState, then update
          headers: new Headers({
            Authorization: 'Bearer ' + user.token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            ...dataToSend,
            breakfast_profile,
            lunch_profile,
            dinner_profile,
          }),
        },
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            return toast.error(res.error, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 15000,
              className: 'toast-message',
            })
          }
          return toast.success(res.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
          })
        })
        .catch((err) => {
          console.log(err)
          return toast.error('An error has occured while reaching the server', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
            className: 'toast-message',
          })
        })
    } else {
      toast.error(validation.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 15000,
        className: 'toast-message',
      })
    }
  }

  return (
    <div id="main-wrapper">
      {/***********************************
    Header start
    ************************************/}
      <Header />
      {/***********************************
    Header end ti-comment-alt
    ************************************/}
      {/***********************************
    Sidebar start
    ************************************/}
      <LeftBar />
      {/***********************************
    Sidebar end
    ************************************/}
      {/***********************************
    Content body start
    ************************************/}

      {/***********************************
    Content body end
    ************************************/}
      <div className="content-body">
        <p className="nutrition__title">
          Pick a time in the time field and click on a checkbox to select the
          food you had today, under every meal section
        </p>
        {/* Section one */}
        <div className="row">
          <div className="col-md-7 col-sm-12">
            <div className="care__plan__card">
              <p className="subtitle">Breakfast</p>
              <p className="low">Time</p>
              <input
                className="input__time"
                type="time"
                onChange={(e) => {
                  setDataToSend({
                    ...dataToSend,
                    breakfast_time: e.target.value,
                  })
                }}
              />
              <div className="flex__container" style={{ gap: 40 }}>
                <div>
                  {breakfast_data[0].map((box) => (
                    <Form.Check
                      type="checkbox"
                      id={`breakfast-${box.title}`}
                      label={`${box.title}`}
                      style={{ whiteSpace: 'nowrap' }}
                      onChange={(e) => {
                        const checked = extract_text(
                          e.target.nextElementSibling.innerText,
                        )
                        dataToSend.breakfast[checked] = e.target.checked
                        if (e.target.checked) {
                          const breakfast = []
                          breakfast.push({
                            title: box.title,
                            calories: box.calories,
                            number: 0
                          })
                          setCalorieData(...breakfast)
                          setBreakfastSelected([...breakfastSelected, checked])
                          // calculate calories
                          let cal = calories.breakfast_profile.calories
                          let sodium = calories.breakfast_profile.sodium
                          let fats = calories.breakfast_profile.fat
                          cal += box.calories
                          sodium += box.sodium
                          fats += box.fats
                          setCalories({
                            ...calories,
                            breakfast_profile: {
                              calories: cal,
                              sodium: sodium,
                              fat: fats,
                            },
                          })
                          setDataToSend({
                            ...dataToSend,
                            breakfast: {
                              ...dataToSend.breakfast,
                            },
                          })
                        } else {
                          setBreakfastSelected(
                            deselect(breakfastSelected, checked),
                          )
                          dataToSend.breakfast[checked] = false
                          setCalories({
                            ...calories,
                            breakfast_profile: {
                              calories:
                                calories.breakfast_profile.calories -
                                box.calories,
                              sodium:
                                calories.breakfast_profile.sodium - box.sodium,
                              fat: calories.breakfast_profile.fat - box.fats,
                            },
                          })
                        }
                      }}
                    />
                  ))}
                </div>
                <div>
                  {breakfast_data[1].map((box) => (
                    <Form.Check
                      type="checkbox"
                      id={`breakfast-${box.title}`}
                      label={`${box.title}`}
                      style={{ whiteSpace: 'nowrap' }}
                      onChange={(e) => {
                        console.log('calorie data =>', calorieData)
                        const checked = extract_text(
                          e.target.nextElementSibling.innerText,
                          console.log('checkbox data =>', box)
                        )
                        dataToSend.breakfast[checked] = e.target.checked
                        if (e.target.checked) {
                          setBreakfastSelected([...breakfastSelected, checked])
                          console.log('breakfast meal selected =>',calorieData)
                          // calculate calories
                          let cal = calories.breakfast_profile.calories
                          let sodium = calories.breakfast_profile.sodium
                          let fats = calories.breakfast_profile.fat
                          cal += box.calories
                          sodium += box.sodium
                          fats += box.fats
                          setCalories({
                            ...calories,
                            breakfast_profile: {
                              calories: cal,
                              sodium: sodium,
                              fat: fats,
                            },
                          })
                          setDataToSend({
                            ...dataToSend,
                            breakfast: {
                              ...dataToSend.breakfast,
                            },
                          })
                        } else {
                          setBreakfastSelected(
                            deselect(breakfastSelected, checked),
                          )
                          dataToSend.breakfast[checked] = false
                          setCalories({
                            ...calories,
                            breakfast_profile: {
                              calories:
                                calories.breakfast_profile.calories -
                                box.calories,
                              sodium:
                                calories.breakfast_profile.sodium - box.sodium,
                              fat: calories.breakfast_profile.fat - box.fats,
                            },
                          })
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
              <div className="col-sm-12">
                <Form.Check
                  type="checkbox"
                  label="Fruits(Specify)"
                  className="mb-3"
                  style={{ marginLeft: -15 }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setDisabled({
                        ...disabled,
                        breakfast: {
                          ...disabled.breakfast,
                          fruit: false,
                        },
                      })
                    } else {
                      setDisabled({
                        ...disabled,
                        breakfast: {
                          ...disabled.breakfast,
                          fruit: true,
                        },
                      })
                    }
                  }}
                />
                <Form.Group
                  className="mb-3 mr-4 ms-2"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={dataToSend.breakfast.fruit}
                    style={{ backgroundColor: '#f9f9f9' }}
                    disabled={disabled.breakfast.fruit}
                    className="mobo__container mobo__text__area"
                    onChange={(e) => {
                      setDataToSend({
                        ...dataToSend,
                        breakfast: {
                          ...dataToSend.breakfast,
                          fruit: e.target.value,
                        },
                      })
                    }}
                  />
                </Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Other"
                  className="mb-3"
                  style={{ marginLeft: -15 }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setDisabled({
                        ...disabled,
                        breakfast: {
                          ...disabled.breakfast,
                          other: false,
                        },
                      })
                    } else {
                      setDisabled({
                        ...disabled,
                        breakfast: {
                          ...disabled.breakfast,
                          other: true,
                        },
                      })
                    }
                  }}
                />
                <Form.Group
                  className="mb-2 mr-4 ms-4"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={dataToSend.breakfast.other}
                    style={{ backgroundColor: '#f9f9f9' }}
                    disabled={disabled.breakfast.other}
                    className="mobo__container mobo__text__area"
                    onChange={(e) => {
                      setDataToSend({
                        ...dataToSend,
                        breakfast: {
                          ...dataToSend.breakfast,
                          other: e.target.value,
                        },
                      })
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="col-md-5 col-sm-12 ">
            <IntakeCalculation
              title="Breakfast Profile"
              calories={calories?.breakfast_profile}
            />
          </div>
          {/*<div className="col-md-5 col-sm-12 table__mobo">
            <div style={{ padding: 10 }}>
              <p style={{ marginLeft: 50 }}>Food Calorie & Fat Specification</p>
              <Table>
                <thead>
                  <tr style={{ marginRight: 20 }}>
                    <th>Food</th>
                    <th>Calories(kcal)</th>
                    <th>Sodium(mg)</th>
                    <th>Fats</th>
                  </tr>
                </thead>
                <tbody>
                  {tabledata.map((data, index) => (
                    <tr key={index} style={{ marginRight: 20 }}>
                      <td>{data.Food}</td>
                      <td>{data.Callories}</td>
                      <td>{data.Sodium}</td>
                      <td>{data.Fats}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>*/}
        </div>
        {/* End of section one */}
        {/* Section two */}

        {/* End section */}
        {/* Section Three */}
        <div className="row" style={{ marginTop: 15 }}>
          <div className="col-md-8 col-sm-12">
            <div className="care__plan__card">
              <p className="subtitle">Lunch</p>
              <p className="low">Time</p>
              <input
                className="input__time"
                type="time"
                onChange={(e) => {
                  setDataToSend({
                    ...dataToSend,
                    lunch_time: e.target.value,
                  })
                }}
              />
              <div className="flex__container" style={{ gap: 50 }}>
                <div>
                  {lunch_data[0].map((box) => (
                    <Form.Check
                      type="checkbox"
                      id={`lunch-${box.title}`}
                      label={`${box.title}`}
                      style={{ whiteSpace: 'nowrap' }}
                      onChange={(e) => {
                        const checked = extract_text(
                          e.target.nextElementSibling.innerText,
                        )
                        dataToSend.lunch[checked] = e.target.checked
                        if (e.target.checked) {
                          setLunchSelected([...lunchSelected, checked])
                          // calculate calories
                          let cal = calories.lunch_profile.calories
                          let sodium = calories.lunch_profile.sodium
                          let fats = calories.lunch_profile.fat
                          cal += box.calories
                          sodium += box.sodium
                          fats += box.fats
                          setCalories({
                            ...calories,
                            lunch_profile: {
                              calories: cal,
                              sodium: sodium,
                              fat: fats,
                            },
                          })
                          setDataToSend({
                            ...dataToSend,
                            lunch: {
                              ...dataToSend.lunch,
                            },
                          })
                          console.log('lunch data to send =>', dataToSend.lunch)
                        } else {
                          setLunchSelected(deselect(lunchSelected, checked))
                          dataToSend.lunch[checked] = false
                          // substract calories intake
                          setCalories({
                            ...calories,
                            lunch_profile: {
                              calories:
                                calories.lunch_profile.calories - box.calories,
                              sodium:
                                calories.lunch_profile.sodium - box.sodium,
                              fat: calories.lunch_profile.fat - box.fats,
                            },
                          })
                        }
                      }}
                    />
                  ))}
                </div>
                <div>
                  {lunch_data[1].map((box) => (
                    <Form.Check
                      type="checkbox"
                      id={`lunch-${box.title}`}
                      label={`${box.title}`}
                      style={{ whiteSpace: 'nowrap' }}
                      onChange={(e) => {
                        const checked = extract_text(
                          e.target.nextElementSibling.innerText,
                        )
                        dataToSend.lunch[checked] = e.target.checked
                        if (e.target.checked) {
                          setLunchSelected([...lunchSelected, checked])
                          // calculate calories
                          let cal = calories.lunch_profile.calories
                          let sodium = calories.lunch_profile.sodium
                          let fats = calories.lunch_profile.fat
                          cal += box.calories
                          sodium += box.sodium
                          fats += box.fats
                          setCalories({
                            ...calories,
                            lunch_profile: {
                              calories: cal,
                              sodium: sodium,
                              fat: fats,
                            },
                          })
                          setDataToSend({
                            ...dataToSend,
                            lunch: {
                              ...dataToSend.lunch,
                            },
                          })
                          console.log('lunch data to send =>', dataToSend.lunch)
                        } else {
                          setLunchSelected(deselect(lunchSelected, checked))
                          dataToSend.lunch[checked] = false
                          // substract calories intake
                          setCalories({
                            ...calories,
                            lunch_profile: {
                              calories:
                                calories.lunch_profile.calories - box.calories,
                              sodium:
                                calories.lunch_profile.sodium - box.sodium,
                              fat: calories.lunch_profile.fat - box.fats,
                            },
                          })
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
              <div className="col-sm-12 mobo__container">
                <div>
                  <Form.Check
                    type="checkbox"
                    label="Fruits(Specify)"
                    className="mb-3"
                    style={{ marginLeft: -15 }}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setDisabled({
                          ...disabled,
                          lunch: {
                            ...disabled.lunch,
                            fruit: false,
                          },
                        })
                      } else {
                        setDisabled({
                          ...disabled,
                          lunch: {
                            ...disabled.lunch,
                            fruit: true,
                          },
                        })
                      }
                    }}
                  />
                  <Form.Group
                    className="mb-3 mr-4 ms-2"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={dataToSend.lunch.fruit}
                      style={{ backgroundColor: '#f9f9f9' }}
                      disabled={disabled.lunch.fruit}
                      className="mobo__text__area text__area__width"
                      onChange={(e) => {
                        setDataToSend({
                          ...dataToSend,
                          lunch: {
                            ...dataToSend.lunch,
                            fruit: e.target.value,
                          },
                        })
                      }}
                    />
                  </Form.Group>
                  <Form.Check
                    type="checkbox"
                    label="Other"
                    className="mb-3"
                    style={{ marginLeft: -15 }}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setDisabled({
                          ...disabled,
                          lunch: {
                            ...disabled.lunch,
                            other: false,
                          },
                        })
                      } else {
                        setDisabled({
                          ...disabled,
                          lunch: {
                            ...disabled.lunch,
                            other: true,
                          },
                        })
                      }
                    }}
                  />
                  <Form.Group
                    className="mb-2 mr-4 ms-4"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={dataToSend.lunch.other}
                      style={{ backgroundColor: '#f9f9f9' }}
                      className="mobo__text__area text__area__width"
                      disabled={disabled.lunch.other}
                      onChange={(e) => {
                        setDataToSend({
                          ...dataToSend,
                          lunch: {
                            ...dataToSend.lunch,
                            other: e.target.value,
                          },
                        })
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 ">
            <IntakeCalculation
              title="Lunch profile"
              calories={calories?.lunch_profile}
            />
          </div>
        </div>
        {/* End of Section Three */}
        {/* Section Four */}
        <div className="row">
          {/* <div className="col-md-7 col-sm-12 ">
            <Bubble selected={lunchSelected} />
          </div> */}
          {/* <div className="col-md-5 col-sm-12 ">
            <IntakeCalculation
              title="Lunch profile"
              calories={calories?.lunch_profile}
            /> */}
        </div>
        <div className="row" style={{ marginTop: 15 }}>
          <div className="col-md-7 col-sm-12">
            <div className="care__plan__card">
              <p className="subtitle">Dinner</p>
              <p className="low">Time</p>
              <input
                className="input__time"
                type="time"
                onChange={(e) => {
                  setDataToSend({
                    ...dataToSend,
                    dinner_time: e.target.value,
                  })
                }}
              />
              <div className="flex__container" style={{ gap: 40 }}>
                <div>
                  {lunch_data[0].map((box) => (
                    <Form.Check
                      type="checkbox"
                      id={`dinner-${box.title}`}
                      label={`${box.title}`}
                      style={{ whiteSpace: 'nowrap' }}
                      onChange={(e) => {
                        const checked = extract_text(
                          e.target.nextElementSibling.innerText,
                        )
                        dataToSend.dinner[checked] = e.target.checked
                        if (e.target.checked) {
                          setDinnerSelected([...dinnerSelected, checked])
                          // calculate calories
                          let cal = calories.dinner_profile.calories
                          let sodium = calories.dinner_profile.sodium
                          let fats = calories.dinner_profile.fat
                          cal += box.calories
                          sodium += box.sodium
                          fats += box.fats
                          setCalories({
                            ...calories,
                            dinner_profile: {
                              calories: cal,
                              sodium: sodium,
                              fat: fats,
                            },
                          })
                          setDataToSend({
                            ...dataToSend,
                            dinner: {
                              ...dataToSend.dinner,
                            },
                          })
                          console.log(
                            'dinner data to send =>',
                            dataToSend.dinner,
                          )
                        } else {
                          setDinnerSelected(deselect(dinnerSelected, checked))
                          dataToSend.dinner[checked] = false
                          // substract calories intake
                          setCalories({
                            ...calories,
                            dinner_profile: {
                              calories:
                                calories.dinner_profile.calories - box.calories,
                              sodium:
                                calories.dinner_profile.sodium - box.sodium,
                              fat: calories.dinner_profile.fat - box.fats,
                            },
                          })
                        }
                      }}
                    />
                  ))}
                </div>
                <div>
                  {lunch_data[1].map((box) => (
                    <Form.Check
                      type="checkbox"
                      id={`dinner-${box.title}`}
                      label={`${box.title}`}
                      style={{ whiteSpace: 'nowrap' }}
                      onChange={(e) => {
                        const checked = extract_text(
                          e.target.nextElementSibling.innerText,
                        )
                        dataToSend.dinner[checked] = e.target.checked
                        if (e.target.checked) {
                          setDinnerSelected([...dinnerSelected, checked])
                          // calculate calories
                          let cal = calories.dinner_profile.calories
                          let sodium = calories.dinner_profile.sodium
                          let fats = calories.dinner_profile.fat
                          cal += box.calories
                          sodium += box.sodium
                          fats += box.fats
                          setCalories({
                            ...calories,
                            dinner_profile: {
                              calories: cal,
                              sodium: sodium,
                              fat: fats,
                            },
                          })
                          setDataToSend({
                            ...dataToSend,
                            dinner: {
                              ...dataToSend.dinner,
                            },
                          })
                        } else {
                          setDinnerSelected(deselect(dinnerSelected, checked))
                          dataToSend.lunch[checked] = false
                          // substract calories intake
                          setCalories({
                            ...calories,
                            dinner_profile: {
                              calories:
                                calories.dinner_profile.calories - box.calories,
                              sodium:
                                calories.dinner_profile.sodium - box.sodium,
                              fat: calories.dinner_profile.fat - box.fats,
                            },
                          })
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
              <div className="col-sm-12">
                <Form.Check
                  type="checkbox"
                  label="Fruits(Specify)"
                  className="mb-3"
                  style={{ marginLeft: -15 }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setDisabled({
                        ...disabled,
                        dinner: {
                          ...disabled.dinner,
                          fruit: false,
                        },
                      })
                    } else {
                      setDisabled({
                        ...disabled,
                        dinner: {
                          ...disabled.dinner,
                          fruit: true,
                        },
                      })
                    }
                  }}
                />
                <Form.Group
                  className="mb-3 mr-4 ms-2"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={dataToSend.dinner.fruit}
                    style={{ backgroundColor: '#f9f9f9', marginLeft: -15 }}
                    disabled={disabled.dinner.fruit}
                    className="mobo__container mobo__text__area"
                    onChange={(e) => {
                      setDataToSend({
                        ...dataToSend,
                        dinner: {
                          ...dataToSend.dinner,
                          fruit: e.target.value,
                        },
                      })
                    }}
                  />
                </Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Other"
                  className="mb-3"
                  style={{ marginLeft: -15 }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setDisabled({
                        ...disabled,
                        dinner: {
                          ...disabled.dinner,
                          other: false,
                        },
                      })
                    } else {
                      setDisabled({
                        ...disabled,
                        dinner: {
                          ...disabled.dinner,
                          other: true,
                        },
                      })
                    }
                  }}
                />
                <Form.Group
                  className="mb-2 mr-4 ms-4"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={dataToSend.dinner.other}
                    style={{ backgroundColor: '#f9f9f9', marginLeft: -15 }}
                    className="mobo__container mobo__text__area"
                    disabled={disabled.dinner.other}
                    onChange={(e) => {
                      setDataToSend({
                        ...dataToSend,
                        dinner: {
                          ...dataToSend.dinner,
                          other: e.target.value,
                        },
                      })
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="col-md-5 col-sm-12 ">
            <IntakeCalculation
              title="Dinner Profile"
              calories={calories?.dinner_profile}
            />
          </div>
          {/* End of section Five */}
          {/* section Six */}
          {/* <div className="row">
          <div className="col-md-7 col-sm-12 ">
            <Bubble selected={dinnerSelected} />
          </div>
          
        </div> */}
          {/* End of section Six */}
          {/* Section Seven */}
        </div>

        <div className="row" style={{ marginTop: 15 }}>
          <div className="col-md-7 col-sm-12 ">
            <div className="care__plan__card">
              <p className="subtitle">Snacks</p>
              <p className="dinner__span">Number of snacks in a day</p>
              <Counter
                leftPosition={dataToSend.snacks_a_day}
                times={27}
                data={dataToSend.snacks_a_day}
              />
              <div>
                <Form.Range
                  min={0}
                  max={10}
                  value={dataToSend?.snacks_a_day}
                  style={{
                    width: 301,
                    marginRight: 10,
                    marginBottom: 30,
                    color: 'red',
                  }}
                  onChange={(e) => {
                    setDataToSend({
                      ...dataToSend,
                      snacks_a_day: e.target.value,
                    })
                  }}
                />
              </div>
              <div className="row">
                <div className="col-md-3 col-sm-12">
                  <div>
                    {snacks_data[0].map((box) => (
                      <Form.Check
                        type="checkbox"
                        id={`snacks-${box.title}`}
                        label={`${box.title}`}
                        style={{ whiteSpace: 'nowrap' }}
                        onChange={(e) => {
                          const checked = extract_text(
                            e.target.nextElementSibling.innerText,
                          )
                          dataToSend.snacks[checked] = e.target.checked
                          if (e.target.checked) {
                            setSnacksSelected([...snacksSelected, checked])
                            // calculate calories
                            let cal = calories.snacks_profile.calories
                            let sodium = calories.snacks_profile.sodium
                            let fats = calories.snacks_profile.fat
                            cal += box.calories
                            sodium += box.sodium
                            fats += box.fats
                            setCalories({
                              ...calories,
                              snacks_profile: {
                                calories: cal,
                                sodium: sodium,
                                fat: fats,
                              },
                            })
                            setDataToSend({
                              ...dataToSend,
                              snacks: {
                                ...dataToSend.snacks,
                              },
                            })
                            console.log(
                              'snacks data to send =>',
                              dataToSend.snacks,
                            )
                          } else {
                            setSnacksSelected(deselect(snacksSelected, checked))
                            dataToSend.snacks[checked] = false
                            // substract calories intake
                            setCalories({
                              ...calories,
                              snacks_profile: {
                                calories:
                                  calories.snacks_profile.calories -
                                  box.calories,
                                sodium:
                                  calories.snacks_profile.sodium - box.sodium,
                                fat: calories.snacks_profile.fat - box.fats,
                              },
                            })
                          }
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div className="col-md-3 col-sm-12">
                  <div>
                    {snacks_data[1].map((box) => (
                      <Form.Check
                        type="checkbox"
                        id={`snacks-${box.title}`}
                        label={`${box.title}`}
                        style={{ whiteSpace: 'nowrap' }}
                        onChange={(e) => {
                          const checked = extract_text(
                            e.target.nextElementSibling.innerText,
                          )
                          dataToSend.snacks[checked] = e.target.checked
                          if (e.target.checked) {
                            setSnacksSelected([...snacksSelected, checked])
                            // calculate calories
                            let cal = calories.snacks_profile.calories
                            let sodium = calories.snacks_profile.sodium
                            let fats = calories.snacks_profile.fat
                            cal += box.calories
                            sodium += box.sodium
                            fats += box.fats
                            setCalories({
                              ...calories,
                              snacks_profile: {
                                calories: cal,
                                sodium: sodium,
                                fat: fats,
                              },
                            })
                            setDataToSend({
                              ...dataToSend,
                              snacks: {
                                ...dataToSend.snacks,
                              },
                            })
                            console.log(
                              'snacks data to send',
                              dataToSend.snacks,
                            )
                          } else {
                            setSnacksSelected(deselect(snacksSelected, checked))
                            dataToSend.snacks[checked] = false
                            // substract calories intake
                            setCalories({
                              ...calories,
                              snacks_profile: {
                                calories:
                                  calories.snacks_profile.calories -
                                  box.calories,
                                sodium:
                                  calories.snacks_profile.sodium - box.sodium,
                                fat: calories.snacks_profile.fat - box.fats,
                              },
                            })
                          }
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div className="col-md-5 col-sm-12">
                  <div className="snacks__textarea">
                    <Form.Check
                      type="checkbox"
                      label="Fruits(Specify)"
                      className="mb-3"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setDisabled({
                            ...disabled,
                            snacks: {
                              ...disabled.snacks,
                              fruit: false,
                            },
                          })
                        } else {
                          setDisabled({
                            ...disabled,
                            snacks: {
                              ...disabled.snacks,
                              fruit: true,
                            },
                          })
                        }
                      }}
                    />
                    <Form.Group
                      className="mb-3 mr-4 ms-2"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={dataToSend.snacks.fruit}
                        style={{ backgroundColor: '#f9f9f9' }}
                        disabled={disabled.snacks.fruit}
                        className="mobo__container mobo__text__area"
                        onChange={(e) => {
                          setDataToSend({
                            ...dataToSend,
                            snacks: {
                              ...dataToSend.snacks,
                              fruit: e.target.value,
                            },
                          })
                        }}
                      />
                    </Form.Group>
                    <Form.Check
                      type="checkbox"
                      label="Other"
                      className="mb-3"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setDisabled({
                            ...disabled,
                            snacks: {
                              ...disabled.snacks,
                              other: false,
                            },
                          })
                        } else {
                          setDisabled({
                            ...disabled,
                            snacks: {
                              ...disabled.snacks,
                              other: true,
                            },
                          })
                        }
                      }}
                    />
                    <Form.Group
                      className="mb-2 mr-4 ms-4"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={dataToSend.snacks.other}
                        style={{ backgroundColor: '#f9f9f9' }}
                        disabled={disabled.snacks.other}
                        className="mobo__container mobo__text__area"
                        onChange={(e) => {
                          setDataToSend({
                            ...dataToSend,
                            snacks: {
                              ...dataToSend.snacks,
                              other: e.target.value,
                            },
                          })
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 col-sm-12 ">
            <IntakeCalculation
              title="Snacks Profile"
              calories={calories?.snacks_profile}
            />
          </div>
        </div>
        {/* Section Seven Endd-- */}
        {/* Section Eight */}
        {/* <div className="row">
          <div className="col-md-7 col-sm-12 ">
            <Bubble selected={snacksSelected} />
          </div>
          
        </div> */}
        {/* End of Section Eight */}
        {/* Section Nine */}
        <div className="row">
          <div className="col-md-7">
            <div className="oil__used__container">
              <p className="low" style={{ marginBottom: 30 }}>
                Oil used at home
              </p>
              <div className="row">
                <div className="col-md-3 col-sm-12">
                  {oil_data[0].map((oil, index) => (
                    <div key={index}>
                      <Form.Check
                        type="checkbox"
                        label={oil.title}
                        id={`oil-${oil.title}-${index + 1}`}
                        onChange={(e) => {
                          const checked = extract_text(
                            e.target.nextElementSibling.innerText,
                          )
                          if (e.target.checked) {
                            dataToSend.oils[checked] = e.target.checked
                            setDataToSend({
                              ...dataToSend,
                              oils: {
                                ...dataToSend.oils,
                              },
                            })
                            console.log('oils selected =>', dataToSend.oils)
                          } else {
                            dataToSend.oils[checked] = false
                            console.log(
                              'updated oils selected =>',
                              dataToSend.oils,
                            )
                          }
                        }}
                      />
                    </div>
                  ))}
                </div>
                <div className="col-md-3 col-sm-12">
                  {oil_data[1].map((oil, index) => (
                    <div key={index}>
                      <Form.Check
                        type="checkbox"
                        label={oil.title}
                        id={`oil-${oil.title}-${index + 1}`}
                        onChange={(e) => {
                          const checked = extract_text(
                            e.target.nextElementSibling.innerText,
                          )
                          if (e.target.checked) {
                            dataToSend.oils[checked] = e.target.checked
                            setDataToSend({
                              ...dataToSend,
                              oils: {
                                ...dataToSend.oils,
                              },
                            })
                          } else {
                            dataToSend.oils[checked] = false
                          }
                        }}
                      />
                    </div>
                  ))}
                </div>
                <div
                  className="col-md-6 col-sm-12"
                  style={{ marginBottom: 30 }}
                >
                  <Form.Check
                    type="checkbox"
                    label="Other"
                    className="mb-3"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setDisabled({
                          ...disabled,
                          oil: {
                            ...disabled.oil,
                            other: false,
                          },
                        })
                      } else {
                        setDisabled({
                          ...disabled,
                          oil: {
                            ...disabled.oil,
                            other: true,
                          },
                        })
                      }
                    }}
                  />
                  <Form.Group
                    className="mb-2 mr-4 ms-4"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={dataToSend.oils.other}
                      style={{ width: 300, backgroundColor: '#f9f9f9' }}
                      disabled={disabled.oil.other}
                      onChange={(e) => {
                        setDataToSend({
                          ...dataToSend,
                          oils: {
                            ...dataToSend.oils,
                            other: e.target.value,
                          },
                        })
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* End Section Nine */}
        {/* Section ten Food alergies */}
        <div className="flex__container" style={{ marginTop: 15 }}>
          <div className="care__plan__card">
            <p className="low" style={{ fontSize: 20 }}>
              Food allergies
            </p>
            <div className="flex__container" style={{ gap: 40 }}>
              <div>
                {foodAlergies_data[0].map((box) => (
                  <Form.Check
                    type="checkbox"
                    id={`food-alergies-${box.title}`}
                    label={`${box.title}`}
                    onChange={(e) => {
                      const checked = extract_text(
                        e.target.nextElementSibling.innerText,
                      )
                      dataToSend.food_allergies[checked] = e.target.checked
                      if (e.target.checked) {
                        setFoodAlergiesSelected([
                          ...foodAlergiesSelected,
                          checked,
                        ])
                        setDataToSend({
                          ...dataToSend,
                          food_allergies: {
                            ...dataToSend.food_allergies,
                          },
                        })
                      } else {
                        setFoodAlergiesSelected(
                          deselect(foodAlergiesSelected, checked),
                        )
                        dataToSend.breakfast[checked] = false
                        console.log(
                          'food alergies data to send =>',
                          dataToSend.food_allergies,
                        )
                      }
                    }}
                  />
                ))}
              </div>
              <div>
                {foodAlergies_data[1].map((box) => (
                  <Form.Check
                    type="checkbox"
                    id={`food-alergies-${box.title}`}
                    label={`${box.title}`}
                    onChange={(e) => {
                      const checked = extract_text(
                        e.target.nextElementSibling.innerText,
                      )
                      dataToSend.food_allergies[checked] = e.target.checked
                      if (e.target.checked) {
                        setFoodAlergiesSelected([
                          ...foodAlergiesSelected,
                          checked,
                        ])
                        setDataToSend({
                          ...dataToSend,
                          food_allergies: {
                            ...dataToSend.food_allergies,
                          },
                        })
                      } else {
                        setFoodAlergiesSelected(
                          deselect(foodAlergiesSelected, checked),
                        )
                        dataToSend.food_allergies[checked] = false
                        console.log(
                          'updted food alergies data =>',
                          dataToSend.food_allergies,
                        )
                      }
                    }}
                  />
                ))}
                <Form.Check
                  type="checkbox"
                  label="Other"
                  className="mb-3"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setDisabled({
                        ...disabled,
                        food_alergies: {
                          ...disabled.food_alergies,
                          other: false,
                        },
                      })
                    } else {
                      setDisabled({
                        ...disabled,
                        food_alergies: {
                          ...disabled.food_alergies,
                          other: true,
                        },
                      })
                    }
                  }}
                />
                <Form.Group
                  className="mb-2 mr-4 ms-4"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={dataToSend.food_allergies.other}
                    style={{ width: '100%', backgroundColor: '#f9f9f9' }}
                    disabled={disabled.food_alergies.other}
                    onChange={(e) => {
                      setDataToSend({
                        ...dataToSend,
                        food_allergies: {
                          ...dataToSend.food_allergies,
                          other: e.target.value,
                        },
                      })
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
        {/* End of Section Ten */}
        {/* Section Eleven */}
        {/* <div className="row" style={{ marginBottom: 15 }}>
          <div className="col-md-7">
            <Bubble selected={foodAlergiesSelected} />
          </div>
        </div> */}
        {/* Section Eleven End */}
        {/* Last section */}
        <div className="intake__card mobo__top">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <p
                className="low mobo__margin"
                style={{ marginBottom: 20, marginTop: 20, fontSize: 18 }}
              >
                Sodium intake
              </p>
              <div className="sodium__intake">
                <p className="intake__medium__text">Tablespoons/ meal</p>
                <Form.Range
                  min={1}
                  max={10000}
                  value={dataToSend.sodium_chloride_intake?.value}
                  style={{ width: 178, marginRight: 10, marginLeft: 15 }}
                  onChange={(e) => {
                    setDataToSend({
                      ...dataToSend,
                      sodium_chloride_intake: {
                        ...dataToSend.sodium_chloride_intake,
                        value: e.target.value,
                        level:
                          e.target.value > 2300
                            ? 'High'
                            : e.target.value < 2300
                            ? 'Low'
                            : 'Normal',
                      },
                    })
                  }}
                />
                <small className="intake__small__text">
                  Estimated daily sodium intake
                </small>
                <p className="intake__big__text">
                  {dataToSend.sodium_chloride_intake.value}
                  <small className="low">mg</small>
                </p>
                <button className="intake__btn">
                  {dataToSend.sodium_chloride_intake.level}
                </button>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <p
                className="low mobo__margin"
                style={{ marginBottom: 20, marginTop: 20, fontSize: 18 }}
              >
                Oil Intake
              </p>
              <div
                className="sodium__intake"
                style={{ backgroundColor: '#FFC00057' }}
              >
                <p className="intake__medium__text">g/ Meal</p>
                <Form.Range
                  min={1}
                  max={900}
                  value={dataToSend.oil_intake?.value}
                  style={{ width: 178, marginRight: 10, marginLeft: 15 }}
                  onChange={(e) => {
                    setDataToSend({
                      ...dataToSend,
                      oil_intake: {
                        ...dataToSend.oil_intake,
                        value: e.target.value,
                        level:
                          e.target.value > 66
                            ? 'High'
                            : e.target.value < 66
                            ? 'Low'
                            : 'Normal',
                      },
                    })
                  }}
                />
                <small className="intake__small__text">
                  Estimated daily oil intake
                </small>
                <p className="intake__big__text">
                  {dataToSend.oil_intake.value}
                  <small className="low">g</small>
                </p>
                <button
                  className="intake__btn"
                  style={{
                    backgroundColor: '#AF91331A',
                    border: '1px solid #AF91331A',
                  }}
                >
                  {dataToSend.oil_intake.level}
                </button>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <p
                className="low"
                style={{ marginBottom: 20, marginTop: 20, fontSize: 18 }}
              >
                Water intake
              </p>
              <div
                className="sodium__intake"
                style={{ backgroundColor: '#2FA5DB57' }}
              >
                <p className="intake__medium__text">Glasses/ day</p>
                <Form.Range
                  min={1}
                  max={15}
                  value={dataToSend.water_intake?.value}
                  style={{ width: 178, marginRight: 10, marginLeft: 15 }}
                  onChange={(e) => {
                    setDataToSend({
                      ...dataToSend,
                      water_intake: {
                        ...dataToSend.water_intake,
                        value: e.target.value,
                        level:
                          e.target.value > 2.5
                            ? 'High'
                            : e.target.value < 2.5
                            ? 'Low'
                            : 'Normal',
                      },
                    })
                  }}
                />
                <small className="intake__small__text">
                  Estimated daily water intake
                </small>
                <p className="intake__big__text">
                  {dataToSend.water_intake.value}
                  <small className="low">ltrs</small>
                </p>
                <button className="intake__btn">
                  {dataToSend.water_intake.level}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="submit__notes submit__mobo">
          <button
            className="submit__btn btn__mobo"
            style={{ marginRight: 170, marginTop: 50, width: 130 }}
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
      {/* End of section Four */}
      {/* Section Five */}

      {/***********************************
    Footer start
    ************************************/}
      <Footer />
      {/***********************************
    Footer end
    ************************************/}
    </div>
  )
}
export default Nutrition
