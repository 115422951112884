export const defaultinvoiceData = [
  {
    service: 'Consultation',
    selected: true,
    options: ['New Appointment', 'Follow up Appointment'],
    pricefield: 0,
    quantityfield: 1,
    discountfield: 0,
    amountfield: 0,
    subtotalfield: 0,
    taxfield: 0,
    totalfield: 0,
  },
  {
    service: 'Labs',
    selected: false,
    options: ['Heart Function', 'Liver Function'],
    pricefield: false,
    quantityfield: true,
    discountfield: true,
    amountfield: true,
    subtotalfield: 0,
    taxfield: '0',
    totalfield: 0,
    chosenTests: [],
  },
  {
    service: 'Pharmacy',
    selected: false,
    drugs: ['Paracetamol', 'Flagyl'],
    forms: ['Tabs', 'Injection', 'Pills'],
    pricefield: false,
    quantityfield: true,
    discountfield: true,
    amountfield: true,
    subtotalfield: 0,
    taxfield: 0,
    totalfield: 0,
    chosenDrugs: [],
  },
]
