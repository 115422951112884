import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../redux_setup/slices/userSlice";
import Select from "react-select";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { trimDate } from "../constants/Essentials";
import { formatDate } from "@fullcalendar/react";

const DoctorsAssessmentHistoryComponent = () => {
  toast.configure();
  let navigate = useNavigate();
  const location = useLocation();
  const abortcontroller = new AbortController();
  const signal = abortcontroller.signal;
  const user = useSelector(selectUser);
  const [currentTab, setcurrentTab] = useState("physical_assessment");

  const [dateOptions, setDateOptions] = useState([]);
  const [mainRes, setMainRes] = useState([]);
  const [currentRes, setCurrentRes] = useState({});

  // doctor assessment states
  const [assessmentCurrentSymptoms, setAssessmentCurrentSymptoms] = useState(
    ""
  );
  const [assessmentCaseInformation, setAssessmentCaseInformation] = useState(
    ""
  );
  const [assessmentTests, setAssessmentTests] = useState("");
  const [assessmentDiagnosis, setAssessmentDiagnosis] = useState("");
  const [assessmentPrescription, setAssessmentPrescription] = useState("");
  const patient = location.state;
  // handle doctor assesment button

  const url = `${process.env.REACT_APP_MAIN_URL}/api/general_consultation/?patient_id=${patient.patient_id}`;
  useEffect(() => {
    fetch(url, {
      signal: signal,
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + user.token,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning("An error occured while fetching history", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          });
        }
        const response = res.data.items;
        const dates = [];
        response.map((res) => {
          dates.push({
            value: res.createdAt,
            label: `${trimDate(res.createdAt)}`,
          });
        });
        // console.log(response);
        setDateOptions(dates);
        setMainRes(response);
        response.length && setCurrentRes(response[0]);
      })
      .catch((err) => {
        console.log(err);
      });
    return function cleanup() {
      abortcontroller.abort();
    };
  }, []);

  const handleChange = (date) => {
    const res = mainRes.filter((item) => item.createdAt === date);
    setCurrentRes(res[0]);
  };

  useEffect(() => {
    const response = currentRes;
    setAssessmentCurrentSymptoms(response?.current_symptoms);
    setAssessmentCaseInformation(response?.case_information);
    setAssessmentTests(response?.lab_tests);
    setAssessmentDiagnosis(response?.diagnosis);
    setAssessmentPrescription(response?.prescription);
  }, [currentRes]);

  return (
    <div className="content-body">
      {/* row */}

      {/* <div className="container-fluid">
        <div className="form-head page-titles d-flex mb-md-4">
          <div className="mr-auto">
            <h2 className="text-black font-w600">Doctor</h2>
          </div>
        </div>
      </div> */}
      <div className="container-fluid pt-0">
        <div className="row">
          <div className="col-lg-12 col-xxl-12">
            <div className="card ">
              <div className="card-header">
                <h4 className="card-title">Assessment history</h4>
              </div>
              <div className="card-body text-left">
                <div className="form-group row">
                  <label className="col-md-2">Date recorded: </label>
                  <div className="col-md-6">
                    <select
                      onChange={(e) => handleChange(e.target.value)}
                      className="form-control"
                    >
                      {dateOptions.map((d) => (
                        <option key={d.value} value={d.value}>
                          {d.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  {mainRes?.length ? (
                    <>
                      <div className="col-md-6">
                        <p>
                          <strong>Current symptoms:</strong>{" "}
                          {assessmentCurrentSymptoms}
                        </p>
                        <p>
                          <strong>Case information:</strong>{" "}
                          {assessmentCaseInformation}
                        </p>
                        <p>
                          <strong>Lab tests:</strong>
                          {assessmentTests}
                        </p>
                        <p>
                          <strong>Diagnosis:</strong> {assessmentDiagnosis}
                        </p>
                        <p>
                          <strong>Prescription:</strong>
                          {assessmentPrescription}
                        </p>
                      </div>
                    </>
                  ) : (
                    <div className="alert alert-info">
                      <p>This patient has no medical assessment history yet</p>
                    </div>
                  )}
                </div>
                <div className="mt-3">
                  <button className="btn btn-dark" onClick={() => navigate(-1)}>
                    Go back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <a href="/ManagePatients">
          <button className="btn btn-light btl-lg shadow"> 
          Manage Patients
          </button> </a> */}

      {/* <MainApp appointments={appointments} today={appointmentsToday} /> */}
      {/* <AppointmentActivity /> */}
    </div>
  );
};

export default DoctorsAssessmentHistoryComponent;
