import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import { selectReceptionistFilters } from '../redux_setup/slices/receptionistSlice'
import { tConv24, todayDate, trimDate } from '../constants/Essentials'
import { formatDate } from '@fullcalendar/react'
import { Link, useNavigate } from 'react-router-dom'

const NurseInstructions = () => {
  let navigate = useNavigate()
  const abortcontroller = new AbortController()
  const user = useSelector(selectUser)
  const filters = useSelector(selectReceptionistFilters)
  const [patientsAssigned, setPatientsAssigned] = useState([])
  const [instructions, setInstructions] = useState([])

  useEffect(() => {
    const today = todayDate()
    const url = `${process.env.REACT_APP_MAIN_URL}/api/general_consultation/note?nurse_id=${user.user_id}`
    fetch(url, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        setInstructions(res.data?.items)
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  return (
    <div className="card roundediv">
      <div className="card-header">Instructions given to me</div>
      <div className="card-body">
        <Table variant="hover">
          <thead>
            <tr style={{ textAlign: 'left' }}>
              <th>Patient</th>
              <th>Doctor</th>
              <th>Primary instr</th>
              <th>Additional</th>
              <th>Date</th>
              <th>Time</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {/* {patientsAssigned ? ( */}
            {/* {1 == 2 ? ( */}
            {instructions.length > 0 ? (
              instructions.map((instruction, index) => {
                const dataToProceed = {}

                return (
                  <tr className="text-left" key={index}>
                    <td>{instruction?.appointment?.patient?.name}</td>
                    <td>{instruction?.appointment?.doctorId?.name}</td>
                    <td>{instruction?.primary_instruction}</td>
                    <td>{instruction?.other_instruction}</td>
                    <td>
                      {formatDate(instruction?.createdAt, { locale: 'es' })}
                    </td>
                    <td>{trimDate(instruction?.createdAt, 'time_only')}</td>
                    <td>
                      {instruction?.executed ? (
                        <>
                          <i
                            className="fa fa-check-circle text-success"
                            title="Completed"
                          ></i>{' '}
                          Executed
                        </>
                      ) : (
                        <button
                          className={`btn btn-sm btn-info`}
                          title="Click here to mark as done"
                        >
                          Mark as done
                        </button>
                      )}
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <p>You have no pending instructions</p>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default NurseInstructions
