import React from 'react'

const TermsOfServices = () => {
  return (
    <div style={{ padding: "5%" }}>
      <p
        style={{
          fontSize: 24,
          fontWeight: 700,
          fontFamily: "Lato",
          color: "#000",
        }}
      >
        Terms of Service
      </p>
      <div
        className='card pt-5 pr-5 pl-5'
        style={{
          fontSize: 14,
          fontWeight: 400,
          fontFamily: "Lato",
          color: "#52575C",
          lineHeight: 20,
        }}
      >
        <p>
          By signing up for a HealthCenta and/or MyHealthCenta Account (as
          defined in Section 1 below) or by using any Executive Assist Ltd (EAL)
          products and/or services (as defined below), you are agreeing to be
          bound by the following terms and conditions (the “Terms of Service”).
          As used in these Terms of Service, “we”, “us” “Executive Assist”,
          “EAL”, “HealthCenta” and “MyHealthCenta” means the applicable
          Executive Assist Ltd Contracting Party (as defined in Section 4
          below).
        </p>
        <p>
          {" "}
          The services offered by HealthCenta under the Terms of Service include
          various products and services to help you access remote and virtual
          health management services online (“Health Centre Services”). Any such
          services offered by HealthCenta are referred to in these Terms of
          Services as the “Services”. Any new features or tools which are added
          to the current Services shall be also subject to the Terms of Service.
          You can review the current version of the Terms of Service at any time
          at{" "}
          <a
            href='https://www.executiveassistafrica.com/myhealthcenta/legal/terms'
            target='_blank'
          >
            https://www.executiveassistafrica.com/myhealthcenta/legal/terms
          </a>
          . 
        </p>
        <p>
          HealthCenta reserves the right to update and change the Terms of
          Service by posting updates and changes to the Executive Assist
          website. You are advised to check the Terms of Service from time to
          time for any updates or changes that may impact you and if you do not
          accept such amendments, you must cease using the Services and notify
          us of this decision by sending an email to
          support@executiveassistafrica.com . You must read, agree with and
          accept all of the terms and conditions contained or expressly
          referenced in these Terms of Service, Privacy Policy, and the
          Executive Assist Data Protection Policy before you can sign up for a
          MyHealthCenta account or use any HealthCenta and/or Executive Assist
          products and/or services.
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            fontFamily: "Lato",
            color: "#000",
          }}
          className='mt-3 mb-3'
        >
          Account terms
        </p>
        <p>
          To access and use the Services, you must register for a MyHealthCenta
          account (“Account”) by providing your full legal name, date of birth,
          current address, phone number, a valid email address, as well as the
          full legal name and contact information for your next of kin; and any
          other information indicated as required. HealthCenta may reject your
          application for an Account, or cancel an existing Account, for any
          reason, at our sole discretion.
        </p>
        <p>
          {" "}
          You must be the older of: (i) 15 years, or (ii) at least the age of
          majority in the jurisdiction where you reside and from which you open
          an Account and use the Services.  Any person(s) between 15 years and
          below 18 years of age opening a MyHealthCenta account must provide the
          full legal name, valid email address, valid current address and
          contact phone number of a legal guardian who must be above 18 years of
          age. You must also provide consent for HealthCenta to share data
          processed and stored in your MyHealthCenta account with your legal
          guardian where appropriate and as prescribed by law in the
          jurisdiction where you reside and from which you open an Account and
          use the Services.
        </p>
        <p>
          {" "}
          Any person(s) opening a MyHealthCenta account on behalf of a child
          below 15 years of age have full authority to access and manage the
          account. You must provide the full legal name and date of birth of the
          child you are operating the account for. It is your responsibility to
          ensure that any other information requested for use of the Services is
          provided and updated accordingly.  
        </p>
        <p>
          {" "}
          You confirm that you are receiving any Services provided by
          HealthCenta for the purposes of carrying on a personal activity and
          not for any illegal purposes as described in the jurisdiction where
          you reside and from which you open an Account and use the Services.
          You acknowledge that HealthCenta will use the email address you
          provide on opening an Account or as updated by you from time to time
          as the primary method for communication with you.
        </p>
        <p>
          {" "}
          You are responsible for keeping your account credentials secure.
          HealthCenta cannot and will not be liable for any loss or damage from
          your failure to maintain the security of your Account. You are
          responsible for safeguarding your passwords and for all activities
          that occur under your account. You should notify us immediately if you
          become aware of any breach of security or unauthorised use of your
          account. 
        </p>
        <p>
          {" "}
          A breach or violation of any term in the Terms of Service, as
          determined in the sole discretion of HealthCenta will result in the
          termination of your Account.
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            fontFamily: "Lato",
            color: "#000",
          }}
        >
          2. Account activation
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            fontFamily: "Lato",
            color: "#000",
          }}
        >
          2.1 Software-as-a-service
        </p>
        <p>
          Your Subscription is offered as a Software-as-a-Service. This means
          the Services will be provided via HealthCenta’s cloud-based
          application, “The Application”.
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            fontFamily: "Lato",
            color: "#000",
          }}
          className='mt-2 mb-2'
        >
          2.2 Subscription fees
        </p>
        <p>
          In the event you have a paid account, fees are non-refundable except
          as required by law. You will pay all applicable fees when due and, if
          such fees are being paid via credit card, mobile money and/or other
          electronic means, you authorise us to charge such fees using your
          selected payment method. By default, customer accounts are set to
          auto-renew and we may automatically charge you for such renewal on or
          after the renewal date associated with your account unless you have
          cancelled the Services prior to its renewal date. 
        </p>
        <p>
          {" "}
          You must keep a valid payment method on file with us to pay for all
          incurred and recurring Fees. HealthCenta will charge applicable Fees
          to any valid payment method that you authorize (“Authorized Payment
          Method”), and HealthCenta will continue to charge the Authorized
          Payment Method for applicable Fees until the Services are terminated,
          and any and all outstanding Fees have been paid in full.
        </p>
        <p>
          {" "}
          Subscription Fees are paid in advance and will be billed in 30-day
          intervals (each such date, a “Billing Date”). Additional Fees will be
          charged from time to time at HealthCenta’s discretion. You will be
          charged on each Billing Date for all outstanding Fees that have not
          previously been charged. Fees will appear on an invoice, which will be
          sent to the Subscription Owner via the email provided. Users have
          approximately two weeks to bring up and settle any issues with the
          billing of Subscription Fees.
        </p>
        <p>
          {" "}
          If we are not able to process payment of Fees using an Authorized
          Payment Method, we will make a second attempt to process payment using
          any Authorized Payment Method 3 days later. If the second attempt is
          not successful, we will make a final attempt 3 days following the
          second attempt. If our final attempt is not successful, we may suspend
          and revoke access to your Account and the Services. Your Account will
          be reactivated upon your payment of any outstanding Fees, plus the
          Fees applicable to your next billing cycle. You may not be able to
          access your Account during any period of suspension. If the
          outstanding Fees remain unpaid for 60 days following the date of
          suspension, HealthCenta reserves the right to terminate your Account.
        </p>
        <p>
          {" "}
          All Fees are exclusive of applicable local or other governmental
          sales, goods and services, harmonized or other taxes, fees or charges
          now in force or enacted in the future (“Taxes”). You are responsible
          for all applicable Taxes that arise from or as a result of your
          subscription to or purchase of HealthCenta’s products and services. To
          the extent that HealthCenta charges these Taxes, they are calculated
          using the tax rates that apply based on the billing address you
          provide to us. Such amounts are in addition to the Fees for such
          products and services and will be billed to your Authorized Payment
          Method. If you are exempt from payment of such Taxes, you must provide
          us with evidence of your exemption, which in some jurisdictions
          includes an original certificate that satisfies applicable legal
          requirements attesting to tax-exempt status. Tax exemption will only
          apply from and after the date we receive evidence satisfactory to
          HealthCenta of your exemption. If you are not charged Taxes by
          HealthCenta, you are responsible for determining if Taxes are payable,
          and if so, self-remitting Taxes to the appropriate tax authorities in
          your jurisdiction.
        </p>
        <p>
          {" "}
          We may revise fee rates for the Services from time to time and will
          provide you with email notice of any changes in fees at least thirty
          (30) days prior to your Service renewal date.
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            fontFamily: "Lato",
            color: "#000",
          }}
        >
          2.3 Subscription owner
        </p>
        <p>
          Subject to section 2 (2.1), the person signing up for the Service by
          opening an Account will be the contracting party (“Subscription
          Owner”) for the purposes of our Terms of Service and will be the
          person who is authorised to use any corresponding Account we may
          provide to the Subscription Owner in connection with the Service.
        </p>
        <p>
          {" "}
          Your HealthCenta Account can only be associated with one Subscription
          Owner.
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            fontFamily: "Lato",
            color: "#000",
          }}
        >
          2.4 Additional account terms
        </p>
        <p
          style={{
            fontSize: 14,
            fontWeight: 600,
            fontFamily: "Lato",
            color: "#000",
          }}
        >
          (i). Use of the health centa software
        </p>
        <p>
          You may use HealthCenta Software solely for purposes of enabling you
          to use the Services as provided by HealthCenta, and as permitted by
          these Conditions of Use and any Service Terms. You may not incorporate
          any portion of the HealthCenta Software into other programs or compile
          any portion of it in combination with other programs, or otherwise
          copy (except to exercise rights granted in this section), modify,
          create derivative works of, distribute, assign any rights to, or
          licence the HealthCenta Software in whole or in part. All software
          used in any HealthCenta Service is the property of HealthCenta or its
          software suppliers and is protected by the Government of Kenya and
          international copyright laws.
        </p>
        <p
          style={{
            fontSize: 14,
            fontWeight: 600,
            fontFamily: "Lato",
            color: "#000",
          }}
        >
          (ii). Use of third party services
        </p>
        <p>
          When you use the HealthCenta Software, you may also be using the
          services of one or more third parties, such as a wireless carrier or a
          mobile software provider. Your use of these third party services may
          be subject to the separate policies, terms of use, and fees of these
          third parties.
        </p>
        <p
          style={{
            fontSize: 14,
            fontWeight: 600,
            fontFamily: "Lato",
            color: "#000",
          }}
        >
          (iii). No reverse engineering
        </p>
        <p>
           You may not reverse engineer, decompile or disassemble, tamper with,
          or bypass any security associated with the HealthCenta Software,
          whether in whole or in part.
        </p>
        <p
          style={{
            fontSize: 14,
            fontWeight: 600,
            fontFamily: "Lato",
            color: "#000",
          }}
        >
          (iv). Updates
        </p>
        <p>
          We may offer automatic or manual updates to the HealthCenta Software
          at any time and without notice to you.
        </p>
        <p
          style={{
            fontSize: 14,
            fontWeight: 600,
            fontFamily: "Lato",
            color: "#000",
          }}
        >
          (v). Conflicts
        </p>
        <p>
           In the event of any conflict between these Conditions of Use and any
          other HealthCenta or third-party terms applicable to any portion of
          HealthCenta Software, such as open-source licence terms, such other
          terms will control as to that portion of the HealthCenta Software and
          to the extent of the conflict.
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            fontFamily: "Lato",
            color: "#000",
          }}
        >
          3.  Financial Terms
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            fontFamily: "Lato",
            color: "#000",
          }}
        >
          3.1 Patient fees
        </p>
        <p>
          HealthCenta will charge fees (“Patient Fees”) for Health Centre
          Services provided by our partner Health Centres, medical professionals
          and other third party providers (“Partners”), where such Patient Fees
          are based upon a set amount charged by the Partners for the provision
          of the Health Centre Services. HealthCenta will charge comprehensive
          fees incurred in the provision of the Health Centre Services including
          taxes and all other charges and ensure no separate Patient Fees are
          applicable for the same service rendered or product purchased unless
          otherwise agreed with our Partners in which case HealthCenta will
          notify you on purchase. 
        </p>
        <p>
          {" "}
          Any revisions to the Patient Fees will be communicated by HealthCenta
          at the point of purchase or at least thirty (30) days prior to the
          amendment date depending on the notice provided by the Partners to
          HealthCenta. HealthCenta will not be held liable for any increased
          fees that are not reflected on the Application if these changes are
          not communicated to HealthCenta by the Partners within the set
          timeline. 
        </p>
        <p>
          {" "}
          Notwithstanding anything to the contrary in this Section 3.1, if the
          Partners are providing healthcare consultation services, the following
          shall apply: (a) the Patient Fees is calculated pursuant to any
          national health regulations in the jurisdiction where you reside and
          from which you open an Account and use the Services ; and (b) the
          Partners agree to provide the exact amount of the Patient Fees for the
          provision of the Health Centre Services; and (c) any additional
          charges that the Patient will pay the Partners in person will be
          agreed to not be included in the Application and the Patient will be
          notified once an appointment is booked or service is sought from the
          Partners.
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            fontFamily: "Lato",
            color: "#000",
          }}
        >
          3.2 Health Centre Services cancellation charges
        </p>
        <p>
          HealthCenta acknowledges and agrees that you may elect to cancel
          requests for appointments that have been accepted by a Health Centre
          before the appointment. In the event that you cancel an appointment
          within 24 hours of the appointment, HealthCenta may charge you a
          cancellation fee on behalf of the Partners. If charged, this
          cancellation fee shall be deemed the Patient Fees for the cancelled
          appointment for the purpose of remittance to the Partners hereunder
          (“Cancellation Fee”). You acknowledge and agree that the amount of the
          Cancellation Fee will vary and apply as provided by the Partners for
          their individual Health Centre Services and will be charged in the
          event that you have not cancelled an appointment within the set
          timeline of 24 hours.
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            fontFamily: "Lato",
            color: "#000",
          }}
        >
          3.3 Receipts
        </p>
        <p>
          As part of the Application, HealthCenta provides you with receipts for
          booked appointments. Upon the completion of payment for the
          appointment booked, HealthCenta will prepare an applicable receipt and
          issue such receipt via email and/or SMS on behalf of the Partners.
          Such receipts are also provided via the Application. Receipts include
          the breakdown of amounts charged to you for the Health Centre
          Services.
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            fontFamily: "Lato",
            color: "#000",
          }}
        >
          4. General Conditions
        </p>
        <p>
          You must read, agree with and accept all of the terms and conditions
          contained in these Terms of Service, including the Privacy Policy
          before you may become a HealthCenta User. 
        </p>
        <p>
          {" "}
          Technical support in respect of the Services is only provided to
          HealthCenta Users.
        </p>
        <p>
          {" "}
          The Terms of Service shall be governed by and interpreted in
          accordance with the laws of the government of Kenya applicable
          therein, without regard to principles of conflicts of laws. 
        </p>
        <p>
          {" "}
          You acknowledge and agree that HealthCenta may amend these Terms of
          Service at any time by posting the relevant amended and restated Terms
          of Service on Executive Assist’s website, available at &nbsp;
          <a
            href='https://www.executiveassistafrica.com/myhealthcenta/legal/terms'
            target='_blank'
          >
            https://www.executiveassistafrica.com/myhealthcenta/legal/terms
          </a>
            and such amendments to the Terms of Service are effective as of the
          date of posting. Your continued use of the Services after the amended
          Terms of Service are posted to Executive Assist’s website constitutes
          your agreement to, and acceptance of, the amended Terms of Service for
          HealthCenta. If you do not agree to any changes to the Terms of
          Service, do not continue to use the Service.
        </p>
        <p>
          {" "}
          You may not use the HealthCenta Services for any illegal or
          unauthorised purpose nor may you, in the use of the Service, violate
          any laws in your jurisdiction (including but not limited to copyright
          laws), the laws applicable to you in your customer’s jurisdiction, or
          the laws of the government of Kenya. You will comply with all
          applicable laws, rules and regulations in your use of the Service and
          your performance of obligations under the Terms of Service.
        </p>
        <p>
          {" "}
          You agree not to reproduce, duplicate, copy, sell, resell or exploit
          any portion of the Service, use of the Services, or access to the
          Services without the express written permission by HealthCenta.
          Questions about the Terms of Service should be sent to
          support@executiveassistafrica.com. 
        </p>
        <p>
          {" "}
          You understand that your Materials (not including credit card
          information), may be transferred unencrypted and involve (a)
          transmissions over various networks; and (b) changes to conform and
          adapt to technical requirements of connecting networks or devices.
          Credit card and other payment information is always encrypted during
          transfer over networks.
        </p>
        <p>
          You acknowledge and agree that your use of the Services, including
          information transmitted to or stored by HealthCenta, is governed by
          its privacy policy at &nbsp;
          <a
            href='https://www.executiveassistafrica.com/myhealthcenta/legal/privacy'
            target='_blank'
          >
            https://www.executiveassistafrica.com/myhealthcenta/legal/privacy
          </a>
          .
        </p>
        <p>
          {" "}
          The Terms of Service may be available in languages other than English.
          To the extent of any inconsistencies or conflicts between these
          English Terms of Service and HealthCenta’s Terms of Service available
          in another language, the most current English version of the Terms of
          Service at &nbsp;
          <a
            href='https://www.executiveassistafrica.com/myhealthcenta/legal/terms'
            target='_blank'
          >
            https://www.executiveassistafrica.com/myhealthcenta/legal/terms
          </a>{" "}
          will prevail. All the terms and provisions of the Terms of Service
          shall be binding upon and inure to the benefit of the parties to the
          Terms of Service and to their respective heirs, successors, permitted
          assignees and legal representatives. HealthCenta shall be permitted to
          assign these Terms of Service without notice to you or consent from
          you. You shall have no right to assign or otherwise transfer the Terms
          of Service, or any of your rights or obligations hereunder, to any
          third party without HealthCenta’s prior written consent, to be given
          or withheld at HealthCenta’s sole discretion.
        </p>
        <p>
          {" "}
          If any provision, or portion of the provision, in these Terms of
          Service is, for any reason, held to be invalid, illegal or
          unenforceable in any respect, then such invalidity, illegality or
          unenforceability will not affect any other provision (or the
          unaffected portion of the provision) of the Terms of Service, and the
          Terms of Service will be construed as if such invalid, illegal or
          unenforceable provision, or portion of the provision, had never been
          contained within the Terms of Service. You irrevocably and
          unconditionally agree to submit to the jurisdiction of Kenyan courts,
          which have jurisdiction to settle any disputes which may arise out of
          or in connection with the validity, effect, interpretation or
          performance of these Terms of Service and you similarly irrevocably
          and unconditionally waive any objection to any claim that any suit,
          action or proceeding has been brought by HealthCenta in any other
          jurisdiction. 
        </p>
        <p>
          {" "}
          Sections 1 to 12 will survive the termination or expiration of these
          Terms of Service.
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            fontFamily: "Lato",
            color: "#000",
          }}
        >
          5. HealthCenta Rights
        </p>
        <p>
          We reserve the right to modify or terminate the Services for any
          reason, without notice at any time. Not all Services and features are
          available in every jurisdiction and we are under no obligation to make
          any Services or features available in any jurisdiction.
        </p>
        <p>
          {" "}
          We reserve the right to refuse service to anyone for any reason at any
          time. We may, but have no obligation to suspend or terminate Accounts
          if we determine in our sole discretion that the you are in violation
          of these Terms of Service. Verbal or written abuse of any kind
          (including threats of abuse or retribution) of any HealthCenta client,
          HealthCenta employee, member, or officer will result in immediate
          Account termination.
        </p>
        <p>
          {" "}
          In the event of a dispute regarding Account ownership, we reserve the
          right to request documentation to determine or confirm Account
          ownership. Documentation may include, but is not limited to, a scanned
          copy of your government issued photo ID, the last four digits of the
          credit card on file, your status as a guardian, etc. HealthCenta
          retains the right to determine, in our sole judgement, rightful
          Account ownership and transfer an Account to the rightful Subscription
          Owner. If we are unable to reasonably determine the rightful
          Subscription Owner, without prejudice to our other rights and
          remedies, HealthCenta reserves the right to temporarily disable an
          Account until resolution has been determined between the disputing
          parties.
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            fontFamily: "Lato",
            color: "#000",
          }}
        >
          6. Confidentiality
        </p>
        <p>
          “Confidential Information” shall include, but shall not be limited to,
          any and all information associated with a party’s business and not
          publicly known, including specific business information, technical
          processes and formulas, software, customer lists, prospective customer
          lists, names, addresses and other information regarding customers and
          prospective customers, product designs, sales, costs (including any
          relevant processing fees), price lists, and other unpublished
          financial information, business plans and marketing data, and any
          other confidential and proprietary information, whether or not marked
          as confidential or proprietary. HealthCenta’s Confidential Information
          includes all information that you receive relating to us, or to the
          Services, that is not known to the general public including
          information related to our security program and practices.
        </p>
        <p>
          {" "}
          Each party agrees to use the other party’s Confidential Information
          solely as necessary for performing its obligations under these Terms
          of Service and in accordance with any other obligations in these Terms
          of Service including this Section 7. Each party agrees that it shall
          take all reasonable steps, at least substantially equivalent to the
          steps it takes to protect its own proprietary information, to prevent
          the duplication, disclosure or use of any such Confidential
          Information, other than (i) by or to its employees, agents and
          subcontractors who must have access to such Confidential Information
          to perform such party’s obligations hereunder, who each shall treat
          such Confidential Information as provided herein, and who are each
          subject to obligations of confidentiality to such party that are at
          least as stringent as those contained herein; or (ii) as required by
          any law, regulation, or order of any court of proper jurisdiction over
          the parties and the subject matter contained in these Terms of
          Service, provided that, if legally permitted, the receiving party
          shall give the disclosing party prompt written notice and use
          commercially reasonable efforts to ensure that such disclosure is
          accorded confidential treatment. Confidential Information shall not
          include any information that the receiving party can prove:
        </p>
        <p>
          {" "}
          a). was already in the public domain, or was already known by or in
          the possession of the receiving party, at the time of disclosure of
          such information;
          <br /> b) is independently developed by the receiving party without
          use of or reference to the other party’s Confidential Information, and
          without breaching any provisions of these Terms of Service; or <br />
          c). is thereafter rightly obtained by the receiving party from a
          source other than the disclosing party without breaching any provision
          of these Terms of Service.
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            fontFamily: "Lato",
            color: "#000",
          }}
        >
          7. Limitation of liability
        </p>
        <p>
          You expressly understand and agree that, to the extent permitted by
          applicable laws, HealthCenta shall not be liable for any direct,
          indirect, incidental, special, consequential or exemplary damages,
          including but not limited to, damages for loss of profits, goodwill,
          use, data or other intangible losses resulting from the use of or
          inability to use the Services.
        </p>
        <p>
          {" "}
          To the extent permitted by applicable laws, in no event shall
          HealthCenta or our suppliers be liable for lost profits or any
          special, incidental or consequential damages arising out of or in
          connection with our site, our Services or these Terms of Service
          (however arising including negligence). You agree to indemnify and
          hold us and (as applicable) our parent, subsidiaries, affiliates,
          HealthCenta and Executive Assist partners, officers, directors,
          agents, employees, and suppliers harmless from any claim or demand,
          including reasonable attorneys’ fees, made by any third party due to
          or arising out of your breach of these Terms of Service or your
          violation of any law or the rights of a third party.
        </p>
        <p>
          {" "}
          Your use of the Services is at your sole risk. The Services are
          provided on an “as is” and “as available” basis without any warranty
          or condition, express, implied or statutory.
        </p>
        <p>
          {" "}
          HealthCenta does not warrant that the Services will be uninterrupted,
          timely, secure, or error-free.
        </p>
        <p>
          {" "}
          HealthCenta does not warrant that the results that may be obtained
          from the use of the Services will be accurate or reliable. HealthCenta
          does not warrant that the quality of any products, services,
          information, or other materials purchased or obtained by you through
          the Services will meet your expectations, or that any errors in the
          Services will be corrected.
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            fontFamily: "Lato",
            color: "#000",
          }}
        >
          8.  Waiver and complete agreement
        </p>
        <p>
          The failure of HealthCenta to exercise or enforce any right or
          provision of the Terms of Service shall not constitute a waiver of
          such right or provision. The Terms of Service, including the documents
          it incorporates by reference, constitute the entire agreement between
          you and HealthCenta and govern your use of the Services and your
          Account, superseding any prior agreements between you and Executive
          Assist (including, but not limited to, any prior versions of the Terms
          of Service).
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            fontFamily: "Lato",
            color: "#000",
          }}
        >
          9.Intellectual property and client content
        </p>
        <p>
          We do not claim any intellectual property rights over the Materials
          you provide to the HealthCenta Service. All Material you upload
          remains yours. You can request for all Materials to be removed from
          your Account at any time by sending a request for your Account to be
          deleted to support@executiveassistafrica.com.
        </p>
        <p>
          By uploading Materials, you agree:
          <br /> a). to allow HealthCenta employees to view the Materials;
          <br /> b). to allow HealthCenta employees to use your Materials; and{" "}
          <br />
          c). that HealthCenta can, at any time, review and delete all the
          Materials submitted to its Service, although HealthCenta is not
          obligated to do so.
        </p>
        <p>
          {" "}
          You retain ownership over all Materials that you upload to the
          HealthCenta Service. HealthCenta shall have the non-exclusive right
          and licence to use the names, trademarks, service marks and logos
          associated with your Account to promote the Services.
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            fontFamily: "Lato",
            color: "#000",
          }}
        >
          10. Cancellation and Termination
        </p>
        <p>
          You may cancel your Account and terminate the Terms of Service at any
          time by contacting support@executiveassistafrica.com and then
          following the specific instructions indicated to you in HealthCenta’s
          response. Upon termination of the Services by either party for any
          reason:  HealthCenta will cease providing you with the Services and
          you will no longer hold your Account;
        </p>
        <p>
          unless otherwise provided in the Terms of Service, you will not be
          entitled to any refunds of any Fees, pro rata or otherwise; any
          outstanding balance owed to HealthCenta for your use of the Services
          through the effective date of such termination will immediately become
          due and payable in full; and if at the date of termination of the
          Service, there are any outstanding Fees owing by you, you will receive
          one final invoice via email. Once that invoice has been paid in full,
          you will not be charged again.
        </p>
        <p>
          We reserve the right to modify or terminate the HealthCenta Service,
          the Terms of Service and/or your Account for any reason, without
          notice at any time. Termination of the Terms of Service shall be
          without prejudice to any rights or obligations which arose prior to
          the date of termination.
        </p>
        <p>
          {" "}
          Fraud: Without limiting any other remedies, HealthCenta may suspend or
          terminate your Account if we suspect that you (by conviction,
          settlement, insurance or escrow investigation, or otherwise) have
          engaged in fraudulent activity in connection with the use of the
          Services.
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            fontFamily: "Lato",
            color: "#000",
          }}
        >
          11. Privacy & Data Protection
        </p>
        <p>
          HealthCenta is firmly committed to protecting the privacy of your
          personal information and the personal information of your clients. By
          using the Services, you acknowledge and agree that Executive Assist’s
          collection, usage and disclosure of this personal information is
          governed by our Privacy Policy.
        </p>
        <p>
          {" "}
          Additionally, Executive Assist’s collection and use of personal
          information of all Subscription Owners is also subject to our Data
          Processing Policy.
        </p>
        <div
          className='mt-5 mb-5'
          style={{
            height: 1,
            backgroundColor: "#52575C",
          }}
        />
        <span
          style={{
            fontSize: 14,
            fontWeight: 500,
            fontStyle: "italic",
            fontFamily: "Lato",
          }}
        >
          Executive Assist Ltd The Mirage, Tower 2 Waiyaki Way, Westlands, P.O.
          Box 100240-00101 Nairobi, Kenya Last updated on: 20 May 2022.
        </span>
      </div>
    </div>
  );
}

export default TermsOfServices