import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import GraphButtons from './GraphButtons'
import { Line } from 'react-chartjs-2'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { selectUser } from '../../../redux_setup/slices/userSlice'
import { data_options, calorie_intake } from '../../../constants/Graph_data'
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from 'chart.js'
import { isEmptyArray } from 'formik'
import { titleSentenceCase } from '../../../constants/Essentials'
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement)
const NutritionGraph = ({ patientDetails }) => {
  const [graphdata, setGraphdata] = useState({
    calories: null,
    sodium: null,
    fat: null,
  })
  const [noDataFound, setNoDataFound] = useState(false)
  const [dataFetched, setDataFetched] = useState(false)
  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  const user = useSelector(selectUser)
  const url = ``
  toast.configure()
  const location = useLocation()
  const fetchData = (timeframe = 'today') => {
    fetch(
      `${process.env.REACT_APP_MAIN_URL_HYPERTENSION}/api/careplans/hypertensionv1/nutrition/analysis?patient=${patientDetails.id}&timeframe=${timeframe}`,
      {
        signal: signal,
        method: 'GET', //if you can find an id in labs, then update
        headers: new Headers({
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }
        console.log('data fetched', res.data)
        if (isEmptyArray(res.data)) {
          setNoDataFound(true)
        }
        // calories data
        const caloriesdata = []
        caloriesdata.push(res.data[0].calories)
        caloriesdata.push(res.data[0].calories)
        caloriesdata.push(res.data[0].calories)
        caloriesdata.push(res.data[1].calories)
        caloriesdata.push(res.data[1].calories)
        caloriesdata.push(res.data[2].calories)
        caloriesdata.push(res.data[2].calories)
        // sodium data
        const sodiumdata = []
        sodiumdata.push(res.data[0].sodium)
        sodiumdata.push(res.data[0].sodium)
        sodiumdata.push(res.data[0].sodium)
        sodiumdata.push(res.data[1].sodium)
        sodiumdata.push(res.data[1].sodium)
        sodiumdata.push(res.data[2].sodium)
        sodiumdata.push(res.data[2].sodium)

        // fat data
        const fatdata = []
        fatdata.push(res.data[0].fat)
        fatdata.push(res.data[0].fat)
        fatdata.push(res.data[0].fat)
        fatdata.push(res.data[1].fat)
        fatdata.push(res.data[1].fat)
        fatdata.push(res.data[2].fat)
        fatdata.push(res.data[2].fat)

        setGraphdata({
          ...graphdata,
          calories: caloriesdata,
          sodium: sodiumdata,
          fat: fatdata,
        })
        setDataFetched(true)
      })
      .catch((err) => {
        console.log(err)
        return toast.info(
          'Graph data not found for the selected period. Please change period',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        )
      })
  }
  const onclickHandler = (event) => {
    fetchData(event.toLowerCase())
  }
  useEffect(() => {
    fetchData()
  }, [])

  const ErrorFallback = (error, resetErrorBoundary) => {
    return (
      <div role="alert">
        <p>Not enough data found to draw graph:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    )
  }
  if (!dataFetched) {
    // here
    return !noDataFound ? (
      <div>
        <h5>Loading...</h5>
      </div>
    ) : (
      <div className="flex__container" style={{ gap: 50 }}>
        <h3>No Graph data found</h3>
        <GraphButtons
          nutritionType={'fat intake'}
          clickHandler={(e) => onclickHandler(e, 'Calories data fetched...')}
        />
      </div>
    )
  } else {
    return (
      <>
        <div
          className="graph__container"
          style={{ backgroundColor: 'transparent' }}
        >
          <div className="row" style={{ marginLeft: -100 }}>
            <div className="col-md-6 col-sm-12">
              <div className="flex__container">
                <p className="vital__tile">Nutrition</p>
                <div className="graph__card">
                  <svg
                    width="63"
                    height="26"
                    viewBox="0 0 63 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 26V10.3755L5.60289 18.645L11.8586 14.0553L17.1566 8.95548L22.5442 6.43643L27.8222 8.95548L33.8206 14.6354L39.481 8.95548L45.2619 14.0553L50.9223 3.80797C50.9223 3.80797 52.9538 5.69498 53.7891 7.26675C53.9586 7.58562 62.0776 -1.1916 62.7162 0.137052C63.3548 1.4657 62.7162 26 62.7162 26H0Z"
                      fill="url(#paint0_linear_3030_16938)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_3030_16938"
                        x1="-25.6449"
                        y1="-18.7048"
                        x2="-25.6449"
                        y2="28.4624"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#D60A51" stop-opacity="0.6" />
                        <stop offset="1" stop-color="white" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <div className="flex__container" style={{ marginTop: 10 }}>
                    <small className="graph__card__text">Sodium</small>
                    <small className="graph__card__text">Calorie</small>
                    <small className="graph__card__text">Water</small>
                    <small className="graph__card__text">Fat</small>
                  </div>
                  <div className="flex__container" style={{ marginTop: 10 }}>
                    <small
                      className="graph__card__subtext"
                      style={{ marginLeft: 10 }}
                    >
                      100MG
                    </small>
                    <small className="graph__card__subtext">389KCAL</small>
                    <small
                      className="graph__card__subtext"
                      style={{ marginLeft: 10 }}
                    >
                      2L
                    </small>
                    <small
                      className="graph__card__subtext"
                      style={{ marginLeft: 10 }}
                    >
                      57G
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="graph__patient__identification__card">
                <p
                  className="graph__card__text"
                  style={{ color: '#52575C', fontSize: 16 }}
                >
                  Name: {titleSentenceCase(location.state.name)}
                </p>
                <p
                  className="graph__card__text"
                  style={{ color: '#52575C', fontSize: 16 }}
                >
                  Diagnosis: Hypertension stage 1
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="btn_component__container" style={{ marginTop: -230 }}>
          <div
            className="flex__container"
            style={{ position: 'absolute', left: 10 }}
          >
            <p className="title">Calorie Intake</p>
            <div className="graph__legend" />
          </div>
          <GraphButtons
            nutritionType={'calorie intake'}
            clickHandler={(e) => onclickHandler(e, 'Calories data fetched...')}
          />
        </div>
        <div className="graph__container">
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Line
              data={calorie_intake(graphdata.calories)}
              options={data_options}
            />
          </ErrorBoundary>
        </div>
        <div className="btn_component__container">
          <div
            className="flex__container"
            style={{ position: 'absolute', left: 10 }}
          >
            <p className="title">Fat Intake</p>
            <div className="graph__legend" />
          </div>
          <GraphButtons nutritionType={'fat intake'} />
        </div>
        <div className="graph__container">
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Line data={calorie_intake(graphdata.fat)} options={data_options} />
          </ErrorBoundary>
        </div>
        <div className="btn_component__container">
          <div
            className="flex__container"
            style={{ position: 'absolute', left: 10 }}
          >
            <p className="title">Sodium Intake</p>
            <div
              className="graph__legend"
              style={{ backgroundColor: '#FFC000' }}
            />
          </div>
          <GraphButtons nutritionType={'sodium intake'} />
        </div>
        <div className="graph__container">
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Line
              data={calorie_intake(graphdata.sodium, '#FFC000')}
              options={data_options}
            />
          </ErrorBoundary>
        </div>
        <div className="btn_component__container">
          <div
            className="flex__container"
            style={{ position: 'absolute', left: 10 }}
          >
            <p className="title">Water Intake</p>
            <div
              className="graph__legend"
              style={{ backgroundColor: '#45B757' }}
            />
          </div>
          <GraphButtons nutritionType={'water intake'} />
        </div>
        <div className="graph__container">
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Line
              data={calorie_intake(graphdata.calories, '#45B757')}
              options={data_options}
            />
          </ErrorBoundary>
        </div>
      </>
    )
  }
}
export default NutritionGraph
