import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import Select from 'react-select'

import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { trimDate } from '../constants/Essentials'
import { formatDate } from '@fullcalendar/react'

const PatientTriageHistoryComponent = () => {
  toast.configure()
  let navigate = useNavigate()
  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  const user = useSelector(selectUser)
  const [currentTab, setcurrentTab] = useState('physical_assessment')

  // inputs states
  const [height, setHeight] = useState('')
  const [weight, setWeight] = useState('')
  let bmi = weight / Math.pow(height / 100, 2)
  const [knownAllergies, setKnownAllergies] = useState('')
  const [terminalIllness, setTerminalIllness] = useState('')
  const [foodAllergy, setFoodAllergy] = useState(false)
  const [pollenAllergy, setPollenAllergy] = useState(false)
  const [dustAllergy, setDustAllergy] = useState(false)
  const [petAllergy, setPetAllergy] = useState(false)
  const [latexAllergy, setLatexAllergy] = useState(false)
  const [whichterminalIllness, setWhichTerminalIllness] = useState('')
  const [medicineAllergy, setMedicineAllergy] = useState('')

  const [alcoholHabit, setAlcoholHabit] = useState(false)
  const [smokingHabit, setSmokingHabit] = useState(false)
  const [drugHabit, setDrugHabit] = useState(false)
  const [currentSymptoms, setCurrentSymptoms] = useState('')
  const [illnessStart, setIllnessStart] = useState('')
  const [triedSelfTreatment, setTriedSelfTreatment] = useState('')
  const [whatTried, setWhatTried] = useState('')
  const [oftenDrink, setOftenDrink] = useState('')
  const [oftenSmoke, setOftenSmoke] = useState('')
  const [oftenDrugs, setOftenDrugs] = useState('')
  const [bottlesDay, setBottlesDay] = useState('')
  const [packsDay, setPacksDay] = useState('')
  const [dateOptions, setDateOptions] = useState([])
  const [mainRes, setMainRes] = useState([])
  const [currentRes, setCurrentRes] = useState({})

  const triageUrl = `${process.env.REACT_APP_MAIN_URL}/api/vital/medical-history?patient_id=${user.user_id}`
  useEffect(() => {
    fetch(triageUrl, {
      signal: signal,
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning('An error occured while fetching history', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          })
        }
        const response = res.data.items
        const dates = []
        response.map((res) => {
          dates.push({
            value: res.createdAt,
            label: `${trimDate(res.createdAt)}`,
          })
        })
        // console.log(response);
        setDateOptions(dates)
        setMainRes(response)
        setCurrentRes(response[0])
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  const handleChange = (date) => {
    const res = mainRes.filter((item) => item.createdAt === date)
    setCurrentRes(res[0])
  }

  useEffect(() => {
    const response = currentRes
    setKnownAllergies(response?.known_allergies)
    bmi = response?.bmi
    setHeight(response?.height)
    setWeight(response?.weight)
    setCurrentSymptoms(response?.current_symptoms)
    setIllnessStart(response?.illness_start)
    setTriedSelfTreatment(response?.self_treatment)
    setWhatTried(response?.which_self_treatment)

    setMedicineAllergy(response?.know_medicine_allergies)
    setTerminalIllness(response?.terminal_illness)
    setWhichTerminalIllness(response?.which_terminal_illness)

    setOftenDrink(response?.often_drink)
    setBottlesDay(response?.bottles_a_day)
    setOftenSmoke(response?.often_smoke)
    setPacksDay(response?.packs_a_day)
    setOftenDrugs(response?.often_drugs)

    const parsedhabits = response?.habits ? JSON.parse(response?.habits) : []
    setAlcoholHabit(parsedhabits?.alcohol)
    setSmokingHabit(parsedhabits?.smoking)
    setDrugHabit(parsedhabits?.drugs)

    const parsedapplicables = response?.where_applicable
      ? JSON.parse(response?.where_applicable)
      : []
    setFoodAllergy(parsedapplicables?.food)
    setPollenAllergy(parsedapplicables?.pollen)
    setDustAllergy(parsedapplicables?.dust)
    setPetAllergy(parsedapplicables?.pet)
    setLatexAllergy(parsedapplicables?.latex)
  }, [currentRes])

  return (
    <div className="content-body">
      {/* row */}

      {/* <div className="container-fluid">
        <div className="form-head page-titles d-flex mb-md-4">
          <div className="mr-auto">
            <h2 className="text-black font-w600">Doctor</h2>
          </div>
        </div>
      </div> */}
      <div className="container-fluid pt-0">
        {mainRes.length ? (
          <div className="row">
            <div className="col-lg-12 col-xxl-12">
              <div className="card ">
                <div className="card-header">
                  <h4 className="card-title">Triage History</h4>
                </div>
                <div className="card-body text-left">
                  <div className="form-group row">
                    <label className="col-md-2">Date Recorded: </label>
                    <div className="col-md-6">
                      <select
                        onChange={(e) => handleChange(e.target.value)}
                        className="form-control"
                      >
                        {dateOptions.map((d) => (
                          <option key={d.value} value={d.value}>
                            {d.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <p>
                        <strong>Height:</strong> {height}
                      </p>
                      <p>
                        <strong>Weight:</strong> {weight}
                      </p>
                      <p>
                        <strong>BMI:</strong>
                        {bmi}
                      </p>
                      <p>
                        <strong>Medicine Allergy?:</strong> {medicineAllergy}
                      </p>
                      <p>
                        <strong>Terminal Illness:</strong>
                        {`${terminalIllness} - ${whichterminalIllness}`}
                      </p>
                      <p>
                        <strong>Have Known Allergies?:</strong>
                        {`${knownAllergies} - ${
                          foodAllergy === true ? 'food, ' : ''
                        }${pollenAllergy === true ? 'Pollen, ' : ''}${
                          dustAllergy === true ? 'Dust, ' : ''
                        }${petAllergy === true ? 'pet, ' : ''}${
                          latexAllergy === true ? 'Latex' : ''
                        }`}
                      </p>
                      <p>
                        <strong>Often Drink?:</strong>
                        {`${oftenDrink} -${bottlesDay || 0} in a day`}
                      </p>
                      <p>
                        <strong>Often Smoke?:</strong>
                        {`${oftenSmoke} -${packsDay || 0} in a day`}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p>
                        <strong>Do you do drugs?: </strong>
                        {drugHabit ? 'Yes' : 'No'}
                      </p>
                      <p>
                        <strong>What are your current symptoms?</strong>
                      </p>
                      <p>{currentSymptoms}</p>
                      <p>
                        <strong>When did the illness start?</strong>
                      </p>
                      <p>{formatDate(illnessStart, { locale: 'es' })}</p>
                      <p>
                        <strong>Have you tried self treatment?</strong>
                      </p>
                      <p>{`${triedSelfTreatment} - ${whatTried}`}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="alert alert-info">
            <p>You have no added triage info yet</p>
          </div>
        )}
      </div>

      {/* <a href="/ManagePatients">
          <button className="btn btn-light btl-lg shadow"> 
          Manage Patients
          </button> </a> */}

      {/* <MainApp appointments={appointments} today={appointmentsToday} /> */}
      {/* <AppointmentActivity /> */}
    </div>
  )
}

export default PatientTriageHistoryComponent
