import { useRef } from 'react'
import ReactToPrint from 'react-to-print'
import Footer from '../Footer'
import Header from '../Header'
import LeftBar from '../LeftBar'
import { Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import {
  selectprescription,
  selectRx,
} from '../../redux_setup/slices/prescriptionSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { calculateAge } from '../../constants/Essentials'
import { selectUser } from '../../redux_setup/slices/userSlice'
import { useState } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Swal from 'sweetalert2'
const PrescriptionPrintView = () => {
  const prescriptionData = useSelector(selectprescription)
  const rx_number = useSelector(selectRx)
  toast.configure()
  const user = useSelector(selectUser)
  const location = useLocation()
  const patientDetails = location.state.patientDetails
  const componentRef = useRef()
  const [pharmacyEmail, setPharmacyEmail] = useState('')
  let navigate = useNavigate()

  const sendPrescriptionEmail = () => {
    const dataToSend = {
      patient_name: patientDetails?.name,
      patient_email: patientDetails?.email,
      pharmacy_email: pharmacyEmail,
      prescriptionData,
      doctor_name: user?.userName,
    }
    const url = `${process.env.REACT_APP_MAIN_URL}/api/doctor/send-prescription`
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }
        setPharmacyEmail('')

        // navigate('/')

        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
      .catch((err) => {
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
        })
      })
  }

  const completeConsultation = () => {
    console.log('patient details', patientDetails)
    Swal.fire({
      title: 'You have completed consultation with this patient',
      showDenyButton: true,
      showCancelButton: true,
      icon: 'success',
      width: '600',
      confirmButtonText: 'Book Follow Up',
      denyButtonText: 'Nurse Instructions',
      cancelButtonText: 'Back To Dashboard',
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log('Booked Follow Up!', '', 'success')

        navigate('/book-follow-up', {
          state: {
            app_id: patientDetails.appt_id,
            patient_id: patientDetails.patient_id,
            doctor_id: user.user_id,
            health_center: user.hc_id,
          },
        })
      } else if (result.isDenied) {
        navigate('/instruct-nurse', {
          state: {
            appt_id: patientDetails.appt_id,
          },
        })
      } else {
        console.log('cancelled')
        navigate('/')
      }
    })
  }
  return (
    <div className="main-wrapper">
      {/***********************************
                    Header start
                    ************************************/}
      <Header />
      {/***********************************
                    Header end ti-comment-alt
                    ************************************/}
      {/***********************************
                    Sidebar start
                    ************************************/}
      <LeftBar />
      {/***********************************
                    Sidebar end
                    ************************************/}
      {/***********************************
                    Content body start
                    ************************************/}

      {/***********************************
                    Content body end
                    ************************************/}
      <div className="content-body">
        <div ref={componentRef} style={{ marginLeft: 20, marginTop: 30 }}>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="flex__container" style={{ gap: 50 }}>
                <img src={`${process.env.PUBLIC_URL}/images/clinicklogo.png`} />
                <div className="flex__block">
                  <span className="header__title" style={{ color: '#25282B' }}>
                    Street Name, Suit 00, State,
                    <br />
                    Zip Code, Country
                  </span>
                  <span className="header__title">
                    <span style={{ color: '#45B757' }}>t:&nbsp;&nbsp;</span>+123
                    00 456 7890
                  </span>
                  <span className="header__title">
                    <span style={{ color: '#45B757' }}>e:&nbsp;&nbsp;</span>
                    email@doctorschamber.com
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div style={{ marginLeft: 200 }}>
                <p style={{ color: '#45B757' }}>Patient details</p>
                <div className="flex__container">
                  <label className="invoice__label">
                    <b className="text-dark">Name: </b>
                    {patientDetails?.name}
                  </label>
                  <label className="invoice__label">
                    <b className="text-dark">Gender: </b>
                    {patientDetails?.gender}
                  </label>
                </div>
                <div className="flex__container">
                  <label className="invoice__label">
                    <b className="text-dark">Age: </b>
                    {calculateAge(patientDetails?.age)} years
                  </label>
                  <label className="invoice__label">
                    <b className="text-dark">Phone: </b>
                    {patientDetails?.phone}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: 100 }}>
            {user.role === 'DOCTOR' && (
              <div className="col-md-8 col-sm-12">
                <div className="flex__block">
                  <label className="header__title" style={{ color: '#25282B' }}>
                    Dr {user?.userName}
                  </label>
                  {/* <label className="header__title" style={{ color: '#25282B' }}>
                  REG: 12784908
                </label> */}
                </div>
              </div>
            )}

            <div className="col-md-4 col-sm-12">
              {new Date().toLocaleDateString()}
            </div>
          </div>
          <p
            className="prescription__text"
            style={{ color: '#45B757', margin: '100px 0' }}
          >
            {prescriptionData[1]?.rx_number}
          </p>
          <div className="table-full-width table-responsive mt-3">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th style={{ backgroundColor: '#E5E5E5' }}>Drug name</th>
                  <th style={{ backgroundColor: '#E5E5E5' }}>Dose</th>
                  <th style={{ backgroundColor: '#E5E5E5' }}>Strength</th>
                  <th style={{ backgroundColor: '#E5E5E5' }}>Weight</th>
                  <th style={{ backgroundColor: '#E5E5E5' }}>volume</th>
                  <th style={{ backgroundColor: '#E5E5E5' }}>
                    Direction of use
                  </th>
                  <th style={{ backgroundColor: '#E5E5E5' }}>Quantity</th>
                  <th style={{ backgroundColor: '#E5E5E5' }}>No of refills</th>
                </tr>
              </thead>
              <tbody>
                {prescriptionData.map((drug, index) => (
                  <tr key={index}>
                    <td>{drug?.drug_name}</td>
                    <td>{drug?.drug_dose}</td>
                    <td>{drug?.drug_strength}</td>
                    <td>{drug?.drug_weight}</td>
                    <td>{drug?.drug_volume}</td>
                    <td>{drug?.drug_direction_of_use}</td>
                    <td>{drug?.drug_quantity}</td>
                    <td>{drug?.drug_refill}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div className="form-inline mr-5">
            <div className="form-group">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pharmacy email address"
                  value={pharmacyEmail}
                  onChange={(e) => setPharmacyEmail(e.target.value)}
                />

                <button
                  className="btn btn-info not__printed"
                  disabled={!pharmacyEmail}
                  onClick={sendPrescriptionEmail}
                >
                  Email
                </button>
              </div>
            </div>
          </div>
          <button
            className="btn btn-warning not__printed"
            onClick={completeConsultation}
          >
            Complete consultation
          </button>
          <ReactToPrint
            trigger={() => (
              <button
                className="btn btn-success not__printed"
                style={{ background: '#45B757' }}
              >
                Print{' '}
              </button>
            )}
            content={() => componentRef.current}
            documentTitle="Requested Lab Tests"
          />
        </div>
      </div>

      {/***********************************
                    Footer start
                    ************************************/}
      <Footer />
      {/***********************************
                    Footer end
                    ************************************/}
    </div>
  )
}

export default PrescriptionPrintView
