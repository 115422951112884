import React, { useState, useEffect } from 'react'
import Collapse from 'react-bootstrap/Collapse'
import HypertensionMenu from './HypertensionMenu'

import {
  populateFamilyHistory,
  selectFamilyHistory,
} from '../../redux_setup/slices/hypertensionSlice'
import { ReduxActions } from '../../constants/redux_actions'
import { useDispatch, useSelector } from 'react-redux'
import { machinize } from '../../constants/Essentials'
import { selectUser } from '../../redux_setup/slices/userSlice'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { updateStep } from '../../redux_setup/slices/stepSlice'

const FamilyHistory = ({ patientDetails }) => {
  const conditionsCheck = {
    hypertension: false,
    diabetes: false,
    obesity: false,
    metabolic_syndrome: false,
    stroke: false,
    heart_disease: false,
  }
  const user = useSelector(selectUser)
  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  toast.configure()
  const familyHistoryState = useSelector(selectFamilyHistory)
  const dispatch = useDispatch()
  const [toggles, setToggles] = useState(conditionsCheck)

  const casesHandler = (category, property, value) => {
    dispatch(
      populateFamilyHistory({
        category,
        property,
        value,

        action_type: ReduxActions.change_object_property_in_array,
      }),
    )
  }
  const handleSubmit = () => {
    let dataToSend
    if (familyHistoryState?.id) {
      dataToSend = { ...familyHistoryState }
      delete dataToSend.created_on
      delete dataToSend.update_at
      delete dataToSend.patient_id
    } else {
      dataToSend = {
        patient_id: patientDetails.patient_id,
        condition_obj: familyHistoryState.condition_obj,
      }
    }

    fetch(`${process.env.REACT_APP_MAIN_URL_HYPERTENSION}/api/family_history`, {
      signal: signal,
      method: familyHistoryState?.id ? 'PATCH' : 'POST', //if you can find an id in familyHistoryState, then update
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          })
        }
        dispatch(updateStep('underlying_conditions'))
        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        })

        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error has occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        })
      })
  }
  useEffect(() => {
    const togglesClone = { ...toggles }
    familyHistoryState.condition_obj.map((condition) => {
      if (condition.cases[0].diagnosis_date !== '') {
        const machinized_condition = machinize(condition.condition)

        togglesClone[machinized_condition] = true
      }
      setToggles(togglesClone)
    })
  }, [familyHistoryState])

  useEffect(() => {
    // console.log(patientDetails)
    fetch(
      `${process.env.REACT_APP_MAIN_URL_HYPERTENSION}/api/family_history/${patientDetails.patient_id}`,
      {
        signal: signal,
        method: 'GET',
        headers: new Headers({
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        if (res.error) {
          return console.log('Family history fetching did not succeed')
        }
        if (res.data.length <= 0) {
          //reset redux statepiece

          dispatch(
            populateFamilyHistory({
              action_type: ReduxActions.reset_to_default_value,
            }),
          )
        } else {
          dispatch(
            populateFamilyHistory({
              value: res.data[0],
              action_type: ReduxActions.add_initial_data_from_server,
            }),
          )

          //adjust the toggles according to condition_obj
          console.log(res.data[0].condition_obj)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  return (
    <div className="margin__container">
      <h6 className="margin__left vital__subtopic">Family History</h6>
      <HypertensionMenu />

      <div className="card">
        <div className="card-body text-left">
          <p>Has anyone in the patient’s family been diagonised with:</p>

          <div className="row">
            {familyHistoryState.condition_obj.map((cond, index) => {
              return (
                <div className="col-6" key={index}>
                  <div className="card">
                    <div className="card-body family__history__card__collapse">
                      <div className="row">
                        <div className="col-1 ">
                          <input
                            type="checkbox"
                            id={cond.condition}
                            checked={
                              toggles[machinize(cond.condition)] === true
                            }
                            onChange={(e) => {
                              const machinized_condition = machinize(
                                cond.condition,
                              )
                              const togglesClone = { ...toggles }

                              togglesClone[
                                machinized_condition
                              ] = !togglesClone[machinized_condition]
                              setToggles(togglesClone)
                            }}
                            // defaultValue="hypertension"
                            // onChange={(e) => setHypertensionOpen(e.target.checked)}
                            // onClick={toggleHypertension}
                          />
                        </div>
                        <div className="col-11 text-left ">
                          <label
                            htmlFor={cond.condition}
                            className="vital__subtopic cursor__pointer"
                          >
                            {cond.condition}
                          </label>
                          <Collapse
                            in={toggles[machinize(cond.condition)] === true}
                          >
                            <div id="example-collapse-text">
                              <div
                                className="hypeForms"
                                style={{ display: 'flex' }}
                              >
                                <p>Relation:</p>
                                <div
                                  className="text-right"
                                  style={{ width: '100%' }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      margin: '0 0 0 30% ',
                                    }}
                                  >
                                    <select
                                      placeholder="Select Family Member"
                                      className="form-control form-control-sm mgn__right"
                                      onChange={(e) =>
                                        casesHandler(
                                          cond.condition,
                                          'relation',
                                          e.target.value,
                                        )
                                      }
                                      value={cond.cases[0]?.relation}
                                    >
                                      <option value="">
                                        Select Family Member
                                      </option>
                                      <option>Father</option>
                                      <option>Mother</option>
                                      <option>Brother</option>
                                      <option>Sister</option>
                                      <option>Aunt</option>
                                      <option>Uncle</option>
                                      <option>Cousin</option>
                                      <option>Grandfather</option>
                                      <option>Grandmother</option>
                                    </select>
                                    <select
                                      placeholder="Maternal"
                                      class="form-control form-control-sm"
                                      onChange={(e) =>
                                        casesHandler(
                                          cond.condition,
                                          'relation_side',
                                          e.target.value,
                                        )
                                      }
                                      value={cond.cases[0]?.relation_side}
                                    >
                                      <option value="" defaultValue>
                                        Select Relation Side
                                      </option>
                                      <option>Maternal</option>
                                      <option>Paternal</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="hypeForms">
                                <div className="flex__container">
                                  <label className="labels">Age:</label>
                                  <input
                                    className="form-control form-control-sm"
                                    style={{ marginLeft: 12 }}
                                    type="text"
                                    placeholder="Age"
                                    value={cond.cases[0]?.age}
                                    onChange={(e) =>
                                      casesHandler(
                                        cond.condition,
                                        'age',
                                        e.target.value,
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="hypeForms">
                                <div className="flex__container">
                                  <label className="labels">Type:</label>
                                  <select
                                    placeholder="Type"
                                    className="form-control form-control-sm"
                                    style={{ marginLeft: 6 }}
                                    onChange={(e) =>
                                      casesHandler(
                                        cond.condition,
                                        'condition_type',
                                        e.target.value,
                                      )
                                    }
                                    value={cond.cases[0]?.condition_type}
                                  >
                                    <option value="" defaultValue>
                                      Type
                                    </option>
                                    <option>Essential </option>
                                    <option>Secondary </option>
                                    <option>Isolated Systolic</option>
                                    <option>Malignant </option>
                                    <option>Resistant </option>
                                  </select>
                                </div>
                              </div>
                              <div className="hypeForms">
                                <div className="flex__container">
                                  <label className="labels">Stage:</label>
                                  <select
                                    className="form-control form-control-sm"
                                    style={{ marginLeft: 0 }}
                                    onChange={(e) =>
                                      casesHandler(
                                        cond.condition,
                                        'condition_stage',
                                        e.target.value,
                                      )
                                    }
                                    value={cond.cases[0]?.condition_stage}
                                  >
                                    <option value=""> Stage</option>
                                    <option>Stage 1 </option>
                                    <option>Stage 2 </option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex__container">
                                <label className='labels'>Diag<br/>nosis Date:</label>
                                <input
                                  id="date"
                                  type="date"
                                  placeholder="Date"
                                  style={{
                                    width: '100%',
                                    marginLeft: -27,
                                    height: 31,
                                  }}
                                  onChange={(e) =>
                                    casesHandler(
                                      cond.condition,
                                      'diagnosis_date',
                                      e.target.value,
                                    )
                                  }
                                  value={cond.cases[0]?.diagnosis_date}
                                />
                              </div>
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div style={{ textAlign: 'right' }}>
        <button className="btn btn-success hypemit" onClick={handleSubmit}>
          Next
        </button>
      </div>
    </div>
  )
}

export default FamilyHistory
