import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import { Table } from 'react-bootstrap'
import PatientAppointments from '../components/PatientAppointments.jsx'
import { formatDate } from '@fullcalendar/react'
import { forceUpdate } from '../constants/Essentials'
import { useNavigate } from 'react-router-dom'
import PatientFollowUps from './PatientFollowups'
import PatientMedicalDetailsHistoryComponent from './PatientMedicalHistory'
import PatientConsultationHistoryComponent from './PatientConsultationHistory'

const Patient = ({ notPatient, fromId }) => {
  let navigate = useNavigate()
  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  const user = useSelector(selectUser)
  const [appointments, setAppointments] = useState([])
  const [followups, setFollowups] = useState([])
  const fullName =
    user.role !== 'Patient' ? `${notPatient?.name}` : user.userName
  const gender = user.role !== 'Patient' ? `${notPatient?.gender}` : user.gender
  const dob =
    user.role !== 'Patient' ? `${notPatient?.birth_date}` : user.birth_date
  const mail = user.role !== 'Patient' ? `${notPatient?.email}` : user.email
  const phone = user.role !== 'Patient' ? `${notPatient?.phone}` : user.phone
  const patient_id =
    user.role === 'Patient'
      ? user.user_id
      : fromId
      ? fromId
      : `${notPatient?.id}`
  const firstName = fullName.split(' ')
  const fetchAppointmentsUrl = `${process.env.REACT_APP_MAIN_URL}/api/appointment/patient/${patient_id}?size=50`

  //alter state
  const alterStateAfterCancelAppointment = (id) => {
    const appt = appointments.filter((appointment) => {
      return appointment.id === id
    })
    appt[0].status = 'Cancel'

    console.log(appt)

    const cloneAppts = [...appointments]

    //get the index of the above id in the clone array

    const indexToModify = cloneAppts.findIndex((item) => item.id === id)

    // if index above is valid, splice the array
    indexToModify !== -1 && cloneAppts.splice(indexToModify, 1, appt[0])

    // cloneAppts.forEach((item) => {
    //   item.title =
    //     user.role === 'Patient' ? item?.doctorId?.name : item?.patient?.name
    //   const dt = new Date(item.date)
    //   // item.value = item.id;
    //   // item.start = ;
    //   // const slot = "T"+item.time_slot.substring(0, str.length - 2)
    //   item.start = String(
    //     dt.toISOString().replace(/T.*$/, '') + 'T' + item.time_slot,
    //   )
    // })

    setAppointments(cloneAppts)
  }
  useEffect(() => {
    console.log(notPatient, fetchAppointmentsUrl, 'hhhh')
    fetch(fetchAppointmentsUrl, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res, 'patients_appts')
        res.data.items.length > 0 &&
          res.data.items.forEach((item) => {
            item.title =
              user.role === 'Patient'
                ? item?.doctorId?.name
                : item?.patient?.name
            const dt = new Date(item.date)
            // item.value = item.id;
            // item.start = ;
            // const slot = "T"+item.time_slot.substring(0, str.length - 2)
            item.start = String(
              dt.toISOString().replace(/T.*$/, '') + 'T' + item.time_slot,
            )
            // item.start = "2022-02-01T16:38";

            //->will delete the rest later
          })

        res.data?.items?.length > 0 && setAppointments(res.data.items)
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])
  // fetch followup appts
  useEffect(() => {
    const followupurl = `${process.env.REACT_APP_MAIN_URL}/api/follow-up/?patient_id=${patient_id}`
    if (user.role === 'Patient') {
      fetch(followupurl, {
        method: 'GET',
        headers: new Headers({
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          // res.data.items.length > 0 &&
          //   res.data.items.forEach((item) => {
          //     item.title =
          //       user.role === 'Patient' ? item?.user?.name : item?.patient?.name
          //     const dt = new Date(item.date)
          //     // item.value = item.id;
          //     // item.start = ;
          //     // const slot = "T"+item.time_slot.substring(0, str.length - 2)
          //     item.start = String(
          //       dt.toISOString().replace(/T.*$/, '') + 'T' + item.time_slot,
          //     )
          //     // item.start = "2022-02-01T16:38";

          //     //->will delete the rest later
          //   })

          setFollowups(res.data.items)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])
  return (
    <div className="content-body">
      {/* row */}
      {/* <div className="container-fluid">
        <div className="form-head page-titles d-flex mb-md-4">
          <div className="mr-auto">
            <h2 className="text-black font-w600">Doctor</h2>
          </div>
        </div>
      </div> */}
      <div className="container-fluid pt-0">
        <div className="row">
          <div className="col-lg-8 col-xxl-12">
            {user.role === 'DOCTOR' || user.role === 'NURSE' ? (
              <div className="row text-center">
                <div className="col-lg-12">
                  <PatientConsultationHistoryComponent
                    dataToProcess={{ patient_id }}
                  />
                </div>
                <div className="col-lg-12">
                  <PatientMedicalDetailsHistoryComponent
                    dataToProcess={{
                      patient_id,
                    }}
                  />
                </div>
                <div className="col-lg-6"> </div>
              </div>
            ) : (
              <div className="row text-center">
                <div className="col-lg-6 pr-2">
                  {/* <img src="images/bg.jpg" alt="" className="bg-img" /> */}
                  {/* <div className="noPadBottom"> */}
                  <Link to='/calendar'>
                    <div className="row" title="Pending appointments" style={{cursor: 'pointer'}}>
                      <div
                        className="card gradientwelcom fullWidth"
                        style={{ padding: '48px 20px', margin: '0px 16px' }}
                      >
                        <h2>
                          {user.role === 'Patient' && `Welcome, `}
                          {fullName}
                        </h2>
                        {/*{firstName}</h2> */}

                        <p>{appointments.length} pending appointments</p>
                        {/* <h6>0930 - 10th March 2022</h6> */}
                      </div>
                    </div>
                  </Link>

                  <div className="mt-2 row mb-0">
                    <div className="col-lg-12 col-xxl-12">
                      <Link
                        to="/book-appointment"
                        state={{
                          patient_name: notPatient
                            ? notPatient?.name
                            : user.userName,
                          patient_id: notPatient
                            ? notPatient?.id
                            : user.user_id,
                        }}
                      >
                        <div
                          title="Click here to Book Appointment"
                          style={{
                            marginBottom: '2.2rem',
                            backgroundColor: '#2FA5DB',
                          }}
                          className="card  p-4"
                        >
                          <div className="btn-block btn bookBig">
                            <h5>Book appointment</h5>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row patientRow">
                    <div
                      style={{ paddingLeft: '0px', paddingRight: '4px' }}
                      className="col-4"
                    >
                      <div
                        title="Past Appointments"
                        style={{ backgroundColor: 'rgba(47, 165, 219, 0.3)' }}
                        className="card noPadBottom"
                      >
                        <div className="someData" style={{cursor: 'pointer'}}>
                          <Link to='/calendar'>
                            <h4 className="low" style={{ color: '#19226D' }}>
                              Past
                              <br /> appointments
                            </h4>
                            <p>0</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div
                      title="Upcoming Appointments"
                      style={{ paddingLeft: '0px', paddingRight: '4px' }}
                      className="col-4"
                    >
                      <Link to='/calendar'>
                        <div
                          style={{ backgroundColor: 'rgba(69, 183, 87, 0.3)',cursor: 'pointer' }}
                          className="card noPadBottom"
                        >
                          <div className="someData" style={{cursor: 'pointer'}}>
                            <h4 className="">
                              Upcoming
                              <br /> appointments
                            </h4>
                            <p>{appointments.length}</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div
                      title="Last reason for visit"
                      style={{ paddingLeft: '0px', paddingRight: '4px' }}
                      className="col-4"
                    >
                      <div
                        style={{ backgroundColor: 'rgba(102, 35, 130, 0.3)' }}
                        className="card noPadBottom"
                      >
                        <div className="someData" style={{cursor: 'pointer'}}>
                          <h4 className="">
                            Last
                            <br />
                            reason for visit
                          </h4>
                          <p>None</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ paddingTop: '8px' }}
                    className="row patientRow"
                    title="Assigned Doctors"
                  >
                    <div className="card noPadBottom fullWidth">
                      <div className="assignedDoctors">
                        <h4 className="detailHead">Assigned doctors</h4>
                        {/* <ul>
                        <li>
                          <p className="noPadBottom">
                            Dr. Mike Tukana (Primary){" "}
                          </p>
                        </li>
                        <li>
                          <p className="noPadBottom">Dr. Cecilia Mwanaka </p>
                        </li>
                      </ul> */}
                        <p className="noPadBottom">None</p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ paddingTop: '8px', marginBottom: '40px' }}
                    title="Current Medication"
                    className="row patientRow"
                  >
                    <div
                      className="card fullWidth"
                      style={{ marginBottom: '0.8rem' }}
                    >
                      <div className="currentMedication">
                        <h4 className="detailHead">Current medication</h4>
                        <ul>
                          <li>
                            <p
                              className="noPadBottom"
                              style={{ marginBottom: '0px' }}
                            >
                              None
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div style={{ marginTop: '-18px' }} className="col-lg-12 pr-0">
                <div className="card">
                  <div className="card-header">
                    <div className="pr-4 container">
                      <h5 className="apptActivity">Appointment activity</h5>
                    </div>
                  </div>
                  <div
                    className="card-body"
                    style={{ maxHeight: '400px', overflowY: 'scroll' }}
                  >
                    <Table variant="hover">
                      <thead>
                        <tr style={{ textAlign: 'left' }}>
                          <th>Doctor</th>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Reason</th>
                          <th>Mode</th>
                          <th>Paid</th>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: '14px' }}>
                        {appointments.map((appointment, key) => (
                          <PatientAppointments
                            key={key}
                            time={appointment?.time_slot}
                            date={formatDate(appointment?.date, {
                              locale: 'es',
                            })}
                            docname={appointment?.doctorId?.name}
                            visitReason={appointment?.reason_for_visit}
                            id={appointment?.id}
                            isPayed={appointment?.is_payed}
                            patient_id={appointment?.patient_id}
                            doctor_id={appointment?.doctor_id}
                            health_center={appointment?.health_center}
                            appt={appointment}
                            patient_name={fullName}
                            alterAfterCancel={alterStateAfterCancelAppointment}
                          />
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
            {user.role === 'Patient' && (
              <div className="row ">
                <div style={{ marginTop: '-18px' }} className="col-lg-12 pr-0">
                  <div className="card">
                    <div className="pr-4 container">
                      <h5 className="apptActivity">Requested follow ups</h5>
                    </div>
                    <Table variant="hover">
                      <thead>
                        <tr style={{ textAlign: 'left' }}>
                          <th>Doctor</th>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Reason</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: '14px' }}>
                        {followups.map((appointment, key) => (
                          <PatientFollowUps key={key} appt={appointment} />
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="col-lg-4 pl-2">
            <div className="text-center">
              <div className="card noPadBottom">
                <div className="profileCap">
                  <img
                    src="./images/avatar/noProfile.jpg"
                    style={{ borderRadius: '100px' }}
                    width={138}
                    alt=""
                  />

                  <h5 className="profileName">{fullName}</h5>
                </div>
              </div>

              <div className="col-lg-12">
                <div
                  style={{ paddingTop: '8px', marginBottom: '0.8rem' }}
                  className="row"
                >
                  <div
                    style={{ paddingLeft: '0px', paddingRight: '4px' }}
                    className="col-lg-6"
                  >
                    <div className="card">
                      <div className="someData">
                        <h4 className="detailHead">Gender</h4>
                        <p style={{ color: '#333' }}>{gender}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ paddingRight: '0px', paddingLeft: '4px' }}
                    className=" col-lg-6"
                  >
                    <div className="card">
                      <div className="someData">
                        <h4 className="detailHead">Date of birth</h4>
                        <p style={{ color: '#333' }}>
                          {formatDate(dob, { locale: 'es' })}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card naModhe">
              <div className="card-body">
                <div className="row sideDetails">
                  <p className="qwerty">
                    <i className="fa-solid fa-phone fa-lg"></i>
                    {phone}
                  </p>

                  <p className="qwerty">
                    <i className="fa-solid fa-envelope fa-lg"></i>
                    {mail}
                  </p>
                  <p className="qwerty">
                    <i className="fa-solid fa-house-user fa-lg"></i>
                    {/* 7 Kaunda Street,
                    Nairobi, Kenya */}
                    Address not set
                  </p>
                  {/* <p className="qwerty">
                    <i className="fa-solid fa-gift fa-lg"></i>13 Nov 1998
                  </p> */}
                  {/* <button
                    className="btn btn-dark mr-5"
                    title="click to return to previous page"
                    onClick={() => navigate(-1)}
                  >
                    {' '}
                    Back
                  </button> */}
                  <div className='flex__container' style={{margin: 15}}>
                    <Link to='/payment-history'>
                      <button className='btn' style={{backgroundColor: '#97CE5157',width:144,height:35}}>Payment History</button>
                    </Link>
                    <Link to='/lab-result'>
                      <button className='btn' style={{backgroundColor: '#F683AC',width:144,height:35}}>Labs</button>
                    </Link>
                  </div>
                  {user.role === 'DOCTOR' || user.role === 'NURSE' ? (
                    <>
                      <Link
                        to="/book-appointment"
                        title="Click here to Book appointment"
                        className="btn btn-success"
                        state={{
                          patient_name: notPatient
                            ? notPatient?.name
                            : user.userName,
                          patient_id: notPatient
                            ? notPatient?.id
                            : user.user_id,
                        }}
                      >
                        Book appointment
                      </Link>
                      <br />
                      <Link
                        to="/hypertension"
                        title="Click here for hypertension diagnosis"
                        className="btn btn-info mt-2"
                        state={{
                          name: fullName,
                          patient_id,
                          dob,
                          gender,
                        }}
                      >
                        Hypertension diagnosis
                      </Link>
                    </>
                  ) : (
                    <Link
                      title="Click here to Edit Profile"
                      className="btn btn-success"
                      to="/patient-complete-profile"
                      style={{backgroundColor: '#2FA5DB99',width: 298,height: 35, marginLeft: 15, border:' 1px solid #2FA5DB99'}}
                      state={{
                        patient_id:
                          user.role === 'Patient'
                            ? user.user_id
                            : notPatient?.id,
                        patient_gender:
                          user.role === 'Patient'
                            ? user.gender
                            : notPatient?.gender,
                        patient_phone:
                          user.role === 'Patient'
                            ? user.phone
                            : notPatient?.phone,
                        patient_email:
                          user.role === 'Patient'
                            ? user.email
                            : notPatient?.email,
                        patient_name:
                          user.role === 'Patient'
                            ? user.userName
                            : notPatient?.name,
                        patient_dob:
                          user.role === 'Patient'
                            ? user.birth_date
                            : notPatient?.birth_date,
                      }}
                    >
                      <i className="fa fa-pencil"></i> Edit
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <a href="/ManagePatients">
          <button className="btn btn-light btl-lg shadow"> 
          Manage Patients
          </button> </a> */}
      {/* <MainApp appointments={appointments} today={appointmentsToday} /> */}
      {/* <AppointmentActivity /> */}.
    </div>
  )
}

export default Patient
