import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LeftBar from "../components/LeftBar";
import { useLocation } from "react-router-dom";
import PatientTriageComponent from "../components/PatientTriage";
import DoctorsAssessmentHistoryComponent from "../components/DoctorsAssessmentHistory";
// import "../foreigncss/Profile.css";

const DoctorAssessmentHistory = () => {
  const location = useLocation();
  return (
    <div id="main-wrapper">
      {/***********************************
        Nav header start
    ************************************/}
      {/* <NavHeader/> */}
      {/***********************************
        Nav header end
    ************************************/}

      {/***********************************
        Header start
    ************************************/}
      <Header />
      {/***********************************
        Header end ti-comment-alt
    ************************************/}
      {/***********************************
        Sidebar start
    ************************************/}
      <LeftBar />
      {/***********************************
        Sidebar end
    ************************************/}
      {/***********************************
        Content body start
    ************************************/}

      <DoctorsAssessmentHistoryComponent />
      {/***********************************
        Content body end
    ************************************/}

      {/***********************************
        Footer start
    ************************************/}
      <Footer />
      {/***********************************
        Footer end
    ************************************/}
    </div>
  );
};

export default DoctorAssessmentHistory;
