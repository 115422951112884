import { useState } from "react";
import Switch from "react-switch";
import { Form, FormControl } from "react-bootstrap";
const switchColorCompute = (level) => {
  let color = "white";
  let handle = "white";
  switch (level) {
    case "High":
      color = "#ff80ad";
      handle = "#ff005c";
      break;

    case "Medium":
      color = "#fff9e5";
      handle = "#ffc000";
      break;

    case "Low":
      color = "#e6f7fb";
      handle = "#07b0d7";
      break;

    default:
      color = "white";
      handle = "#ff005c";
      break;
  }
  return { color, handle };
};
const AcitvityAndExercise = () => {
  const [switched, setSwitched] = useState({id: null, checked: false})
  return (
    <div className='card pl-2 pr-2 pt-3'>
      <p
        className='meal-profile-text mt-3'
        style={{ color: "#000", fontSize: 18 }}
      >
        Activity and Exercise
      </p>
      <label>How often do you exercise?</label>
      <label
        style={{
          fontFamily: "Lato",
          fontWeight: 400,
          fontSize: 18,
          color: "#616161",
        }}
      >
        Cardio Workout
      </label>
      <div className='d-flex flex-wrap' style={{ gap: 20 }}>
        <div>
          <label>Exercise type</label>
          <FormControl
            as='textarea'
            rows='3'
            placeholder='Running, Swimming'
            style={{ backgroundColor: "#eee", width: 456 }}
          />
        </div>
        <div>
          <label>Frequency</label>
          <Form.Select>
            <option>2 Days per week</option>
            <option>4 Days per week</option>
            <option>5 Days per week</option>
          </Form.Select>
        </div>
        <div>
          <label>Duration</label>
          <Form.Select>
            <option>20min</option>
            <option>40min</option>
            <option>10min</option>
          </Form.Select>
        </div>
      </div>
      <label
        style={{
          fontFamily: "Lato",
          fontWeight: 400,
          fontSize: 18,
          color: "#616161",
        }}
      >
        Strength Training
      </label>
      <div className='d-flex flex-wrap' style={{ gap: 20 }}>
        <div>
          <label>Exercise type</label>
          <FormControl
            as='textarea'
            rows='3'
            placeholder='Lifting, Weight, Dance'
            style={{ backgroundColor: "#eee", width: 456 }}
          />
        </div>
        <div>
          <label>Frequency</label>
          <Form.Select>
            <option>2 Days per week</option>
            <option>4 Days per week</option>
            <option>5 Days per week</option>
          </Form.Select>
        </div>
        <div>
          <label>Duration</label>
          <Form.Select>
            <option>20min</option>
            <option>40min</option>
            <option>10min</option>
          </Form.Select>
        </div>
      </div>
      <div className='d-flex flex-wrap mt-3 mb-3' style={{ gap: 20 }}>
        {["Low", "Medium", "High"].map((level, index) => (
          <div className='d-flex flex-wrap'>
            <label style={{ marginTop: 5, fontWeight: 700 }}>{level}</label>
            <div className={`switch${index + 1}`} key={index}>
              <Switch
                key={index}
                onChange={(e) => setSwitched({ id: index, checked: e })}
                value={switched.checked}
                checked={switched.id === index ? switched.checked : false}
                uncheckedIcon={false}
                checkedIcon={false}
                onHandleColor={switchColorCompute(level).handle}
                offHandleColor={switchColorCompute(level).handle}
                onColor={switchColorCompute(level).color}
                offColor={switchColorCompute(level).color}
                width={80}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AcitvityAndExercise;
