import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import { SignInSchema } from '../constants/ValidationSchema'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'

const Login = () => {
  toast.configure()

  const [twoStep, setTwoStep] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')

  let navigate = useNavigate()
  const initialValues = {
    email: '',
    password: '',
  }

  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal

  const url = `${process.env.REACT_APP_MAIN_URL}/api/auth/login`

  const handleLogin = (values, resetForm) => {
    const dataToSend = {
      username: values.email,
      password: values.password,
    }

    // send request
    fetch(url, {
      signal: signal,
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // "Access-Control-Request-Headers": "*",
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }

        if (res?.data?.two_step) {
          return setTwoStep(true)
        }

        resetForm()
        localStorage.setItem('temp_token', res.token)
        if (res.data.avatar) {
          navigate('/careplan-dashboard')
        } else {
          navigate('/dashboard')
        }
        window.location.reload(false)
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('A server error occured', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
  }

  const submitCode = () => {
    //     https://services.healthcenta.com/api/auth/step-verify (post)
    // body {
    // verification_code:
    // }
    const dataToSend = {
      verification_code: verificationCode,
    }

    // send request
    fetch(`${process.env.REACT_APP_MAIN_URL}/api/auth/step-verify`, {
      signal: signal,
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // "Access-Control-Request-Headers": "*",
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        if (res.error) {
          return toast.warning('Sorry, you entered the wrong code', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }

        // localStorage.setItem('temp_token', res.token)
        // // console.log(res);
        // res.data.role === 'RECEPTIONIST'
        //   ? navigate('/admin-dashboard')
        //   : navigate('/dashboard')
        // // window.location.reload(false)
        // return toast.success(res.message, {
        //   position: toast.POSITION.TOP_CENTER,
        // })
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('A server error occured', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
  }

  return (
    <div className="bgLockScreen">
      <div className="form-body">
        <div className="row">
          <div className="img-holder">
            <div className="bg" />

            <h3
              className="bigScreenOnly text-left mr-5"
              style={{ marginTop: '55vh' }}
            >
              Welcome to <span>better</span> Healthcare management
            </h3>
            <div className="info-holder"></div>
          </div>
          <div className="form-holder">
            <div className="form-content">
              <div className="form-items">
                <div className="bgCover">
                  {twoStep ? (
                    <>
                      <div className="col-md-12 my-2">
                        <p>
                          To verify it's you, please enter the code we sent you
                          on email
                        </p>
                        <div className="form-group">
                          <label
                            className="mb-1 text-white"
                            htmlFor="verification_code"
                          >
                            <strong>Verification code:</strong>
                          </label>
                          <input
                            type="text"
                            id="verification_code"
                            title="Enter your code here"
                            value={verificationCode}
                            onChange={(e) =>
                              setVerificationCode(e.target.value)
                            }
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 text-center">
                        <button
                          disabled={!verificationCode}
                          type="submit"
                          className="btn btn-light text-primary btn-blocke"
                          title="Click here to submit your code"
                          onClick={submitCode}
                        >
                          Verify
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mt-4 page-links text-center">
                        <Link
                          to="/login"
                          className="active"
                          title="Click here to Login"
                        >
                          Login
                        </Link>
                        <Link to="/register" title="Create New Account">
                          Register
                        </Link>
                      </div>
                      <Formik
                        initialValues={initialValues}
                        validationSchema={SignInSchema}
                        onSubmit={(values, { resetForm }) => {
                          handleLogin(values, resetForm)
                        }}
                      >
                        {(formik) => {
                          const { errors, touched, isValid, dirty } = formik
                          return (
                            <Form className="row">
                              <div className="col-md-12 my-2">
                                <Field name="email">
                                  {(props) => {
                                    return (
                                      <div className="form-group">
                                        <label
                                          className="mb-1 text-white"
                                          htmlFor="email"
                                        >
                                          <strong>
                                            Email or phone{' '}
                                            <ErrorMessage
                                              name="email"
                                              component="span"
                                              className="help-block warning errorMessage"
                                            />
                                          </strong>
                                        </label>
                                        <input
                                          type="text"
                                          id="email"
                                          title="Type your Email or Phone number"
                                          value={props.field.value}
                                          onChange={props.field.onChange}
                                          onBlur={props.field.onBlur}
                                          className={
                                            errors.email && touched.email
                                              ? 'form-control input-error warning'
                                              : 'form-control'
                                          }
                                        />
                                      </div>
                                    )
                                  }}
                                </Field>
                              </div>

                              <div className="col-md-12 my-2">
                                <Field name="password">
                                  {(props) => {
                                    return (
                                      <div className="form-group">
                                        <label
                                          className="mb-1 text-white"
                                          htmlFor="password"
                                        >
                                          <strong>
                                            Password
                                            <ErrorMessage
                                              name="password"
                                              component="span"
                                              className="help-block text-danger errorMessage"
                                            />
                                          </strong>
                                        </label>
                                        <input
                                          type="password"
                                          id="password"
                                          autoComplete="password"
                                          title="Type your Password"
                                          value={props.field.value}
                                          onChange={props.field.onChange}
                                          onBlur={props.field.onBlur}
                                          className={
                                            errors.password && touched.password
                                              ? 'form-control input-error text-danger'
                                              : 'form-control'
                                          }
                                        />
                                      </div>
                                    )
                                  }}
                                </Field>
                              </div>

                              <div className="col-md-3 text-center">
                                <button
                                  disabled={!(dirty && isValid)}
                                  type="submit"
                                  className="btn btn-light text-primary btn-blocke"
                                  title="Click here to Login"
                                >
                                  Login
                                </button>
                              </div>
                              <div className="col-md-12"></div>
                              <div className=" col-md-4 text-center my-4">
                                <p className="text-white">
                                  <Link
                                    to="staff-login"
                                    className="text-white"
                                    title="Click here to Login as Staff"
                                  >
                                    Staff login
                                  </Link>
                                </p>
                              </div>

                              <div className=" col-md-5 text-center mt-4">
                                <p className="text-white">
                                  <Link
                                    to="/forgot-password"
                                    className="text-white"
                                    title="Click here to reset password"
                                  >
                                    Forgot password?
                                  </Link>
                                </p>
                              </div>
                            </Form>
                          )
                        }}
                      </Formik>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
