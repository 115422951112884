import React, { useState } from 'react'
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import './main.css'
import { Col, Modal, Row, Button, Table, Badge, Card } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUser } from '../../redux_setup/slices/userSlice'
import { forceUpdate, trimDate, trimString } from '../../constants/Essentials'
import HoverComponent from '../HoverComponent'
import Swal from 'sweetalert2'

const MainApp = ({ appointments, today }) => {
  let navigate = useNavigate()
  let eventGuid = 0
  const user = useSelector(selectUser)

  const [weekendsVisible, setWeekendsVisible] = useState(true)
  const [currentEvents, setCurrentEvents] = useState([])
  const [viewAppointment, setViewAppointment] = useState(false)
  const [appointmentToView, setAppointmentToView] = useState({})
  const [showDetails, setShowDetails] = useState(false)

  //logistics
  const [delaySymptoms, setDelaySymptoms] = useState(true)
  const [forbidden, setForbidden] = useState(false)
  const [errorBoxSymptoms, setErrorBoxSymptoms] = useState(false)
  const [errorBoxNotes, setErrorBoxNotes] = useState(false)
  const [infoBoxNotes, setInfoBoxNotes] = useState(false)
  const [infoBoxSymptoms, setInfoBoxSymptoms] = useState(false)
  const [proceed, setProceed] = useState(false)

  //symptoms states
  const [currentSymptoms, setCurrentSymptoms] = useState('')
  const [whenSymptomsStarted, setWhenSymptomsStarted] = useState('')
  const [selfTreatment, setSelfTreatment] = useState(false)
  const [whichSelfTreatment, setWhichSelfTreatment] = useState('none')
  const [temperature, setTemperature] = useState('')
  const [pulse, setPulse] = useState('')
  const [bloodPressure, setBloodPressure] = useState('')
  const [respirationRate, setRespirationRate] = useState('')

  // notes states
  const [assessmentCaseInformation, setAssessmentCaseInformation] = useState('')
  const [assessmentTests, setAssessmentTests] = useState('')
  const [assessmentDiagnosis, setAssessmentDiagnosis] = useState('')
  const [assessmentPrescription, setAssessmentPrescription] = useState('')
  const [additionalNotes, setAdditionalNotes] = useState('')
  const [delayNotes, setDelayNotes] = useState(false)

  const lookForExtras = (appt) => {
    setTimeout(function () {
      //logic here
      //fetch symptoms
      const fetchDetailsUrl = `${process.env.REACT_APP_MAIN_URL}/api/vital/?appointment_id=${appt?.id}`
      if (user.role !== 'Patient' && user.role !== 'DOCTOR') {
        setForbidden(true)
        return setDelaySymptoms(false)
      }
      fetch(fetchDetailsUrl, {
        method: 'GET',
        headers: new Headers({
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res)
          if (res.error) {
            setErrorBoxSymptoms(true)
            return setDelaySymptoms(false)
          }
          if (!res?.data) {
            setInfoBoxSymptoms(true)
            return setDelaySymptoms(false)
          }
          setCurrentSymptoms(res.data?.current_symptoms)
          setWhenSymptomsStarted(trimDate(res.data?.when_started, 'date_only'))

          setSelfTreatment(res.data?.self_treatment)
          setWhichSelfTreatment(res.data?.what_self_treatment)
          setBloodPressure(res.data?.pressure)
          setPulse(res.data?.pulse_rate)
          setRespirationRate(res.data?.respiration_rate)
          setTemperature(res.data?.temperature)
          setDelayNotes(true)
          setDelaySymptoms(false)
          setProceed(true)

          //chain another fetch for notes here after 2 secs
          setTimeout(function () {
            const consultationUrl = `${process.env.REACT_APP_MAIN_URL}/api/general_consultation/appointment?appointment_id=${appt?.id}`
            fetch(consultationUrl, {
              method: 'GET',
              headers: new Headers({
                Authorization: 'Bearer ' + user.token,
                Accept: 'application/json',
                'Content-Type': 'application/json',
              }),
            })
              .then((res) => res.json())
              .then((res) => {
                console.log(res)
                if (res.error) {
                  setErrorBoxNotes(true)
                  return setDelayNotes(false)
                }
                const response = res.data
                // setCurrentRes(response[0])
                if (response) {
                  setAssessmentCaseInformation(response?.case_info)
                  setAssessmentTests(response?.tests_to_be_done)
                  setAssessmentDiagnosis(response?.diagnosis)
                  setAssessmentPrescription(response?.prescription)
                  setAdditionalNotes(response?.extra_notes)
                  setDelayNotes(false)
                } else {
                  setInfoBoxNotes(true)
                  return setDelayNotes(false)
                }
              })
              .catch((err) => {
                console.log(err)
                setErrorBoxNotes(true)
                return setDelayNotes(false)
              })
          }, 2000)

          // res.data?.items?.length > 0 && setAppointments(res.data.items)
        })
        .catch((err) => {
          console.log(err)
          setErrorBoxNotes(true)
          return setDelayNotes(false)
        })
    }, 2000)
  }

  // const [initialEvents, setInitialEvents] = useState([
  //   {
  //     id: createEventId(),
  //     title: "All-day event",
  //     start: todayStr,
  //   },
  //   {
  //     id: createEventId(),
  //     title: "Timed evente",
  //     start: "2022-01-25T16:38",
  //   },
  // ]);

  const [initialEvents, setInitialEvents] = useState(appointments)
  function createEventId() {
    return String(eventGuid++)
  }

  const renderSidebar = () => {
    return (
      <div className="demo-app-sidebar">
        <div className="demo-app-sidebar-section">
          <h2>Instructions</h2>
          <ul>
            <li>Select dates and you will be prompted to create a new event</li>
            <li>Drag, drop, and resize events</li>
            <li>Click an event to delete it</li>
          </ul>
        </div>
        <div className="demo-app-sidebar-section">
          <label>
            <input
              type="checkbox"
              checked={weekendsVisible}
              onChange={handleWeekendsToggle}
            ></input>
            toggle weekends
          </label>
        </div>
        <div className="demo-app-sidebar-section">
          <h2>All Events ({currentEvents.length})</h2>
          <ul>{currentEvents.map(renderSidebarEvent)}</ul>
        </div>
      </div>
    )
  }

  const handleWeekendsToggle = () => {
    setWeekendsVisible(!weekendsVisible)
  }

  const handleDateSelect = (selectInfo) => {
    // console.log(selectInfo);
    // let title = prompt("Please enter a new title for your event");
    // let calendarApi = selectInfo.view.calendar;
    // calendarApi.unselect(); // clear date selection
    // if (title) {
    //   calendarApi.addEvent({
    //     id: createEventId(),
    //     title,
    //     start: selectInfo.startStr,
    //     end: selectInfo.endStr,
    //     allDay: selectInfo.allDay,
    //   });
    // }
  }

  const handleEventClick = (clickInfo) => {
    const obj = appointments.filter((event) => event.id === clickInfo.event.id)
    setAppointmentToView(obj[0])
    setShowDetails(!showDetails)
    lookForExtras(obj[0])
    // setViewAppointment(!viewAppointment)
  }
  function renderEventContent(eventInfo) {
    // console.log(eventInfo.event.id)
    const thisAppt = appointments.filter(
      (event) => event.id === eventInfo.event.id,
    )[0]
    return (
      // <div className={`calendar-event`}>
      <div
        className={`calendar-event ${thisAppt.type}-appt ${
          thisAppt.status === 'Cancel' && 'cancelled-appt bg-warning'
        }`}
      >
        <span className="mr-1">{eventInfo.timeText}</span>
        <span>{eventInfo.event.title}</span>{' '}
        {thisAppt?.status === 'Done' && (
          <i className="fa fa-check-circle text-success"></i>
        )}
      </div>
    )
  }

  const handleEvents = (events) => {
    setCurrentEvents(events)
  }

  const assessPatient = (event) => {
    if (event?.status === 'Done') {
      Swal.fire({
        title: 'Consultation was completed for this patient. ',
        text: 'You are still able to review the notes. Continue?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, I want to review!',
      }).then((result) => {
        if (result.isConfirmed) {
          return navigate('/doctor-assessment', {
            state: {
              patient_id: event?.patient_id,
              appt_id: event?.id,
              patient_name: event?.patient?.name,
              patient_phone: event?.patient?.phone,
              patient_gender: event?.patient?.gender,
              patient_email: event?.patient?.email,
              patient_birth_date: event?.patient?.birth_date,
            },
          })
        }
      })
    } else if (event?.status === 'Cancel') {
      Swal.fire({
        title: 'Consultation cannot be perfomed ',
        text: 'This appointment has been canceled',
        icon: 'info',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok, got it!',
      }).then((result) => {
        if (result.isConfirmed) {
          return false
        }
      })
    } else {
      // return console.log('event', event)
      navigate('/doctor-assessment', {
        state: {
          patient_id: event?.patient_id,
          appt_id: event?.id,
          patient_name: event?.patient?.name,
          patient_phone: event?.patient?.phone,
          patient_gender: event?.patient?.gender,
          patient_email: event?.patient?.email,
          patient_birth_date: event?.patient?.birth_date,
        },
      })
    }
  }
  const bookFollowUp = () => {
    navigate('/book-follow-up', {
      state: {
        app_id: appointmentToView?.id,
        patient_id: appointmentToView?.patient_id,
        doctor_id: appointmentToView?.doctor_id,
        health_center: appointmentToView?.health_center,
      },
    })
  }
  const cleanup = () => {
    setShowDetails(false)
    setDelaySymptoms(true)
    setDelayNotes(false)
    setForbidden(false)
    setProceed(false)
    setInfoBoxSymptoms(false)
    setInfoBoxNotes(false)
    setErrorBoxNotes(false)
    setErrorBoxSymptoms(false)
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          {showDetails ? (
            <div
              className="col-lg-7 divCalendar"
              style={{ maxHeight: '500px', overflowY: 'scroll' }}
            >
              <Card className="low-profile">
                <Card.Header>
                  <h4 className="card-title">Appointment Details</h4>
                </Card.Header>
                <Card.Body className="text-left ">
                  <Row>
                    <Col md={6}>
                      <strong>Doctor: </strong>
                      {appointmentToView?.doctorId?.name}
                    </Col>
                    <Col md={6}>
                      <strong>Status: </strong>
                      {appointmentToView?.status}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>
                      <strong>Patient: </strong>
                      {appointmentToView?.patient?.name || 'self'}
                    </Col>
                    <Col md={6}>
                      <strong>Center: </strong>
                      {appointmentToView?.Health_center?.name}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>
                      <strong>Date: </strong>
                      {formatDate(appointmentToView?.date, { locale: 'es' })}
                    </Col>
                    <Col md={6}>
                      <strong>Time: </strong>
                      {appointmentToView?.time_slot}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>
                      <strong>SMS Reminder: </strong>
                      {appointmentToView?.send_reminder === true ? 'Yes' : 'No'}
                    </Col>
                    <Col md={6}>
                      <strong>Email Reminder: </strong>
                      {appointmentToView?.email === true ? 'Yes' : 'No'}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={12}>
                      <strong>Reason For Visit: </strong>
                      {appointmentToView?.reason_for_visit}
                    </Col>
                    <br />
                    {delaySymptoms && (
                      <div className="sk-three-bounce">
                        <div className="sk-child sk-bounce1" />
                        <div className="sk-child sk-bounce2" />
                        <div className="sk-child sk-bounce3" />
                      </div>
                    )}
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Button
                      variant="dark"
                      className="mb-1  mr-5"
                      onClick={cleanup}
                    >
                      Back to Calendar
                    </Button>
                    {appointmentToView?.status === 'Done' && (
                      <Button
                        variant="warning"
                        className="mb-1 ml-3"
                        onClick={bookFollowUp}
                      >
                        Book Follow Up
                      </Button>
                    )}
                  </Row>
                  <Row>
                    {forbidden && (
                      <div className="alert alert-warning text-center">
                        <p>
                          Further details are only available to patients and
                          doctors
                        </p>
                      </div>
                    )}
                    {errorBoxSymptoms && (
                      <div className="alert alert-warning text-center">
                        <p>An error occured while fetching more data</p>
                      </div>
                    )}
                    {infoBoxSymptoms && (
                      <div
                        className="alert alert-info text-center"
                        align="center"
                      >
                        <p>No symptoms information was found</p>
                      </div>
                    )}
                  </Row>
                </Card.Footer>
              </Card>
              {!errorBoxSymptoms &&
                !delaySymptoms &&
                !infoBoxSymptoms &&
                !forbidden && (
                  <Card className="low-profile">
                    <Card.Header>
                      <h4 className="card-title">Symptoms</h4>
                    </Card.Header>
                    <Card.Body className="text-left card-body">
                      <Row>
                        <br />
                        <Col md={6} className="mt-2">
                          <strong>Current Symptoms: </strong>
                          {currentSymptoms}
                        </Col>

                        <Col md={6} className="mt-2">
                          <strong>When Did They Start: </strong>
                          {whenSymptomsStarted}
                        </Col>

                        <Col md={6} className="mt-2">
                          <strong>Tried Self Treatment: </strong>
                          {selfTreatment ? 'Yes' : 'No'}
                        </Col>

                        {selfTreatment && (
                          <Col md={6} className="mt-2">
                            <strong>Which Self Treatment: </strong>
                            {whichSelfTreatment}
                          </Col>
                        )}
                        <Col md={6} className="mt-2">
                          <strong>Temperature: </strong>
                          {temperature}
                        </Col>
                        <Col md={6} className="mt-2">
                          <strong>Pulse: </strong>
                          {pulse}
                        </Col>
                        <Col md={6} className="mt-2">
                          <strong>Blood Pressure: </strong>
                          {bloodPressure}
                        </Col>
                        <Col md={6} className="mt-2">
                          <strong>Respiratory Rate: </strong>
                          {respirationRate}
                        </Col>
                      </Row>
                      <Row>
                        {delayNotes && (
                          <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1" />
                            <div className="sk-child sk-bounce2" />
                            <div className="sk-child sk-bounce3" />
                          </div>
                        )}

                        {errorBoxNotes && (
                          <div className="alert alert-warning">
                            <p>An error occured while fetching more data</p>
                          </div>
                        )}

                        {infoBoxNotes && (
                          <div className="alert alert-info">
                            <p>No diagnosis information was found</p>
                          </div>
                        )}
                      </Row>
                    </Card.Body>
                  </Card>
                )}
              {!errorBoxNotes && !infoBoxNotes && proceed && !delayNotes && (
                <Card className="low-profile">
                  <Card.Header>
                    <h4 className="card-title">Diagnosis</h4>
                  </Card.Header>
                  <Card.Body className="text-left card-body">
                    <Row>
                      <br />
                      {/* <Col md={12} className="mt-2">
                        <strong>Case Information: </strong>
                        {assessmentCaseInformation}
                      </Col> */}

                      {/* <Col md={12} className="mt-2">
                        <strong>Tests Recommended: </strong>
                        {assessmentTests}
                      </Col> */}

                      <Col md={12} className="mt-2">
                        <strong>Diagnosis: </strong>
                        {assessmentDiagnosis}
                      </Col>

                      <Col md={12} className="mt-2">
                        <strong>Prescription: </strong>
                        {assessmentPrescription}
                      </Col>

                      <Col md={12} className="mt-2">
                        <strong>Additional Notes: </strong>
                        {additionalNotes}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              )}
            </div>
          ) : (
            <div
              className="col-lg-7 divCalendar"
              style={{ maxHeight: '500px', overflowY: 'scroll' }}
            >
              {/* <div className="card">
               */}
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: 'prev,next',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay',
                }}
                // dayMaxEvents={1}
                moreLinkContent={function (args) {
                  return args.num + ' appts'
                }}
                stickyHeaderDates={true}
                contentHeight={800}
                views={{
                  // dayGridMonth: {
                  //   eventLimit: 1, // adjust to 6 only for timeGridWeek/timeGridDay
                  // },
                  day: {
                    // name of view
                    titleFormat: {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    },
                    // other view-specific options here
                  },
                }}
                initialView="dayGridMonth"
                // editable={true}
                // selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={weekendsVisible}
                events={appointments} // alternatively, use the `events` setting to fetch from a feed
                select={handleDateSelect}
                eventContent={renderEventContent} // custom render function
                eventClick={handleEventClick}
                eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                /* you can update a remote database when these fire:
          eventAdd={function(){}}
          eventChange={function(){}}
          eventRemove={function(){}}
          */
              />
            </div>
          )}
          <div className="col-lg-5">
            {/* <div className="card">
              <div className="card-body"> */}
            <h4 className="card-intro-title">Todays Appointments</h4>
            <div>
              <div id="external-events" className="my-3">
                <>
                  {today?.length > 0 ? (
                    <Table>
                      <thead className="bg-gray">
                        <tr>
                          <th>Time</th>
                          <th>Patient</th>
                          <th>Reason</th>
                          <th>Manage</th>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: '14px' }}>
                        {today.map((event, index) => {
                          return (
                            <tr
                              key={index}
                              className={
                                event?.status === 'Cancel'
                                  ? 'cancelled-appt'
                                  : 'r'
                              }
                            >
                              <td>
                                {event.time_slot}{' '}
                                {event?.status === 'Done' && (
                                  <i
                                    className="fa fa-check-circle text-success"
                                    title="Completed"
                                  ></i>
                                )}
                              </td>
                              <td>
                                <div
                                  title="Click here to view appointment"
                                  className={`${event?.type}-appt cursor-pointer`}
                                  onClick={() => {
                                    setAppointmentToView(event)
                                    setViewAppointment(!viewAppointment)
                                  }}
                                >
                                  <small>
                                    {user.role === 'Patient'
                                      ? user.userName
                                      : event?.patient?.name}
                                  </small>
                                </div>
                                <small>
                                  {trimString(event?.doctorId?.name, 13)}
                                </small>
                              </td>
                              <td>
                                <HoverComponent
                                  textToDisplay={event.reason_for_visit}
                                  placement="bottom"
                                >
                                  <b className="cursor-pointer text-info">
                                    {trimString(event.reason_for_visit, 13)}
                                  </b>
                                </HoverComponent>
                              </td>
                              <td
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                <i
                                  style={{ color: '#A0A4A8' }}
                                  className="fas fa-eye fa-2x mr-2 cursor-pointer"
                                  onClick={() => {
                                    setAppointmentToView(event)
                                    setViewAppointment(!viewAppointment)
                                  }}
                                  title="View Appointment"
                                ></i>
                                {event?.symptom?.triage_started === 1 && (
                                  <i
                                    style={{ color: '#A0A4A8' }}
                                    className="fas fa-check-circle fa-2x mr-1 cursor-pointer text-warning"
                                    title="Triage completed"
                                  ></i>
                                )}

                                {user.role === 'DOCTOR' ? (
                                  <i
                                    onClick={() => assessPatient(event)}
                                    title="Assess Patient"
                                  >
                                    <i
                                      className={`fa fa-circle-chevron-right fa-2x cursor-pointer ${
                                        event?.status === 'Done'
                                          ? 'text-success'
                                          : 'text-info'
                                      }`}
                                    ></i>
                                  </i>
                                ) : (
                                  <Link
                                    to="/patient-profile"
                                    state={{ fromEvent: event?.patient_id }}
                                    title="See Patient profile"
                                  >
                                    <i className="fa fa-circle-chevron-right fa-2x cursor-pointer text-info"></i>
                                  </Link>
                                )}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  ) : (
                    <p>No Appointments Today</p>
                  )}
                </>
              </div>
              {/* checkbox */}

              <Link
                className="btn btn-primary btn-event w-100"
                to="/book-appointment"
                title="Click here to Book New Appointment"
              >
                <span className="align-middle">
                  <i style={{ color: '#fff' }} className="fas fa-plus mr-2"></i>
                </span>{' '}
                Book New Appointment
              </Link>
              {/* </div>
          </div> */}
            </div>
          </div>

          {/* Modal */}
          <Modal
            size="xl"
            show={viewAppointment}
            onHide={() => setViewAppointment(false)}
            // aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header>
              <Modal.Title id="example-modal-sizes-title-lg">
                View Appointment
              </Modal.Title>

              <i
                className="fa fa-times"
                style={{ cursor: 'pointer' }}
                onClick={() => setViewAppointment(false)}
              ></i>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={6}>
                  <strong>Doctor: </strong>
                  {appointmentToView?.doctorId?.name}
                </Col>
                <Col md={6}>
                  <strong>Status: </strong>
                  {appointmentToView?.status}
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={6}>
                  <strong>Patient: </strong>
                  {appointmentToView?.patient?.name || 'self'}
                </Col>
                <Col md={6}>
                  <strong>Center: </strong>
                  {appointmentToView?.Health_center?.name}
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={6}>
                  <strong>Date: </strong>
                  {formatDate(appointmentToView?.date, { locale: 'es' })}
                </Col>
                <Col md={6}>
                  <strong>Time: </strong>
                  {appointmentToView?.time_slot}
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={6}>
                  <strong>SMS Reminder: </strong>
                  {appointmentToView?.send_reminder === true ? 'Yes' : 'No'}
                </Col>
                <Col md={6}>
                  <strong>Email Reminder: </strong>
                  {appointmentToView?.email === true ? 'Yes' : 'No'}
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  <strong>Reason For Visit: </strong>
                  {appointmentToView?.reason_for_visit}
                </Col>
              </Row>
              <br />
              <hr />
              {/* {user.role === 'Patient' && (
                <Row>
                  <Col sm={6}>
                    <Button variant="danger" onClick={cancelAppointment}>
                      Cancel Appointment
                    </Button>
                  </Col>
                </Row>
              )} */}
            </Modal.Body>
          </Modal>

          {/* modal end */}
        </div>
      </div>
    </div>
  )
}
export default MainApp

function renderSidebarEvent(event) {
  return (
    <li key={event.id}>
      <b>
        {formatDate(event.start, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })}
      </b>
      <i>{event.title}</i>
    </li>
  )
}
