import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import LeftBar from '../components/LeftBar'
import Select from 'react-select'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay'
import { tConv24, titleSentenceCase } from '../constants/Essentials'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const Booking = () => {
  const location = useLocation()
  toast.configure()
  // abortcontroller
  const abortcontroller = new AbortController()
  let navigate = useNavigate()
  const signal = abortcontroller.signal
  const user = useSelector(selectUser)

  const [healthCenters, setHealthCenters] = useState([])
  const [doctor, setDoctor] = useState([])
  const [allDoctors, setAllDoctors] = useState([])
  const [slots, setSlots] = useState([])
  const [patients, setPatients] = useState([])
  const [emailReminder, setEmailReminder] = useState(true)
  const [smsReminder, setSmsReminder] = useState(true)
  const [specialties, setSpecialties] = useState([])
  const [reasonToSelect, setReasonToSelect] = useState([])
  const [reasonSelected, setReasonSelected] = useState()
  const [otherReason, setOtherReason] = useState('')

  const [selectedHc, setSelectedHc] = useState()
  const [dateSelected, setDateSelected] = useState('')
  const [selectedDoctor, setSelectedDoctor] = useState('')
  const [selectedPatient, setSelectedPatient] = useState({})
  const [slotSelected, setSlotSelected] = useState()
  const [selectedSpecialty, setSelectedSpecialty] = useState('')
  const [selectedSpecialtyId, setSelectedSpecialtyId] = useState('')

  //fake payment modal--
  const [fakePaymentModal, setFakePaymentModal] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')

  const centersUrl = `${process.env.REACT_APP_MAIN_URL}/api/health-center/`

  const bookAppointmentsStep1 = async () => {
    // document.getElementById("fake_payment").style.filter = "blur(5px)";

    // setIsProcessing(true);
    // await new Promise((resolve) => setTimeout(resolve, 4000));

    bookAppointments()
  }

  const bookAppointments = () => {
    delete slotSelected.value
    delete slotSelected.label
    const dataToSend = {
      // health_center_id: selectedHc.value,
      slot: slotSelected,
      health_center: {
        id: user.hc_id === undefined ? selectedHc.value : user.hc_id,
        name:
          user.hc_id === undefined
            ? selectedHc.label
            : user?.health_center_name || 'Self',
      },
      doctor: { id: selectedDoctor?.value, name: selectedDoctor?.label },
      patient_id:
        user.role === 'Patient'
          ? user.user_id
          : location.state?.patient_id || selectedPatient.value,
      time_slot: slotSelected.time_slot,
      date: dateSelected,
      reason_for_visit:
        otherReason === ''
          ? reasonSelected?.label
          : reasonSelected?.label === undefined
          ? otherReason
          : reasonSelected?.label + ' ' + otherReason,
      send_reminder: smsReminder,
      email: emailReminder,
      specialty_id: selectedSpecialtyId,
    }
    // return console.log(dataToSend);
    const url = `${process.env.REACT_APP_MAIN_URL}/api/appointment/create-appointment`
    // send to payment page here
    // return navigate("/payments", { state: dataToSend });

    // send request
    fetch(url, {
      signal: signal,
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }
        console.log(res)
        const dataToProceed = {
          ...dataToSend,
          ...{
            patient_name:
              location?.state?.patient_name || selectedPatient.label,
          },
          ...{ price: res.data.price.cost },
          ...{ appt_id: res.data.appointment.id },
        }
        navigate('/payments', { state: dataToProceed })

        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        })
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
  }
  const searchSlots = () => {
    //get selected doctor
    if (
      selectedDoctor?.value !== '' &&
      dateSelected !== '' &&
      (selectedHc?.value !== '' || user.hc_id !== undefined)
    ) {
      const hc = selectedHc?.value || user.hc_id
      const fetchSlotsUrl = `${process.env.REACT_APP_MAIN_URL}/api/appointment/?health_center_id=${hc}&doctor_id=${selectedDoctor?.value}&date=${dateSelected}`
      fetch(fetchSlotsUrl, {
        method: 'GET',
        headers: new Headers({
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            return toast.warning(
              'An error occured while fetching center users',
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 15000,
              },
            )
          }

          res.data.forEach((item) => {
            item.value = item.id
            item.label = tConv24(item.time_slot)
            // now I no longer need them
            // delete item.id;
            // delete item.time_slot;
            //->will delete the rest later
          })

          setSlots(res.data)
          setSlotSelected(null)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  // useEffect(() => {
  //   window.scrollTo(0, 0)
  //   console.log('scroolededededededed to the top')
  // }, [])

  // health centers
  useEffect(() => {
    if (user.hc_id === undefined) {
      fetch(centersUrl, {
        signal: signal,
        method: 'GET',
        headers: new Headers({
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            return console.log('health centers fetching did not succeed')
          }

          res.data.items.forEach((item) => {
            item.value = item.id
            item.label = titleSentenceCase(item.name)
            // now I no longer need them
            delete item.id
            delete item.name
            //->will delete the rest later
          })
          // console.log(res.data);

          res.data?.items?.length > 0 && setHealthCenters(res.data.items)
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      findDoctors({ value: user.hc_id })
    }
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  //specialties
  const specialtyUrl = `${process.env.REACT_APP_MAIN_URL}/api/specialty/?size=30`
  useEffect(() => {
    fetch(specialtyUrl, {
      signal: signal,
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return console.log('Specialty fetching did not succeed')
        }
        const iterable = res.data.items

        iterable.forEach((item) => {
          item.value = item.id
          item.label = titleSentenceCase(item.name)
        })
        // iterable.unshift({ value: "any", label: "Any" });

        setSpecialties(iterable)
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  // patients
  const patientsUrl = `${process.env.REACT_APP_MAIN_URL}/api/patient/`
  useEffect(() => {
    fetch(patientsUrl, {
      signal: signal,
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return console.log('patients fetching did not succeed')
        }
        let iterable = user.role === 'SUPER_ADMIN' ? res.data.items : res.data

        iterable.forEach((item) => {
          item.value = item.id
          item.label = titleSentenceCase(item.name)
          // now I no longer need them
          delete item.id
          delete item.name
          //->will delete the rest later
        })
        // console.log(res.data);

        iterable?.length > 0 && setPatients(iterable)
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  const findDoctors = (selectedOption, specialty = '') => {
    setDateSelected('')
    setSlotSelected(null)
    setSlots([])
    setSelectedHc(selectedOption)
    const fetchUsersUrl = `${process.env.REACT_APP_MAIN_URL}/api/user/health-center/?role=doctor&health_center_id=${selectedOption.value}&specialty=${specialty}`

    fetch(fetchUsersUrl, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        res.data.items.forEach((item) => {
          item.value = item.id
          item.label = item.name
          // now I no longer need them
          delete item.id
          delete item.name
          //->will delete the rest later
        })
        // console.log(res.data);

        setDoctor(res.data.items)
        setAllDoctors(res.data.items)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  // filter Doctors
  const filterDoctors = (label) => {
    setSelectedDoctor(null)
    setSlots([])
    setSlotSelected(null)
    const newDocs =
      label === 'Any'
        ? allDoctors
        : allDoctors.filter((doc) => doc.specialties === label)
    setDoctor(newDocs)

    // if(label==="Any"){
    //   setReasonToSelect([{value:"General Consultation", label:"General Consultatione"}])
    // }
  }
  // find reasonT
  const findReasons = (specialty_id) => {
    const fetchReasonsUrl = `${process.env.REACT_APP_MAIN_URL}/api/reason/?specialty_id=${specialty_id}`

    fetch(fetchReasonsUrl, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        res.data.forEach((item) => {
          item.value = item.id
          item.label = item.title
          // now I no longer need them
          delete item.id
          delete item.title
          //->will delete the rest later
        })

        if (res.data?.length === 0) {
          setReasonSelected(null)
        }
        setReasonToSelect(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div id="main-wrapper">
      {/***********************************
        Nav header start
    ************************************/}
      {/* <NavHeader/> */}
      {/***********************************
        Nav header end
    ************************************/}

      {/***********************************
        Header start
    ************************************/}
      <Header />
      {/***********************************
        Header end ti-comment-alt
    ************************************/}
      {/***********************************
        Sidebar start
    ************************************/}
      <LeftBar />
      {/***********************************
        Sidebar end
    ************************************/}
      {/***********************************
        Content body start
    ************************************/}
      <div className="content-body">
        <div className="card">
          <div className="card-header py-2">
            <h3>Book appointment</h3>
          </div>
        </div>
        <div className="card">
          <div className="row bg-white py-3">
            <div className="col-lg-9">
              <form id="contact-form" role="form">
                <div className="controls">
                  <div className="row">
                    {location.state && (
                      <div className="col-sm-12">
                        {/* <h4>
                        {`Booking Appointment for ${location?.state?.patient_name}`}
                      </h4> */}
                        <h4>Appointment details</h4>
                      </div>
                    )}

                    {user.hc_id === undefined && (
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label htmlFor="health_center">
                            Health center <span className="required">*</span>
                          </label>

                          <Select
                            options={healthCenters}
                            id="health_center"
                            onChange={(selectedOption) => {
                              findDoctors(selectedOption)
                              console.log(
                                'health centr id:',
                                selectedOption.value,
                              )
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="specialty">Specialty</label>
                        <Select
                          options={specialties}
                          id="specialty"
                          placeholder="Select specialty"
                          onChange={(selectedOption) => {
                            // findDoctors(
                            //   { value: user.hc_id || selectedHc?.value },
                            //   selectedOption.label
                            // );
                            console.log('========== speciality ===========')
                            console.log('speciality:', selectedOption.value)
                            filterDoctors(selectedOption.label)
                            findReasons(selectedOption.value)
                            setSelectedSpecialtyId(selectedOption.value)
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="doctor">
                          Doctor <span className="required">*</span>
                        </label>
                        <Select
                          options={doctor}
                          id="doctor"
                          value={selectedDoctor}
                          onChange={(selectedOption) => {
                            setSelectedDoctor(selectedOption)
                            setSlotSelected(null)
                            setSlots([])
                          }}
                          onBlur={searchSlots}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="date">
                          Date <span className="required">*</span>
                        </label>{' '}
                        <input
                          id="date"
                          type="date"
                          min={new Date().toISOString().split('T')[0]}
                          className="form-control"
                          value={dateSelected}
                          onChange={(e) => {
                            setDateSelected(e.target.value)
                          }}
                          onBlur={searchSlots}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="slot">
                          Time <span className="required">*</span>
                        </label>
                        <Select
                          options={slots}
                          id="slot"
                          defaultValue={{ value: '', label: 'Select a slot' }}
                          placeholder="select slot"
                          value={slotSelected}
                          onChange={(selectedOption) =>
                            setSlotSelected(selectedOption)
                          }
                        />
                      </div>
                    </div>
                    <>
                      {user.role !== 'Patient' && (
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="patient">Patient *</label>
                            <Select
                              options={
                                location.state
                                  ? patients.filter(
                                      (patient) =>
                                        patient.value ===
                                        location.state?.patient_id,
                                    )
                                  : patients
                              }
                              id="patient"
                              defaultValue={{
                                value: location.state?.patient_id || '',
                                label: location.state?.patient_name || '',
                              }}
                              placeholder="select patient"
                              onChange={(selectedOption) =>
                                setSelectedPatient(selectedOption)
                              }
                            />
                          </div>
                        </div>
                      )}
                    </>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label htmlFor="sms">SMS reminder</label>
                        <br />
                        <input
                          id="sms"
                          type="checkbox"
                          // className="form-control"
                          checked={smsReminder}
                          onChange={(e) => setSmsReminder(e.target.checked)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label htmlFor="email">Email reminder</label>
                        <br />
                        <input
                          id="email"
                          type="checkbox"
                          // className="form-control"
                          checked={emailReminder}
                          onChange={(e) => setEmailReminder(e.target.checked)}
                        />
                      </div>
                    </div>
                    {user.role === 'Patient' && (
                      <div className="col-sm-6"></div>
                    )}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="reason">Reason for visit</label>
                        <Select
                          options={reasonToSelect}
                          id="reason"
                          value={reasonSelected}
                          onChange={(value) => {
                            setReasonSelected(value)
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="reason-o">Other</label>

                        <textarea
                          className="form-control"
                          rows="4"
                          value={otherReason}
                          id="reason-o"
                          placeholder="Enter other reason that is not listed above"
                          onChange={(e) => setOtherReason(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 ">
                      <button
                        className="btn btn-success btn-send pt-2 rounded btn-block shadow center-align"
                        defaultValue="Book"
                        title="Click here to Book Appointment"
                        onClick={bookAppointments}
                        type="button"
                        disabled={
                          !slotSelected?.time_slot ||
                          !selectedDoctor?.value ||
                          !dateSelected
                        }
                      >
                        Next
                      </button>
                    </div>
                    <div className="col-md-8 "></div>
                  </div>
                </div>
              </form>
            </div>
            {/* /.row*/}
          </div>
        </div>
      </div>
      {/***********************************
        Content body end
    ************************************/}

      {/***********************************
        Footer start
    ************************************/}
      <Footer />
      {/***********************************
        Footer end
    ************************************/}
    </div>
  )
}

export default Booking
