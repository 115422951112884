import React, { useRef } from 'react'
import ReactToPrint from 'react-to-print'
import { useSelector } from 'react-redux'
import {
  selectInvoice,
  selectIdentification,
  selectPharmacy,
  selectConsultation,
  selectTotal,
} from '../redux_setup/slices/invoiceSlice'
import Footer from './Footer'
import Header from './Header'
import LeftBar from './LeftBar'
import { selectInsuranceInvoice } from '../redux_setup/slices/insuranceInvoiceSlice'
import { findTotalsInArrayOfObjects } from '../constants/Essentials'
import { Table } from 'react-bootstrap'
const OutputInvoice = () => {
  const invoice = useSelector(selectInvoice)
  const identification = useSelector(selectIdentification)
  const pharmacy = useSelector(selectPharmacy)
  const consultation = useSelector(selectConsultation)
  const calculated = useSelector(selectTotal)
  const insuranceInvoice = useSelector(selectInsuranceInvoice)
  const componentRef = useRef()

  return (
    <div className="main-wrapper">
      {/***********************************
            Header start
            ************************************/}
      <Header />
      {/***********************************
            Header end ti-comment-alt
            ************************************/}
      {/***********************************
            Sidebar start
            ************************************/}
      <LeftBar />
      {/***********************************
            Sidebar end
            ************************************/}
      {/***********************************
            Content body start
            ************************************/}

      {/***********************************
            Content body end
            ************************************/}
      <div className="content-body">
        <div ref={componentRef}>
          <div className="flex__container" style={{ gap: 50 }}>
            <img src={`${process.env.PUBLIC_URL}/images/clinicklogo.png`} />
            <div className="flex__block">
              <span className="header__title" style={{ color: '#25282B' }}>
                Street Name, Suit 00, State,
                <br />
                Zip Code, Country
              </span>
              <span className="header__title">
                <span style={{ color: '#45B757' }}>t:&nbsp;&nbsp;</span>+123 00
                456 7890
              </span>
              <span className="header__title">
                <span style={{ color: '#45B757' }}>e:&nbsp;&nbsp;</span>
                email@doctorschamber.com
              </span>
            </div>
          </div>
          <div
            className="flex__container"
            style={{ justifyContent: 'flex-end' }}
          >
            <span className="invoice__title">INVOICE</span>
          </div>
          {/* Left section */}
          <div className="row" style={{ marginTop: 40 }}>
            <div className="col-md-6 col-sm-12">
              <div className="flex__container">
                <label className="invoice__label">INVOICE NO:</label>
                <label className="invoice__label" style={{ fontWeight: 400 }}>
                  {identification?.invoice_no}
                </label>
              </div>
              <div className="flex__container">
                <label className="invoice__label">DATE:</label>
                <label className="invoice__label" style={{ fontWeight: 400 }}>
                  {identification?.date}
                </label>
              </div>
              <div className="flex__container">
                <label className="invoice__label">OUTPATIENT FILE NO:</label>
                <label className="invoice__label" style={{ fontWeight: 400 }}>
                  {identification?.file_no}
                </label>
              </div>
              <div className="flex__container">
                <label className="invoice__label">PATIENTS NO:</label>
                <label className="invoice__label" style={{ fontWeight: 400 }}>
                  {identification?.patient_no}
                </label>
              </div>
            </div>
            {/* Right section */}
            <div className="col-md-6 col-sm-12">
              <div style={{ marginLeft: 140 }}>
                <div className="flex__container">
                  <label className="invoice__label">INSURANCE PROVIDER:</label>
                  <label className="invoice__label" style={{ fontWeight: 400 }}>
                    {identification?.insurance_provider}
                  </label>
                </div>
                <div className="flex__container">
                  <label className="invoice__label">SCHEME:</label>
                  <label className="invoice__label" style={{ fontWeight: 400 }}>
                    {identification?.scheme}
                  </label>
                </div>
                <div className="flex__container">
                  <label className="invoice__label">
                    INSURANCE MEMBERSHIP NO:
                  </label>
                  <label className="invoice__label" style={{ fontWeight: 400 }}>
                    {identification?.insurance_no}
                  </label>
                </div>
                <div className="flex__container">
                  <label className="invoice__label">
                    PRINCIPAL MEMBER’S NAME:
                  </label>
                  <label className="invoice__label" style={{ fontWeight: 400 }}>
                    {identification?.principal_name}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <Table>
            {insuranceInvoice.map((elem, index) => {
              if (elem.selected && elem.service === 'Consultation') {
                return (
                  <>
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Service</th>
                        <th>Type</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Discount</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1.</td>
                        <td>{elem.service}</td>
                        <td></td>
                        <td>
                          <input
                            type="number"
                            value={elem?.pricefield}
                            className="form-control"
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={elem?.quantityfield}
                            className="form-control"
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={elem?.discountfield}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={elem?.amountfield}
                            className="form-control"
                            readOnly
                          />
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Subtotal</td>
                        <td>
                          <input
                            type="text"
                            value={elem?.subtotalfield || 0}
                            className="form-control"
                            readOnly
                          />
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Tax (%)</td>
                        <td>
                          <input
                            type="number"
                            value={elem?.taxfield}
                            max={100}
                            min={0}
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total</td>
                        <td>
                          <input
                            type="text"
                            value={elem?.totalfield || 0}
                            className="form-control"
                            readOnly
                          />
                        </td>
                      </tr>
                    </tbody>
                  </>
                )
              }
              if (elem.selected && elem.service === 'Labs') {
                return (
                  <>
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Service</th>
                        <th>Test</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Discount</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>2.</td>
                        <td>{elem.service}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      {
                        //repeat rows in chosen tests here
                        elem.chosenTests.map((test, index) => (
                          <tr>
                            <td></td>
                            <td></td>
                            <td>{test?.name}</td>
                            <td>
                              <input
                                type="text"
                                value={test?.price}
                                className="form-control"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={test?.quantity}
                                className="form-control"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={test?.discount}
                                className="form-control"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={test.amount || 0}
                                className="form-control"
                                readOnly
                              />
                            </td>
                          </tr>
                        ))
                      }

                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Subtotal</td>
                        <td>
                          <input
                            type="text"
                            value={elem?.subtotalfield || 0}
                            className="form-control"
                            readOnly
                          />
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Tax (%)</td>
                        <td>
                          <input
                            type="number"
                            value={elem?.taxfield}
                            max={100}
                            min={0}
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3}></td>
                        <td></td>
                        <td></td>
                        <td>Total</td>
                        <td>
                          <input
                            type="text"
                            value={elem?.totalfield || 0}
                            className="form-control"
                            readOnly
                          />
                        </td>
                      </tr>
                    </tbody>
                  </>
                )
              }
              //pharmacy
              if (elem.selected && elem.service === 'Pharmacy') {
                return (
                  <>
                    <thead style={{ width: '80%' }}>
                      <tr>
                        <th>No.</th>
                        <th>Service</th>
                        <th>Drug</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Discount</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>3.</td>
                        <td>{elem.service}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      {
                        //repeat rows in chosen tests here
                        elem.chosenDrugs.map((drug, index) => (
                          <tr>
                            <td></td>
                            <td></td>
                            <td>{drug?.slug}</td>
                            <td>
                              <input
                                type="text"
                                value={drug?.price}
                                className="form-control"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={drug?.quantity}
                                className="form-control"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={drug?.discount}
                                className="form-control"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={drug.amount || 0}
                                className="form-control"
                                readOnly
                              />
                            </td>
                          </tr>
                        ))
                      }
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Subtotal</td>
                        <td>
                          <input
                            type="text"
                            value={elem?.subtotalfield || 0}
                            className="form-control"
                            readOnly
                          />
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Tax (%)</td>
                        <td>
                          <input
                            type="number"
                            value={elem?.taxfield}
                            max={100}
                            min={0}
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total</td>
                        <td>
                          <input
                            type="text"
                            value={elem?.totalfield || 0}
                            className="form-control"
                            readOnly
                          />
                        </td>
                      </tr>
                    </tbody>
                  </>
                )
              }
            })}
          </Table>

          <div
            className="flex__container"
            style={{
              justifyContent: 'space-between',
              backgroundColor: '#FFF',
              width: '100%',
            }}
          >
            <div
              className="flex__block"
              style={{ justifyContent: 'space-between' }}
            >
              <span
                className="invoice__label"
                style={{ marginTop: 20, marginLeft: 30 }}
              >
                If you have any query about any information
                <br /> provided on this invoice please contact us on
                <br /> the details provided.
              </span>
            </div>
            <div>
              <div
                className="invoice__item"
                style={{ justifyContent: 'flex-end' }}
              >
                <div
                  className="flex__container"
                  style={{ marginRight: 30, gap: 80 }}
                >
                  <span className="invoice__label" style={{ color: '#25282B' }}>
                    {`Grand Total`}
                  </span>
                  <label className="short__label">
                    {findTotalsInArrayOfObjects(insuranceInvoice, 'totalfield')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex__container"
            style={{ backgroundColor: '#FFF', gap: 40, width: '100%' }}
          >
            <div
              className="flex__container"
              style={{ marginBottom: 5, marginTop: 5 }}
            >
              <span style={{ marginLeft: 30, whiteSpace: 'nowrap' }}>
                Approving Doctor’s
                <br /> Signature:
              </span>
              <div
                style={{
                  width: 246,
                  height: 1,
                  backgroundColor: '#E8E8E8',
                  marginTop: 30,
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ width: '90%' }}>
          <div style={{ marginTop: 50 }}>
            <div
              className="flex__container"
              style={{ justifyContent: 'flex-end' }}
            >
              <ReactToPrint
                trigger={() => (
                  <button className="invoice__btn" style={{ marginLeft: 0 }}>
                    Print Invoice
                  </button>
                )}
                content={() => componentRef.current}
              />
            </div>
          </div>
        </div>
      </div>

      {/***********************************
            Footer start
            ************************************/}
      <Footer />
      {/***********************************
            Footer end
            ************************************/}
    </div>
  )
}
const ReusableSection = ({ number, property }) => {
  const sumation = (qty) => {
    let sm = 0
    for (let i = 0; i < qty.length; i++) {
      sm += parseInt(qty[i])
    }
    return sm
  }
  return (
    <>
      <div className="invoice__header">
        <label className="invoice__label" style={{ color: '#25282B' }}>
          NO
        </label>
        <label className="invoice__label" style={{ color: '#25282B' }}>
          SERVICE
        </label>
        <label className="invoice__label" style={{ color: '#25282B' }}>
          SERVICE TYPE
        </label>
        <label className="invoice__label" style={{ color: '#25282B' }}>
          PRICE
        </label>
        <label className="invoice__label" style={{ color: '#25282B' }}>
          QUANTITY
        </label>
        <label className="invoice__label" style={{ color: '#25282B' }}>
          DISCOUNT
        </label>
        <label className="invoice__label" style={{ color: '#25282B' }}>
          AMOUNT
        </label>
      </div>
      <div className="invoice__item">
        <span className="invoice__label" style={{ fontWeight: 400 }}>
          {number}.
        </span>
        <span
          className="invoice__label"
          style={{ fontWeight: 400, marginLeft: 62 }}
        >
          {property?.service}
        </span>
        <span
          className="invoice__label"
          style={{ fontWeight: 400, marginLeft: 90 }}
        >
          {Array.isArray(property?.serviceType) ? (
            property.serviceType.map((type, index) => (
              <div className="flex__block" key={index}>
                <span className="invoice__label" style={{ fontWeight: 400 }}>
                  {type}
                </span>
              </div>
            ))
          ) : (
            <span
              className="invoice__label"
              style={{ fontWeight: 400, marginLeft: 60 }}
            >
              {property?.serviceType}
            </span>
          )}
        </span>
        <span
          className="invoice__label"
          style={{ fontWeight: 400, marginLeft: 40 }}
        >
          {property?.price}
        </span>

        <>
          <span
            className="invoice__label"
            style={{ fontWeight: 400, marginLeft: 60 }}
          >
            {property.quantity}
          </span>
        </>
        <span
          className="invoice__label"
          style={{ fontWeight: 400, marginLeft: 70 }}
        >
          {property?.discount}
        </span>
        <span
          className="invoice__label"
          style={{ fontWeight: 400, marginLeft: 60 }}
        >
          {property?.total}
        </span>
      </div>
      <div className="invoice__item" style={{ justifyContent: 'flex-end' }}>
        <div className="flex__container" style={{ marginRight: 200, gap: 80 }}>
          <span className="invoice__label" style={{ color: '#25282B' }}>
            {`subtotal ${property?.service}`}
          </span>
          <span>{property?.subtotal}</span>
        </div>
      </div>
      <div className="invoice__item" style={{ justifyContent: 'flex-end' }}>
        <div className="flex__container" style={{ marginRight: 206, gap: 80 }}>
          <span className="invoice__label" style={{ color: '#25282B' }}>
            Tax
          </span>
          <span>{property?.tax}</span>
        </div>
      </div>
      <div className="invoice__item" style={{ justifyContent: 'flex-end' }}>
        <div className="flex__container" style={{ marginRight: 206, gap: 80 }}>
          <span className="invoice__label" style={{ color: '#25282B' }}>
            {`Total ${property?.service}`}
          </span>
          <span>{property?.total}</span>
        </div>
      </div>
      {/* <div style={{ width: "90%" }}>
        <div className="flex__container" style={{ justifyContent: "flex-end" }}>
          <div style={{ marginTop: 50, backgroundColor: "#FFF" }}>
            <div className="invoice__item" style={{ width: 299 }}>
              <div className="flex__container" style={{ gap: 120 }}>
                <span
                  className="invoice__label"
                  style={{ color: "#25282B", whiteSpace: "nowrap" }}
                >
                  {`Subtotal ${property?.service}`}
                </span>
                <span>{property?.subtotal}</span>
              </div>
            </div>
            <div className="invoice__item" style={{ width: 299 }}>
              <div className="flex__container" style={{ gap: 80 }}>
                <span
                  className="invoice__label"
                  style={{ color: "#25282B", whiteSpace: "nowrap" }}
                >
                  Tax
                </span>
                <span>{property?.tax}</span>
              </div>
            </div>
            <div className="invoice__item" style={{ width: 299 }}>
              <div className="flex__container" style={{ gap: 120 }}>
                <span
                  className="invoice__label"
                  style={{ color: "#25282B", whiteSpace: "nowrap" }}
                >
                  {`Total ${property?.service}`}
                </span>
                <span>{property?.service}</span>
              </div>
            </div>
            <div className="invoice__item" style={{ width: 299 }}>
              <div className="flex__container" style={{ gap: 100 }}>
                <span
                  className="invoice__label"
                  style={{ color: "#25282B", whiteSpace: "nowrap" }}
                >
                  Grand Total
                </span>
                <span>0.00</span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}
export default OutputInvoice
