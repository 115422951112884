import React from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

const HoverComponent = (props) => {
  const text = props.textToDisplay
  const renderTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {text}
      </Tooltip>
    )
  }
  return (
    <OverlayTrigger
      placement={props.placement}
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      {props.children}
    </OverlayTrigger>
  )
}

export default HoverComponent
