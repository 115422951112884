import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Pie } from 'react-chartjs-2'

ChartJS.register(ChartDataLabels, ArcElement);

export const data = {
  labels: ["Lunch", "Breakfast", "Dinner", "Snacks"],
  datasets: [
    {
      label: "# of Votes",
      data: [490, 600, 1000, 578],
      backgroundColor: ["#D7C3E680", "#101E734D", "#2FA5DB4D", "#D0E0FF80"],
      borderColor: ["#D7C3E680", "#101E734D", "#2FA5DB4D", "#D0E0FF80"],
      borderWidth: 1,
    },
  ],
};
const options = {
  plugins: {
    datalabels: {
      color: "#000",
      formatter: (value, ctx) => {
        const label = ctx.chart.data.labels[ctx.dataIndex];
        return `${label}: ${value}`;
      },
    },
  },
};

export const FoodChart = () => {
  return (
    <div style={{ margin: 20, width: 278, height: 278 }}>
      <Pie data={data} options={options} />
      {/* <Bar data={data}/> */}
    </div>
  );
}
