import { useState, useRef, useEffect } from 'react'
import Footer from './Footer'
import Header from './Header'
import LeftBar from './LeftBar'
import Form from 'react-bootstrap/Form'
import { Link } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import Select from 'react-select'
import {
  selectRx,
  updatePrescription,
  updateRx,
} from '../redux_setup/slices/prescriptionSlice'
import { calculateAge } from '../constants/Essentials'
const DoctorPrescription = () => {
  const user = useSelector(selectUser)
  const dispatch = useDispatch()
  toast.configure()
  const rxRef = useRef(null)
  const formRef = useRef()
  let navigate = useNavigate()
  const location = useLocation()
  const [agree, setAgree] = useState(false)
  const [dataToSend, setDataToSend] = useState([])
  const [drugName, setDrugName] = useState('')
  const [drugStrength, setDrugStrength] = useState('')
  const [drugVolume, setDrugVolume] = useState('')
  const [drugWeight, setDrugWeight] = useState('')
  const [drugDose, setDrugDose] = useState('')
  const [drugDirectionUse, setDrugDirectionUse] = useState('')
  const [drugQuantity, setDrugQuantity] = useState('')
  const [drugRefill, setDrugRefill] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [index, setIndex] = useState(null)
  const [rx, setRx] = useState('')
  const [proceedAgreed, setProceedAgreed] = useState(false)
  const [drugs, setDrugs] = useState([
    {
      value: 'Paracetamol',
      label: 'Paracetamol',
    },
    {
      value: 'Amoxylin',
      label: 'Amoxylin',
    },
    {
      value: 'Flagyl',
      label: 'Flagyl',
    },
    {
      value: 'Flugone',
      label: 'Flugone',
    },
    {
      value: 'Panadol',
      label: 'Panadol',
    },
    {
      value: 'Aesthesia',
      label: 'Aesthesia',
    },
  ])

  useEffect(() => {
    if (location?.state?.rx) {
      setDataToSend(location?.state?.rx)
    }
  }, [])
  useEffect(() => {
    setRx(Math.floor(Math.random() * 1000000))
  }, [])

  // console.log("DoctorPrescription", location);
  const patientDetails = location.state?.patientDetails
  const addDrugHandler = () => {
    if (!drugName) {
      return toast.info('Please select a drug name', {
        position: toast.POSITION.TOP_CENTER,
      })
    } else {
      if (isEdit) {
        dataToSend[index].drug_name = drugName?.value
        dataToSend[index].drug_weight = drugWeight
        dataToSend[index].drug_volume = drugVolume
        dataToSend[index].drug_strength = drugStrength
        dataToSend[index].drug_direction_of_use = drugDirectionUse
        dataToSend[index].drug_quantity = drugQuantity
        dataToSend[index].drug_refill = drugRefill

        if (!!dataToSend[index].drug_name) {
          // console.log(dataToSend[index].drug_name, 'isgood')
          setDataToSend([...dataToSend])
        }
        resetStates()
      } else {
        setDataToSend([
          ...dataToSend,
          {
            drug_name: drugName?.value,
            drug_direction_of_use: drugDirectionUse,
            drug_strength: drugStrength,
            drug_weight: drugWeight,
            drug_volume: drugVolume,
            drug_quantity: drugQuantity,
            drug_dose: drugDose,
            drug_refill: drugRefill,
          },
        ])
        resetStates()
        formRef.current?.reset()
        // setAddMedecine([...addMedecine, addDrug]);
        setIsEdit(false)
      }
    }
  }
  const resetStates = () => {
    setDrugName('')
    setDrugWeight('')
    setDrugVolume('')
    setDrugStrength('')
    setDrugDirectionUse('')
    setDrugDose('')
    setDrugQuantity('')
    setDrugRefill('')
    setIsEdit(false)
  }
  const submitHandler = () => {
    if (dataToSend?.length < 1) {
      return toast.info('Please fill the rx with data before proceeding', {
        position: toast.POSITION.TOP_CENTER,
      })
    }
    console.log(dataToSend)
    if (!proceedAgreed) {
      return toast.info('Please confirm by checking the box above', {
        position: toast.POSITION.TOP_CENTER,
      })
    }

    dispatch(updatePrescription([...dataToSend]))
    dispatch(updateRx({ rx_number: rxRef.current.innerText }))
    //send to server and save
    const toSend = {
      appointment_id: patientDetails?.appt_id,
      prescription: {
        rx: dataToSend,
      },
    }
    console.log(toSend)
    // return console.log(dataToSend);
    const url = `${process.env.REACT_APP_MAIN_URL}/api/general_consultation/prescription`
    // send to payment page here
    // return navigate("/payments", { state: dataToSend });
    // send request
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(toSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }
        // Swal.fire({
        //   title: 'You have completed consultation with this patient',
        //   showDenyButton: true,
        //   showCancelButton: true,
        //   icon: 'success',
        //   width: '600',
        //   confirmButtonText: 'Book Follow Up',
        //   denyButtonText: 'Nurse Instructions',
        //   cancelButtonText: 'Back To Dashboard',
        // }).then((result) => {
        //   if (result.isConfirmed) {
        //     // console.log('Booked Follow Up!', '', 'success')

        //     navigate('/book-follow-up', {
        //       state: {
        //         app_id: patient.appt_id,
        //         patient_id: patient.patient_id,
        //         doctor_id: user.user_id,
        //         health_center: user.hc_id,
        //       },
        //     })
        //   } else if (result.isDenied) {
        //     navigate('/instruct-nurse', {
        //       state: {
        //         appt_id: patient.appt_id,
        //       },
        //     })
        //   } else {
        //     console.log('cancelled')
        //     navigate('/')
        //   }
        // })

        toast.success('Prescription saved successfully', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
        return navigate('/prescription-print-view', {
          state: {
            patientDetails,
          },
        })
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
  }

  const agreement = (e) => {
    // if (e.target.checked) {
    setProceedAgreed(e.target.checked)
    // }
    // const newMedecine = {
    //   drug_name: drugName,
    //   drug_direction_of_use: drugDirectionUse,
    //   drug_strength: drugStrength,
    //   drug_weight: drugWeight,
    //   drug_volume: drugVolume,
    //   drug_quantity: drugQuantity,
    //   drug_dose: drugDose,
    //   drug_refill: drugRefill,
    // };
    // setAgree(e.target.checked);
    // if (e.target.checked) {
    //   if (!dataToSend.includes(newMedecine, 0)) {
    //     setDataToSend([...dataToSend, newMedecine]);
    //   }
    // }
  }
  // delete unwanted drug
  const deleteHandler = (index) => {
    console.log(index)
    dataToSend.splice(index, 1)
    setDataToSend([...dataToSend])
  }
  // Edit existing drug
  const editHandler = (index) => {
    setIsEdit(true)
    setIndex(index)
    const data = dataToSend[index]
    setDrugName('')
    setDrugDose(data.drug_dose)
    setDrugStrength(data.drug_strength)
    setDrugWeight(data.drug_weight)
    setDrugVolume(data.drug_volume)
    setDrugDirectionUse(data.drug_direction_of_use)
    setDrugQuantity(data.drug_quantity)
    setDrugRefill(data.drug_refill)
  }

  return (
    <div className="main-wrapper">
      {/***********************************
                Header start
                ************************************/}
      <Header />
      {/***********************************
                Header end ti-comment-alt
                ************************************/}
      {/***********************************
                Sidebar start
                ************************************/}
      <LeftBar />
      {/***********************************
                Sidebar end
                ************************************/}
      {/***********************************
                Content body start
                ************************************/}

      {/***********************************
                Content body end
                ************************************/}
      <div className="content-body">
        <p className="prescription__text">Patient details</p>
        <div className="card p-3">
          <div className="row">
            <div className="col-md-4">
              <div>
                <label className="recommeded__amount__content">
                  Name: {patientDetails?.name || ' '}
                </label>
              </div>
              <div>
                <label className="recommeded__amount__content">
                  Age: {calculateAge(patientDetails?.age) || '65 '} years
                </label>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <label className="recommeded__amount__content">
                  Phone: {patientDetails?.phone || ' '}
                </label>
              </div>
              <div>
                <label className="recommeded__amount__content">
                  Email: {patientDetails?.email || ' '}
                </label>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <label className="recommeded__amount__content">
                  Gender: {patientDetails?.gender || ' '}
                </label>
              </div>
            </div>
          </div>
        </div>

        <p className="prescription__text" ref={rxRef}>{`Rx #${rx}`}</p>
        <form ref={formRef}>
          <div className="prescription__container">
            {/* <input
              type="text"
              placeholder="Drug Name"
              className="form-control"
              style={{ width: '30%' }}
              value={drugName}
              onChange={(e) => setDrugName(e.target.value)}
            /> */}
            <p className="text-bold mt-2 mb-2">Drug</p>
            <div className="form-group row">
              <label className="label">Drug Name</label>
              <div className="col-md-4">
                <Select
                  isClearable
                  isSearchable
                  placeholder="Type a drug name"
                  options={drugs}
                  onChange={(selectedOption) => {
                    setDrugName(selectedOption)
                  }}
                  value={drugName}
                />
              </div>
            </div>

            <p className="text-bold mt-2 mb-2">Dosage Form</p>
            <div className="flex__container mb-3" style={{ gap: 40 }}>
              {['Capsules', 'Tablets', 'Suspension', 'Suppository'].map(
                (item, index) => (
                  <Form.Check
                    id={`${item}-${index + 1}`}
                    type="checkbox"
                    label={item}
                    key={index}
                    checked={item === drugDose}
                    onChange={(e) => setDrugDose(item)}
                  />
                ),
              )}
            </div>
            <p className="text-bold mt-2 mb-2">
              Drug strength (Number/weight or volume or other)
            </p>
            <div className="flex__container mb-3" style={{ gap: 100 }}>
              <div>
                <label className="label mb-2">Strength</label>
                <select
                  className="prescription__select"
                  value={drugStrength}
                  onChange={(e) => setDrugStrength(e.target.value)}
                >
                  <option value="">select</option>
                  <option>20</option>
                  <option>50</option>
                  <option>100</option>
                  <option>200</option>
                  <option>500</option>
                </select>
              </div>
              <div>
                <label className="label mb-2">Weight</label>
                <select
                  className="prescription__select"
                  value={drugWeight}
                  onChange={(e) => setDrugWeight(e.target.value)}
                >
                  <option value="">select</option>
                  <option>grams</option>
                  <option>miligrams</option>
                </select>
              </div>
              <div>
                <label className="label mb-2">Volume</label>
                <select
                  className="prescription__select"
                  value={drugVolume}
                  onChange={(e) => setDrugVolume(e.target.value)}
                >
                  <option value="">select</option>
                  <option>Drop</option>
                  <option>Drops</option>
                </select>
              </div>
            </div>
            <p className="text-bold mt-2 mb-2">Direction for use</p>
            <textarea
              className="form-control"
              placeholder="direction to use"
              value={drugDirectionUse}
              onChange={(e) => setDrugDirectionUse(e.target.value)}
            />
            <div className="flex__container">
              <div>
                <p
                  className="recommended__amount__content"
                  style={{ marginTop: 20 }}
                >
                  Quantity
                </p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="input some text"
                  value={drugQuantity}
                  onChange={(e) => setDrugQuantity(e.target.value)}
                />
              </div>
              <div>
                <p
                  className="recommended__amount__content"
                  style={{ marginTop: 20 }}
                >
                  Number of refills
                </p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="input some text"
                  value={drugRefill}
                  onChange={(e) => setDrugRefill(e.target.value)}
                />
              </div>
            </div>
          </div>
        </form>
        <div className="add__prescription">
          <span onClick={addDrugHandler}>
            {isEdit ? (
              <button className="btn">
                <i class="fa-solid fa-pen"></i> &nbsp;Edit Medication
              </button>
            ) : (
              <button className="btn">
                <i class="fa-solid fa-plus"></i> &nbsp;Add Medication
              </button>
            )}
          </span>
        </div>

        {/*Table   */}
        {dataToSend?.length > 0 ? (
          <div style={{ width: 1080, marginTop: 20 }}>
            <Table striped bordered hover style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th style={{ backgroundColor: '#E5E5E5' }}>Drug name</th>
                  <th style={{ backgroundColor: '#E5E5E5' }}>Dose</th>
                  <th style={{ backgroundColor: '#E5E5E5' }}>Strength</th>
                  <th style={{ backgroundColor: '#E5E5E5' }}>Weight</th>
                  <th style={{ backgroundColor: '#E5E5E5' }}>Volume</th>
                  <th style={{ backgroundColor: '#E5E5E5' }}>
                    Direction of use
                  </th>
                  <th style={{ backgroundColor: '#E5E5E5' }}>Quantity</th>
                  <th style={{ backgroundColor: '#E5E5E5' }}>No. of refills</th>
                  <th style={{ backgroundColor: '#E5E5E5', width: 50 }}>
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataToSend?.map((drug, index) => (
                  <tr key={index}>
                    <td>{drug.drug_name}</td>
                    <td>{drug.drug_dose}</td>
                    <td>{drug.drug_strength}</td>
                    <td>{drug.drug_weight}</td>
                    <td>{drug.drug_volume}</td>
                    <td>{drug.drug_direction_of_use}</td>
                    <td>{drug.drug_quantity}</td>
                    <td>{drug.drug_refill}</td>
                    <td>
                      <span
                        style={{ cursor: 'pointer', color: '#03dc83' }}
                        onClick={() => editHandler(index)}
                      >
                        <i class="fa-solid fa-pen-to-square"></i>
                      </span>
                      &nbsp; &nbsp;
                      <span
                        style={{ color: '#fe3548', cursor: 'pointer' }}
                        onClick={() => deleteHandler(index)}
                      >
                        <i class="fa-solid fa-trash"></i>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <></>
        )}

        <div className="flex__container" style={{ marginTop: 100 }}>
          <Form.Check
            id="agree"
            type="checkbox"
            label={`I confirm that I gave the above prescription to ${patientDetails?.name}`}
            onChange={(e) => agreement(e)}
          />
        </div>
        <div className="add__prescription" style={{ marginRight: 50 }}>
          {/* <Link to="/prescription-print-view"> */}
          <button
            className="prescription__send__btn"
            // disabled={!proceedAgreed}
            onClick={submitHandler}
          >
            Send
          </button>
          {/* </Link> */}
        </div>
      </div>

      {/***********************************
                Footer start
                ************************************/}
      <Footer />
      {/***********************************
                Footer end
                ************************************/}
    </div>
  )
}
export default DoctorPrescription
