import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectPatientData } from "../../redux_setup/slices/patientDataSlice";
// import { backgroundImage } from 'html2canvas/dist/types/css/property-descriptors/background-image'

const SuccessfulPage = () => {
  const patient = useSelector(selectPatientData)
  return (
    <div>
      <div
        className='signup-container'
        style={{
          background:
            "linear-gradient(180deg, rgba(69, 183, 87, 0.6) 0%, rgba(47, 165, 219, 0.6) 100%)",
        }}
      >
        <div style={{ marginLeft: "0" }} className='content-body'>
          <div className='card card pt-5 pb-3 signup-card'>
            <div className='d-flex justify-content-center align-items-center'>
              <p
                style={{
                  fontSize: 24,
                  fontWeight: 400,
                  fontFamily: "Lato",
                  color: "#19226D",
                  marginBottom: -20,
                }}
              >
                Welcome To
              </p>
            </div>
            <div className='text-center' style={{ width: "100%" }}>
              <div className='filipelogos' style={{ marginBottom: -20 }}></div>
              <p
                style={{
                  fontSize: 24,
                  fontWeight: 400,
                  fontFamily: "Lato",
                  color: "#19226D",
                }}
              >
                Patients Care Plan
              </p>
              <div style={{ marginTop: "6%" }}>
                <div
                  className='d-flex justify-content-center flex-wrap'
                  style={{ gap: "3%" }}
                >
                  <div
                    className='d-flex justify-content-center align-items-center'
                    style={{
                      width: 65,
                      height: 65,
                      borderRadius: "50%",
                      backgroundColor: "#45B757",
                      color: "#FFF",
                    }}
                  >
                    <i className='fas fa-check' style={{ fontSize: 32 }} />
                  </div>
                  <p
                    style={{
                      fontSize: 40,
                      fontWeight: 900,
                      fontFamily: "Lato",
                      color: "#45B757",
                    }}
                  >
                    Successful!
                  </p>
                </div>
                <div
                  className='card mt-3 mb-3'
                  style={{
                    width: "80%",
                    height: 1,
                    backgroundColor: "#45B757",
                    marginLeft: "10%",
                    marginRight: "10%",
                  }}
                />
                <div className='d-flex flex-wrap justify-content-center'>
                  <p
                    style={{
                      fontSize: 24,
                      fontWeight: 400,
                      fontFamily: "Lato",
                      color: "#19226D",
                    }}
                  >
                    Hi {`${patient?.firstName} ${patient?.lastName}`},
                  </p>
                </div>
                <div className='d-flex justify-content-center flex-wrap'>
                  <Button
                    className='mt-4 mr-2'
                    variant='success'
                    style={{ width: "15%", height: 40 }}
                  >
                    <Link
                      to='patient-dashboard'
                      style={{ color: "#FFF", whiteSpace: "nowrap" }}
                    >
                      Quit
                    </Link>
                  </Button>
                  <Button
                    className='mt-4 mb-btn'
                    variant='success'
                    style={{ width: "35%", height: 40 }}
                  >
                    <Link
                      to='/careplan-dashboard'
                      className='mb-btn'
                      style={{ color: "#FFF", whiteSpace: "nowrap" }}
                    >
                      Continue <i className='fas fa-arrow-right ml-1' />
                    </Link>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/***********************************
            Footer start
        ************************************/}
      {/***********************************
            Footer end
        ************************************/}
    </div>
  );
};

export default SuccessfulPage;
