import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import LeftBar from '../../components/LeftBar'
import MealProfile from '../../components/new-nutrition/Mealprofile'
import VitalProfile from '../../components/vitals/VitalProfile'
import { useDispatch, useSelector } from 'react-redux'
import {
  populateVitals,
  selectVitals,
} from '../../redux_setup/slices/hypertensionSlice'
import { selectUser } from '../../redux_setup/slices/userSlice'
import { ReduxActions } from '../../constants/redux_actions'
import moment from 'moment/moment'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Colors } from '../../constants/Colors'
import { Button } from 'react-bootstrap'
import { appendSlashforThreeDigits } from '../../constants/Essentials'
import { useInRouterContext, useLocation } from 'react-router-dom'
const TimeDiv = ({ bg }) => (
  <div className="ml-3">
    <div className="row" style={{ fontSize: 8 }}>
      <div className="col-6">
        <span className=" mt-1">{moment().format('hh:mm A')}</span>
      </div>
      <div className="col-6">
        <span className=" mt-1 ">
          {moment(new Date()).format('DD/MM/YYYY')}
        </span>
      </div>
    </div>
  </div>
)

const VitalsCarePlan = () => {
  const location = useLocation()
  const recent = location.state?.recent
  const [toggle, setToggle] = useState(true)
  const [toggleHeart, setToggleHeart] = useState(true)
  const [toggleWeight, setToggleWeight] = useState(true)
  const [toggleTemperature, setToggleTemperature] = useState(true)

  const vitalsState = useSelector(selectVitals)
  const [value, setValue] = useState(vitalsState.vitals_readings.blood_pressure)
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  toast.configure()

  const levelChanger = (value, subcategory) => {
    switch (subcategory) {
      case 'blood_pressure':
        const mm = value.split('/')[0]
        const hg = value.split('/')[1]
        if (parseInt(mm) < 90) {
          return 'Low'
        } else if (parseInt(mm) >= 90 && parseInt(mm) <= 120) {
          return 'Normal'
        } else if (parseInt(mm) > 120) {
          return 'High'
        } else if (parseInt(hg) < 60) {
          return 'Low'
        } else if (parseInt(hg) >= 60 && parseInt(hg) <= 80) {
          return 'Normal'
        } else {
          return 'High'
        }
      case 'heart_rate':
        if (parseInt(value) < 60) {
          return 'Low'
        } else if (parseInt(value) >= 60 && parseInt(value) <= 100) {
          return 'Normal'
        } else {
          return 'High'
        }

      case 'weight':
        if (parseInt(value) < 30) {
          return 'Low'
        } else if (parseInt(value) >= 30 && parseInt(value) <= 100) {
          return 'Normal'
        } else {
          return 'High'
        }

      case 'temperature':
        if (parseInt(value) < 36.1) {
          return 'Low'
        } else if (parseInt(value) >= 36.1 && parseInt(value) <= 37.5) {
          return 'Normal'
        } else {
          return 'High'
        }
      default:
        return ''
    }
  }

  const textBoxChangeHandler = (data, category, subcategory) => {
    dispatch(
      populateVitals({
        data: data,
        main_property: category,
        sub_property: subcategory,
        action_type: ReduxActions.add_property_in_property,
      }),
    )

    //change the labels accordingly
    const level = levelChanger(data, subcategory)
    if (level) {
      //update redux
      dispatch(
        populateVitals({
          data: level,
          main_property: category,
          sub_property: subcategory + '_level',
          action_type: ReduxActions.add_property_in_property,
        }),
      )
    }
  }

  const handleCareSubmit = () => {
    const dataToSend = {
      patient_id: user?.user_id,
      blood_pressure: vitalsState.vitals_readings.blood_pressure,
      heart_rate: vitalsState.vitals_readings.heart_rate,
      respiration_rate: vitalsState.vitals_readings.respiration_rate,
      temperature: vitalsState.stethoscopic_readings.temperature,
    }
    // return console.log(dataToSend)

    fetch(
      `${process.env.REACT_APP_MAIN_URL_HYPERTENSION}/api/careplans/hypertensionv1/vitals/`,
      {
        method: 'POST',
        headers: new Headers({
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(dataToSend),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }
        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        })

        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error has occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
  }
  const sendData = (prp, value) => {
    const dataToSend = {
      patient_id: user?.user_id,
    }
    dataToSend[prp] = value
    fetch(`${process.env.REACT_APP_MAIN_URL_HYPERTENSION}/api/patient/vitals`, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          })
        }
        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        })

        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error has occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
  }

  // Handling bp inputs
  function handleKeyUp(event) {
    const input = event.target
    let inputValue = input.value.replace(/[^0-9/]/g, '')

    // Check if the user pressed backspace or delete
    if (event.key === 'Backspace' || event.key === 'Delete') {
      // Remove the slash if it's in the third position
      // if (inputValue.length === 3 && inputValue[2] === '/') {
      //   inputValue = inputValue.slice(0, 2) + inputValue.slice(3)
      // }
    } else {
      // Append slash after third position
      if (inputValue.length === 3 && inputValue[2] !== '/') {
        inputValue = inputValue.slice(0, 3) + '/' + inputValue.slice(3)
      }
    }

    // Update the input value

    input.value = inputValue
    return inputValue
  }

  return (
    <div className="main-wrapper">
      {/***********************************
                  Header start
                  ************************************/}
      <Header />
      {/***********************************
                  Header end ti-comment-alt
                  ************************************/}
      {/***********************************
                  Sidebar start
                  ************************************/}
      <LeftBar />
      {/***********************************
                  Sidebar end
                  ************************************/}
      {/***********************************
                  Content body start
                  ************************************/}

      {/***********************************
                  Content body end
                  ************************************/}
      <div className="content-body">
        <div className="row">
          <div className="col-md-8 col-lg-8 col-sm-12 justify-content-between">
            <div className="card pt-3 pl-3">
              <div className="row">
                <div className="col-md-7 col-sm-12">
                  <div className="row pr-1">
                    <div className="col-sm-6 col-6">
                      <div
                        className="card"
                        style={{ backgroundColor: Colors.PURPLE_LIGHT }}
                      >
                        <div className="d-flex ml-2" style={{ gap: 10 }}>
                          <img
                            src={`${process.env.PUBLIC_URL}/images/icons/unlock.png`}
                            alt="Heart icon"
                            width={22}
                            height={20}
                            className="mt-3"
                          />
                          <p className="mt-2">B. Pressure</p>
                        </div>
                        <div
                          className="ml-2 mr-2"
                          style={{ height: 1, backgroundColor: '#818181' }}
                        />
                        <div className="d-flex justify-content-between ml-2 mr-2 mt-2">
                          <small style={{ color: '#74378d' }}>Systolic</small>
                          <small style={{ color: '#74378d' }}>Diastolic</small>
                        </div>
                        <h4 className="ml-3 mt-2">
                          {toggle ? (
                            <span>
                              {vitalsState.vitals_readings.blood_pressure}
                              <small style={{ fontSize: 11 }}> mm/Hg</small>
                            </span>
                          ) : (
                            <input
                              style={{ width: '98%', marginBottom: 3 }}
                              type="text"
                              value={value}
                              title="Note: / inserted automatically as you type"
                              maxLength="7"
                              onChange={(e) => {
                                const val = handleKeyUp(e)
                                setValue(val)
                                textBoxChangeHandler(
                                  val,
                                  'vitals_readings',
                                  'blood_pressure',
                                )
                              }}
                              // onKeyUp={handleKeyUp}
                            />
                          )}
                        </h4>
                        <TimeDiv bg={'#92003526'} />
                        <Button
                          style={{
                            backgroundColor: Colors.PURPLE,
                            borderColor: Colors.PURPLE,
                          }}
                          size="sm"
                          className="mt-1"
                          onClick={() => {
                            setToggle(!toggle)
                            !toggle &&
                              sendData(
                                'bp',
                                vitalsState.vitals_readings.blood_pressure,
                              )
                          }}
                        >
                          {toggle ? 'Update' : 'Submit'}
                        </Button>
                      </div>
                    </div>
                    <div className="col-sm-6 col-6">
                      <div
                        className="card"
                        style={{ backgroundColor: Colors.OCEAN_BLUE_LIGHT }}
                      >
                        <div className="d-flex ml-2" style={{ gap: 10 }}>
                          <img
                            src={`${process.env.PUBLIC_URL}/images/icons/heart-icon.png`}
                            alt="Heart icon"
                            width={22}
                            height={20}
                            className="mt-3"
                          />
                          <p className="mt-2">Heart Rate</p>
                        </div>
                        <div
                          className="ml-2 mr-2"
                          style={{ height: 1, backgroundColor: '#818181' }}
                        />
                        <div className="d-flex justify-content-between ml-2 mb-3 mt-2">
                          {/* <small style={{ color: '#74378d' }}>Systolic</small>
                          <small style={{ color: '#74378d' }}>Diastolic</small> */}
                        </div>
                        <h4 className="ml-3 mt-2">
                          {/* 105 <small style={{ fontSize: 14 }}>Beats/Min</small> */}
                          {toggleHeart ? (
                            <span>
                              {vitalsState.vitals_readings.heart_rate}
                              <small style={{ fontSize: 11 }}> Beats/min</small>
                            </span>
                          ) : (
                            <input
                              style={{ width: '90%', marginBottom: 3 }}
                              type="text"
                              value={vitalsState.vitals_readings.heart_rate}
                              // onBlur={() => setToggleHeart(!toggleHeart)}
                              maxlength="3"
                              onChange={(e) => {
                                const input = e.target
                                let inputValue = input.value.replace(
                                  /[^0-9/]/g,
                                  '',
                                )
                                inputValue = inputValue > 250 ? 250 : inputValue

                                textBoxChangeHandler(
                                  inputValue,
                                  'vitals_readings',
                                  'heart_rate',
                                )
                              }}
                            />
                          )}
                        </h4>
                        <TimeDiv bg={'#004A5B26'} />
                        <Button
                          style={{
                            backgroundColor: Colors.OCEAN_BLUE,
                            borderColor: Colors.OCEAN_BLUE,
                          }}
                          size="sm"
                          className="mt-1"
                          onClick={() => {
                            setToggleHeart(!toggleHeart)
                            !toggleHeart &&
                              sendData(
                                'heart_rate',
                                vitalsState.vitals_readings.heart_rate,
                              )
                          }}
                        >
                          {toggleHeart ? 'Update' : 'Submit'}
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* begin temperature and weight */}
                  <div className="row pr-1">
                    <div className="col-sm-6 col-6">
                      <div
                        className="card"
                        style={{
                          backgroundColor: Colors.DIM_PURPLE_BLUE_LIGHT,
                        }}
                      >
                        <div className="d-flex ml-2" style={{ gap: 10 }}>
                          <img
                            src={`${process.env.PUBLIC_URL}/images/icons/temperature.png`}
                            alt="Heart icon"
                            width={22}
                            height={20}
                            className="mt-3"
                            style={{ background: 'transparent' }}
                          />
                          <p className="mt-2">Temperature</p>
                        </div>
                        <div
                          className="ml-2 mr-2"
                          style={{ height: 1, backgroundColor: '#818181' }}
                        />
                        <h4 className="ml-3 mt-2">
                          {/* 105 <small style={{ fontSize: 14 }}>Beats/Min</small> */}
                          {toggleTemperature ? (
                            <span>
                              {vitalsState.stethoscopic_readings.temperature}
                              <small style={{ fontSize: 14 }}> &#8451;</small>
                            </span>
                          ) : (
                            <input
                              style={{ width: '90%', marginBottom: 3 }}
                              type="text"
                              value={
                                vitalsState.stethoscopic_readings.temperature
                              }
                              maxlength="2"
                              onChange={(e) => {
                                const input = e.target
                                let inputValue = input.value.replace(
                                  /[^0-9/]/g,
                                  '',
                                )
                                inputValue = inputValue > 50 ? 50 : inputValue
                                textBoxChangeHandler(
                                  inputValue,
                                  'stethoscopic_readings',
                                  'temperature',
                                )
                              }}
                            />
                          )}
                        </h4>
                        <TimeDiv bg={'#004A5B26'} />
                        <Button
                          style={{
                            backgroundColor: Colors.DIM_PURPLE_BLUE,
                            borderColor: Colors.DIM_PURPLE_BLUE,
                          }}
                          size="sm"
                          className="mt-1"
                          onClick={() => {
                            !toggleTemperature &&
                              sendData(
                                'temp',
                                vitalsState.stethoscopic_readings.temperature,
                              )
                            setToggleTemperature(!toggleTemperature)
                          }}
                        >
                          {toggleTemperature ? 'Update' : 'Submit'}
                        </Button>
                      </div>
                    </div>
                    <div className="col-sm-6 col-6">
                      <div
                        className="card"
                        style={{ backgroundColor: Colors.LIGHT_PURPLE_LIGHT }}
                      >
                        <div className="d-flex ml-2" style={{ gap: 10 }}>
                          <img
                            src={`${process.env.PUBLIC_URL}/images/icons/weight.jpg`}
                            alt="Heart icon"
                            width={22}
                            height={20}
                            className="mt-3"
                          />
                          <p className="mt-2">Weight</p>
                        </div>
                        <div
                          className="ml-2 mr-2"
                          style={{ height: 1, backgroundColor: '#818181' }}
                        />
                        <h4 className="ml-3 mt-2">
                          {/* 105 <small style={{ fontSize: 14 }}>Beats/Min</small> */}
                          {toggleWeight ? (
                            <span>
                              {vitalsState.vitals_readings.weight}
                              <small style={{ fontSize: 14 }}> Kg</small>
                            </span>
                          ) : (
                            <input
                              style={{ width: '90%', marginBottom: 3 }}
                              type="text"
                              value={vitalsState.vitals_readings.weight}
                              // onBlur={() => setToggleHeart(!toggleHeart)}
                              maxlength="3"
                              onChange={(e) => {
                                const input = e.target
                                let inputValue = input.value.replace(
                                  /[^0-9/]/g,
                                  '',
                                )
                                inputValue = inputValue > 700 ? 700 : inputValue
                                textBoxChangeHandler(
                                  inputValue,
                                  'vitals_readings',
                                  'weight',
                                )
                              }}
                            />
                          )}
                        </h4>
                        <TimeDiv bg={'#004A5B26'} />
                        <Button
                          style={{
                            backgroundColor: Colors.LIGHT_PURPLE,
                            borderColor: Colors.LIGHT_PURPLE,
                          }}
                          size="sm"
                          className="mt-1"
                          onClick={() => {
                            setToggleWeight(!toggleWeight)
                            !toggleWeight &&
                              sendData(
                                'weight',
                                vitalsState.vitals_readings.weight,
                              )
                          }}
                        >
                          {toggleWeight ? 'Update' : 'Submit'}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-sm-12">
            <VitalProfile
              temperature={vitalsState.stethoscopic_readings.temperature_level}
              weight={vitalsState.vitals_readings.weight_level}
              heart_rate={vitalsState.vitals_readings.heart_rate_level}
              blood_pressure={vitalsState.vitals_readings.blood_pressure_level}
              submitHandler={handleCareSubmit}
            />
          </div>
        </div>
      </div>

      {/***********************************
                  Footer start
                  ************************************/}
      <Footer />
      {/***********************************
                  Footer end
                  ************************************/}
    </div>
  )
}

export default VitalsCarePlan
