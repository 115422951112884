import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import LeftBar from '../components/LeftBar'
import DataTable from 'react-data-table-component'
import { Button, Col, Modal, Row, Table } from 'react-bootstrap'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import Preloader from '../components/Preloader'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { AddRoomSchema } from '../constants/ValidationSchema'
import { formatDate } from '@fullcalendar/react'
import { humanize, titleSentenceCase } from '../constants/Essentials'
import { useNavigate } from 'react-router-dom'
// import { $ } from 'react-jquery-plugin'
// import $ from 'jquery'
var $ = require('jquery')
// var dt = require('datatables.net')()
// import { dt } from 'datatables.net'
// var dt = require('datatables.net')
// require('imports?define=>false!datatables.net')(window, $);

const columns = [
  {
    name: 'No',
    selector: (row) => row.count,
    sortable: true,
  },
  {
    name: 'Patient',
    selector: (row) => row.patient.name,
    sortable: true,
  },
  {
    name: 'Recepient',
    selector: (row) => row.user?.name,
    sortable: true,
  },
  {
    name: 'Amount',
    selector: (row) => row.amount,
    sortable: true,
  },
  {
    name: 'Payment Type',
    selector: (row) => humanize(row.payment_type),
    sortable: true,
  },
  {
    name: 'Date',
    selector: (row) => formatDate(row.createdAt, { locale: 'es' }),
    sortable: true,
  },
]

const Ledger = () => {
  $.DataTable = require('datatables.net')
  $.DataTable = require('datatables.net-buttons')
  $.DataTable = require('datatables.net-buttons-dt')
  $.DataTable = require('jszip')

  $.DataTable = require('datatables.net-buttons/js/buttons.html5.js')
  $.DataTable = require('datatables.net-buttons/js/buttons.print.js')
  $.DataTable = require('datatables.net-buttons/js/buttons.flash.js')
  toast.configure()
  const [editShow, setEditShow] = useState(false) //edit modal
  const [addShow, setAddShow] = useState(false) //add modal
  const [dataToEdit, setDataToEdit] = useState({})
  const [loading, setLoading] = useState(true)
  const [ledgers, setLedgers] = useState([])
  const [allDoctors, setAllDoctors] = useState([])
  const [toDate, setToDate] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [filtersAppended, setFiltersAppended] = useState(false)
  const [ledgerTotal, setLedgerTotal] = useState(0)
  const [totalFromCash, setTotalFromCash] = useState(0)
  const [totalFromMpesa, setTotalFromMpesa] = useState(0)
  const [totalFromInsurance, setTotalFromInsurance] = useState(0)
  let navigate = useNavigate()

  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  const user = useSelector(selectUser)
  // $('#table_id').css('color', 'blue')
  // $('#table_id').DataTable()
  function fireDataTable() {
    if (!filtersAppended) {
      // $('#table_id thead tr')
      //   .clone(true)
      //   .addClass('filters')
      //   .appendTo('#table_id thead')
      // remove no
      $('.filters .narrow').text('')
    }
    $('#table_id').DataTable({
      dom: 'Bfrtip',
      buttons: [
        {
          text: 'Copy',
          extend: 'copy',
          className: 'btn btn-primary',
        },
        {
          text: 'Export To Excel',
          extend: 'excel',
          className: 'btn btn-success',
        },
        {
          text: 'Print',
          extend: 'print',
          className: 'btn btn-info',
        },
      ],
      orderCellsTop: true,
      fixedHeader: true,
      // initComplete: function () {
      //   var api = this.api()

      //   // For each column
      //   api
      //     .columns()
      //     .eq(0)
      //     .each(function (colIdx) {
      //       // Set the header cell to contain the input element
      //       var cell = $('.filters .searchable').eq(
      //         $(api.column(colIdx).header()).index(),
      //       )
      //       var title = $(cell).text()
      //       $(cell).html('<input type="text" placeholder="' + title + '" />')

      //       // On every keypress in this input
      //       $(
      //         'input',
      //         $('.filters th').eq($(api.column(colIdx).header()).index()),
      //       )
      //         .off('keyup change')
      //         .on('keyup change', function (e) {
      //           e.stopPropagation()

      //           // Get the search value
      //           $(this).attr('title', $(this).val())
      //           var regexr = '({search})' //$(this).parents('th').find('select').val();

      //           var cursorPosition = this.selectionStart
      //           // Search the column for that value
      //           api
      //             .column(colIdx)
      //             .search(
      //               this.value != ''
      //                 ? regexr.replace('{search}', '(((' + this.value + ')))')
      //                 : '',
      //               this.value != '',
      //               this.value == '',
      //             )
      //             .draw()

      //           $(this)
      //             .focus()[0]
      //             .setSelectionRange(cursorPosition, cursorPosition)
      //         })
      //     })
      // },
    })
  }

  function destroyDataTable() {
    $('#table_id').DataTable().destroy()
  }

  function fetchData(url) {
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        setLoading(false)
        if (res.error) {
          return toast.warning('An error occured while fetching rooms', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }
        let count = 1
        let total = 0
        let cash = 0
        let mpesa = 0
        let insurance = 0
        res.data.items.forEach((item) => {
          item.count = count
          count++
          total += parseFloat(item.amount)
          if (item.payment_type === 'cash') cash += +item.amount
          else if (item.payment_type === 'mpesa') mpesa += +item.amount
          else insurance += +item.amount
          setTotalFromCash(cash)
          setTotalFromInsurance(insurance)
          setTotalFromMpesa(mpesa)
        })
        setLedgerTotal(total)
        console.log('Ledgers=>', res.data.items)
        res.data?.items?.length > 0 && setLedgers(res.data.items)
        fireDataTable()
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
  }

  const adjustDates = () => {
    if (fromDate && toDate) {
      //triger state change
      destroyDataTable()
      // return console.log('fetching data', fromDate, toDate)
      const url = `${process.env.REACT_APP_MAIN_URL}/api/appointment/payment-process?dateTo=${toDate}&&dateFrom=${fromDate}`
      fetchData(url)
    }
  }

  useEffect(() => {
    const url = `${process.env.REACT_APP_MAIN_URL}/api/appointment/payment-process/?size=50`
    fetchData(url)
    setFiltersAppended(true)

    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  useEffect(() => {
    const fetchUsersUrl = `${process.env.REACT_APP_MAIN_URL}/api/user/health-center/?role=doctor&health_center_id=${user.hc_id}`

    fetch(fetchUsersUrl, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        res.data.items.forEach((item) => {
          item.value = item.id
          item.label = item.name
          // now I no longer need them
          delete item.id
          delete item.name
          //->will delete the rest later
        })

        setAllDoctors(res.data.items)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <div id="main-wrapper">
      {/***********************************
    Nav header start
************************************/}
      {/* <NavHeader/> */}
      {/***********************************
    Nav header end
************************************/}

      {/***********************************
    Header start
************************************/}
      <Header />
      {/***********************************
    Header end ti-comment-alt
************************************/}
      {/***********************************
    Sidebar start
************************************/}
      <LeftBar />
      {/***********************************
    Sidebar end
************************************/}
      {/***********************************
    Content body start
************************************/}
      <div className="content-body">
        <div className="card">
          <div className="card-header py-2">
            <h3>Ledger</h3>
          </div>
        </div>
        {loading ? (
          <Preloader />
        ) : (
          <Row>
            <Col md={12}>
              {/* <DataTable columns={columns} data={ledgers} pagination /> */}

              <Row className="mb-3">
                <Col md={8}>
                  <div className="form-inline p-2">
                    <div className="form-group">
                      <label htmlFor="date_from" className="mr-2">
                        {' '}
                        Date from
                      </label>
                      <input
                        type="date"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                        className="form-control bg-white"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="date_from" className="mr-2 ml-1">
                        {' '}
                        Date to
                      </label>
                      <input
                        type="date"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                        className="form-control bg-white"
                      />
                    </div>
                    <div className="form-group">
                      <button
                        className="btn btn-sm btn-dark ml-1"
                        onClick={adjustDates}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="form-inline p-2">
                    <div className="form-group">
                      <select className="form-control">
                        <option value="">Select doctor</option>
                        {allDoctors &&
                          allDoctors.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <button className="btn btn-sm btn-dark ml-1">
                        Search
                      </button>
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="flex__container">
                    <button className="btn btn-sm btn-primary">
                      {ledgerTotal != 0 &&
                        'Ledger Total: Ksh ' + ledgerTotal.toFixed(2)}
                    </button>
                    <button className="btn btn-sm btn-success">
                      {totalFromCash != 0 &&
                        'Total From Cash: Ksh ' + totalFromCash.toFixed(2)}
                    </button>
                    <button className="btn btn-sm btn-warning">
                      {totalFromMpesa != 0 &&
                        'Total From Mpesa: Ksh ' + totalFromMpesa.toFixed(2)}
                    </button>
                    <button className="btn btn-sm btn-danger">
                      {totalFromInsurance != 0 &&
                        'Total From Insurance: Ksh ' +
                          totalFromInsurance.toFixed(2)}
                    </button>
                  </div>
                </Col>
              </Row>

              <Table responsive className="table table-hover" id="table_id">
                <thead>
                  <tr>
                    <th className="narrow">No</th>
                    <th className="not-searchable">Patient</th>

                    <th className="not-searchable">Amount</th>
                    <th className="not-searchable">Mode</th>
                    <th className="not-searchable">Invoice</th>
                    <th className="not-searchable">Date</th>
                    <th className="not-searchable">Doctor</th>
                    <th className="not-searchable">Recipient</th>
                  </tr>
                </thead>
                <tbody>
                  {ledgers.map((ledger, index) => {
                    const invoice = JSON.parse(ledger.invoice)
                    return (
                      <tr key={index}>
                        <td className="narrow">{ledger?.count}</td>
                        <td>{titleSentenceCase(ledger?.patient?.name)}</td>
                        <td>Ksh {ledger?.amount}</td>
                        <td>{humanize(ledger?.payment_type)}</td>
                        <td>
                          {ledger?.payment_type === 'insurance' && (
                            <span
                              role="button"
                              onClick={() =>
                                navigate('/insurance-print', {
                                  state: { invoice, amount: ledger?.amount },
                                })
                              }
                            >
                              <i className="fa fa-print text-success"></i>
                              Print
                            </span>
                          )}
                        </td>
                        <td>
                          {formatDate(ledger?.createdAt, { locale: 'es' })}
                        </td>
                        <td>{ledger?.doctorId?.name}</td>
                        <td>{ledger?.receptionistId?.name}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
      </div>
      {/***********************************
    Content body end
************************************/}

      {/***********************************
    Footer start
************************************/}
      <Footer />
      {/***********************************
    Footer end
************************************/}
    </div>
  )
}

export default Ledger
