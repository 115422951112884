import React, { useState, useRef } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Counter from '../hypertension/Counter'
import Header from '../Header'
import Footer from '../Footer'
import LeftBar from '../LeftBar'
import { FoodChart } from './FoodChart'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateBasketFood,
  selectBasketFood,
} from '../../redux_setup/slices/basketFoodSlice'
import { snacks_data } from '../../constants/nutrition_data'
import Mealprofile from './Mealprofile'
import {
  selectBreakfast,
  selectDinner,
  selectLunch,
  selectSnacks,
  selectSummary,
  updateSummary,
} from '../../redux_setup/slices/nutritionBasketSlice'
const FoodBasketSummary = () => {
  const foodInBasket = useSelector(selectBasketFood)
  const breakfast = useSelector(selectBreakfast)
  const lunch = useSelector(selectLunch)
  const dinner = useSelector(selectDinner)
  const snacks = useSelector(selectSnacks)
  const combined = [...breakfast, ...lunch, ...dinner, ...snacks]
  const filteredcombined = combined.filter((fd) => fd?.count > 0)
  // console.log(combined, 'and', filteredcombined)
  return (
    <div className="main-wrapper">
      {/***********************************
                  Header start
                  ************************************/}
      <Header />
      {/***********************************
                  Header end ti-comment-alt
                  ************************************/}
      {/***********************************
                  Sidebar start
                  ************************************/}
      <LeftBar />
      {/***********************************
                  Sidebar end
                  ************************************/}
      {/***********************************
                  Content body start
                  ************************************/}

      {/***********************************
                  Content body end
                  ************************************/}
      <div className="content-body">
        <div className="d-flex" style={{ width: 300 }}>
          <Form.Select
            aria-label="Default select example"
            className="mr-2 pl-2"
          >
            <option>Open this select menu</option>
          </Form.Select>
          <Button
            className="mr-2"
            style={{
              backgroundColor: '#2FA5DB33',
              border: '1px solid #2FA5DB33',
              fontWeight: 700,
            }}
          >
            Today
          </Button>
          <div
            className=" circle"
            style={{
              border: '1px solid #242E75',
              width: 50,
              height: 32,
              paddingLeft: 10,
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/meal.png`}
              alt="meal icon"
              width={7.6}
              height={17}
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/meal-1.png`}
              alt="meal icon"
            />
            <div
              className="circle"
              style={{
                position: 'absolute',
                right: -5,
                top: -12,
                backgroundColor: '#662382',
                color: '#FFF',
                paddingBottom: 7,
                paddingLeft: 4,
                fontSize: 10,
                width: foodInBasket.length > 9 ? 20 : '',
                height: foodInBasket.length > 9 ? 20 : '',
                paddingTop: foodInBasket.length > 9 ? 2 : '',
              }}
            >
              {filteredcombined.length}
            </div>
          </div>
        </div>
        <p
          className="mt-3 mb-3"
          style={{
            fontSize: 20,
            fontFamily: 'Lato',
            fontWeight: 700,
            color: '#000',
          }}
        >
          Meals
        </p>
        <div className="row">
          <div
            className=" col-md-8 col-lg-8 col-sm-12 d-flex flex-wrap rounded"
            style={{ backgroundColor: '#fff', padding: 20, borderRadius: 8 }}
          >
            <div
              className="card pl-2 pt-2 pr-5"
              style={{ width: '100%', maxHeight: 'auto', height: 300 }}
            >
              <p className="meal-profile-text" style={{ color: '#000' }}>
                Breakfast
              </p>
              {['Yam', 'Smokie', 'Chapati', 'Whole Milk'].map((food, index) => (
                <>
                  <div
                    className="d-flex justify-content-between"
                    key={index}
                    style={{ width: '100%' }}
                  >
                    <p>{food}</p>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/icons/delete-icon.png`}
                      alt="delete icon"
                      width={10}
                      height={14}
                    />
                  </div>
                  <div
                    className="mb-1"
                    style={{ height: 1, backgroundColor: '#52575C26' }}
                  />
                </>
              ))}
              <div
                className="d-flex justify-content-end pt-3"
                style={{ cursor: 'pointer' }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/icons/edit-icon.png`}
                  alt="Edit icon"
                  width={13}
                  height={14}
                />
                <span
                  className="ml-2"
                  style={{ color: '#19226D', fontSize: 10 }}
                >
                  Edit
                </span>
              </div>
            </div>
            <div
              className="card pl-2 pt-2 pr-5"
              style={{ width: '100%', height: 300 }}
            >
              <p className="meal-profile-text" style={{ color: '#000' }}>
                Lunch
              </p>
              {['White Rice', 'Beans', 'Cabbage', 'Fruits(Water Melon)'].map(
                (food, index) => (
                  <>
                    <div
                      className="d-flex justify-content-between"
                      key={index}
                      style={{ width: '100%' }}
                    >
                      <p>{food}</p>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/icons/delete-icon.png`}
                        alt="delete icon"
                        width={10}
                        height={14}
                      />
                    </div>
                    <div
                      className="mb-1"
                      style={{ height: 1, backgroundColor: '#52575C26' }}
                    />
                  </>
                ),
              )}
              <div
                className="d-flex justify-content-end pt-3"
                style={{ cursor: 'pointer' }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/icons/edit-icon.png`}
                  alt="Edit icon"
                  width={13}
                  height={14}
                />
                <span
                  className="ml-2"
                  style={{ color: '#19226D', fontSize: 10 }}
                >
                  Edit
                </span>
              </div>
            </div>
            <div
              className="card pl-2 pt-2 pr-5"
              style={{ width: '100%', height: 300 }}
            >
              <p className="meal-profile-text" style={{ color: '#000' }}>
                Dinner
              </p>
              {['Brown Ugali', 'Managu', 'Pork', 'Fruits'].map(
                (food, index) => (
                  <>
                    <div
                      className="d-flex justify-content-between"
                      key={index}
                      style={{ width: '100%' }}
                    >
                      <p>{food}</p>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/icons/delete-icon.png`}
                        alt="delete icon"
                        width={10}
                        height={14}
                      />
                    </div>
                    <div
                      className="mb-1"
                      style={{ height: 1, backgroundColor: '#52575C26' }}
                    />
                  </>
                ),
              )}
              <div
                className="d-flex justify-content-end pt-3"
                style={{ cursor: 'pointer' }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/icons/edit-icon.png`}
                  alt="Edit icon"
                  width={13}
                  height={14}
                />
                <span
                  className="ml-2"
                  style={{ color: '#19226D', fontSize: 10 }}
                >
                  Edit
                </span>
              </div>
            </div>
            <div
              className="card pl-2 pt-2 pr-5"
              style={{ width: '100%', height: 200 }}
            >
              <p className="meal-profile-text" style={{ color: '#000' }}>
                Snacks
              </p>
              {['Roasted Maize', 'Fruits'].map((food, index) => (
                <>
                  <div
                    className="d-flex justify-content-between"
                    key={index}
                    style={{ width: '100%' }}
                  >
                    <p>{food}</p>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/icons/delete-icon.png`}
                      alt="delete icon"
                      width={10}
                      height={14}
                    />
                  </div>
                  <div
                    className="mb-1"
                    style={{ height: 1, backgroundColor: '#52575C26' }}
                  />
                </>
              ))}
              <div
                className="d-flex justify-content-end pt-3"
                style={{ cursor: 'pointer' }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/icons/edit-icon.png`}
                  alt="Edit icon"
                  width={13}
                  height={14}
                />
                <span
                  className="ml-2"
                  style={{ color: '#19226D', fontSize: 10 }}
                >
                  Edit
                </span>
              </div>
            </div>
            <div
              className="card pl-2 pt-2 pr-5"
              style={{ width: '100%', height: 200 }}
            >
              <p className="meal-profile-text" style={{ color: '#000' }}>
                Oild Used at Home
              </p>
              {['Olive Oil', 'Cooking Fat'].map((food, index) => (
                <>
                  <div
                    className="d-flex justify-content-between"
                    key={index}
                    style={{ width: '100%' }}
                  >
                    <p>{food}</p>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/icons/delete-icon.png`}
                      alt="delete icon"
                      width={10}
                      height={14}
                    />
                  </div>
                  <div
                    className="mb-1"
                    style={{ height: 1, backgroundColor: '#52575C26' }}
                  />
                </>
              ))}
              <div
                className="d-flex justify-content-end pt-3"
                style={{ cursor: 'pointer' }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/icons/edit-icon.png`}
                  alt="Edit icon"
                  width={13}
                  height={14}
                />
                <span
                  className="ml-2"
                  style={{ color: '#19226D', fontSize: 10 }}
                >
                  Edit
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-sm-12 card pb-3">
            <Mealprofile />
            <Button className="mt-2" style={{ backgroundColor: '#45B757' }}>
              Update Meal Profile
            </Button>
          </div>
        </div>
      </div>

      {/***********************************
                  Footer start
                  ************************************/}
      <Footer />
      {/***********************************
                  Footer end
                  ************************************/}
    </div>
  )
}

export default FoodBasketSummary
