import { useState } from "react";
import { Form } from "react-bootstrap";

const WeightLoss = ({ sendDataToParent }) => {
    const [currentWeight, setCurrentWeight] = useState(0)
    const [targetWeight, setTargetWeight] = useState(0)
    const [duration, setDuration] = useState(0)
    const [recommendation, setRecommendation] = useState(0)
    const [isCurrentWeightEditable, setIsCurrentWeightEditable] = useState(false)
    const [isTargetWeightEditable, setIsTargetWeightEditable] = useState(false)
    const [isDurationEditable, setIsDurationEditable] = useState(false)
    const [isRecommendationEditable, setIsRecommendationEditable] =
    useState(false);
  const handleSendData = () => {
    sendDataToParent({
      target_weight: targetWeight,
      duration_target_weight: duration,
      recommendation_target_weight: recommendation,
    });
  }
  return (
    <div className='card pt-4 pl-3' style={{ width: 654, height: 650 }}>
      <p
        style={{
          fontWeight: 400,
          fontFamily: "Lato",
          fontSize: 16,
          color: "#000",
        }}
      >
        Weight Loss
      </p>
      <div className='d-flex' style={{ gap: 30 }}>
        <div
          className='card pt-3 pl-3 pr-2 pb-3'
          style={{
            width: 288,
            backgroundColor: "#D0E0FF80",
          }}
        >
          <div
            className='card'
            style={{
              width: 255,
              height: 35,
              backgroundColor: "#0F56B333",
              textAlign: "center",
            }}
          >
            <p
              className='mt-1'
              style={{
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Current weight
            </p>
          </div>
          {isCurrentWeightEditable ? (
            <Form.Control
              placeholder='Enter weight'
              value={currentWeight}
              onChange={(e) => e.target.value}
              onBlur={() => setIsCurrentWeightEditable(false)}
            />
          ) : (
            <div className='d-flex' style={{ gap: 15 }}>
              <span
                style={{
                  fontSize: 55,
                  fontFamily: "Lato",
                  fontWeight: 700,
                  color: "#19226D",
                }}
              >
                120
              </span>
              <span
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "Lato",
                  color: "#000",
                  }}
                  className='mt-5'
              >
                Kg
              </span>
            </div>
          )}
          <div className='d-flex justify-content-end'>
            <span
              style={{
                fontSize: 10,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Updated on: 13/12/2022
            </span>
          </div>
          <div
            className='mt-2 mr-2 mb-2'
            style={{ backgroundColor: "#000", width: 252, height: 1 }}
          />
          <div
            style={{
              width: 252,
              height: 29,
              borderRadius: 2,
              backgroundColor: "#FFF",
              textAlign: "center",
            }}
          >
            <p>
              Prev weight: 120/115mmHg
              <i class='fas-solid fas-arrow-up-right-from-square'></i>
            </p>
          </div>
        </div>
        <div
          className='card pt-3 pl-3 pr-2 pb-3'
          style={{
            width: 288,
            backgroundColor: "#D0E0FF80",
          }}
        >
          <div
            className='card'
            style={{
              width: 255,
              height: 35,
              backgroundColor: "#0F56B333",
              textAlign: "center",
            }}
          >
            <p
              className='mt-1'
              style={{
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Target weight
            </p>
          </div>
          {isTargetWeightEditable ? (
            <Form.Control
              placeholder='Type weight here'
              value={targetWeight}
              onChange={(e) => setTargetWeight(e.target.value)}
              onBlur={() => {
                setIsTargetWeightEditable(false);
                handleSendData()
              }}
            />
          ) : (
            <div className='d-flex' style={{ gap: 15 }}>
              <span
                style={{
                  fontSize: 55,
                  fontFamily: "Lato",
                  fontWeight: 700,
                  color: "#19226D",
                }}
              >
                {targetWeight}
              </span>
              <span
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "Lato",
                  color: "#000",
                  }}
                  className='mt-5'
              >
                Kg
              </span>
            </div>
          )}
          <div className='d-flex justify-content-end'>
            <span
              style={{
                fontSize: 10,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Updated on: 13/12/2022
            </span>
          </div>
          <div
            className='mt-2 mr-2 mb-2'
            style={{ backgroundColor: "#000", width: 252, height: 1 }}
          />
          <div className='d-flex justify-content-between'>
            <div style={{ cursor: "pointer" }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/edit-icon.png`}
                alt='Edit icon'
                width={13}
                height={14}
              />
              <span
                className='ml-2'
                style={{ color: "#19226D", fontSize: 10 }}
                onClick={() => setIsTargetWeightEditable(true)}
              >
                Edit
              </span>
            </div>
            <div
              style={{
                width: 150,
                height: 29,
                borderRadius: 2,
                backgroundColor: "#FFF",
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "Lato",
                  color: "#000",
                }}
              >
                Target loss 50kgs
                <i class='fas-solid fas-arrow-up-right-from-square'></i>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className='mt-2 mr-2 mb-4'
        style={{ backgroundColor: "#000", width: 604, height: 1 }}
      />
      <div className='d-flex' style={{ gap: 30 }}>
        <div
          className='card pt-3 pl-3 pr-2 pb-3'
          style={{
            width: 288,
            backgroundColor: "#D0E0FF80",
          }}
        >
          <div
            className='card'
            style={{
              width: 255,
              height: 35,
              backgroundColor: "#0F56B333",
              textAlign: "center",
            }}
          >
            <p
              className='mt-1'
              style={{
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Duration
            </p>
          </div>
          {isDurationEditable ? (
            <Form.Control
              placeholder='Type duration'
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              onBlur={() => {
                setIsDurationEditable(false);
                handleSendData()
              }}
            />
          ) : (
            <div className='d-flex' style={{ gap: 15 }}>
              <span
                style={{
                  fontSize: 55,
                  fontFamily: "Lato",
                  fontWeight: 700,
                  color: "#19226D",
                }}
              >
                {duration}
              </span>
              <span
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "Lato",
                  color: "#000",
                  }}
                  className='mt-5'
              >
                Weeks
              </span>
            </div>
          )}
          <div className='d-flex justify-content-end'>
            <span
              style={{
                fontSize: 10,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Updated on: 13/12/2022
            </span>
          </div>
          <div
            className='mt-2 mr-2 mb-2'
            style={{ backgroundColor: "#000", width: 252, height: 1 }}
          />
          <div className='d-flex justify-content-between'>
            <div style={{ cursor: "pointer" }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/edit-icon.png`}
                alt='Edit icon'
                width={13}
                height={14}
              />
              <span
                className='ml-2'
                style={{ color: "#19226D", fontSize: 10 }}
                onClick={() => setIsDurationEditable(true)}
              >
                Edit
              </span>
            </div>
            <div
              style={{
                width: 71,
                height: 29,
                borderRadius: 2,
                backgroundColor: "#FFF",
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "Lato",
                  color: "#000",
                }}
              >
                36/36
                <i class='fas-solid fas-arrow-up-right-from-square'></i>
              </p>
            </div>
          </div>
        </div>
        <div
          className='card pt-3 pl-3 pr-2 pb-3'
          style={{
            width: 288,
            backgroundColor: "#D0E0FF80",
          }}
        >
          <div
            className='card'
            style={{
              width: 255,
              height: 35,
              backgroundColor: "#D60A5180",
              textAlign: "center",
            }}
          >
            <p
              className='mt-1'
              style={{
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Recommendation
            </p>
          </div>
          {isRecommendationEditable ? (
            <Form.Control
              placeholder='Type recommendation'
              value={recommendation}
              onChange={(e) => setRecommendation(e.target.value)}
              onBlur={() => {
                setIsRecommendationEditable(false);
                handleSendData()
              }}
            />
          ) : (
            <div className='d-flex' style={{ gap: 15 }}>
              <span
                style={{
                  fontSize: 64,
                  fontFamily: "Lato",
                  fontWeight: 700,
                  color: "#D60A5133",
                }}
              >
                {recommendation}
              </span>
              <span
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "Lato",
                  color: "#000",
                  }}
                  className='mt-5'
              >
                Kg/week
              </span>
            </div>
          )}
          <div className='d-flex justify-content-end'>
            <span
              style={{
                fontSize: 10,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Updated on: 13/12/2022
            </span>
          </div>
          <div
            className='mt-2 mr-2 mb-2'
            style={{ backgroundColor: "#000", width: 252, height: 1 }}
          />
          <div className='d-flex justify-content-between'>
            <div style={{ cursor: "pointer" }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/edit-icon.png`}
                alt='Edit icon'
                width={13}
                height={14}
              />
              <span
                className='ml-2'
                style={{ color: "#19226D", fontSize: 10 }}
                onClick={() => setIsRecommendationEditable(true)}
              >
                Edit
              </span>
            </div>
            <div
              style={{
                width: 111,
                height: 29,
                borderRadius: 2,
                backgroundColor: "#FFF",
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "Lato",
                  color: "#000",
                }}
              >
                1.5Kgs added
                <i class='fas-solid fas-arrow-up-right-from-square'></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeightLoss;
