import React from 'react'
import moment from 'moment';
import { Button } from 'react-bootstrap';
const RxDetails = () => {
  return (
    <div className='card pt-5 pl-4'>
      <p
        style={{
          fontSize: 20,
          fontWeight: 700,
          color: "#45B757",
          fontFamily: "Lato",
        }}
        className='mt-4 mb-5'
      >
        Rx #123445
      </p>
      <div
        className='mb-3'
        style={{ width: "95%", height: 2, backgroundColor: "#E8E8E8" }}
      />
      {/* Doctor identification Section */}
      <div className='d-flex flex-wrap justify-content-between mt-5'>
        <div className='d-flex' style={{ gap: 30 }}>
          <img
            src={`${process.env.PUBLIC_URL}/images/clinicklogo.png`}
            width={139}
            height={53}
            alt='clinic logo'
          />
          <div className='flex__block'>
            <span className='header__title' style={{ color: "#25282B" }}>
              Street Name, Suit 00, State,
              <br />
              Zip Code, Country
            </span>
            <span className='header__title'>
              <span style={{ color: "#45B757" }}>t:&nbsp;&nbsp;</span>+123 00
              456 7890
            </span>
            <span className='header__title'>
              <span style={{ color: "#45B757" }}>e:&nbsp;&nbsp;</span>
              email@doctorschamber.com
            </span>
          </div>
        </div>
        <div className='d-flex mr-5' style={{ gap: 30 }}>
          <div>
            <p
              style={{
                fontSize: 16,
                fontWeight: 700,
                fontFamily: "Lato",
                color: "#2FA5DB",
              }}
            >
              DOCTOR DETAILS
            </p>
            <p
              style={{
                fontSize: 16,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              NAME: DR. FRED WANJALA
            </p>
            <p
              style={{
                fontSize: 16,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              REG: 1234567890
            </p>
            <img
              src={`${process.env.PUBLIC_URL}/images/doctor-signature.png`}
              width={124}
              height={74}
            />
            <p
              style={{
                fontSize: 16,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              {moment().format("MM/DD/YYYY")}
            </p>
          </div>
          <Button
            className='mt-5'
            style={{
              background: "rgba(110, 227, 11, 0.24)",
              border: "1px rgba(110, 227, 11, 0.24) solid",
              width: "94px",
              height: 40,
              color: "#000",
            }}
          >
            Available
          </Button>
        </div>
      </div>
      {/* patient details section */}
      <div className='d-flex flex-wrap justify-content-between'>
        <div>
          <p
            style={{
              fontWeight: 400,
              fontSize: 20,
              fontFamily: "Lato",
              color: "#2FA5DB",
            }}
          >
            PATIENT’S DETAILS
          </p>
          <div>
            <div className='d-flex' style={{ gap: 30 }}>
              <p style={{ fontSize: 16, fontWeight: 700 }}>Name</p>
              <p style={{ fontSize: 14, fontWeight: 400 }}>Patient One</p>
            </div>
            <div className='d-flex' style={{ gap: 50 }}>
              <p style={{ fontSize: 16, fontWeight: 700 }}>Age</p>
              <p style={{ fontSize: 14, fontWeight: 400 }}>50</p>
            </div>
            <div className='d-flex' style={{ gap: 50 }}>
              <p style={{ fontSize: 16, fontWeight: 700 }}>Sex</p>
              <p style={{ fontSize: 14, fontWeight: 400 }}>Female</p>
            </div>
            <div className='d-flex' style={{ gap: 20 }}>
              <p style={{ fontSize: 16, fontWeight: 700 }}>Weight</p>
              <p style={{ fontSize: 14, fontWeight: 400 }}>68kgs</p>
            </div>
          </div>
        </div>
        <div className='mt-5'>
          <div className='d-flex' style={{ gap: 30 }}>
            <p style={{ fontSize: 16, fontWeight: 700 }}>BMI</p>
            <p style={{ fontSize: 14, fontWeight: 400 }}>20.29</p>
          </div>
          <div className='d-flex' style={{ gap: 30 }}>
            <p style={{ fontSize: 16, fontWeight: 700 }}>Diagnosis</p>
            <p style={{ fontSize: 14, fontWeight: 400 }}>Type 2 Diabetes</p>
          </div>
          <div className='d-flex' style={{ gap: 30 }}>
            <p style={{ fontSize: 16, fontWeight: 700 }}>
              Underlying Conditions
            </p>
            <p style={{ fontSize: 14, fontWeight: 400 }}>Hypertension</p>
          </div>
          <div className='d-flex' style={{ gap: 30 }}>
            <p style={{ fontSize: 16, fontWeight: 700 }}>Last Prescription</p>
            <p style={{ fontSize: 14, fontWeight: 400 }}>Amoxil 500mg Tabs</p>
          </div>
        </div>
        <div className='mt-5 mr-5'>
          <div className='d-flex' style={{ gap: 30 }}>
            <p style={{ fontSize: 16, fontWeight: 700 }}>Current Medication</p>
            <p style={{ fontSize: 14, fontWeight: 400 }}>None</p>
          </div>
          <div className='d-flex' style={{ gap: 30 }}>
            <p style={{ fontSize: 16, fontWeight: 700 }}>
              No. of Prescribes Refills
            </p>
            <p style={{ fontSize: 14, fontWeight: 400 }}>1</p>
          </div>
          <div className='d-flex' style={{ gap: 30 }}>
            <p style={{ fontSize: 16, fontWeight: 700 }}>
              Prev. No. of Refills
            </p>
            <p style={{ fontSize: 14, fontWeight: 400 }}>5</p>
          </div>
          <div className='d-flex' style={{ gap: 30 }}>
            <p style={{ fontSize: 16, fontWeight: 700 }}>
              Pending No. of Refills
            </p>
            <p style={{ fontSize: 14, fontWeight: 400 }}>None</p>
          </div>
        </div>
      </div>
      <div
        className='mb-3'
        style={{ width: "95%", height: 2, backgroundColor: "#E8E8E8" }}
      />
      <div className='d-flex' style={{ gap: 30 }}>
        <p style={{ fontSize: 16, fontWeight: 700 }}>Phone number</p>
        <p style={{ fontSize: 14, fontWeight: 400 }}>+254 712 345 678</p>
      </div>
      <div className='d-flex' style={{ gap: 30 }}>
        <p style={{ fontSize: 16, fontWeight: 700 }}>Email address</p>
        <p style={{ fontSize: 14, fontWeight: 400 }}>patientone@gmail.com</p>
      </div>
    </div>
  );
}

export default RxDetails