import { useState } from "react";
import { Form } from "react-bootstrap";

const Dietary = ({ sendDataToParent }) => {
  const [currentWeight, setCurrentWeight] = useState(0);
  const [targetCalories, setTargetCalories] = useState(0);
  const [durationTargetCalories, setDurationTargetCalories] = useState(0);
  const [recommendationTargetCalories, setRecommendationTargetCalories] = useState(0);
  const [isCurrentCaloriesEditable, setIsCurrentWeightEditable] = useState(false);
  const [isTargetCaloriesEditable, setIsTargetCaloriesEditable] =
    useState(false);
  const [isDurationCaloriesEditable, setIsDurationCaloriesEditable] = useState(false);
  const [isRecommendationCaloriesEditable, setIsRecommendationEditable] =
    useState(false);
  const handleSendData = () => {
    sendDataToParent({
      target_calories: targetCalories,
      duration_target_calories: durationTargetCalories,
      recommendation_target_calories: recommendationTargetCalories,
    });
  };
  return (
    <div className='card pt-4 pl-3' style={{ width: 654, height: 650 }}>
      <p
        style={{
          fontWeight: 400,
          fontFamily: "Lato",
          fontSize: 16,
          color: "#000",
        }}
      >
        Weight Loss
      </p>
      <div className='d-flex' style={{ gap: 30 }}>
        <div
          className='card pt-3 pl-3 pr-2 pb-3'
          style={{
            width: 288,
            backgroundColor: "#D0E0FF80",
          }}
        >
          <div
            className='card'
            style={{
              width: 255,
              height: 35,
              backgroundColor: "#0F56B333",
              textAlign: "center",
            }}
          >
            <p
              className='mt-1'
              style={{
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Current Calorie Intake
            </p>
          </div>
          {isCurrentCaloriesEditable ? (
            <Form.Control
              placeholder='Enter weight'
              value={targetCalories}
              onChange={(e) => e.target.value}
              onBlur={() => {
                setIsCurrentWeightEditable(false);
              }}
            />
          ) : (
            <div className='d-flex' style={{ gap: 15 }}>
              <span
                style={{
                  fontSize: 55,
                  fontFamily: "Lato",
                  fontWeight: 700,
                  color: "#19226D",
                }}
              >
                120
              </span>
              <span
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "Lato",
                  color: "#000",
                }}
                className='mt-5'
              >
                Kcal
              </span>
            </div>
          )}
          <div className='d-flex justify-content-end'>
            <span
              style={{
                fontSize: 10,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Updated on: 13/12/2022
            </span>
          </div>
          <div
            className='mt-2 mr-2 mb-2'
            style={{ backgroundColor: "#000", width: 252, height: 1 }}
          />
          <div
            style={{
              width: 252,
              height: 29,
              borderRadius: 2,
              backgroundColor: "#FFF",
              textAlign: "center",
            }}
          >
            <p>
              Prev weight: 120/115mmHg
              <i class='fas-solid fas-arrow-up-right-from-square'></i>
            </p>
          </div>
        </div>
        <div
          className='card pt-3 pl-3 pr-2 pb-3'
          style={{
            width: 288,
            backgroundColor: "#D0E0FF80",
          }}
        >
          <div
            className='card'
            style={{
              width: 255,
              height: 35,
              backgroundColor: "#0F56B333",
              textAlign: "center",
            }}
          >
            <p
              className='mt-1'
              style={{
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Reduce Calorie Intake
            </p>
          </div>
          {isTargetCaloriesEditable ? (
            <Form.Control
              placeholder='Type calorie here'
              value={targetCalories}
              onChange={(e) => setTargetCalories(e.target.value)}
              onBlur={() => {
                setIsTargetCaloriesEditable(false);
                handleSendData();
              }}
            />
          ) : (
            <div className='d-flex' style={{ gap: 15 }}>
              <span
                style={{
                  fontSize: 55,
                  fontFamily: "Lato",
                  fontWeight: 700,
                  color: "#19226D",
                }}
              >
                {targetCalories}
              </span>
              <span
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "Lato",
                  color: "#000",
                }}
                className='mt-5'
              >
                Kcal
              </span>
            </div>
          )}
          <div className='d-flex justify-content-end'>
            <span
              style={{
                fontSize: 10,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Updated on: 13/12/2022
            </span>
          </div>
          <div
            className='mt-2 mr-2 mb-2'
            style={{ backgroundColor: "#000", width: 252, height: 1 }}
          />
          <div className='d-flex justify-content-between'>
            <div style={{ cursor: "pointer" }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/edit-icon.png`}
                alt='Edit icon'
                width={13}
                height={14}
              />
              <span
                className='ml-2'
                style={{ color: "#19226D", fontSize: 10 }}
                onClick={() => setIsTargetCaloriesEditable(true)}
              >
                Edit
              </span>
            </div>
            <div
              style={{
                width: 150,
                height: 29,
                borderRadius: 2,
                backgroundColor: "#FFF",
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "Lato",
                  color: "#000",
                }}
              >
                Target deficit 800
                <i class='fas-solid fas-arrow-up-right-from-square'></i>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className='mt-2 mr-2 mb-4'
        style={{ backgroundColor: "#000", width: 604, height: 1 }}
      />
      <div className='d-flex' style={{ gap: 30 }}>
        <div
          className='card pt-3 pl-3 pr-2 pb-3'
          style={{
            width: 288,
            backgroundColor: "#D0E0FF80",
          }}
        >
          <div
            className='card'
            style={{
              width: 255,
              height: 35,
              backgroundColor: "#0F56B333",
              textAlign: "center",
            }}
          >
            <p
              className='mt-1'
              style={{
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Duration
            </p>
          </div>
          {isDurationCaloriesEditable ? (
            <Form.Control
              placeholder='Type duration'
              value={durationTargetCalories}
              onChange={(e) => setDurationTargetCalories(e.target.value)}
              onBlur={() => {
                setIsDurationCaloriesEditable(false);
                handleSendData();
              }}
            />
          ) : (
            <div className='d-flex' style={{ gap: 15 }}>
              <span
                style={{
                  fontSize: 55,
                  fontFamily: "Lato",
                  fontWeight: 700,
                  color: "#19226D",
                }}
              >
                {durationTargetCalories}
              </span>
              <span
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "Lato",
                  color: "#000",
                }}
                className='mt-5'
              >
                Weeks
              </span>
            </div>
          )}
          <div className='d-flex justify-content-end'>
            <span
              style={{
                fontSize: 10,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Updated on: 13/12/2022
            </span>
          </div>
          <div
            className='mt-2 mr-2 mb-2'
            style={{ backgroundColor: "#000", width: 252, height: 1 }}
          />
          <div className='d-flex justify-content-between'>
            <div style={{ cursor: "pointer" }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/edit-icon.png`}
                alt='Edit icon'
                width={13}
                height={14}
              />
              <span
                className='ml-2'
                style={{ color: "#19226D", fontSize: 10 }}
                onClick={() => setIsDurationCaloriesEditable(true)}
              >
                Edit
              </span>
            </div>
            <div
              style={{
                width: 71,
                height: 29,
                borderRadius: 2,
                backgroundColor: "#FFF",
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "Lato",
                  color: "#000",
                }}
              >
                36/36
                <i class='fas-solid fas-arrow-up-right-from-square'></i>
              </p>
            </div>
          </div>
        </div>
        <div
          className='card pt-3 pl-3 pr-2 pb-3'
          style={{
            width: 288,
            backgroundColor: "#D0E0FF80",
          }}
        >
          <div
            className='card'
            style={{
              width: 255,
              height: 35,
              backgroundColor: "#D60A5180",
              textAlign: "center",
            }}
          >
            <p
              className='mt-1'
              style={{
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Recommendation
            </p>
          </div>
          {isRecommendationCaloriesEditable ? (
            <Form.Control
              placeholder='Type recommendation'
              value={recommendationTargetCalories}
              onChange={(e) => setRecommendationTargetCalories(e.target.value)}
              onBlur={() => {
                setIsRecommendationEditable(false);
                handleSendData();
              }}
            />
          ) : (
            <div className='d-flex' style={{ gap: 15 }}>
              <span
                style={{
                  fontSize: 64,
                  fontFamily: "Lato",
                  fontWeight: 700,
                  color: "#D60A5133",
                }}
              >
                {recommendationTargetCalories}
              </span>
              <span
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "Lato",
                  color: "#000",
                }}
                className='mt-5'
              >
                Kg/week
              </span>
            </div>
          )}
          <div className='d-flex justify-content-end'>
            <span
              style={{
                fontSize: 10,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Updated on: 13/12/2022
            </span>
          </div>
          <div
            className='mt-2 mr-2 mb-2'
            style={{ backgroundColor: "#000", width: 252, height: 1 }}
          />
          <div className='d-flex justify-content-between'>
            <div style={{ cursor: "pointer" }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/edit-icon.png`}
                alt='Edit icon'
                width={13}
                height={14}
              />
              <span
                className='ml-2'
                style={{ color: "#19226D", fontSize: 10 }}
                onClick={() => setIsRecommendationEditable(true)}
              >
                Edit
              </span>
            </div>
            <div
              style={{
                width: 111,
                height: 29,
                borderRadius: 2,
                backgroundColor: "#FFF",
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "Lato",
                  color: "#000",
                }}
              >
                1.5Kgs added
                <i class='fas-solid fas-arrow-up-right-from-square'></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dietary;
