import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import MainApp from './calendar/MainApp'
import AppointmentActivity from './AppointmentActivity'
import DashboardCards from './DashboardCards'

const Doctor_ = () => {
  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  const user = useSelector(selectUser)
  const [appointments, setAppointments] = useState([])
  const [appointmentsToday, setAppointmentsToday] = useState([])

  const [dashboardItems, setDashboardItems] = useState([])
  const fetchAppointmentsUrl =
    user.role === 'Patient'
      ? `${process.env.REACT_APP_MAIN_URL}/api/appointment/patient/${user.user_id}?size=30`
      : user.role === 'DOCTOR'
      ? `${process.env.REACT_APP_MAIN_URL}/api/doctor/appointments?size=100`
      : `${process.env.REACT_APP_MAIN_URL}/api/user/appointments-admin?doctor_id=${user.user_id}&size=30`
  useEffect(() => {
    fetch(fetchAppointmentsUrl, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.data.items)
        res.data.items.forEach((item) => {
          // item.appointment_id = item.id;
          // item.id = count;
          item.title =
            user.role === 'Patient' ? item?.doctorId?.name : item?.patient?.name
          const dt = new Date(item.date)
          // item.value = item.id;
          // item.start = ;
          // const slot = "T"+item.time_slot.substring(0, str.length - 2)
          item.start = String(
            dt.toISOString().replace(/T.*$/, '') + 'T' + item.time_slot,
          )
          // item.start = "2022-02-01T16:38";

          //->will delete the rest later
        })

        // set appointments today
        // console.log(res.data.items[0].date);
        const appointmentsT = res?.data?.items.filter(
          (item) =>
            new Date(item.date).toISOString().replace(/T.*$/, '') ===
            new Date().toISOString().replace(/T.*$/, ''),
        )
        console.log(appointmentsT)
        setAppointmentsToday(appointmentsT)
        console.log('urefu', appointmentsT)
        // console.log(res.data);

        res.data?.items?.length > 0 && setAppointments(res.data.items)
      })
      .catch((err) => {
        console.log(err, user.token)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  // fetch dashboard items
  useEffect(() => {
    fetch(`${process.env.REACT_APP_MAIN_URL}/api/dashboard/dashboard`, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.data, 'mbwakni')
        setDashboardItems(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  return (
    <div className="content-body">
      {/* row */}

      {/* <div className="container-fluid">
        <div className="form-head page-titles d-flex mb-md-4">
          <div className="mr-auto">
            <h2 className="text-black font-w600">Doctor</h2>
          </div>
        </div>
      </div> */}

      <DashboardCards
        bookedAppointments={dashboardItems[0]?.total || 0}
        upcAppointments={dashboardItems[1]?.total || 0}
        activePatients={dashboardItems[2]?.total || 0}
        newPatients={dashboardItems[3]?.total || 0}
      />

      <MainApp appointments={appointments} today={appointmentsToday} />
      {/* <AppointmentActivity /> */}
    </div>
  )
}

export default Doctor_
