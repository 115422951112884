import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../redux_setup/slices/userSlice";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";
import Preloader from "../components/Preloader";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import LeftBar from "../components/LeftBar";
import Header from "../components/Header";
import Footer from "../components/Footer";

const columns = [
  {
    name: "HealthCenterName",
    selector: (row) => row.HealthCenterName,
  },
  {
    name: "DoctorName",
    selector: (row) => row.DoctorName,
  },
  {
    name: "PatientName",
    selector: (row) => row.PatientName,
  },
  {
    name: "Date",
    selector: (row) => row.Date,
  },
  {
    name: "TimeSlot",
    selector: (row) => row.TimeSlot,
  },
  {
    name: "ReasonToVisit",
    selector: (row) => row.ReasonToVisit,
  },
  {
    name: "DoctorConsultationNotes",
    selector: (row) => row.DoctorConsultationNotes,
  },
  {
    name: "DoctorConsultationDiagnosis",
    selector: (row) => row.DoctorConsultationDiagnosis,
  },
  {
    name: "DoctorConsultationPrescription",
    selector: (row) => row.DoctorConsultationPrescription,
  },
  {
    name: "DoctorConsultationExtraNotes",
    selector: (row) => row.DoctorConsultationExtraNotes,
  },
];
const TerminateAccount = () => {
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [doctors, setDoctors] = useState([]);
  const [appts, setAppts] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [checkRadio, setCheckRadio] = useState("Healthcenter");
  const [url, setUrl] = useState(
    `${process.env.REACT_APP_MAIN_URL3}/api/health-center/export?health_center_id=3eb63c7e-e733-4481-9bec-6f756f3d9fb7`
  );
  const [apptsHeader, setApptsHeader] = useState([
    {
      label: "Health Center Name",
      key: "healthcentername",
    },
    {
      label: "Doctor Name",
      key: "doctor name",
    },
    {
      label: "Patient Name",
      key: "patient name",
    },
    {
      label: "Date",
      key: "date",
    },
    {
      label: "Time Slot",
      key: "time slot",
    },
    {
      label: "Reason To Visit",
      key: "reason to visit",
    },
    {
      label: "Doctor consultation Notes",
      key: "health center",
    },
    {
      label: "Doctor Consultation Diagnosis",
      key: "doctor consultation diagnosis",
    },
    {
      label: "Doctor Consultation Prescription",
      key: "doctor consultation prescription",
    },
    {
      label: "Doctor Consultation Extra Notes",
      key: "doctor consultation extra notes",
    },
  ]);
  const [doctor, setDoctor] = useState([]);
  const user = useSelector(selectUser);
  const exportCsv = (doctor_id) => {
    setLoading(true);
    // console.log("selected doctor:", doctor_id);
    // console.log("URL:", process.env.REACT_APP_MAIN_URL3);
    const url1 = `${process.env.REACT_APP_MAIN_URL3}/api/user/export?doctor_id=${doctor_id}`;
    fetch(url1, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + user.token,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.data.data.appts.length === 0) {
          setIsVisible(false);
          setLoading(false);
          setTableData([]);
          setName("");
          // alert(isVisible);
        } else {
          setIsVisible(true);
          setLoading(false);
        }
        let appt = {};
        const apptArray = [];
        res.data.data.appts.forEach((item) => {
          appt.HealthCenterName = item["Health_center.name"];
          appt.DoctorName = item["doctorId.name"];
          appt.PatientName = item["patient.name"];
          appt.Date = item["date"];
          appt.TimeSlot = item["time_slot"];
          appt.ReasonToVisit = item["reason_for_visit"];
          appt.DoctorConsultationNotes =
            item["doctor_consultation_notes.case_info"];
          appt.DoctorConsultationTests =
            item["doctor_consultation_notes.tests_to_be_done"];
          appt.DoctorConsultationDiagnosis =
            item["doctor_consultation_notes.diagnosis"];
          appt.DoctorConsultationPrescription =
            item["doctor_consultation_notes.prescription"];
          appt.DoctorConsultationExtraNotes =
            item["doctor_consultation_notes.extra_notes"];
          setName(item["doctorId.name"] + "'s ");
          apptArray.push(appt);
          appt = {};
        });
        setAppts(apptArray);
        appts.unshift(apptsHeader);
        setTableData(apptArray);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  // Fetch health center appointments records
  const hcAppointmentsRecord = () => {
    setLoading(true);
    fetch(url, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + user.token,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.data.data.appts.length === 0) {
          setIsVisible(false);
          setLoading(false);
          setTableData([]);
          setName("");
        } else {
          setIsVisible(true);
          setLoading(false);
        }
        let appt = {};
        const apptArray = [];
        res.data.data.appts.forEach((item) => {
          appt.HealthCenterName = item["Health_center.name"];
          appt.DoctorName = item["doctorId.name"];
          appt.PatientName = item["patient.name"];
          appt.Date = item["date"];
          appt.TimeSlot = item["time_slot"];
          appt.ReasonToVisit = item["reason_for_visit"];
          appt.DoctorConsultationNotes =
            item["doctor_consultation_notes.case_info"];
          appt.DoctorConsultationTests =
            item["doctor_consultation_notes.tests_to_be_done"];
          appt.DoctorConsultationDiagnosis =
            item["doctor_consultation_notes.diagnosis"];
          appt.DoctorConsultationPrescription =
            item["doctor_consultation_notes.prescription"];
          appt.DoctorConsultationExtraNotes =
            item["doctor_consultation_notes.extra_notes"];
          setName(item["Health_center.name"] + "'s ");
          apptArray.push(appt);
          appt = {};
        });
        setAppts(apptArray);
        appts.unshift(apptsHeader);
        setTableData(apptArray);
      })

      .catch((err) => {
        console.log(err);
      });
  };
  //specialties
  const abortcontroller = new AbortController();
  const signal = abortcontroller.signal;
  const doctorUrl = `${process.env.REACT_APP_MAIN_URL3}/api/doctor/?health_center_id=3eb63c7e-e733-4481-9bec-6f756f3d9fb7`;
  useEffect(() => {
    fetch(doctorUrl, {
      signal: signal,
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + user.token,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return console.log("Specialty fetching did not succeed");
        }
        const iterable = res.data;

        iterable.forEach((item) => {
          item.value = item.id;
          item.label = item.name;
        });
        // iterable.unshift({ value: "any", label: "Any" });

        setDoctors(iterable);
        console.log("Doctors data:", iterable);
      })
      .catch((err) => {
        console.log(err);
      });
    return function cleanup() {
      abortcontroller.abort();
    };
  }, []);
  useEffect(() => hcAppointmentsRecord(), []);
  return (
    <div id="main-wrapper">
      {/***********************************
Header start
************************************/}
      <Header />
      {/***********************************
Header end ti-comment-alt
************************************/}
      {/***********************************
Sidebar start
************************************/}
      <LeftBar />
      {/***********************************
Sidebar end
************************************/}
      {/***********************************
Content body start
************************************/}
      <div className="content-body">
        <Form.Check
          inline
          label="Health center"
          name="group1"
          type="radio"
          value="healthcenter"
          checked={checkRadio === "healthcenter"}
          onChange={({ target }) => {
            setCheckRadio(target.value);
            hcAppointmentsRecord();
          }}
        />
        <Form.Check
          inline
          label="Doctor"
          name="group1"
          type="radio"
          value="doctor"
          onClick={() => {
            setCheckRadio("doctor");
          }}
          checked={checkRadio === "doctor"}
          onChange={({ target }) => {
            setTableData([]);
            setName("");
            setIsVisible(false);
            setCheckRadio(target.value);
          }}
        />
        {checkRadio === "doctor" && (
          <Select
            className="mt-3"
            placeholder="Select Doctor"
            options={doctors}
            value={selectedDoctor}
            onChange={(e) => {
              setSelectedDoctor(e);
              exportCsv(e.value);
            }}
          />
        )}
        {loading ? (
          <Preloader />
        ) : (
          <>
            <div className="card mt-3">
              <div className="pr-4 container">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h5 className="apptActivity">{name}Appointment records</h5>
                  {isVisible ? (
                    <CSVLink
                      filename={name.substring(0, name.length - 3)}
                      data={appts}
                    >
                      <Button
                        variant="success"
                        className="ml-3"
                        title="Click here to export CSV"
                      >
                        Export CSV
                      </Button>
                    </CSVLink>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <DataTable columns={columns} data={tableData} pagination />
            </div>
          </>
        )}
      </div>
      {/***********************************
Content body end
************************************/}

      {/***********************************
Footer start
************************************/}
      <Footer />
      {/***********************************
Footer end
************************************/}
    </div>
  );
};
export default TerminateAccount;
