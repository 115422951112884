import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import MainApp from './calendar/MainApp'
import AppointmentActivity from './AppointmentActivity'
import { Table } from 'react-bootstrap'
import PatientAppointments from './PatientAppointments.jsx'
import { formatDate } from '@fullcalendar/react'
import { forceUpdate, trimDate } from '../constants/Essentials'
import { Formik, Form, Field, ErrorMessage, FastField } from 'formik'
import { PatientAdddedSchema } from '../constants/ValidationSchema'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PatientMedicalDetailsHistoryComponent from './PatientMedicalHistory'
import PatientConsultationHistoryComponent from './PatientConsultationHistory'

const PatientSymptomsComponent = ({ dataToProcess }) => {
  toast.configure()
  const location = useLocation()
  let navigate = useNavigate()
  const profile = location.state
  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  const user = useSelector(selectUser)
  // prefill states
  const [alreadyPrefilled, setAlreadyPrefilled] = useState(false)

  // states
  const [currentSymptoms, setCurrentSymptoms] = useState('')
  const [whenSymptomsStarted, setWhenSymptomsStarted] = useState('')
  const [selfTreatment, setSelfTreatment] = useState(false)
  const [whichSelfTreatment, setWhichSelfTreatment] = useState('')
  const [triageStarted, setTriageStarted] = useState(false)
  const [vitalId, setVitalId] = useState('')
  const [temperature, setTemperature] = useState('')
  const [pulse, setPulse] = useState('')
  const [bloodPressure, setBloodPressure] = useState('')
  const [respirationRate, setRespirationRate] = useState('')

  // view states
  const [viewDetailsHistory, setViewDetailsHistory] = useState(false)
  const [viewConsultationHistory, setViewConsultationHistory] = useState(false)

  const patient_id =
    user.role !== 'Patient' ? dataToProcess?.patient_id : user.user_id
  const appt_id = dataToProcess?.appt_id

  //submit data
  const postDetails = () => {
    const dataToSend = {
      patient_id: patient_id,
      appointment_id: appt_id,
      current_symptoms: currentSymptoms,
      self_treatment: selfTreatment,
      what_self_treatment: whichSelfTreatment,
      when_started: whenSymptomsStarted,
      triage_started: triageStarted,
    }

    if (user.role === 'DOCTOR' || user.role === 'NURSE') {
      dataToSend.temperature = temperature
      dataToSend.pulse_rate = pulse
      dataToSend.respiration_rate = respirationRate
      dataToSend.pressure = bloodPressure
    }
    const postUrl = alreadyPrefilled
      ? `${process.env.REACT_APP_MAIN_URL}/api/vital/${vitalId}`
      : `${process.env.REACT_APP_MAIN_URL}/api/vital/`

    console.log(user.token, postUrl, dataToSend)
    fetch(postUrl, {
      method: alreadyPrefilled ? 'PUT' : 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, dataToSend)

        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }
        setAlreadyPrefilled(true)

        if (user.role !== 'DOCTOR') {
          navigate('/patient-medical-details', {
            state: { patient_id, appt_id },
          })
        }

        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
        })
      })
      .catch((err) => {
        console.log(err, dataToSend)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
  }

  // fetch profile details
  const fetchDetailsUrl = `${process.env.REACT_APP_MAIN_URL}/api/vital/?appointment_id=${appt_id}`

  useEffect(() => {
    console.log(user.user_id, user.token, fetchDetailsUrl)
    fetch(fetchDetailsUrl, {
      method: 'GET',
      signal: signal,
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        if (res.error) {
          return toast.warning('An error occured while fetching data', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }
        if (res?.data) {
          setAlreadyPrefilled(true)
          setVitalId(res.data?.id || '')
          setCurrentSymptoms(res.data?.current_symptoms || '')
          setWhenSymptomsStarted(trimDate(res.data?.when_started, 'date_only'))

          setSelfTreatment(res.data?.self_treatment || '')
          setWhichSelfTreatment(res.data?.what_self_treatment || '')
          setTriageStarted(res.data?.triage_started || '')
          setTemperature(parseInt(res.data?.temperature) || '')
          setBloodPressure(res.data?.pressure)
          setPulse(res.data?.pulse_rate)
          setRespirationRate(res.data?.respiration_rate)
        }

        // res.data?.items?.length > 0 && setAppointments(res.data.items)
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])
  return (
    <div className="container-fluid pt-0">
      <div className="row">
        <div className="col-lg-12 col-xxl-12">
          <div className="card py-3 px-3">
            <div className="card-header">
              <h4 className="card-title">
                Additional Information{' '}
                {dataToProcess?.patient_name &&
                  ` - ${dataToProcess?.patient_name}`}
              </h4>
            </div>
            <div className="card-body text-left">
              <div className="row">
                <div className="col-md-7">
                  <div className="form-group">
                    <label className="mb-1 " htmlFor="sympts">
                      <strong>What are your current Symptoms?</strong>
                    </label>
                    <textarea
                      className="form-control"
                      id="sympts"
                      rows="3"
                      value={currentSymptoms}
                      onChange={(e) => setCurrentSymptoms(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="mb-1 " htmlFor="illness_start">
                      <strong>When did the symptoms start?</strong>
                    </label>
                    <input
                      className="form-control"
                      id="illness_start"
                      type="date"
                      value={whenSymptomsStarted}
                      onChange={(e) => setWhenSymptomsStarted(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="form-group">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={selfTreatment}
                        onChange={(e) => {
                          setSelfTreatment(e.target.checked)
                        }}
                        id="medic"
                      />
                      <label className="form-check-label" htmlFor="medic">
                        Have you tried self treatment? (Including use of Over
                        The Counter medication)
                      </label>
                    </div>
                  </div>
                  {selfTreatment && (
                    <div className="form-group">
                      <label htmlFor="medic_condition">
                        Which self treatment have you tried?
                      </label>
                      <textarea
                        className="form-control"
                        id="medic_condition"
                        rows="3"
                        value={whichSelfTreatment}
                        onChange={(e) => setWhichSelfTreatment(e.target.value)}
                      ></textarea>
                    </div>
                  )}
                </div>
                <div className="col-md-7">
                  {(user.role === 'DOCTOR' || user.role === 'NURSE') && (
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={triageStarted}
                        onChange={(e) => {
                          setTriageStarted(e.target.checked)
                        }}
                        id="start_triage"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="start_triage"
                      >
                        {`${triageStarted ? 'Triage started' : 'Start Triage'}`}
                      </label>
                    </div>
                  )}
                </div>
                {(user.role === 'DOCTOR' || user.role === 'NURSE') &&
                  triageStarted && (
                    <>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="temperature">Temperature</label>
                          <input
                            type="number"
                            max={200}
                            maxLength={3}
                            id="temperature"
                            className="form-control"
                            value={temperature}
                            onChange={(e) => setTemperature(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="blood_pressure">Blood Pressure</label>
                          <input
                            type="text"
                            id="blood_pressure"
                            className="form-control"
                            maxLength={7}
                            value={bloodPressure}
                            onChange={(e) => setBloodPressure(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="pulse">Pulse</label>
                          <input
                            type="text"
                            id="pulse"
                            maxLength={3}
                            className="form-control"
                            value={pulse}
                            onChange={(e) => setPulse(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="respiration_rate">
                            Respiratiory Rate
                          </label>
                          <input
                            type="text"
                            id="respiration_rate"
                            className="form-control"
                            maxLength={3}
                            value={respirationRate}
                            onChange={(e) => setRespirationRate(e.target.value)}
                          />
                        </div>
                      </div>
                    </>
                  )}
              </div>
              <br />
              {user?.role === 'NURSE' && (
                <button
                  className="btn btn-dark mt-2 mr-5"
                  onClick={() => navigate(-1)}
                >
                  Back to dashboard
                </button>
              )}
              <button className="btn btn-primary mt-2" onClick={postDetails}>
                {user.role === 'Patient' ? 'Next' : 'Done'}
              </button>
              {(user.role === 'DOCTOR' || user.role === 'NURSE') && (
                <div className="row mt-2">
                  <div className="col-12">
                    <button
                      className="btn btn-link"
                      onClick={() => setViewDetailsHistory(!viewDetailsHistory)}
                    >
                      {viewDetailsHistory ? 'Hide ' : 'View '} Patient Details
                      History
                    </button>

                    <button
                      className="btn btn-link ml-5"
                      onClick={() =>
                        setViewConsultationHistory(!viewConsultationHistory)
                      }
                    >
                      {viewConsultationHistory ? 'Hide ' : 'View '} Patient
                      consultation History
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {viewDetailsHistory && (
          <div className="col-lg-6">
            <PatientMedicalDetailsHistoryComponent
              dataToProcess={dataToProcess}
            />
          </div>
        )}
        {viewConsultationHistory && (
          <div className="col-lg-6">
            <PatientConsultationHistoryComponent
              dataToProcess={dataToProcess}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default PatientSymptomsComponent
