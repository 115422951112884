import { useState } from "react";
import Switch from "react-switch";
import { Form } from "react-bootstrap";
const switchColorCompute = (level) => {
  let color = "white";
  let handle = "white";
  switch (level) {
    case "High":
      color = "#ff80ad";
      handle = "#ff005c";
      break;

    case "Medium":
      color = "#fff9e5";
      handle = "#ffc000";
      break;

    case "Low":
      color = "#e6f7fb";
      handle = "#07b0d7";
      break;

    default:
      color = "white";
      handle = "#ff005c";
      break;
  }
  return { color, handle };
};
const Habits = () => {
  const [switched, setSwitched] = useState({id: null, checked: false})
  return (
    <div className='card pl-2 pr-2 mt-3'>
      <p className='meal-profile-text mt-3' style={{ color: "#000" }}>
        Habits
      </p>
      <label>Do you drink Alcohol?</label>
      <div className='d-flex' style={{ gap: 50 }}>
        <Form.Check id='alcohol-yes' label='Yes' type='checkbox' />
        <Form.Check id='alcohol-no' label='No' type='checkbox' />
      </div>
      <div className='d-flex flex-wrap justify-content-between mt-2'>
        <div>
          <label style={{ display: "block" }}>Habit</label>
          <input
            type='text'
            placeholder='Enter your alcohol habit'
            style={{ height: 40 }}
          />
        </div>
        <div>
          <label>Alcohol Type</label>
          <Form.Select
            type='text'
            placeholder='Enter your alcohol habit'
            className='pl-2'
          >
            <option>Beer/Lager</option>
            <option>Wine</option>
            <option>Spirits</option>
          </Form.Select>
        </div>
        <div>
          <label>Frequency</label>
          <Form.Select
            type='text'
            placeholder='Enter your alcohol habit'
            className='pl-2'
          >
            <option>Days per week</option>
            <option>3 Days per week</option>
          </Form.Select>
        </div>
        <div>
          <label style={{ display: "block" }}>Quantity</label>
          <input
            type='number'
            placeholder='Enter your alcohol habit'
            style={{ height: 40 }}
          />
        </div>
        <div>
          <label>Bottle</label>
          <Form.Select
            type='text'
            placeholder='Enter your alcohol habit'
            className='pl-2'
          >
            <option>Bottles(300ml)</option>
            <option>Glasses(120ml)</option>
            <option>Totes(25ml)</option>
          </Form.Select>
        </div>
      </div>
      <div className='d-flex flex-wrap mt-3 mb-3' style={{ gap: 20 }}>
        {["Low", "Medium", "High"].map((level, index) => (
          <div className='d-flex flex-wrap'>
            <label style={{ marginTop: 5, fontWeight: 700 }}>{level}</label>
            <div className={`switch${index + 1}`} key={index}>
              <Switch
                key={index}
                onChange={(e) => setSwitched({ id: index, checked: e })}
                value={switched.checked}
                checked={switched.id === index ? switched.checked : false}
                uncheckedIcon={false}
                checkedIcon={false}
                onHandleColor={switchColorCompute(level).handle}
                offHandleColor={switchColorCompute(level).handle}
                onColor={switchColorCompute(level).color}
                offColor={switchColorCompute(level).color}
                width={80}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Habits;
