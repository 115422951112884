import React, { useState } from "react";
import Header from "../../components/Header";
import LeftBar from "../../components/LeftBar";
import Footer from "../../components/Footer";
import { Button, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import RxDetails from "./RxDetails";
const data = [
  {
    drugClass: "Analgesics and antipyretics",
    drugDetails: "Paracetamol   (500mg) Tablets",
    dosage: "1 or 2 tablets ",
    packSize: "10Tables/ pack",
    paymentMethod: "Cash",
    quantity: "10Tablets",
    refill: 1,
  },
  {
    drugClass: "Penicillins, Amino",
    drugDetails: "Amoxicillin  (500mg) Capsules",
    dosage: "1 tablet After Meals",
    packSize: "10Tablets/pack",
    paymentMethod: "Cash",
    quantity: "10Tablets/",
    refill: 1,
  },
  {
    drugClass: "Reflux suppressant",
    drugDetails: "Gaviscon Syrup",
    dosage: "10ml after meals",
    packSize: "10Tablet/Pack",
    paymentMethod: "Cash",
    quantity: "10Tablets/pack",
    refill: 0,
  },
];
const styles = {
  fontSize: 16,
  fontFamily: 'Lato',
  fontWeight: 700,
  color: '#000'
}
const smallStyles = {
  fontSize: 14,
  fontFamily: "Lato",
  fontWeight: 400,
  color: "#52575C",
};
const PrescriptionDispensation = () => {
    const [show, setShow] = useState(false)
    const [pharmacistModal, setPharmacistModal] = useState(false)
    const [plusChecked, setPlusChecked] = useState(false)
    const [highChecked, setHighChecked] = useState(false)
    const [lowChecked, setLowChecked] = useState(false)
    const [isDoctor, setIsDoctor] = useState(false)
    const handleClose = () => setShow(false)
  return (
    <div id='main-wrapper'>
      {/***********************************
Header start
************************************/}
      <Header />
      {/***********************************
Header end ti-comment-alt
************************************/}
      {/***********************************
Sidebar start
************************************/}
      <LeftBar />
      {/***********************************
Sidebar end
************************************/}
      {/***********************************
Content body start
************************************/}

      {/***********************************
      Sidebar end
  ************************************/}
      {/***********************************
      Content body start
  ************************************/}

      <div className='content-body'>
        <RxDetails />
        <p
          className='mt-2 mb-3'
          style={{ fontSize: 20, fontFamily: "Lato", fontWeight: 700 }}
        >
          RX Details
        </p>
        <div className='card pt-3 pl-3'>
          <div className='table-responsive card-table mb-5'>
            <table
              id='example5'
              className='display dataTablesCard table-responsive-xl'
            >
              <thead>
                <tr>
                  <th>DRUG Class</th>
                  <th>DRUG Details</th>
                  <th>Dosage</th>
                  <th> &nbsp; &nbsp;Pack Size &nbsp; &nbsp;</th>
                  <th>
                    Payment
                    <br /> Method &nbsp; &nbsp;
                  </th>
                  <th>
                    Available <br /> Quantity
                  </th>
                  <th>&nbsp; &nbsp;NO. OF REFILLS</th>
                </tr>
              </thead>

              <tbody>
                <>
                  {data?.map((data) => (
                    <tr>
                      <td>{data?.drugClass}&nbsp; &nbsp; &nbsp; &nbsp;</td>
                      <td>{data?.drugDetails}&nbsp; &nbsp;&nbsp; &nbsp;</td>
                      <td>{data?.dosage}&nbsp; &nbsp;&nbsp; &nbsp;</td>
                      <td>{data?.packSize}&nbsp; &nbsp;</td>
                      <td>{data?.paymentMethod}&nbsp; &nbsp;</td>
                      <td>{data?.quantity}&nbsp; &nbsp;&nbsp; &nbsp;</td>
                      <td>{data?.refill}&nbsp; &nbsp;&nbsp; &nbsp;</td>
                      <td>
                        <p
                          style={{
                            fontWeight: 400,
                            fontSize: 14,
                            fontFamily: "Lato",
                            color: "#0193D9",
                            cursor: "pointer",
                          }}
                        >
                          Dispense
                        </p>
                      </td>
                    </tr>
                  ))}
                </>
              </tbody>
            </table>
          </div>
          <div className='d-flex justify-content-end'>
            <Button
              className='mr-5 mt-5 mb-5'
              style={{ backgroundColor: "#19226D", width: 166, height: 40 }}
              onClick={() => setPharmacistModal(true)}
            >
              Edit Rx
            </Button>
          </div>
        </div>
        <div className='d-flex justify-content-end' style={{ gap: 30 }}>
          <Button style={{ backgroundColor: "#19226D" }}>Back to Queue</Button>
          <Link to='/pharmacist-dashboard'>
            <Button style={{ backgroundColor: "#19226D" }}>
              Back to Dashboard
            </Button>
          </Link>
        </div>
      </div>
      {/* Modal for doctor */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        size='xl'
      >
        <Modal.Body className='pt-5 pl-5 pr-5'>
          <div>
            <div className='d-flex flex-wrap' style={{ gap: 40 }}>
              <div>
                <label
                  style={{
                    fontSize: 16,
                    fontFamily: "Lato",
                    fontWeight: 400,
                    color: "#52575C",
                  }}
                >
                  Drug Class
                </label>
                <Form.Select className='pl-2'>
                  <option>Class one</option>
                  <option>Class two</option>
                </Form.Select>
              </div>
              <div>
                <label
                  style={{
                    fontSize: 16,
                    fontFamily: "Lato",
                    fontWeight: 400,
                    color: "#52575C",
                  }}
                >
                  Drug Class
                </label>
                <Form.Select className='pl-2'>
                  <option>Drug one</option>
                  <option>Drug two</option>
                </Form.Select>
              </div>
            </div>
            <label
              style={{
                fontSize: 16,
                fontFamily: "Lato",
                fontWeight: 400,
                color: "#52575C",
              }}
              className='mt-3'
            >
              Dosage Form
            </label>
            <div className='d-flex flex-wrap' style={{ gap: 10 }}>
              <Form.Check id='capsules' label='Capsules' />
              <Form.Check id='tablets' label='Tablets' />
              <Form.Check id='suspension' label='Suspension' />
              <Form.Check id='suppository' label='Suppository' />
            </div>
            <label
              style={{
                fontSize: 16,
                fontFamily: "Lato",
                fontWeight: 400,
                color: "#52575C",
              }}
              className='mt-3'
            >
              Drug strength (Number/weight or volume or other)
            </label>
            <div
              className='d-flex flex-wrap'
              style={{ gap: 15, width: "100%" }}
            >
              <div className='d-flex' style={{ gap: 15 }}>
                <label>Strength</label>
                <Form.Select className='pl-2' style={{ width: 149 }}>
                  <option>50mg</option>
                  <option>100mg</option>
                  <option>200mg</option>
                </Form.Select>
              </div>
              <div className='d-flex' style={{ gap: 15 }}>
                <label>Weight</label>
                <Form.Select className='pl-2' style={{ width: 149 }}>
                  <option>ml</option>
                  <option>cl</option>
                </Form.Select>
              </div>
              <div className='d-flex' style={{ gap: 15 }}>
                <label>Volume</label>
                <Form.Select className='pl-2' style={{ width: 149 }}>
                  <option>drops</option>
                </Form.Select>
              </div>
            </div>
            <div
              className='d-flex flex-wrap mt-3 mobile-width'
              style={{ gap: 10 }}
            >
              <div>
                <label>Amount to be dispensed by pharmacy</label>
                <Form.Control type='number' placeholder='Input amount' />
              </div>
              <div>
                <label>Enter number of refills</label>
                <Form.Control
                  type='number'
                  placeholder='Input number of refills'
                  className='mobile-width'
                />
              </div>
            </div>
            <label
              style={{
                fontSize: 16,
                fontFamily: "Lato",
                fontWeight: 400,
                color: "#52575C",
              }}
              className='mt-3'
            >
              Direction for use
            </label>
            <textarea
              className='form-control mt-3'
              placeholder='Input text'
              id='exampleFormControlTextarea1'
              rows={4}
            />
          </div>
          <div className='row mt-3'>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "Lato",
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Reason For Error
              </p>
              <div className='d-flex flex-wrap' style={{ gap: 50 }}>
                <Form.Check label='Drug Detail' id='details' />
                <div>
                  <Form.Check
                    label='Plus'
                    id='plus'
                    onChange={(e) => {
                      if (e.target.checked) {
                        setPlusChecked(true);
                      } else {
                        setPlusChecked(false);
                      }
                    }}
                  />
                  {plusChecked ? (
                    <div className='ml-4'>
                      <Form.Check
                        label='High'
                        id='high'
                        onChange={(e) => {
                          if (e.target.checked) {
                            setHighChecked(true);
                          } else {
                            setHighChecked(false);
                          }
                        }}
                      />
                      {highChecked ? (
                        <div className='ml-4'>
                          <Form.Check id='diagnosis' label='For Diagnosis' />
                          <Form.Check
                            id='underlying-condition'
                            label='For Underlying Condition'
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      <Form.Check
                        label='Low'
                        id='low'
                        onChange={(e) => {
                          if (e.target.checked) {
                            setLowChecked(true);
                          } else {
                            setLowChecked(false);
                          }
                        }}
                      />
                      {lowChecked ? (
                        <div className='ml-4'>
                          <Form.Check
                            id='diagnosis-low'
                            label='For Diagnosis'
                          />
                          <Form.Check
                            id='underlying-condition-low'
                            label='For Underlying Condition'
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <label
                style={{
                  fontSize: 16,
                  fontFamily: "Lato",
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Other Details
              </label>
              <textarea
                className='form-control mt-3'
                placeholder='Input text'
                id='other-details'
                rows={4}
              />
            </div>
          </div>
          <div className='d-flex justify-content-end mt-3'>
            <Button
              style={{
                backgroundColor: "#19226D",
                border: "1px #19226D solid",
              }}
              onClick={() => setShow(false)}
            >
              Update Rx
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* Modal for Pharmacist */}
      <Modal
        show={pharmacistModal}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        size='xl'
        onClick={() => setPharmacistModal(false)}
      >
        <Modal.Body className='pt-5 pl-5 pr-5'>
          <div className='mt-5 ml-3 mr-3'>
            <p
              style={{
                fontSize: 20,
                fontWeight: 700,
                fontFamily: "Lato",
                color: "#45B757",
              }}
            >
              Rx #123445
            </p>
            <p
              style={{
                fontSize: 18,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#52575C",
                marginTop: -20,
              }}
            >
              Paracetamol 500mg Tablets
            </p>
            <div
              className='mt-4 mb-3'
              style={{ height: 1, backgroundColor: "#818181" }}
            />
            <p
              style={{
                fontSize: 20,
                fontWeight: 700,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Billing Details
            </p>
            <div className='d-flex justify-content-between flex-wrap'>
              <p style={styles}>Retail price</p>
              <p
                style={{
                  fontSize: 20,
                  fontWeight: 700,
                  fontFamily: "Lato",
                  color: "#0193D9",
                }}
              >
                UGX 3,000
              </p>
              <p style={styles}>Drug Tier</p>
              <p style={smallStyles}>Good</p>
              <p style={styles}>Subsidy Status</p>
              <Button
                style={{
                  borderRadius: 40,
                  backgroundColor: "#cce9f7",
                  border: "1px #cce9f7 solid",
                  color: "#52575C",
                  fontSize: 14,
                }}
                className='rounded-pill'
              >
                Not Covered
              </Button>
            </div>
            <div
              className='mt-4'
              style={{ height: 1, backgroundColor: "#818181" }}
            />
            <div
              className='mt-1 mb-3'
              style={{ height: 1, backgroundColor: "#818181" }}
            />
            <p
              style={{
                fontSize: 20,
                fontWeight: 700,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Inventory Status
            </p>
            <div className='d-flex justify-content-between flex-wrap'>
              <p style={styles}>Drug Group</p>
              <p style={smallStyles}>Pharma</p>
              <p style={styles}>Manufacturer</p>
              <p style={smallStyles}>GlaxoSmithKline (GSK)</p>
              <p style={styles}>MOQ Status</p>
              <Button
                style={{
                  borderRadius: 40,
                  backgroundColor: "#cce9f7",
                  border: "1px #cce9f7 solid",
                  color: "#52575C",
                  fontSize: 14,
                }}
                className='rounded-pill'
              >
                Optimal
              </Button>
            </div>
            <div
              className='mt-4'
              style={{ height: 1, backgroundColor: "#818181" }}
            />
            <div
              className='mt-1 mb-3'
              style={{ height: 1, backgroundColor: "#818181" }}
            />
            <p
              style={{
                fontSize: 20,
                fontWeight: 700,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Dispensing Details
            </p>
            <div className='d-flex justify-content-between flex-wrap'>
              <p style={styles}>Sales Unit of Measure</p>
              <p style={smallStyles}>pack</p>
              <p style={styles}>Dispensing Quantity</p>
              <div className='d-flex' style={{ gap: 20 }}>
                <Form.Control type='text' placeholder='Enter Quantity' />
                <Button
                  style={{
                    backgroundColor: "#19226D",
                    border: "1px #19226D solid",
                    height: 35,
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
            <div
              className='mt-4 mb-5'
              style={{ height: 1, backgroundColor: "#818181" }}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </div>
  );
};

export default PrescriptionDispensation;
