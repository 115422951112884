import React from 'react'
import Footer from '../../Footer';
import LeftBar from '../../LeftBar';
import Header from '../../Header';
import { Button, Form } from 'react-bootstrap';
import Mealprofile from '../../new-nutrition/Mealprofile';
import { FoodChart } from '../../new-nutrition/FoodChart';
import { useSelector } from "react-redux";
import { selectBasketFood } from '../../../redux_setup/slices/basketFoodSlice';
const DietAndNutritionRecording = () => {
    const foodInBasket = useSelector(selectBasketFood);
  return (
    <div className='main-wrapper'>
      {/***********************************
                  Header start
                  ************************************/}
      <Header />
      {/***********************************
                  Header end ti-comment-alt
                  ************************************/}
      {/***********************************
                  Sidebar start
                  ************************************/}
      <LeftBar />
      {/***********************************
                  Sidebar end
                  ************************************/}
      {/***********************************
                  Content body start
                  ************************************/}

      {/***********************************
                  Content body end
                  ************************************/}
      <div className='content-body'>
        <div className='d-flex' style={{ width: 300 }}>
          <Form.Select
            aria-label='Default select example'
            className='mr-2 pl-2'
          >
            <option>Open this select menu</option>
          </Form.Select>
          <Button
            className='mr-2'
            style={{
              backgroundColor: "#2FA5DB33",
              border: "1px solid #2FA5DB33",
              fontWeight: 700,
            }}
          >
            Today
          </Button>
          <div
            className=' circle'
            style={{
              border: "1px solid #242E75",
              width: 50,
              height: 32,
              paddingLeft: 10,
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/meal.png`}
              alt='meal icon'
              width={7.6}
              height={17}
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/meal-1.png`}
              alt='meal icon'
            />
            <div
              className='circle'
              style={{
                position: "absolute",
                right: -5,
                top: -12,
                backgroundColor: "#662382",
                color: "#FFF",
                paddingBottom: 7,
                paddingLeft: 4,
                fontSize: 10,
                width: foodInBasket.length > 9 ? 20 : "",
                height: foodInBasket.length > 9 ? 20 : "",
                paddingTop: foodInBasket.length > 9 ? 2 : "",
              }}
            >
              {foodInBasket.length}
            </div>
          </div>
        </div>
        <p
          className='mt-3 mb-3'
          style={{
            fontSize: 20,
            fontFamily: "Lato",
            fontWeight: 700,
            color: "#000",
          }}
        >
          Diet & Nutrition(Recordings)
        </p>
        <div className='row'>
          <div
            className=' col-md-8 col-lg-8 col-sm-12 d-flex flex-wrap rounded'
            style={{ backgroundColor: "#fff", padding: 20, borderRadius: 8 }}
          >
            <div className='col-md-12'>
              {["Breakfast", "Lunch", "Dinner", "Snacks"].map((food) => (
                <div>
                  <p className='meal-profile-text mt-3' style={{ color: "#000" }}>
                    {food}
                  </p>
                  <div className='d-flex flex-wrap'>
                    <label className='mr-3 mt-2'>Time</label>
                    <input
                      className='mt-2'
                      type='time'
                      style={{
                        width: 135,
                        height: 27,
                        marginBottom: 20,
                          border: "1px solid #662382",
                        
                      }}
                    />
                    <div className='row'>
                      <div className='col-md-4 col-lg-4'>
                        <label>Carbohydrates</label>
                        <Form.Select
                          aria-label='Default select example'
                          className='mr-2 pl-2'
                        >
                          <option>Select Foods</option>
                        </Form.Select>
                      </div>
                      <div className='col-md-4 col-lg-4'>
                        <label>Proteins</label>
                        <Form.Select
                          aria-label='Default select example'
                          className='mr-2 pl-2'
                        >
                          <option>Select Foods</option>
                        </Form.Select>
                      </div>
                      <div className='col-md-4 col-lg-4'>
                        <label>Fats</label>
                        <Form.Select
                          aria-label='Default select example'
                          className='mr-2 pl-2'
                        >
                          <option>Select Foods</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='col-md-4 col-lg-4 col-sm-12 card'>
            <Mealprofile />
            <FoodChart />
          </div>
        </div>
      </div>

      {/***********************************
                  Footer start
                  ************************************/}
      <Footer />
      {/***********************************
                  Footer end
                  ************************************/}
    </div>
  );
}

export default DietAndNutritionRecording