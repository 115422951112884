import React from 'react'
import { Button, Form } from 'react-bootstrap'

const Invite = ({ handleSubmit }) => {
  return (
      <div className='d-flex justify-content-end'>
          <Form.Control type='text' placeholder='Enter patients Email' style={{width: 374}} />
          <Button variant='success' className='ml-3' onClick={handleSubmit}>Send Invite</Button>
    </div>
  )
}

export default Invite