import { useState } from "react";
import { Form } from "react-bootstrap";

const BloodPressureReduction = ({ sendData }) => {
  const [target, setTarget] = useState(0);
  const [duration, setDuration] = useState(0);
  const [recommendation, setRecommendation] = useState(0);
  const [isTargetEditable, setIsTargetEditable] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isRecommendationEditable, setIsRecommendationEditable] =
    useState(false);
  
  const sendDataToParent = () => {
    sendData({
      target_bp: target,
      duration_target_bp: duration,
      recommendation_target_bp: recommendation
    })
  }
  
  return (
    <div className='card pt-4 pl-3' style={{ width: '100%',height: '40%' }}>
      <p
        style={{
          fontWeight: 400,
          fontFamily: "Lato",
          fontSize: 16,
          color: "#000",
        }}
      >
        Blood Pressure Reduction
      </p>
      <div className='d-flex flex-wrap' style={{ gap: 30 }}>
        <div
          className='card pt-3 pl-3 pr-2 pb-3'
          style={{
            width: 288,
            backgroundColor: "#D0E0FF80",
          }}
        >
          <div
            className='card'
            style={{
              width: 255,
              height: 35,
              backgroundColor: "#0F56B333",
              textAlign: "center",
            }}
          >
            <p
              className='mt-1'
              style={{
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Current blood pressure
            </p>
          </div>
          <div className='d-flex flex-wrap' style={{ gap: 15 }}>
            <span
              style={{
                fontSize: 55,
                fontFamily: "Lato",
                fontWeight: 700,
                color: "#19226D",
              }}
              className='mt-5'
            >
              120/129
            </span>
            <span
              style={{
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
              className='mt-5'
            >
              mm/Hg
            </span>
          </div>
          <div className='d-flex flex-wrap justify-content-end'>
            <span
              style={{
                fontSize: 10,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Updated on: 13/12/2022
            </span>
          </div>
          <div
            className='mt-2 mr-2 mb-2'
            style={{ backgroundColor: "#000", width: 252, height: 1 }}
          />
          <div
            style={{
              width: 252,
              height: 29,
              borderRadius: 2,
              backgroundColor: "#FFF",
              textAlign: "center",
            }}
          >
            <p>
              Prev reading: 120/115mmHg
              <i class='fas-solid fas-arrow-up-right-from-square'></i>
            </p>
          </div>
        </div>
        <div
          className='card pt-3 pl-3 pr-2 pb-3'
          style={{
            width: 288,
            backgroundColor: "#D0E0FF80",
          }}
        >
          <div
            className='card'
            style={{
              width: 255,
              height: 35,
              backgroundColor: "#0F56B333",
              textAlign: "center",
            }}
          >
            <p
              className='mt-1'
              style={{
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Target blood pressure
            </p>
          </div>
          {isTargetEditable ? (
            <Form.Control
              placeholder='enter current blood pressure'
              value={target}
              onBlur={() => {
                setIsTargetEditable(false);
                sendDataToParent()
              }}
              onChange={(e) => setTarget(e.target.value)}
            />
          ) : (
            <div className='d-flex flex-wrap' style={{ gap: 15 }}>
              <span
                style={{
                  fontSize: 55,
                  fontFamily: "Lato",
                  fontWeight: 700,
                  color: "#19226D",
                }}
              >
                {target}
              </span>
              <span
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "Lato",
                  color: "#000",
                }}
                className='mt-5'
              >
                mm/Hg
              </span>
            </div>
          )}
          <div className='d-flex flex-wrap justify-content-end'>
            <span
              style={{
                fontSize: 10,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Updated on: 13/12/2022
            </span>
          </div>
          <div
            className='mt-2 mr-2 mb-2'
            style={{ backgroundColor: "#000", width: 252, height: 1 }}
          />
          <div className='d-flex flex-wrap justify-content-between'>
            <div style={{ cursor: "pointer" }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/edit-icon.png`}
                alt='Edit icon'
                width={13}
                height={14}
              />
              <span
                className='ml-2'
                style={{ color: "#19226D", fontSize: 10 }}
                onClick={() => setIsTargetEditable(true)}
              >
                Edit
              </span>
            </div>
            <div
              style={{
                width: 150,
                height: 29,
                borderRadius: 2,
                backgroundColor: "#FFF",
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "Lato",
                  color: "#000",
                }}
              >
                Target loss 49mmHg
                <i class='fas-solid fas-arrow-up-right-from-square'></i>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className='mt-2 mr-2 mb-4'
        style={{ backgroundColor: "#000", width: '90%', height: 1 }}
      />
      <div className='d-flex flex-wrap' style={{ gap: 30 }}>
        <div
          className='card pt-3 pl-3 pr-2 pb-3'
          style={{
            width: 288,
            backgroundColor: "#D0E0FF80",
          }}
        >
          <div
            className='card'
            style={{
              width: 255,
              height: 35,
              backgroundColor: "#0F56B333",
              textAlign: "center",
            }}
          >
            <p
              className='mt-1'
              style={{
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Duration
            </p>
          </div>
          {isEditable ? (
            <Form.Control
              placeholder='Enter duration'
              value={duration}
              onBlur={() => {
                setIsEditable(false);
                sendDataToParent()
              }}
              onChange={(e) => {
                setDuration(e.target.value);
              }}
            />
          ) : (
            <div className='d-flex flex-wrap' style={{ gap: 15 }}>
              <span
                style={{
                  fontSize: 55,
                  fontFamily: "Lato",
                  fontWeight: 700,
                  color: "#19226D",
                }}
                onClick={() => setIsEditable(true)}
              >
                {duration}
              </span>
              <span
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "Lato",
                  color: "#000",
                }}
                className='mt-5'
              >
                Weeks
              </span>
            </div>
          )}
          <div className='d-flex flex-wrap justify-content-end'>
            <span
              style={{
                fontSize: 10,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Updated on: 13/01/2023
            </span>
          </div>
          <div
            className='mt-2 mr-2 mb-2'
            style={{ backgroundColor: "#000", width: 252, height: 1 }}
          />
          <div className='d-flex flex-wrap justify-content-between'>
            <div style={{ cursor: "pointer" }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/edit-icon.png`}
                alt='Edit icon'
                width={13}
                height={14}
              />
              <span
                className='ml-2'
                style={{ color: "#19226D", fontSize: 10 }}
                onClick={() => {
                  setIsEditable(true);
                  console.log(duration);
                }}
              >
                Edit
              </span>
            </div>
            <div
              style={{
                width: 71,
                height: 29,
                borderRadius: 2,
                backgroundColor: "#FFF",
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "Lato",
                  color: "#000",
                }}
              >
                36/36
                <i class='fas-solid fas-arrow-up-right-from-square'></i>
              </p>
            </div>
          </div>
        </div>
        <div
          className='card pt-3 pl-3 pr-2 pb-3'
          style={{
            width: 288,
            backgroundColor: "#D0E0FF80",
          }}
        >
          <div
            className='card'
            style={{
              width: 255,
              height: 35,
              backgroundColor: "#D60A5180",
              textAlign: "center",
            }}
          >
            <p
              className='mt-1'
              style={{
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Recommendation
            </p>
          </div>
          {isRecommendationEditable ? (
            <Form.Control
              placeholder='Type recommendation'
              value={recommendation}
              onChange={(e) => {
                setRecommendation(e.target.value);
                sendDataToParent()
              }}
              onBlur={() => setIsRecommendationEditable(false)}
            />
          ) : (
            <div className='d-flex flex-wrap' style={{ gap: 15 }}>
              <span
                style={{
                  fontSize: 64,
                  fontFamily: "Lato",
                  fontWeight: 700,
                  color: "#D60A5133",
                }}
              >
                {recommendation}
              </span>
              <span
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "Lato",
                  color: "#000",
                }}
                className='mt-5'
              >
                mmHgHg/Wk
              </span>
            </div>
          )}
          <div className='d-flex flex-wrap justify-content-end'>
            <span
              style={{
                fontSize: 10,
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Updated on: 13/12/2022
            </span>
          </div>
          <div
            className='mt-2 mr-2 mb-2'
            style={{ backgroundColor: "#000", width: 252, height: 1 }}
          />
          <div className='d-flex flex-wrap justify-content-between'>
            <div style={{ cursor: "pointer" }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/edit-icon.png`}
                alt='Edit icon'
                width={13}
                height={14}
              />
              <span
                className='ml-2'
                style={{ color: "#19226D", fontSize: 10 }}
                onClick={() => {
                  setIsRecommendationEditable(true);
                }}
              >
                Edit
              </span>
            </div>
            <div
              style={{
                width: 111,
                height: 29,
                borderRadius: 2,
                backgroundColor: "#FFF",
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "Lato",
                  color: "#000",
                }}
              >
                1.5Kgs added
                <i class='fas-solid fas-arrow-up-right-from-square'></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BloodPressureReduction;
