import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PatientSignupSchema } from "../constants/ValidationSchema";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Register = () => {
  const [phoneState, setPhoneState] = useState("");
  let navigate = useNavigate();
  toast.configure();
  const initialValues = {
    email: "",
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    birth_date: "",
    password: "",
    passwordConfirmation: "",
  };

  const abortcontroller = new AbortController();
  const signal = abortcontroller.signal;

  const url = `${process.env.REACT_APP_MAIN_URL}/api/auth/signup`;

  const signupLogic = (values, resetForm) => {
    if (phoneState === "" || phoneState.length < 8) {
      return alert("Error: Phone is invalid");
    }
    const dataToSend = {
      email: values.email,
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      phone: phoneState,
      gender: values.gender,
      birth_date: values.birth_date,
      password: values.password,
    };
    // send request
    fetch(url, {
      signal: signal,
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          });
        }
        // console.log(JSON.stringify(dataToSend));
        resetForm();
        navigate("/verify-account");
        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        });
      })
      .catch((err) => {
        console.log(err);
        return toast.warning("An error occured while reaching the server", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        });
      });
  };

  return (
    <div className="bgLockScreen">
      <div className="form-body">
        <div className="row">
          <div className="img-holder">
            <div className="bg" />
            <h3
              className="bigScreenOnly text-left mr-5"
              style={{ marginTop: "55vh" }}
            >
              Welcome to <span>better</span> Healthcare management
            </h3>
            <div className="info-holder"></div>
          </div>
          <div className="form-holder">
            <div className="form-content ">
              <div className="form-itemsreg">
                <div className="register-width">
                  {/* <div className="website-logo">
                    <a href="index.html">
                      <div className="logo">
                        <img
                          className="logo-size"
                          src="./images/NewLogos/MoyoLogo-01-02.png"
                          alt=""
                        />
                      </div>
                    </a>
                  </div> */}

                  <div className="page-links text-center">
                    <Link to="/login" title="Click here to Login">Login</Link>
                    <Link to="/register" className="active" title="Create New Account">
                      Register
                    </Link>
                  </div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={PatientSignupSchema}
                    onSubmit={(values, { resetForm }) => {
                      signupLogic(values, resetForm);
                    }}
                  >
                    {(formik) => {
                      const { errors, touched, isValid, dirty } = formik;
                      return (
                        <Form className="row">
                          <div className="col-md-4">
                            <Field name="firstName">
                              {(props) => {
                                return (
                                  <div className="form-group">
                                    <label
                                      className="mb-1 text-white"
                                      htmlFor="firstName"
                                    >
                                      <strong>
                                        First name
                                        <ErrorMessage
                                          name="firstName"
                                          component="span"
                                          className="help-block warning errorMessage"
                                        />
                                      </strong>
                                    </label>
                                    <input
                                      type="text"
                                      title="Type your First Name"
                                      id="firstName"
                                      autoComplete="firstName"
                                      value={props.field.value}
                                      onChange={props.field.onChange}
                                      onBlur={props.field.onBlur}
                                      className={
                                        errors.firstName && touched.firstName
                                          ? "form-control input-error warning"
                                          : "form-control"
                                      }
                                    />
                                  </div>
                                );
                              }}
                            </Field>
                          </div>
                          <div className="col-md-4">
                            <Field name="middleName">
                              {(props) => {
                                return (
                                  <div className="form-group">
                                    <label
                                      className="mb-1 text-white"
                                      htmlFor="middleName"
                                    >
                                      <strong>
                                        Middle name
                                        <ErrorMessage
                                          name="middleName"
                                          component="span"
                                          className="help-block warning errorMessage"
                                        />
                                      </strong>
                                    </label>
                                    <input
                                      type="text"
                                      title="Type your Middle Name"
                                      id="middleName"
                                      autoComplete="middleName"
                                      value={props.field.value}
                                      onChange={props.field.onChange}
                                      onBlur={props.field.onBlur}
                                      className={
                                        errors.middleName && touched.middleName
                                          ? "form-control input-error warning"
                                          : "form-control"
                                      }
                                    />
                                  </div>
                                );
                              }}
                            </Field>
                          </div>
                          <div className="col-md-4">
                            <Field name="lastName">
                              {(props) => {
                                return (
                                  <div className="form-group">
                                    <label
                                      className="mb-1 text-white"
                                      htmlFor="lastName"
                                    >
                                      <strong>
                                        Last name
                                        <ErrorMessage
                                          name="lastName"
                                          component="span"
                                          className="help-block warning errorMessage"
                                        />
                                      </strong>
                                    </label>
                                    <input
                                      type="text"
                                      id="lastName"
                                      title="Type your Last Name"
                                      // placeholder="Enter lastName.."
                                      autoComplete="lastName"
                                      value={props.field.value}
                                      onChange={props.field.onChange}
                                      onBlur={props.field.onBlur}
                                      className={
                                        errors.lastName && touched.lastName
                                          ? "form-control input-error warning"
                                          : "form-control"
                                      }
                                    />
                                  </div>
                                );
                              }}
                            </Field>
                          </div>

                          <div className="col-md-6">
                            <Field name="email">
                              {(props) => {
                                return (
                                  <div className="form-group">
                                    <label
                                      className="mb-1 text-white"
                                      htmlFor="email"
                                    >
                                      <strong>
                                        Email
                                        <ErrorMessage
                                          name="email"
                                          component="span"
                                          className="help-block warning errorMessage"
                                        />
                                      </strong>
                                    </label>
                                    <input
                                      type="email"
                                      id="email"
                                      title="Type your Email"
                                      // placeholder="Enter email.."
                                      autoComplete="email"
                                      value={props.field.value}
                                      onChange={props.field.onChange}
                                      onBlur={props.field.onBlur}
                                      className={
                                        errors.email && touched.email
                                          ? "form-control input-error warning"
                                          : "form-control"
                                      }
                                    />
                                  </div>
                                );
                              }}
                            </Field>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                className="mb-1 text-white"
                                htmlFor="phone"
                              >
                                <strong>
                                  Phone number
                                  <ErrorMessage
                                    name="phone"
                                    component="span"
                                    className="help-block warning errorMessage"
                                  />
                                </strong>
                              </label>
                              {/* <input
                                      type="text"
                                      id="phone"
                                      // placeholder="Enter phone.."
                                      autoComplete="phone"
                                      value={props.field.value}
                                      onChange={props.field.onChange}
                                      onBlur={props.field.onBlur}
                                      className={
                                        errors.phone && touched.phone
                                          ? "form-control input-error warning"
                                          : "form-control"
                                      }
                                    /> */}

                              <PhoneInput
                                inputStyle={{ color: "black", width: "100%" }}
                                id="phone"
                                title="Type your Phone Number"
                                country={"ke"}
                                value={phoneState}
                                onChange={(e) => setPhoneState(e)}
                                inputClass={
                                  errors.phone && touched.phone
                                    ? "form-control input-error warning"
                                    : "form-control"
                                }
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <Field name="gender">
                              {(props) => {
                                return (
                                  <div className="form-group">
                                    <label
                                      className="mb-1 text-white"
                                      htmlFor="gender"
                                    >
                                      <strong>
                                        Gender{" "}
                                        <ErrorMessage
                                          name="gender"
                                          component="span"
                                          className="help-block errorMessage warning"
                                        />
                                      </strong>
                                    </label>
                                    <select
                                      id="gender"
                                      value={props.field.value}
                                      onChange={props.field.onChange}
                                      onBlur={props.field.onBlur}
                                      title="Select your Gender"
                                      className={
                                        errors.gender && touched.gender
                                          ? "form-control input-error warning"
                                          : "form-control"
                                      }
                                    >
                                      <option value="">Select</option>
                                      <option value="male">Male</option>
                                      <option value="female">Female</option>
                                      <option value="other">Other</option>
                                    </select>
                                  </div>
                                );
                              }}
                            </Field>
                          </div>

                          <div className="col-md-6">
                            <Field name="birth_date">
                              {(props) => {
                                return (
                                  <div className="form-group">
                                    <label
                                      className="mb-1 text-white"
                                      htmlFor="birth_date"
                                    >
                                      <strong>
                                        Date of birth{" "}
                                        <ErrorMessage
                                          name="birth_date"
                                          component="span"
                                          className="help-block errorMessage warning"
                                        />
                                      </strong>
                                    </label>
                                    <input
                                      type="date"
                                      id="birth_date"
                                      title="Type your Date of Birth"
                                      // placeholder="Enter birth_date.."
                                      autoComplete="birth_date"
                                      value={props.field.value}
                                      onChange={props.field.onChange}
                                      onBlur={props.field.onBlur}
                                      className={
                                        errors.birth_date && touched.birth_date
                                          ? "form-control input-error warning"
                                          : "form-control"
                                      }
                                    />
                                  </div>
                                );
                              }}
                            </Field>
                          </div>

                          <div className="col-md-6">
                            <Field name="password">
                              {(props) => {
                                return (
                                  <div className="form-group">
                                    <label
                                      className="mb-1 text-white"
                                      htmlFor="password"
                                    >
                                      <strong>Password</strong>
                                    </label>
                                    <input
                                      type="password"
                                      id="password"
                                      title="Type your Password"
                                      // placeholder="Enter password.."
                                      autoComplete="password"
                                      value={props.field.value}
                                      onChange={props.field.onChange}
                                      onBlur={props.field.onBlur}
                                      className={
                                        errors.password && touched.password
                                          ? "form-control input-error warning"
                                          : "form-control"
                                      }
                                    />
                                    <ErrorMessage
                                      name="password"
                                      component="span"
                                      className="help-block errorMessage warning"
                                    />
                                  </div>
                                );
                              }}
                            </Field>
                          </div>

                          <div className="col-md-6">
                            <Field name="passwordConfirmation">
                              {(props) => {
                                return (
                                  <div className="form-group">
                                    <label
                                      className="mb-1 text-white"
                                      htmlFor="passwordConfirmation"
                                    >
                                      <strong>Confirm Password</strong>
                                    </label>
                                    <input
                                      type="password"
                                      id="passwordConfirmation"
                                      title="Type your Confirm Password"
                                      // placeholder="Enter password again.."
                                      autoComplete="passwordConfirmation"
                                      value={props.field.value}
                                      onChange={props.field.onChange}
                                      onBlur={props.field.onBlur}
                                      className={
                                        errors.passwordConfirmation &&
                                        touched.passwordConfirmation
                                          ? "form-control input-error warning"
                                          : "form-control"
                                      }
                                    />
                                    <ErrorMessage
                                      name="passwordConfirmation"
                                      component="span"
                                      className="help-block errorMessage warning"
                                    />
                                  </div>
                                );
                              }}
                            </Field>
                          </div>
                          <div className="col-md-12 text-center mt-4">
                            <button
                              disabled={!(dirty && isValid)}
                              type="submit"
                              className="btn bg-white text-primary btn-block"
                              title="Click here to Sign up"
                            >
                              Sign me up
                            </button>
                          </div>
                          {/* <div className="new-account mt-3">
                            <p className="text-white">
                              Already have an account? &nbsp;&nbsp;
                              <Link to="/login" className="text-white">
                                Sign in
                              </Link>
                            </p>
                          </div> */}
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    // <div className="authincation h-100">
    //   <div className="container h-100">
    //     <div className="row justify-content-center h-100 align-items-center">
    //       <div className="col-md-6">
    //         <div className="authincation-content">
    //           <div className="row no-gutters">
    //             <div className="col-xl-12">
    //               <div className="auth-form">
    //                 <div className="text-center mb-3">
    //                   <a href="index.html">
    //                     <img src="images/logo-full.png" alt="" />
    //                   </a>
    //                 </div>
    //                 <h4 className="text-center mb-4 text-white">
    //                   Sign up your account
    //                 </h4>

    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Register;
