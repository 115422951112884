import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import Collapse from 'react-bootstrap/Collapse'
import HypertensionMenu from './HypertensionMenu'
import {
  populateUnderlyingConditions,
  selectUnderlyingConditions,
} from '../../redux_setup/slices/hypertensionSlice'
import { ReduxActions } from '../../constants/redux_actions'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../../redux_setup/slices/userSlice'
import { machinize } from '../../constants/Essentials'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { updateStep } from '../../redux_setup/slices/stepSlice'

const conditionsCheck = {
  hypertension: false,
  diabetes: false,
  obesity: false,
  metabolic_syndrome: false,
  stroke: false,
  heart_disease: false,
}

const UnderlyingConditions = ({ patientDetails }) => {
  const underlyingConditionsState = useSelector(selectUnderlyingConditions)
  const user = useSelector(selectUser)
  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  toast.configure()
  const dispatch = useDispatch()
  const [toggles, setToggles] = useState(conditionsCheck)
  const casesHandler = (category, property, value) => {
    dispatch(
      populateUnderlyingConditions({
        category,
        property,
        value,

        action_type: ReduxActions.change_object_property_in_array,
      }),
    )
  }

  const addPropertyinProperty = (category, property, value) => {
    dispatch(
      populateUnderlyingConditions({
        category,
        property,
        value,

        action_type: ReduxActions.add_property_in_property,
      }),
    )
  }

  const addDirectProperty = (property, value) => {
    dispatch(
      populateUnderlyingConditions({
        property,
        value,

        action_type: ReduxActions.add_property,
      }),
    )
  }

  const handleSubmit = () => {
    //are we gonna post or patch?
    //
    console.log(underlyingConditionsState)
    let dataToSend

    if (underlyingConditionsState?.id) {
      dataToSend = { ...underlyingConditionsState }
      delete dataToSend.created_on
      delete dataToSend.updated_at
      delete dataToSend.patient_id
    } else {
      dataToSend = {
        ...underlyingConditionsState,
        patient_id: patientDetails.patient_id,
      }
    }

    fetch(
      `${process.env.REACT_APP_MAIN_URL_HYPERTENSION}/api/underlying_conditions`,
      {
        signal: signal,
        method: underlyingConditionsState?.id ? 'PATCH' : 'POST', //if you can find an id in underlyingConditionsState, then update
        headers: new Headers({
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(dataToSend),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res)

        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }
        dispatch(updateStep('LifestyleReview'))
        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        })

        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error has occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
  }

  useEffect(() => {
    const togglesClone = { ...toggles }
    underlyingConditionsState.condition_obj.map((condition) => {
      if (condition.cases[0].diagnosis_date !== '') {
        const machinized_condition = machinize(condition.condition)

        togglesClone[machinized_condition] = true
      }
      setToggles(togglesClone)
    })
  }, [underlyingConditionsState])

  useEffect(() => {
    // console.log(patientDetails)
    fetch(
      `${process.env.REACT_APP_MAIN_URL_HYPERTENSION}/api/underlying_conditions/${patientDetails.patient_id}`,
      {
        signal: signal,
        method: 'GET',
        headers: new Headers({
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        if (res.error) {
          return console.log('Underlying conditions fetching did not succeed')
        }
        if (res.data.length <= 0) {
          //reset redux statepiece

          dispatch(
            populateUnderlyingConditions({
              action_type: ReduxActions.reset_to_default_value,
            }),
          )
        } else {
          dispatch(
            populateUnderlyingConditions({
              value: res.data[0],
              action_type: ReduxActions.add_initial_data_from_server,
            }),
          )
        }

        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err)
      })

    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])
  return (
    <div className="margin__container">
      <h6 className="margin__left vital__subtopic">Underlying Conditions</h6>
      <HypertensionMenu />

      <div className="card">
        <div className="card-body text-left">
          <p>Have you been diagonised with:</p>

          <div className="row">
            {/* Hypertension */}

            {underlyingConditionsState.condition_obj.map((cond, index) => {
              return (
                <div className="col-6" key={index}>
                  <div className="card">
                    <div className="card-body family__history__card__collapse">
                      <div className="row">
                        <div className="col-1 ">
                          <input
                            type="checkbox"
                            id={cond.condition}
                            checked={
                              toggles[machinize(cond.condition)] === true
                            }
                            onChange={(e) => {
                              const machinized_condition = machinize(
                                cond.condition,
                              )
                              const togglesClone = { ...toggles }

                              togglesClone[
                                machinized_condition
                              ] = !togglesClone[machinized_condition]
                              setToggles(togglesClone)
                            }}
                          />
                        </div>
                        <div className="col-11 text-left ">
                          <label
                            htmlFor={cond.condition}
                            className="vital__subtopic cursor__pointer"
                          >
                            {cond.condition}
                          </label>
                          <Collapse
                            in={toggles[machinize(cond.condition)] === true}
                          >
                            <div id="example-collapse-text">
                              <div className="hypeForms">
                                <div className="flex__container">
                                  <label className="labels">Type:</label>
                                  <select
                                    placeholder="Type"
                                    style={{ marginLeft: 6 }}
                                    className="form-control form-control-sm"
                                    onChange={(e) =>
                                      casesHandler(
                                        cond.condition,
                                        'condition_type',
                                        e.target.value,
                                      )
                                    }
                                    value={cond.cases[0]?.condition_type}
                                  >
                                    <option value="" defaultValue>
                                      Type
                                    </option>
                                    <option>Essential </option>
                                    <option>Secondary </option>
                                    <option>Isolated systolic</option>
                                    <option>Maligant </option>
                                    <option>Resistant </option>
                                  </select>
                                </div>
                              </div>
                              <div className="hypeForms">
                                <div className="flex__container">
                                  <label className="labels">Stage:</label>
                                  <select
                                    className="form-control form-control-sm"
                                    placeholder="Stage"
                                    style={{ marginLeft: 0 }}
                                    onChange={(e) =>
                                      casesHandler(
                                        cond.condition,
                                        'condition_stage',
                                        e.target.value,
                                      )
                                    }
                                    value={cond.cases[0]?.condition_stage}
                                  >
                                    <option value="">Stage</option>
                                    <option>Stage 1 </option>
                                    <option>Stage 2 </option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex__container">
                                <label className="labels">
                                  Diag
                                  <br />
                                  nosis Date:
                                </label>
                                <input
                                  id="date"
                                  type="date"
                                  placeholder="Date"
                                  style={{
                                    width: '100%',
                                    marginLeft: -27,
                                    height: 31,
                                  }}
                                  onChange={(e) =>
                                    casesHandler(
                                      cond.condition,
                                      'diagnosis_date',
                                      e.target.value,
                                    )
                                  }
                                  value={cond.cases[0]?.diagnosis_date}
                                />
                              </div>
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className="card-body">
        <div className="row">
          <div className="col-6">
            <h3 className="lilHeading">Prescriptions</h3>

            <div className="card">
              <div className="card-body text-left">
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Check
                      type="checkbox"
                      label="Obesity"
                      name="prescription"
                      id="prescription-1"
                      checked={
                        underlyingConditionsState.prescription_obj.obesity
                      }
                      style={{ marginLeft: 15 }}
                      onChange={(e) =>
                        addPropertyinProperty(
                          'prescription_obj',
                          'obesity',
                          e.target.checked,
                        )
                      }
                    />
                    <Form.Check
                      type="checkbox"
                      label="Steroids"
                      name="prescription"
                      id="prescription-2"
                      checked={
                        underlyingConditionsState.prescription_obj.steroids
                      }
                      style={{ marginLeft: 15 }}
                      onChange={(e) =>
                        addPropertyinProperty(
                          'prescription_obj',
                          'steroids',
                          e.target.checked,
                        )
                      }
                    />
                    <Form.Check
                      type="checkbox"
                      label="Non-steroidal
                  anti-inflammatory drugs (NSAIDs)"
                      name="prescription"
                      id="prescription-3"
                      checked={
                        underlyingConditionsState.prescription_obj
                          .anti_inflammatory
                      }
                      style={{ marginLeft: 15 }}
                      onChange={(e) =>
                        addPropertyinProperty(
                          'prescription_obj',
                          'anti_inflammatory',
                          e.target.checked,
                        )
                      }
                    />
                    <Form.Check
                      type="checkbox"
                      label="Herbal remedies –
                  particularly those containing liquorice"
                      name="prescription"
                      id="prescription-4"
                      checked={
                        underlyingConditionsState.prescription_obj
                          .herbal_remedies
                      }
                      style={{ marginLeft: 15 }}
                      onChange={(e) =>
                        addPropertyinProperty(
                          'prescription_obj',
                          'herbal_remedies',
                          e.target.checked,
                        )
                      }
                    />

                    <Form.Check
                      type="checkbox"
                      label="Serotonin-noradrenaline reuptake inhibitor (SSNRI)
                  antidepressants"
                      name="prescription"
                      id="prescription-6"
                      checked={
                        underlyingConditionsState.prescription_obj
                          .serotonin_inhibitor
                      }
                      style={{ marginLeft: 15 }}
                      onChange={(e) =>
                        addPropertyinProperty(
                          'prescription_obj',
                          'serotonin_inhibitor',
                          e.target.checked,
                        )
                      }
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
          <div className="col-6">
            <h3 className="lilHeading">Drug allergies</h3>
            <div className="card">
              <div className="card-body text-left">
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    placeholder="Input subjective data"
                    id="exampleFormControlTextarea1"
                    rows={8}
                    defaultValue={''}
                    value={underlyingConditionsState.drug_allergies}
                    onChange={(e) =>
                      addDirectProperty('drug_allergies', e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ textAlign: 'right' }}>
        <button className="btn btn-success hypemit" onClick={handleSubmit}>
          Next
        </button>
      </div>
    </div>
  )
}

export default UnderlyingConditions
