import { formatDate } from '@fullcalendar/react'
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import PatientAppointments from '../components/PatientAppointments'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import LeftBar from '../components/LeftBar'
import Header from '../components/Header'
import Footer from '../components/Footer'

const AppointmentsActivity = () => {
  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  const user = useSelector(selectUser)
  const [appointments, setAppointments] = useState([])
  const fetchAppointmentsUrl =
    user.role === 'Patient'
      ? `${process.env.REACT_APP_MAIN_URL}/api/patient/appointments?size=30`
      : `${process.env.REACT_APP_MAIN_URL}/api/user/appointments-admin?doctor_id=${user.user_id}&size=30`
  useEffect(() => {
    fetch(fetchAppointmentsUrl, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        res.data.items.forEach((item) => {
          item.title =
            user.role === 'Patient' ? item?.user?.name : item?.patient?.name
          const dt = new Date(item.date)
          // item.value = item.id;
          // item.start = ;
          // const slot = "T"+item.time_slot.substring(0, str.length - 2)
          item.start = String(
            dt.toISOString().replace(/T.*$/, '') + 'T' + item.time_slot,
          )
          // item.start = "2022-02-01T16:38";

          //->will delete the rest later
        })
        setAppointments(res.data.items)
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])
  return (
    <div id="main-wrapper">
      {/***********************************
  Header start
************************************/}
      <Header />
      {/***********************************
  Header end ti-comment-alt
************************************/}
      {/***********************************
  Sidebar start
************************************/}
      <LeftBar />
      {/***********************************
  Sidebar end
************************************/}
      {/***********************************
  Content body start
************************************/}
      <div className="content-body">
        <div className="row bg-white py-3">
          <div className="col-lg-12 pr-0">
            <div className="card">
              <div className="pr-4 container">
                <h5 className="apptActivity">Appointment activity</h5>
              </div>
              <Table className="table-bordered table-hover">
                <thead>
                  <tr style={{ textAlign: 'left' }}>
                    <th>Doctor</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Reason</th>
                    <th>Payment</th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: '14px' }}>
                  {appointments.map((appointment, index) => (
                    <PatientAppointments
                      key={index}
                      time={appointment.time_slot}
                      date={formatDate(appointment.date, { locale: 'es' })}
                      docname={appointment.user.name}
                      visitReason={appointment.reason_for_visit}
                      isPayed={appointment.is_payed}
                      sms={appointment.sms}
                      appt={appointment}
                      patient_name={
                        user.role === 'Patient' ? user.userName : ''
                      }
                    />
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      {/***********************************
  Content body end
************************************/}

      {/***********************************
  Footer start
************************************/}
      <Footer />
      {/***********************************
  Footer end
************************************/}
    </div>
  )
}

export default AppointmentsActivity
