import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import LeftBar from '../components/LeftBar'
import { useSelector } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Col, Row } from 'react-bootstrap'

import { Link, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { formatDate } from '@fullcalendar/react'

const AppointmentPayments = () => {
  const location = useLocation()
  const [method, setMethod] = useState('')
  const [mpesanum, setMpesaNum] = useState('')
  const [insuranceCompanies, setInsuranceCompanies] = useState([])
  const [selectedCompany, setSelectedCompany] = useState('')
  const [schemeVisible, setSchemeVisible] = useState(false)
  const [schemes, setSchemes] = useState([])
  const [selectedScheme, setSelectedScheme] = useState('')
  const [referenceNumber, setReferenceNumber] = useState('')
  // state for insurance number
  const [insuranceNumber, setInsuranceNumber] = useState('')
  // state for insurance date
  const [insuranceExpDate, setInsuranceExpDate] = useState('')
  const [mpesaProcessing, setMpesaProcessing] = useState(false)
  const appointmentToView = location.state
  console.log(`Appointment `, appointmentToView)

  const dataToProceed = {
    appt_id: appointmentToView?.appt_id,
    patient_id: appointmentToView?.patient_id,
    doctor_id: appointmentToView?.doctor_id,
    health_center: { name: appointmentToView?.Health_center?.name },
    doctor: { name: appointmentToView?.doctorId?.name },
    time_slot: appointmentToView?.time_slot,
    date: appointmentToView?.date,
    reason_for_visit: appointmentToView?.reason_for_visit,
    send_reminder: appointmentToView?.send_reminder,
    email: appointmentToView?.email,
    patient_name: appointmentToView?.patient_name,
    price: appointmentToView?.price,
  }
  toast.configure()
  // abortcontroller
  const abortcontroller = new AbortController()
  let navigate = useNavigate()
  const signal = abortcontroller.signal
  const user = useSelector(selectUser)

  const searchSchemes = (company, label) => {
    // console.log(label)
    setSelectedCompany(label)
    if (company) {
      setSchemeVisible(true)
      const fetchSchemesUrl = `${process.env.REACT_APP_MAIN_URL}/api/insurance/schemes?insurance_company_id=${company}`

      fetch(fetchSchemesUrl, {
        method: 'GET',
        headers: new Headers({
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res)
          res?.data.forEach((item) => {
            item.value = item.title
            item.label = item.title

            //->will delete the rest later
          })

          // console.log(res.data.items);
          // setNurses(res.data.items);
          setSchemes(res.data)
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      setSchemeVisible(false)
    }
  }

  const generateInvoice = () => {
    const dataToProceed = {
      selectedCompany,
      selectedScheme,
      referenceNumber,
      price: location.state?.price,
      appt_id: location.state?.appt_id,
      patient_id: location.state?.patient_id,
      patient_name: location.state?.patient_name,
      insurance_number: insuranceNumber,
      insurance_exp_date: insuranceExpDate,
    }
    navigate('/insurance-invoice', { state: dataToProceed })
  }

  // const [priceset, setPriceSet] = useState(location.state.price);
  useEffect(() => {
    const fetchInsuranceUrl = `${process.env.REACT_APP_MAIN_URL}/api/insurance/insurances`

    fetch(fetchInsuranceUrl, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        res?.data.forEach((item) => {
          item.value = item.id
          item.label = item.name
          // now I no longer need them
          delete item.id
          delete item.name
          //->will delete the rest later
        })

        // console.log(res.data.items);
        // setNurses(res.data.items);
        setInsuranceCompanies(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const mpesaPay = () => {
    const dataToSend = {
      receptionist_id: user.role === 'RECEPTIONIST' && user.user_id,
      appointment_id: location.state.appt_id,
      payment_type: 'mpesa',
      amount: location.state?.price,
      phone_number: mpesanum,
    }
    //set state to processing
    setMpesaProcessing(true)

    const url = `${process.env.REACT_APP_MAIN_URL}/api/appointment/process-payment`
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        setMpesaProcessing(false)
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }
        // console.log(res, dataToSend, user.token);

        const dataToProceed = {
          appt_id: location.state.appt_id,
          patient_id: location.state.patient_id,
        }
        if (user.role === 'Patient') {
          navigate('/patient-symptoms', { state: dataToProceed })
        } else {
          navigate('/assign-to-nurse', { state: dataToProceed })
        }

        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
      .catch((err) => {
        console.log(err)
        return toast.error('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
  }

  const payLater = (method) => {
    // fetch o patch the appt to set it to paid
    const dataToSend = {
      payment_type: method,
    }
    const url = `${process.env.REACT_APP_MAIN_URL}/api/appointment/payment-type/${location.state.appt_id}`
    fetch(url, {
      method: 'PATCH',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        // if (res.error) {
        //   return toast.warning(res.error, {
        //     position: toast.POSITION.TOP_CENTER,
        //   })
        // }
        // console.log(res, dataToSend, user.token, url)
      })

      .catch((err) => {
        console.log(err)
      })

    //navigate to the patient symptoms page
    navigate('/patient-symptoms', {
      state: {
        appt_id: location.state.appt_id,
        patient_id: location.state.patient_id,
      },
    })
  }

  const makePayment = () => {
    const dataToSend = {
      receptionist_id: user.role === 'RECEPTIONIST' && user.user_id,
      appointment_id: location.state.appt_id,
      payment_type: 'cash', //cash or mpesa
      amount: location.state?.price,
    }
    console.log(dataToSend)
    // return console.log(dataToSend);
    const url = `${process.env.REACT_APP_MAIN_URL}/api/appointment/process-payment`
    // send to payment page here
    // return navigate("/payments", { state: dataToSend });
    // send request
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }
        // console.log(res, dataToSend, user.token);

        const dataToProceed = {
          appt_id: location.state.appt_id,
          patient_id: location.state.patient_id,
        }
        navigate('/assign-to-nurse', { state: dataToProceed })

        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
  }

  return (
    <div id="main-wrapper">
      {/***********************************
        Nav header start
    ************************************/}
      {/* <NavHeader/> */}
      {/***********************************
        Nav header end
    ************************************/}

      {/***********************************
        Header start
    ************************************/}
      <Header />
      {/***********************************
        Header end ti-comment-alt
    ************************************/}
      {/***********************************
        Sidebar start
    ************************************/}
      <LeftBar />
      {/***********************************
        Sidebar end
    ************************************/}
      {/***********************************
        Content body start
    ************************************/}
      <div className="content-body">
        <div className="row bg-white py-5">
          <div className="card col-12">
            <div className="card-header">
              <h4>{location.state && `Appointment Summary`}</h4>
            </div>
            <div className="card-body text-left">
              <Row>
                <Col md={4}>
                  <strong>Doctor: </strong>
                  {appointmentToView?.doctor?.name}
                </Col>
                <Col md={4}>
                  <strong>Status: </strong>
                  {/* {appointmentToView?.status} */}
                  Pending payment
                </Col>
                <Col md={4}>
                  <strong>Patient: </strong>
                  {appointmentToView?.patient_name}
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={4}>
                  <strong>Center: </strong>
                  {appointmentToView?.health_center?.name}
                </Col>
                <Col md={4}>
                  <strong>Date: </strong>
                  {formatDate(appointmentToView?.date, { locale: 'es' })}
                </Col>
                <Col md={4}>
                  <strong>Time: </strong>
                  {appointmentToView?.time_slot}
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={4}>
                  <strong>SMS reminder: </strong>
                  {appointmentToView?.send_reminder === true ? 'Yes' : 'No'}
                </Col>
                <Col md={4}>
                  <strong>Email reminder: </strong>
                  {appointmentToView?.email === true ? 'Yes' : 'No'}
                </Col>
                <Col md={4}>
                  <strong>Fee: </strong>
                  {'Ksh '}
                  {appointmentToView?.price}
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  {/* conditional statemnt to check for user role */}
                  {user.role !== 'RECEPTIONIST' && (
                    <>
                      <strong>Reason for visit: </strong>
                      {appointmentToView?.reason_for_visit}
                    </>
                  )}
                </Col>
              </Row>
            </div>

            <br />
            <div className="card-header">
              <h4>Payment options</h4>
            </div>

            <div className="card-body text-left">
              <div className="form-group mb-0">
                <label className="radio-inline mr-3">
                  <input
                    type="radio"
                    name="optradio"
                    value="cash"
                    onChange={(e) => setMethod(e.target.value)}
                  />
                  &nbsp;&nbsp; Cash
                </label>

                <label className="radio-inline mr-3">
                  <input
                    type="radio"
                    name="optradio"
                    value="mpesa"
                    onChange={(e) => setMethod(e.target.value)}
                  />
                  &nbsp;&nbsp; Mpesa
                </label>
                <label className="radio-inline mr-3">
                  <input
                    type="radio"
                    name="optradio"
                    value="insurance"
                    onChange={(e) => setMethod(e.target.value)}
                  />
                  &nbsp;&nbsp; Insurance
                </label>
              </div>
              <br />
              {user.role === 'RECEPTIONIST' ? (
                <div className={`col-sm-9 ${method !== 'cash' && 'hide'}`}>
                  <h5>Cash payment</h5>
                  <input
                    type="text"
                    className="form-control"
                    value={`Ksh ${location.state?.price}`}
                    disabled
                  />
                  <button
                    className="btn btn-primary pull-right mt-3"
                    onClick={makePayment}
                    title="Click here to proceed if you receive payment"
                  >
                    Receive
                  </button>
                </div>
              ) : (
                <div className={`col-sm-9 ${method !== 'cash' && 'hide'}`}>
                  <h5>Cash payment</h5>
                  <div className="alert alert-info">
                    <p>
                      Your slot has been reserved. You will process cash payment
                      at the reception
                    </p>
                    <button
                      className="btn btn-primary"
                      onClick={() => payLater('cash')}
                    >
                      Okay
                    </button>
                  </div>
                </div>
              )}

              <div className={`col-sm-9 ${method !== 'mpesa' && 'hide'}`}>
                <h5>Mpesa payment</h5>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Mpesa number 071234..."
                  value={mpesanum}
                  onChange={(e) => setMpesaNum(e.target.value)}
                />
                <button
                  className="btn btn-primary pull-right mt-3"
                  onClick={mpesaPay}
                  disabled={mpesanum.length !== 10 || mpesaProcessing}
                  title="Click here to Proceed Payment"
                >
                  {mpesaProcessing ? 'Processing ...' : 'Proceed'}
                </button>
              </div>
              <div className={`col-sm-9 ${method !== 'insurance' && 'hide'}`}>
                <h5>Insurance</h5>
                {user.role === 'RECEPTIONIST' ? (
                  <Link
                    to="/invoice"
                    state={dataToProceed}
                    className="btn btn-dark btn-sm"
                    title="Click to generate Invoice"
                  >
                    Generate Invoice
                  </Link>
                ) : (
                  <div className="alert alert-info">
                    <p>
                      Your appointment has been reserved. You will process
                      insurance at the reception
                    </p>
                    <button
                      className="btn btn-primary"
                      onClick={() => payLater('insurance')}
                    >
                      Okay
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* /.row*/}
        </div>
      </div>
      {/***********************************
        Content body end
    ************************************/}

      {/***********************************
        Footer start
    ************************************/}
      <Footer />
      {/***********************************
        Footer end
    ************************************/}
    </div>
  )
}

export default AppointmentPayments
