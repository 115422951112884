import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Footer from "../Footer";
import Header from "../Header";
import LeftBar from "../LeftBar";

const CheckInvoice = () => {
  const [drugs, setDrugs] = useState([]);
  const [tax, setTax] = useState(0);
  const [types, setTypes] = useState([]);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();
  const one = useRef(0);
  const two = useRef(0);
  const priceRef = useRef(0);
  const discountRef = useRef(0);
  const subTotalRef = useRef(0);
  const discount = discountRef.current.innerText;
  const subtotal = subTotalRef.current.innerText;

  const amountRef = (ref) => {
    switch (ref) {
      case 1:
        return one;
      case 2:
        return two;
      default:
        return;
    }
  };

  return (
    <div className="main-wrapper">
      {/***********************************
                  Header start
                  ************************************/}
      <Header />
      {/***********************************
                  Header end ti-comment-alt
                  ************************************/}
      {/***********************************
                  Sidebar start
                  ************************************/}
      <LeftBar />
      {/***********************************
                  Sidebar end
                  ************************************/}
      {/***********************************
                  Content body start
                  ************************************/}

      {/***********************************
                  Content body end
                  ************************************/}
      <div className="content-body">
        <>
          {/* <div
            className="invoice__item"
            style={{ height: "auto", overflow: "auto" }}
          >
            <label>Pharmacy</label>
            <div className="flex__block">
              <label>Prescription</label>
              <div className="flex__container">
                <div>
                  <select
                    style={{ width: 130, height: 23 }}
                    onChange={(e) => {
                      if (!drugs.includes(e.target.value))
                        setDrugs([...drugs, e.target.value]);
                    }}
                  >
                    <option>select</option>
                    <option>Panadol</option>
                    <option>Ronferon</option>
                  </select>
                  {drugs.map((drug, index) => (
                    <div key={index}>
                      <label style={{ marginTop: 10 }}>{drug}</label>
                    </div>
                  ))}
                </div>

                <div>
                  <select
                    style={{ width: 130, height: 23 }}
                    onChange={(e) => {
                      if (!types.includes(e.target.value))
                        setTypes([...types, e.target.value]);
                    }}
                  >
                    <option>select</option>
                    <option>Tabs</option>
                    <option>Caps</option>
                  </select>
                </div>
                <div className="flex__container">
                  {types.map((type, index) => (
                    <>
                      <div className="flex__container" key={index}>
                        <div style={{ marginLeft: 139, marginTop: -80 }}>
                          <label style={{ marginTop: 15 }}>{type}</label>
                        </div>
                      </div>
                      <div
                        className="flex__container"
                        style={{ marginTop: -17, marginBottom: 20 }}
                      >
                        <label
                          ref={priceRef}
                          className="short__label"
                          style={{ marginLeft: 150 }}
                        >
                          {3000}
                        </label>
                        <input
                          type="text"
                          className="short__input"
                          onChange={(e) =>
                            setTotal(
                              total +
                                +priceRef.current.innerText * +e.target.value -
                                +discountRef.current.innerText
                            )
                          }
                          ref={amountRef(index + 1)}
                          style={{ marginLeft: 20 }}
                        />
                        <label
                          className="short__label"
                          style={{ marginLeft: 80 }}
                          ref={discountRef}
                        >
                          {1000}
                        </label>
                        <label className="short__label">
                          {total.toFixed(2)}
                        </label>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div> */}
          <div className="invoice__item" style={{ overflowY: "scroll" }}>
            <div className="flex__container" style={{ gap: 50 }}>
              <label>Pharmacy</label>
              <div className="flex__block" style={{ gap: 20 }}>
                <label>Prescription</label>
                <div className="flex__container">
                  <select
                    style={{ width: 130, height: 23 }}
                    onChange={(e) => {
                      if (!drugs.includes(e.target.value))
                        setDrugs([...drugs, e.target.value]);
                    }}
                  >
                    <option>select</option>
                    <option>Panadol</option>
                    <option>Ronferon</option>
                  </select>
                  <select
                    style={{ width: 130, height: 23 }}
                    onChange={(e) => {
                      if (!types.includes(e.target.value))
                        setTypes([...types, e.target.value]);
                    }}
                  >
                    <option>select</option>
                    <option>Tabs</option>
                    <option>Caps</option>
                  </select>
                </div>
                <div className="flex__container">
                  <div className="flex__block" style={{ gap: 35 }}>
                    {drugs.map((drug, index) => (
                      <div key={index}>
                        <label style={{ marginTop: 10 }}>{drug}</label>
                      </div>
                    ))}
                  </div>
                  <div className="flex__block">
                    {types.map((type, index) => (
                      <>
                        <div className="flex__container" key={index}>
                          <div style={{ marginLeft: 80 }}>
                            <label style={{ marginTop: 15 }}>{type}</label>
                          </div>
                        </div>
                        <div
                          className="flex__container"
                          style={{ marginTop: -25, marginBottom: 20 }}
                        >
                          <label
                            ref={priceRef}
                            className="short__label"
                            style={{ marginLeft: 150 }}
                          >
                            {3000}
                          </label>
                          <input
                            type="text"
                            className="short__input"
                            onChange={(e) =>
                              setTotal(
                                total +
                                  +priceRef.current.innerText *
                                    +e.target.value -
                                  +discountRef.current.innerText
                              )
                            }
                            ref={amountRef(index + 1)}
                            style={{ marginLeft: 20 }}
                          />
                          <label
                            className="short__label"
                            style={{ marginLeft: 80 }}
                            ref={discountRef}
                          >
                            {1000}
                          </label>
                          <label className="short__label">
                            {total.toFixed(2)}
                          </label>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="invoice__item" style={{ justifyContent: "flex-end" }}>
            <div
              className="flex__container"
              style={{ marginRight: 15, gap: 80 }}
            >
              <span className="invoice__label" style={{ color: "#25282B" }}>
                {`subtotal Pharmacy`}
              </span>
              <label className="short__label" ref={subTotalRef}>
                {(+total + +tax) | total}
              </label>
            </div>
          </div>
          <div className="invoice__item" style={{ justifyContent: "flex-end" }}>
            <div
              className="flex__container"
              style={{ marginRight: 30, gap: 80 }}
            >
              <span className="invoice__label" style={{ color: "#25282B" }}>
                Tax
              </span>
              <input
                type="text"
                className="short__input"
                style={{ marginRight: -14 }}
                onChange={(e) => setTax(e.target.value)}
              />
            </div>
          </div>
          <div className="invoice__item" style={{ justifyContent: "flex-end" }}>
            <div
              className="flex__container"
              style={{ marginRight: 30, gap: 80 }}
            >
              <span className="invoice__label" style={{ color: "#25282B" }}>
                {`Total Pharmacy`}
              </span>
              <label className="short__label">{total}</label>
            </div>
          </div>
        </>
      </div>

      {/***********************************
                  Footer start
                  ************************************/}
      <Footer />
      {/***********************************
                  Footer end
                  ************************************/}
    </div>
  );
};
export default CheckInvoice;
