import { useState } from "react";
import { useSelector } from "react-redux";
import Header from "../Header";
import Footer from "../Footer";
import LeftBar from "../LeftBar";
import { Button, Form } from "react-bootstrap";
import Vitals from "./Vitals";
import PatientDetails from "./PatientDetails";
import Invite from "./Invite";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { selectUser } from "../../redux_setup/slices/userSlice";
import "react-toastify/dist/ReactToastify.css";

const ActivityLevel = () => {
  const [target, setTarget] = useState(0);
  const [duration, setDuration] = useState(0);
  const [recommendation, setRecommendation] = useState(0);
  const [isTargetEditable, setIsTargetEditable] = useState(false);
  const [isDurationEditable, setIsDurationEditable] = useState(false);
  const [isRecommendationEditable, setIsRecommendationEditable] =
    useState(false);
  toast.configure();
  const user = useSelector(selectUser);
  const dataToSend = {
    patient_id: "91880746-13e3-407e-a33a-5382183d073f",
    doctor_id: "458691eb-480c-4e68-ab18-7a17c18abd68",
    target_activity_mins: target,
    duration_target_activity_mins: duration,
    recommendation_target_activity_mins: recommendation,
  };
  const handleSubmit = () => {
    fetch(`${process.env.REACT_APP_MAIN_URL_HYPERTENSION}/api/goals/activity`, {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + user.token,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        // navigate("/careplan-weight-loss");
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
          });
        }
        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        });
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        return toast.warning("An error has occured while reaching the server", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        });
      });
  };
  return (
    <div className='main-wrapper'>
      {/***********************************
                  Header start
                  ************************************/}
      <Header />
      {/***********************************
                  Header end ti-comment-alt
                  ************************************/}
      {/***********************************
                  Sidebar start
                  ************************************/}
      <LeftBar />
      {/***********************************
                  Sidebar end
                  ************************************/}
      {/***********************************
                  Content body start
                  ************************************/}

      {/***********************************
                  Content body end
                  ************************************/}
      <div className='content-body'>
        <p
          className='mt-3 mb-3'
          style={{
            fontSize: 20,
            fontFamily: "Lato",
            fontWeight: 700,
            color: "#000",
          }}
        >
          Patient Details
        </p>
        <div className='row'>
          <div className='col-md-7 col-lg-7 col-sm-12'>
            <PatientDetails />
            <p
              className='mt-3 mb-3'
              style={{
                fontSize: 20,
                fontFamily: "Lato",
                fontWeight: 700,
                color: "#000",
              }}
            >
              Activity Health Outcomes
            </p>
            <div className='card pt-4 pl-3' style={{ width: 654, height: 84 }}>
              <div className='d-flex' style={{ gap: 100 }}>
                <div className='d-flex ml-4' style={{ gap: 10 }}>
                  <Form.Check id='activity level' className='mt-1' />
                  <label
                    htmlFor='activity level'
                    style={{
                      fontFamily: "Lato",
                      fontSize: 18,
                      fontWeight: 400,
                      color: "#616161",
                    }}
                  >
                    Activity Level
                  </label>
                </div>
              </div>
            </div>
            <div className='card pt-4 pl-3' style={{ width: 654, height: 650 }}>
              <p
                style={{
                  fontWeight: 400,
                  fontFamily: "Lato",
                  fontSize: 16,
                  color: "#000",
                }}
              >
                Increased activity level
              </p>
              <div className='d-flex' style={{ gap: 30 }}>
                <div
                  className='card pt-3 pl-3 pr-2 pb-3'
                  style={{
                    width: 288,
                    backgroundColor: "#D0E0FF80",
                  }}
                >
                  <div
                    className='card'
                    style={{
                      width: 255,
                      height: 35,
                      backgroundColor: "#0F56B333",
                      textAlign: "center",
                    }}
                  >
                    <p
                      className='mt-1'
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        fontFamily: "Lato",
                        color: "#000",
                      }}
                    >
                      Current activity status
                    </p>
                  </div>
                  <div className='d-flex' style={{ gap: 15 }}>
                    <span
                      style={{
                        fontSize: 55,
                        fontFamily: "Lato",
                        fontWeight: 700,
                        color: "#19226D",
                      }}
                    >
                      0
                    </span>
                    <span
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        fontFamily: "Lato",
                        color: "#000",
                      }}
                      className='mt-5'
                    >
                      min/wk
                    </span>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <span
                      style={{
                        fontSize: 10,
                        fontWeight: 400,
                        fontFamily: "Lato",
                        color: "#000",
                      }}
                    >
                      Updated on: 13/12/2022
                    </span>
                  </div>
                  <div
                    className='mt-2 mr-2 mb-2'
                    style={{ backgroundColor: "#000", width: 252, height: 1 }}
                  />
                  <div
                    style={{
                      width: 252,
                      height: 29,
                      borderRadius: 2,
                      backgroundColor: "#FFF",
                      textAlign: "center",
                    }}
                  >
                    <p>
                      Prev reading: 0min/wk
                      <i class='fas-solid fas-arrow-up-right-from-square'></i>
                    </p>
                  </div>
                </div>
                <div
                  className='card pt-3 pl-3 pr-2 pb-3'
                  style={{
                    width: 288,
                    backgroundColor: "#D0E0FF80",
                  }}
                >
                  <div
                    className='card'
                    style={{
                      width: 255,
                      height: 35,
                      backgroundColor: "#0F56B333",
                      textAlign: "center",
                    }}
                  >
                    <p
                      className='mt-1'
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        fontFamily: "Lato",
                        color: "#000",
                      }}
                    >
                      Target activity
                    </p>
                  </div>
                  {isTargetEditable ? (
                    <Form.Control
                      placeholder='Enter target'
                      value={target}
                      onChange={(e) => setTarget(e.target.value)}
                      onBlur={() => setIsTargetEditable(false)}
                    />
                  ) : (
                    <div className='d-flex' style={{ gap: 15 }}>
                      <span
                        style={{
                          fontSize: 55,
                          fontFamily: "Lato",
                          fontWeight: 700,
                          color: "#19226D",
                        }}
                      >
                        {target}
                      </span>
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          fontFamily: "Lato",
                          color: "#000",
                        }}
                        className='mt-5'
                      >
                        min/wk
                      </span>
                    </div>
                  )}
                  <div className='d-flex justify-content-end'>
                    <span
                      style={{
                        fontSize: 10,
                        fontWeight: 400,
                        fontFamily: "Lato",
                        color: "#000",
                      }}
                    >
                      edited on: 13/12/2022
                    </span>
                  </div>
                  <div
                    className='mt-2 mr-2 mb-2'
                    style={{ backgroundColor: "#000", width: 252, height: 1 }}
                  />
                  <div className='d-flex justify-content-between'>
                    <div style={{ cursor: "pointer" }}>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/icons/edit-icon.png`}
                        alt='Edit icon'
                        width={13}
                        height={14}
                      />
                      <span
                        className='ml-2'
                        style={{ color: "#19226D", fontSize: 10 }}
                        onClick={() => setIsTargetEditable(true)}
                      >
                        Edit
                      </span>
                    </div>
                    <div
                      style={{
                        width: 160,
                        height: 29,
                        borderRadius: 2,
                        backgroundColor: "#FFF",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          fontFamily: "Lato",
                          color: "#000",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Target duration 15min/wk
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='mt-2 mr-2 mb-4'
                style={{ backgroundColor: "#000", width: 604, height: 1 }}
              />
              <div className='d-flex' style={{ gap: 30 }}>
                <div
                  className='card pt-3 pl-3 pr-2 pb-3'
                  style={{
                    width: 288,
                    backgroundColor: "#D0E0FF80",
                  }}
                >
                  <div
                    className='card'
                    style={{
                      width: 255,
                      height: 35,
                      backgroundColor: "#0F56B333",
                      textAlign: "center",
                    }}
                  >
                    <p
                      className='mt-1'
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        fontFamily: "Lato",
                        color: "#000",
                      }}
                    >
                      Duration
                    </p>
                  </div>
                  {isDurationEditable ? (
                    <Form.Control
                      placeholder='Enter target'
                      value={duration}
                      onChange={(e) => setDuration(e.target.value)}
                      onBlur={() => setIsDurationEditable(false)}
                    />
                  ) : (
                    <div className='d-flex' style={{ gap: 15 }}>
                      <span
                        style={{
                          fontSize: 55,
                          fontFamily: "Lato",
                          fontWeight: 700,
                          color: "#19226D",
                        }}
                      >
                        {duration}
                      </span>
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          fontFamily: "Lato",
                          color: "#000",
                        }}
                        className='mt-5'
                      >
                        Weeks
                      </span>
                    </div>
                  )}
                  <div className='d-flex justify-content-end'>
                    <span
                      style={{
                        fontSize: 10,
                        fontWeight: 400,
                        fontFamily: "Lato",
                        color: "#000",
                      }}
                    >
                      Updated on: 13/12/2022
                    </span>
                  </div>
                  <div
                    className='mt-2 mr-2 mb-2'
                    style={{ backgroundColor: "#000", width: 252, height: 1 }}
                  />
                  <div className='d-flex justify-content-between'>
                    <div style={{ cursor: "pointer" }}>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/icons/edit-icon.png`}
                        alt='Edit icon'
                        width={13}
                        height={14}
                      />
                      <span
                        className='ml-2'
                        style={{ color: "#19226D", fontSize: 10 }}
                        onClick={() => setIsDurationEditable(true)}
                      >
                        Edit
                      </span>
                    </div>
                    <div
                      style={{
                        width: 71,
                        height: 29,
                        borderRadius: 2,
                        backgroundColor: "#FFF",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          fontFamily: "Lato",
                          color: "#000",
                        }}
                      >
                        36/36
                        <i class='fas-solid fas-arrow-up-right-from-square'></i>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className='card pt-3 pl-3 pr-2 pb-3'
                  style={{
                    width: 288,
                    backgroundColor: "#D0E0FF80",
                  }}
                >
                  <div
                    className='card'
                    style={{
                      width: 255,
                      height: 35,
                      backgroundColor: "#D60A5180",
                      textAlign: "center",
                    }}
                  >
                    <p
                      className='mt-1'
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        fontFamily: "Lato",
                        color: "#000",
                      }}
                    >
                      Recommendation
                    </p>
                  </div>
                  {isRecommendationEditable ? (
                    <Form.Control
                      placeholder='Enter target'
                      value={recommendation}
                      onChange={(e) => setRecommendation(e.target.value)}
                      onBlur={() => setIsRecommendationEditable(false)}
                    />
                  ) : (
                    <div className='d-flex' style={{ position: "relative" }}>
                      <span
                        style={{
                          fontSize: 64,
                          fontFamily: "Lato",
                          fontWeight: 700,
                          color: "#D60A5133",
                        }}
                      >
                        {recommendation}
                      </span>
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          fontFamily: "Lato",
                          color: "#000",
                          position: "absolute",
                          bottom: 10,
                          right: 100,
                        }}
                      >
                        min/wk
                      </span>
                    </div>
                  )}
                  <div className='d-flex justify-content-end'>
                    <span
                      style={{
                        fontSize: 10,
                        fontWeight: 400,
                        fontFamily: "Lato",
                        color: "#000",
                      }}
                    >
                      Updated on: 13/12/2022
                    </span>
                  </div>
                  <div
                    className='mt-2 mr-2 mb-2'
                    style={{ backgroundColor: "#000", width: 252, height: 1 }}
                  />
                  <div className='d-flex justify-content-between'>
                    <div style={{ cursor: "pointer" }}>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/icons/edit-icon.png`}
                        alt='Edit icon'
                        width={13}
                        height={14}
                      />
                      <span
                        className='ml-2'
                        style={{ color: "#19226D", fontSize: 10 }}
                        onClick={() => {
                          setIsRecommendationEditable(true);
                        }}
                      >
                        Edit
                      </span>
                    </div>
                    <div
                      style={{
                        width: 111,
                        height: 29,
                        borderRadius: 2,
                        backgroundColor: "#FFF",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 12,
                          fontWeight: 400,
                          fontFamily: "Lato",
                          color: "#000",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Increase by 15mi/wk
                        <i class='fas-solid fas-arrow-up-right-from-square'></i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p
              className='mt-3 mb-3'
              style={{
                fontSize: 20,
                fontFamily: "Lato",
                fontWeight: 700,
                color: "#000",
              }}
            >
              Activity Recommendation Notes
            </p>
            <div className='card mt-4 pt-4 pl-3' style={{ height: 108 }}>
              <span>Cardio min/week + Strength/Toning min/week</span>
            </div>
            <Invite />
          </div>
          <div className='col-md-5 col-lg-5 col-sm-12'>
            <Vitals />
          </div>
        </div>
        <div className='d-flex justify-content-end' style={{ gap: 15 }}>
          <Link to='/careplan-weight-loss'>
            <Button variant='outline-primary'>
              <i class='fa fa-arrow-left mr-3' />
              Back
            </Button>
          </Link>
          {/* <Link to='/careplan-habit-breaker'> */}
          <Button variant='primary' onClick={handleSubmit}>
            Next
            <i class='fa fa-arrow-right ml-3' />
          </Button>
          {/* </Link> */}
        </div>
      </div>
      {/***********************************
                  Footer start
                  ************************************/}
      <Footer />
      {/***********************************
                  Footer end
                  ************************************/}
    </div>
  );
};

export default ActivityLevel;
