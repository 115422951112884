import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import LeftBar from '../components/LeftBar'
import Select from 'react-select'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { tConv24 } from '../constants/Essentials'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const BookFollowUp = () => {
  const location = useLocation()
  const prevAppt = location.state
  toast.configure()
  // abortcontroller
  const abortcontroller = new AbortController()
  let navigate = useNavigate()
  const signal = abortcontroller.signal
  const user = useSelector(selectUser)

  const [slots, setSlots] = useState([])

  const [dateSelected, setDateSelected] = useState('')
  const [slotSelected, setSlotSelected] = useState()
  const [reason, setReason] = useState('')

  const bookFollowUp = () => {
    const part = user.role === 'DOCTOR' ? 'doctor-request' : 'patient-request'
    const url = `${process.env.REACT_APP_MAIN_URL}/api/follow-up/${part}`
    const dataToSend = {
      appointment_id: prevAppt.app_id,
      patient_id: user.role === 'DOCTOR' ? prevAppt.patient_id : undefined,
      time_slot: slotSelected?.time_slot,
      date: dateSelected,
      reason: reason,
    }
    console.log(dataToSend)
    fetch(url, {
      signal: signal,
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }

        navigate(-1)

        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
  }

  const searchSlots = () => {
    //get selected doctor
    if (dateSelected !== '') {
      const hc = prevAppt.health_center
      const fetchSlotsUrl = `${process.env.REACT_APP_MAIN_URL}/api/appointment/?health_center_id=${hc}&doctor_id=${prevAppt.doctor_id}&date=${dateSelected}`
      fetch(fetchSlotsUrl, {
        method: 'GET',
        headers: new Headers({
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            return toast.warning(
              'An error occured while fetching center users',
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 15000,
              },
            )
          }

          res.data.forEach((item) => {
            item.value = item.id
            item.label = tConv24(item.time_slot)
            // now I no longer need them
            // delete item.id;
            // delete item.time_slot;
            //->will delete the rest later
          })

          setSlots(res.data)
          setSlotSelected(null)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  return (
    <div id="main-wrapper">
      {/***********************************
        Nav header start
    ************************************/}
      {/* <NavHeader/> */}
      {/***********************************
        Nav header end
    ************************************/}

      {/***********************************
        Header start
    ************************************/}
      <Header />
      {/***********************************
        Header end ti-comment-alt
    ************************************/}
      {/***********************************
        Sidebar start
    ************************************/}
      <LeftBar />
      {/***********************************
        Sidebar end
    ************************************/}
      {/***********************************
        Content body start
    ************************************/}
      <div className="content-body">
        <div className="row bg-white py-5">
          <div className="col-lg-9 mx-auto">
            <form id="contact-form" role="form">
              <div className="controls">
                <div className="row">
                  <h4>{location.state && `Booking Follow up Appointment `}</h4>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="date">
                        Date <span className="required">*</span>
                      </label>{' '}
                      <input
                        id="date"
                        type="date"
                        min={new Date().toISOString().split('T')[0]}
                        className="form-control"
                        value={dateSelected}
                        onChange={(e) => setDateSelected(e.target.value)}
                        onBlur={searchSlots}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="slot">
                        Time <span className="required">*</span>
                      </label>
                      <Select
                        options={slots}
                        id="slot"
                        defaultValue={{ value: '', label: 'Select a slot' }}
                        placeholder="select slot"
                        value={slotSelected}
                        onChange={(selectedOption) =>
                          setSlotSelected(selectedOption)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div>
                      <label htmlFor="reason">
                        Reason <span className="required">*</span>
                      </label>
                    </div>
                    <div>
                      <textarea
                        value={reason}
                        id="reason"
                        onChange={(e) => setReason(e.target.value)}
                        className="form-control"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 ">
                    <button
                      className="btn btn-dark btn-send pt-2 rounded btn-block shadow center-align"
                      onClick={() => navigate(-1)}
                      type="button"
                      title="Click to return to previous page"
                      style={{ marginBottom: 20 }}
                    >
                      Go back
                    </button>
                  </div>
                  <div className="col-md-4 ">
                    <button
                      className="btn btn-success btn-send pt-2 rounded btn-block shadow center-align"
                      defaultValue="Book"
                      onClick={bookFollowUp}
                      type="button"
                      disabled={!slotSelected?.time_slot || !dateSelected}
                      title="Click to book follow up"
                    >
                      Book follow up
                    </button>
                  </div>
                  <div className="col-md-4 "></div>
                </div>
              </div>
            </form>
          </div>
          {/* /.row*/}
        </div>
      </div>
      {/***********************************
        Content body end
    ************************************/}

      {/***********************************
        Footer start
    ************************************/}
      <Footer />
      {/***********************************
        Footer end
    ************************************/}
    </div>
  )
}

export default BookFollowUp
