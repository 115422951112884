import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const DietNutrition = ({ data }) => {
  const navigate = useNavigate()
  return (
    <div className="card pl-2 pr-2 pb-5 mt-2">
      <p className="meal-profile-text" style={{ color: '#000', fontSize: 20 }}>
        Diet & Nutrition Care Plan
      </p>
      {/* <div className='dont-show-on-mobile'> */}
      <div
        className="card pl-4 pr-4 careplan-title mt-2"
        style={{
          background: 'rgba(171, 179, 253, 0.25)',
          height: 35,
          width: '98%',
        }}
      >
        <p
          className="meal-profile-text pr-2"
          style={{
            color: '#000',
            fontWeight: 700,
            fontSize: 20,
            fontFamily: 'Lato',
          }}
        >
          Suggested Outcome: {data?.nutrition_suggested_outcome}
        </p>
      </div>
      <div className="row">
        <div className="col-md-4 col-lg-4 col-6">
          <div
            className="card pl-2 pt-2 pr-2"
            style={{ backgroundColor: '#6623823D' }}
          >
            <div
              className="card pl-2 pt-2"
              style={{ background: 'rgba(102, 35, 130, 0.24)' }}
            >
              <p className="mb-text">Daily Calorie Intake</p>
              <span className="show-on-mobile mb-2" style={{ fontSize: 12 }}>
                (kcal)
              </span>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: -20 }}
            >
              <div>
                <small
                  style={{
                    fontSize: 10,
                    fontWeight: 400,
                    fontFamily: 'Lato',
                    color: '#0D5CA4',
                  }}
                  className="ml-3"
                >
                  Current
                </small>
                <br />
                <small className="ml-3 mt-2">
                  400
                  <small
                    className="dont-show-on-mobile"
                    style={{ fontSize: 14 }}
                  >
                    kcal
                  </small>
                </small>
              </div>
              <div>
                <small
                  style={{
                    fontSize: 10,
                    fontWeight: 400,
                    fontFamily: 'Lato',
                    color: '#0D5CA4',
                  }}
                  className="ml-3"
                >
                  Target
                </small>
                <br />
                <small className="ml-3 mt-2">
                  {data?.nutrition_target_calorie || 0}{' '}
                  <small
                    className="dont-show-on-mobile"
                    style={{ fontSize: 14 }}
                  >
                    kcal
                  </small>
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-lg-4 col-6">
          <div
            className="card pl-2 pt-2 pr-2"
            style={{ background: 'rgba(7, 176, 215, 0.24)' }}
          >
            <div
              className="card pl-2 pt-2"
              style={{ background: 'rgba(7, 176, 215, 0.24)' }}
            >
              <p className="mb-text">Daily Sodium Intake</p>
              <span className="show-on-mobile" style={{ fontSize: 12 }}>
                (mEq/L)
              </span>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: -20 }}
            >
              <div>
                <small
                  style={{
                    fontSize: 10,
                    fontWeight: 400,
                    fontFamily: 'Lato',
                    color: '#0D5CA4',
                  }}
                  className="ml-3"
                >
                  Current
                </small>
                <br />
                <small
                  className="ml-3 mt-2"
                  title="click to edit"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  1000
                  <small
                    className="dont-show-on-mobile"
                    style={{ fontSize: 12 }}
                  >
                    mEq/L
                  </small>
                </small>
              </div>
              <div>
                <small
                  style={{
                    fontSize: 10,
                    fontWeight: 400,
                    fontFamily: 'Lato',
                    color: '#0D5CA4',
                  }}
                  className="ml-3"
                >
                  Target
                </small>
                <br />
                <small
                  className="ml-3 mt-2"
                  title="click to edit"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {data?.nutrition_target_sodium || 0}{' '}
                  <small
                    className="dont-show-on-mobile"
                    style={{ fontSize: 12 }}
                  >
                    mEq/L
                  </small>
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-12">
          <div
            className="card pl-2 pt-2 pr-2"
            style={{ backgroundColor: '#E7EFFF' }}
          >
            <div
              className="card pl-2 pt-2"
              style={{ background: 'rgba(15, 86, 179, 0.24)' }}
            >
              <p className="mb-text">Daily Fat Intake</p>
              <span className="show-on-mobile mb-2" style={{ fontSize: 12 }}>
                (kcal)
              </span>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: -20 }}
            >
              <div>
                <small
                  style={{
                    fontSize: 10,
                    fontWeight: 400,
                    fontFamily: 'Lato',
                    color: '#0D5CA4',
                  }}
                  className="ml-3 mt-2"
                >
                  Current
                </small>
                <br />
                <small className="ml-3 mt-2" style={{ whiteSpace: 'nowrap' }}>
                  1000
                  <small
                    className="dont-show-on-mobile"
                    style={{ fontSize: 12 }}
                  >
                    kcal
                  </small>
                </small>
              </div>
              <div>
                <small
                  style={{
                    fontSize: 10,
                    fontWeight: 400,
                    fontFamily: 'Lato',
                    color: '#0D5CA4',
                  }}
                  className="ml-3 mt-2"
                >
                  Target
                </small>
                <br />
                <small className="ml-3 mt-2" style={{ whiteSpace: 'nowrap' }}>
                  {data?.nutrition_target_fat || 0}{' '}
                  <small
                    className="dont-show-on-mobile"
                    style={{ fontSize: 12 }}
                  >
                    kcal
                  </small>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button
        style={{
          backgroundColor: '#11195A',
          width: '100%',
          height: '20%',
        }}
        className="mt-2 mb-2"
        onClick={() => navigate('/new-nutrition')}
      >
        Update Nutrition Care Plan
      </Button>
    </div>
  )
}

export default DietNutrition
