const InvoiceHeader = ({
  invoiceNo,date,fileNo,patientNo,insurance,scheme,insuranceNo,principalNo
}) => {
  return (
    <>
      <div className="flex__container" style={{ gap: 50 }}>
        <img src={`${process.env.PUBLIC_URL}/images/clinicklogo.png`} />
        <div className="flex__block">
          <span className="header__title" style={{ color: "#25282B" }}>
            Street Name, Suit 00, State,
            <br />
            Zip Code, Country
          </span>
          <span className="header__title">
            <span style={{ color: "#45B757" }}>t:&nbsp;&nbsp;</span>+123 00 456
            7890
          </span>
          <span className="header__title">
            <span style={{ color: "#45B757" }}>e:&nbsp;&nbsp;</span>
            email@doctorschamber.com
          </span>
        </div>
      </div>
      <div className="flex__container" style={{ justifyContent: "flex-end" }}>
        <span className="invoice__title">INVOICE</span>
      </div>
      {/* Left section */}
      <div className="row" style={{marginTop: 130}}>
        <div className="col-md-6 col-sm-12">
          <div className="flex__container">
            <label className="invoice__label">INVOICE NO:</label>
            <input type="text" className="invoice__input" onChange={invoiceNo}/>
          </div>
          <div className="flex__container">
            <label className="invoice__label">DATE:</label>
            <input
              type="date"
              className="invoice__input"
              style={{ width: 272 }}
              onChange={date}
            />
          </div>
          <div className="flex__container">
            <label className="invoice__label">OUTPATIENT FILE NO:</label>
            <input
              type="text"
              className="invoice__input"
              style={{ width: 165 }}
              onChange={fileNo}
            />
          </div>
          <div className="flex__container">
            <label className="invoice__label">PATIENTS NO:</label>
            <input
              type="text"
              className="invoice__input"
              style={{ width: 220 }}
              onChange={patientNo}
            />
          </div>
        </div>
        {/* Right section */}
        <div className="col-md-6 col-sm-12">
          <div style={{marginLeft: 55}}>
          <div className="flex__container">
            <label className="invoice__label">INSURANCE PROVIDER:</label>
            <input type="text" className="invoice__input" style={{width: 232}} onChange={insurance} />
          </div>
          <div className="flex__container">
            <label className="invoice__label">SCHEME:</label>
            <input
              type="text"
              className="invoice__input"
              style={{ width: 330 }}
              onChange={scheme}
            />
          </div>
          <div className="flex__container">
            <label className="invoice__label">INSURANCE MEMBERSHIP NO:</label>
            <input
              type="text"
              className="invoice__input"
              style={{ width: 186 }}
              onChange={insuranceNo}
            />
          </div>
          <div className="flex__container">
            <label className="invoice__label">PRINCIPAL MEMBER’S NAME:</label>
            <input
              type="text"
              className="invoice__input"
              style={{ width: 196 }}
              onChange={principalNo}
            />
          </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InvoiceHeader;
