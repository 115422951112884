import React, { useRef, useState, useEffect } from 'react'
import moment from 'moment/moment'
import ReactToPrint from 'react-to-print'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { updateTests, selectTests } from '../../redux_setup/slices/testSlice'
import { selectUser } from '../../redux_setup/slices/userSlice'
import Footer from '../Footer'
import Header from '../Header'
import LeftBar from '../LeftBar'
import Table from 'react-bootstrap/Table'
import Swal from 'sweetalert2'
import { ReduxActions } from '../../constants/redux_actions'
import { toast } from 'react-toastify'
import { calculateAge } from '../../constants/Essentials'
const LabTestRequested = () => {
  toast.configure()
  const [realTime, setRealTime] = useState()
  // const mainTest = useSelector(updateTests)
  moment.locale()
  const navigate = useNavigate()
  const location = useLocation()
  const componentRef = useRef()
  const user = useSelector(selectUser)
  const testsArray = useSelector(selectTests)
  const [labsEmail, setLabsEmail] = useState('')
  const dispatch = useDispatch()
  const patientDetails = location.state
  const selected = location.state?.tests.filter(
    (test) => test.expanded === true,
  )

  const removeMainTest = (name) => {
    Swal.fire({
      title: 'Are you sure you want to remove this test with all its items?',
      showCancelButton: true,
      icon: 'question',
      width: '600',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log('Booked Follow Up!', '', 'success')

        dispatch(
          updateTests({
            val: name,
            action_type: ReduxActions.expand_test_option,
          }),
        )
      } else {
        console.log('cancelled')
        // navigate('/')
      }
    })
  }
  const sendLabTestsEmail = () => {
    console.log('sending', location?.state?.tests)

    const arrayToSend = []
    selected.map((test) => {
      const obj = {}
      obj.name = test.name
      obj.requestedTime = location.state?.timeRequested
      obj.status = 'Pending'

      arrayToSend.push(obj)
    })
    const dataToSend = {
      patient_name: patientDetails?.name,
      patient_email: patientDetails?.email,
      labtech_email: labsEmail,
      labTestsData: arrayToSend,
      doctor_name: user?.userName,
    }

    const url = `${process.env.REACT_APP_MAIN_URL}/api/doctor/send-labtests`
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }
        setLabsEmail('')

        // navigate('/')

        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
      .catch((err) => {
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
        })
      })
  }
  ///sub test removal
  const removeSubTest = (motherObject, child) => {
    Swal.fire({
      title: 'Are you sure you want to remove this test?',
      showCancelButton: true,
      icon: 'question',
      width: '600',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('Booked Follow Up!', motherObject, child)

        dispatch(
          updateTests({
            motherObject,
            child,
            action_type: ReduxActions.change_child_option,
          }),
        )
      } else {
        console.log('cancelled')
        // navigate('/')
      }
    })
  }
  const submitTests = () => {
    const toSend = {
      appointment_id: patientDetails?.appt_id,
      patient_id: patientDetails?.patient_id,
      tests_to_be_done: {
        tests: testsArray,
        date: moment().format('YYYY-MM-DD'),
        timeRequested: moment().format('h:mm:ss a'),
        doctor: user?.userName,
      },
    }
    console.log(toSend)
    // return console.log(dataToSend);
    const url = `${process.env.REACT_APP_MAIN_URL}/api/general_consultation/labtest`
    // send to payment page here
    // return navigate("/payments", { state: dataToSend });
    // send request
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(toSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }

        return toast.success('Lab tests request saved successfully', {
          position: toast.POSITION.TOP_CENTER,
        })
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
  }

  // useEffect(() => {
  // setInterval(() => {
  //   // setRealTime(moment().format('h:mm:ss a'))
  // }, 5000)
  // }, [])
  return (
    <div className="main-wrapper">
      {/***********************************
                    Header start
                    ************************************/}
      <Header />
      {/***********************************
                    Header end ti-comment-alt
                    ************************************/}
      {/***********************************
                    Sidebar start
                    ************************************/}
      <LeftBar />
      {/***********************************
                    Sidebar end
                    ************************************/}
      {/***********************************
                    Content body start
                    ************************************/}

      {/***********************************
                    Content body end
                    ************************************/}
      <div className="content-body">
        <div ref={componentRef} style={{ marginLeft: 20, marginTop: 30 }}>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="flex__container" style={{ gap: 50 }}>
                <img src={`${process.env.PUBLIC_URL}/images/clinicklogo.png`} />
                <div className="flex__block">
                  <span className="header__title" style={{ color: '#25282B' }}>
                    Street Name, Suit 00, State,
                    <br />
                    Zip Code, Country
                  </span>
                  <span className="header__title">
                    <span style={{ color: '#45B757' }}>t:&nbsp;&nbsp;</span>+123
                    00 456 7890
                  </span>
                  <span className="header__title">
                    <span style={{ color: '#45B757' }}>e:&nbsp;&nbsp;</span>
                    email@doctorschamber.com
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div style={{ marginLeft: 200 }}>
                <p style={{ color: '#45B757' }}>Patient details</p>
                <div className="flex__container">
                  <label className="invoice__label">
                    <b className="text-dark">Name: </b>
                    {patientDetails?.name}
                  </label>
                  <label className="invoice__label">
                    <b className="text-dark">Gender: </b>
                    {patientDetails?.gender}
                  </label>
                </div>
                <div className="flex__container">
                  <label className="invoice__label">
                    <b className="text-dark">Age: </b>
                    {calculateAge(patientDetails?.age)} years
                  </label>
                  <label className="invoice__label">
                    <b className="text-dark">Phone: </b>
                    {patientDetails?.phone}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: 100, marginBottom: 50 }}>
            {user.role === 'DOCTOR' && (
              <div className="col-md-8 col-sm-12">
                <div className="flex__block">
                  <label className="header__title" style={{ color: '#25282B' }}>
                    Dr {user?.userName}
                  </label>
                  {/* <label className="header__title" style={{ color: '#25282B' }}>
                  REG: 12784908
                </label> */}
                </div>
              </div>
            )}

            <div className="col-md-4 col-sm-12">
              {new Date().toLocaleDateString()}
            </div>
          </div>

          <Table striped bordered hover style={{ width: '99%' }}>
            <thead>
              <tr>
                <th style={{ backgroundColor: '#E5E5E5' }}>Doctor</th>
                <th style={{ backgroundColor: '#E5E5E5' }}>Date</th>
                <th style={{ backgroundColor: '#E5E5E5' }}>Time</th>
                <th style={{ backgroundColor: '#E5E5E5' }}>Test Requested</th>
                <th style={{ backgroundColor: '#E5E5E5' }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {selected.map((selectedtest, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td style={{ height: 50 }}>{user?.userName}</td>
                    <td style={{ height: 50 }}>
                      {new Date().toLocaleDateString()}
                    </td>
                    <td style={{ height: 50 }}>
                      {location.state?.timeRequested}
                    </td>
                    <td style={{ color: '#25282B' }}>
                      <div
                        className="d-flex flex-row justify-content-between"
                        key={index}
                      >
                        {selectedtest.name}
                        <div className="cancel__btn"></div>
                      </div>
                    </td>
                    <td>Pending</td>
                  </tr>
                  {selectedtest?.selectedChildren?.length > 0 && (
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        {selectedtest?.selectedChildren.map((test, index) => (
                          <div
                            className="d-flex flex-row justify-content-between"
                            key={index}
                          >
                            <label key={index}>{`${index + 1}. ${test}`}</label>
                            <div className="cancel__btn"></div>
                          </div>
                        ))}
                      </td>
                      <td></td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="form-inline mr-5">
            <div className="form-group">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Lab tech email"
                  value={labsEmail}
                  onChange={(e) => setLabsEmail(e.target.value)}
                />

                <button
                  className="btn btn-info not__printed"
                  disabled={!labsEmail}
                  onClick={sendLabTestsEmail}
                >
                  Email
                </button>
              </div>
            </div>
          </div>
          <div
            className="flex__container"
            style={{ justifyContent: 'flex-end' }}
          >
            <button className="btn btn-info" onClick={() => navigate(-1)}>
              <i class="fa-solid fa-arrow-left-long"></i>Back
            </button>
            <ReactToPrint
              trigger={() => (
                <button
                  className="btn btn-success not__printed"
                  style={{ background: '#45B757' }}
                >
                  Print
                </button>
              )}
              content={() => componentRef.current}
              documentTitle="Requested Lab Tests"
            />
          </div>
        </div>
      </div>

      {/***********************************
                    Footer start
                    ************************************/}
      <Footer />
      {/***********************************
                    Footer end
                    ************************************/}
    </div>
  )
}
export default LabTestRequested
