import React, { useState } from 'react'
import Footer from '../../Footer'
import Header from '../../Header'
import LeftBar from '../../LeftBar'
import NutritionGraph from './NutritionGraph'
import GraphVital from './GraphVital'
import { useLocation } from 'react-router-dom'
const GraphContainer = () => {
  const [active, setActive] = useState({
    vitalActive: true,
    nutritionActive: false,
  })

  const location = useLocation()
  console.log("patient stuff=>",location)
  return (
    <div className="main-wrapper">
      {/***********************************
        Header start
        ************************************/}
      <Header />
      {/***********************************
        Header end ti-comment-alt
        ************************************/}
      {/***********************************
        Sidebar start
        ************************************/}
      <LeftBar />
      {/***********************************
        Sidebar end
        ************************************/}
      {/***********************************
        Content body start
        ************************************/}

      {/***********************************
        Content body end
        ************************************/}
      <div className="content-body">
        <p className="vital__subtopic">CarePlan</p>
        <div className="custom__tab">
          {active.vitalActive ? (
            <div
              className="num__container"
              style={{ backgroundColor: '#19226D', color: '#FFF' }}
            >
              <i class="fa-sharp fa-solid fa-check"></i>
            </div>
          ) : (
            <div className="num__container">1</div>
          )}
          <p
            className="title"
            style={{ margin: '10px 0 0 50px', cursor: 'pointer' }}
            onClick={() => {
              active['vitalActive'] = true
              active['nutritionActive'] = false
              setActive({
                ...active,
              })
            }}
          >
            Vitals
          </p>
          <div className="tab__separator__top" />
          <div className="tab__separator__bottom" />
          {active.nutritionActive ? (
            <div
              className="num__container"
              style={{
                backgroundColor: '#19226D',
                color: '#FFF',
                left: '30%',
                position: 'absolute',
              }}
            >
              <i class="fa-sharp fa-solid fa-check"></i>
            </div>
          ) : (
            <div
              className="num__container"
              style={{ left: '30%', position: 'absolute' }}
            >
              2
            </div>
          )}
          <p
            className="title"
            style={{
              position: 'absolute',
              left: '38%',
              marginTop: 20,
              cursor: 'pointer',
            }}
            onClick={() => {
              active['vitalActive'] = false
              active['nutritionActive'] = true
              setActive({
                ...active,
              })
            }}
          >
            Nutrition
          </p>
          <div style={{ marginLeft: 140 }}>
            <div className="tab__separator__top" />
            <div className="tab__separator__bottom" />
          </div>
        </div>
        {active.vitalActive ? (
          <GraphVital patientDetails={location.state} />
        ) : (
          <NutritionGraph patientDetails={location.state} />
        )}
      </div>

      {/***********************************
        Footer start
        ************************************/}
      <Footer />
      {/***********************************
        Footer end
        ************************************/}
    </div>
  )
}
export default GraphContainer
