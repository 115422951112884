import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import MainApp from './calendar/MainApp'
import AppointmentActivity from './AppointmentActivity'
import { Table } from 'react-bootstrap'
import PatientAppointments from './PatientAppointments.jsx'
import { formatDate } from '@fullcalendar/react'
import { forceUpdate } from '../constants/Essentials'
import { Formik, Form, Field, ErrorMessage, FastField } from 'formik'
import { PatientAdddedSchema } from '../constants/ValidationSchema'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const PatientDetails = ({ notPatient }) => {
  toast.configure()
  const location = useLocation()
  let navigate = useNavigate()
  const profile = location.state
  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  const user = useSelector(selectUser)
  // states
  const [height, setHeight] = useState(0)
  const [weight, setWeight] = useState(0)
  const [bmi, setBmi] = useState('')

  const [foodAllergy, setFoodAllergy] = useState(false)
  const [pollenAllergy, setPollenAllergy] = useState(false)
  const [dustAllergy, setDustAllergy] = useState(false)
  const [petAllergy, setPetAllergy] = useState(false)
  const [latexAllergy, setLatexAllergy] = useState(false)

  const [oftenDrink, setOftenDrink] = useState('')
  const [oftenSmoke, setOftenSmoke] = useState('')
  const [oftenDrugs, setOftenDrugs] = useState('')

  const [medicalCondition, setMedicalCondition] = useState(false)
  const [whichMedicalCondition, setWhichMedicalCondition] = useState('')
  const [detailsId, setDetailsId] = useState('')
  const [alreadyPrefilled, setAlreadyPrefilled] = useState(false)

  const patient_id =
    user.role !== 'Patient' ? `${location.state?.patient_id}` : user.user_id

  useEffect(() => {
    let b = parseInt(weight) / Math.pow(parseInt(height) / 100, 2)
    b = Math.round(b * 100) / 100
    isNaN(b) ? setBmi(0) : setBmi(b)
    return () => {
      console.log('completed')
    }
  }, [height, weight])

  //submit data
  const postDetails = () => {
    const dataToSend = {
      patient_id: patient_id,
      height,
      weight,
      bmi,
      allergies: {
        food: foodAllergy,
        pollen: pollenAllergy,
        dust: dustAllergy,
        pet: petAllergy,
        latex: latexAllergy,
      },
      // allergies: 'fdfdfdf',
      medical_condition: medicalCondition,
      which_medical_condition: whichMedicalCondition,
      alcohol_intake: oftenDrink,
      smoking: oftenSmoke,
      drug_use: oftenDrugs,
    }
    const postUrl = alreadyPrefilled
      ? `${process.env.REACT_APP_MAIN_URL}/api/vital/${detailsId}/medical-history`
      : `${process.env.REACT_APP_MAIN_URL}/api/vital/medical-history`
    fetch(postUrl, {
      method: alreadyPrefilled ? 'PUT' : 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)

        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }
        setAlreadyPrefilled(true)
        if (user.role === 'Patient' && location.state?.patient_id) {
          // means they are from booking an appt
          navigate('/')
        }
        if (user.role === 'NURSE') {
          navigate('/')
        }
        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
        })
      })
      .catch((err) => {
        console.log(err, dataToSend)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
        })
      })
  }

  // fetch profile details
  const fetchDetailsUrl = `${process.env.REACT_APP_MAIN_URL}/api/vital/medical-history?patient_id=${patient_id}`

  useEffect(() => {
    console.log(user.user_id, user.token)
    fetch(fetchDetailsUrl, {
      method: 'GET',
      signal: signal,
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        if (res.error) {
          return toast.warning('An error occured while fetching data', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
          })
        }
        if (res.data?.id) {
          console.log(res.data)
          setAlreadyPrefilled(true)
          setHeight(res.data?.height)
          setWeight(res.data?.weight)
          setOftenDrink(res.data?.alcohol_intake)
          setOftenSmoke(res.data?.smoking)
          setOftenDrugs(res.data?.drug_use)
          setMedicalCondition(res.data?.medical_condition)
          setWhichMedicalCondition(res.data?.which_medical_condition)

          const parsedapplicables = JSON.parse(res.data?.allergies)
          setFoodAllergy(parsedapplicables.food)
          setPollenAllergy(parsedapplicables.pollen)
          setDustAllergy(parsedapplicables.dust)
          setPetAllergy(parsedapplicables.pet)
          setLatexAllergy(parsedapplicables.latex)

          setDetailsId(res.data?.id)
          toast.info('Ensure that the medical details are up to date', {
            position: toast.POSITION.TOP_RIGHT,
          })
        } else {
          toast.info('Fill patients medical details here', {
            position: toast.POSITION.TOP_RIGHT,
          })
        }

        // res.data?.items?.length > 0 && setAppointments(res.data.items)
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])
  return (
    <div className="content-body">
      <div className="container-fluid pt-0">
        <div className="row">
          <div className="col-lg-12 col-xxl-12">
            <div className="card py-3 px-3">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="mb-1 " htmlFor="height">
                      <strong>Height in cm</strong>
                    </label>
                    <input
                      type="text"
                      id="height"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="mb-1 " htmlFor="weight">
                      <strong>Weight in Kg</strong>
                    </label>
                    <input
                      type="text"
                      id="weight"
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="mb-1 " htmlFor="bmi">
                      <strong>BMI</strong>
                    </label>
                    <input
                      type="text"
                      id="bmi"
                      value={bmi}
                      readOnly
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="mb-1 " htmlFor="allergies">
                      <strong>Do you have the following allergies?</strong>
                    </label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={foodAllergy}
                        onChange={(e) => {
                          setFoodAllergy(e.target.checked)
                        }}
                        id="food_allergy"
                      />
                      <label className="form-check-label" for="food_allergy">
                        Food
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={pollenAllergy}
                        onChange={(e) => {
                          setPollenAllergy(e.target.checked)
                        }}
                        id="pollen_allergy"
                      />
                      <label className="form-check-label" for="pollen_allergy">
                        Pollen
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={dustAllergy}
                        onChange={(e) => {
                          setDustAllergy(e.target.checked)
                        }}
                        id="dust_allergy"
                      />
                      <label className="form-check-label" for="dust_allergy">
                        Dust
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={petAllergy}
                        onChange={(e) => {
                          setPetAllergy(e.target.checked)
                        }}
                        id="pet_allergy"
                      />
                      <label className="form-check-label" for="pet_allergy">
                        Pet
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={latexAllergy}
                        onChange={(e) => {
                          setLatexAllergy(e.target.checked)
                        }}
                        id="latex_allergy"
                      />
                      <label class="form-check-label" for="latex_allergy">
                        Latex
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={medicalCondition}
                        onChange={(e) => {
                          setMedicalCondition(e.target.checked)
                        }}
                        id="medic"
                      />
                      <label className="form-check-label" for="medic">
                        Have you been diagonised with a medical condition?
                      </label>
                    </div>
                  </div>
                  {medicalCondition && (
                    <div className="form-group">
                      <label for="medic_condition">
                        Which medical condition?
                      </label>
                      <textarea
                        class="form-control"
                        id="medic_condition"
                        rows="3"
                        value={whichMedicalCondition}
                        onChange={(e) =>
                          setWhichMedicalCondition(e.target.value)
                        }
                      ></textarea>
                    </div>
                  )}
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="mb-1 " htmlFor="alcohol">
                      <strong>How often do you take alcohol?</strong>
                    </label>
                    <select
                      className="form-control"
                      value={oftenDrink}
                      onChange={(e) => setOftenDrink(e.target.value)}
                    >
                      <option value="Never">Never</option>
                      <option value="Monthly or less">Monthly or less</option>
                      <option value="2 - 4 times a week">
                        2 - 4 times a week
                      </option>
                      <option value="2 - 3 times a week">
                        2 - 3 times a week
                      </option>
                      <option value="4 or more times a week">
                        4 or more times a week
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="mb-1 " htmlFor="alcohol">
                      <strong>How often Do you smoke?</strong>
                    </label>
                    <select
                      className="form-control"
                      value={oftenSmoke}
                      onChange={(e) => setOftenSmoke(e.target.value)}
                    >
                      <option value="Never">Never</option>
                      <option value="Monthly or less">Monthly or less</option>
                      <option value="2 - 4 times a week">
                        2 - 4 times a week
                      </option>
                      <option value="2 - 3 times a week">
                        2 - 3 times a week
                      </option>
                      <option value="4 or more times a week">
                        4 or more times a week
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="mb-1 " htmlFor="alcohol">
                      <strong>Recreational drug use</strong>
                    </label>
                    <select
                      className="form-control"
                      value={oftenDrugs}
                      onChange={(e) => setOftenDrugs(e.target.value)}
                    >
                      <option value="Never">Never</option>
                      <option value="Monthly or less">Monthly or less</option>
                      <option value="2 - 4 times a week">
                        2 - 4 times a week
                      </option>
                      <option value="2 - 3 times a week">
                        2 - 3 times a week
                      </option>
                      <option value="4 or more times a week">
                        4 or more times a week
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-md-8 mt-5">
                  {/* {(user?.role === 'NURSE' || user.role === 'Patient') && (
                    <button
                      className="btn btn-dark  mr-5"
                      onClick={() => navigate('/')}
                    >
                      Ignore and proceed to dashboard
                    </button>
                  )} */}
                  <button className="btn btn-primary" onClick={postDetails}>
                    Submit
                  </button>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>

      {/* <a href="/ManagePatients">
          <button className="btn btn-light btl-lg shadow"> 
          Manage Patients
          </button> </a> */}

      {/* <MainApp appointments={appointments} today={appointmentsToday} /> */}
      {/* <AppointmentActivity /> */}
    </div>
  )
}

export default PatientDetails
