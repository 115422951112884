import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../Header";
import Footer from "../../Footer";
import LeftBar from "../../LeftBar";
import { Button, Form } from "react-bootstrap";
import Vitals from "../Vitals";
import PatientDetails from "../PatientDetails";
import Invite from "../Invite";
import { selectUser } from "../../../redux_setup/slices/userSlice";
import { selectPatientData } from "../../../redux_setup/slices/patientDataSlice";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BloodPressureReduction from "./BloodPressureReduction";
import HeartRateReduction from "./HeartRateReduction";
const BloodPressure = () => {
  const [showBloodPressure, setShowBloodPressure] = useState(false);
  const [showHeartRate, setShowHeartRate] = useState(false);
  const [bloodPressureData, setBloodPressureData] = useState({});
  const [heartRateData, setHeartRateData] = useState({});

  const user = useSelector(selectUser);
  const patient = useSelector(selectPatientData)
  const navigate = useNavigate();
  toast.configure();

  const receiveBloodPressureData = (data) => {
    setBloodPressureData({ ...bloodPressureData, data });
  };
  const receiveHeartRateData = (data) => {
    setHeartRateData({ ...heartRateData, data });
  };
  let dataToSend = {
    patient_id: patient.id,
    doctor_id: user.user_id,
  };
  dataToSend = {
    ...dataToSend,
    ...bloodPressureData.data,
    ...heartRateData.data,
  };
  const checkPatientData = () => console.log(patient)
  const handleSubmit = () => {
    fetch(`${process.env.REACT_APP_MAIN_URL_HYPERTENSION}/api/goals/vitals`, {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + user.token,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        // navigate("/careplan-weight-loss");
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
          });
        }
        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        });
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        return toast.warning("An error has occured while reaching the server", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        });
      });
  };

  return (
    <div className='main-wrapper'>
      {/***********************************
                  Header start
                  ************************************/}
      <Header />
      {/***********************************
                  Header end ti-comment-alt
                  ************************************/}
      {/***********************************
                  Sidebar start
                  ************************************/}
      <LeftBar />
      {/***********************************
                  Sidebar end
                  ************************************/}
      {/***********************************
                  Content body start
                  ************************************/}

      {/***********************************
                  Content body end
                  ************************************/}
      <div className='content-body'>
        <p
          className='mt-3 mb-3'
          style={{
            fontSize: 20,
            fontFamily: "Lato",
            fontWeight: 700,
            color: "#000",
          }}
        >
          Patient Details
        </p>
        <div className='row'>
          <div className='col-md-7 col-lg-7 col-sm-12'>
            <PatientDetails />
            <div className='card pt-4 pl-3' style={{ width: 654, height: 84 }}>
              <div className='d-flex' style={{ gap: 100 }}>
                <div className='d-flex flex-wrap ml-4' style={{ gap: 10 }}>
                  <Form.Check
                    id='blood level'
                    className='mt-1'
                    onChange={(e) => setShowBloodPressure(e.target.checked)}
                  />
                  <label
                    htmlFor='blood level'
                    style={{
                      fontFamily: "Lato",
                      fontSize: 18,
                      fontWeight: 400,
                      color: "#616161",
                    }}
                  >
                    Blood Pressure Reduction
                  </label>
                </div>
                <div className='d-flex ml-4' style={{ gap: 10 }}>
                  <Form.Check
                    id='heart rate'
                    className='mt-1'
                    onChange={(e) => setShowHeartRate(e.target.checked)}
                  />
                  <label
                    htmlFor='heart rate'
                    style={{
                      fontFamily: "Lato",
                      fontSize: 18,
                      fontWeight: 400,
                      color: "#616161",
                    }}
                  >
                    Heart Rate Reduction
                  </label>
                </div>
              </div>
            </div>
            {showBloodPressure ? (
              <BloodPressureReduction sendData={receiveBloodPressureData} />
            ) : (
              <></>
            )}
            {showHeartRate ? (
              <HeartRateReduction sendData={receiveHeartRateData} />
            ) : (
              <></>
            )}
            <Invite handleSubmit={checkPatientData} />
          </div>

          <div className='col-md-5 col-lg-5 col-sm-12'>
            <Vitals />
          </div>
        </div>
        <div className='d-flex justify-content-end' style={{ gap: 15 }}>
          <Button variant='outline-primary'>
            <i class='fa fa-arrow-left mr-3' />
            Back
          </Button>
          {/* <Link to='/careplan-weight-loss'> */}
          <Button variant='primary' onClick={handleSubmit}>
            Next
            <i class='fa fa-arrow-right ml-3' />
          </Button>
          {/* </Link> */}
        </div>
      </div>
      {/***********************************
                  Footer start
                  ************************************/}
      <Footer />
      {/***********************************
                  Footer end
                  ************************************/}
    </div>
  );
};
export default BloodPressure;
