import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import { selectReceptionistFilters } from '../redux_setup/slices/receptionistSlice'
import { humanize, todayDate } from '../constants/Essentials'
import { formatDate } from '@fullcalendar/react'
import { Link } from 'react-router-dom'
import { Badge } from 'react-bootstrap'
import HoverComponent from './HoverComponent'

const AppointmentActivity = () => {
  const abortcontroller = new AbortController()
  const user = useSelector(selectUser)
  const filters = useSelector(selectReceptionistFilters)
  const [appointments, setAppointments] = useState([])
  const [changingappt, setChangingAppt] = useState([])
  useEffect(() => {
    console.log(user.token)
    const today = todayDate()
    const url = `${process.env.REACT_APP_MAIN_URL}/api/appointment/health-center?date=${today}`
    fetch(url, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, url, user.token)

        setAppointments(res.data.items)
        setChangingAppt(res.data.items)
        // res.data.items.forEach((item) => {
        //   item.value = item.id;
        //   item.label = item.name;
        //   // now I no longer need them
        //   delete item.id;
        //   delete item.name;
        //   //->will delete the rest later
        // });
        // console.log(res.data);

        // res.data?.items?.length > 0 && setDoctor(res.data.items);
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])
  useEffect(() => {
    const tempAppointments = [...appointments]
    if (filters?.payment_type === 'all') {
      setChangingAppt(tempAppointments)
    } else {
      const tmp = tempAppointments.filter(
        (appt) => appt.payment_type === filters?.payment_type,
      )
      setChangingAppt(tmp)
    }
  }, [filters])
  return (
    <div className="card roundediv">
      <div className="card-header">Appointments Today</div>
      <div className="card-body">
        <Table variant="hover">
          <thead>
            <tr style={{ textAlign: 'left' }}>
              <th>Patient</th>
              <th>Doctor</th>
              <th>Date</th>
              <th>Time</th>
              <th>Payment mode</th>
              <th>Payment status</th>
            </tr>
          </thead>
          <tbody>
            {changingappt.map((appointment, index) => {
              const dataToProceed = {
                appt_id: appointment?.id,
                patient_id: appointment?.patient_id,
                doctor_id: appointment?.doctor_id,
                health_center: { name: appointment?.Health_center.name },
                doctor: { name: appointment?.doctorId?.name },
                time_slot: appointment?.time_slot,
                date: appointment?.date,
                reason_for_visit: appointment?.reason_for_visit,
                send_reminder: appointment?.send_reminder,
                email: appointment?.email,
                patient_name: appointment?.patient.name,
                price: appointment?.price,
              }
              // console.log(dataToProceed, 'is data to proceed')

              return (
                <tr className="text-left cursor-pointer" key={index}>
                  <td>{appointment?.patient?.name}</td>
                  <td>{appointment?.doctorId?.name}</td>
                  <td>{formatDate(appointment?.date, { locale: 'es' })}</td>
                  <td>{appointment?.time_slot}</td>
                  <td>{humanize(appointment?.payment_type)}</td>
                  <td>
                    {appointment?.is_payed === true ? (
                      <>
                        <span className="text-success">Paid</span>

                        {appointment?.nurse_id ? (
                          <HoverComponent
                            textToDisplay={`Assigned to ${appointment?.nurseId?.name}`}
                            placement="bottom"
                          >
                            <Badge text="white" bg="info" className="ml-2 p-0">
                              <i
                                className="fa fa-check-circle text-white"
                                title="Completed"
                              ></i>
                              Assigned
                            </Badge>
                          </HoverComponent>
                        ) : (
                          <Link
                            to="/assign-to-nurse"
                            state={{
                              patient_id: dataToProceed.patient_id,
                              appt_id: dataToProceed.appt_id,
                            }}
                            className="btn btn-primary btn-sm ml-3"
                            title="Click to assign to nurse"
                          >
                            Assign nurse
                          </Link>
                        )}
                      </>
                    ) : user.isAdmin ? (
                      <>
                        {appointment?.payment_type === 'insurance' ? (
                          <Link
                            to="/invoice"
                            state={dataToProceed}
                            className="btn btn-dark btn-sm"
                            title="Click to generate Invoice"
                          >
                            Generate Invoice
                          </Link>
                        ) : (
                          <Link
                            to="/payments"
                            state={dataToProceed}
                            className="btn btn-dark btn-sm"
                            title="Click to receive Payment"
                          >
                            Receive Payment
                          </Link>
                        )}
                      </>
                    ) : (
                      <span className="text-warning">Unpaid</span>
                    )}
                  </td>
                  {/* {user.role === 'DOCTOR' && (
                    <td>
                      <Link
                        to="/transfer-appt"
                        state={{
                          app_id: appointment?.id,
                          patient_id: appointment?.patient_id,
                          doctor_id: appointment?.doctor_id,
                          health_center: appointment?.health_center,
                        }}
                        title="Click to initiate transfer"
                        className="btn btn-warning btn-sm"
                      >
                        Transfer
                      </Link>
                    </td>
                  )} */}

                  {/* <a href="appintment-details" style={{ padding: '0 15px' }}><i style={{ color: '#A0A4A8' }} class="fas fa-pencil-alt"></i></a> */}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default AppointmentActivity
