import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import LeftBar from '../components/LeftBar'
import DataTable from 'react-data-table-component'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import Preloader from '../components/Preloader'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Add_HC_Schema, Add_User_Schema } from '../constants/ValidationSchema'
import Select from 'react-select'
import { read, utils } from 'xlsx'
import { trimDate } from '../constants/Essentials'
import { formatDate } from '@fullcalendar/react'

const columns = [
  {
    name: 'No',
    selector: (row) => row.count,
    sortable: true,
  },
  {
    name: 'Patient No',
    selector: (row) => row.avatar_mimetype,
  },
  {
    name: 'Name',
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: 'Email',
    selector: (row) => row.email,
  },
  {
    name: 'Phone',
    selector: (row) => row.phone,
  },
  {
    name: 'Gender',
    selector: (row) => row.gender,
  },

  {
    name: 'Address',
    selector: (row) => row.address,
  },

  {
    name: 'Birth Date',
    selector: (row) => formatDate(row.birth_date, { locale: 'es' }),
  },
]

const ImportPatients = () => {
  toast.configure()
  const [editShow, setEditShow] = useState(false) //edit modal
  const [addShow, setAddShow] = useState(false) //add modal
  const [dataToEdit, setDataToEdit] = useState({})
  const [loading, setLoading] = useState(false)
  const [healthCenterUsers, setHealthCenterUsers] = useState([])
  const [hc, setHc] = useState('')
  const [patientsFromExcel, setPatientsFromExcel] = useState([])
  const [status, setStatus] = useState('Upload')
  const [erroed, setErroed] = useState(0)
  const [successful, setSuccessful] = useState(0)
  const [allPatients, setAllPatients] = useState([])

  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  const user = useSelector(selectUser)

  const [healthCentersSelect, setHealthCentersSelect] = useState([])
  const c_url = `${process.env.REACT_APP_MAIN_URL}/api/health-center/`

  const patientsUrl = `${process.env.REACT_APP_MAIN_URL}/api/patient/`
  useEffect(() => {
    fetch(patientsUrl, {
      signal: signal,
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        if (res.error) {
          return console.log('patients fetching did not succeed')
        }
        let iterable = user.role === 'SUPER_ADMIN' ? res.data.items : res.data

        let count = 1
        iterable.forEach((item) => {
          item.count = count
          count++
          //->will delete the rest later
        })
        setAllPatients(iterable)
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])
  const ExpandedComponent = ({ data }) => {
    return (
      <div className="expanded-div py-5 px-5">
        <p>Name: {data.name}</p>
        <p>Email: {data.email}</p>
        <p>Adress: {data.address}</p>
        <p>Phone: {data.phone}</p>
      </div>
    )
  }

  const fetchUsers = (health_center) => {
    setLoading(true)
    const fetchUsersUrl = `${process.env.REACT_APP_MAIN_URL}/api/user/health-center/?health_center_id=${health_center}&size=10`
    fetch(fetchUsersUrl, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        setLoading(false)
        if (res.error) {
          return toast.warning('An error occured while fetching center users', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          })
        }

        // toast.success('Users were fetched successfuly', {
        //   position: toast.POSITION.TOP_CENTER,
        // })
        let count = 1
        res.data.items.forEach((item) => {
          item.count = count
          count++
          //->will delete the rest later
        })
        // console.log(res.data);

        res.data?.items?.length > 0 && setHealthCenterUsers(res.data.items)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        })
      })
  }

  const deleteUser = (id) => {
    const url = `${process.env.REACT_APP_MAIN_URL}/api/user/${id}/delete`
    if (window.confirm('Are you sure you want to delete this user?')) {
      // send request
      fetch(url, {
        signal: signal,
        method: 'DELETE',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: 'Bearer ' + user.token,
          'Content-Type': 'application/json',
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            return toast.warning(res.error, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: false
            })
          }

          //adjust state
          const newUsersData = healthCenterUsers.filter(
            (item) => item.id !== id,
          )

          setHealthCenterUsers(newUsersData)

          return toast.success(res.message, {
            position: toast.POSITION.TOP_CENTER,
          })
        })
        .catch((err) => {
          console.log(err)
          return toast.warning('An error occured while reaching the server', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          })
        })
    }
  }

  const readUploadFile = (e) => {
    e.preventDefault()
    if (e.target.files) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = e.target.result
        const workbook = read(data, { type: 'array' })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const json = utils.sheet_to_json(worksheet, {
          raw: false,
        })
        //new array
        const newArr = []
        //cycle thru every obj of this array

        if (json.length > 0) {
          json.map((item) => {
            const tempItem = {}
            tempItem.address = item['Address']
            tempItem.email = item['E-Mail']
            tempItem.phone = item['Phone No']
            tempItem.gender = item['Gender'].toLowerCase()
            tempItem.firstName = item['First Name']
            tempItem.middleName = item['Other Name']
            tempItem.lastName = item['Surname']
            tempItem.birth_date = trimDate(item['Birth Date'], 'date_only')
            tempItem.avatar_mimetype = item['Patient No']
            tempItem.password = item['Patient No'] + '@Healthcenta'
            tempItem.is_verified = 1

            newArr.push(tempItem)
          })
        }

        setPatientsFromExcel(newArr)
      }
      reader.readAsArrayBuffer(e.target.files[0])
    }
  }
  const url = `${process.env.REACT_APP_MAIN_URL}/api/auth/signup`

  const uploadPatients = () => {
    //set status of uploading
    setStatus('Uploading data...')
    console.log(patientsFromExcel)
    patientsFromExcel.map((patient) => {
      return fetch(url, {
        signal: signal,
        method: 'POST',
        headers: new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(patient),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            return setErroed(erroed + 1)
          }

          return setSuccessful(successful + 1)
        })
        .catch((err) => {
          console.log(err)
          return setErroed(erroed + 1)
        })
    })
    setTimeout(() => {
      setStatus('Upload')
      setPatientsFromExcel([])
      return toast.info('Data Transfer completed', {
        position: toast.POSITION.TOP_CENTER,
      })
    }, 100)
  }
  return (
    <div id="main-wrapper">
      {/***********************************
    Header start
************************************/}
      <Header />
      {/***********************************
    Header end ti-comment-alt
************************************/}
      {/***********************************
    Sidebar start
************************************/}
      <LeftBar />
      {/***********************************
    Sidebar end
************************************/}
      {/***********************************
    Content body start
************************************/}
      <div className="content-body">
        {loading ? (
          <Preloader />
        ) : (
          <Row>
            <Col md={6}>
              <Button
                variant="info"
                className="ml-3"
                title="Click here to Add user"
                onClick={() => setAddShow(!addShow)}
              >
                <i className="fa fa-plus"></i> Import patients
              </Button>
            </Col>
            {user.role === 'SUPER_ADMIN' && (
              <Col md={4}>
                <Select
                  placeholder="Search Health Center..."
                  options={healthCentersSelect}
                  id="health_center_for_users"
                  onChange={(selectedOption) => {
                    fetchUsers(selectedOption.value)
                  }}
                  // defaultInputValue={dataToEdit?.health_center_id}
                />
              </Col>
            )}

            <Col md={12}>
              <DataTable
                columns={columns}
                data={allPatients}
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                pagination
              />
            </Col>
          </Row>
        )}
      </div>
      {/***********************************
    Content body end
************************************/}

      {/***********************************
    Footer start
************************************/}
      <Footer />
      {/***********************************
    Footer end
************************************/}

      {/***********************************
    Add Modal start
************************************/}
      <Modal
        size="lg"
        show={addShow}
        onHide={() => setAddShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Import patients data
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Upload an excel file containing patients data</p>
          <div className="form-group">
            <input
              type="file"
              placeholder="Excel file only"
              className="form-control"
              accept=".xlsx, .xls, .csv"
              onChange={readUploadFile}
            />
          </div>
          <div className="form-group">
            <button
              className="btn btn-primary btn-sm"
              disabled={patientsFromExcel.length === 0}
              onClick={uploadPatients}
            >
              {status}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {/***********************************
    Add Modal end
************************************/}
    </div>
  )
}

export default ImportPatients
