import { useNavigate } from 'react-router'
import Footer from '../Footer'
import Header from '../Header'
import LeftBar from '../LeftBar'
import { Collapse, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUser } from '../../redux_setup/slices/userSlice'
import React, { useState, useEffect } from 'react'
import moment from 'moment/moment'

const data = [
  {
    doctor: 'Doctor One',
    requested_date: '01/02/2001',
    received_date: '03/08/2022',
    time: '11:12',
    type_of_test: 'Lab',
  },
  {
    doctor: 'Doctor Wanjala',
    requested_date: '01/02/2021',
    received_date: '03/08/2022',
    time: '09:12',
    type_of_test: 'PSA',
  },
  {
    doctor: 'Doctor Three',
    requested_date: '01/02/2022',
    received_date: '03/08/2022',
    time: '12:23',
    type_of_test: 'X-rays',
  },
  {
    doctor: 'Doctor Ronald',
    requested_date: '18/12/2022',
    received_date: '03/01/2023',
    time: '03:12',
    type_of_test: 'PSA',
  },
  {
    doctor: 'Dactari',
    requested_date: '01/02/2001',
    received_date: '03/08/2002',
    time: '11:12',
    type_of_test: 'Lab',
  },
]
const LabResults = () => {
  const navigate = useNavigate()
  const user = useSelector(selectUser)
  const [labresultsState, setLabresultsState] = useState([])
  const [openLabtest, setOpenLabtest] = useState('')
  const [dateFrom, setDateFrom] = useState('')
  const abortcontroller = new AbortController()

  const fetchUrl = `${process.env.REACT_APP_MAIN_URL}/api/general_consultation/labtests?patient_id=${user?.user_id}&size=50&dateFrom=${dateFrom}`
  useEffect(() => {
    fetch(fetchUrl, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res?.error) {
          return console.log('an error occurred')
        }
        // console.log(res, 'labresults')

        const results = res.data.items

        //before set to state modify them first
        results.forEach((item) => {
          item.tests_to_be_done = JSON.parse(item?.tests_to_be_done)
        })
        console.log(results, 'labresults')
        setLabresultsState(results)
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [dateFrom])
  return (
    <div className="main-wrapper">
      {/***********************************
                    Header start
                    ************************************/}
      <Header />
      {/***********************************
                    Header end ti-comment-alt
                    ************************************/}
      {/***********************************
                    Sidebar start
                    ************************************/}
      <LeftBar />
      {/***********************************
                    Sidebar end
                    ************************************/}
      {/***********************************
                    Content body start
                    ************************************/}

      {/***********************************
                    Content body end
                    ************************************/}
      <div className="content-body">
        <div
          className="flex__container"
          style={{ width: '85%', justifyContent: 'flex-end' }}
        >
          <input
            type="date"
            className="form-control"
            style={{ width: 200 }}
            onChange={(e) => setDateFrom(e.target.value)}
          />
        </div>
        <div
          className="card"
          style={{ width: '90%', marginTop: 20, padding: 20 }}
        >
          <p className="header__title" style={{ fontSize: 22 }}>
            Lab Results
          </p>
          <Table
            striped
            bordered
            hover
            style={{ width: '95%', marginLeft: 30 }}
          >
            <thead>
              <tr>
                <th style={{ backgroundColor: '#E5E5E5' }}>Doctor</th>
                <th style={{ backgroundColor: '#E5E5E5' }}>Requested Date</th>
                <th style={{ backgroundColor: '#E5E5E5' }}>Time</th>
                <th style={{ backgroundColor: '#E5E5E5' }}>Type of test</th>
                <th style={{ backgroundColor: '#E5E5E5' }}>File</th>
              </tr>
            </thead>
            <tbody>
              {labresultsState.map((item, index) => {
                const selectedTests = item?.tests_to_be_done?.tests?.filter(
                  (test) => test.expanded === true,
                )
                console.log(selectedTests)
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td>{item?.tests_to_be_done?.doctor}</td>
                      <td>{moment(item?.createdAt).format('YYYY-MM-DD')}</td>
                      <td>{item?.tests_to_be_done?.timeRequested}</td>
                      <td>
                        <span
                          onClick={() => {
                            setOpenLabtest(openLabtest === index ? '' : index)
                          }}
                          role="button"
                        >
                          <u className="text-info">
                            {selectedTests?.length} tests
                          </u>
                        </span>
                      </td>
                      <td className="flex__container" style={{ gap: 20 }}>
                        <img
                          width={25}
                          height={35}
                          src={`${process.env.PUBLIC_URL}/images/pdf_red_icon.png`}
                        />
                      </td>
                    </tr>
                    {selectedTests?.length > 0 && (
                      <Collapse in={openLabtest === index}>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            {selectedTests?.map((test, index) => (
                              <div
                                className="d-flex flex-row justify-content-between"
                                key={index}
                              >
                                <label key={index}>{`${index + 1}. ${
                                  test?.name
                                }`}</label>
                                {test?.status === 'done' ? (
                                  <span
                                    style={{
                                      color: '#3fb558',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <i class="fa-regular fa-circle-check"></i>
                                  </span>
                                ) : (
                                  <div className="cancel__btn">
                                    {test?.status}
                                  </div>
                                )}
                              </div>
                            ))}
                          </td>
                          <td></td>
                        </tr>
                      </Collapse>
                    )}
                  </React.Fragment>
                )
              })}
            </tbody>
          </Table>
          <div className="flex__container">
            <button className="btn btn-dark" onClick={() => navigate(-1)}>
              Back
            </button>
          </div>
        </div>
      </div>

      {/***********************************
                    Footer start
                    ************************************/}
      <Footer />
      {/***********************************
                    Footer end
                    ************************************/}
    </div>
  )
}

export default LabResults
