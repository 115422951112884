import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import Footer from './Footer'
import Header from './Header'
import LeftBar from './LeftBar'
import { Table } from 'react-bootstrap'

const PaymentHistory = () => {
  const [paymentData, setPaymentData] = useState([])
  const navigate = useNavigate()
  const abortcontroller = new AbortController()
  const user = useSelector(selectUser)
  const fetchUrl = `${process.env.REACT_APP_MAIN_URL}/api/appointment/payment-process/?size=50&patient_id=${user?.user_id}`
  useEffect(() => {
    fetch(fetchUrl, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, 'patients_appts')

        res.data?.items?.length > 0 && setPaymentData(res.data.items)
      })
      .catch((err) => {
        console.log(err)
      })
    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  return (
    <div className="main-wrapper">
      {/***********************************
                    Header start
                    ************************************/}
      <Header />
      {/***********************************
                    Header end ti-comment-alt
                    ************************************/}
      {/***********************************
                    Sidebar start
                    ************************************/}
      <LeftBar />
      {/***********************************
                    Sidebar end
                    ************************************/}
      {/***********************************
                    Content body start
                    ************************************/}

      {/***********************************
                    Content body end
                    ************************************/}
      <div className="content-body">
        <div style={{ marginLeft: 20, marginTop: 30 }}>
          <div className="card" style={{ marginTop: 20, padding: 20 }}>
            <p className="header__title" style={{ fontSize: 22 }}>
              Payment History
            </p>
            <Table
              striped
              bordered
              hover
              style={{ width: '95%', marginLeft: 30 }}
            >
              <thead>
                <tr>
                  <th style={{ backgroundColor: '#E5E5E5' }}>Amount(kshs)</th>
                  <th style={{ backgroundColor: '#E5E5E5' }}>Date</th>
                  <th style={{ backgroundColor: '#E5E5E5' }}>Doctor</th>
                  <th style={{ backgroundColor: '#E5E5E5' }}>Payment Method</th>
                  <th style={{ backgroundColor: '#E5E5E5', width: 100 }}>
                    File
                  </th>
                </tr>
              </thead>
              <tbody>
                {paymentData.map((item, index) => (
                  <tr key={index}>
                    <td>{`Ksh ${item?.amount}`}</td>
                    <td>{item?.createdAt}</td>
                    <td>{item?.doctorId?.name}</td>
                    <td>{item?.payment_type}</td>
                    <td className="flex__container" style={{ gap: 20 }}>
                      {item?.payment_type === 'insurance' && (
                        <img
                          width={25}
                          height={35}
                          src={`${process.env.PUBLIC_URL}/images/pdf_red_icon.png`}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="flex__container">
              <button className="btn btn-dark" onClick={() => navigate(-1)}>
                Back
              </button>
            </div>
          </div>
        </div>

        <div
          className="flex__container"
          style={{ justifyContent: 'flex-end', width: '90%' }}
        ></div>
      </div>

      {/***********************************
                    Footer start
                    ************************************/}
      <Footer />
      {/***********************************
                    Footer end
                    ************************************/}
    </div>
  )
}

export default PaymentHistory
