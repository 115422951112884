import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import { setReceptionistFilters } from '../redux_setup/slices/receptionistSlice'

const DashboardCards = (props) => {
  const user = useSelector(selectUser)
  const dispatch = useDispatch()
  const dispatchAction = (action) => {
    dispatch(
      setReceptionistFilters({
        payment_type: action,
      }),
    )
  }
  return (
    <>
      {user.role === 'RECEPTIONIST' ? (
        <div className="row padit">
          <div
            className="col-xl-3 col-sm-6 cursor-pointer"
            onClick={() => dispatchAction('all')}
            title="Click to get all appointments for today"
          >
            <div className="card appointmentBG">
              <div className="card-body " style={{ cursor: 'pointer' }}>
                <span style={{ fontSize: '14px' }}>Daily appointments</span>
                <div className="media ">
                  <svg
                    width={40}
                    height={40}
                    viewBox="0 0 40 40"
                    fill="#45B757"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0)">
                      <path
                        d="M32.04 4.08H31.24V2.04C31.24 0.8 30.4 0 29.2 0C28 0 27.16 0.8 27.16 2.04V4.08H13.88V2.04C13.88 0.8 13.08 0 11.84 0C10.6 0 9.80002 0.8 9.80002 2.04V4.08H7.96002C4.08002 4.08 0.800018 7.36 0.800018 11.24V32.88C0.800018 36.76 4.08002 40.04 7.96002 40.04H32.04C35.92 40.04 39.2 36.76 39.2 32.88V11.24C39.2 7.36 35.92 4.08 32.04 4.08ZM7.96002 8.16H32.04C33.68 8.16 35.12 9.6 35.12 11.24V14.08H4.88002V11.24C4.88002 9.6 6.32002 8.16 7.96002 8.16ZM32.04 35.92H7.96002C6.32002 35.92 4.88002 34.48 4.88002 32.84V18.16H35.08V32.84C35.12 34.48 33.68 35.92 32.04 35.92Z"
                        fill="#45B757"
                      />
                      <path
                        d="M16.12 20.6H14.48C13.44 20.6 12.84 21.4 12.84 22.24V24.08C12.84 25.12 13.64 25.72 14.48 25.72H16.12C17.16 25.72 17.76 24.92 17.76 24.08V22.44C17.96 21.44 17.16 20.6 16.12 20.6Z"
                        fill="#45B757"
                      />
                      <path
                        d="M25.52 20.6H23.88C22.84 20.6 22.24 21.4 22.24 22.24V24.08C22.24 25.12 23.04 25.72 23.88 25.72H25.52C26.56 25.72 27.16 24.92 27.16 24.08V22.44C27.16 21.44 26.32 20.6 25.52 20.6Z"
                        fill="#45B757"
                      />
                      <path
                        d="M16.12 28.56H14.48C13.44 28.56 12.84 29.36 12.84 30.2V31.84C12.84 32.88 13.64 33.48 14.48 33.48H16.12C17.16 33.48 17.76 32.68 17.76 31.84V30.2C17.96 29.4 17.16 28.56 16.12 28.56Z"
                        fill="#45B757"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width={40} height={40} fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <h2 className="fs-34 text-black font-w600">
                    {props.bookedAppointments}
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-xl-3  col-sm-6 cursor-pointer"
            onClick={() => dispatchAction('cash')}
            title="Click to get all appointments by cash"
            style={{ cursor: 'pointer' }}
          >
            <div className="card totDoctorBG">
              <div className="card-body ">
                <span style={{ fontSize: '14px' }}>Cash daily appts</span>

                <div className="media ">
                  <i
                    className="fa fa-wallet fa-3x"
                    style={{ color: '#45B757' }}
                    aria-hidden="true"
                  ></i>

                  <h2 className="fs-34 text-black font-w600">
                    {props.upcAppointments}
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-xl-3  col-sm-6 cursor-pointer"
            onClick={() => dispatchAction('insurance')}
            title="Click to get all appointments by insurance"
            style={{ cursor: 'pointer' }}
          >
            <div className="card earningBG">
              <div className="card-body">
                <span style={{ fontSize: '14px' }}>Insurance daily appts</span>
                <div className="media ">
                  <i
                    style={{ color: '#2168F1' }}
                    className=" fa fa-book-medical fa-3x"
                  />
                  <h2 className="fs-34 text-black font-w600">
                    {props.activePatients}
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3  col-sm-6">
            <div className="card heartBg">
              <div className="card-body">
                <span style={{ fontSize: '14px' }}>Free slots today</span>
                <div className="media ">
                  <i
                    className="fa fa-calendar-plus fa-3x"
                    style={{ color: '#45B757' }}
                    aria-hidden="true"
                  ></i>
                  <h2 className="fs-34 text-black font-w600">
                    {/* {props.newPatients} */}
                    63
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {user.role === 'NURSE' ? (
            <div className="row padit">
              <div className="col-xl-3 col-sm-4">
                <div className="card appointmentBG">
                  <div className="card-body ">
                    <span>Patients assigned to me</span>
                    <div className="media ">
                      <i
                        style={{ color: '#2B3479' }}
                        className=" fa fa-stethoscope fa-3x"
                      />
                      <h2 className="fs-34 text-black font-w600">0</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-6">
                <div className="card earningBG">
                  <div
                    className="card-body"
                    title="Click to get all active patients"
                  >
                    <span>Active triage</span>
                    <div className="media ">
                      <i
                        style={{ color: '#2168F1' }}
                        className="fa fa-users fa-3x"
                      />
                      <h2 className="fs-34 text-black font-w600">0</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-4">
                <div className="card totDoctorBG">
                  <div className="card-body ">
                    <span>Upcoming appointments today</span>
                    <div className="media ">
                      <i
                        style={{ color: '#45B757' }}
                        className="fa fa-notes-medical fa-3x"
                      />

                      <h2 className="fs-34 text-black font-w600">0</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-4">
                <Link to="/calendar">
                  <div className="card earningBG">
                    <div className="card-body " style={{ cursor: 'pointer' }}>
                      <span>Appointments this week</span>
                      <div className="media ">
                        <i
                          style={{ color: '#45B757' }}
                          className=" fa fa-calendar-alt fa-3x"
                        />
                        <h2 className="fs-34 text-black font-w600">0</h2>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          ) : (
            <div className="row padit">
              <div className="col-xl-3 col-6">
                <Link to="/calendar">
                  <div className="card appointmentBG">
                    <div
                      className="card-body"
                      title="Click to get all booked appointments for this month"
                    >
                      <span style={{ fontSize: '14px' }}>
                        Total booked appts this month
                      </span>
                      <div className="media ">
                        <i
                          style={{ color: '#45B757' }}
                          className=" fa fa-calendar-alt fa-3x"
                        />
                        <h2 className="fs-34 text-black font-w600">
                          {props.bookedAppointments}
                        </h2>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-xl-3  col-6">
                <Link to="/calendar">
                  <div className="card totDoctorBG">
                    <div
                      className="card-body "
                      title="Click to see all upcoming appointments for this month"
                      style={{ cursor: 'pointer' }}
                    >
                      <span style={{ fontSize: '14px' }}>
                        Upcoming appts this month
                      </span>

                      <div className="media ">
                        <i
                          style={{ color: '#2B3479' }}
                          className=" fa fa-stethoscope fa-3x"
                        />

                        <h2 className="fs-34 text-black font-w600">
                          {props.upcAppointments}
                        </h2>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-xl-3  col-6">
                <div className="card earningBG">
                  <div
                    className="card-body"
                    title="Click to get all active patients"
                  >
                    <span style={{ fontSize: '14px' }}>Active patients</span>
                    <div className="media ">
                      <i
                        style={{ color: '#2168F1' }}
                        className=" fa fa-users fa-3x"
                      />
                      <h2 className="fs-34 text-black font-w600">
                        {props.activePatients}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3  col-6">
                <div className="card heartBg">
                  <div
                    className="card-body"
                    title="Click to get all New Patients"
                  >
                    <span style={{ fontSize: '14px' }}>
                      New patients this year
                    </span>
                    <div className="media ">
                      <i
                        style={{ color: '#45B757' }}
                        className=" fa fa-address-card-o  fa-3x"
                      />
                      <h2 className="fs-34 text-black font-w600">
                        {props.newPatients}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default DashboardCards
