import { FormControl } from "react-bootstrap";
const Notes = () => {
  return (
    <div className='card pl-2 pr-2 mt-3' style={{ height: 180 }}>
      <p className='meal-profile-text mt-3' style={{ color: "#000" }}>
        Notes
      </p>
      <FormControl
        as='textarea'
        rows='3'
        placeholder='Type something here...'
        style={{ backgroundColor: "#eee" }}
      />
    </div>
  );
};

export default Notes;
