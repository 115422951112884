import React from 'react'
import { Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'

const ActivityExercise = ({ data }) => {
  const navigate = useNavigate()
  return (
    <div className="card pl-1 pr-1 pb-5">
      <p className="meal-profile-text" style={{ color: '#000', fontSize: 20 }}>
        Activity & Exercise Care Plan
      </p>
      <div
        className="card pl-4 pr-4 careplan-title"
        style={{
          background: 'rgba(171, 179, 253, 0.25)',
          height: '100%',
          width: '100%',
        }}
      >
        <p
          className="meal-profile-text pr-2"
          style={{
            color: '#000',
            fontWeight: 700,
            fontSize: 20,
            fontFamily: 'Lato',
            whiteSpace: 'normal',
          }}
        >
          Suggested Outcome: {data?.activity_suggested_outcome}
        </p>
      </div>
      {/* <div className='dont-show-on-mobile'> */}
      <div className="row pr-1">
        <div className="col-lg-3 col-md-3 col-6 pr-1">
          <div
            className="card pl-1 pt-2 pr-1"
            style={{ backgroundColor: '#6623823D' }}
          >
            <div
              className="card pl-1 pt-2"
              style={{
                width: '100%',
                background: 'rgba(102, 35, 130, 0.24)',
              }}
            >
              <p className="mobo-card-text">Cardio Workout</p>
              <span className="show-on-mobile" style={{ fontSize: 12 }}>
                (Mins/day)
              </span>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: -20 }}
            >
              <div>
                <small
                  style={{
                    fontSize: 10,
                    fontWeight: 400,
                    fontFamily: 'Lato',
                    color: '#0D5CA4',
                  }}
                  className="ml-3 mt-2"
                >
                  Current
                </small>
                <br />
                <small className="ml-1" style={{ whiteSpace: 'nowrap' }}>
                  20 min/day
                </small>
              </div>
              <div>
                <small
                  style={{
                    fontSize: 10,
                    fontWeight: 400,
                    fontFamily: 'Lato',
                    color: '#0D5CA4',
                  }}
                  className="ml-3 mt-2"
                >
                  Target
                </small>
                <br />
                <small className="ml-3 mt-2" style={{ whiteSpace: 'nowrap' }}>
                  {data?.activity_target_cardio || 0}
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-6 pr-1">
          <div
            className="card pl-2 pt-2 pr-1"
            style={{ background: 'rgba(7, 176, 215, 0.24)' }}
          >
            <div
              className="card pl-2 pt-2"
              style={{ width: '100%', backgroundColor: '#07B0D73D' }}
            >
              <p className="mobo-card-text" style={{ marginLeft: -5 }}>
                Strength Training
              </p>
              <span className="show-on-mobile mb-2" style={{ fontSize: 12 }}>
                (Mins/day)
              </span>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: -20 }}
            >
              <div>
                <small
                  style={{
                    fontSize: 10,
                    fontWeight: 400,
                    fontFamily: 'Lato',
                    color: '#0D5CA4',
                  }}
                  className="ml-3 mt-2"
                >
                  Current
                </small>
                <br />
                <small className="ml-1 mt-2 " style={{ whiteSpace: 'nowrap' }}>
                  10 min/day
                  {/* <small
                      className='dont-show-on-mobile'
                      style={{ fontSize: 14 }}
                    >
                      kgs
                    </small> */}
                </small>
              </div>
              <div>
                <small
                  style={{
                    fontSize: 10,
                    fontWeight: 400,
                    fontFamily: 'Lato',
                    color: '#0D5CA4',
                  }}
                  className="ml-3 mt-2"
                >
                  Target
                </small>
                <br />
                <small className="ml-3 mt-2 " style={{ whiteSpace: 'nowrap' }}>
                  {data?.activity_target_strength || 0}
                  {/* <small
                      className='dont-show-on-mobile'
                      style={{ fontSize: 14 }}
                    >
                      kgs
                    </small> */}
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-6 pr-1">
          <div
            className="card pl-2 pt-2 pr-2"
            style={{ backgroundColor: '#6623823D' }}
          >
            <div
              className="card pl-2 pt-2 pb-2"
              style={{
                width: '100%',
                background: 'rgba(102, 35, 130, 0.24)',
              }}
            >
              <p className="mobo-card-text">Activity Level</p>
              <span className="show-on-mobile mb-2" style={{ fontSize: 12 }}>
                (Mins/day)
              </span>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: -20 }}
            >
              <div>
                <small
                  style={{
                    fontSize: 10,
                    fontWeight: 400,
                    fontFamily: 'Lato',
                    color: '#0D5CA4',
                  }}
                  className="ml-3 mt-2"
                >
                  Current
                </small>
                <br />
                <small
                  className="ml-1 mt-2 mb-2 "
                  style={{ whiteSpace: 'nowrap' }}
                >
                  10 min/day
                  {/* <small
                      className='dont-show-on-mobile'
                      style={{ fontSize: 14 }}
                    >
                      Min/wk
                    </small> */}
                </small>
              </div>
              <div>
                <small
                  style={{
                    fontSize: 10,
                    fontWeight: 400,
                    fontFamily: 'Lato',
                    color: '#0D5CA4',
                  }}
                  className="ml-3 mt-2"
                >
                  Target
                </small>
                <br />
                <small
                  className="ml-3 mt-2 mb-2 "
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {data?.activity_target_level || 0}
                  {/* <small
                      className='dont-show-on-mobile'
                      style={{ fontSize: 14 }}
                    >
                      Min/wk
                    </small> */}
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-6 pr-1">
          <div
            className="card pl-2 pt-2 pr-1"
            style={{ background: 'rgba(7, 176, 215, 0.24)' }}
          >
            <div
              className="card pl-2 pt-2"
              style={{ width: '100%', backgroundColor: '#07B0D73D' }}
            >
              <p className="mobo-card-text" style={{ marginLeft: -5 }}>
                Calorie Loss
              </p>
              <span className="show-on-mobile" style={{ fontSize: 12 }}>
                (kcal)
              </span>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: -20 }}
            >
              <div>
                <h4 className="ml-3 mt-2 ">
                  0
                  {/* <small
                      className='dont-show-on-mobile'
                      style={{ fontSize: 14 }}
                    >
                      Min/wk
                    </small> */}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button
        style={{
          backgroundColor: '#11195A',
          width: '100%',
          height: '20%',
        }}
        className="mt-2 mb-2"
        onClick={() => navigate('/new-activity-care-plan')}
      >
        Update Activity & Exercise Profile
      </Button>
    </div>
  )
}

export default ActivityExercise
