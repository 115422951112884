import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import { selectMenu } from '../redux_setup/slices/menuSlice'
import { Link, useNavigate } from 'react-router-dom'
import { appendScripts } from '../constants/Essentials'

const LeftBar = () => {
  const menu = useSelector(selectMenu)
  useEffect(() => {
    appendScripts()
    console.log('menu =>', menu)
  }, [])
  const user = useSelector(selectUser)
  let navigate = useNavigate()
  return (
    <div className={`${menu ? 'deznav' : 'toggle-menu mt-3 ml-2'}`}>
      <div className="deznav-scroll">
        <ul className="metismenu" id="menu">
          <li>
            <a
              className="has-arrow ai-icon"
              // href="javascript:void()"
              role="button"
              aria-expanded="false"
            >
              <i className="fas fa-grip-horizontal"></i>
              <span className="nav-text">Dashboard</span>
            </a>
            <ul aria-expanded="false">
              {user.role !== 'RECEPTIONIST' && (
                <li>
                  <Link to="/dashboard">General</Link>
                </li>
              )}

              {user.isAdmin === true && (
                <li>
                  <Link to="/admin-dashboard">
                    {user.role === 'RECEPTIONIST' ? 'General' : 'Admin'}
                  </Link>
                </li>
              )}
            </ul>
          </li>
          <>
            {(user.role === 'DOCTOR' ||
              user.role === 'NURSE' ||
              user.isAdmin) && (
              <>
                <li>
                  <a
                    className="has-arrow ai-icon"
                    // href="/#/patient-add"
                    role="button"
                    aria-expanded="false"
                  >
                    <i className="fas fa-user-injured"></i>
                    <span
                      className="nav-text"
                      onClick={() => navigate('patient-add')}
                    >
                      Patient
                    </span>
                  </a>
                  <ul aria-expanded="false">
                    <li>
                      <Link to="/patient-add">Add</Link>
                    </li>
                    <li>
                      <Link to="/patient-manage">Manage</Link>
                    </li>
                    {user.role !== 'RECEPTIONIST' && (
                      <>
                        <li>
                          <Link to="/hypertensive-patients">
                            Hypertensive Patients
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </li>
                {user.role === 'NURSE' && (
                  <li>
                    <a
                      className="has-arrow ai-icon"
                      // href="/#/patient-add"
                      role="button"
                      aria-expanded="false"
                    >
                      <i className="fas fa-user-injured"></i>
                      <span
                        className="nav-text"
                        onClick={() => navigate('patient-add')}
                      >
                        Pharmacy
                      </span>
                    </a>
                    <ul aria-expanded="false">
                      <li>
                        <Link to="/pharmacist-dashboard">Dashboard</Link>
                      </li>
                      <li>
                        <Link to="/pharmacist-prescription-order-summary">
                          Prescription Order Summary
                        </Link>
                      </li>
                      <li>
                        <Link to="/pharmacist-prescription-dispensation">
                          Prescription Dispensation
                        </Link>
                      </li>
                      <li>
                        <Link to="/dispensation-summary">
                          Dispensation Summary
                        </Link>
                      </li>
                      <li>
                        <Link to="/external-rx-details">
                          External Rx Details
                        </Link>
                      </li>
                      <li>
                        <Link to="/external-dispensation-summary">
                          External Dispensation Summary
                        </Link>
                      </li>
                    </ul>
                  </li>
                )}
              </>
            )}
          </>

          {user.role === 'DOCTOR' && (
            <>
              <li>
                <a
                  className="has-arrow ai-icon"
                  // href="javascript:void()"
                  role="button"
                  aria-expanded="false"
                >
                  <i className="fas fa-folder"></i>
                  <span className="nav-text">Specialist Consults</span>
                </a>
                <ul aria-expanded="false">
                  <li>
                    <Link to="/hypertension">Chronic Care Management</Link>
                  </li>
                </ul>
              </li>
            </>
          )}
          {user.role === 'PHARMACIST' && (
            <li>
              <a
                className="has-arrow ai-icon"
                // href="/#/patient-add"
                role="button"
                aria-expanded="false"
              >
                <i className="fas fa-user-injured"></i>
                <span
                  className="nav-text"
                  onClick={() => navigate('patient-add')}
                >
                  Pharmacy
                </span>
              </a>
              <ul aria-expanded="false">
                <li>
                  <Link to="/pharmacist-dashboard">Dashboard</Link>
                </li>
                <li>
                  <Link to="/pharmacist-prescription-order-summary">
                    Prescription Order Summary
                  </Link>
                </li>
                <li>
                  <Link to="/pharmacist-prescription-dispensation">
                    Prescription Dispensation
                  </Link>
                </li>
                <li>
                  <Link to="/dispensation-summary">Dispensation Summary</Link>
                </li>
                <li>
                  <Link to="/external-rx-details">External Rx Details</Link>
                </li>
                <li>
                  <Link to="/external-dispensation-summary">
                    External Dispensation Summary
                  </Link>
                </li>
              </ul>
            </li>
          )}
          {user.isAdmin === true && (
            <>
              <li>
                <a
                  className="has-arrow ai-icon"
                  // href="javascript:void()"
                  role="button"
                  aria-expanded="false"
                >
                  <i className="fas fa-calendar-check"></i>
                  <span className="nav-text">Appointments</span>
                </a>
                <ul aria-expanded="false">
                  <li>
                    <Link to="/book-appointment">Book Appointments</Link>
                  </li>
                  <li>
                    <Link to="/calendar">Appointments Calendar</Link>
                  </li>

                  <li>
                    <Link to="/create-slots">Create Slots</Link>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  className="has-arrow ai-icon"
                  // href="javascript:void()"
                  role="button"
                  aria-expanded="false"
                >
                  <i className="fas fa-hospital-alt"></i>
                  <span className="nav-text">Accounting</span>
                </a>
                <ul aria-expanded="false">
                  <li>
                    <Link to="/ledger">Ledger</Link>
                  </li>
                </ul>
              </li>

              <li>
                <a
                  className="has-arrow ai-icon"
                  // href="javascript:void()"
                  role="button"
                  aria-expanded="false"
                >
                  <i className="fas fa-users fa-sm"></i>
                  <span className="nav-text">Users</span>
                </a>
                <ul aria-expanded="false">
                  <li>
                    <Link to="/manage-users">Manage staff</Link>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  className="has-arrow ai-icon"
                  // href="javascript:void()"
                  role="button"
                  aria-expanded="false"
                >
                  <i className="flaticon-381-controls-3" />
                  <span className="nav-text">Accounts</span>
                </a>
                <ul aria-expanded="false">
                  <li>
                    <Link to="/terminate-account">Terminate</Link>
                  </li>
                </ul>
              </li>
            </>
          )}

          {(user.role === 'Patient' || user.role === 'NURSE') && (
            <>
              <li>
                <a
                  className="has-arrow ai-icon"
                  // href="javascript:void()"
                  role="button"
                  aria-expanded="false"
                >
                  <i className="fas fa-calendar-check"></i>
                  <span className="nav-text">Appointments</span>
                </a>
                <ul aria-expanded="false">
                  <li>
                    <Link to="/book-appointment">Book Appointments</Link>
                  </li>
                  <li>
                    <Link to="/calendar">Appointments Calendar</Link>
                  </li>
                </ul>
              </li>
              {user.role !== 'NURSE' && user.invitedHyperTension?.length > 0 && (
                <>
                  <li>
                    <a
                      className="has-arrow ai-icon"
                      // href="javascript:void()"
                      role="button"
                      aria-expanded="false"
                    >
                      <i className="fas fa-stethoscope"></i>
                      <span className="nav-text">Careplans</span>
                    </a>
                    <ul aria-expanded="false">
                      <li>
                        <Link to="/careplan-dashboard">Dashboard</Link>
                      </li>
                      {/* <li>
                        <Link  to="/care-plan-summary">
                          Summary
                        </Link>
                      </li> */}

                      <li>
                        <Link to="/vitals-care-plan">Vitals Careplan</Link>
                      </li>
                      {user?.invitedHyperTension?.includes(
                        'Nutrition & Diet Care Plan',
                      ) && (
                        <li>
                          <Link to="/new-nutrition">Nutrition Careplan</Link>
                        </li>
                      )}

                      {/* <li>
                        <Link to='/new-life-style-review'>
                          LifeStyle Review Careplan
                        </Link>
                      </li> */}

                      {user?.invitedHyperTension?.includes(
                        'Activity Care Plan',
                      ) && (
                        <li>
                          <Link to="/new-activity-care-plan">
                            Activity Care Plan
                          </Link>
                        </li>
                      )}

                      {user?.invitedHyperTension?.includes(
                        'Habit Breaker Care Plan',
                      ) && (
                        <li>
                          <Link to="/new-habit-breaker-care-plan">
                            Habit Breaker Care Plan
                          </Link>
                        </li>
                      )}

                      {user?.invitedHyperTension?.includes(
                        'Mental Care Plan',
                      ) && (
                        <li>
                          <Link to="/mental-health-careplan">
                            Mental Health
                          </Link>
                        </li>
                      )}

                      {/* <li>
                        <Link to="/nutrition-care-plan">
                          Nutrition & Diet Care Plan
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link to="/diet-basket-summary">Diet Summary</Link>
                      </li> */}

                      {/* <li>
                        <Link to="/activity-care-plan">Activity Care Plan</Link>
                      </li> */}

                      {/* <li>
                        <Link to="/habit-care-plan">
                          Old Habit Breaker Care Plan
                        </Link>
                      </li> */}

                      {/* <li>
                        <Link to="/diet-care-plan">Diet Care Plan</Link>
                      </li> */}
                      <li>
                        {/* <Link to="/care-plan-signup">Care Plan Signup</Link> */}
                      </li>
                    </ul>
                  </li>
                  {/* <li> */}
                  {/* <a
                      className="has-arrow ai-icon"
                      // href="javascript:void()"
                      role="button"
                      aria-expanded="false"
                    >
                      <i className="fas fa-hospital-user"></i>
                      <span className="nav-text">Patient Onboarding</span>
                    </a> */}
                  {/* <ul aria-expanded="false">
                      <li>
                        <Link  to="/care-plan-signup">
                          Onboarding
                        </Link>
                      </li>
                    </ul> */}
                  {/* </li> */}
                </>
              )}

              {user?.role !== 'NURSE' && (
                <li>
                  <a
                    className="has-arrow ai-icon"
                    // href="javascript:void()"
                    role="button"
                    aria-expanded="false"
                  >
                    <i className="fas fa-book-medical"></i>
                    <span className="nav-text">Records</span>
                  </a>
                  <ul aria-expanded="false">
                    <li>
                      <Link to="/patient-medical-details">
                        Patient Medical Details
                      </Link>
                    </li>
                    {/* <li>
                    <Link  to="/hypertension-triage">
                      Hypertension Triage
                    </Link>
                  </li> */}

                    <li>
                      <Link to="/details-history">Details History</Link>
                    </li>
                    <li>
                      <Link to="/consultation-history">
                        Consultation History
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
            </>
          )}

          <>
            {user.role === 'SUPER_ADMIN' && (
              <>
                <li>
                  <a
                    className="has-arrow ai-icon"
                    // href="javascript:void()"
                    role="button"
                    aria-expanded="false"
                  >
                    <i className="fas fa-hospital-alt"></i>
                    <span className="nav-text">Health Centers</span>
                  </a>
                  <ul aria-expanded="false">
                    <li>
                      <Link to="/health-centers">Manage</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    className="has-arrow ai-icon"
                    // href="javascript:void()"
                    role="button"
                    aria-expanded="false"
                  >
                    <i className="fas fa-clipboard-list"></i>
                    <span className="nav-text">Specialties</span>
                  </a>
                  <ul aria-expanded="false">
                    <li>
                      <Link to="/specialities">Manage</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    className="has-arrow ai-icon"
                    // href="javascript:void()"
                    role="button"
                    aria-expanded="false"
                  >
                    <i className="fas fa-user-injured"></i>
                    <span className="nav-text">Patient</span>
                  </a>
                  <ul aria-expanded="false">
                    <li>
                      <Link to="/patient-manage">Manage</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    className="has-arrow ai-icon"
                    // href="javascript:void()"
                    role="button"
                    aria-expanded="false"
                  >
                    <i className="fas fa-users"></i>
                    <span className="nav-text">Users</span>
                  </a>
                  <ul aria-expanded="false">
                    <li>
                      <Link to="/manage-users">Manage</Link>
                    </li>
                  </ul>
                </li>
              </>
            )}
          </>
        </ul>
      </div>
    </div>
  )
}

export default LeftBar
