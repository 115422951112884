import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import LeftBar from '../components/LeftBar'
import Select from 'react-select'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../redux_setup/slices/userSlice'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Button, Col, Modal, Row } from 'react-bootstrap'

import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { formatDate } from '@fullcalendar/react'

const AssignToNurse = () => {
  const location = useLocation()
  const [method, setMethod] = useState('')
  const appointmentToView = location.state
  toast.configure()
  // abortcontroller
  const abortcontroller = new AbortController()
  let navigate = useNavigate()
  const signal = abortcontroller.signal
  const user = useSelector(selectUser)

  const [nurses, setNurses] = useState([])
  const [nurseSelected, setNurseSelected] = useState('')
  // const [priceset, setPriceSet] = useState(location.state.price);

  // health centers
  useEffect(() => {
    const fetchUsersUrl = `${process.env.REACT_APP_MAIN_URL}/api/user/health-center/?role=nurse&health_center_id=${user?.hc_id}`

    fetch(fetchUsersUrl, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        res.data.items.forEach((item) => {
          item.value = item.id
          item.label = item.name
          // now I no longer need them
          delete item.id
          delete item.name
          //->will delete the rest later
        })

        console.log(res.data.items)
        setNurses(res.data.items)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const assignNurse = () => {
    const dataToSend = {
      // appointment_id: location.state.appt_id,
      // patient_id: location.state.patient_id,
      nurse_id: nurseSelected,
    }
    // return console.log(dataToSend);
    const url = `${process.env.REACT_APP_MAIN_URL}/api/triage/assign?appointment_id=${location.state.appt_id}`
    // send to payment page here
    // return navigate("/payments", { state: dataToSend });
    console.log(dataToSend)
    // send request
    fetch(url, {
      method: 'PATCH',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          })
        }
        // console.log(res, dataToSend, user.token);
        navigate('/admin-dashboard')

        return toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        })
      })
      .catch((err) => {
        console.log(err)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        })
      })
  }

  return (
    <div id="main-wrapper">
      {/***********************************
        Nav header start
    ************************************/}
      {/* <NavHeader/> */}
      {/***********************************
        Nav header end
    ************************************/}

      {/***********************************
        Header start
    ************************************/}
      <Header />
      {/***********************************
        Header end ti-comment-alt
    ************************************/}
      {/***********************************
        Sidebar start
    ************************************/}
      <LeftBar />
      {/***********************************
        Sidebar end
    ************************************/}
      {/***********************************
        Content body start
    ************************************/}
      <div className="content-body">
        <div className="row bg-white py-5">
          <div className="card col-12">
            {user.isAdmin && (
              <>
                <div className="card-header">
                  <h4>Assign patient to nurse</h4>
                </div>
                <div className="card-body text-left">
                  <div className="form-group row">
                    <label htmlFor="nurse" className="col-md-2">
                      Select nurse
                    </label>
                    <div className="col-md-5">
                      <Select
                        options={nurses}
                        id="nurse"
                        onChange={(selectedOption) =>
                          setNurseSelected(selectedOption.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-9">
                    <button
                      className="btn btn-primary pull-right mt-3"
                      disabled={!nurseSelected}
                      onClick={assignNurse}
                    >
                      Assign
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* /.row*/}
        </div>
      </div>
      {/***********************************
        Content body end
    ************************************/}

      {/***********************************
        Footer start
    ************************************/}
      <Footer />
      {/***********************************
        Footer end
    ************************************/}
    </div>
  )
}

export default AssignToNurse
