import { useState } from "react";
import Switch from "react-switch";
import { Form, FormControl } from "react-bootstrap";
const switchColorCompute = (level) => {
  let color = "white";
  let handle = "white";
  switch (level) {
    case "High":
      color = "#ff80ad";
      handle = "#ff005c";
      break;

    case "Medium":
      color = "#fff9e5";
      handle = "#ffc000";
      break;

    case "Low":
      color = "#e6f7fb";
      handle = "#07b0d7";
      break;

    default:
      color = "white";
      handle = "#ff005c";
      break;
  }
  return { color, handle };
};

const SocialSupport = () => {
  const [switched, setSwitched] = useState({id: null, checked: false})
  return (
    <div className='card pl-2 pr-2 mt-3'>
      <p className='meal-profile-text mt-3' style={{ color: "#000" }}>
        Social Support
      </p>
      <label>Do you have any hobbies or interests?</label>
      <div className='d-flex' style={{ gap: 50 }}>
        <Form.Check id='hobbies-yes' label='Yes' type='checkbox' />
        <Form.Check id='hobbies-no' label='No' type='checkbox' />
      </div>
      <div className='d-flex flex-wrap' style={{ gap: 100 }}>
        <div>
          <label className='pt-3'>Hobbies/Interests</label>
          <FormControl
            as='textarea'
            rows='3'
            placeholder='Type something here...'
            style={{ backgroundColor: "#eee", width: 456 }}
          />
        </div>
        <div>
          <label style={{ display: "block" }}>Frequency</label>
          <input
            type='number'
            placeholder='Days per week'
            style={{ height: 30 }}
          />
        </div>
      </div>
      <label className='pt-3'>
        Do you have a family/relatives who support you?
      </label>
      <div className='d-flex' style={{ gap: 50 }}>
        <Form.Check id='support-yes' label='Yes' type='checkbox' />
        <Form.Check id='support-no' label='No' type='checkbox' />
      </div>
      <label className='pt-3'>Do you have friends who support you?</label>
      <div className='d-flex' style={{ gap: 50 }}>
        <Form.Check id='friends-yes' label='Yes' type='checkbox' />
        <Form.Check id='friends-no' label='No' type='checkbox' />
      </div>
      <div className='d-flex flex-wrap mt-3 mb-3' style={{ gap: 20 }}>
        {["Low", "Medium", "High"].map((level, index) => (
          <div className='d-flex flex-wrap'>
            <label style={{ marginTop: 5, fontWeight: 700 }}>{level}</label>
            <div className={`switch${index + 1}`} key={index}>
              <Switch
                key={index}
                onChange={(e) => setSwitched({ id: index, checked: e })}
                value={switched.checked}
                checked={switched.id === index ? switched.checked : false}
                uncheckedIcon={false}
                checkedIcon={false}
                onHandleColor={switchColorCompute(level).handle}
                offHandleColor={switchColorCompute(level).handle}
                onColor={switchColorCompute(level).color}
                offColor={switchColorCompute(level).color}
                width={80}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SocialSupport;
