import Footer from "../../Footer";
import LeftBar from "../../LeftBar";
import Header from "../../Header";
import { Button, Form } from "react-bootstrap";
import Mealprofile from "../../new-nutrition/Mealprofile";
import { useSelector } from "react-redux";
import { selectBasketFood } from "../../../redux_setup/slices/basketFoodSlice";
const DietBasketSummary = () => {
  const foodInBasket = useSelector(selectBasketFood);
  return (
    <div className='main-wrapper'>
      {/***********************************
                  Header start
                  ************************************/}
      <Header />
      {/***********************************
                  Header end ti-comment-alt
                  ************************************/}
      {/***********************************
                  Sidebar start
                  ************************************/}
      <LeftBar />
      {/***********************************
                  Sidebar end
                  ************************************/}
      {/***********************************
                  Content body start
                  ************************************/}

      {/***********************************
                  Content body end
                  ************************************/}
      <div className='content-body'>
        <div className='d-flex flex-wrap' style={{ gap: 100 }}>
          <div className='d-flex' style={{ width: 300 }}>
            <Form.Select
              aria-label='Default select example'
              className='mr-2 pl-2'
            >
              <option>Open this select menu</option>
            </Form.Select>
            <Button
              className='mr-2'
              style={{
                backgroundColor: "#2FA5DB33",
                border: "1px solid #2FA5DB33",
                fontWeight: 700,
              }}
            >
              Today
            </Button>
            <div
              className=' circle'
              style={{
                border: "1px solid #242E75",
                width: 50,
                height: 32,
                paddingLeft: 10,
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/meal.png`}
                alt='meal icon'
                width={7.6}
                height={17}
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/meal-1.png`}
                alt='meal icon'
              />
              <div
                className='circle'
                style={{
                  position: "absolute",
                  right: -5,
                  top: -12,
                  backgroundColor: "#662382",
                  color: "#FFF",
                  paddingBottom: 7,
                  paddingLeft: 4,
                  fontSize: 10,
                  width: foodInBasket.length > 9 ? 20 : "",
                  height: foodInBasket.length > 9 ? 20 : "",
                  paddingTop: foodInBasket.length > 9 ? 2 : "",
                }}
              >
                {foodInBasket.length}
              </div>
            </div>
          </div>
          <div>
            <input
              type='date'
              style={{
                width: 200,
                height: 40,
                borderRadius: 8,
              }}
            />
          </div>
        </div>
        <p
          className='mt-3 mb-3'
          style={{
            fontSize: 20,
            fontFamily: "Lato",
            fontWeight: 700,
            color: "#000",
          }}
        >
          Diet & Nutrition(Recordings)
        </p>
        <div className='row'>
          <div
            className=' col-md-8 col-lg-8 col-sm-12 d-flex flex-wrap rounded'
            style={{ backgroundColor: "#fff", padding: 20, borderRadius: 8 }}
          >
            <div className='col-md-12'>
              {["Breakfast", "Lunch", "Dinner", "Snacks"].map((food) => (
                <div className='card pl-3 pt-3 pr-3' style={{ height: 400 }}>
                  <div className='d-flex justify-content-between'>
                    <p
                      className='meal-profile-text mt-3'
                      style={{ color: "#000" }}
                    >
                      {food}
                    </p>
                    <div style={{cursor: 'pointer'}}>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/icons/edit-icon.png`}
                        alt='Edit icon'
                        width={13}
                        height={14}
                      />
                      <span
                        className='ml-2'
                        style={{ color: "#19226D", fontSize: 10 }}
                      >
                        Edit
                      </span>
                    </div>
                  </div>
                  <div className='d-flex flex-wrap'>
                    <div>
                      <label className='mr-3 mt-2'>Carbohydrates</label>

                      <div
                        className='card pt-2 pl-1 pr-2'
                        style={{ width: 215, backgroundColor: "#E8E8E840" }}
                      >
                        <label style={{ fontWeight: 700, color: "#000" }}>
                          Total Carbs
                        </label>
                        <div
                          style={{
                            width: "97%",
                            height: 2,
                            backgroundColor: "#52575C26",
                          }}
                          className='mt-2 mb-2'
                        />
                        <div
                          className='card pl-2 pb-1'
                          style={{
                            backgroundColor: "#6623824D",
                            width: 195,
                            height: 24,
                          }}
                        >
                          Calories
                        </div>
                        <h4 style={{ color: "#662382" }}>
                          250 <span style={{ fontSize: 14 }}>kcal</span>
                        </h4>
                        <div
                          className='card pl-2 pb-1'
                          style={{
                            backgroundColor: "#6623824D",
                            width: 195,
                            height: 24,
                          }}
                        >
                          Sodium
                        </div>
                        <h4 style={{ color: "#662382" }}>
                          15 <span style={{ fontSize: 14 }}>mEq/L</span>
                        </h4>
                      </div>
                    </div>
                    {/* proteins */}
                    <div>
                      <label className='mr-3 mt-2'>Proteins</label>

                      <div
                        className='card pt-2 pl-1 pr-2'
                        style={{ width: 215, backgroundColor: "#E8E8E840" }}
                      >
                        <label style={{ fontWeight: 700, color: "#000" }}>
                          Total Proteins
                        </label>
                        <div
                          style={{
                            width: "97%",
                            height: 2,
                            backgroundColor: "#52575C26",
                          }}
                          className='mt-2 mb-2'
                        />
                        <div
                          className='card pl-2 pb-1'
                          style={{
                            backgroundColor: "#6623824D",
                            width: 195,
                            height: 24,
                          }}
                        >
                          Calories
                        </div>
                        <h4 style={{ color: "#662382" }}>
                          100 <span style={{ fontSize: 14 }}>kcal</span>
                        </h4>
                        <div
                          className='card pl-2 pb-1'
                          style={{
                            backgroundColor: "#6623824D",
                            width: 195,
                            height: 24,
                          }}
                        >
                          Sodium
                        </div>
                        <h4 style={{ color: "#662382" }}>
                          10 <span style={{ fontSize: 14 }}>mEq/L</span>
                        </h4>
                      </div>
                    </div>
                    {/* Fats */}
                    <div>
                      <label className='mr-3 mt-2'>Fats</label>

                      <div
                        className='card pt-2 pl-1 pr-2'
                        style={{ width: 215, backgroundColor: "#E8E8E840" }}
                      >
                        <label style={{ fontWeight: 700, color: "#000" }}>
                          Total Fats
                        </label>
                        <div
                          style={{
                            width: "97%",
                            height: 2,
                            backgroundColor: "#52575C26",
                          }}
                          className='mt-2 mb-2'
                        />
                        <div
                          className='card pl-2 pb-1'
                          style={{
                            backgroundColor: "#6623824D",
                            width: 195,
                            height: 24,
                          }}
                        >
                          Calories
                        </div>
                        <h4 style={{ color: "#662382" }}>
                          71 <span style={{ fontSize: 14 }}>kcal</span>
                        </h4>
                        <div
                          className='card pl-2 pb-1'
                          style={{
                            backgroundColor: "#6623824D",
                            width: 195,
                            height: 24,
                          }}
                        >
                          Sodium
                        </div>
                        <h4 style={{ color: "#662382" }}>
                          10 <span style={{ fontSize: 14 }}>mEq/L</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='col-md-4 col-lg-4 col-sm-12 card'>
            <Mealprofile />
            <Button variant='success' className='mt-2'>Update Meal Profile</Button>
          </div>
        </div>
      </div>

      {/***********************************
                  Footer start
                  ************************************/}
      <Footer />
      {/***********************************
                  Footer end
                  ************************************/}
    </div>
  );
};

export default DietBasketSummary;
