import React,{useState} from 'react'
import Header from '../../components/Header';
import LeftBar from '../../components/LeftBar';
import Footer from '../../components/Footer';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import RxDetails from './RxDetails';
const data = [
  {
    drugClass: "Analgesics and antipyretics",
    drugName: "Paracetamol   (500mg) Tablets",
    dosage: "1 or 2 tablets ",
    route: "Oral",
    frequency: "every 4-6 hours",
    amount: 1,
    refill: 1,
  },
  {
    drugClass: "Penicillins, Amino",
    drugName: "Amoxicillin  (500mg) Capsules",
    dosage: "1 tablet After Meals",
    route: "Oral",
    frequency: "every 8 or 12 hours",
    amount: 1,
    refill: 1,
  },
  {
    drugClass: "Reflux suppressant",
    drugName: "Gaviscon Syrup",
    dosage: "10ml after meals",
    route: "Oral",
    frequency: "every 4-6 hours",
    amount: 1,
    refill: 0,
  },
];
const PrescriptionSummary = () => {
  const [showTextArea, setShowTextArea] = useState(false)
  return (
    <div id='main-wrapper'>
      {/***********************************
Header start
************************************/}
      <Header />
      {/***********************************
Header end ti-comment-alt
************************************/}
      {/***********************************
Sidebar start
************************************/}
      <LeftBar />
      {/***********************************
Sidebar end
************************************/}
      {/***********************************
Content body start
************************************/}

      {/***********************************
      Sidebar end
  ************************************/}
      {/***********************************
      Content body start
  ************************************/}

      <div className='content-body'>
        <RxDetails />
        <p
          className='mt-2 mb-3'
          style={{ fontSize: 20, fontFamily: "Lato", fontWeight: 700 }}
        >
          RX Details
        </p>
        <div className='card pt-3 pl-3'>
          <div className='table-responsive card-table mb-5'>
            <table
              id='example5'
              className='display dataTablesCard table-responsive-xl'
            >
              <thead>
                <tr>
                  <th>DRUG CLASS</th>
                  <th>DRUG NAME</th>
                  <th>DOSAGE</th>
                  <th>ROUTE &nbsp; &nbsp;</th>
                  <th>FREQUENCY</th>
                  <th>
                    AMT TO BE DISPENSED
                    <br /> BY PHARMACY
                  </th>
                  <th>&nbsp; &nbsp;NO. OF REFILLS</th>
                </tr>
              </thead>

              <tbody>
                <>
                  {data?.map((data) => (
                    <tr className='mt-2 mb-2'>
                      <td>{data?.drugClass}</td>
                      <td>{data?.drugName}</td>
                      <td>{data?.dosage}</td>
                      <td>{data?.route}</td>
                      <td>{data?.frequency}</td>
                      <td>{data?.amount}</td>
                      <td>{data?.refill}</td>
                    </tr>
                  ))}
                </>
              </tbody>
            </table>
          </div>
          <p
            className='mt-4 mb-3'
            style={{ fontSize: 16, fontFamily: "Lato", fontWeight: 700 }}
          >
            Additional Directions of use
          </p>
          <p
            className='mt-2 mb-3'
            style={{
              fontSize: 14,
              fontFamily: "Lato",
              fontWeight: 400,
              color: "#52575C",
            }}
          >
            This is additional information about this medication to this
            patient. Please take it seriously.
          </p>
          <div className='d-flex justify-content-start'>
            <Button
              className='mr-5 mt-5 mb-5'
              style={{ backgroundColor: "#19226D", width: 166, height: 40 }}
            >
              Dispense
            </Button>
          </div>
        </div>
        {/* <div className='row'>
          <div className='col-md-4 col-lg-4 col-sm-12'>
            <p
              className='mt-4 mb-3 ml-4'
              style={{
                fontSize: 20,
                fontFamily: "Lato",
                fontWeight: 700,
                color: "#25282B",
              }}
            >
              Inventory Status
            </p>
            <div
              className='card pl-4 pt-4'
              style={{
                backgroundColor: "#66238252",
                border: "1px #66238252 solid",
                height: 163,
              }}
            >
              <div className='d-flex' style={{ gap: 30 }}>
                <p style={{ fontSize: 16, fontWeight: 700 }}>Pack Size</p>
                <p style={{ fontSize: 14, fontWeight: 400 }}>Doctor One</p>
              </div>
              <div className='d-flex' style={{ gap: 30 }}>
                <p style={{ fontSize: 16, fontWeight: 700 }}>
                  Available Quantity
                </p>
                <p style={{ fontSize: 14, fontWeight: 400 }}>2 Pkts</p>
              </div>
              <div className='d-flex' style={{ gap: 30 }}>
                <p style={{ fontSize: 16, fontWeight: 700 }}>Drug Tier</p>
                <p style={{ fontSize: 14, fontWeight: 400 }}>Best</p>
              </div>
            </div>
          </div>
          <div className='col-md-4 col-lg-4 col-sm-12'>
            <p
              className='mt-4 mb-3 ml-4'
              style={{
                fontSize: 20,
                fontFamily: "Lato",
                fontWeight: 700,
                color: "#25282B",
              }}
            >
              Billing Details
            </p>
            <div
              className='card pl-4 pt-4'
              style={{
                backgroundColor: "#0193D952",
                border: "1px #0193D952 solid",
                height: 163,
              }}
            >
              <div className='d-flex' style={{ gap: 20 }}>
                <p style={{ fontSize: 16, fontWeight: 700 }}>
                  Patient Payment Method
                </p>
                <p style={{ fontSize: 14, fontWeight: 400 }}>Cash</p>
              </div>
              <div className='d-flex' style={{ gap: 20 }}>
                <p style={{ fontSize: 16, fontWeight: 700 }}>
                  Drug Billing Type
                </p>
                <p style={{ fontSize: 14, fontWeight: 400 }}>Cash</p>
              </div>
            </div>
          </div>
          <div className='col-md-4 col-lg-4 col-sm-12'>
            <p
              className='mt-4 mb-3 ml-4'
              style={{
                fontSize: 20,
                fontFamily: "Lato",
                fontWeight: 700,
                color: "#25282B",
              }}
            >
              Factors to Note
            </p>
            <div
              className='card pl-4 pt-4'
              style={{
                backgroundColor: "#45B7571A",
                border: "1px #45B7571A solid",
                height: 163,
              }}
            >
              <span
                style={{ fontFamily: "Lato", fontSize: 16, fontWeight: 700 }}
              >
                Current Medications & Possible Drug
                <br /> Interactions with Prescribed Drug
              </span>
              <p
                className='mt-3'
                style={{ fontFamily: "Lato", fontSize: 14, fontWeight: 400 }}
              >
                State the lethal interaction
              </p>
            </div>
          </div>
        </div>
        <div className='card pl-4 pt-4 pb-5 mt-3'>
          <p
            className='mt-2 mb-3'
            style={{
              fontSize: 16,
              fontFamily: "Lato",
              fontWeight: 700,
              color: "#000",
            }}
          >
            Would you like to proceed to dispensation?
          </p>
          <div style={{ width: 250 }}>
            <div className='d-flex justify-content-between'>
              <Form.Check id='yes' label='Yes' />
              <Form.Check
                id='no'
                label='No'
                onChange={(e) => {
                  if (e.target.checked) {
                    setShowTextArea(true);
                  } else {
                    setShowTextArea(false);
                  }
                }}
              />
            </div>
            {showTextArea ? (
              <>
                <textarea
                  className='form-control mt-3 web-mgn'
                  placeholder='Please provide a reason for the selection above'
                  id='exampleFormControlTextarea1'
                  rows={6}
                  style={{ width: "135%", height: 124 }}
                />
                <Button
                  className='web-btn'
                  style={{
                    backgroundColor: "#19226D",
                    position: "absolute",
                    bottom: 60,
                  }}
                >
                  Okay
                </Button>
              </>
            ) : (
              <></>
            )}
          </div>
        </div> */}
        <div className='d-flex justify-content-end' style={{ gap: 30 }}>
          <Button style={{ backgroundColor: "#19226D" }}>Back to Queue</Button>
          <Link to='/pharmacist-dashboard'>
            <Button style={{ backgroundColor: "#19226D" }}>
              Back to Dashboard
            </Button>
          </Link>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default PrescriptionSummary;