import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LeftBar from "../components/LeftBar";
import DataTable from "react-data-table-component";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { selectUser } from "../redux_setup/slices/userSlice";
import Preloader from "../components/Preloader";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Add_HC_Schema, Add_User_Schema } from "../constants/ValidationSchema";
import Select from "react-select";

const columns = [
  {
    name: "No",
    selector: (row) => row.count,
    sortable: true,
  },
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row.email,
  },
  {
    name: "Gender",
    selector: (row) => row.gender,
  },
  {
    name: "Role",
    selector: (row) => row.role,
  },

  {
    name: "Admin",
    selector: (row) => (
      <span className={row.is_admin === true ? "text-success" : "text-warning"}>
        {row.is_admin ? "Yes" : "No"}
      </span>
    ),
  },
];

const CreateSlots = () => {
  toast.configure();

  const [doctor, setDoctor] = useState([]);
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const abortcontroller = new AbortController();
  const signal = abortcontroller.signal;
  const user = useSelector(selectUser);

  const health_center = user.hc_id;

  const [healthCentersSelect, setHealthCentersSelect] = useState([]);
  //fetch doctors
  useEffect(() => {
    const fetchUsersUrl = `${process.env.REACT_APP_MAIN_URL}/api/user/health-center/?role=doctor&health_center_id=${health_center}`;
    fetch(fetchUsersUrl, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + user.token,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        res.data.items.forEach((item) => {
          item.value = item.id;
          item.label = item.name;
          // now I no longer need them
          delete item.id;
          delete item.name;
          //->will delete the rest later
        });
        // console.log(res.data);

        res.data?.items?.length > 0 && setDoctor(res.data.items);
      })
      .catch((err) => {
        console.log(err);
      });
    return function cleanup() {
      abortcontroller.abort();
    };
  }, []);

  const pushToDoctors = (obj) => {
    const temp = [...selectedDoctors];
    temp.push({ id: obj.value });
    const uniqueTemp = [...new Set(temp)];
    setSelectedDoctors(uniqueTemp);

    // for visualization
    const temp2 = [...selectedNames];
    temp2.push(obj.label);
    const uniqueTemp2 = [...new Set(temp2)];
    setSelectedNames(uniqueTemp2);
  };

  const pushToSlots = (value) => {
    if (value !== "") {
      const temp = [...selectedSlots];
      temp.push(value);
      const uniqueTemp = [...new Set(temp)];
      setSelectedSlots(uniqueTemp);
    }
  };

  function dateRange(startDate, endDate) {
    var listDate = [];
    var dateMove = new Date(startDate);
    var strDate = startDate;

    while (strDate < endDate) {
      var strDate = dateMove.toISOString().slice(0, 10);
      listDate.push(strDate);
      dateMove.setDate(dateMove.getDate() + 1);
    }
    return listDate;
  }

  const createSlots = (value) => {
    if (
      selectedDoctors.length > 0 &&
      selectedSlots.length > 0 &&
      toDate > fromDate
    ) {
      const objToSend = {};
      objToSend.doctors = selectedDoctors;

      //convert date range to array
      const dates = dateRange(fromDate, toDate);

      const slots = [];

      selectedSlots.map((timeSlot) => {
        dates.map((date) => {
          const obj = {
            health_center_id: health_center,
            time_slot: timeSlot,
            date: date,
          };
          slots.push(obj);
        });
      });
      objToSend.slots = slots;

      const url = `${process.env.REACT_APP_MAIN_URL}/api/doctor/create-slots`;

      // send request
      fetch(url, {
        signal: signal,
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(objToSend),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            return toast.warning(res.error, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: false
            });
          }

          window.location.reload(false);

          return toast.success(res.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          });
        })
        .catch((err) => {
          console.log(err);
          return toast.warning("An error occured while reaching the server", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          });
        });

      //   console.log(objToSend);
    }
  };

  return (
    <div id="main-wrapper">
      {/***********************************
    Nav header start
************************************/}
      {/* <NavHeader/> */}
      {/***********************************
    Nav header end
************************************/}

      {/***********************************
    Header start
************************************/}
      <Header />
      {/***********************************
    Header end ti-comment-alt
************************************/}
      {/***********************************
    Sidebar start
************************************/}
      <LeftBar />
      {/***********************************
    Sidebar end
************************************/}
      {/***********************************
    Content body start
************************************/}
      <div className="content-body">
        <div className="card">
          <div className="p-5">
            <h4>Create Appointment Slots</h4>
            <Row className="container ">
              <Col sm={6}>
                <label className="mb-1">Include doctors</label>
                <Select
                  options={doctor}
                  id="doctor"
                  onChange={(selectedOption) => pushToDoctors(selectedOption)}
                  placeholder="Select Doctor"
                />
              </Col>
              <Col sm={6}>
                <label className="mb-1">Selected Doctors</label>
                <textarea
                  className="form-control forclicks"
                  readOnly
                  value ={selectedNames.toString()}
                >
                
                </textarea>
              </Col>
              <Col sm={6}>
                <label className="mb-1">Slots</label>
                <select
                  className="forclicks form-control "
                  onChange={(e) => pushToSlots(e.target.value)}
                >
                  <option value="">Choose slot</option>
                  <option value="08:00">08:00</option>
                  <option value="08:30">08:30</option>
                  <option value="09:00">09:00</option>
                  <option value="09:30">09:30</option>
                  <option value="10:00">10:00</option>
                  <option value="10:30">10:30</option>
                  <option value="11:00">11:00</option>
                  <option value="11:30">11:30</option>
                  <option value="12:00">12:00</option>
                  <option value="12:30">12:30</option>
                  <option value="13:00">13:00</option>
                  <option value="13:30">13:30</option>
                  <option value="14:00">14:00</option>
                  <option value="14:30">14:30</option>
                  <option value="15:00">15:00</option>
                  <option value="15:30">15:30</option>
                  <option value="16:00">16:00</option>
                  <option value="16:30">16:30</option>
                  <option value="17:00">17:00</option>
                </select>
              </Col>
              <Col sm={6}>
                <label className="mb-1">Selected slots</label>
                <textarea
                  className="form-control forclicks"
                  value={selectedSlots.toString()}
                  readOnly>
                  
                </textarea>
              </Col>
              <Col sm={6}>
                <label className="mb-1">From date</label>
                <input
                  type="date"
                  min={new Date().toISOString().split("T")[0]}
                  className="form-control"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  placeholder="From Date"
                />
              </Col>
              <Col sm={6}>
                <label className="mb-1">To date</label>
                <input
                  type="date"
                  className="form-control"
                  min={new Date().toISOString().split("T")[0]}
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  placeholder="To Date"
                />
              </Col>
            </Row>
            <br />
            <div className="text-center">
            <Button variant="warning" onClick={createSlots} title="Click here to create Appointment slot">
              Create
            </Button>
            </div>
           
          </div>
        </div>
      </div>
      {/***********************************
    Content body end
************************************/}

      {/***********************************
    Footer start
************************************/}
      <Footer />
      {/***********************************
    Footer end
************************************/}

      {/***********************************
    Edit Modal start
************************************/}

      {/***********************************
    Edit Modal end
************************************/}
      {/***********************************
    Add Modal start
************************************/}

      {/***********************************
    Add Modal end
************************************/}
    </div>
  );
};

export default CreateSlots;
