import React from 'react'
const spanStyle = {
  fontFamily: "Lato",
  fontWeight: 400,
  fontSize: 14,
  marginRight: 20,
};
const style = {
  fontFamily: "Lato",
  fontWeight: 400,
  fontSize: 16,
  marginRight: 20,
};
const PatientDetails = () => {
  return (
    <div className='card pt-4 pl-3' style={{ width: 654, height: 131 }}>
      <div className='d-flex flex-wrap justify-content-between'>
        <div>
          <span style={spanStyle}>Name:</span>
          <span style={style}>John Doe</span>
          <br />
          <span style={spanStyle}>Age:</span>
          <span style={style}>56YRS</span>
          <br />
          <span style={spanStyle}>Careplan:</span>
          <span style={style}>Nutrition & Care Plan</span>
          <br />
        </div>
        <div className='mr-5'>
          <span style={spanStyle}>Sex:</span>
          <span style={style}>Male</span>
          <br />
          <span style={spanStyle}>Weight:</span>
          <span style={style}>120KGS</span>
          <br />
        </div>
      </div>
    </div>
  );
}

export default PatientDetails